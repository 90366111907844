import React from "react";
import { connect } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import PageNotFound from "../../common/PageNotFound";
import { Offline, Online } from "react-detect-offline";
import InternetConnection from "../../common/InternetConnection";
import { axios, url, modalStyle } from "../../../store/actions/actions";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FileUpload from "react-mui-fileuploader"
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";


class CreateAnnualBudget extends React.Component {

  state = {
    teams: null,
    team_id: null,
    balance: null,
    amount: null,
    remark: null,
    chalan: null,
    ref_num: null,
    info: [],
  }

  style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

    open: false
  };

  componentDidMount() {
    this.loadTeams()
  }

  handleFileUploadError = (error) => {
    // Do something...
    console.log(error)
  }

  handleFilesChange = (files) => {
    this.setState({
      info: []
    })
    if (files.length !== 0) {
      let array = this.state.info
      let dict = {}
      dict['name'] = files[0].name
      dict['image'] = files[0].path.replace("data:", "").replace(/^.+,/, "")
      array.push(dict)
      this.setState({
        info: array
      })
    }
  }

  loadTeams = () => {
    let endpoint = `/teams/dash`
    let token = this.props.user.token

    axios
      .get(endpoint, token)
      .then((res) => {
        let responseData = res.data;
        this.setState({
          teams: responseData
        })
        console.log({ 'Team response data---------': responseData })
      })
  }

  loadTeamAccountBalance = (id) => {
    const data = {
      team_id: id
    }
    let endpoint = `account/get-team-balance`
    let token = this.props.user.token

    axios
      .post(endpoint, data, token)
      .then((res) => {
        let responseData = res.data;
        this.setState({
          balance: responseData.balance
        })
        console.log({ 'Team account balance---------': responseData })
      })
  }

  submit = () => {
    const data = {
      team_id: this.state.team_id,
      amount: this.state.amount,
      remark: this.state.remark,
      chalan: this.state.chalan,
      ref_num: this.state.ref_num,
      info: this.state.info
    }
    console.log(data)

    let endpoint = `account/team-account-update`
    let token = this.props.user.token

    axios
      .post(endpoint, data, token)
      .then((res) => {
        let responseData = res.data;
        if (responseData.success!==false){
          this.setState({
            open: true
          })
        }
        console.log({ 'response data---------': responseData })
      })
  }

  getTeam = (event, value) => {
    let id = value ? value.id : null
    this.setState({ team_id: id })
    let endpoint = `/teams/${id}`
    let token = this.props.user.token

    axios
      .get(endpoint, token)
      .then((res) => {
        let responseData = res.data;
        this.setState({
          team: responseData
        })
        this.loadTeamAccountBalance(id)
        console.log({ 'Get Team response data---------': responseData })
      })
  }

  render() {
    return (
      <>
        {this.props.user.login === true ?
          <>
            <Online>
              {this.props.user.login ?
                <>
                  <div className="container-fluid d-none d-lg-block">
                    <div className="row justify-content-end">
                      <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-10" : "col-12"}>
                        <div className="col-md-8 d-block m-auto mt-lg-0 mt-4">
                          {/*// <!-- Card Basic Info -->*/}
                          <div className="card mt-4 mb-4 p-4" id="basic-info">
                            <div className="card-header">
                              <h5>Team Account</h5>
                            </div>
                            <div className="card-body pt-0">
                              <div className="row">
                                <div className="col-6">
                                  {this.state.teams ?
                                    <Autocomplete
                                      disablePortal
                                      id="combo-box-demo"
                                      options={this.state.teams}
                                      onChange={this.getTeam}
                                      getOptionLabel={(option) => option.name}
                                      // sx={{ width: 300 }}
                                      renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                          <img
                                            loading="lazy"
                                            width="20"
                                            src={`${url + option.logo}`}
                                            srcSet={`${url + option.logo} 2x`}
                                            alt=""
                                          />
                                          {option.name}
                                        </Box>
                                      )}
                                      renderInput={(params) => <TextField {...params} label="Select Team" />}
                                    />
                                    : null}
                                </div>
                                <div className="col-6 mt-3">
                                  {this.state.balance ?
                                    <div className="d-flex">
                                      <h6 style={{ fontSize: "18px" }}>Current Balance:</h6>
                                      <p className="m-1"><CurrencyRupeeIcon />{this.state.balance}</p>
                                    </div>
                                    : null}
                                </div>

                                <div className="col-4 mt-3">
                                  <TextField id="outlined-basic" label="Enter Amount" variant="standard" onChange={(e) => this.setState({ amount: e.target.value })} fullWidth />
                                </div>

                                <div className="col-4 mt-3">
                                  <TextField id="outlined-basic" label="Chalan" variant="standard" onChange={(e) => this.setState({ chalan: e.target.value })} fullWidth />
                                </div>

                                <div className="col-4 mt-3">
                                  <TextField id="outlined-basic" label="Reference-num" variant="standard" onChange={(e) => this.setState({ ref_num: e.target.value })} fullWidth />
                                </div>
                              </div>
                              <div className="p-5">
                                <FileUpload
                                  // multiFile={null}
                                  disabled={false}
                                  // title="Upload"
                                  title={null}
                                  header="Upload Transaction Info here"
                                  // leftLabel="or"
                                  leftLabel={null}
                                  // rightLabel="to select files"
                                  buttonLabel="click here"
                                  buttonRemoveLabel="Remove all"
                                  maxFileSize={10}
                                  maxUploadFiles={2}
                                  maxFilesContainerHeight={357}
                                  errorSizeMessage={'fill it or move it to use the default error message'}
                                  // allowedExtensions={['jpg', 'jpeg']}
                                  onFilesChange={this.handleFilesChange}
                                  onError={this.handleFileUploadError}
                                  // imageSrc={'path/to/custom/image'}
                                  bannerProps={{ elevation: 0, variant: "outlined" }}
                                  containerProps={{ elevation: 0, variant: "outlined" }}
                                />

                              </div>

                              <div className="col-12 mt-3">
                                <TextField id="outlined-basic" label="Remark" variant="standard" onChange={(e) => this.setState({ remark: e.target.value })} fullWidth />
                              </div>

                              <button onClick={this.submit} className="btn bg-gradient-dark btn-md mt-6">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* mobile view */}

                  <div className="row d-lg-none px-2">
                                    <div className="dashOption-mv mt-4">
                                        <div className="">
                                            <div className="button-bar-mv d-flex">
                                                <Link to='/team' className="btn btn-mv "><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                                {this.props.user.employee_profile === 'admin' ?
                                                <Link to="/team/create-team" className="btn btn-mv "><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create</Link>
                                                :null}
                                                {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                                <Link to="/account/team-account" className="btn btn-mv active"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create Budget</Link>
                                                :null}
                                                {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                                <Link to="/account/team-transactions" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Transactions</Link>
                                                :null}
                                            </div>
                                        </div>
                                    </div>
                    <div className="col-md-12 d-block m-auto mt-lg-0 mt-3">
                      {/*// <!-- Card Basic Info -->*/}
                      <div className="card mt-4 mb-4" id="basic-info">
                        <div className="card-header">
                          <h5>Team Account</h5>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-12">
                              {this.state.teams ?
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  options={this.state.teams}
                                  onChange={this.getTeam}
                                  getOptionLabel={(option) => option.name}
                                  // sx={{ width: 300 }}
                                  renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={`${url + option.logo}`}
                                        srcSet={`${url + option.logo} 2x`}
                                        alt=""
                                      />
                                      {option.name}
                                    </Box>
                                  )}
                                  renderInput={(params) => <TextField {...params} label="Select Team" />}
                                />
                                : null}
                            </div>
                            <div className="col-12 mt-3">
                              {this.state.balance ?
                                <div className="d-flex">
                                  <h6 style={{ fontSize: "18px" }}>Current Balance:</h6>
                                  <p className="m-1"><CurrencyRupeeIcon />{this.state.balance}</p>
                                </div>
                                : null}
                            </div>
                            <div className="col-12">
                              <label className="form-label">Enter Amount</label>
                              <div className="input-group input-group-dynamic">
                                <input
                                  placeholder='Team name'
                                  onChange={(e) => this.setState({ amount: e.target.value })}
                                  className="multisteps-form__input form-control form-control-mv"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="col-12 mt-3">
                              <label className="form-label">Challan</label>
                              <div className="input-group input-group-dynamic">
                                <input
                                  placeholder='Team name'
                                  onChange={(e) => this.setState({ chalan: e.target.value })}
                                  className="multisteps-form__input form-control form-control-mv"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="col-12 mt-3">
                              <label className="form-label">Reference-Num</label>
                              <div className="input-group input-group-dynamic">
                                <input
                                  placeholder='Team name'
                                  onChange={(e) => this.setState({ ref_num: e.target.value })}
                                  className="multisteps-form__input form-control form-control-mv"
                                  type="text"
                                />
                              </div>
                            </div>
{/*
                            <div className="col-12 mt-3">
                              <TextField id="outlined-basic" label="Enter Amount" variant="standard" onChange={(e) => this.setState({ amount: e.target.value })} fullWidth />
                            </div>

                            <div className="col-12 mt-3">
                              <TextField id="outlined-basic" label="Chalan" variant="standard" onChange={(e) => this.setState({ chalan: e.target.value })} fullWidth />
                            </div>

                            <div className="col-12 mt-3">
                              <TextField id="outlined-basic" label="Reference-num" variant="standard" onChange={(e) => this.setState({ ref_num: e.target.value })} fullWidth />
                            </div> */}
                          </div>
                          <div className="mt-3">
                            <FileUpload
                              // multiFile={null}
                              disabled={false}
                              // title="Upload"
                              title={null}
                              header="Upload Transaction Info here"
                              // leftLabel="or"
                              leftLabel={null}
                              // rightLabel="to select files"
                              buttonLabel="click here"
                              buttonRemoveLabel="Remove all"
                              maxFileSize={10}
                              maxUploadFiles={2}
                              maxFilesContainerHeight={357}
                              errorSizeMessage={'fill it or move it to use the default error message'}
                              // allowedExtensions={['jpg', 'jpeg']}
                              onFilesChange={this.handleFilesChange}
                              onError={this.handleFileUploadError}
                              // imageSrc={'path/to/custom/image'}
                              bannerProps={{ elevation: 0, variant: "outlined" }}
                              containerProps={{ elevation: 0, variant: "outlined" }}
                            />

                          </div>

                          <div className="col-12 mt-3">
                              <label className="form-label">Reference-Num</label>
                              <div className="input-group input-group-dynamic">
                                <input
                                  placeholder='Remark'
                                  onChange={(e) => this.setState({ remark: e.target.value })}
                                  className="multisteps-form__input form-control form-control-mv"
                                  type="text"
                                />
                              </div>
                            </div>

                          {/* <div className="col-12 mt-3">
                            <TextField id="outlined-basic" label="Remark" variant="standard" onChange={(e) => this.setState({ remark: e.target.value })} fullWidth />
                          </div> */}

                          <button onClick={this.submit} className="btn bg-gradient-dark btn-md btn-mv mt-3 mb-5 ms-auto float-end">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={this.state.open}
                      onClose={(e)=>this.setState({open: false})}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={this.state.open}>
                        <Box sx={modalStyle}>
                          {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                          {/*  Text in a modal*/}
                          {/*</Typography>*/}
                          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                              <div className="modal-body">
                                  <div className="circle__box">
                                      <div className="circle__wrapper circle__wrapper--right">
                                          <div className="circle__whole circle__right"></div>
                                      </div>
                                      <div className="circle__wrapper circle__wrapper--left">
                                          <div className="circle__whole circle__left"></div>
                                      </div>
                                      <div className="circle__checkmark">
                                      </div>
                                  </div>
                                  <div style={{textAlign: 'center'}}>
                                      <h5>Budget Created Successfully.</h5>
                                      <h6><Link to='/team' style={{color: "grey"}}>Click here </Link> for go to Team dashboard.</h6>
                                  </div>
                              </div>
                          </Typography>
                        </Box>
                      </Fade>
                  </Modal>
                </>

                :
                <PageNotFound />
              }
            </Online>
            <Offline>
              <InternetConnection />
            </Offline>
          </>
          :
          <Redirect to={'/sign-in'} />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(CreateAnnualBudget);
