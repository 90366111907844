import React from "react";
import { connect } from "react-redux";
import {
  axios,
  sideBar,
  userLogin,
  modalStyle,
} from "../../../store/actions/actions";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { Link, Redirect } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class CreateTada extends React.Component {
  handleImageSelected = this.handleImageSelected.bind(this);
  uploadTadaImages = this.uploadTadaImages.bind(this);

  state = {
    visit_date: null,
    teams: null,
    visible: true,
    team_id: null,

    visit_obj: [],

    image: null,
    instanceImage: null,

    da1: 0,
    da2: 0,
    da3: 0,
    total: 0,

    redirect: false,
  };

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  componentDidMount() {
    this.loadTeam();
    this.toeknExpiryCheck();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "Update Response data----------": responseData });
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  loadTeam = () => {
    let endpoint = "teams/select-team";
    let token = this.props.user.token;
    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({ teams: responseData.teams });
      console.log({ "team response data---------": responseData.teams });
    });
  };

  getVisits = (date) => {
    const data = {
      visit_date: date,
      // team_id: selectedOption.value,
      visit_id: null,
    };
    let endpoint = "tada/get-tada-visit2";
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "tada visits ----------": responseData });
      responseData.visit.map((item) => {
        let obj = {
          visit_id: item.id,
          customer_id: item.customer.id,
          company: item.customer.company,
          working_status: item.working_status ? item.working_status : null,
          station:
            item.customer.station.station +
            ", " +
            item.customer.station.state.state,
          remark: null,
        };
        this.state.visit_obj.push(obj);
        this.setState({
          visit_obj: this.state.visit_obj,
          // team_id: selectedOption.value
        });
      });
      console.log({ "tada visits ----------": responseData });
      console.log({ "array ----------": this.state.visit_obj });
    });
  };

  saveValueToObject = (id, key, value) => {
    let obj_array = this.state.visit_obj;

    obj_array.map((i, index) => (i.visit_id === id ? (i[key] = value) : null));
    this.setState({
      visit_obj: obj_array,
    });
  };

  setAmount = (key, value) => {
    if (key === "da1") {
      this.setState({
        da1: value,
      });
      const myTimeout = setTimeout(this.gtotal, 100);
    }
    if (key === "da2") {
      this.setState({
        da2: value,
      });
      const myTimeout = setTimeout(this.gtotal, 100);
    }
    if (key === "da3") {
      this.setState({
        da3: value,
      });
      const myTimeout = setTimeout(this.gtotal, 100);
    }
  };

  gtotal = () => {
    console.log("working");
    this.setState({
      total:
        parseInt(this.state.da1) +
        parseInt(this.state.da2) +
        parseInt(this.state.da3),
    });
  };

  imageArray = [];
  instanceImageArray = [];
  removeDBImageArray = [];

  handleImageSelected(event) {
    console.log(...event.target.files);
    this.imageArray.push(...event.target.files);
    this.setState({
      image: this.imageArray,
    });
    const files = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      console.log(reader.result);
      this.instanceImageArray.push(reader.result);
      this.setState({
        instanceImage: this.instanceImageArray,
      });
    };
    reader.readAsDataURL(files);
  }

  removeImage(item) {
    this.instanceImageArray.pop(this.instanceImageArray[item]);
    this.imageArray.pop(this.imageArray[item]);
    this.setState({
      instanceImage: this.instanceImageArray,
      image: this.imageArray,
    });
  }

  submit = (images) => {
    const data = {
      team_id: this.state.team_id,
      obj: this.state.visit_obj,
      da1: this.state.da1,
      da2: this.state.da2,
      da3: this.state.da3,
      total: this.state.total,
      images: images,
    };

    let endpoint = `tada/`;
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "submit---------": responseData });
      if (responseData.success === true) {
        this.setState({
          redirect: true,
        });
      }
    });
  };

  uploadTadaImages(event) {
    event.preventDefault();
    let data = new FormData();
    if (this.imageArray.length !== 0) {
      for (const key of Object.keys(this.state.image)) {
        data.append("images", this.state.image[key]);
      }
    } else {
      data.append("images", null);
    }

    console.log(data, "===================");

    let endpoint = `tada/upload-slips`;
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "submit---------": responseData });
      if (responseData.success === true) {
        this.submit(responseData.id);
      }
    });
  }

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            {this.state.redirect === false ? (
              <>
                <div className="container-fluid d-none d-lg-block">
                  <div className="row justify-content-end">
                    <div
                      className={
                        this.props.sidebar === undefined ||
                        this.props.sidebar === true
                          ? "col-10"
                          : "col-12"
                      }
                    >
                      <div className="col-md-10 d-block m-auto mt-lg-0 mt-4">
                        {/* <!-- Card Basic Info -->*/}
                        <div className="card mt-4" id="basic-info">
                          <div className="card-body pt-0">
                            <div className="row mt-3 align-items-center">
                              <div className="col-4">
                                <div className="d-flex mt-2 p-1 align-items-center">
                                  <TextField
                                    id="date"
                                    label="Visit Date"
                                    type="date"
                                    size="small"
                                    // onChange={(e) => this.setState({visit_date: e.target.value})}
                                    onChange={(e) =>
                                      this.getVisits(e.target.value)
                                    }
                                    sx={{ width: 400 }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-4 mt-2">
                                {this.state.visit_date ? (
                                  <div className="input-group input-group-static is-filled">
                                    <Select
                                      placeholder="Select Team"
                                      options={
                                        this.state.teams
                                          ? this.state.teams.map(
                                              (item, index) => ({
                                                value: item.id,
                                                label: item.name,
                                              })
                                            )
                                          : null
                                      }
                                      onChange={this.getVisits}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="dataTable-container mt-4">
                              <table
                                className="table align-items-center mb-0 dataTable-table"
                                id="custe"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                      data-sortable=""
                                      // style={{width: "8%"}}
                                    >
                                      <a href="#" className="dataTable-sorter">
                                        S.NO
                                      </a>
                                    </th>
                                    <th
                                      className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                      data-sortable=""
                                      // style={{width: "20%"}}
                                    >
                                      <a href="#" className="dataTable-sorter">
                                        Customer
                                      </a>
                                    </th>
                                    <th
                                      className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                      data-sortable=""
                                      // style={{width: "15%"}}
                                    >
                                      <a href="#" className="dataTable-sorter">
                                        Station
                                      </a>
                                    </th>
                                    <th
                                      className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                      data-sortable=""
                                      // style={{width: "15%"}}
                                    >
                                      <a href="#" className="dataTable-sorter">
                                        Purpose
                                      </a>
                                    </th>
                                    <th
                                      className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                      data-sortable=""
                                      // style={{width: "15%"}}
                                    >
                                      <a href="#" className="dataTable-sorter">
                                        Remark
                                      </a>
                                    </th>
                                  </tr>
                                </thead>
                                {this.state.visit_obj
                                  ? this.state.visit_obj.map((item, index) => (
                                      <tbody>
                                        <tr>
                                          <td onClick={this.dv}>
                                            <p
                                              className="text-sm font-weight-normal mb-0"
                                              onClick={(e) =>
                                                console.log(
                                                  "----------->",
                                                  item.working_status
                                                )
                                              }
                                            >
                                              {index + 1}
                                            </p>
                                          </td>
                                          <td>
                                            <p className="text-sm font-weight-normal mb-0">
                                              {item.company}
                                            </p>
                                          </td>
                                          <td className="align-middle text-center text-sm w-15">
                                            <p className="mb-0 font-weight-normal text-sm">
                                              {item.station}
                                            </p>
                                          </td>
                                          <td className="align-middle text-center text-sm w-15">
                                            <p className="mb-0 font-weight-normal text-sm">
                                              {item.working_status
                                                ? item.working_status
                                                    .working_status.choice
                                                : null}
                                            </p>
                                          </td>
                                          <td className="align-middle text-center">
                                            <TextField
                                              className="col-11"
                                              id="standard-multiline-flexible"
                                              multiline
                                              maxRows={4}
                                              label="Remark"
                                              variant="filled"
                                              size="small"
                                              value={
                                                item.remark ? item.remark : ""
                                              }
                                              onChange={(e) =>
                                                this.saveValueToObject(
                                                  item.visit_id,
                                                  "remark",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))
                                  : null}
                              </table>

                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={this.state.open}
                                onClose={(e) => this.setState({ open: false })}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 500,
                                }}
                              >
                                <Fade in={this.state.open}>
                                  <Box sx={modalStyle}>
                                    {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                    {/*  Text in a modal*/}
                                    {/*</Typography>*/}
                                    <Typography
                                      id="transition-modal-description"
                                      sx={{ mt: 2 }}
                                    >
                                      <div className="modal-body">
                                        <div className="circle__box">
                                          <div className="circle__wrapper circle__wrapper--right">
                                            <div className="circle__whole circle__right"></div>
                                          </div>
                                          <div className="circle__wrapper circle__wrapper--left">
                                            <div className="circle__whole circle__left"></div>
                                          </div>
                                          <div className="circle__checkmark"></div>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <h5>Tada Created Successfully.</h5>
                                          <h6>
                                            <Link
                                              to="/tada"
                                              style={{ color: "grey" }}
                                            >
                                              Click here{" "}
                                            </Link>{" "}
                                            for go to TA/DA dashboard.
                                          </h6>
                                        </div>
                                      </div>
                                    </Typography>
                                  </Box>
                                </Fade>
                              </Modal>
                            </div>
                          </div>

                          {this.state.visit_obj.length !== 0 ? (
                            <>
                              <div className="row justify-content-around">
                                <div
                                  className="col-md-11"
                                  style={{
                                    border: "1px solid rgb(230 230 230)",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div
                                    className="row justify-content-end align-items-center"
                                    style={{ height: "120px" }}
                                  >
                                    {this.state.instanceImage
                                      ? this.state.instanceImage.map(
                                          (instImg, index) => (
                                            <div
                                              className="col-md-1 my-1 p-0 tada-img mx-3 d-flex justify-content-center"
                                              style={{ height: "110px" }}
                                            >
                                              <img
                                                className="img-fluid h-100"
                                                onClick={(e) =>
                                                  this.removeImage(index)
                                                }
                                                src={instImg}
                                                alt="Preview"
                                              />
                                              <i className="fa-solid fa-xmark tada-close"></i>
                                            </div>
                                          )
                                        )
                                      : null}
                                    <div className="col-md-1 d-flex justify-content-center align-items-center ">
                                      <label htmlFor="imgupload">
                                        <AddToPhotosIcon
                                          style={{ color: "green" }}
                                          fontSize="large"
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <input
                                id="imgupload"
                                className="file-upload"
                                type="file"
                                accept="image/*"
                                onChange={this.handleImageSelected}
                                style={{
                                  display: "none",
                                }}
                              />

                              <div className="row justify-content-around">
                                <div className="col-md-11">
                                  <div className="dataTable-container mt-4">
                                    <table
                                      className="mt-2"
                                      style={{ float: "right" }}
                                    >
                                      <tfoot>
                                        <tr>
                                          <td colSpan="3"></td>
                                          <td colSpan="3">
                                            DA Local Outstation(night stay)
                                          </td>
                                          <td colSpan="2">
                                            <span className="form-group">
                                              <input
                                                style={{
                                                  backgroundColor: "#f0f2f5",
                                                  padding: "9px",
                                                }}
                                                placeholder={"0"}
                                                type="number"
                                                className="form-control disable-input"
                                                onChange={(e) =>
                                                  this.setAmount(
                                                    "da1",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </span>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td colSpan="3"></td>
                                          <td colSpan="2">
                                            Fare in case of Outstation(night
                                            stay)
                                            <br /> Bus/Train ticket is mandatory
                                          </td>
                                          <td colSpan="2">
                                            <span className="form-group">
                                              <input
                                                style={{
                                                  backgroundColor: "#f0f2f5",
                                                  padding: "9px",
                                                }}
                                                placeholder={"0"}
                                                type="number"
                                                className="form-control disable-input"
                                                onChange={(e) =>
                                                  this.setAmount(
                                                    "da2",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </span>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td colSpan="3"></td>
                                          <td colSpan="3">
                                            Transport Courier Expenses if any
                                          </td>
                                          <td colSpan="2">
                                            <span className="form-group">
                                              <input
                                                style={{
                                                  backgroundColor: "#f0f2f5",
                                                  padding: "9px",
                                                }}
                                                placeholder={"0"}
                                                type="number"
                                                className="form-control disable-input"
                                                onChange={(e) =>
                                                  this.setAmount(
                                                    "da3",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </span>
                                          </td>
                                        </tr>

                                        <tr className="mt-2">
                                          <td colSpan="3"></td>
                                          <td colSpan="3">Grand Total</td>
                                          <td colSpan="2">
                                            <span className="form-group">
                                              <input
                                                style={{
                                                  backgroundColor: "#f0f2f5",
                                                  padding: "9px",
                                                }}
                                                type="number"
                                                disabled
                                                className="form-control disable-input"
                                                value={this.state.total}
                                              />
                                            </span>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>
                              </div>

                              <div className="row my-4">
                                <div className="col-12">
                                  <button
                                    className="btn bg-gradient-dark d-block m-auto"
                                    onClick={this.uploadTadaImages}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*<--------------------Mobile View-------------------->*/}

                <div className="dashOption-mv my-3 px-2 d-lg-none">
                  <div className="">
                    <div className="button-bar-mv d-flex">
                      <Link to="/tada" className="btn btn-mv ">
                        <img
                          src={require("../../../kavi/icons/dashbord.png")}
                          alt="btn-icon"
                        />
                        Dashboard
                      </Link>
                      <Link
                        to="/tada/create-tada "
                        className="btn btn-mv active"
                      >
                        <img
                          src={require("../../../kavi/icons/add.png")}
                          alt="btn-icon"
                        />
                        Create Ta/da
                      </Link>
                    </div>
                  </div>
                </div>
                <>
                  <div className="rstada-box py-1 d-lg-none">
                    <div
                      className="row mt-3 align-items-center"
                      style={{
                        width: "98%",
                        maxWidth: "100%",
                        marginInline: "auto",
                        marginBottom: "15px",
                      }}
                    >
                      <div className="col-6">
                        <div className="d-flex mt-2 p-1 align-items-center">
                          <TextField
                            id="date"
                            label="Visit Date"
                            type="date"
                            size="small"
                            onChange={(e) =>
                              this.setState({ visit_date: e.target.value })
                            }
                            sx={{ width: 400 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6 mt-2">
                        {this.state.visit_date ? (
                          <div className="input-group input-group-static is-filled">
                            <Select
                              placeholder="Select Team"
                              options={
                                this.state.teams
                                  ? this.state.teams.map((item, index) => ({
                                      value: item.id,
                                      label: item.name,
                                    }))
                                  : null
                              }
                              onChange={this.getVisits}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {this.state.visit_obj.length !== 0 ? (
                      <>
                        <div className="accordion">
                          {this.state.visit_obj
                            ? this.state.visit_obj.map((item, index) => (
                                <div className="accordion-item">
                                  <div
                                    className="accordion-item-header fw-bold"
                                    onClick={(e) =>
                                      document.getElementById(item.visit_id)
                                        .style.maxHeight === "0px"
                                        ? (document.getElementById(
                                            item.visit_id
                                          ).style.maxHeight = "169px")
                                        : (document.getElementById(
                                            item.visit_id
                                          ).style.maxHeight = "0px")
                                    }
                                  >
                                    <p>{index + 1 + "."}&nbsp;&nbsp;</p>
                                    <p className="fw-bold">Visit</p>
                                  </div>

                                  <div
                                    className="accordion-item-body"
                                    id={item.visit_id}
                                    style={{ maxHeight: "0px" }}
                                  >
                                    <div className="accordion-item-body-content">
                                      <p className="fw-bold mb-3">
                                        {item.company}
                                      </p>
                                      <div className="info">
                                        <div className="station d-flex">
                                          <p className="info-head fw-bold me-2 py-1">
                                            Station:
                                          </p>
                                          <p className="py-1">{item.station}</p>
                                        </div>
                                        <div className="station d-flex">
                                          <p className="info-head fw-bold py-1 me-2">
                                            Purpose:
                                          </p>
                                          {/*<p className="py-1">none</p>*/}
                                          <p className="py-1">{item.purpose}</p>
                                        </div>
                                        <div className="station d-flex">
                                          {/*<p className="info-head fw-bold  py-1 me-2">Remark:</p>*/}
                                          {/*<p className="py-1">Remark</p>*/}
                                          <TextField
                                            className="col-11"
                                            id="standard-multiline-flexible"
                                            multiline
                                            maxRows={4}
                                            label="Remark"
                                            variant="filled"
                                            size="small"
                                            value={
                                              item.remark ? item.remark : ""
                                            }
                                            onChange={(e) =>
                                              this.saveValueToObject(
                                                item.visit_id,
                                                "remark",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                        <div className="row justify-content-around">
                          <div
                            className="col-md-11"
                            style={{
                              border: "1px solid rgb(230 230 230)",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              className="row justify-content-end align-items-center"
                              style={{ height: "120px" }}
                            >
                              {this.state.instanceImage
                                ? this.state.instanceImage.map(
                                    (instImg, index) => (
                                      <div
                                        className="col-md-1 my-1 p-0 tada-img mx-3 d-flex justify-content-center"
                                        style={{
                                          height: "60px",
                                          width: "100px",
                                        }}
                                      >
                                        <img
                                          className="img-fluid h-100"
                                          onClick={(e) =>
                                            this.removeImage(index)
                                          }
                                          src={instImg}
                                          alt="Preview"
                                        />
                                        <i className="fa-solid fa-xmark tada-close"></i>
                                      </div>
                                    )
                                  )
                                : null}
                              <div className="col-md-1 d-flex justify-content-center align-items-center ">
                                <label htmlFor="imgupload">
                                  <AddToPhotosIcon
                                    style={{ color: "green" }}
                                    fontSize="large"
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <input
                          id="imgupload"
                          className="file-upload"
                          type="file"
                          accept="image/*"
                          onChange={this.handleImageSelected}
                          style={{
                            display: "none",
                          }}
                        />
                        <div className="billing w-100 px-4">
                          <div className="station d-flex w-100 justify-content-between my-2">
                            <p
                              className="info-head fw-bold py-1 me-2 m-0"
                              style={{ color: "#000", fontSize: "10px" }}
                            >
                              DA Local Outstation (night stay)
                            </p>

                            <span className="form-group">
                              <input
                                style={{
                                  color: "#000",
                                  background: "#bcbcbc",
                                  width: "80px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                }}
                                placeholder={"0"}
                                type="number"
                                className="form-control disable-input"
                                onChange={(e) =>
                                  this.setAmount("da1", e.target.value)
                                }
                              />
                            </span>
                          </div>
                          <div className="station d-flex w-100 justify-content-between my-2">
                            <p
                              className="info-head fw-bold py-1 me-2 m-0"
                              style={{ color: "#000", fontSize: "10px" }}
                            >
                              DA Local Outstation (night stay)
                            </p>

                            <span className="form-group">
                              <input
                                style={{
                                  color: "#000",
                                  background: "#bcbcbc",
                                  width: "80px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                }}
                                placeholder={"0"}
                                type="number"
                                className="form-control disable-input"
                                onChange={(e) =>
                                  this.setAmount("da2", e.target.value)
                                }
                              />
                            </span>
                          </div>
                          <div className="station d-flex w-100 justify-content-between my-2">
                            <p
                              className="info-head fw-bold py-1 me-2 m-0"
                              style={{ color: "#000", fontSize: "10px" }}
                            >
                              Fare in case of Outstation (nightstay)
                              <br />
                              Bus/Train ticket is mandatory
                            </p>

                            <span className="form-group">
                              <input
                                style={{
                                  color: "#000",
                                  background: "#bcbcbc",
                                  width: "80px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                }}
                                placeholder={"0"}
                                type="number"
                                className="form-control disable-input"
                                onChange={(e) =>
                                  this.setAmount("da3", e.target.value)
                                }
                              />
                            </span>
                          </div>
                          <div className="station d-flex w-100 justify-content-between my-2">
                            <p
                              className="info-head fw-bold py-1 me-2 m-0"
                              style={{ color: "#000", fontSize: "10px" }}
                            >
                              Grand Total
                            </p>

                            <span className="form-group">
                              <input
                                style={{
                                  color: "#000",
                                  background: "#bcbcbc",
                                  width: "80px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                }}
                                placeholder={"0"}
                                type="number"
                                className="form-control disable-input"
                                disabled
                                value={this.state.total}
                              />
                            </span>
                          </div>
                          <div className="station d-flex w-100 justify-content-center my-2">
                            <button
                              className="btn bg-primary text-white px-3 py-2"
                              style={{ marginBottom: "110px" }}
                              onClick={this.uploadTadaImages}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              </>
            ) : (
              <Redirect to={"/tada"} />
            )}
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTada);
