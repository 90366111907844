import React, { useEffect, useState } from 'react'
import { Redirect } from "react-router-dom";
import { axios, url } from '../../store/actions/actions';
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Crudsrf from './Crudsrf';

function Srfedit(props) {
    const [pages, setPages] = useState(0)
    const [employee, setEmployee] = useState(null)

    const [submitStatus, setsubmitStatus] = useState(false)
    const [imageChange, setImageChange] = useState(false)
    const [laoding, setLoading] = useState(false)

    let { id } = useParams();




    const [red, setred] = useState(false);



    // Now 'id' contains the value from the URL
    console.log('ID from URL:', id);

    // Form State 
    const [from, setFrom] = useState('');
    const [formData, setFormData] = useState({
        from: '',
        to: '',
        cc: [],
        category: '',
        purpose: '',
        message: '',
        file: null,
    });
    const [to, setTo] = useState('');
    const [cc, setCC] = useState([]);
    const [category, setCategory] = useState('');
    const [purpose, setPurpose] = useState('');
    const [message, setMessage] = useState('');
    const [file, setFile] = useState("");

    const [srflist, setSrfList] = useState("");



    useEffect(() => {
        getAllEmployees()
        getSrfInstance(id)
    }, [])



    const getAllEmployees = () => {
        setLoading(true)
        let token = props.user.token
        let endpoint = "common/load-employee"
        console.log(token)
        axios.get(endpoint, token).then((res) => {
            console.log(res.data, "Employee Data")
            setEmployee(res.data.employee)
            setLoading(false)
        })
    }
    const getSrfInstance = (srfId) => {
        let token = props.user.token
        let endpoint = "common/load-employee"
        let data = {
            "id": srfId
        }
        axios.put(endpoint, data, token).then((res) => {
            console.log(res.data.srf)
            setSrfList(res.data.srf)

            setFrom(res.data.srf.froms)
            setTo(res.data.srf.to)
            setCategory(res.data.srf.category)
            setCC(res.data.srf.cc)
            setPurpose(res.data.srf.purpose)
            setMessage(res.data.srf.message)
        })
    }





    // Handle Form Submit 
    const handleSubmit = (e) => {
        e.preventDefault();

        let token = props.user.token
        let endpoint = "common/load-employee"

        // Create a FormData object
        const formData = new FormData();
        formData.append('from', from.id);
        formData.append('to', to.id);
        // formData.append('cc', cc);
        formData.append('cc', JSON.stringify(cc));
        formData.append('category', category);
        formData.append('purpose', purpose);
        formData.append('message', message);
        formData.append('file', file);
        formData.append('idsrf', id);
        formData.append('imgchange', imageChange);


        setsubmitStatus(true)

        axios.put(endpoint, formData, token)
            .then((response) => {
                console.log('SRF form submitted successfully');
                setsubmitStatus(false)
                setSrfList(response.data.srf)
                setred(true)

            })
            .catch((error) => {
                // Handle any errors that occur during the request
                console.error('Error submitting SRF form:', error);
            });
    };


    return (
        <>
            {props.user.login === true ? (
                <>
                    {props.user.employee_profile === "executive" ? (

                        <div className="container-fluid homepage-view">
                            <div className="row justify-content-end">
                                <div
                                    className={
                                        props.sidebar === undefined ||
                                            props.sidebar === true
                                            ? "d-none d-lg-block col-10"
                                            : "d-none d-lg-block col-12"
                                    }
                                >
                                    <div className="container-fluid">
                                        <div className="row justify-content-center">
                                            <div className="col-md-6">
                                                <div className="card">
                                                    {laoding && <LinearProgress />}
                                                    <form onSubmit={handleSubmit}>
                                                        <div class="row p-3">
                                                            <div class="col-12">
                                                                <h6>SRF Form</h6>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="combo-box-demo"
                                                                        options={employee || []}
                                                                        getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                        fullWidth
                                                                        value={from} // Uncomment this line and set 'from' to the database value
                                                                        renderInput={(params) => <TextField {...params} label="From" />}
                                                                        onChange={(e, newValue) => {
                                                                            console.log(newValue);
                                                                            setFrom(newValue);
                                                                        }}
                                                                    />





                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="combo-box-demo"
                                                                        options={employee || []}
                                                                        value={to}
                                                                        getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                        fullWidth
                                                                        renderInput={(params) => <TextField {...params} label="To" />}
                                                                        onChange={(e, newValue) => {
                                                                            console.log(newValue); // newValue mein selected option ka object hoga
                                                                            setTo(newValue)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="tags-outlined"
                                                                        options={employee || []}
                                                                        value={cc}
                                                                        getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                        filterSelectedOptions
                                                                        fullWidth
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                label="CC"
                                                                                placeholder="CC"
                                                                            />
                                                                        )}
                                                                        onChange={(e, newValue) => {
                                                                            console.log(newValue); // newValue mein selected option ka object hoga
                                                                            setCC(newValue)
                                                                        }}
                                                                    />

                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField id="outlined-basics"
                                                                        // value={srflist ? srflist.category:""} 
                                                                        value={category}
                                                                        fullWidth label="Category" variant="outlined"
                                                                        onChange={(e) => {
                                                                            setCategory(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField id="outlined-basic" value={purpose} fullWidth label="Purpose" variant="outlined"
                                                                        onChange={(e) => {
                                                                            setPurpose(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField id="outlined-basic" type='file' fullWidth variant="outlined"
                                                                        onChange={(e) => {
                                                                            setFile(e.target.files[0])
                                                                            setImageChange(true)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField
                                                                        id="outlined-multiline-static"
                                                                        label="Message"
                                                                        multiline
                                                                        rows={4}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        value={message}
                                                                        onChange={(e) => {
                                                                            setMessage(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Button type='submit' disabled={laoding} variant="contained">
                                                                        Save Changes
                                                                        {submitStatus && <CircularProgress color='inherit' size={25} sx={{ marginLeft: "10px" }} />}
                                                                    </Button>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-lg-none">
                                    <div className="container-fluid d-lg-none  homepage-view">
                                        <div className="row justify-content-end">
                                            <div className="container-fluid mt-5">
                                                <div className="row gap-5">
                                                    <div className="col-md-6">
                                                        <div className="card">
                                                            {laoding && <LinearProgress />}
                                                            <form onSubmit={handleSubmit}>
                                                                <div class="row p-3">
                                                                    <div class="col-12">
                                                                        <h6>SRF Form</h6>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                options={employee || []}
                                                                                getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                                fullWidth
                                                                                value={from}
                                                                                renderInput={(params) => <TextField {...params} label="From" />}
                                                                                onChange={(e, newValue) => {
                                                                                    console.log(newValue); // newValue mein selected option ka object hoga
                                                                                    setFrom(newValue)
                                                                                }}
                                                                            />

                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                options={employee || []}
                                                                                value={to}
                                                                                getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                                fullWidth
                                                                                renderInput={(params) => <TextField {...params} label="To" />}
                                                                                onChange={(e, newValue) => {
                                                                                    console.log(newValue); // newValue mein selected option ka object hoga
                                                                                    setTo(newValue)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <Autocomplete
                                                                                multiple
                                                                                id="tags-outlined"
                                                                                options={employee || []}
                                                                                value={cc}
                                                                                getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                                filterSelectedOptions
                                                                                fullWidth
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        label="CC"
                                                                                        placeholder="CC"
                                                                                    />
                                                                                )}
                                                                                onChange={(e, newValue) => {
                                                                                    console.log(newValue); // newValue mein selected option ka object hoga
                                                                                    setCC(newValue)
                                                                                }}
                                                                            />

                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <TextField id="outlined-basics"
                                                                                // value={srflist ? srflist.category:""} 
                                                                                value={category}
                                                                                fullWidth label="Category" variant="outlined"
                                                                                onChange={(e) => {
                                                                                    setCategory(e.target.value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <TextField id="outlined-basic" value={purpose} fullWidth label="Purpose" variant="outlined"
                                                                                onChange={(e) => {
                                                                                    setPurpose(e.target.value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <TextField id="outlined-basic" type='file' fullWidth variant="outlined"
                                                                                onChange={(e) => {
                                                                                    setFile(e.target.files[0])
                                                                                    setImageChange(true)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <TextField
                                                                                id="outlined-multiline-static"
                                                                                label="Message"
                                                                                multiline
                                                                                rows={4}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                value={message}
                                                                                onChange={(e) => {
                                                                                    setMessage(e.target.value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <Button type='submit' variant="contained">Save Changes{submitStatus && <CircularProgress color='inherit' size={25} sx={{ marginLeft: "10px" }} />}</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {/* Agar Executive Naa ho Tab  */}
                            <div className="container-fluid d-lg-none  homepage-view">
                                <div className="row justify-content-end">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="card">
                                                    <form onSubmit={handleSubmit}>
                                                        <div class="row p-3">
                                                            <div class="col-12">
                                                                <h6>SRF Form from{category}</h6>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="combo-box-demo"
                                                                        options={employee || []}
                                                                        getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                        fullWidth
                                                                        renderInput={(params) => <TextField {...params} label="From" />}
                                                                        onChange={(e, newValue) => {
                                                                            console.log(newValue); // newValue mein selected option ka object hoga
                                                                            setFrom(newValue.id)
                                                                        }}
                                                                    />

                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="combo-box-demo"
                                                                        options={employee || []}
                                                                        getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                        fullWidth
                                                                        renderInput={(params) => <TextField {...params} label="To" />}
                                                                        onChange={(e, newValue) => {
                                                                            console.log(newValue); // newValue mein selected option ka object hoga
                                                                            setTo(newValue.id)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="tags-outlined"
                                                                        options={employee || []}
                                                                        getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                        filterSelectedOptions
                                                                        fullWidth
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                label="CC"
                                                                                placeholder="CC"
                                                                            />
                                                                        )}
                                                                        onChange={(e, newValue) => {
                                                                            console.log(newValue); // newValue mein selected option ka object hoga
                                                                            setCC(newValue)
                                                                        }}
                                                                    />

                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField id="outlined-basic" fullWidth label="Category" variant="outlined"
                                                                        onChange={(e) => {
                                                                            setCategory(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField id="outlined-basic" fullWidth label="Purpose" variant="outlined"
                                                                        onChange={(e) => {
                                                                            setPurpose(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField id="outlined-basic" type='file' fullWidth variant="outlined"
                                                                        onChange={(e) => {
                                                                            setFile(e.target.files[0])
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField
                                                                        id="outlined-multiline-static"
                                                                        label="Message"
                                                                        multiline
                                                                        rows={4}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        onChange={(e) => {
                                                                            setMessage(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Button type='submit' variant="contained">Create SRF</Button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <Redirect to={"/sign-in"} />
            )}


            {red && <Redirect to={"/srf"} />}

        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(Srfedit);