import React, {useState, useEffect} from "react";
import { Marker, Popup, TileLayer, Map } from "react-leaflet";
import L from 'leaflet'
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {axios, url} from "../../../store/actions/actions";
import FilterListIcon from "@mui/icons-material/FilterList";
import Autocomplete from "@mui/material/Autocomplete";
import LatLng from "../../GoogleMap/getLatLng";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NavigationIcon from '@mui/icons-material/Navigation';
import Box from "@mui/material/Box";
import MapActivityMarkedLocations from "../../GoogleMap/MapActivityMarkedLocations";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function TourPlan(props) {

    const [showfilter, setShowfilter] = useState(false);
    const [cities, setCities] = useState(null);
    const [cust, setCust] = useState(null);
    const [Test, setTest] = useState([]);
    const [tourCust, setTourCust] = useState([]);
    const [city, setCity] = useState(null);
    const [showtp, setshowtp] = useState(false);
    const [position, setPosition] = useState(false);
    const [navUrl, setNavUrl] = useState(null);

    var latLng = null

    const getLatLng = (data) => {
        // console.log('lat lng in trip-----------------------------=>', data)
        latLng = data
        setPosition(data)
    }

    useEffect(() => {
        document.getElementById('rs-btn-box').style.display='none'
        loadCities()
    }, []);

    const loadCities = () => {
        let endpoint = `common/get-cities`
        let token = props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Cities Response data---------->': responseData.cities})
                let jsonObject = responseData.cities.map(JSON.stringify);
                let uniqueSet = new Set(jsonObject);
                let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
                setCities(uniqueArray)
            })
    }

    const loadCustomers = () => {
        setShowfilter(false)
        let data = {
            city: city.station,
            position: position
        }
        console.log(data, "-=-=-=- Load Customer City")
        let endpoint = `common/get-cust-by-city`
        let token = props.user.token
        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Customers Response data---------->': responseData})
                setCust(responseData.customers)
                setTourCust(responseData.customers)
                // update1()
                // tourCustomers(responseData.customers)
                setTimeout(update1(responseData.customers), 10000)
            })
    }

    const tourCustomers = (cust) => {
        if (tourCust.includes(cust)===false){
            tourCust.push(cust)
            setTest([])
            let a1 = setTimeout(update1, 100)
        } else {
            // setTourCust(tourCust.filter((item)=>item.id!==cust.id))
            // setTest(null)
            let a2 = setTimeout((e)=>update2(tourCust.filter((item)=>item.id!==cust.id)), 300)
        }
    }

    const update1 = (obj) => {
        // console.log(this.state.tourCust)
        // setTest(this.state.tourCust)
        // setTest(tourCust)
        let k1 = ''
        obj.map((item, index)=>k1 = k1+(item.company.replace(" ", "+").replace(" ", "+").replace(" ", "+").replace(" ", "+"))+'+'+(item.address2__street).replace(", ", "").replace(" ", "+").replace(" ", "+").replace(" ", "+")+'/')
        let url = 'https://www.google.com/maps/dir/'+position.lat+',+'+position.lng+'/'+k1
        setNavUrl(url)
    }

    const update2 = (obj) => {
        console.log(obj)
        setTourCust(obj)
        setTest(obj)
        let k1 = ''
        obj.map((item, index)=>k1 = k1+(item.company.replace(" ", "+").replace(" ", "+").replace(" ", "+").replace(" ", "+"))+'+'+(item.address2__street).replace(", ", "").replace(" ", "+").replace(" ", "+").replace(" ", "+")+'/')
        let url = 'https://www.google.com/maps/dir/'+position.lat+',+'+position.lng+'/'+k1
        setNavUrl(url)
    }

    const test = () => {
        // let k1 = ''
        // tourCust.map((item, index)=>k1 = k1+(item.company.replace(" ", "+").replace(" ", "+").replace(" ", "+").replace(" ", "+"))+'+'+(item.address2__street).replace(", ", "/").replace(" ", "+").replace(" ", "+").replace(" ", "+"))
        // let url = 'https://www.google.com/maps/dir/'+position.lat+',+'+position.lng+'/'+k1
        // setNavUrl(url)
        console.log('url----->', navUrl)
    }

    const backToHome = () => {
        document.getElementById('rs-btn-box').style.display='block'
        props.history.push('/')
    }

    return (

            <>
            {latLng===null?
                <LatLng getLatLng={getLatLng}/>
            :null}

            {props.user.login===true?
                <>
                    <div className="d-lg-block d-none">
                        <div className="container-fluid homepage-view">
                            <div className="row justify-content-end">
                              <div
                                className={
                                  props.sidebar === undefined || props.sidebar === true
                                    ? "col-10"
                                    : "col-12"
                                }
                              >
                                  {/*<div className='row align-items-center'>*/}
                                      <div className="" >
                        <div className={'mb-1'}>

                        <div
                            className='tour-plan-lg'
                            style={{float: 'right', marginTop: '15px'}}
                            onClick={(e)=>setShowfilter(showfilter===true?false:true)}
                            >
                            <FilterListIcon style={{cursor: "pointer", marginLeft: "15px"}}/>
                        </div>

                        {showfilter===true?
                            <div className="card p-3" style={{width: "20%", position: "absolute", top: "141px", right: "43px", zIndex: '200000', boxShadow: "0px 0px 6px #bdbbbb"}}>

                              <div className="row p-1">
                                  <div className="col-12 p-1">
                                      {cities?
                                          <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            size={'small'}
                                            options={cities}
                                            getOptionLabel={(option) => option.station}
                                            onChange={(e, value)=>setCity(value)}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Select City"
                                              />
                                            )}
                                          />
                                      :null}
                                  </div>
                              </div>
                              <div className="d-flex justify-content-end mt-2">
                                  <button onClick={loadCustomers} className="btn my-0 bg-gradient-dark d-block" style={{padding: '7px 13px'}}>Apply</button>
                              </div>

                          </div>
                        :null}


                        <div className='' style={{height: '875px', zIndex: '50000'}}>
                            {position?
                              <Map  style={{height: '100%', width: '100%'}} center={city?[city.lat, city.lng]:[position.lat, position.lng]} height={'30'} zoom={14} scrollWheelZoom={false}>
                                  <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                  />
                                  {cust?cust.map((item, index)=>(
                                      item.address_by_location__geolocation?
                                  <Marker key={index} position={[item.address_by_location__geolocation.split(",")[0], item.address_by_location__geolocation.split(",")[1]]} >
                                    <Popup>
                                        <h5 >{item.company}</h5>
                                        <span style={{whiteSpace: 'break-spaces'}}>{item.address2__street}</span>
                                        <br/>
                                        <br/>
                                        <div style={{marginBottom: '25px'}}>
                                        <span style={{color: 'green', float: 'left', fontSize: '15px'}}>{item.distance} Km</span>
                                        {Test.includes(item)===true?
                                           <RemoveCircleIcon onClick={(e)=>tourCustomers(item)} style={{color: 'red', float: 'right', fontSize: "28px", marginTop: "-9px", marginRight: "-15px"}}/>
                                            :
                                            <AddCircleIcon onClick={(e)=>tourCustomers(item)} style={{color: 'green', float: 'right', fontSize: "28px", marginTop: "-9px", marginRight: "-15px"}}/>
                                        }
                                        </div>
                                    </Popup>
                                  </Marker>
                                  :null)):
                                      <Marker position={[position.lat, position.lng]} >

                                      </Marker>
                                  }
                              </Map>
                                :null}
                        </div>


                            <section id="ansh"
                                 style={{
                                     position: 'fixed',
                                     bottom: '0',
                                     maxHeight: showtp===true?'550px':'68px',
                                     right: '0',
                                     overflowY: 'overlay',
                                     overflowX: 'hidden',
                                     padding: '0px 4px',
                                     zIndex: '100000'
                            }}
                            >
                                <div className="container-fluid p-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header py-3 bg-success">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h4 className="text-white mb-0 ">Tour Plan</h4>
                                                        <i className="material-icons-round opacity-10  text-white"
                                                           onClick={(e)=>setshowtp(showtp===true?false:true)}
                                                           id="expand_icon" style={{fontSize: "40px", transform: showtp===true?'rotate(0deg)':'rotate(180deg)'}}>expand_more</i>
                                                    </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    {tourCust?tourCust.map((item, index)=>(
                                                    <div
                                                        className="d-flex p-2 px-3 border-bottom justify-content-between align-items-center" key={index}>
                                                        <div className="w-100">
                                                            <h6 className="mb-0">{item.company}</h6>
                                                            <p className="mb-0">{item.address2__street}</p>
                                                            <p className="text-success mb-0">{item.distance} Km</p>
                                                        </div>
                                                        <div>
                                                            <i className="material-icons-round opacity-10"
                                                               style={{fontSize: "25px"}} onClick={(e)=>tourCustomers(item)}>delete_outline</i>
                                                        </div>
                                                    </div>
                                                        )):null}

                                                    {tourCust.length!==0?
                                                    <a href={navUrl}>
                                                    <div
                                                        style={{background: "#00c4ff"}}
                                                        className="d-flex p-2 px-3 border-bottom justify-content-between align-items-center">
                                                        <div className="w-100">
                                                            <h2 style={{textAlign: "center", color: "white", marginLeft: "32px"}} className="mb-0">Start Navigation</h2>
                                                        </div>
                                                        <div>
                                                            
                                                                <NavigationIcon style={{
                                                                transform: "rotate(45deg)",
                                                                color: 'aliceblue',
                                                                fontSize: '42px',
                                                                }}/>
                                                           
                                                        </div>
                                                    </div>
                                                    </a>
                                                        :null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                                  {/*</div>*/}
                              </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-lg-none" style={{paddingLeft: '8px', paddingRight: '8px'}}>
                        <div className={'mb-1'}>
                        <div className="" style={{float: 'left', marginBottom: '8px'}}>
                            <div className="f-btn">
                                <button onClick={backToHome}>Back</button>
                            </div>
                        </div>

                        <div
                            style={{float: 'right', marginTop: '15px'}}
                            onClick={(e)=>setShowfilter(showfilter===true?false:true)}
                            >
                            <FilterListIcon style={{cursor: "pointer", marginLeft: "15px"}}/>
                        </div>

                        {showfilter===true?
                            <div className="card p-4" style={{width: "96%", position: "absolute", top: "117px", right: "7px", zIndex: '200000', boxShadow: "0px 0px 6px #bdbbbb"}}>

                              <div className="row p-1">
                                  <div className="col-12 p-1">
                                      {cities?
                                          <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={cities}
                                            getOptionLabel={(option) => option.station}
                                            onChange={(e, value)=>setCity(value)}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Select City"
                                              />
                                            )}
                                          />
                                      :null}
                                  </div>
                              </div>
                              <div className="d-flex justify-content-end mt-3">
                                  <button onClick={loadCustomers} className="btn my-0 bg-gradient-dark d-block">Submit</button>
                              </div>

                          </div>
                        :null}


                        <div className='' style={{height: '700px', zIndex: '50000'}}>
                            {position?
                              <Map  style={{height: '100%', width: '100%'}} center={city?[city.lat, city.lng]:[position.lat, position.lng]} height={'30'} zoom={14} scrollWheelZoom={false}>
                                  <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                  />
                                  {cust?cust.map((item, index)=>(
                                      item.address_by_location__geolocation?
                                  <Marker key={index} position={[item.address_by_location__geolocation.split(",")[0], item.address_by_location__geolocation.split(",")[1]]} >
                                    <Popup>
                                        <h5 >{item.company}</h5>
                                        <span style={{whiteSpace: 'break-spaces'}}>{item.address2__street}</span>
                                        <br/>
                                        <br/>
                                        <div style={{marginBottom: '25px'}}>
                                        <span style={{color: 'green', float: 'left', fontSize: '15px'}}>{item.distance} Km</span>
                                        {Test.includes(item)===true?
                                           <RemoveCircleIcon onClick={(e)=>tourCustomers(item)} style={{color: 'red', float: 'right', fontSize: "28px", marginTop: "-9px", marginRight: "-15px"}}/>
                                            :
                                            <AddCircleIcon onClick={(e)=>tourCustomers(item)} style={{color: 'green', float: 'right', fontSize: "28px", marginTop: "-9px", marginRight: "-15px"}}/>
                                        }
                                        </div>
                                    </Popup>
                                  </Marker>
                                  :null)):
                                      <Marker position={[position.lat, position.lng]} >

                                      </Marker>
                                  }
                              </Map>
                                :null}
                        </div>


                            <section id="ansh"
                                 style={{
                                     position: 'fixed',
                                     bottom: '0',
                                     maxHeight: showtp===true?'550px':'68px',
                                     width: '100%',
                                     left: '0',
                                     overflowY: 'overlay',
                                     overflowX: 'hidden',
                                     padding: '0px 4px',
                                     zIndex: '100000'
                            }}
                            >
                                <div className="container-fluid p-0 ">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header py-3 bg-success">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h4 className="text-white mb-0 ">Tour Plan</h4>
                                                        <i className="material-icons-round opacity-10  text-white"
                                                           onClick={(e)=>setshowtp(showtp===true?false:true)}
                                                           id="expand_icon" style={{fontSize: "40px", transform: showtp===true?'rotate(0deg)':'rotate(180deg)'}}>expand_more</i>
                                                    </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    {tourCust?tourCust.map((item, index)=>(
                                                    <div
                                                        className="d-flex p-2 px-3 border-bottom justify-content-between align-items-center" key={index}>
                                                        <div className="w-100">
                                                            <h6 className="mb-0">{item.company}</h6>
                                                            <p className="mb-0">{item.address2__street}</p>
                                                            <p className="text-success mb-0">{item.distance} Km</p>
                                                        </div>
                                                        <div>
                                                            <i className="material-icons-round opacity-10"
                                                               style={{fontSize: "25px"}} onClick={(e)=>tourCustomers(item)}>delete_outline</i>
                                                        </div>
                                                    </div>
                                                        )):null}

                                                    {tourCust.length!==0?
                                                    <a href={navUrl}>
                                                    <div
                                                        style={{background: "#00c4ff"}}
                                                        className="d-flex p-2 px-3 border-bottom justify-content-between align-items-center">
                                                        <div className="w-100">
                                                            <h2 style={{textAlign: "center", color: "white", marginLeft: "32px"}} className="mb-0">Start Navigation</h2>
                                                        </div>
                                                        <div >
                                                            
                                                                <NavigationIcon style={{
                                                                transform: "rotate(45deg)",
                                                                color: 'aliceblue',
                                                                fontSize: '42px',
                                                                }}/>
                                                          
                                                        </div>
                                                    </div>
                                                      </a>
                                                        :null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>
    );
}

const mapStateToProps = (state) => ({
    user:state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(TourPlan);

