import React from "react";
import '../Customer/Style';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {axios, url} from "../../../store/actions/actions";
import Loader from "../../common/Loader/Loader";

class VisitView extends React.Component {

    state = {
        visit: null,
        samples: null
    }

    componentDidMount() {
        this.loadVisit()
    }

    my_array = []

    loadVisit = () => {
        let pathname = window.location.pathname;

        let pathurl = pathname.split("/");
        let path = pathurl[pathurl.length - 1];
        let endpoint = `visit/${path}`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({
                    visit: [responseData.visit],
                    samples: responseData.samples
                })
                console.log({'Visit response data--------->':responseData})
            });
    }

  render() {
    return (
        <>
            {this.props.user.login===true?
                <>
                    {this.state.visit?this.state.visit.map((vt, index)=>(
                        <>
                        <div className="d-none d-lg-block container-fluid">
                 <div className="row justify-content-end">
                    <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-9 me-5" : "col-10 me-5"}>
                        <>
                            <div className="card mt-4">
                        <div className="card-body p-3">
                          <div id="basic-info">
                            <div>
                              <div><h5 className="font-weight-bolder mb-2">Basic Info</h5></div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Employee</label>
                                          <input
                                              value={vt.employee.last_name?vt.employee.first_name+' '+vt.employee.last_name:vt.employee.first_name}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Serial no.</label>
                                          <input
                                              value={vt.serial_number}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Date</label>
                                          <input
                                              value={vt.date}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Customer</label>
                                          <input
                                              value={vt.customer.company}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Station</label>
                                          <input
                                              value={vt.station?vt.station.station+'('+vt.station.state.state+')':null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Board</label>
                                          <input
                                              value={vt.board?vt.board.boards:null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Student</label>
                                          <input
                                              value={vt.student_str}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Purpose</label>
                                          <input
                                              value={vt.purpose}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Visit Type</label>
                                          <input
                                              value={vt.is_old===true?'Old':'New'}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                            <div className="card mt-4">
                        <div className="card-body p-3">
                          <div id="basic-info">
                            <div>
                              <div><h5 className="font-weight-bolder mb-2">Contact Details</h5></div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Contact Person Name</label>
                                          <input
                                              value={vt.contact_person_name}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Contact no.</label>
                                          <input
                                              value={vt.contact_no}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Email</label>
                                          <input
                                              value={vt.email}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                            <div className="card mt-4">
                        <div className="card-body p-3">
                          <div id="basic-info">
                            <div>
                              <div><h5 className="font-weight-bolder mb-2">Working Status</h5></div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Employee</label>
                                          <input
                                              value={vt.working_status?
                                                      vt.working_status.employee?
                                                          vt.working_status.employee.last_name?
                                                              vt.working_status.employee.first_name+vt.working_status.employee.last_name
                                                          :vt.working_status.employee.first_name
                                                      :null
                                                     :null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Status</label>
                                          <input
                                              value={vt.working_status?vt.working_status.working_status?vt.working_status.working_status.choice:null:null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-12 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Questionnaire</label>
                                          <input
                                              value={vt.working_status?vt.working_status.questionnaire.map((qst, index)=>(
                                                  qst.option+' '
                                              )):null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-12 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Remark</label>
                                          <audio className="mt-5" src={vt.working_status?url+vt.working_status.remark:null} controls></audio>
                                    </div>
                                  </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      {this.state.samples?
                        <div className="card mt-4 mb-5">
                        <div className="card-body p-3">
                          <div id="basic-info">
                            <div>
                              <div><h5 className="font-weight-bolder mb-2">Sampling</h5></div>
                            </div>
                            <div className="card-body pt-0">

                          <div className="table-responsive">
                              <table className="table mt-2">
                             <thead>
                                <tr>
                                   <th >S. No.</th>
                                   <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                   <th>From</th>
                                   <th>To</th>
                                   <th>&nbsp;&nbsp;Qty.&nbsp;&nbsp;</th>
                                   <th>&nbsp;&nbsp;Price&nbsp;&nbsp;</th>
                                </tr>
                             </thead>
                             <tbody>
                             {this.state.samples.map((item, index) => (

                                        <tr>
                                           <td width="7%" className="text-center">
                                              <div ><span className="">{index + 1}</span>.</div>
                                           </td>
                                           <td width="43%">
                                              <p className="text-sm font-weight-normal mb-0">{item.book[0].title.title}</p>
                                           </td>

                                           <td width="10%">
                                               <p className="text-sm font-weight-normal mb-0">{item.book[0].classes.class_name}</p>
                                           </td>

                                           <td width="10%">
                                                 <p className="text-sm font-weight-normal mb-0">{item.book.length!==0?item.book[item.book.length-1].classes.class_name:null}</p>
                                           </td>

                                           <td width="10%">
                                              <p className="text-center text-sm font-weight-normal mb-0">{item.quantity}</p>
                                           </td>

                                           <td width="10%" className="text-center">
                                              <p className="text-center text-sm font-weight-normal mb-0">{item.price}</p>
                                           </td>
                                        </tr>

                              ))}
                             </tbody>
                          </table>
                          </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      :null}
                        </>
                    </div>
                  </div>
                 </div>
                        <div className="d-lg-none container-fluid">
                 <div className="row">
                    <div className='col-12'>
                        <>
                            <div className="card mt-4">
                        <div className="card-body p-3">
                          <div id="basic-info">
                            <div>
                              <div><h5 className="font-weight-bolder mb-2">Basic Info</h5></div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Employee</label>
                                          <input
                                              value={vt.employee.last_name?vt.employee.first_name+' '+vt.employee.last_name:vt.employee.first_name}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Serial no.</label>
                                          <input
                                              value={vt.serial_number}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Date</label>
                                          <input
                                              value={vt.date}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Customer</label>
                                          <input
                                              value={vt.customer.company}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Station</label>
                                          <input
                                              value={vt.station?vt.station.station+'('+vt.station.state.state+')':null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Board</label>
                                          <input
                                              value={vt.board?vt.board.boards:null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Student</label>
                                          <input
                                              value={vt.student_str}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Purpose</label>
                                          <input
                                              value={vt.purpose}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Visit Type</label>
                                          <input
                                              value={vt.is_old===true?'Old':'New'}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                            <div className="card mt-4">
                        <div className="card-body p-3">
                          <div id="basic-info">
                            <div>
                              <div><h5 className="font-weight-bolder mb-2">Contact Details</h5></div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Contact Person Name</label>
                                          <input
                                              value={vt.contact_person_name}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Contact no.</label>
                                          <input
                                              value={vt.contact_no}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Email</label>
                                          <input
                                              value={vt.email}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                            <div className="card mt-4">
                        <div className="card-body p-3">
                          <div id="basic-info">
                            <div>
                              <div><h5 className="font-weight-bolder mb-2">Working Status</h5></div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Employee</label>
                                          <input
                                              value={vt.working_status?
                                                      vt.working_status.employee?
                                                          vt.working_status.employee.last_name?
                                                              vt.working_status.employee.first_name+vt.working_status.employee.last_name
                                                          :vt.working_status.employee.first_name
                                                      :null
                                                     :null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Status</label>
                                          <input
                                              value={vt.working_status?vt.working_status.working_status?vt.working_status.working_status.choice:null:null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-12 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Questionnaire</label>
                                          <input
                                              value={vt.working_status?vt.working_status.questionnaire.map((qst, index)=>(
                                                  qst.option+' '
                                              )):null}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                          />
                                    </div>
                                  </div>
                                  <div className="col-12 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Remark</label>
                                          <audio className="mt-5" src={vt.working_status?url+vt.working_status.remark:null} controls></audio>
                                    </div>
                                  </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      {this.state.samples?
                        <div className="card mt-4" style={{marginBottom: '7rem'}}>
                        <div className="card-body p-3">
                          <div id="basic-info">
                            <div>
                              <div><h5 className="font-weight-bolder mb-2">Sampling</h5></div>
                            </div>
                            <div className="card-body pt-0">

                          <div className="table-responsive">
                              <table className="table mt-2">
                             <thead>
                                <tr>
                                   <th >S. No.</th>
                                   <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                   <th>From</th>
                                   <th>To</th>
                                   <th>&nbsp;&nbsp;Qty.&nbsp;&nbsp;</th>
                                   <th>&nbsp;&nbsp;Price&nbsp;&nbsp;</th>
                                </tr>
                             </thead>
                             <tbody>
                             {this.state.samples.map((item, index) => (

                                        <tr>
                                           <td width="7%" className="text-center">
                                              <div ><span className="">{index + 1}</span>.</div>
                                           </td>
                                           <td width="43%">
                                              <p className="text-sm font-weight-normal mb-0">{item.book[0].title.title}</p>
                                           </td>

                                           <td width="10%">
                                               <p className="text-sm font-weight-normal mb-0">{item.book[0].classes.class_name}</p>
                                           </td>

                                           <td width="10%">
                                                 <p className="text-sm font-weight-normal mb-0">{item.book.length!==0?item.book[item.book.length-1].classes.class_name:null}</p>
                                           </td>

                                           <td width="10%">
                                              <p className="text-center text-sm font-weight-normal mb-0">{item.quantity}</p>
                                           </td>

                                           <td width="10%" className="text-center">
                                              <p className="text-center text-sm font-weight-normal mb-0">{item.price}</p>
                                           </td>
                                        </tr>

                              ))}
                             </tbody>
                          </table>
                          </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      :null}
                        </>
                    </div>
                  </div>
                 </div>
                            </>
                    )):
                        <Loader/>
                    }
                </>
            :
                <Redirect to={'/sign-in'}/>
            }
        </>

    );
  }
}

const mapStateToProps = (state) => ({
  user:state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(VisitView);
