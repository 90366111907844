import React, { Component } from 'react'
import Select from 'react-select'

class CustomerSearch extends React.Component {

    handleChange = (selectedOption) => {
        this.props.parentCallback({'value': selectedOption.value, "type": this.props.placeholder})
    };

    render(){
    return(
        <>
            {this.props.company === null || this.props.company === undefined ?
                <Select
                  placeholder={this.props.placeholder}

                  options={
                      this.props.customer?this.props.customer.map((item, index)=>(
                          {value: item, label: item.company}
                      )):null
                  }

                  onChange={this.handleChange}
                />
            :
                <Select
                    placeholder={this.props.placeholder}

                    value={{label: this.props.company, value: this.props.company}}

                    options={
                    this.props.customer?this.props.customer.map((item, index)=>(
                        {value: item, label: item.company}
                    )):null
                }

                    onChange={this.handleChange}
                    />
            }
        </>
    )
  }
}

export default CustomerSearch;