import React from "react";
import {axios, userLogin, modalStyle} from "../../store/actions/actions";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from "@mui/material/TextField";
import PageNotFound from "../common/PageNotFound";

class SignUp extends React.Component {

    state = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        pass1: null,
        pass2: null,

        c_name: null,
        c_phone: null,
        website: null,
        industry: null,
        size: null,

        msg: null,
        open: false
    }

    submit = () => {
        this.setState({open: false, msg: null})
        const data = {
            // user details
            f_name: this.state.first_name,
            l_name: this.state.last_name===null?'':this.state.last_name,
            email: this.state.email,
            phone: this.state.phone,

            pass1: this.state.pass1,
            pass2: this.state.pass2,

            // company
            c_name: this.state.c_name,
            website: this.state.website,
            c_phone: this.state.c_phone,
            industry: this.state.industry,
            size: this.state.size,

            host: window.location.hostname
        }

        console.log(data)

        let endpoint = `client/`
        axios
            .post(endpoint, data)
            .then((res) => {
                let responseData = res.data;
                if(responseData.success===true){
                    let red = `${window.location.protocol}//${this.state.c_name}.${window.location.host}`
                    window.location.replace(red)
                } else {
                    this.setState({
                        open: true,
                        msg: responseData.msg
                    })
                }
                console.log({ 'response data---------': responseData })
            })
    }

    PrvNxt = (data) => {
    console.log(data)
    if (data === 'user') {
        document.getElementById("show-user").style.display = "block"
        document.getElementById("show-company").style.display = "none"
    }

    if (data === 'company') {
      document.getElementById("show-company").style.display = "block"
      document.getElementById("show-user").style.display = "none"
    }
  }

    render() {
            let schema = window.location.hostname.slice(0,-10)
        return(
            schema===''?
            <>
                {this.props.user.login===true?
                    <Redirect to={'/'}/>
                :
                    <>
                    <div className="main-content  mt-0">
                    <div className="page-header align-items-start min-height-300 m-3 border-radius-xl"
                         style={{backgroundImage: "url('https://images.unsplash.com/photo-1491466424936-e304919aada7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1949&q=80')"}}>
                      <span className="mask bg-gradient-dark opacity-6"></span>
                    </div>
                    <div className="container mb-4">
                      <div className="row mt-lg-n12 mt-md-n12 mt-n12 justify-content-center">
                        <div className="col-xl-4 col-lg-5 col-md-7 mx-auto" style={{
                                width: '55%'
                        }}>
                          <div className="card mt-8">
                            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">

                                <div className="bg-gradient-success shadow-success border-radius-lg py-3 pe-1 text-center py-4" style={{backgroundImage: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))'}}>
                                <h4 className="font-weight-bolder text-white mt-1">Join us today</h4>
                                <p className="mb-1 text-sm text-white">Enter your and your company details for register.</p>
                              </div>

                            </div>
                            <div className="card-body">
                                {/*-----------------------User Information----------------------*/}
                                <div id="show-user" role="row" className="text-start">

                                <div className="input-group input-group-static mb-4">
                                    <div className="col-6">
                                  <label>First Name</label>
                                  <input type="email" className="form-control lower-input" placeholder="John"
                                         onChange={(e)=>this.setState({first_name: e.target.value})}
                                  />
                                </div>
                                    <div className="col-6">
                                  <label>Last Name</label>
                                  <input type="email" className="form-control lower-input" placeholder="Doe"
                                         onChange={(e)=>this.setState({last_name: e.target.value})}
                                  />
                                </div>
                                </div>
                                <div className="input-group input-group-static mb-4">
                                  <div className="col-6">
                                  <label>Email</label>
                                  <input type="email" className="form-control lower-input" placeholder="john@email.com"
                                         onChange={(e)=>this.setState({email: e.target.value})}
                                  />
                                  </div>
                                  <div className="col-6">
                                  <label>Phone</label>
                                  <input type="tel" className="form-control lower-input" placeholder="+91 705 566 1822"
                                         onChange={(e)=>this.setState({phone: e.target.value})}
                                  />
                                  </div>
                                </div>
                                <div className="input-group input-group-static mb-4">
                                  <div className="col-6">
                                  <label>Password</label>
                                  <input type="password" className="form-control lower-input" placeholder="•••••••••••••"
                                         onChange={(e)=>this.setState({pass1: e.target.value})}
                                  />
                                  </div>
                                  <div className="col-6">
                                  <label>Confirm Password</label>
                                  <input type="password" className="form-control lower-input" placeholder="•••••••••••••"
                                         onChange={(e)=>this.setState({pass2: e.target.value})}
                                  />
                                  </div>
                                </div>
                                  <div className="button-row d-flex mt-4">
                                  <span
                                      style={{
                                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                          color: 'rgb(123, 128, 154)',
                                          fontSize: '0.875rem',
                                          fontWeight: '300'
                                      }}
                                  >Already have an account?
                                      <Link to="/sign-in" style={{fontWeight: '600', color: 'rgb(26, 115, 232)'}}>&nbsp; Sign in</Link>
                                  </span>
                                                            <button
                                                className="btn bg-gradient-dark ms-auto mb-0"
                                                type="button"
                                                title="Send"
                                                                onClick={(e) => this.PrvNxt('company')}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>

                              </div>

                                {/*-----------------------Company Information----------------------*/}
                                <div id="show-company" style={{display: 'none'}}
                                >
                                    {/* <!-- <p className="mb-0 text-sm">Optional informations</p> --> */}
                                    <div className="multisteps-form__content mt-3">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <TextField id="standard-basic" onChange={(e)=>this.setState({c_name: e.target.value})} label="Company Name" variant="standard" fullWidth/>
                                            </div>
                                            <div className="col-6">
                                                <TextField id="standard-basic" onChange={(e)=>this.setState({c_phone: e.target.value})} label="Phone" variant="standard" fullWidth/>
                                            </div>
                                            <div className="col-6">
                                                <TextField id="standard-basic" onChange={(e)=>this.setState({website: e.target.value})} label="Website" variant="standard" fullWidth/>
                                            </div>
                                            <div className="col-6 mt-3">
                                                <TextField id="standard-basic" onChange={(e)=>this.setState({industry: e.target.value})} label="Industry" variant="standard" fullWidth/>
                                            </div>
                                            <div className="col-6 mt-3">
                                                <TextField id="standard-basic" onChange={(e)=>this.setState({size: e.target.value})} label="Size" variant="standard" fullWidth/>
                                            </div>
                                        </div>
                                        <div className="button-row d-flex mt-5">
                                            <button
                                                onClick={(e) => this.PrvNxt('user')}
                                                className="btn bg-gradient-light mb-0 js-btn-prev"
                                                type="button"
                                                title="Prev"
                                            >
                                                Prev
                                            </button>
                                            <button
                                                className="btn bg-gradient-dark ms-auto mb-0"
                                                type="button"
                                                title="Send"
                                                onClick={this.submit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.open}
                        onClose={(e) => this.setState({ open: false })}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.open}>
                            <Box sx={modalStyle}>
                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                    <div className="modal-body">
                                        <div className="d-flex justify-content-center" style={{ textAlign: 'center' }}>
                                            <h5 style={{color: 'red'}}>{this.state.msg}</h5>
                                        </div>
                                    </div>
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>
                    </>
                }
            </>
            :
            <PageNotFound/>

        )
    }
}

const mapStateToProps = state =>({
    user:state.userData.userData
})

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps,mapDispatchToProps)(SignUp)

