import React from "react";
import MarkedLocations from "../../GoogleMap/MarkedLocations";
import {axios, url} from "../../../store/actions/actions";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import MapActivity from "./MapActivity";
import MapActivityMarkedLocations from "../../GoogleMap/MapActivityMarkedLocations";


class ProjectView extends React.Component {

    state = {
        all_employee: null,
        employee: null,
        f_date: null,
        t_date: null,
        s_date: null,
        station: null,
        marker: null,
        attendance: null
    }

    componentDidMount() {
        this.loadSelectEmployees()
    }

    loadSelectEmployees = () => {
        let endpoint = `/account/get-employees`
        let token = this.props.user.token

        axios
          .get(endpoint, token)
          .then((res)=>{
            let responseData = res.data;
            this.setState({
              all_employee: responseData.employee
            })
            console.log({'Employee data---------':responseData.employee})
          })
    }

    // getMarkers = (station) => {
    //     const array = []
    //     station.map((station, index)=>(
    //       station.customer?station.customer.map((cust, index)=>(
    //           array.push({lat: cust.address_by_location?cust.address_by_location.geolocation.split(",")[0]:null, lng: cust.address_by_location?cust.address_by_location.geolocation.split(",")[1]:null})
    //       )):null
    //     ))

    //     this.setState({marker: array, show: true})
    // }

    submit = () => {
        const data = {
            emp: this.state.employee,
            s_date: this.state.s_date,
            f_date: this.state.f_date,
            t_date: this.state.t_date,
        }

        let endpoint = `/employee/map-activity`
        let token = this.props.user.token

        axios
          .post(endpoint, data, token)
          .then((res)=>{
            let responseData = res.data;
            console.log({'Map Activity data--------->':responseData.attendance})
            this.setState({
                station: responseData.station,
                marker: responseData.customer,
                attendance: responseData.attendance,
            })
          })
    }

    test = () => {
        let dt = "25.34097770000001,82.84253029999999"
        console.log({'Map Activity data--------->':dt.split(',')[0]})
        console.log({'Map Activity data--------->':dt.split(',')[1]})
    }

  render() {
    return (
            <>
            {this.props.user.login===true?
                <>
                    <div className="d-lg-block d-none">
                        <div className="container-fluid homepage-view">
                            <div className="row justify-content-end">
                              <div
                                className={
                                  this.props.sidebar === undefined || this.props.sidebar === true
                                    ? "col-10"
                                    : "col-12"
                                }
                              >
                                  <div className='row align-items-center' >

                                      {this.state.all_employee?
                                          <div className="col-3">
                                              <div className="form-check-inline w-100" >
                                                  <>
                                                    <Autocomplete
                                                        disablePortal
                                                        size='small'
                                                        id="combo-box-demo"
                                                        options={this.state.all_employee}
                                                        onChange={(e, value)=>this.setState({employee: value.id})}
                                                        getOptionLabel={(option) => option.last_name?option.first_name+' '+option.last_name:option.first_name}
                                                        renderOption={(props, option) => (
                                                            <Box component="li" sx={{
                                                                '& > img': {
                                                                    mr: 2,
                                                                    flexShrink: 0
                                                                }
                                                            }} {...props}>
                                                                <img
                                                                    style={{borderRadius: '50%'}}
                                                                    // loading="lazy"
                                                                    width="30"
                                                                    src={option.pic?url + option.pic:url+'/static/agent.png'}
                                                                    // srcSet={`${url + option.pic} 2x`}
                                                                    alt=""
                                                                />
                                                                {option.last_name?option.first_name+' '+option.last_name:option.first_name}
                                                            </Box>
                                                        )}
                                                        renderInput={(params) =>
                                                            <TextField {...params} label="Select Employee"/>
                                                        }
                                                    />
                                                  </>
                                              </div>
                                          </div>
                                      :null}

                                      <div className="col-3">
                                          <div className="col-12 p-1 w-100">
                                              <div className="d-flex mt-2 p-1 align-items-center w-100"  >
                                                      <TextField
                                                        id="date"
                                                        label="Date"
                                                        type="date"
                                                        size="small"

                                                        onChange={(e) => this.setState({s_date: e.target.value})}

                                                        sx={{ width: '100%'}}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                      />
                                                  </div>
                                          </div>
                                      </div>
                                      <div className="col-5">
                                          <div className="col-12 p-1 w-100">
                                              <div className="d-flex mt-2 p-1 align-items-center w-100"  >
                                                      <TextField
                                                        id="date"
                                                        label="From"
                                                        type="date"
                                                        size="small"

                                                        onChange={(e) => this.setState({f_date: e.target.value})}

                                                        sx={{ width: '50%' }}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                      />

                                                      <p className="mx-4 my-0" style={{fontSize: "24px"}}>to</p>

                                                      <TextField
                                                        id="date"
                                                        label="To"
                                                        type="date"
                                                        size="small"

                                                        onChange={(e) => this.setState({t_date: e.target.value})}

                                                        sx={{ width: '50%' }}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                      />
                                                  </div>
                                          </div>
                                      </div>

                                      <div className="col-1">
                                        <div className="f-btn map-activity-submit">
                                            <button onClick={this.submit}>Submit</button>
                                        </div>
                                      </div>

                                  </div>
                                  {/*<h2 onClick={this.test}>Marked Customers In Map</h2>*/}
                                  <MapActivityMarkedLocations station={this.state.station} marker={this.state.marker} attendance={this.state.attendance}/>
                              </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-lg-none mt-4">
                        <div className="container-fluid">
                            <div className="row justify-content-end">
                              <div>
                                  {/*<h2>Marked Customers In Map</h2>*/}
                                  <MapActivityMarkedLocations station={this.state.station} marker={this.state.marker}/>
                              </div>
                            </div>
                        </div>
                    </div>
                </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>
    );
  }
}

const mapStateToProps = (state) => ({
    user:state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(ProjectView);
