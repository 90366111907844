import React from "react";
import "../../custom/css/material-dashboard.css"
import "../../custom/css/jquery.dropdown.min.css"
import "../../custom/css/nucleo-svg.css"
import "../../custom/css/srkcutom.css"
import "../../custom/css/nucleo-icons.css"
import img1 from '../../custom/img/logo-ct.png'
import img2 from "../../custom/img/team-3.jpg"
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {sideBar, userLogin} from "../../store/actions/actions";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import TollIcon from '@mui/icons-material/Toll';
import DescriptionIcon from '@mui/icons-material/Description';
import ScienceIcon from '@mui/icons-material/Science';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';


class Sidebar extends React.Component {

    state = {
        path: "",
        path2: null
    }

    changeHandler = (data) => {
        this.props.parentCallback(data)

        this.setState({
            path: data,
            // path2: path2,
        })

        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>.", data)
    }


    logout = () => {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined })
    }

    render(){

        return(
            <>
                <aside
                    className="pb-3 sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
                    id="sidenav-main">
                    <div className="sidenav-header">
                        <i className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                           aria-hidden="true" id="iconSidenav"/>
                        <div className="navbar-brand m-0" target="_blank">
                            <img src={require("../../custom/img/dreambox2.png")} className="navbar-brand-img h-100" alt="main_logo"/>
                                <span className="ms-1 font-weight-bold text-white">&nbsp; DOVA</span>
                        </div>
                    </div>
                    <hr className="horizontal light mt-0 mb-2"/>
                        <div className="collapse navbar-collapse  w-auto h-auto" id="sidenav-collapse-main">
                            <ul className="navbar-nav">
                                <li className="nav-item mb-2 mt-0">
                                    <a data-bs-toggle="collapse" href="#ProfileNav" className="nav-link text-white"
                                       aria-controls="ProfileNav" role="button" aria-expanded="false">
                                        <img src={img2} className="avatar sidebar-image"/>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">{this.props.user.employee_full_name}</span>
                                    </a>
                                    <div className="collapse" id="ProfileNav">
                                        <ul className="nav ">
                                            <li className="nav-item">
                                                <Link to={"/employee/employee-view/" + this.props.user.employee_id}>
                                                <a className="nav-link text-white">
                                                    <span className="sidenav-mini-icon"> MP </span>
                                                    <span className="sidenav-normal  ms-3  ps-1"> My Profile </span>
                                                </a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={"/employee/edit-employee/" + this.props.user.employee_id}>
                                                <a className="nav-link text-white ">
                                                    <span className="sidenav-mini-icon"> S </span>
                                                    <span className="sidenav-normal  ms-3  ps-1"> Edit </span>
                                                </a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <div className="nav-link text-white"  onClick={this.logout}>
                                                    <span className="sidenav-mini-icon"> L </span>
                                                    <span className="sidenav-normal  ms-3  ps-1"> Logout </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <hr className="horizontal light mt-0"/>
                                    <li className="nav-item">
                                        <Link to='/'
                                          onClick={(e)=>this.changeHandler('/')}
                                          className={this.props.path==="" || this.props.path==="/"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                           aria-controls="dashboardsExamples" role="button" aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                                <DashboardIcon/>
                                            </i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Dashboard</span>
                                        </Link>

                                    </li>
                                    {this.props.user.employee_profile==='executive'?null:
                                    <li className="nav-item">
                                        <Link to='/employee'
                                          onClick={(e)=>this.changeHandler('employee')}
                                          className={this.props.path==="employee"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                           aria-controls="dashboardsExamples" role="button" aria-expanded="false">
                                            <i className="material-icons-round opacity-10">groups</i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Employee</span>
                                        </Link>

                                    </li>}
                                    <li className="nav-item">
                                        <Link to='/team'
                                          className={this.props.path==="team"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                           onClick={(e)=>this.changeHandler('teams')}
                                           aria-controls="dashboardsExamples"
                                           role="button" aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                                <Diversity3Icon/>
                                            </i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Teams</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/customer'
                                          className={this.props.path==="customer"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                          onClick={(e)=>this.changeHandler('customers')}
                                           aria-controls="dashboardsExamples" role="button" aria-expanded="false">
                                            <i className="material-icons-round opacity-10">support_agent</i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Customers</span>
                                        </Link>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <Link to='/visit'
                                          className={this.props.path==="visit"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                          onClick={(e)=>this.changeHandler('visits')}
                                           aria-controls="dashboardsExamples" role="button" aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                                <WhereToVoteIcon/>
                                            </i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Visits</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item d-none">
                                        <Link to='/tada'
                                          className={this.props.path==="tada"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                          onClick={(e)=>this.changeHandler('tada')}
                                           aria-controls="dashboardsExamples" role="button" aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                                <RequestQuoteIcon/></i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">TA/DA</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/trip'
                                          className={this.props.path==="trip"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                              aria-controls="dashboardsExamples"
                                          onClick={(e)=>this.changeHandler('trip')}
                                           role="button"
                                           aria-expanded="false">
                                            <i className="material-icons-round opacity-10">tour</i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Check In</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item d-none">
                                        <Link to='/tour'
                                          className={this.props.path==="tour"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                              aria-controls="dashboardsExamples"
                                          onClick={(e)=>this.changeHandler('tour')}
                                           role="button"
                                           aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                                <TollIcon/></i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Tour Fund</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item d-none">
                                        <Link to='/report'
                                          className={this.props.path==="report"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                              aria-controls="dashboardsExamples"
                                          onClick={(e)=>this.changeHandler('report')}
                                           role="button"
                                           aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                                <DescriptionIcon/></i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Reports</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/sample'
                                          className={this.props.path==="sample"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                              aria-controls="dashboardsExamples"
                                          onClick={(e)=>this.changeHandler('sample')}
                                           role="button"
                                           aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                            <ScienceIcon/></i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Sample Request</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/attendance'
                                          className={this.props.path==="attendance"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                              aria-controls="dashboardsExamples"
                                          onClick={(e)=>this.changeHandler('attendance')}
                                           role="button"
                                           aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                                <PermContactCalendarIcon/>
                                            </i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Attendance</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/map'
                                          className={this.props.path==="map"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                              aria-controls="dashboardsExamples"
                                          onClick={(e)=>this.changeHandler('map')}
                                           role="button"
                                           aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                            <PersonPinIcon/></i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Agent Tracking</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/tour-plan'
                                          className={this.props.path==="tour-plan"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                              aria-controls="dashboardsExamples"
                                          onClick={(e)=>this.changeHandler('tour-plan')}
                                           role="button"
                                           aria-expanded="false">
                                            <i className="material-icons-round opacity-10">
                                            <ModeOfTravelIcon/></i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">Tour Plan</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/srf'
                                          className={this.props.path==="customer"?
                                              "nav-link text-white active"
                                              :"nav-link text-white"}
                                          onClick={(e)=>this.changeHandler('customers')}
                                           aria-controls="dashboardsExamples" role="button" aria-expanded="false">
                                            <i className="material-icons-round opacity-10">support_agent</i>
                                            <span className="nav-link-text ms-2 ps-1 sidebar-image-text">SRF</span>
                                        </Link>
                                    </li>
                            </ul>
                        </div>
                </aside>
            </>
        )
    }
}


const mapStateToProps = state => ({
    sidebar: state.sideBarData.sidebarData.sidebar,
    user: state.userData.userData

})

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
