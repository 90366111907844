import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import "../../../custom/css/srkcutom.css";
import { axios, url, userLogin } from "../../../store/actions/actions";
import Loader from "../../common/Loader/Loader";
import EmployeeVisits from "./EmployeeVisits";

class EmployeeView extends React.Component {
  state = {
    employee: null,

    first_name: null,
    last__name: null,
    email: null,
    phone: null,
    dob: null,
    gender: null,

    profile: null,

    division: null,

    house_num: null,
    area: null,
    pin_code: null,
    street: null,
    city: null,
    state: null,

    image: null,
    photo: null,

    showModal: false,

    station: null,

    assign_states: null,

    selected_division: null,
    selected_division_list: null,

    selected_city: null,
    selected_city_list: null,

    selected_state: null,
    selected_state_list: null,
  };

  componentDidMount() {
    this.loadEmployee();
    this.toeknExpiryCheck();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "Update Response data----------": responseData });
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  loadEmployee = () => {
    let pathname = window.location.pathname;
    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];
    let endpoint = `employee/${path}`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({
        employee: responseData.employee,
      });
      console.log({ "Emp response data---------": responseData.employee });
    });
  };

  render() {
    let emp = this.state.employee;
    return (
      <>
        {this.props.user.login === true ? (
          <>
            {emp ? (
              <div>
                <div className="row justify-content-end">
                  <div
                    className={
                      this.props.sidebar === undefined ||
                      this.props.sidebar === true
                        ? "d-none d-lg-block col-10"
                        : "d-none d-lg-block col-12"
                    }
                  >
                    <div className="container-fluid px-2 px-md-4">
                      <div
                        className="page-header min-height-300 border-radius-xl mt-4"
                        style={{
                          backgroundImage:
                            "url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1920&amp;q=80')",
                        }}
                      >
                        <span className="mask bg-gradient-primary opacity-6"></span>
                      </div>

                      <div className="card card-body mx-3 mx-md-4 mt-n6 mb-3">
                        {/*-------------------User Detail-------------------*/}
                        {/*<div className={'d-flex'}>*/}
                        <div className="row gx-4 my-3">
                          <div className="col-auto">
                            <div className="avatar avatar-xl position-relative">
                              <img
                                src={
                                  emp.pic
                                    ? url + emp.pic
                                    : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                                }
                                alt="profile_image"
                                className="w-100 border-radius-lg shadow-sm"
                                id="ddd"
                              />
                            </div>
                          </div>

                          <div className="col-auto my-auto">
                            <div className="h-100">
                              <h5 className="mb-1">
                                {emp.last_name
                                  ? emp.first_name + " " + emp.last_name
                                  : emp.first_name}
                              </h5>
                              <p className="mb-0 font-weight-normal text-sm">
                                {emp.is_admin === true ? "Admin " : null}
                                {emp.is_manager === true ? "Manager " : null}
                                {emp.is_executive === true
                                  ? "Executive "
                                  : null}

                                {emp.division
                                  ? emp.division.map(
                                      (div, index) => "/ " + div.name
                                    )
                                  : null}
                              </p>
                            </div>
                          </div>
                          <div className="col-auto d-none f-btn">
                            <button>Edit</button>
                          </div>
                        </div>
                        {/*</div>*/}

                        <div className="row">
                          {/*---------------------Bio-------------------*/}
                          <div className="row mt-3" id="bio">
                            <div className="col-12 mt-4 position-relative">
                              <div className="card card-plain h-100">
                                <div className="card-header pb-0 p-3">
                                  <div className="row">
                                    <div className="col-12 d-flex align-items-center">
                                      <h4 className="mb-0">
                                        Personal Information
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                <hr className="vertical dark" />

                                <div className="card-body ps-5 row">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div className="mt-3">
                                        <strong className="text-dark">
                                          First Name:
                                        </strong>{" "}
                                        &nbsp;
                                        {emp.first_name}
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div className="mt-3">
                                        <strong className="text-dark">
                                          Last Name:
                                        </strong>{" "}
                                        &nbsp;
                                        {emp.last_name}
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div className=" mt-3">
                                        <strong className="text-dark">
                                          Mobile:
                                        </strong>{" "}
                                        &nbsp;
                                        {emp.phone}
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div className=" mt-3">
                                        <strong className="text-dark">
                                          Email:
                                        </strong>{" "}
                                        &nbsp;
                                        {emp.email}
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div className=" mt-3">
                                        <strong className="text-dark">
                                          D.O.B:
                                        </strong>{" "}
                                        &nbsp;
                                        {emp.dob}
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div className=" mt-3">
                                        <strong className="text-dark">
                                          Gender:
                                        </strong>{" "}
                                        &nbsp;
                                        {emp.gender}
                                      </div>
                                    </div>
                                  </div>
                                  <hr class="my-5" />
                                </div>

                                <div className="card-header pt-0">
                                  <div className="row">
                                    <div className="col-12 d-flex align-items-center">
                                      <h4 className="mb-0">Address</h4>
                                    </div>
                                  </div>
                                </div>

                                {emp.address
                                  ? emp.address.map((add, index) => (
                                      <div className="card-body ps-5 row">
                                        <div className="col-6">
                                          <strong className="text-dark">
                                            House no:
                                          </strong>{" "}
                                          &nbsp;
                                          {add.house_num}
                                        </div>
                                        <div className="col-6">
                                          <strong className="text-dark">
                                            Street:
                                          </strong>{" "}
                                          &nbsp;
                                          {add.street}
                                        </div>
                                        <div className="col-6 mt-3">
                                          <strong className="text-dark">
                                            Area:
                                          </strong>{" "}
                                          &nbsp;
                                          {add.area}
                                        </div>
                                        <div className="col-6 mt-3">
                                          <strong className="text-dark">
                                            City:
                                          </strong>{" "}
                                          &nbsp;
                                          {add.distt}
                                        </div>
                                        <div className="col-6 mt-3">
                                          <strong className="text-dark">
                                            Pin code:
                                          </strong>{" "}
                                          &nbsp;
                                          {add.pin_code}
                                        </div>
                                        <div className="col-6 mt-3">
                                          <strong className="text-dark">
                                            State:
                                          </strong>{" "}
                                          &nbsp;
                                          {add.state}
                                        </div>
                                        <hr className="horizontal gray-light my-4" />
                                      </div>
                                    ))
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid px-2 px-md-5">
                      <EmployeeVisits />
                    </div>
                  </div>
                </div>
                <div className="d-lg-none">
                  <div className="dashOption-mv mt-4 px-2">
                    <div className="">
                      <div className="button-bar-mv d-flex">
                        <Link to="/employee" className="btn btn-mv ">
                          <img
                            src={require("../../../kavi/icons/dashbord.png")}
                            alt="btn-icon"
                          />
                          Dashboard
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid px-2 px-md-4 mb-5">
                    <div
                      className="page-header min-height-300 border-radius-xl mt-4"
                      style={{
                        backgroundImage:
                          "url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1920&amp;q=80')",
                      }}
                    >
                      <span className="mask bg-gradient-primary opacity-6"></span>
                    </div>

                    <div className="card card-body mx-3 mx-md-4 mt-n6 mb-3">
                      {/*-------------------User Detail-------------------*/}
                      <div className="row gx-4 my-3">
                        <div className="col-auto">
                          <div className="avatar avatar-xl position-relative">
                            <img
                              src={
                                emp.pic
                                  ? url + emp.pic
                                  : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                              }
                              alt="profile_image"
                              className="w-100 border-radius-lg shadow-sm"
                              id="ddd"
                            />
                          </div>
                        </div>

                        <div className="col-auto my-auto">
                          <div className="h-100">
                            <h5 className="mb-1">
                              {emp.last_name
                                ? emp.first_name + " " + emp.last_name
                                : emp.first_name}
                            </h5>
                            <p className="mb-0 font-weight-normal text-sm">
                              {emp.is_admin === true ? "Admin " : null}
                              {emp.is_manager === true ? "Manager " : null}
                              {emp.is_executive === true ? "Executive " : null}

                              {emp.division
                                ? emp.division.map(
                                    (div, index) => "/ " + div.name
                                  )
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/*---------------------Bio-------------------*/}
                        <div className="row mt-3" id="bio">
                          <div className="col-12 mt-4 position-relative">
                            <div className="card card-plain h-100">
                              <div className="card-header pb-0 p-3">
                                <div className="row">
                                  <div className="col-12 d-flex align-items-center">
                                    <h4 className="mb-0">
                                      Personal Information
                                    </h4>
                                  </div>
                                </div>
                              </div>

                              <hr className="vertical dark" />

                              <div className="card-body ps-5 row">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="mt-3">
                                      <strong className="text-dark">
                                        First Name:
                                      </strong>{" "}
                                      &nbsp;
                                      {emp.first_name}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mt-3">
                                      <strong className="text-dark">
                                        Last Name:
                                      </strong>{" "}
                                      &nbsp;
                                      {emp.last_name}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className=" mt-3">
                                      <strong className="text-dark">
                                        Mobile:
                                      </strong>{" "}
                                      &nbsp;
                                      {emp.phone}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className=" mt-3">
                                      <strong className="text-dark">
                                        Email:
                                      </strong>{" "}
                                      &nbsp;
                                      {emp.email}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className=" mt-3">
                                      <strong className="text-dark">
                                        D.O.B:
                                      </strong>{" "}
                                      &nbsp;
                                      {emp.dob}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className=" mt-3">
                                      <strong className="text-dark">
                                        Gender:
                                      </strong>{" "}
                                      &nbsp;
                                      {emp.gender}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="card-header p-2">
                                <div className="row">
                                  <div className="col-12 d-flex align-items-center">
                                    <h4 className="mb-0">Address</h4>
                                  </div>
                                </div>
                              </div>

                              {emp.address
                                ? emp.address.map((add, index) => (
                                    <div className="card-body ps-5 row">
                                      <div className="col-6">
                                        <strong className="text-dark">
                                          House no:
                                        </strong>{" "}
                                        &nbsp;
                                        {add.house_num}
                                      </div>
                                      <div className="col-6">
                                        <strong className="text-dark">
                                          Street:
                                        </strong>{" "}
                                        &nbsp;
                                        {add.street}
                                      </div>
                                      <div className="col-6 mt-3">
                                        <strong className="text-dark">
                                          Area:
                                        </strong>{" "}
                                        &nbsp;
                                        {add.area}
                                      </div>
                                      <div className="col-6 mt-3">
                                        <strong className="text-dark">
                                          City:
                                        </strong>{" "}
                                        &nbsp;
                                        {add.distt}
                                      </div>
                                      <div className="col-6 mt-3">
                                        <strong className="text-dark">
                                          Pin code:
                                        </strong>{" "}
                                        &nbsp;
                                        {add.pin_code}
                                      </div>
                                      <div className="col-6 mt-3">
                                        <strong className="text-dark">
                                          State:
                                        </strong>{" "}
                                        &nbsp;
                                        {add.state}
                                      </div>
                                      <hr className="horizontal gray-light my-4" />
                                    </div>
                                  ))
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid px-2 px-md-5 mb-5">
                      <EmployeeVisits />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeView);
