import React from "react";
import { connect } from "react-redux";
import {
  axios,
  google_map_api_key,
  modalStyle,
} from "../../../store/actions/actions";
import Select from "react-select";
import StarRatings from "react-star-ratings/build/star-ratings";
import { Link, Redirect } from "react-router-dom";
import "../../common/common.css";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { GoogleApiWrapper } from "google-maps-react";
import TextField from "@mui/material/TextField";
import { boards } from "../../common/objects";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { default as MUISelect } from "@mui/material/Select";

class CreateVisit extends React.Component {
  state = {
    visit_is: "school",
    page: "Create Visit From Trip",
    visit_type: "trip",

    radio_manually: false,
    radio_trip: true,
    radio_nearby: false,

    address_type: "address",
    lat: null,
    long: null,
    map_address: null,

    street: null,
    city: null,
    pin_code: null,
    state: null,
    area: null,
    route: null,
    landmark: null,
    sector: null,
    transport_name: null,
    nearest_station: null,
    special_instruction: null,

    company: null,
    cust_phone: null,
    board: null,
    email: null,
    rating: null,
    gst_num: null,
    pan_num: null,
    website: null,

    serial_num: null,
    date: null,
    working_status: null,
    working_status_id: null,
    status: null,
    mp3: null,
    recordState: null,
    quest: null,
    questionnaire: null,
    contact_person_name: null,
    contact_person_phone: null,
    remark: null,
    trip_id: null,
    customer_id: null,

    sample_array: [
      {
        id: "sample_" + new Date().valueOf(),
        book: null,
        from: null,
        to: null,
        class_from: null,
        class_to: null,
        selected_from: null,
        selected_to: null,
        quantity: null,
        price: null,

        n_from: null,
        n_to: null,
      },
    ],
    series: null,

    nearby_customer: null,
    nearby_schools: null,
    nearby_sellers: null,

    customer: null,
    trip: null,
    today_date: null,
    start: false,

    open: false,
    getbygoogle: false,

    show_dropdown: false,

    all_schools: null,
    all_sellers: null,
    google_schools: null,
    db_schools: null,

    input_address: "",
    suggestions: null,
    schools: null,

    quest_remark: null,
    errorMessage: null,

    submit: false,
  };

  my_array = [];

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  componentDidMount() {
    this.loadTripWithAxios();
    this.loadWorkingStatus();
    this.todayDate();
  }

  getlatlong = () => {
    console.log("---------GetLatLong--------");
    if (
      this.state.city !== null &&
      this.state.city !== undefined &&
      this.state.city !== "" &&
      this.state.state !== null &&
      this.state.state !== undefined &&
      this.state.state !== ""
    ) {
      let address = `${this.state.company}, ${this.state.city}, ${this.state.state}`;
      let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${google_map_api_key}`;

      axios.get(endpoint).then((response) => {
        const responseData = response.data.results[0].geometry.location;
        this.setState({
          map_address: address,
          lat: responseData.lat,
          long: responseData.lng,
        });
        let sub = setTimeout(this.submit, 300);
        console.log({ "get loc response data---------": responseData });
      });
    } else {
      console.log("please select city or state...");
    }
  };

  loadWorkingStatus = () => {
    let endpoint = `visit/working-status`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ status: responseData.status });
      console.log({
        "Working status response data---------": responseData,
      });
    });
  };

  getQuestionnaire = (status) => {
    this.setState({ working_status: status });
    const data = {
      status: status,
    };
    if (status === "SAMPLING") {
      let endpoint = `books/get-books`;
      let token = this.props.user.token;

      axios.get(endpoint, token).then((res) => {
        let responseData = res.data;
        console.log({ "Books Response data----------->": responseData });
        this.setState({
          series: responseData.books,
        });
      });
    }

    // if (status==='CLOSURE'){
    //     const data2 = {
    //         lat: this.state.lat,
    //         lng: this.state.long,
    //         // id:
    //     };
    //     let endpoint = `visit/get-samples`;
    //     let token = this.props.user.token
    //
    //     axios
    //         .post(endpoint, data2, token)
    //         .then((response) => {
    //             const responseData = response.data;
    //             this.setState({ sample_array: responseData.samples });
    //             console.log({"Samples response data---------": responseData})
    //     });
    // }

    let endpoint = `visit/questionnaires`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      this.setState({ quest: responseData.quest });
      console.log({ "Questionnaire response data---------": responseData });
    });
  };

  handleQuestionnaire = (item) => {
    let check = this.my_array.includes(item);
    if (check === false) {
      this.my_array.push(item);
    } else {
      let index = this.my_array.indexOf(item);

      if (index > -1) {
        this.my_array.splice(index, 1);
      }
    }
    this.setState({ questionnaire: this.my_array });
  };

  saveQuestionnaire = (id) => {
    const data = {
      visit_id: id,
      working_status: this.state.working_status,
      questionnaire: this.state.questionnaire,
      remark: this.state.quest_remark,
    };

    let endpoint = `visit/save-working-status`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      if (responseData.success === true) {
        if (this.state.recordState !== null) {
          this.saveAudio(responseData.id);
        }
        this.setState({
          working_status_id: responseData.id,
        });
      }
      console.log({
        "Working status response data---------": responseData,
      });
    });
  };

  saveAudio = (id) => {
    let data = new FormData();
    data.append("file", this.state.recordState);
    data.append("id", id);

    console.log("------------------------>", data);

    let endpoint = `visit/save-working-status`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      console.log({
        "Working status response data---------": responseData,
      });
    });
  };

  loadTripWithAxios = () => {
    let endpoint = `trip/visit-from-trip`;
    let token = this.props.user.token;
    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({ trip: responseData.trip });

      console.log({ "Trip response data--------->": responseData });
    });
  };

  todayDate = () => {
    var today = new Date().toISOString().slice(0, 10);
    this.setState({ today_date: today });
  };

  handleRadio = (data) => {
    this.setState({
      input_address: null,
      company: null,
      cust_phone: null,
      website: null,
      rating: null,

      street: null,
      city: null,
      pin_code: null,
      state: null,
      area: null,
      route: null,
      landmark: null,
      sector: null,
      block: null,
      getbygoogle: false,
    });

    if (data === "radio_manually") {
      this.setState({
        radio_trip: false,
        radio_manually: true,
        visit_type: "new",
        trip_id: null,
      });
    }

    if (data === "radio_trip") {
      this.setState({
        radio_manually: false,
        radio_trip: true,
        visit_type: "trip",
      });
    }
  };

  selectTrip = (selectedOption) => {
    this.setState({ trip_id: selectedOption.value.id });
  };

  submit = () => {
    console.log("---------Submit--------");
    if (this.state.working_status === null) {
      this.setState({
        errorMessage: "Please select any purpose...",
        open: true,
      });
    }
    if (
      this.state.working_status !== null &&
      (this.state.questionnaire === null ||
        this.state.questionnaire.length === 0)
    ) {
      this.setState({
        errorMessage: "Please select any questionnaire of your purpose...",
        open: true,
      });
    }
    if (
      this.state.working_status !== null &&
      (this.state.questionnaire !== null ||
        this.state.questionnaire.length !== 0)
    ) {
      this.setState({ open: false, errorMessage: null });
      if (this.state.submit === false) {
        if (this.state.visit_type === "trip") {
          console.log("----------------->Visit form trip<---------------->");
          var data = {
            visit_is: this.state.visit_is,
            visit_type: "trip",

            trip_id: this.state.trip_id,
            date: this.state.date ? this.state.date : this.state.today_date,
            contact_person_name: this.state.contact_person_name,
            contact_person_phone: this.state.contact_person_phone,
            samples: this.state.sample_array,
            working_status: this.state.working_status,
            working_status_id: this.state.working_status_id,
          };
        }

        if (this.state.visit_type === "new") {
          console.log("----------------->New Visit---------------->");
          var data = {
            visit_is: this.state.visit_is,
            visit_type: "new",

            lat: this.state.lat,
            long: this.state.long,
            map_address: this.state.map_address,

            street_name:
              this.state.getbygoogle === true
                ? this.state.street +
                  this.state.block +
                  this.state.sector +
                  this.state.route +
                  this.state.landmark +
                  this.state.area
                : this.state.street_name,
            street: this.state.street,
            city: this.state.city,
            pin_code: this.state.pin_code,
            state: this.state.state,
            transport_name: this.state.transport_name,
            nearest_station: this.state.nearest_station,
            special_instruction: this.state.special_instruction,

            customer: this.state.company,
            cust_phone: this.state.cust_phone,
            board: this.state.board,
            rating: this.state.rating,
            email: this.state.email,
            website: this.state.website,

            contact_person_name: this.state.contact_person_name,
            contact_person_phone: this.state.contact_person_phone,

            serial_num: this.state.serial_num,
            date: this.state.date ? this.state.date : this.state.today_date,
            customer_name: this.state.company,
            station: this.state.city,
            working_status: this.state.working_status,
            working_status_id: this.state.working_status_id,
            samples: this.state.sample_array,
            remark: this.state.remark,
          };
        }

        console.log({ "data---------": data });

        let endpoint = `visit/`;
        let token = this.props.user.token;
        if (
          this.state.company !== null ||
          this.state.customer_id !== null ||
          this.state.trip_id !== null
        ) {
          if (
            this.state.trip_id !== null ||
            (this.state.city !== null &&
              this.state.city !== undefined &&
              this.state.city !== "" &&
              this.state.state !== null &&
              this.state.state !== undefined &&
              this.state.state !== "")
          ) {
            axios.post(endpoint, data, token).then((res) => {
              let responseData = res.data;

              if (responseData.success === true) {
                if (this.state.working_status !== null) {
                  this.saveQuestionnaire(responseData.id);
                }

                this.setState({
                  visit: data,
                  open: true,
                  submit: true,
                });
              } else {
                this.setState({
                  errorMessage: responseData.msg,
                  open: true,
                });
              }
              console.log({ "submit---------": responseData });
            });
          } else {
            console.log("please select city or state...");
          }
        } else {
          console.log("please select customer or trip...");
        }
      }
    }
  };

  start = () => {
    this.setState({
      recordState: RecordState.START,
      start: true,
    });
  };

  stop = () => {
    this.setState({
      recordState: RecordState.STOP,
      start: false,
    });
    console.log("audioData...............", this.state.recordState);
  };

  onStop = (audioData) => {
    console.log("audioData...............", audioData);
    console.log("audioData...............", audioData.blob);
    this.setState({
      mp3: audioData.url,
      recordState: audioData.blob,
    });
  };

  refreshPage = (data) => {
    this.setState({
      input_address: null,
      company: null,
      cust_phone: null,
      website: null,
      rating: null,

      street: null,
      city: null,
      pin_code: null,
      state: null,
      area: null,
      route: null,
      landmark: null,
      sector: null,
      block: null,
      getbygoogle: false,
      visit_is: data,
    });
  };

  addSample = () => {
    let obj_array = this.state.sample_array;
    let obj = {
      id: "sample_" + new Date().valueOf(),

      selected_from: null,
      selected_to: null,
      book: null,
      from: null,
      to: null,
      class_from: null,
      class_to: null,
      quantity: null,
      price: null,

      n_from: null,
      n_to: null,
    };
    obj_array.push(obj);
    this.setState({
      sample_array: obj_array,
    });
  };

  removeSample = (id) => {
    let arr = this.state.sample_array;
    let filteredArray = arr.filter((item) => item.id !== id);
    this.setState({
      sample_array: filteredArray,
    });
  };

  saveValueToObject = (id, key, value) => {
    let obj_array = this.state.sample_array;
    let filter_obj = obj_array.filter((i) => i.id === id);
    if (key === "book") {
      let obj = this.state.series.filter((i) =>
        i.title.toLowerCase().includes(value.toLowerCase())
      );
      obj_array.map((i, index) =>
        i.id === id ? (i["selected_from"] = obj[0].book) : null
      );
      obj_array.map((i, index) =>
        i.id === id ? (i["selected_to"] = obj[0].book) : null
      );
      obj_array.map((i, index) => (i.id === id ? (i[key] = obj[0].id) : null));
    } else {
      if (key === "from") {
        obj_array.map((i, index) =>
          i.id === id ? (i["class_from"] = value) : null
        );
        obj_array.map((i, index) =>
          i.id === id ? (i["n_from"] = value.n) : null
        );
        obj_array.map((i, index) => (i.id === id ? (i[key] = value.id) : null));
        obj_array.map((i, index) =>
          i.id === id ? (i["price"] = this.calculateAmount(filter_obj)) : null
        );
      }

      if (key === "to") {
        obj_array.map((i, index) =>
          i.id === id ? (i["class_to"] = value) : null
        );
        obj_array.map((i, index) =>
          i.id === id ? (i["n_to"] = value.n) : null
        );
        obj_array.map((i, index) => (i.id === id ? (i[key] = value.id) : null));
        obj_array.map((i, index) =>
          i.id === id ? (i["price"] = this.calculateAmount(filter_obj)) : null
        );
      }

      if (key === "quantity") {
        obj_array.map((i, index) => (i.id === id ? (i[key] = value) : null));
        obj_array.map((i, index) =>
          i.id === id ? (i["price"] = this.calculateAmount(filter_obj)) : null
        );
      }
    }

    this.setState({
      sample_obj: obj_array,
    });
  };

  calculateAmount = (obj) => {
    let amount_array = [];
    if (obj[0].n_to === null) {
      obj[0].selected_from.map((item) => {
        if (item.n === obj[0].n_from) {
          amount_array.push(Number(item.price));
        }
      });
      return obj[0].quantity === null || obj[0].quantity === 0
        ? amount_array[0]
        : obj[0].quantity * amount_array[0];
    } else {
      for (let step = obj[0].n_from; step < obj[0].n_to + 1; step++) {
        obj[0].selected_from.map((item) => {
          if (item.n === step) {
            amount_array.push(Number(item.price));
          }
        });
      }
      const sum = amount_array.reduce((partialSum, a) => partialSum + a, 0);
      return obj[0].quantity === null || obj[0].quantity === 0
        ? sum
        : obj[0].quantity * sum;
    }
  };

  render() {
    console.log(
      "mp3------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>------------------>",
      this.state.mp3
    );
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="container-fluid d-none d-lg-block">
              <div className="row justify-content-end">
                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "col-10"
                      : "col-12"
                  }
                >
                  <div className="container row mb-4 align-items-center">
                    <div className="col-lg-6 col-sm-6">
                      <div className="nav-wrapper position-relative end-0">
                        <ul
                          className="nav nav-pills nav-fill p-1"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <div
                              className="nav-link mb-0 px-0 py-1 active "
                              data-bs-toggle="tab"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => this.refreshPage("school")}
                              role="tab"
                              aria-selected="true"
                            >
                              School Visit
                            </div>
                          </li>
                          <li className="nav-item">
                            <div
                              className="nav-link mb-0 px-0 py-1 "
                              data-bs-toggle="tab"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => this.refreshPage("seller")}
                              role="tab"
                              aria-selected="false"
                            >
                              Seller Visit
                            </div>
                          </li>
                          <div
                            className="moving-tab position-absolute nav-link"
                            style={
                              this.state.visit_is === "school" ||
                              this.state.visit_is === "school manually"
                                ? {
                                    padding: "0px",
                                    transition: "all 0.5s ease 0s",
                                    transform: "translate3d(0px, 0px, 0px)",
                                    width: "50%",
                                  }
                                : this.state.visit_is === "seller" ||
                                  this.state.visit_is === "seller manually"
                                ? {
                                    padding: "0px",
                                    transition: "all 0.5s ease 0s",
                                    transform: "translate3d(309px, 0px, 0px)",
                                    width: "48%",
                                  }
                                : null
                            }
                          >
                            <div
                              className="nav-link mb-0 px-0 py-1 active "
                              data-bs-toggle="tab"
                              role="tab"
                              aria-selected="true"
                            >
                              -
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="container row col-12">
                    <div className="form-check col-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={this.state.radio_manually}
                        onClick={(e) => this.handleRadio("radio_manually")}
                        id="create_visit_for"
                      />
                      <label
                        className="form-check-label"
                        for="create_visit_for"
                      >
                        Create Visit
                      </label>
                    </div>

                    <div className="form-check col-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={this.state.radio_trip}
                        onClick={(e) => this.handleRadio("radio_trip")}
                        id="create_visit_from_trip_for"
                      />
                      <label
                        className="form-check-label"
                        for="create_visit_from_trip_for"
                      >
                        Create Visit From Trip
                      </label>
                    </div>
                  </div>
                  <div className="container row">
                    <>
                      {this.state.visit_type === "trip" ? (
                        <div className="col-md-12 mt-3">
                          <div className="card">
                            <div className="card-body">
                              <div id="basic-info">
                                <>
                                  <div>
                                    <h5
                                      className="font-weight-bolder mb-2"
                                      onClick={this.test}
                                    >
                                      Visit
                                    </h5>
                                    <p className="mb-4 text-sm">
                                      Creat Visit Report And Submit
                                    </p>
                                  </div>
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-6">
                                        {this.state.trip ? (
                                          <div className="mt-4">
                                            <Select
                                              placeholder="Select Trip"
                                              options={this.state.trip.map(
                                                (item, index) => ({
                                                  value: item,
                                                  label: item.customer__company,
                                                })
                                              )}
                                              onChange={this.selectTrip}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-6">
                                        <div className="input-group input-group-static is-filled">
                                          <label>Date</label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onFocus="focused(this)"
                                            value={
                                              this.state.date
                                                ? this.state.date
                                                : this.state.today_date
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                date: e.target.value,
                                              })
                                            }
                                            onfocusout="defocused(this)"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row mt-4">
                                      <div className="col-6 mt-2">
                                        <div className="input-group input-group-static is-filled">
                                          <label>Contact Person Name:</label>
                                          <input
                                            type="text"
                                            className="form-control mt-3"
                                            onFocus="focused(this)"
                                            onChange={(e) =>
                                              this.setState({
                                                contact_person_name:
                                                  e.target.value,
                                              })
                                            }
                                            onfocusout="defocused(this)"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6 row">
                                        <div className="col-12 input-group input-group-static">
                                          <label className="form-label">
                                            Purpose of visit
                                          </label>
                                        </div>
                                        <div className="col-12">
                                          <div className="dropdown mt-5">
                                            <div
                                              className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                              id="navbarDropdownMenuLink2"
                                              aria-expanded="false"
                                            >
                                              {/*Purpose of visit*/}
                                              {this.state.working_status
                                                ? this.state.working_status
                                                : "Purpose of visit"}
                                            </div>
                                            <ul
                                              className="dropdown-menu w-50 srkw"
                                              aria-labelledby="navbarDropdownMenuLink2"
                                            >
                                              {this.state.status
                                                ? this.state.status.map(
                                                    (st, index) => (
                                                      <li>
                                                        <div
                                                          className="dropdown-item"
                                                          onClick={(e) =>
                                                            this.getQuestionnaire(
                                                              st.choice
                                                            )
                                                          }
                                                        >
                                                          {st.choice}
                                                        </div>
                                                      </li>
                                                    )
                                                  )
                                                : null}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>

                                      {this.state.quest ? (
                                        <div className="col-12 w-100 d-flex justify-content-center quest-button">
                                          <div className="card-box card w-75 p-5">
                                            <div className="arrow-up"></div>

                                            {this.state.quest.map(
                                              (qst, index) => (
                                                <div className="row justify-content-center mt-5">
                                                  <div className="col-md-8">
                                                    <p>{qst}</p>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <div className="btn-box">
                                                      <button
                                                        onClick={(e) =>
                                                          this.handleQuestionnaire(
                                                            qst
                                                          )
                                                        }
                                                        type="submit"
                                                        className={
                                                          this.my_array.includes(
                                                            qst
                                                          ) === true
                                                            ? "btn btn-confirm btn-primary"
                                                            : "btn btn-confirm btn-color"
                                                        }
                                                      >
                                                        Yes
                                                      </button>
                                                      <button
                                                        onClick={(e) =>
                                                          this.handleQuestionnaire(
                                                            qst
                                                          )
                                                        }
                                                        type="submit"
                                                        className={
                                                          this.my_array.includes(
                                                            qst
                                                          ) === true
                                                            ? "btn btn-confirm btn-color"
                                                            : "btn btn-confirm btn-primary"
                                                        }
                                                      >
                                                        No
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                            <div className="row justify-content-center">
                                              <div className="col-md-9">
                                                <div className="microphone d-flex flex-column align-items-center">
                                                  <div>
                                                    <div
                                                      className="audio-view"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    >
                                                      <AudioReactRecorder
                                                        canvasWidth={500}
                                                        canvasHeight={100}
                                                        state={
                                                          this.state.recordState
                                                        }
                                                        onStop={this.onStop}
                                                      />
                                                    </div>

                                                    {this.state.start ===
                                                    false ? (
                                                      <div>
                                                        <div
                                                          className="p-4 position-relative"
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                          onClick={this.start}
                                                        >
                                                          <button
                                                            id="speech"
                                                            className="btn micBtn"
                                                          >
                                                            <i
                                                              className="fa fa-microphone"
                                                              aria-hidden="true"
                                                              style={{
                                                                fontSize:
                                                                  "35px",
                                                                position:
                                                                  "absolute",
                                                                top: "21px",
                                                                right: "27px",
                                                              }}
                                                            ></i>
                                                          </button>
                                                          {/*<div className="pulse-ring"></div>*/}
                                                        </div>
                                                        {this.state.mp3 ? (
                                                          <div
                                                            className="audioPlayer col-2 ms-3 d-inline-block"
                                                            style={{
                                                              width: "100%",
                                                              justifyContent:
                                                                "center",
                                                              marginBottom:
                                                                "10px",
                                                            }}
                                                          >
                                                            <audio
                                                              src={
                                                                this.state.mp3
                                                              }
                                                              controls
                                                            ></audio>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="p-4 position-relative"
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                        onClick={this.stop}
                                                      >
                                                        <button
                                                          id="speech"
                                                          className="btn micBtn"
                                                        >
                                                          <i
                                                            className="fa fa-microphone"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: "35px",
                                                              position:
                                                                "absolute",
                                                              top: "21px",
                                                              right: "27px",
                                                            }}
                                                          ></i>
                                                        </button>
                                                        <div className="pulse-ring"></div>
                                                      </div>
                                                      // <div className="row align-items-center">
                                                      //     <div className="col-1">
                                                      //         <img
                                                      //             height="40px"
                                                      //             onClick={this.stop}
                                                      //             src={url + "/static/pause.png"}
                                                      //         />
                                                      //     </div>
                                                      //
                                                      //     <div className="col-2 listening">
                                                      //         <span className="listening1"></span>
                                                      //         <span className="listening2"></span>
                                                      //         <span className="listening3"></span>
                                                      //         <span className="listening4"></span>
                                                      //         <span className="listening5"></span>
                                                      //     </div>
                                                      // </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <Box
                                              component="form"
                                              sx={{
                                                "& .MuiTextField-root": {
                                                  m: 1,
                                                  width: "25ch",
                                                },
                                              }}
                                              noValidate
                                              autoComplete="off"
                                            >
                                              <div className="input-group input-group-static is-filled">
                                                <TextField
                                                  id="standard-multiline-flexible"
                                                  label="Remark"
                                                  multiline
                                                  maxRows={4}
                                                  className="form-control"
                                                  onFocus="focused(this)"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      quest_remark:
                                                        e.target.value,
                                                    })
                                                  }
                                                  onfocusout="defocused(this)"
                                                  variant="standard"
                                                />
                                              </div>
                                            </Box>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>

                                    {this.state.working_status ===
                                    "SAMPLING" ? (
                                      <div className="sample-thead mt-4">
                                        <div className="">
                                          <div
                                            className="add-ins float-right d-flex mb-3"
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addSample}
                                          >
                                            <img
                                              src={require("../../../custom/icons/plus.png")}
                                              height="30"
                                            />
                                            <p className="m-1">ADD SAMPLE</p>
                                          </div>
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table mt-2">
                                            <thead>
                                              <tr>
                                                <th>S. No.</th>
                                                <th>Book</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Qty.</th>
                                                <th>Price</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            {this.state.sample_array
                                              ? this.state.sample_array.map(
                                                  (sample, index) => (
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          width="7%"
                                                          className="text-center"
                                                        >
                                                          <div
                                                            style={{
                                                              marginTop: "16px",
                                                            }}
                                                          >
                                                            <span className="">
                                                              {index + 1}
                                                            </span>
                                                            .
                                                          </div>
                                                        </td>
                                                        <td width="43%">
                                                          {this.state.series ? (
                                                            <Autocomplete
                                                              className="pl-1 pr-1"
                                                              id="free-solo-demo"
                                                              size={"small"}
                                                              style={{
                                                                marginTop:
                                                                  "16px",
                                                              }}
                                                              options={this.state.series.map(
                                                                (option) =>
                                                                  option.title
                                                              )}
                                                              onChange={(
                                                                event,
                                                                value
                                                              ) =>
                                                                this.saveValueToObject(
                                                                  sample.id,
                                                                  "book",
                                                                  value
                                                                )
                                                              }
                                                              renderInput={(
                                                                params
                                                              ) => (
                                                                <TextField
                                                                  {...params}
                                                                  label="Select Book"
                                                                />
                                                              )}
                                                            />
                                                          ) : null}
                                                        </td>

                                                        <td width="10%">
                                                          <div className="">
                                                            {sample.selected_from ? (
                                                              <Autocomplete
                                                                className="pl-1 pr-1"
                                                                id="free-solo-demo"
                                                                size={"small"}
                                                                sx={{
                                                                  m: 1,
                                                                  minWidth: 120,
                                                                }}
                                                                options={sample.selected_from.map(
                                                                  (option) =>
                                                                    option.classes__class_name
                                                                )}
                                                                onChange={(
                                                                  event,
                                                                  value
                                                                ) =>
                                                                  this.saveValueToObject(
                                                                    sample.id,
                                                                    "from",
                                                                    sample.selected_from.filter(
                                                                      (i) =>
                                                                        i.classes__class_name ===
                                                                        value
                                                                    )[0]
                                                                  )
                                                                }
                                                                value={
                                                                  sample.class_from
                                                                    ? sample
                                                                        .class_from
                                                                        .classes__class_name
                                                                    : ""
                                                                }
                                                                renderInput={(
                                                                  params
                                                                ) => (
                                                                  <TextField
                                                                    {...params}
                                                                    label="From"
                                                                    variant="standard"
                                                                  />
                                                                )}
                                                              />
                                                            ) : (
                                                              <FormControl
                                                                variant="standard"
                                                                sx={{
                                                                  m: 1,
                                                                  minWidth: 120,
                                                                }}
                                                              >
                                                                <InputLabel id="demo-simple-select-standard-label">
                                                                  From
                                                                </InputLabel>
                                                                <MUISelect
                                                                  labelId="demo-simple-select-standard-label"
                                                                  id="demo-simple-select-standard"
                                                                  value={
                                                                    sample.class_from
                                                                      ? sample.class_from
                                                                      : ""
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.saveValueToObject(
                                                                      sample.id,
                                                                      "from",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                  label="From"
                                                                >
                                                                  <MenuItem
                                                                    value={null}
                                                                  >
                                                                    <em>
                                                                      None
                                                                    </em>
                                                                  </MenuItem>
                                                                  {sample.selected_from
                                                                    ? sample.selected_from.map(
                                                                        (
                                                                          item
                                                                        ) => (
                                                                          <MenuItem
                                                                            value={
                                                                              item
                                                                            }
                                                                          >
                                                                            {
                                                                              item.classes__class_name
                                                                            }
                                                                          </MenuItem>
                                                                        )
                                                                      )
                                                                    : null}
                                                                </MUISelect>
                                                              </FormControl>
                                                            )}
                                                          </div>
                                                        </td>

                                                        <td width="10%">
                                                          <div className="">
                                                            {sample.selected_to ? (
                                                              <Autocomplete
                                                                className="pl-1 pr-1"
                                                                id="free-solo-demo"
                                                                size={"small"}
                                                                sx={{
                                                                  m: 1,
                                                                  minWidth: 120,
                                                                }}
                                                                options={sample.selected_to.map(
                                                                  (option) =>
                                                                    option.classes__class_name
                                                                )}
                                                                onChange={(
                                                                  event,
                                                                  value
                                                                ) =>
                                                                  this.saveValueToObject(
                                                                    sample.id,
                                                                    "to",
                                                                    sample.selected_to.filter(
                                                                      (i) =>
                                                                        i.classes__class_name ===
                                                                        value
                                                                    )[0]
                                                                  )
                                                                }
                                                                value={
                                                                  sample.class_to
                                                                    ? sample
                                                                        .class_to
                                                                        .classes__class_name
                                                                    : ""
                                                                }
                                                                renderInput={(
                                                                  params
                                                                ) => (
                                                                  <TextField
                                                                    {...params}
                                                                    label="To"
                                                                    variant="standard"
                                                                  />
                                                                )}
                                                              />
                                                            ) : (
                                                              <FormControl
                                                                variant="standard"
                                                                sx={{
                                                                  m: 1,
                                                                  minWidth: 120,
                                                                }}
                                                              >
                                                                <InputLabel id="demo-simple-select-standard-label">
                                                                  To
                                                                </InputLabel>
                                                                <MUISelect
                                                                  labelId="demo-simple-select-standard-label"
                                                                  id="demo-simple-select-standard"
                                                                  value={
                                                                    sample.class_to
                                                                      ? sample.class_to
                                                                      : ""
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.saveValueToObject(
                                                                      sample.id,
                                                                      "to",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                  label="To"
                                                                >
                                                                  <MenuItem
                                                                    value={null}
                                                                  >
                                                                    <em>
                                                                      None
                                                                    </em>
                                                                  </MenuItem>
                                                                  {sample.selected_from
                                                                    ? sample.selected_to.map(
                                                                        (
                                                                          item
                                                                        ) => (
                                                                          <MenuItem
                                                                            value={
                                                                              item
                                                                            }
                                                                          >
                                                                            {
                                                                              item.classes__class_name
                                                                            }
                                                                          </MenuItem>
                                                                        )
                                                                      )
                                                                    : null}
                                                                </MUISelect>
                                                              </FormControl>
                                                            )}
                                                          </div>
                                                        </td>

                                                        <td width="10%">
                                                          <div
                                                            id="div_id_qty"
                                                            className="form-group"
                                                          >
                                                            <div className="">
                                                              <input
                                                                type="number"
                                                                id={sample.id}
                                                                value={
                                                                  sample.quantity
                                                                    ? parseInt(
                                                                        sample.quantity
                                                                      )
                                                                    : ""
                                                                }
                                                                onChange={(e) =>
                                                                  this.saveValueToObject(
                                                                    sample.id,
                                                                    "quantity",
                                                                    e.target
                                                                      .value ===
                                                                      ""
                                                                      ? 0
                                                                      : e.target
                                                                          .value
                                                                  )
                                                                }
                                                                className="form-control w-100 p-2"
                                                                style={{
                                                                  background:
                                                                    "#ededed",
                                                                  marginTop:
                                                                    "16px",
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </td>

                                                        <td width="10%">
                                                          <div
                                                            id="div_id_qty"
                                                            className="form-group"
                                                          >
                                                            <div className="">
                                                              <input
                                                                type="number"
                                                                id={sample.id}
                                                                value={
                                                                  sample.price
                                                                    ? parseInt(
                                                                        sample.price
                                                                      )
                                                                    : ""
                                                                }
                                                                onChange={(e) =>
                                                                  this.saveValueToObject(
                                                                    sample.id,
                                                                    "price",
                                                                    e.target
                                                                      .value ===
                                                                      ""
                                                                      ? 0
                                                                      : e.target
                                                                          .value
                                                                  )
                                                                }
                                                                className="form-control w-100 p-2"
                                                                style={{
                                                                  background:
                                                                    "#ededed",
                                                                  marginTop:
                                                                    "16px",
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </td>

                                                        <td
                                                          width="10%"
                                                          className="text-center"
                                                        >
                                                          <div
                                                            className="btn btn-xs"
                                                            style={{
                                                              color: "red",
                                                              fontSize: "15px",
                                                              marginTop: "16px",
                                                            }}
                                                            onClick={(e) =>
                                                              this.removeSample(
                                                                sample.id
                                                              )
                                                            }
                                                          >
                                                            X
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  )
                                                )
                                              : null}
                                          </table>
                                        </div>
                                      </div>
                                    ) : null}

                                    {/*<--------------------Visit By Trip------------------>*/}

                                    <div className="row my-4">
                                      <div className="col-12">
                                        <button
                                          className="btn bg-gradient-dark d-block m-auto"
                                          onClick={this.submit}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div
                            className={
                              this.state.address_type === "address"
                                ? "col-md-6 mt-3"
                                : "col-md-12 mt-3"
                            }
                          >
                            <div className="card">
                              <div className="card-body p-3">
                                <div>
                                  <div className="row">
                                    <h5 className="font-weight-bold mb-0">
                                      Address
                                    </h5>
                                    <p className="mb-0 text-sm">
                                      Tell us where are you living
                                    </p>
                                    <div
                                      className={
                                        this.state.address_type === "address"
                                          ? "col-md-12"
                                          : "col-md-5"
                                      }
                                    >
                                      <div className="multisteps-form__content">
                                        <div className="row mt-3">
                                          <div className="col-12 ">
                                            <div className="input-group input-group-dynamic">
                                              {/*<label className="form-label">Street Name</label>*/}
                                              <input
                                                placeholder="Street Name"
                                                onChange={(e) =>
                                                  this.setState({
                                                    street_name: e.target.value,
                                                  })
                                                }
                                                defaultValue={
                                                  this.state.city
                                                    ? this.state.street +
                                                      this.state.block +
                                                      this.state.sector +
                                                      this.state.route +
                                                      this.state.landmark +
                                                      this.state.area
                                                    : null
                                                }
                                                className="multisteps-form__input form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-12 ">
                                            <div className="input-group input-group-dynamic">
                                              {/*<label className="form-label">City</label>*/}
                                              <input
                                                placeholder="City"
                                                onChange={(e) =>
                                                  this.setState({
                                                    city: e.target.value,
                                                  })
                                                }
                                                defaultValue={this.state.city}
                                                className="multisteps-form__input form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-12 ">
                                            <div className="input-group input-group-dynamic">
                                              {/*<label className="form-label">Pin Code</label>*/}
                                              <input
                                                placeholder="Pin Code"
                                                onChange={(e) =>
                                                  this.setState({
                                                    pin_code: e.target.value,
                                                  })
                                                }
                                                defaultValue={
                                                  this.state.pin_code
                                                }
                                                className="multisteps-form__input form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-12 ">
                                            <div className="input-group input-group-dynamic">
                                              {/*<label className="form-label">State</label>*/}
                                              <input
                                                placeholder="State"
                                                onChange={(e) =>
                                                  this.setState({
                                                    state: e.target.value,
                                                  })
                                                }
                                                defaultValue={this.state.state}
                                                className="multisteps-form__input form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-6">
                                            <div className="input-group input-group-dynamic">
                                              {/*<label className="form-label">*/}
                                              {/*  Transport Name*/}
                                              {/*</label>*/}
                                              <input
                                                placeholder="Transport Name"
                                                onChange={(e) =>
                                                  this.setState({
                                                    transport_name:
                                                      e.target.value,
                                                  })
                                                }
                                                className="multisteps-form__input form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="input-group input-group-dynamic">
                                              {/*<label className="form-label">*/}
                                              {/*  Nearest Station*/}
                                              {/*</label>*/}
                                              <input
                                                placeholder="Nearest Station"
                                                onChange={(e) =>
                                                  this.setState({
                                                    nearest_station:
                                                      e.target.value,
                                                  })
                                                }
                                                className="multisteps-form__input form-control"
                                                defaultValue={this.state.city}
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-12">
                                            <div className="input-group input-group-dynamic">
                                              {/*<label className="form-label">*/}

                                              {/*  Special Instruction*/}
                                              {/*</label>*/}
                                              <textarea
                                                placeholder="Special Instruction"
                                                onChange={(e) =>
                                                  this.setState({
                                                    special_instruction:
                                                      e.target.value,
                                                  })
                                                }
                                                className="mt-4 multisteps-form__textarea form-control"
                                                rows="3"
                                              ></textarea>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <div className="card">
                              <div className="card-body p-3">
                                <div>
                                  <h5 className="font-weight-bolder mb-2">
                                    Customer Information
                                  </h5>
                                  <p className="mb-0 text-sm">
                                    Mandatory informations
                                  </p>
                                  <div className="multisteps-form__content">
                                    <div className="row mt-3">
                                      <div className="col-12 mt-3">
                                        <div className="input-group input-group-dynamic">
                                          {/*<label className="form-label">Company Name</label>*/}
                                          <input
                                            placeholder="Customer Name"
                                            defaultValue={this.state.company}
                                            onChange={(e) =>
                                              this.setState({
                                                company: e.target.value,
                                              })
                                            }
                                            className="multisteps-form__input form-control"
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-6 mt-4">
                                        <div className="input-group input-group-dynamic">
                                          {/*<label className="form-label">*/}
                                          {/*  Phone Number*/}
                                          {/*</label>*/}
                                          <input
                                            placeholder="Phone Number"
                                            minLength="11"
                                            maxLength="11"
                                            defaultValue={this.state.cust_phone}
                                            onChange={(e) =>
                                              this.setState({
                                                cust_phone: e.target.value,
                                              })
                                            }
                                            className="multisteps-form__input form-control"
                                            type="tel"
                                            pattern="[0-9]{3}-[0-9]{8}"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-6 mt-4">
                                        <div className="input-group input-group-dynamic">
                                          <label className="form-label">
                                            Rating:
                                          </label>
                                          {this.state.rating ? (
                                            <div
                                              style={{
                                                marginTop: "5px",
                                                marginLeft: "50px",
                                              }}
                                            >
                                              <StarRatings
                                                rating={this.state.rating}
                                                starDimension="15px"
                                                starSpacing="1px"
                                                starRatedColor="yellow"
                                                name="rating"
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-12 col-sm-12">
                                        <div className="input-group input-group-dynamic">
                                          <input
                                            placeholder="Email"
                                            onChange={(e) =>
                                              this.setState({
                                                email: e.target.value,
                                              })
                                            }
                                            className="multisteps-form__input form-control"
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.visit_is === "seller" ? (
                                      <div className="row mt-3">
                                        <div className="col-12 col-sm-6">
                                          <div className="input-group input-group-dynamic">
                                            {/*<label className="form-label">GST No.</label>*/}
                                            <input
                                              placeholder="GST No."
                                              onChange={(e) =>
                                                this.setState({
                                                  gst_num: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                              minLength="15"
                                              maxLength="15"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                          <div className="input-group input-group-dynamic">
                                            {/*<label className="form-label">*/}
                                            {/*  PAN Number*/}
                                            {/*</label>*/}
                                            <input
                                              placeholder="PAN Number"
                                              onChange={(e) =>
                                                this.setState({
                                                  pan_num: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                              maxLength="10"
                                              minLength="10"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.visit_is === "school" ? (
                                      <div className="col-12 mt-4">
                                        <div className="input-group input-group-dynamic">
                                          {/*<label className="form-label">Websites</label>*/}
                                          <input
                                            placeholder="Websites"
                                            defaultValue={this.state.website}
                                            onChange={(e) =>
                                              this.setState({
                                                website: e.target.value,
                                              })
                                            }
                                            className="multisteps-form__input form-control"
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <div className="card">
                              <div className="card-body p-3">
                                <div id="basic-info">
                                  <div>
                                    <div>
                                      <h5
                                        className="font-weight-bolder mb-2"
                                        // onClick={this.test}
                                      >
                                        Visit
                                      </h5>
                                      <p className="mb-4 text-sm">
                                        Creat Visit Report And Submit
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card-body pt-0">
                                    <div className="row">
                                      <div className="col-6">
                                        {this.state.visit_is === "school" ? (
                                          <div className="input-group input-group-static is-filled">
                                            <label>School Name</label>
                                            <input
                                              defaultValue={this.state.company}
                                              onChange={(e) =>
                                                this.setState({
                                                  company: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                            />
                                          </div>
                                        ) : null}

                                        {this.state.visit_is === "seller" ? (
                                          <div className="input-group input-group-static is-filled">
                                            <label>Seller Name</label>
                                            <input
                                              defaultValue={this.state.company}
                                              onChange={(e) =>
                                                this.setState({
                                                  company: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-6">
                                        <div className="input-group input-group-static is-filled">
                                          <label>Date</label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onFocus="focused(this)"
                                            value={
                                              this.state.date
                                                ? this.state.date
                                                : this.state.today_date
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                date: e.target.value,
                                              })
                                            }
                                            onfocusout="defocused(this)"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row mt-4">
                                      <div className="col-6">
                                        <div className="input-group input-group-static is-filled">
                                          <label>Contact Person Name:</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            onFocus="focused(this)"
                                            onChange={(e) =>
                                              this.setState({
                                                contact_person_name:
                                                  e.target.value,
                                              })
                                            }
                                            onfocusout="defocused(this)"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="input-group input-group-static is-filled">
                                          <label>Phone:</label>
                                          <input
                                            type="tel"
                                            className="form-control"
                                            onFocus="focused(this)"
                                            onChange={(e) =>
                                              this.setState({
                                                contact_person_phone:
                                                  e.target.value,
                                              })
                                            }
                                            onfocusout="defocused(this)"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-12 input-group input-group-static">
                                        <label className="form-label">
                                          Purpose of visit
                                        </label>
                                      </div>
                                      <div className="col-12">
                                        <div className="dropdown mt-5">
                                          <div
                                            className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            id="navbarDropdownMenuLink2"
                                            aria-expanded="false"
                                          >
                                            {/*Purpose of visit*/}
                                            {this.state.working_status
                                              ? this.state.working_status
                                              : "Purpose of visit"}
                                          </div>
                                          <ul
                                            className="dropdown-menu w-50 srkw"
                                            aria-labelledby="navbarDropdownMenuLink2"
                                          >
                                            {this.state.status
                                              ? this.state.status.map(
                                                  (st, index) => (
                                                    <li>
                                                      <div
                                                        className="dropdown-item"
                                                        onClick={(e) =>
                                                          this.getQuestionnaire(
                                                            st.choice
                                                          )
                                                        }
                                                      >
                                                        {st.choice}
                                                      </div>
                                                    </li>
                                                  )
                                                )
                                              : null}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    {this.state.quest ? (
                                      <div className="col-12 w-100 d-flex justify-content-center quest-button">
                                        <div className="card-box card w-75 p-5">
                                          <div className="arrow-up"></div>

                                          {this.state.quest.map(
                                            (qst, index) => (
                                              <div className="row justify-content-center mt-5">
                                                <div className="col-md-8">
                                                  <p>{qst}</p>
                                                </div>
                                                <div className="col-md-4">
                                                  <div className="btn-box">
                                                    <button
                                                      onClick={(e) =>
                                                        this.handleQuestionnaire(
                                                          qst
                                                        )
                                                      }
                                                      type="submit"
                                                      className={
                                                        this.my_array.includes(
                                                          qst
                                                        ) === true
                                                          ? "btn btn-confirm btn-primary"
                                                          : "btn btn-confirm btn-color"
                                                      }
                                                    >
                                                      Yes
                                                    </button>
                                                    <button
                                                      onClick={(e) =>
                                                        this.handleQuestionnaire(
                                                          qst
                                                        )
                                                      }
                                                      type="submit"
                                                      className={
                                                        this.my_array.includes(
                                                          qst
                                                        ) === true
                                                          ? "btn btn-confirm btn-color"
                                                          : "btn btn-confirm btn-primary"
                                                      }
                                                    >
                                                      No
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                          <div className="row justify-content-center">
                                            <div className="col-md-9">
                                              <div className="microphone d-flex flex-column align-items-center">
                                                <div>
                                                  <div
                                                    className="audio-view"
                                                    style={{
                                                      display: "none",
                                                    }}
                                                  >
                                                    <AudioReactRecorder
                                                      canvasWidth={500}
                                                      canvasHeight={100}
                                                      state={
                                                        this.state.recordState
                                                      }
                                                      onStop={this.onStop}
                                                    />
                                                  </div>

                                                  {this.state.start ===
                                                  false ? (
                                                    <div>
                                                      <div
                                                        className="p-4 position-relative"
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                        onClick={this.start}
                                                      >
                                                        <button
                                                          id="speech"
                                                          className="btn micBtn"
                                                        >
                                                          <i
                                                            className="fa fa-microphone"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: "35px",
                                                              position:
                                                                "absolute",
                                                              top: "21px",
                                                              right: "27px",
                                                            }}
                                                          ></i>
                                                        </button>
                                                        {/*<div className="pulse-ring"></div>*/}
                                                      </div>
                                                      {this.state.mp3 ? (
                                                        <div
                                                          className="audioPlayer col-2 ms-3 d-inline-block"
                                                          style={{
                                                            width: "100%",
                                                            justifyContent:
                                                              "center",
                                                            marginBottom:
                                                              "10px",
                                                          }}
                                                        >
                                                          <audio
                                                            src={this.state.mp3}
                                                            controls
                                                          ></audio>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="p-4 position-relative"
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                      onClick={this.stop}
                                                    >
                                                      <button
                                                        id="speech"
                                                        className="btn micBtn"
                                                      >
                                                        <i
                                                          className="fa fa-microphone"
                                                          aria-hidden="true"
                                                          style={{
                                                            fontSize: "35px",
                                                            position:
                                                              "absolute",
                                                            top: "21px",
                                                            right: "27px",
                                                          }}
                                                        ></i>
                                                      </button>
                                                      <div className="pulse-ring"></div>
                                                    </div>
                                                    // <div className="row align-items-center">
                                                    //     <div className="col-1">
                                                    //         <img
                                                    //             height="40px"
                                                    //             onClick={this.stop}
                                                    //             src={url + "/static/pause.png"}
                                                    //         />
                                                    //     </div>
                                                    //
                                                    //     <div className="col-2 listening">
                                                    //         <span className="listening1"></span>
                                                    //         <span className="listening2"></span>
                                                    //         <span className="listening3"></span>
                                                    //         <span className="listening4"></span>
                                                    //         <span className="listening5"></span>
                                                    //     </div>
                                                    // </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="">
                                            <div className="input-group input-group-static is-filled">
                                              <TextField
                                                id="standard-multiline-flexible"
                                                label="Remark"
                                                multiline
                                                maxRows={4}
                                                className="form-control"
                                                onFocus="focused(this)"
                                                onChange={(e) =>
                                                  this.setState({
                                                    quest_remark:
                                                      e.target.value,
                                                  })
                                                }
                                                onfocusout="defocused(this)"
                                                variant="standard"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                {this.state.working_status === "SAMPLING" ? (
                                  <div className="sample-thead mt-4">
                                    <div className="">
                                      <div
                                        className="add-ins float-right d-flex mb-3"
                                        style={{ cursor: "pointer" }}
                                        onClick={this.addSample}
                                      >
                                        <img
                                          src={require("../../../custom/icons/plus.png")}
                                          height="30"
                                        />
                                        <p className="m-1">ADD SAMPLE</p>
                                      </div>
                                    </div>
                                    <div className="table-responsive">
                                      <table className="table mt-2">
                                        <thead>
                                          <tr>
                                            <th>S. No.</th>
                                            <th>Book</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Qty.</th>
                                            <th>Price</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        {this.state.sample_array
                                          ? this.state.sample_array.map(
                                              (sample, index) => (
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      width="7%"
                                                      className="text-center"
                                                    >
                                                      <div
                                                        style={{
                                                          marginTop: "16px",
                                                        }}
                                                      >
                                                        <span className="">
                                                          {index + 1}
                                                        </span>
                                                        .
                                                      </div>
                                                    </td>
                                                    <td width="43%">
                                                      {this.state.series ? (
                                                        <Autocomplete
                                                          className="pl-1 pr-1"
                                                          id="free-solo-demo"
                                                          size={"small"}
                                                          style={{
                                                            marginTop: "16px",
                                                          }}
                                                          options={this.state.series.map(
                                                            (option) =>
                                                              option.title
                                                          )}
                                                          onChange={(
                                                            event,
                                                            value
                                                          ) =>
                                                            this.saveValueToObject(
                                                              sample.id,
                                                              "book",
                                                              value
                                                            )
                                                          }
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              label="Select Book"
                                                            />
                                                          )}
                                                        />
                                                      ) : null}
                                                    </td>

                                                    <td width="10%">
                                                      <div className="">
                                                        {sample.selected_from ? (
                                                          <Autocomplete
                                                            className="pl-1 pr-1"
                                                            id="free-solo-demo"
                                                            size={"small"}
                                                            sx={{
                                                              m: 1,
                                                              minWidth: 120,
                                                            }}
                                                            options={sample.selected_from.map(
                                                              (option) =>
                                                                option.classes__class_name
                                                            )}
                                                            onChange={(
                                                              event,
                                                              value
                                                            ) =>
                                                              this.saveValueToObject(
                                                                sample.id,
                                                                "from",
                                                                sample.selected_from.filter(
                                                                  (i) =>
                                                                    i.classes__class_name ===
                                                                    value
                                                                )[0]
                                                              )
                                                            }
                                                            value={
                                                              sample.class_from
                                                                ? sample
                                                                    .class_from
                                                                    .classes__class_name
                                                                : ""
                                                            }
                                                            renderInput={(
                                                              params
                                                            ) => (
                                                              <TextField
                                                                {...params}
                                                                label="From"
                                                                variant="standard"
                                                              />
                                                            )}
                                                          />
                                                        ) : (
                                                          <FormControl
                                                            variant="standard"
                                                            sx={{
                                                              m: 1,
                                                              minWidth: 120,
                                                            }}
                                                          >
                                                            <InputLabel id="demo-simple-select-standard-label">
                                                              From
                                                            </InputLabel>
                                                            <MUISelect
                                                              labelId="demo-simple-select-standard-label"
                                                              id="demo-simple-select-standard"
                                                              value={
                                                                sample.class_from
                                                                  ? sample.class_from
                                                                  : ""
                                                              }
                                                              onChange={(e) =>
                                                                this.saveValueToObject(
                                                                  sample.id,
                                                                  "from",
                                                                  e.target.value
                                                                )
                                                              }
                                                              label="From"
                                                            >
                                                              <MenuItem
                                                                value={null}
                                                              >
                                                                <em>None</em>
                                                              </MenuItem>
                                                              {sample.selected_from
                                                                ? sample.selected_from.map(
                                                                    (item) => (
                                                                      <MenuItem
                                                                        value={
                                                                          item
                                                                        }
                                                                      >
                                                                        {
                                                                          item.classes__class_name
                                                                        }
                                                                      </MenuItem>
                                                                    )
                                                                  )
                                                                : null}
                                                            </MUISelect>
                                                          </FormControl>
                                                        )}
                                                      </div>
                                                    </td>

                                                    <td width="10%">
                                                      <div className="">
                                                        {sample.selected_to ? (
                                                          <Autocomplete
                                                            className="pl-1 pr-1"
                                                            id="free-solo-demo"
                                                            size={"small"}
                                                            sx={{
                                                              m: 1,
                                                              minWidth: 120,
                                                            }}
                                                            options={sample.selected_to.map(
                                                              (option) =>
                                                                option.classes__class_name
                                                            )}
                                                            onChange={(
                                                              event,
                                                              value
                                                            ) =>
                                                              this.saveValueToObject(
                                                                sample.id,
                                                                "to",
                                                                sample.selected_to.filter(
                                                                  (i) =>
                                                                    i.classes__class_name ===
                                                                    value
                                                                )[0]
                                                              )
                                                            }
                                                            value={
                                                              sample.class_to
                                                                ? sample
                                                                    .class_to
                                                                    .classes__class_name
                                                                : ""
                                                            }
                                                            renderInput={(
                                                              params
                                                            ) => (
                                                              <TextField
                                                                {...params}
                                                                label="To"
                                                                variant="standard"
                                                              />
                                                            )}
                                                          />
                                                        ) : (
                                                          <FormControl
                                                            variant="standard"
                                                            sx={{
                                                              m: 1,
                                                              minWidth: 120,
                                                            }}
                                                          >
                                                            <InputLabel id="demo-simple-select-standard-label">
                                                              To
                                                            </InputLabel>
                                                            <MUISelect
                                                              labelId="demo-simple-select-standard-label"
                                                              id="demo-simple-select-standard"
                                                              value={
                                                                sample.class_to
                                                                  ? sample.class_to
                                                                  : ""
                                                              }
                                                              onChange={(e) =>
                                                                this.saveValueToObject(
                                                                  sample.id,
                                                                  "to",
                                                                  e.target.value
                                                                )
                                                              }
                                                              label="To"
                                                            >
                                                              <MenuItem
                                                                value={null}
                                                              >
                                                                <em>None</em>
                                                              </MenuItem>
                                                              {sample.selected_from
                                                                ? sample.selected_to.map(
                                                                    (item) => (
                                                                      <MenuItem
                                                                        value={
                                                                          item
                                                                        }
                                                                      >
                                                                        {
                                                                          item.classes__class_name
                                                                        }
                                                                      </MenuItem>
                                                                    )
                                                                  )
                                                                : null}
                                                            </MUISelect>
                                                          </FormControl>
                                                        )}
                                                      </div>
                                                    </td>

                                                    <td width="10%">
                                                      <div
                                                        id="div_id_qty"
                                                        className="form-group"
                                                      >
                                                        <div className="">
                                                          <input
                                                            type="number"
                                                            id={sample.id}
                                                            value={
                                                              sample.quantity
                                                                ? parseInt(
                                                                    sample.quantity
                                                                  )
                                                                : ""
                                                            }
                                                            onChange={(e) =>
                                                              this.saveValueToObject(
                                                                sample.id,
                                                                "quantity",
                                                                e.target
                                                                  .value === ""
                                                                  ? 0
                                                                  : e.target
                                                                      .value
                                                              )
                                                            }
                                                            className="form-control w-100 p-2"
                                                            style={{
                                                              background:
                                                                "#ededed",
                                                              marginTop: "16px",
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </td>

                                                    <td width="10%">
                                                      <div
                                                        id="div_id_qty"
                                                        className="form-group"
                                                      >
                                                        <div className="">
                                                          <input
                                                            type="number"
                                                            id={sample.id}
                                                            value={
                                                              sample.price
                                                                ? parseInt(
                                                                    sample.price
                                                                  )
                                                                : ""
                                                            }
                                                            onChange={(e) =>
                                                              this.saveValueToObject(
                                                                sample.id,
                                                                "price",
                                                                e.target
                                                                  .value === ""
                                                                  ? 0
                                                                  : e.target
                                                                      .value
                                                              )
                                                            }
                                                            className="form-control w-100 p-2"
                                                            style={{
                                                              background:
                                                                "#ededed",
                                                              marginTop: "16px",
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </td>

                                                    <td
                                                      width="10%"
                                                      className="text-center"
                                                    >
                                                      <div
                                                        className="btn btn-xs"
                                                        style={{
                                                          color: "red",
                                                          fontSize: "15px",
                                                          marginTop: "16px",
                                                        }}
                                                        onClick={(e) =>
                                                          this.removeSample(
                                                            sample.id
                                                          )
                                                        }
                                                      >
                                                        X
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              )
                                            )
                                          : null}
                                      </table>
                                    </div>
                                  </div>
                                ) : null}

                                {/*<--------------------Visit Manually------------------>*/}
                                <div className="row my-4">
                                  <div className="col-12">
                                    <button
                                      className="btn bg-gradient-dark d-block m-auto"
                                      onClick={this.getlatlong}
                                    >
                                      Submitrrrr
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>

            {/*<----------------------------Mobile View-------------------------->*/}
            <div className="d-lg-none">
              <div className="dashOption-mv my-3 px-2">
                <div className="">
                  <div className="button-bar-mv d-flex">
                    <Link to="/visit" className="btn btn-mv ">
                      <img
                        src={require("../../../kavi/icons/dashbord.png")}
                        alt="btn-icon"
                      />
                      Dashboard
                    </Link>
                    <Link
                      to="/visit/create-new-visit "
                      className="btn btn-mv active"
                    >
                      <img
                        src={require("../../../kavi/icons/add.png")}
                        alt="btn-icon"
                      />
                      Add Visit
                    </Link>
                  </div>
                </div>
              </div>
              <div className="w-100">
                <div className=" row mb-0 align-items-center">
                  <div className="col-12">
                    <div className="nav-wrapper position-relative end-0 pt-3 px-1">
                      <ul className="nav nav-pills nav-fill p-1" role="tablist">
                        <li className="nav-item">
                          <div
                            className="nav-link mb-0 px-0 py-1 active "
                            data-bs-toggle="tab"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => this.refreshPage("school")}
                            role="tab"
                            aria-selected="true"
                          >
                            School Visit
                          </div>
                        </li>
                        <li className="nav-item">
                          <div
                            className="nav-link mb-0 px-0 py-1 "
                            data-bs-toggle="tab"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => this.refreshPage("seller")}
                            role="tab"
                            aria-selected="false"
                          >
                            Seller Visit
                          </div>
                        </li>
                        <div
                          className="moving-tab position-absolute nav-link"
                          style={
                            this.state.visit_is === "school" ||
                            this.state.visit_is === "school manually"
                              ? {
                                  padding: "0px",
                                  transition: "all 0.5s ease 0s",
                                  // transform: "translate3d(0px, 0px, 0px)",
                                  transform: "translate3d(0%, 0px, 0px)",
                                  width: "50%",
                                }
                              : this.state.visit_is === "seller" ||
                                this.state.visit_is === "seller manually"
                              ? {
                                  padding: "0px",
                                  transition: "all 0.5s ease 0s",
                                  // transform: "translate3d(155px, 0px, 0px)",
                                  transform: "translate3d(95%, 0px, 0px)",
                                  width: "50%",
                                }
                              : null
                          }
                        >
                          <div
                            className="nav-link mb-0 px-0 py-1 active "
                            data-bs-toggle="tab"
                            role="tab"
                            aria-selected="true"
                          >
                            -
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row col-12 p-0 mt-3">
                  <div className="form-check col-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={this.state.radio_manually}
                      onClick={(e) => this.handleRadio("radio_manually")}
                    />
                    <label className="form-check-label mb-0">
                      Create Visit
                    </label>
                  </div>

                  <div className="form-check col-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={this.state.radio_trip}
                      onClick={(e) => this.handleRadio("radio_trip")}
                    />
                    <label className="form-check-label mb-0">
                      Create Visit From Trip
                    </label>
                  </div>
                </div>
                <div className="px-2 row">
                  <>
                    {this.state.visit_type === "trip" ? (
                      <>
                        <div>
                          <h5 className="font-weight-bolder mb-2">Visit</h5>
                          <p className="mb-4 text-sm">
                            Creat Visit Report And Submit
                          </p>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-12">
                              {this.state.trip ? (
                                <div className="mt-4">
                                  <Select
                                    placeholder="Select Trip"
                                    options={this.state.trip.map(
                                      (item, index) => ({
                                        value: item,
                                        label: item.customer__company,
                                      })
                                    )}
                                    onChange={this.selectTrip}
                                  />
                                </div>
                              ) : null}
                            </div>
                            <div className="col-12">
                              <div className="input-group input-group-static is-filled mt-3">
                                <label>Date</label>
                                <input
                                  type="date"
                                  className="form-control form-control-mv"
                                  onFocus="focused(this)"
                                  value={
                                    this.state.date
                                      ? this.state.date
                                      : this.state.today_date
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      date: e.target.value,
                                    })
                                  }
                                  onfocusout="defocused(this)"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-12 mt-2">
                              <div className="input-group input-group-static is-filled">
                                <label>Contact Person Name:</label>
                                <input
                                  type="text"
                                  className="form-control form-control-mv"
                                  onFocus="focused(this)"
                                  onChange={(e) =>
                                    this.setState({
                                      contact_person_name: e.target.value,
                                    })
                                  }
                                  onfocusout="defocused(this)"
                                />
                              </div>
                            </div>
                            <div className="col-12 mt-2">
                              <div className="col-12 input-group input-group-static">
                                <label className="form-label">
                                  Purpose of visit
                                </label>
                              </div>
                              <div className="col-12 w-100">
                                <div className="dropdown mt-2 w-100">
                                  <div
                                    className="m-0 btn w-100 srkw bg-gradient-dark dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    id="navbarDropdownMenuLink2"
                                    aria-expanded="false"
                                  >
                                    {/*Purpose of visit*/}
                                    {this.state.working_status
                                      ? this.state.working_status
                                      : "Purpose of visit"}
                                  </div>
                                  <ul
                                    className="dropdown-menu w-100 srkw"
                                    aria-labelledby="navbarDropdownMenuLink2"
                                  >
                                    {this.state.status
                                      ? this.state.status.map((st, index) => (
                                          <li>
                                            <div
                                              className="dropdown-item"
                                              onClick={(e) =>
                                                this.getQuestionnaire(st.choice)
                                              }
                                            >
                                              {st.choice}
                                            </div>
                                          </li>
                                        ))
                                      : null}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            {this.state.quest ? (
                              <div className="col-12 w-100 d-flex justify-content-center quest-button">
                                <div className="card-box card w-100 p-5">
                                  <div className="arrow-up"></div>

                                  {this.state.quest.map((qst, index) => (
                                    <div className="row justify-content-center mt-5">
                                      <div className="col-md-8">
                                        <p>{qst}</p>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="btn-box">
                                          <button
                                            onClick={(e) =>
                                              this.handleQuestionnaire(qst)
                                            }
                                            type="submit"
                                            className={
                                              this.my_array.includes(qst) ===
                                              true
                                                ? "btn btn-confirm btn-primary"
                                                : "btn btn-confirm btn-color"
                                            }
                                          >
                                            Yes
                                          </button>
                                          <button
                                            onClick={(e) =>
                                              this.handleQuestionnaire(qst)
                                            }
                                            type="submit"
                                            className={
                                              this.my_array.includes(qst) ===
                                              true
                                                ? "btn btn-confirm btn-color"
                                                : "btn btn-confirm btn-primary"
                                            }
                                          >
                                            No
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className="row justify-content-center">
                                    <div className="col-md-9">
                                      <div className="microphone d-flex flex-column align-items-center">
                                        <div>
                                          <div
                                            className="audio-view"
                                            style={{
                                              display: "none",
                                            }}
                                          >
                                            <AudioReactRecorder
                                              canvasWidth={500}
                                              canvasHeight={100}
                                              state={this.state.recordState}
                                              onStop={this.onStop}
                                            />
                                          </div>

                                          {this.state.start === false ? (
                                            <div>
                                              <div
                                                className="p-4 position-relative"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                                onClick={this.start}
                                              >
                                                <button
                                                  id="speech"
                                                  className="btn micBtn"
                                                >
                                                  <i
                                                    className="fa fa-microphone"
                                                    aria-hidden="true"
                                                    style={{
                                                      fontSize: "35px",
                                                      position: "absolute",
                                                      top: "21px",
                                                      right: "27px",
                                                    }}
                                                  ></i>
                                                </button>
                                                {/*<div className="pulse-ring"></div>*/}
                                              </div>
                                              {this.state.mp3 ? (
                                                <div
                                                  className="audioPlayer col-2 ms-3 d-inline-block"
                                                  style={{
                                                    width: "100%",
                                                    justifyContent: "center",
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  <audio
                                                    src={this.state.mp3}
                                                    controls
                                                  ></audio>
                                                </div>
                                              ) : null}
                                            </div>
                                          ) : (
                                            <div
                                              className="p-4 position-relative"
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                              onClick={this.stop}
                                            >
                                              <button
                                                id="speech"
                                                className="btn micBtn"
                                              >
                                                <i
                                                  className="fa fa-microphone"
                                                  aria-hidden="true"
                                                  style={{
                                                    fontSize: "35px",
                                                    position: "absolute",
                                                    top: "21px",
                                                    right: "27px",
                                                  }}
                                                ></i>
                                              </button>
                                              <div className="pulse-ring"></div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <Box
                                    component="form"
                                    sx={{
                                      "& .MuiTextField-root": {
                                        m: 1,
                                        width: "25ch",
                                      },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <div className="input-group input-group-static is-filled">
                                      <TextField
                                        id="standard-multiline-flexible"
                                        label="Remark"
                                        multiline
                                        maxRows={4}
                                        className="form-control"
                                        onFocus="focused(this)"
                                        onChange={(e) =>
                                          this.setState({
                                            quest_remark: e.target.value,
                                          })
                                        }
                                        onfocusout="defocused(this)"
                                        variant="standard"
                                      />
                                    </div>
                                  </Box>
                                </div>
                              </div>
                            ) : null}
                          </div>

                          {this.state.working_status === "SAMPLING" ? (
                            <div className="sample-thead mt-4">
                              <div className="">
                                <div
                                  className="add-ins float-right d-flex mb-3"
                                  style={{ cursor: "pointer" }}
                                  onClick={this.addSample}
                                >
                                  <img
                                    src={require("../../../custom/icons/plus.png")}
                                    height="30"
                                  />
                                  <p className="m-1">ADD SAMPLE</p>
                                </div>
                              </div>
                              <div className="table-responsive">
                                <table className="table mt-2">
                                  <thead>
                                    <tr>
                                      <th>S. No.</th>
                                      <th>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </th>
                                      <th>From</th>
                                      <th>To</th>
                                      <th>&nbsp;&nbsp;Qty.&nbsp;&nbsp;</th>
                                      <th>&nbsp;&nbsp;Price&nbsp;&nbsp;</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  {this.state.sample_array
                                    ? this.state.sample_array.map(
                                        (sample, index) => (
                                          <tbody>
                                            <tr>
                                              <td
                                                width="7%"
                                                className="text-center"
                                              >
                                                <div
                                                  style={{
                                                    marginTop: "16px",
                                                  }}
                                                >
                                                  <span className="">
                                                    {index + 1}
                                                  </span>
                                                  .
                                                </div>
                                              </td>
                                              <td width="43%">
                                                {this.state.series ? (
                                                  <Autocomplete
                                                    className="pl-1 pr-1"
                                                    id="free-solo-demo"
                                                    size={"small"}
                                                    style={{
                                                      marginTop: "16px",
                                                      width: "100%",
                                                    }}
                                                    options={this.state.series.map(
                                                      (option) => option.title
                                                    )}
                                                    onChange={(event, value) =>
                                                      this.saveValueToObject(
                                                        sample.id,
                                                        "book",
                                                        value
                                                      )
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label="Select Book"
                                                      />
                                                    )}
                                                  />
                                                ) : null}
                                              </td>

                                              <td width="10%">
                                                <div className="">
                                                  {sample.selected_from ? (
                                                    <Autocomplete
                                                      className="pl-1 pr-1"
                                                      id="free-solo-demo"
                                                      size={"small"}
                                                      sx={{
                                                        m: 1,
                                                        minWidth: 120,
                                                      }}
                                                      options={sample.selected_from.map(
                                                        (option) =>
                                                          option.classes__class_name
                                                      )}
                                                      onChange={(
                                                        event,
                                                        value
                                                      ) =>
                                                        this.saveValueToObject(
                                                          sample.id,
                                                          "from",
                                                          sample.selected_from.filter(
                                                            (i) =>
                                                              i.classes__class_name ===
                                                              value
                                                          )[0]
                                                        )
                                                      }
                                                      value={
                                                        sample.class_from
                                                          ? sample.class_from
                                                              .classes__class_name
                                                          : ""
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="From"
                                                          variant="standard"
                                                        />
                                                      )}
                                                    />
                                                  ) : (
                                                    <FormControl
                                                      variant="standard"
                                                      sx={{
                                                        m: 1,
                                                        minWidth: 120,
                                                      }}
                                                    >
                                                      <InputLabel id="demo-simple-select-standard-label">
                                                        From
                                                      </InputLabel>
                                                      <MUISelect
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={
                                                          sample.class_from
                                                            ? sample.class_from
                                                            : ""
                                                        }
                                                        onChange={(e) =>
                                                          this.saveValueToObject(
                                                            sample.id,
                                                            "from",
                                                            e.target.value
                                                          )
                                                        }
                                                        label="From"
                                                      >
                                                        <MenuItem value={null}>
                                                          <em>None</em>
                                                        </MenuItem>
                                                        {sample.selected_from
                                                          ? sample.selected_from.map(
                                                              (item) => (
                                                                <MenuItem
                                                                  value={item}
                                                                >
                                                                  {
                                                                    item.classes__class_name
                                                                  }
                                                                </MenuItem>
                                                              )
                                                            )
                                                          : null}
                                                      </MUISelect>
                                                    </FormControl>
                                                  )}
                                                </div>
                                              </td>

                                              <td width="10%">
                                                <div className="">
                                                  {sample.selected_to ? (
                                                    <Autocomplete
                                                      className="pl-1 pr-1"
                                                      id="free-solo-demo"
                                                      size={"small"}
                                                      sx={{
                                                        m: 1,
                                                        minWidth: 120,
                                                      }}
                                                      options={sample.selected_to.map(
                                                        (option) =>
                                                          option.classes__class_name
                                                      )}
                                                      onChange={(
                                                        event,
                                                        value
                                                      ) =>
                                                        this.saveValueToObject(
                                                          sample.id,
                                                          "to",
                                                          sample.selected_to.filter(
                                                            (i) =>
                                                              i.classes__class_name ===
                                                              value
                                                          )[0]
                                                        )
                                                      }
                                                      value={
                                                        sample.class_to
                                                          ? sample.class_to
                                                              .classes__class_name
                                                          : ""
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="To"
                                                          variant="standard"
                                                        />
                                                      )}
                                                    />
                                                  ) : (
                                                    <FormControl
                                                      variant="standard"
                                                      sx={{
                                                        m: 1,
                                                        minWidth: 120,
                                                      }}
                                                    >
                                                      <InputLabel id="demo-simple-select-standard-label">
                                                        To
                                                      </InputLabel>
                                                      <MUISelect
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={
                                                          sample.class_to
                                                            ? sample.class_to
                                                            : ""
                                                        }
                                                        onChange={(e) =>
                                                          this.saveValueToObject(
                                                            sample.id,
                                                            "to",
                                                            e.target.value
                                                          )
                                                        }
                                                        label="To"
                                                      >
                                                        <MenuItem value={null}>
                                                          <em>None</em>
                                                        </MenuItem>
                                                        {sample.selected_from
                                                          ? sample.selected_to.map(
                                                              (item) => (
                                                                <MenuItem
                                                                  value={item}
                                                                >
                                                                  {
                                                                    item.classes__class_name
                                                                  }
                                                                </MenuItem>
                                                              )
                                                            )
                                                          : null}
                                                      </MUISelect>
                                                    </FormControl>
                                                  )}
                                                </div>
                                              </td>

                                              <td width="10%">
                                                <div
                                                  id="div_id_qty"
                                                  className="form-group"
                                                >
                                                  <div className="">
                                                    <input
                                                      type="number"
                                                      id={sample.id}
                                                      value={
                                                        sample.quantity
                                                          ? parseInt(
                                                              sample.quantity
                                                            )
                                                          : ""
                                                      }
                                                      onChange={(e) =>
                                                        this.saveValueToObject(
                                                          sample.id,
                                                          "quantity",
                                                          e.target.value === ""
                                                            ? 0
                                                            : e.target.value
                                                        )
                                                      }
                                                      className="form-control w-100 p-2"
                                                      style={{
                                                        background: "#ededed",
                                                        marginTop: "16px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>

                                              <td width="10%">
                                                <div
                                                  id="div_id_qty"
                                                  className="form-group"
                                                >
                                                  <div className="">
                                                    <input
                                                      type="number"
                                                      id={sample.id}
                                                      value={
                                                        sample.price
                                                          ? parseInt(
                                                              sample.price
                                                            )
                                                          : ""
                                                      }
                                                      onChange={(e) =>
                                                        this.saveValueToObject(
                                                          sample.id,
                                                          "price",
                                                          e.target.value === ""
                                                            ? 0
                                                            : e.target.value
                                                        )
                                                      }
                                                      className="form-control w-100 p-2"
                                                      style={{
                                                        background: "#ededed",
                                                        marginTop: "16px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>

                                              <td
                                                width="10%"
                                                className="text-center"
                                              >
                                                <div
                                                  className="btn btn-xs"
                                                  style={{
                                                    color: "red",
                                                    fontSize: "15px",
                                                    marginTop: "16px",
                                                  }}
                                                  onClick={(e) =>
                                                    this.removeSample(sample.id)
                                                  }
                                                >
                                                  X
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                      )
                                    : null}
                                </table>
                              </div>
                            </div>
                          ) : null}

                          {/*<--------------------Visit By Trip------------------>*/}
                          <div className="row my-4">
                            <div
                              className="col-12"
                              style={{ marginBottom: "100px" }}
                            >
                              <button
                                className="btn bg-gradient-dark d-block m-auto"
                                onClick={this.submit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="">
                        <div
                          className={
                            this.state.address_type === "address"
                              ? "col-md-6 mt-3"
                              : "col-md-12 mt-3"
                          }
                        >
                          <div className="card mb-5">
                            <div className="card-body py-3">
                              <div>
                                <div className="row">
                                  <h5 className="font-weight-bold mb-0">
                                    Address
                                  </h5>
                                  <p className="mb-0 text-sm">
                                    Tell us where are you living
                                  </p>
                                  <div
                                    className={
                                      this.state.address_type === "address"
                                        ? "col-md-12"
                                        : "col-md-5"
                                    }
                                  >
                                    <div className="multisteps-form__content">
                                      <div className="row mt-2">
                                        <div className="col-12 ">
                                          <div className="input-group input-group-dynamic">
                                            {/*<label className="form-label">Street Name</label>*/}
                                            <input
                                              placeholder="Street Name"
                                              onChange={(e) =>
                                                this.setState({
                                                  street_name: e.target.value,
                                                })
                                              }
                                              defaultValue={
                                                this.state.city
                                                  ? this.state.street +
                                                    this.state.block +
                                                    this.state.sector +
                                                    this.state.route +
                                                    this.state.landmark +
                                                    this.state.area
                                                  : null
                                              }
                                              className="multisteps-form__input form-control form-control-mv"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-12 ">
                                          <div className="input-group input-group-dynamic">
                                            {/*<label className="form-label">City</label>*/}
                                            <input
                                              placeholder="City"
                                              onChange={(e) =>
                                                this.setState({
                                                  city: e.target.value,
                                                })
                                              }
                                              defaultValue={this.state.city}
                                              className="multisteps-form__input form-control form-control-mv"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-12 ">
                                          <div className="input-group input-group-dynamic">
                                            {/*<label className="form-label">Pin Code</label>*/}
                                            <input
                                              placeholder="Pin Code"
                                              onChange={(e) =>
                                                this.setState({
                                                  pin_code: e.target.value,
                                                })
                                              }
                                              defaultValue={this.state.pin_code}
                                              className="multisteps-form__input form-control form-control-mv"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-12 ">
                                          <div className="input-group input-group-dynamic">
                                            {/*<label className="form-label">State</label>*/}
                                            <input
                                              placeholder="State"
                                              onChange={(e) =>
                                                this.setState({
                                                  state: e.target.value,
                                                })
                                              }
                                              defaultValue={this.state.state}
                                              className="multisteps-form__input form-control form-control-mv"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-12 mb-2">
                                          <div className="input-group input-group-dynamic">
                                            {/*<label className="form-label">*/}
                                            {/*  Transport Name*/}
                                            {/*</label>*/}
                                            <input
                                              placeholder="Transport Name"
                                              onChange={(e) =>
                                                this.setState({
                                                  transport_name:
                                                    e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control form-control-mv"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="input-group input-group-dynamic">
                                            {/*<label className="form-label">*/}
                                            {/*  Nearest Station*/}
                                            {/*</label>*/}
                                            <input
                                              placeholder="Nearest Station"
                                              onChange={(e) =>
                                                this.setState({
                                                  nearest_station:
                                                    e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control form-control-mv"
                                              defaultValue={this.state.city}
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-2    ">
                                        <div className="col-12">
                                          <div className="input-group input-group-dynamic">
                                            {/*<label className="form-label">*/}

                                            {/*  Special Instruction*/}
                                            {/*</label>*/}
                                            <textarea
                                              placeholder="Special Instruction"
                                              onChange={(e) =>
                                                this.setState({
                                                  special_instruction:
                                                    e.target.value,
                                                })
                                              }
                                              className="mt-4 multisteps-form__textarea form-control form-control-mv"
                                              rows="3"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 mt-3">
                          <div className="card">
                            <div className="card-body p-3">
                              <div>
                                <h5 className="font-weight-bolder mb-2">
                                  Customer Information
                                </h5>
                                <p className="mb-0 text-sm">
                                  Mandatory informations
                                </p>
                                <div className="multisteps-form__content">
                                  <div className="row mt-3">
                                    <div className="col-12 mt-3">
                                      <div className="input-group input-group-dynamic">
                                        {/*<label className="form-label">Company Name</label>*/}
                                        <input
                                          placeholder="Customer Name"
                                          defaultValue={this.state.company}
                                          onChange={(e) =>
                                            this.setState({
                                              company: e.target.value,
                                            })
                                          }
                                          className="multisteps-form__input form-control form-control-mv"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    {this.state.visit_is === "school" ? (
                                      <div className="col-12 mt-3">
                                        <div className="input-group input-group-dynamic">
                                          {boards ? (
                                            <Autocomplete
                                              className="pl-1 pr-1"
                                              id="free-solo-demo"
                                              size={"small"}
                                              sx={{ width: "100%" }}
                                              onChange={(event, newValue) => {
                                                this.setState({
                                                  board: newValue,
                                                });
                                              }}
                                              options={boards.map(
                                                (option) => option.name
                                              )}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Select Board"
                                                />
                                              )}
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="col-12 mt-4">
                                      <div className="input-group input-group-dynamic">
                                        {/*<label className="form-label">*/}
                                        {/*  Phone Number*/}
                                        {/*</label>*/}
                                        <input
                                          placeholder="Phone Number"
                                          minLength="11"
                                          maxLength="11"
                                          defaultValue={this.state.cust_phone}
                                          onChange={(e) =>
                                            this.setState({
                                              cust_phone: e.target.value,
                                            })
                                          }
                                          className="multisteps-form__input form-control form-control-mv"
                                          type="tel"
                                          pattern="[0-9]{3}-[0-9]{8}"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-12">
                                      <div className="input-group input-group-dynamic">
                                        <input
                                          placeholder="Email"
                                          onChange={(e) =>
                                            this.setState({
                                              email: e.target.value,
                                            })
                                          }
                                          className="multisteps-form__input form-control form-control-mv"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.visit_is === "seller" ? (
                                    <div className="row mt-3">
                                      <div className="col-12">
                                        <div className="input-group input-group-dynamic">
                                          {/*<label className="form-label">GST No.</label>*/}
                                          <input
                                            placeholder="GST No."
                                            onChange={(e) =>
                                              this.setState({
                                                gst_num: e.target.value,
                                              })
                                            }
                                            className="multisteps-form__input form-control form-control-mv"
                                            type="text"
                                            minLength="15"
                                            maxLength="15"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 mt-3 mt-sm-0">
                                        <div className="input-group input-group-dynamic">
                                          {/*<label className="form-label">*/}
                                          {/*  PAN Number*/}
                                          {/*</label>*/}
                                          <input
                                            placeholder="PAN Number"
                                            onChange={(e) =>
                                              this.setState({
                                                pan_num: e.target.value,
                                              })
                                            }
                                            className="multisteps-form__input form-control form-control-mv"
                                            type="text"
                                            maxLength="10"
                                            minLength="10"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.visit_is === "school" ? (
                                    <div className="col-12 mt-4">
                                      <div className="input-group input-group-dynamic">
                                        {/*<label className="form-label">Websites</label>*/}
                                        <input
                                          placeholder="Websites"
                                          defaultValue={this.state.website}
                                          onChange={(e) =>
                                            this.setState({
                                              website: e.target.value,
                                            })
                                          }
                                          className="multisteps-form__input form-control form-control-mv"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="card ">
                            <div className="card-body p-3 mb-5">
                              <div id="basic-info">
                                <div>
                                  <div>
                                    <h5 className="font-weight-bolder mb-2">
                                      Visit
                                    </h5>
                                    <p className="mb-4 text-sm">
                                      Creat Visit Report And Submit
                                    </p>
                                  </div>
                                </div>
                                <div className="card-body pt-0">
                                  <div className="row">
                                    <div className="col-12">
                                      {this.state.visit_is === "school" ? (
                                        <div className="input-group input-group-static is-filled">
                                          <label>School Name</label>
                                          <input
                                            defaultValue={this.state.company}
                                            onChange={(e) =>
                                              this.setState({
                                                company: e.target.value,
                                              })
                                            }
                                            className="multisteps-form__input form-control form-control-mv"
                                            type="text"
                                          />
                                        </div>
                                      ) : null}

                                      {this.state.visit_is === "seller" ? (
                                        <div className="input-group input-group-static is-filled">
                                          <label>Seller Name</label>
                                          <input
                                            defaultValue={this.state.company}
                                            onChange={(e) =>
                                              this.setState({
                                                company: e.target.value,
                                              })
                                            }
                                            className="multisteps-form__input form-control form-control-mv"
                                            type="text"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-12 mt-2">
                                      <div className="input-group input-group-static is-filled">
                                        <label>Date</label>
                                        <input
                                          type="date"
                                          className="form-control form-control-mv"
                                          onFocus="focused(this)"
                                          value={
                                            this.state.date
                                              ? this.state.date
                                              : this.state.today_date
                                          }
                                          onChange={(e) =>
                                            this.setState({
                                              date: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-12 mt-2">
                                      <div className="input-group input-group-static is-filled">
                                        <label>Contact Person Name:</label>
                                        <input
                                          type="text"
                                          className="form-control form-control-mv"
                                          onFocus="focused(this)"
                                          onChange={(e) =>
                                            this.setState({
                                              contact_person_name:
                                                e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                      <div className="input-group input-group-static is-filled">
                                        <label>Phone:</label>
                                        <input
                                          type="tel"
                                          className="form-control form-control-mv"
                                          onFocus="focused(this)"
                                          onChange={(e) =>
                                            this.setState({
                                              contact_person_phone:
                                                e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-12 input-group input-group-static">
                                      <label className="form-label">
                                        Purpose of visit
                                      </label>
                                    </div>
                                    <div className="col-12">
                                      <div className="dropdown mt-5">
                                        <div
                                          className="m-0 btn w-100 srkw bg-gradient-dark dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          id="navbarDropdownMenuLink2"
                                          aria-expanded="false"
                                        >
                                          {/*Purpose of visit*/}
                                          {this.state.working_status
                                            ? this.state.working_status
                                            : "Purpose of visit"}
                                        </div>
                                        <ul
                                          className="dropdown-menu w-100 srkw"
                                          aria-labelledby="navbarDropdownMenuLink2"
                                        >
                                          {this.state.status
                                            ? this.state.status.map(
                                                (st, index) => (
                                                  <li>
                                                    <div
                                                      className="dropdown-item"
                                                      onClick={(e) =>
                                                        this.getQuestionnaire(
                                                          st.choice
                                                        )
                                                      }
                                                    >
                                                      {st.choice}
                                                    </div>
                                                  </li>
                                                )
                                              )
                                            : null}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  {this.state.quest ? (
                                    <div className="col-12 w-100 d-flex justify-content-center quest-button">
                                      <div className="card-box card w-75 p-5">
                                        <div className="arrow-up"></div>

                                        {this.state.quest.map((qst, index) => (
                                          <div className="row justify-content-center mt-5">
                                            <div className="col-md-8">
                                              <p>{qst}</p>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="btn-box">
                                                <button
                                                  onClick={(e) =>
                                                    this.handleQuestionnaire(
                                                      qst
                                                    )
                                                  }
                                                  type="submit"
                                                  className={
                                                    this.my_array.includes(
                                                      qst
                                                    ) === true
                                                      ? "btn btn-confirm btn-primary"
                                                      : "btn btn-confirm btn-color"
                                                  }
                                                >
                                                  Yes
                                                </button>
                                                <button
                                                  onClick={(e) =>
                                                    this.handleQuestionnaire(
                                                      qst
                                                    )
                                                  }
                                                  type="submit"
                                                  className={
                                                    this.my_array.includes(
                                                      qst
                                                    ) === true
                                                      ? "btn btn-confirm btn-color"
                                                      : "btn btn-confirm btn-primary"
                                                  }
                                                >
                                                  No
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                        <div className="row justify-content-center">
                                          <div className="col-md-9">
                                            <div className="microphone d-flex flex-column align-items-center">
                                              <div>
                                                <div
                                                  className="audio-view"
                                                  style={{
                                                    display: "none",
                                                  }}
                                                >
                                                  <AudioReactRecorder
                                                    canvasWidth={500}
                                                    canvasHeight={100}
                                                    state={
                                                      this.state.recordState
                                                    }
                                                    onStop={this.onStop}
                                                  />
                                                </div>

                                                {this.state.start === false ? (
                                                  <div>
                                                    <div
                                                      className="p-4 position-relative"
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                      onClick={this.start}
                                                    >
                                                      <button
                                                        id="speech"
                                                        className="btn micBtn"
                                                      >
                                                        <i
                                                          className="fa fa-microphone"
                                                          aria-hidden="true"
                                                          style={{
                                                            fontSize: "35px",
                                                            position:
                                                              "absolute",
                                                            top: "21px",
                                                            right: "27px",
                                                          }}
                                                        ></i>
                                                      </button>
                                                      {/*<div className="pulse-ring"></div>*/}
                                                    </div>
                                                    {this.state.mp3 ? (
                                                      <div
                                                        className="audioPlayer col-2 ms-3 d-inline-block"
                                                        style={{
                                                          width: "100%",
                                                          justifyContent:
                                                            "center",
                                                          marginBottom: "10px",
                                                        }}
                                                      >
                                                        <audio
                                                          src={this.state.mp3}
                                                          controls
                                                        ></audio>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="p-4 position-relative"
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                    onClick={this.stop}
                                                  >
                                                    <button
                                                      id="speech"
                                                      className="btn micBtn"
                                                    >
                                                      <i
                                                        className="fa fa-microphone"
                                                        aria-hidden="true"
                                                        style={{
                                                          fontSize: "35px",
                                                          position: "absolute",
                                                          top: "21px",
                                                          right: "27px",
                                                        }}
                                                      ></i>
                                                    </button>
                                                    <div className="pulse-ring"></div>
                                                  </div>
                                                  // <div className="row align-items-center">
                                                  //     <div className="col-1">
                                                  //         <img
                                                  //             height="40px"
                                                  //             onClick={this.stop}
                                                  //             src={url + "/static/pause.png"}
                                                  //         />
                                                  //     </div>
                                                  //
                                                  //     <div className="col-2 listening">
                                                  //         <span className="listening1"></span>
                                                  //         <span className="listening2"></span>
                                                  //         <span className="listening3"></span>
                                                  //         <span className="listening4"></span>
                                                  //         <span className="listening5"></span>
                                                  //     </div>
                                                  // </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <Box
                                          component="form"
                                          sx={{
                                            "& .MuiTextField-root": {
                                              m: 1,
                                              width: "25ch",
                                            },
                                          }}
                                          noValidate
                                          autoComplete="off"
                                        >
                                          <div className="input-group input-group-static is-filled">
                                            <TextField
                                              id="standard-multiline-flexible"
                                              label="Remark"
                                              multiline
                                              maxRows={4}
                                              className="form-control"
                                              onFocus="focused(this)"
                                              onChange={(e) =>
                                                this.setState({
                                                  quest_remark: e.target.value,
                                                })
                                              }
                                              onfocusout="defocused(this)"
                                              variant="standard"
                                            />
                                          </div>
                                        </Box>
                                      </div>
                                    </div>
                                  ) : null}

                                  {this.state.working_status === "SAMPLING" ? (
                                    <div className="sample-thead mt-4">
                                      <div className="">
                                        <div
                                          className="add-ins float-right d-flex mb-3"
                                          style={{ cursor: "pointer" }}
                                          onClick={this.addSample}
                                        >
                                          <img
                                            src={require("../../../custom/icons/plus.png")}
                                            height="30"
                                          />
                                          <p className="m-1">ADD SAMPLE</p>
                                        </div>
                                      </div>
                                      <div className="table-responsive">
                                        <table className="table mt-2">
                                          <thead>
                                            <tr>
                                              <th>S. No.</th>
                                              <th>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              </th>
                                              <th>From</th>
                                              <th>To</th>
                                              <th>
                                                &nbsp;&nbsp;Qty.&nbsp;&nbsp;
                                              </th>
                                              <th>
                                                &nbsp;&nbsp;Price&nbsp;&nbsp;
                                              </th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          {this.state.sample_array
                                            ? this.state.sample_array.map(
                                                (sample, index) => (
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        width="7%"
                                                        className="text-center"
                                                      >
                                                        <div
                                                          style={{
                                                            marginTop: "16px",
                                                          }}
                                                        >
                                                          <span className="">
                                                            {index + 1}
                                                          </span>
                                                          .
                                                        </div>
                                                      </td>
                                                      <td width="43%">
                                                        {this.state.series ? (
                                                          <Autocomplete
                                                            className="pl-1 pr-1"
                                                            id="free-solo-demo"
                                                            size={"small"}
                                                            style={{
                                                              marginTop: "16px",
                                                              width: "100%",
                                                            }}
                                                            options={this.state.series.map(
                                                              (option) =>
                                                                option.title
                                                            )}
                                                            onChange={(
                                                              event,
                                                              value
                                                            ) =>
                                                              this.saveValueToObject(
                                                                sample.id,
                                                                "book",
                                                                value
                                                              )
                                                            }
                                                            renderInput={(
                                                              params
                                                            ) => (
                                                              <TextField
                                                                {...params}
                                                                label="Select Book"
                                                              />
                                                            )}
                                                          />
                                                        ) : null}
                                                      </td>

                                                      <td width="10%">
                                                        <div className="">
                                                          {sample.selected_from ? (
                                                            <Autocomplete
                                                              className="pl-1 pr-1"
                                                              id="free-solo-demo"
                                                              size={"small"}
                                                              sx={{
                                                                m: 1,
                                                                minWidth: 120,
                                                              }}
                                                              options={sample.selected_from.map(
                                                                (option) =>
                                                                  option.classes__class_name
                                                              )}
                                                              onChange={(
                                                                event,
                                                                value
                                                              ) =>
                                                                this.saveValueToObject(
                                                                  sample.id,
                                                                  "from",
                                                                  sample.selected_from.filter(
                                                                    (i) =>
                                                                      i.classes__class_name ===
                                                                      value
                                                                  )[0]
                                                                )
                                                              }
                                                              value={
                                                                sample.class_from
                                                                  ? sample
                                                                      .class_from
                                                                      .classes__class_name
                                                                  : ""
                                                              }
                                                              renderInput={(
                                                                params
                                                              ) => (
                                                                <TextField
                                                                  {...params}
                                                                  label="From"
                                                                  variant="standard"
                                                                />
                                                              )}
                                                            />
                                                          ) : (
                                                            <FormControl
                                                              variant="standard"
                                                              sx={{
                                                                m: 1,
                                                                minWidth: 120,
                                                              }}
                                                            >
                                                              <InputLabel id="demo-simple-select-standard-label">
                                                                From
                                                              </InputLabel>
                                                              <MUISelect
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={
                                                                  sample.class_from
                                                                    ? sample.class_from
                                                                    : ""
                                                                }
                                                                onChange={(e) =>
                                                                  this.saveValueToObject(
                                                                    sample.id,
                                                                    "from",
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                label="From"
                                                              >
                                                                <MenuItem
                                                                  value={null}
                                                                >
                                                                  <em>None</em>
                                                                </MenuItem>
                                                                {sample.selected_from
                                                                  ? sample.selected_from.map(
                                                                      (
                                                                        item
                                                                      ) => (
                                                                        <MenuItem
                                                                          value={
                                                                            item
                                                                          }
                                                                        >
                                                                          {
                                                                            item.classes__class_name
                                                                          }
                                                                        </MenuItem>
                                                                      )
                                                                    )
                                                                  : null}
                                                              </MUISelect>
                                                            </FormControl>
                                                          )}
                                                        </div>
                                                      </td>

                                                      <td width="10%">
                                                        <div className="">
                                                          {sample.selected_to ? (
                                                            <Autocomplete
                                                              className="pl-1 pr-1"
                                                              id="free-solo-demo"
                                                              size={"small"}
                                                              sx={{
                                                                m: 1,
                                                                minWidth: 120,
                                                              }}
                                                              options={sample.selected_to.map(
                                                                (option) =>
                                                                  option.classes__class_name
                                                              )}
                                                              onChange={(
                                                                event,
                                                                value
                                                              ) =>
                                                                this.saveValueToObject(
                                                                  sample.id,
                                                                  "to",
                                                                  sample.selected_to.filter(
                                                                    (i) =>
                                                                      i.classes__class_name ===
                                                                      value
                                                                  )[0]
                                                                )
                                                              }
                                                              value={
                                                                sample.class_to
                                                                  ? sample
                                                                      .class_to
                                                                      .classes__class_name
                                                                  : ""
                                                              }
                                                              renderInput={(
                                                                params
                                                              ) => (
                                                                <TextField
                                                                  {...params}
                                                                  label="To"
                                                                  variant="standard"
                                                                />
                                                              )}
                                                            />
                                                          ) : (
                                                            <FormControl
                                                              variant="standard"
                                                              sx={{
                                                                m: 1,
                                                                minWidth: 120,
                                                              }}
                                                            >
                                                              <InputLabel id="demo-simple-select-standard-label">
                                                                To
                                                              </InputLabel>
                                                              <MUISelect
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={
                                                                  sample.class_to
                                                                    ? sample.class_to
                                                                    : ""
                                                                }
                                                                onChange={(e) =>
                                                                  this.saveValueToObject(
                                                                    sample.id,
                                                                    "to",
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                label="To"
                                                              >
                                                                <MenuItem
                                                                  value={null}
                                                                >
                                                                  <em>None</em>
                                                                </MenuItem>
                                                                {sample.selected_from
                                                                  ? sample.selected_to.map(
                                                                      (
                                                                        item
                                                                      ) => (
                                                                        <MenuItem
                                                                          value={
                                                                            item
                                                                          }
                                                                        >
                                                                          {
                                                                            item.classes__class_name
                                                                          }
                                                                        </MenuItem>
                                                                      )
                                                                    )
                                                                  : null}
                                                              </MUISelect>
                                                            </FormControl>
                                                          )}
                                                        </div>
                                                      </td>

                                                      <td width="10%">
                                                        <div
                                                          id="div_id_qty"
                                                          className="form-group"
                                                        >
                                                          <div className="">
                                                            <input
                                                              type="number"
                                                              id={sample.id}
                                                              value={
                                                                sample.quantity
                                                                  ? parseInt(
                                                                      sample.quantity
                                                                    )
                                                                  : ""
                                                              }
                                                              onChange={(e) =>
                                                                this.saveValueToObject(
                                                                  sample.id,
                                                                  "quantity",
                                                                  e.target
                                                                    .value ===
                                                                    ""
                                                                    ? 0
                                                                    : e.target
                                                                        .value
                                                                )
                                                              }
                                                              className="form-control w-100 p-2"
                                                              style={{
                                                                background:
                                                                  "#ededed",
                                                                marginTop:
                                                                  "16px",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </td>

                                                      <td width="10%">
                                                        <div
                                                          id="div_id_qty"
                                                          className="form-group"
                                                        >
                                                          <div className="">
                                                            <input
                                                              type="number"
                                                              id={sample.id}
                                                              value={
                                                                sample.price
                                                                  ? parseInt(
                                                                      sample.price
                                                                    )
                                                                  : ""
                                                              }
                                                              onChange={(e) =>
                                                                this.saveValueToObject(
                                                                  sample.id,
                                                                  "price",
                                                                  e.target
                                                                    .value ===
                                                                    ""
                                                                    ? 0
                                                                    : e.target
                                                                        .value
                                                                )
                                                              }
                                                              className="form-control w-100 p-2"
                                                              style={{
                                                                background:
                                                                  "#ededed",
                                                                marginTop:
                                                                  "16px",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </td>

                                                      <td
                                                        width="10%"
                                                        className="text-center"
                                                      >
                                                        <div
                                                          className="btn btn-xs"
                                                          style={{
                                                            color: "red",
                                                            fontSize: "15px",
                                                            marginTop: "16px",
                                                          }}
                                                          onClick={(e) =>
                                                            this.removeSample(
                                                              sample.id
                                                            )
                                                          }
                                                        >
                                                          X
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                )
                                              )
                                            : null}
                                        </table>
                                      </div>
                                    </div>
                                  ) : null}

                                  {/*<--------------------Manually Visit------------------>*/}
                                  <div className="row my-4">
                                    <div className="col-12">
                                      <button
                                        className="btn bg-gradient-dark d-block m-auto"
                                        onClick={this.getlatlong}
                                      >
                                        Submitttt
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={this.state.open}
              onClose={(e) => this.setState({ open: false })}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.open}>
                <Box sx={modalStyle}>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    <div className="modal-body">
                      {this.state.errorMessage !== null ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ textAlign: "center" }}
                        >
                          <h5 style={{ color: "red" }}>
                            {this.state.errorMessage}
                          </h5>
                        </div>
                      ) : (
                        <>
                          <div className="circle__box">
                            <div className="circle__wrapper circle__wrapper--right">
                              <div className="circle__whole circle__right"></div>
                            </div>
                            <div className="circle__wrapper circle__wrapper--left">
                              <div className="circle__whole circle__left"></div>
                            </div>
                            <div className="circle__checkmark"></div>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <h5>Visit Created Successfully.</h5>
                            <h6>
                              <Link to="/visit" style={{ color: "grey" }}>
                                Click here{" "}
                              </Link>{" "}
                              for go to visit dashboard.
                            </h6>
                          </div>
                        </>
                      )}
                    </div>
                  </Typography>
                </Box>
              </Fade>
            </Modal>
          </>
        ) : (
          // : <Loader />
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

// export default connect(mapStateToProps)(CreateVisit);

export default GoogleApiWrapper({
  apiKey: "${google_map_api_key}",
})(connect(mapStateToProps)(CreateVisit));
