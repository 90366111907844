import React from "react";
import { connect } from "react-redux";
import VisitDataTable from "./VisitDataTable";
import { Link, Redirect } from "react-router-dom";

class VisitDash extends React.Component {
  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="container-fluid homepage-view">
              <div className="row justify-content-end">
                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "d-none d-lg-block col-10"
                      : "d-none d-lg-block col-12"
                  }
                >
                  <VisitDataTable />
                </div>
              </div>
            </div>


                  {/* Mobile  */}
            <div className="d-lg-none px-2">
              <div className="dashOption-mv mt-4 ">
                <div className="">
                  <div className="dashOption-mv mt-4">
                    <div className="">
                      <div className="button-bar-mv d-flex">
                        <Link to="/visit" className="btn btn-mv active">
                          <img
                            src={require("../../../kavi/icons/dashbord.png")}
                            alt="btn-icon"
                          />
                          Dashboard
                        </Link>
                        {this.props.user.employee_profile === "admin" ? (
                          <Link
                            to="/visit/create-new-visit"
                            className="btn d-none btn-mv"
                          >
                            <img
                              src={require("../../../kavi/icons/add.png")}
                              alt="btn-icon"
                            />
                            Add Visit
                          </Link>
                        ) : null}
                        <Link to="/visit/schedule-visit" className="btn btn-mv">
                          <img
                            src={require("../../../kavi/icons/add.png")}
                            alt="btn-icon"
                          />
                          Schedule Visit
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <div className="">
                  <VisitDataTable />
                </div>
              </>
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(VisitDash);
