import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import StarRatings from "react-star-ratings/build/star-ratings";
import LatLng from "../../GoogleMap/getLatLng";
import { axios, geoapify_key, google_map_api_key, modalStyle } from "../../../store/actions/actions";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import data from './GoogleJSONdata.json';
import CircularProgress from '@mui/material/CircularProgress';

class CreateTrip extends React.Component {
    state = {
        latLng: null,

        customer: null,
        employee: null,
        employee_id: null,
        date: null,
        get_cust: null,
        get_emp: null,
        cust_lat: null,
        cust_long: null,

        show_dropdown: false,
        tripLoader :false,

        company: null,
        customer_type: null,
        cust_phone: null,
        website: null,
        rating: null,

        street: null,
        city: null,
        pin_code: null,
        srk:false,
        state: null,
        area: null,
        route: null,
        landmark: null,
        sector: null,
        block: null,
        places: [],
        lat: null,
        long: null,
        map_address: null,
        getbygoogle: false,

        near_by_companies: null,
        filter_near_by_companies: null,
        today_date: new Date().toISOString().slice(0, 10),

        near_by_from_google: null,
        filter_near_by_from_google: null,

        input: null,

        error: false,
        open: false,
        msg: null,
        showSkeleton: false,

        submit: false,

        required: false,
        apiError: false,
    };

    latLng = null

    componentDidMount() {
        this.setState({ apiError: false, showSkeleton: true })
        this.getCurrentLocation()
    }

    getLatLng = (data) => {
        console.log('lat lng in trip-----------------------------=>', data)
        this.latLng = data
        this.setState({ latLng: data })
        this.loadNearbySchoolsFromGoogle(data)
    }

    loadNearbySchoolsFromGoogle = (position) => {
        const data = {
            lat: position.lat,
            lng: position.lng,
            school_paramerter: `location=${position.lat + "%2C" + position.lng}&radius=50&type=schools&keyword=school`,
            seller_paramerter: `location=${position.lat + "%2C" + position.lng}&radius=50&type=book&keyword=book`
        }
        // let la = 28.616031
        // let ln = 77.389811
        // const data = {
        //     lat: la,
        //     lng: ln,
        //     school_paramerter: `location=${la + "%2C" + ln}&radius=50&type=schools&keyword=school`,
        //     seller_paramerter: `location=${la + "%2C" + ln}&radius=50&type=book&keyword=book`
        // }

        this.setState({ showSkeleton: true })
        let endpoint = `customer/get-customers-nearby`;
        let token = this.props.user.token
        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data.data;
                responseData.sort(function (a, b) {
                    let date_a = a.range
                    let date_b = b.range

                    if (date_a < date_b)
                        return -1;
                    return 0;
                })
                this.setState({
                    showSkeleton: false,
                    near_by_from_google: responseData
                });
                console.log({
                    "LoadNearbySchoolsFromGoogle response data------------>": responseData,
                });
            });
    };

    getEstablishmentDetails = (obj) => {
        this.setState({ getbygoogle: true, show_dropdown: false, input: obj.company })
        let endpoint = `customer/get-establishment-details/${obj.place_id}`;
        let token = this.props.user.token
        axios.get(endpoint, token).then((res) => {
            let responseData = res.data;
            console.log({
                "**************** response data---------": responseData,
            });

            this.setState({
                company: responseData.name,
                customer_type: obj.type === 'SCHOOL' ? 'SCHOOL' : 'SELLER',
                cust_phone: responseData.phone,
                website: responseData.website,
                rating: responseData.rating === null ? 0 : responseData.rating,

                street: responseData.address.street ? responseData.address.street + ", " : "",
                city: responseData.address.city,
                pin_code: responseData.address.pin_code,
                state: responseData.address.state,
                area: responseData.address.area ? responseData.address.area + ", " : "",
                route: responseData.address.route ? responseData.address.route + ", " : "",
                landmark: responseData.address.landmark ? responseData.address.landmark + ", " : "",
                sector: responseData.address.sector ? responseData.address.sector + ", " : "",
                block: responseData.address.block ? responseData.address.block + ", " : "",
                lat: obj.lat,
                long: obj.lng,
                map_address: `${responseData.name}, ${responseData.address.city}, ${responseData.address.state}`
            });
        });
    };

    submit = (id) => {
        const data = {
            emp_lat: this.state.latLng.lat,
            emp_long: this.state.latLng.lng,
            cust_lat: this.state.cust_lat,
            cust_long: this.state.cust_long,
            customer: id,
            date: this.state.date ? this.state.date : this.state.today_date,
            manually: this.state.apiError
        };

        let endpoint = `trip/`;
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                this.setState({ submit: false })
                if (responseData.success === true) {
                    this.setState({
                        error: false,
                        open: true,
                    });
                } else {
                    this.setState({
                        msg: responseData.msg,
                        error: true,
                        open: true
                    });
                }
                console.log({ "response data---------": responseData });
            });
    };

    validation = () => {
        console.log('run...')
        if (
            this.state.company !== null && this.state.company.split(" ").join("") !== '' &&
            this.state.cust_phone !== null && this.state.cust_phone.split(" ").join("") !== '' &&
            this.state.street !== null && this.state.street.split(" ").join("") !== '' &&
            // this.state.city !== null && this.state.city.split(" ").join("") !== '' &&
            this.state.pin_code !== null && this.state.pin_code.split(" ").join("") !== ''
            // this.state.state !== null && this.state.state.split(" ").join("") !== ''
        ) {
            this.customerCreate()
        } else {
            this.setState({
                required: true
            })
        }
    }

    customerCreate = () => {
        if (this.state.submit === false) {
            this.setState({ submit: true })
            const data = {
                street_name: this.state.city ?
                    this.state.street +
                    this.state.block +
                    this.state.sector +
                    this.state.route +
                    this.state.landmark +
                    this.state.area
                    : '',
                dist: this.state.city,
                pin_code: this.state.pin_code,
                state: this.state.state,

                customer_type: this.state.customer_type,
                company: this.state.company,
                cust_phone: this.state.cust_phone,
                rating: this.state.rating,
                website: this.state.website,

                address_type: this.state.address_type,
                lat: this.state.latLng.lat,
                long: this.state.latLng.lng,
                map_address: `${this.state.company}, ${this.state.city}, ${this.state.state}`,
                manually: this.state.apiError
            }
            console.log('-------------->', data)

            let token = this.props.user.token
            let endpoint = `trip/create-customer`

            axios
                .post(endpoint, data, token)
                .then((res) => {
                    let responseData = res.data;
                    console.log({ 'response data---------': responseData })
                    if (responseData.success === true) {
                        this.submit(responseData.customer)
                    } else {
                        this.setState({
                            submit: false,
                            msg: 'Please type valid city and state.',
                            error: true,
                            open: true
                        })
                    }
                })
        }
    }

    handleAddTrip = (place) => {
        this.setState({
            tripLoader:true
        })
        const data = {
            street_name: place.vicinity,
            dist: this.state.city,
            pin_code: this.state.pin_code,
            state: this.state.state,

            customer_type: "School",
            company: place.name,
            cust_phone: this.state.cust_phone,
            rating: place.rating,
            website: this.state.website,

            address_type: this.state.address_type,
            lat: place.geometry.location.lat,
            long: place.geometry.location.lng,
            map_address: `${place.name}, ${place.vicinity}`,
            manually: false
        }

        let token = this.props.user.token
        let endpoint = `trip/create-customer`

            axios
                .post(endpoint, data, token)
                .then((res) => {
                    let responseData = res.data;
                    console.log({ 'response data---------': responseData })
                    if (responseData.success === true) {
                        this.setState({
                            tripLoader:false
                        })
                        this.submit(responseData.customer)
                    } else {
                        this.setState({
                            submit: false,
                            msg: 'Please type valid city and state.',
                            error: true,
                            open: true
                        })
                    }
                })

        console.log(data, "-=-=-=-=-=")
    }


    // Google API Handle 
    getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log(position, "=-=-=-=-=-=-=-= Posti ad asfdc")
                    this.setState({
                        lat:position.coords.latitude,
                        long:position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Error fetching location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    // Function to get nearby places like schools and book stores
    getNearbyPlaces = async (lat, lng) => {
        try {
            // API call for schools
            console.log("working hai bhai")
            const schoolResponse = await axios.get(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${this.state.lat},${this.state.lng}&radius=300&type=school&key=${google_map_api_key}`);
            const schools = schoolResponse.data.results;

            // API call for book stores
            const bookStoreResponse = await axios.get(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${lng}&radius=300&type=book_store&key=${google_map_api_key}`);
            const bookStores = bookStoreResponse.data.results;

            // Merging both results
            const mergedPlaces = [...schools, ...bookStores];

            // Setting merged results to state
            this.setState({
                places:mergedPlaces
            });
        } catch (error) {
            console.error("Error fetching places:", error);
        }
    };

    // Function to get city, state, and pincode using Geocoding API
    getLocationDetails = async (lat, lng) => {
        try {
            const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${google_map_api_key}`;
            const response = await axios.get(endpoint);
            const results = response.data.results;
            console.log(results, "-=-=-=-=-=-=-=")
            
            if (results.length > 0) {
                const addressComponents = results[0].address_components;

                // Iterate through address components to find city, state, and pincode
                addressComponents.forEach(component => {
                    const types = component.types;

                    if (types.includes('locality')) {
                        this.setState({
                            city:component.long_name
                        });
                    }
                    if (types.includes('administrative_area_level_1')) {
                        // setState(component.long_name);
                        this.setState({
                            state:component.long_name
                        });
                    }
                    if (types.includes('postal_code')) {
                        // setPincode(component.long_name);

                        this.setState({
                            pin_code:component.long_name
                        });
                    }
                });
            }

            console.log(this.state.city, "city", this.state.state, "State", this.state.pin_code)
        } catch (error) {
            console.error("Error fetching location details:", error);
        }
    };



    componentDidUpdate(prevProps, prevState) {
        // Check if latitude or longitude have changed
        if (this.state.lat !== prevState.lat || this.state.long !== prevState.long) {
            const { lat, long } = this.state;
            if (lat && long) {
                this.getLocationDetails(lat, long);
                this.getNearbyPlaces(lat, long);
                // this.setState({
                //     places:data.results
                // })
            }
        }
    }


    getAddressByGoogle = () => {
        this.setState({ apiError: true })
        // let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=28.7982071,76.1466884&key=AIzaSyAQsdAypekPAxdJOjefqcjm4pWJ3t2XGAY`
        let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latLng.lat},${this.state.latLng.lng}&key=${google_map_api_key}`
        console.log('endpoint--------------------->', endpoint)
        axios
            .get(endpoint)
            .then((res) => {
                let responseData = res.data.results;
                let data = { result: responseData }
                let endpoint2 = `customer/get-location-details`
                let token = this.props.user.token
                axios
                    .post(endpoint2, data, token)
                    .then((res) => {
                        let responseData = res.data;
                        console.log('response address--------------------->', responseData)
                        if (responseData.success === true) {
                            this.setState({
                                street: responseData.address.street ? responseData.address.street + ", " : "",
                                city: responseData.address.city,
                                pin_code: responseData.address.pin_code,
                                state: responseData.address.state,
                                area: responseData.address.area ? responseData.address.area + ", " : "",
                                route: responseData.address.route ? responseData.address.route + ", " : "",
                                landmark: responseData.address.landmark ? responseData.address.landmark + ", " : "",
                                sector: responseData.address.sector ? responseData.address.sector + ", " : "",
                                block: responseData.address.block ? responseData.address.block + ", " : "",
                                lat: this.state.latLng.lat,
                                long: this.state.latLng.lng,
                            });
                        } else {
                            console.log('response address--------------------->', responseData)
                            console.log('Bill Bharo...')
                            this.setState({ apiError: true })
                        }
                    })
            })
    }

    getAddressByGeoapify = () => {
        let endpoint = `https://api.geoapify.com/v2/place-details?lat=28.7982071&lon=76.1466884&apiKey=${geoapify_key}`
        // let endpoint = `https://api.geoapify.com/v2/place-details?lat=${this.state.latLng.lat}&lon=${this.state.latLng.lng}&apiKey=${geoapify_key}`
        axios
            .get(endpoint)
            .then((res) => {
                let responseData = res.data.features[0].properties;
                console.log('---------------------------->', responseData)
                console.log('---------------------------->', responseData.city)
                // this.setState({
                //     street: responseData.street ? responseData.street + ", " : "",
                //     city: responseData.city,
                //     pin_code: responseData.postcode,
                //     state: responseData.state,
                //     area: responseData.county ? responseData.county + ", " : "",
                //     route: responseData.address.route ? responseData.address.route + ", " : "",
                //     landmark: responseData.address.landmark ? responseData.address.landmark + ", " : "",
                //     sector: responseData.address.sector ? responseData.address.sector + ", " : "",
                //     block: responseData.address.block ? responseData.address.block + ", " : "",
                //     lat: this.state.latLng.lat,
                //     long:  this.state.latLng.lng,
                // });
            })
    }

    render() {
        return (
            <>
                {this.props.user.login === true ?
                    <>
                        {window.screen.availWidth <= 1000 ?
                            <>
                                <div className="d-lg-none px-2">
                                    <div className="dashOption-mv mt-4">
                                        <div className="">
                                            <div className="button-bar-mv d-flex">
                                                <Link to='/trip' className="btn btn-mv"><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                                <Link to="/trip/create-trip" className="btn btn-mv active"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Add Trip</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 mb-3">
                                        <div className="f-btn trip-btn" style={{ textAlign: 'center' }}>
                                            <button onClick={(e) => this.getAddressByGoogle()} style={{ padding: '22px', fontSize: '1.2rem' }}>Check In Manually</button>
                                        </div>
                                    </div>
                                    {this.state.apiError === false && (this.state.city || this.state.state) && this.state.srk ?
                                        <div className='card mt-4' style={{ marginBottom: '100px' }}>
                                            <div className="row px-4 mt-3">
                                                <div className="col-12">
                                                    <div
                                                        className="input-group input-group-static is-filled">
                                                        <label>Customer Name{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                        <input
                                                            id='company'
                                                            // placeholder='Customer Name'
                                                            value={this.state.company ? this.state.company : ''}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    company: e.target.value,
                                                                })
                                                            }
                                                            className="multisteps-form__input form-control form-control-mv"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12  mt-4">
                                                    <div className="row">
                                                        <div className="dropdown">
                                                            <div className="m-0 btn w-100 srkw bg-gradient-dark dropdown-toggle"
                                                                data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">
                                                                {this.state.customer_type ? this.state.customer_type : 'Select Customer Type'}
                                                            </div>
                                                            <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                            >
                                                                <li>
                                                                    <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'SCHOOL' })}>
                                                                        SCHOOL
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'SELLER' })}>
                                                                        SELLER
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'TEACHER' })}>
                                                                        TEACHER
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div
                                                        className="input-group input-group-static is-filled">
                                                        <label>Phone Number{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                        <input
                                                            id='phone'
                                                            minLength="11"
                                                            maxLength="11"
                                                            value={this.state.cust_phone ? this.state.cust_phone : ''}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    cust_phone: e.target.value,
                                                                })
                                                            }
                                                            className="multisteps-form__input form-control form-control-mv"
                                                            type="tel"
                                                            pattern="[0-9]{3}-[0-9]{8}"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div
                                                        className="input-group input-group-static is-filled">
                                                        <label>Website</label>
                                                        <input
                                                            id='website'
                                                            value={this.state.website ? this.state.website : ''}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    website: e.target.value,
                                                                })
                                                            }
                                                            className="multisteps-form__input form-control form-control-mv"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12 mt-3 ">
                                                    <div
                                                        className="input-group input-group-static is-filled">
                                                        <label>Street Name{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                        <input
                                                            id='street'
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    street: e.target.value,
                                                                })
                                                            }
                                                            value={
                                                                this.state.city
                                                                    ? this.state.street +
                                                                    this.state.block +
                                                                    this.state.sector +
                                                                    this.state.route +
                                                                    this.state.landmark +
                                                                    this.state.area
                                                                    : ''
                                                            }
                                                            className="multisteps-form__input form-control form-control-mv"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div
                                                        className="input-group input-group-static is-filled">
                                                        <label>City{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                        <input
                                                            id={'city'}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    city: e.target.value,
                                                                })
                                                            }
                                                            value={this.state.city ? this.state.city : ''}
                                                            className="multisteps-form__input form-control form-control-mv"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div
                                                        className="input-group input-group-static is-filled">
                                                        <label>Pin Code{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                        <input
                                                            id={'pincode'}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    pin_code: e.target.value,
                                                                })
                                                            }
                                                            value={this.state.pin_code ? this.state.pin_code : ''}
                                                            className="multisteps-form__input form-control form-control-mv"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div
                                                        className="input-group input-group-static is-filled">
                                                        <label>State{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                        <input
                                                            id={'state'}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    state: e.target.value,
                                                                })
                                                            }
                                                            value={this.state.state ? this.state.state : ''}
                                                            className="multisteps-form__input form-control form-control-mv"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div
                                                        className="input-group input-group-static is-filled">
                                                        <label>Transport
                                                            Name</label>
                                                        <input
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    transport_name: e.target.value,
                                                                })
                                                            }
                                                            className="multisteps-form__input form-control form-control-mv"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div
                                                        className="input-group input-group-static is-filled">
                                                        <label>Special
                                                            Instruction</label>
                                                        <textarea
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    special_instruction: e.target.value,
                                                                })
                                                            }
                                                            className=" multisteps-form__textarea form-control form-control-mv"
                                                            rows="1"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row my-4">
                                                <div className="col-12">
                                                    {this.state.submit === false ?
                                                        <button className="btn bg-gradient-dark d-block m-auto"
                                                            onClick={
                                                                (e) =>
                                                                    this.state.customer_type
                                                                        ?
                                                                        this.validation()
                                                                        :
                                                                        this.setState({
                                                                            error: true,
                                                                            msg: 'Please select customer type',
                                                                            open: true
                                                                        })
                                                            }
                                                        >
                                                            Submit
                                                        </button>
                                                        :
                                                        <button className="btn bg-gradient-dark d-block m-auto"
                                                            style={{ background: '#9e9fa1' }}
                                                        >
                                                            Submit
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        this.state.apiError ?
                                            <div className='card mt-4' style={{ marginBottom: '100px' }}>
                                                <div className="row px-4 mt-3">
                                                    <div className="col-12">
                                                        <div
                                                            className="input-group input-group-static is-filled">
                                                            <label>Customer Name{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                            <input
                                                                id='customer'
                                                                // value={this.state.company ? this.state.company : ''}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        company: e.target.value,
                                                                    })
                                                                }
                                                                className="multisteps-form__input form-control form-control-mv"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <div className="row">
                                                            <div className="dropdown">
                                                                <div className="m-0 btn w-100 srkw bg-gradient-dark dropdown-toggle"
                                                                    data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">
                                                                    {this.state.customer_type ? this.state.customer_type : 'Select Customer Type'}
                                                                </div>
                                                                <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                                >
                                                                    <li>
                                                                        <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'SCHOOL' })}>
                                                                            SCHOOL
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'SELLER' })}>
                                                                            SELLER
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'TEACHER' })}>
                                                                            TEACHER
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div
                                                            className="input-group input-group-static is-filled">
                                                            <label>Phone Number{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                            <input
                                                                id='phone'
                                                                // placeholder='Phone Number'
                                                                minLength="11"
                                                                maxLength="11"
                                                                // value={this.state.cust_phone ? this.state.cust_phone : ''}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        cust_phone: e.target.value,
                                                                    })
                                                                }
                                                                className="multisteps-form__input form-control form-control-mv"
                                                                type="tel"
                                                                pattern="[0-9]{3}-[0-9]{8}"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mt-3 ">
                                                        <div
                                                            className="input-group input-group-static is-filled">
                                                            <label>Full Address{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                            <input
                                                                id='street'
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        street: e.target.value,
                                                                    })
                                                                }
                                                                // value={
                                                                //     this.state.city
                                                                //         ? this.state.street +
                                                                //         this.state.block +
                                                                //         this.state.sector +
                                                                //         this.state.route +
                                                                //         this.state.landmark +
                                                                //         this.state.area
                                                                //         : ''
                                                                // }
                                                                className="multisteps-form__input form-control form-control-mv"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div
                                                            className="input-group input-group-static is-filled">
                                                            <label>Pin Code{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                            <input
                                                                id='pincode'
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        pin_code: e.target.value,
                                                                    })
                                                                }
                                                                // value={this.state.pin_code ? this.state.pin_code : ''}
                                                                className="multisteps-form__input form-control form-control-mv"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row my-4">
                                                    <div className="col-12">
                                                        {this.state.submit === false ?
                                                            <button className="btn bg-gradient-dark d-block m-auto"
                                                                onClick={
                                                                    (e) =>
                                                                        this.state.customer_type
                                                                            ?
                                                                            this.validation()
                                                                            :
                                                                            this.setState({
                                                                                error: true,
                                                                                msg: 'Please select customer type',
                                                                                open: true
                                                                            })
                                                                }
                                                            >
                                                                Submit
                                                            </button>
                                                            :
                                                            <button className="btn bg-gradient-dark d-block m-auto"
                                                                style={{ background: '#9e9fa1' }}
                                                            >
                                                                Submit
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="row mt-3" style={{ marginBottom: '100px' }}>
                                                {this.state.showSkeleton === true ?
                                                    <div>
                                                        <div className={'card p-2'}>
                                                            <Stack spacing={3}>
                                                                <Skeleton variant="rectangular" style={{ height: '100px' }} />
                                                            </Stack>
                                                        </div>
                                                        <div className={'card p-2 mt-2'}>
                                                            <Stack spacing={3}>
                                                                <Skeleton variant="rectangular" style={{ height: '100px' }} />
                                                            </Stack>
                                                        </div>
                                                        <div className={'card p-2 mt-2'}>
                                                            <Stack spacing={3}>
                                                                <Skeleton variant="rectangular" style={{ height: '100px' }} />
                                                            </Stack>
                                                        </div>
                                                        <div className={'card p-2 mt-2'}>
                                                            <Stack spacing={3}>
                                                                <Skeleton variant="rectangular" style={{ height: '100px' }} />
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                    :
                                                    this.state.near_by_from_google ? this.state.near_by_from_google.map((item, index) => (
                                                        <div className={'col-12'}>
                                                            <div className="card mt-3" style={{ boxShadow: "0 4px 6px -1px rgb(0 0 0 / 10%), -2px -2px 4px -1px rgb(0 0 0 / 6%)" }}>
                                                                <div className="row" style={{ padding: '5px' }}>
                                                                    <div className="col-3 mt-1">
                                                                        <img style={{ width: '100%' }} src={item.type === 'SCHOOL' ? require("../../../custom/school.png") : require("../../../custom/seller.png")} alt="" />
                                                                    </div>
                                                                    <div className="col-9 mt-1">
                                                                        <h6 style={{ whiteSpace: 'break-spaces' }}>{item.name.split(',')[0]}</h6>
                                                                        {/*<span style={{whiteSpace: 'break-spaces', marginTop: '-8px'}}>{this.containsNumbers(item.address.split(',')[0].split(' ')[0])===false?item.address:item.address.slice(item.address.indexOf(',')+1).trim()}</span>*/}
                                                                        <span style={{ whiteSpace: 'break-spaces', marginTop: '-8px' }}>{/\d/.test(item.address.split(',')[0].split(' ')[0]) === false ? item.address : item.address.slice(item.address.indexOf(',') + 1).trim()}</span>
                                                                        <div className="">
                                                                            <span className="" style={{ color: '#34b934' }}>{item.range + '/meter'}</span>
                                                                            <div
                                                                                style={{
                                                                                    marginTop: "-12px",
                                                                                    // marginLeft: "20px",
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: "space-between",
                                                                                    // margin: "0 0"
                                                                                }}
                                                                            >
                                                                                <StarRatings
                                                                                    rating={item.rating}
                                                                                    starDimension="15px"
                                                                                    starSpacing="2px"
                                                                                    starRatedColor="yellow"
                                                                                    name="rating"
                                                                                />

                                                                                <button onClick={(e) => this.getEstablishmentDetails(item)} type="button" className="btn btn-warning" style={{ top: '4px', float: 'right' }}>Check In</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}
                                            </div>
                                    }

                                    {this.latLng === null ?
                                        <LatLng getLatLng={this.getLatLng} />
                                        : null}

                                </div>

                                <div className="container-fluid">
                                    <div className="row justify-content-end">
                                        <div className='col-12'>
                                            <div className="row">
                                                {this.state.places.length > 0 ? 
                                                this.state.places.map((place) => (
                                                    <div className="col-md-4 mb-4" key={place.place_id}>
                                                        <div className="card p-3">
                                                            <div className="d-flex flex-row">
                                                                <img
                                                                    src={place.icon}
                                                                    className="card-img-top d-none"
                                                                    alt={place.name}
                                                                    style={{ width: '50px', height: '50px' }}
                                                                />
                                                                <div className="card-body py-0 px-2">
                                                                    <h5 className="card-title mb-0 fs-5" style={{ whiteSpace: 'pre-wrap' }}>{place.name}</h5>
                                                                    <p className="card-text" style={{ whiteSpace: 'pre-wrap' }}>{place.vicinity}</p>
                                                                    <p className="card-text mb-2" style={{ whiteSpace: 'pre-wrap' }}>
                                                                        Rating: {place.rating} ({place.user_ratings_total} ratings)
                                                                    </p>
                                                                    <p className="card-text mb-2" style={{ whiteSpace: 'pre-wrap' }}>
                                                                        Rating: {place.geometry.location.lat} {place.geometry.location.lng}
                                                                    </p>
                                                                    <div className="d-flex justify-content-center mt-4">
                                                                        <button type="submit" className="btn d-flex align-items-center gap-3 btn-primary"
                                                                        onClick={(e) => this.handleAddTrip(place)}
                                                                        >
                                                                            Add Trip 
                                                                            {this.tripLoader ?       <CircularProgress style={{color:'white'}} size={18} />:null
                                                                            }


                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-none">
                                                                {place.photos && place.photos.length > 0 && (
                                                                    <img
                                                                        src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${place.photos[0].photo_reference}&key=${google_map_api_key}`}
                                                                        alt="Place"
                                                                        className="img-fluid"
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))   
                                            :null
                                            }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>

                            :
                            <div className="d-none d-lg-block container-fluid">
                                <div className="row justify-content-end">
                                    <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-10" : "col-12"}>
                                        <div className='px-2'>
                                            {this.state.apiError === false && (this.state.city || this.state.state) ?
                                                <div className="col-md-10 mt-lg-0 mt-4">
                                                    <div className="card mt-4" id="basic-info">
                                                        <div className="row px-4 mt-4">
                                                            <div className="col-6">
                                                                <div
                                                                    className="input-group input-group-static is-filled">
                                                                    <label>Customer Name{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                    <input
                                                                        id='customer'
                                                                        // placeholder='Customer Name'
                                                                        value={this.state.company ? this.state.company : ''}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                company: e.target.value,
                                                                            })
                                                                        }
                                                                        className="multisteps-form__input form-control"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-4">
                                                                <div className="row">
                                                                    <div className="dropdown">
                                                                        <div className="m-0 btn w-100 srkw bg-gradient-dark dropdown-toggle"
                                                                            data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                                            {this.state.customer_type ? this.state.customer_type : 'Select Customer Type'}
                                                                        </div>
                                                                        <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                                        >
                                                                            <li>
                                                                                <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'SCHOOL' })}>
                                                                                    SCHOOL
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'SELLER' })}>
                                                                                    SELLER
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'TEACHER' })}>
                                                                                    TEACHER
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <div
                                                                    className="input-group input-group-static is-filled">
                                                                    <label>Phone Number{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                    <input
                                                                        id='phone'
                                                                        // placeholder='Phone Number'
                                                                        minLength="11"
                                                                        maxLength="11"
                                                                        value={this.state.cust_phone ? this.state.cust_phone : ''}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                cust_phone: e.target.value,
                                                                            })
                                                                        }
                                                                        className="multisteps-form__input form-control"
                                                                        type="tel"
                                                                        pattern="[0-9]{3}-[0-9]{8}"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <div
                                                                    className="input-group input-group-static is-filled">
                                                                    <label>Website</label>
                                                                    <input
                                                                        id='website'
                                                                        // placeholder='Website'
                                                                        value={this.state.website ? this.state.website : ''}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                website: e.target.value,
                                                                            })
                                                                        }
                                                                        className="multisteps-form__input form-control"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mt-3 ">
                                                                <div
                                                                    className="input-group input-group-static is-filled">
                                                                    <label>Street Name{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                    <input
                                                                        id='street'
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                street: e.target.value,
                                                                            })
                                                                        }
                                                                        value={
                                                                            this.state.city
                                                                                ? this.state.street +
                                                                                this.state.block +
                                                                                this.state.sector +
                                                                                this.state.route +
                                                                                this.state.landmark +
                                                                                this.state.area
                                                                                : ''
                                                                        }
                                                                        className="multisteps-form__input form-control"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <div
                                                                    className="input-group input-group-static is-filled">
                                                                    <label>City{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                    <input
                                                                        id='city'
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                city: e.target.value,
                                                                            })
                                                                        }
                                                                        value={this.state.city ? this.state.city : ''}
                                                                        className="multisteps-form__input form-control"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <div
                                                                    className="input-group input-group-static is-filled">
                                                                    <label>Pin Code{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                    <input
                                                                        id='pincode'
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                pin_code: e.target.value,
                                                                            })
                                                                        }
                                                                        value={this.state.pin_code ? this.state.pin_code : ''}
                                                                        className="multisteps-form__input form-control"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <div
                                                                    className="input-group input-group-static is-filled">
                                                                    <label>State{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                    <input
                                                                        id='state'
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                state: e.target.value,
                                                                            })
                                                                        }
                                                                        value={this.state.state ? this.state.state : ''}
                                                                        className="multisteps-form__input form-control"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <div
                                                                    className="input-group input-group-static is-filled">
                                                                    <label>Transport
                                                                        Name</label>
                                                                    <input
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                transport_name: e.target.value,
                                                                            })
                                                                        }
                                                                        className="multisteps-form__input form-control"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-3">
                                                                <div
                                                                    className="input-group input-group-static is-filled">
                                                                    <label>Special
                                                                        Instruction</label>
                                                                    <textarea
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                special_instruction: e.target.value,
                                                                            })
                                                                        }
                                                                        className="mt-3 multisteps-form__textarea form-control"
                                                                        rows="1"
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row my-4">
                                                            <div className="col-12">
                                                                {this.state.submit === false ?
                                                                    <button className="btn bg-gradient-dark d-block m-auto"
                                                                        onClick={
                                                                            (e) =>
                                                                                this.state.customer_type
                                                                                    ?
                                                                                    this.validation()
                                                                                    :
                                                                                    this.setState({
                                                                                        error: true,
                                                                                        msg: 'Please select customer type',
                                                                                        open: true
                                                                                    })
                                                                        }
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    :
                                                                    <button className="btn bg-gradient-dark d-block m-auto"
                                                                        style={{ background: '#9e9fa1' }}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                this.state.apiError ?
                                                    <div className="col-md-10 mt-lg-0 mt-4">
                                                        <div className="card mt-4" id="basic-info">
                                                            <div className="row px-4 mt-4">
                                                                <div className="col-6">
                                                                    <div
                                                                        className="input-group input-group-static is-filled">
                                                                        <label>Customer Name{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                        <input
                                                                            id='customer'
                                                                            // placeholder='Customer Name'
                                                                            // value={this.state.company ? this.state.company : ''}
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    company: e.target.value,
                                                                                })
                                                                            }
                                                                            className="multisteps-form__input form-control"
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 mt-4">
                                                                    <div className="row">
                                                                        <div className="dropdown">
                                                                            <div className="m-0 btn w-100 srkw bg-gradient-dark dropdown-toggle"
                                                                                data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                                                {this.state.customer_type ? this.state.customer_type : 'Select Customer Type'}
                                                                            </div>
                                                                            <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                                            >
                                                                                <li>
                                                                                    <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'SCHOOL' })}>
                                                                                        SCHOOL
                                                                                    </div>
                                                                                </li>
                                                                                <li>
                                                                                    <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'SELLER' })}>
                                                                                        SELLER
                                                                                    </div>
                                                                                </li>
                                                                                <li>
                                                                                    <div className="dropdown-item" onClick={(e) => this.setState({ customer_type: 'TEACHER' })}>
                                                                                        TEACHER
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <div
                                                                        className="input-group input-group-static is-filled">
                                                                        <label>Phone Number{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                        <input
                                                                            id='phone'
                                                                            // placeholder='Phone Number'
                                                                            minLength="11"
                                                                            maxLength="11"
                                                                            // value={this.state.cust_phone ? this.state.cust_phone : ''}
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    cust_phone: e.target.value,
                                                                                })
                                                                            }
                                                                            className="multisteps-form__input form-control"
                                                                            type="tel"
                                                                            pattern="[0-9]{3}-[0-9]{8}"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <div
                                                                        className="input-group input-group-static is-filled">
                                                                        <label>Website</label>
                                                                        <input
                                                                            id='website'
                                                                            // placeholder='Website'
                                                                            // value={this.state.website ? this.state.website : ''}
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    website: e.target.value,
                                                                                })
                                                                            }
                                                                            className="multisteps-form__input form-control"
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 mt-3 ">
                                                                    <div
                                                                        className="input-group input-group-static is-filled">
                                                                        <label>Street Name{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                        <input
                                                                            id='street'
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    street: e.target.value,
                                                                                })
                                                                            }
                                                                            // value={
                                                                            //     this.state.city
                                                                            //         ? this.state.street +
                                                                            //         this.state.block +
                                                                            //         this.state.sector +
                                                                            //         this.state.route +
                                                                            //         this.state.landmark +
                                                                            //         this.state.area
                                                                            //         : ''
                                                                            // }
                                                                            className="multisteps-form__input form-control"
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <div
                                                                        className="input-group input-group-static is-filled">
                                                                        <label>City{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                        <input
                                                                            id='city'
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    city: e.target.value,
                                                                                })
                                                                            }
                                                                            // value={this.state.city ? this.state.city : ''}
                                                                            className="multisteps-form__input form-control"
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <div
                                                                        className="input-group input-group-static is-filled">
                                                                        <label>Pin Code{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                        <input
                                                                            id='pincode'
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    pin_code: e.target.value,
                                                                                })
                                                                            }
                                                                            // value={this.state.pin_code ? this.state.pin_code : ''}
                                                                            className="multisteps-form__input form-control"
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <div
                                                                        className="input-group input-group-static is-filled">
                                                                        <label>State{this.state.required ? <label style={{ color: 'red', fontSize: '15px' }}>*</label> : null}</label>
                                                                        <input
                                                                            id='state'
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    state: e.target.value,
                                                                                })
                                                                            }
                                                                            // value={this.state.state ? this.state.state : ''}
                                                                            className="multisteps-form__input form-control"
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <div
                                                                        className="input-group input-group-static is-filled">
                                                                        <label>Transport
                                                                            Name</label>
                                                                        <input
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    transport_name: e.target.value,
                                                                                })
                                                                            }
                                                                            className="multisteps-form__input form-control"
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-3">
                                                                    <div
                                                                        className="input-group input-group-static is-filled">
                                                                        <label>Special
                                                                            Instruction</label>
                                                                        <textarea
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    special_instruction: e.target.value,
                                                                                })
                                                                            }
                                                                            className="mt-3 multisteps-form__textarea form-control"
                                                                            rows="1"
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row my-4">
                                                                <div className="col-12">
                                                                    {this.state.submit === false ?
                                                                        <button className="btn bg-gradient-dark d-block m-auto"
                                                                            onClick={
                                                                                (e) =>
                                                                                    this.state.customer_type
                                                                                        ?
                                                                                        this.validation()
                                                                                        :
                                                                                        this.setState({
                                                                                            error: true,
                                                                                            msg: 'Please select customer type',
                                                                                            open: true
                                                                                        })
                                                                            }
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        :
                                                                        <button className="btn bg-gradient-dark d-block m-auto"
                                                                            style={{ background: '#9e9fa1' }}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="row">
                                                        {this.state.showSkeleton === true ?
                                                            <>
                                                                <div className={'col-6 d-none'}>
                                                                    <Stack spacing={3}>
                                                                        {/*<Skeleton variant="rectangular" style={{height: '150px', width: '400px'}} />*/}
                                                                        <Skeleton variant="rectangular" style={{ height: '150px', width: '400px' }} />
                                                                    </Stack>
                                                                </div>
                                                                <div className={'col-6 d-none'}>
                                                                    <Stack spacing={3}>
                                                                        {/*<Skeleton variant="rectangular" style={{height: '150px', width: '400px'}} />*/}
                                                                        <Skeleton variant="rectangular" style={{ height: '150px', width: '400px' }} />
                                                                    </Stack>
                                                                </div>
                                                                <div className={'col-6 d-none'}>
                                                                    <Stack spacing={3}>
                                                                        {/*<Skeleton variant="rectangular" style={{height: '150px', width: '400px'}} />*/}
                                                                        <Skeleton variant="rectangular" style={{ height: '150px', width: '400px' }} />
                                                                    </Stack>
                                                                </div>
                                                            </>
                                                            :
                                                            this.state.near_by_from_google ?
                                                                this.state.near_by_from_google.map((item, index) => (
                                                                    <div className={'col-12 p-2'}>
                                                                        <div className="card mt-3" style={{ boxShadow: "0 4px 6px -1px rgb(0 0 0 / 10%), -2px -2px 4px -1px rgb(0 0 0 / 6%)" }}>
                                                                            <div className="row" style={{ padding: '5px' }}>
                                                                                <div className="col-lg-3 mt-1">
                                                                                    <div className="card" style={{ maxWidth: '200px', maxHeight: '200px', border: 'none', padding: '15px', background: '#f3efe0' }}>
                                                                                        <img style={{ maxHeight: '153px' }} src={item.type === 'SCHOOL' ? require("../../../custom/school.png") : require("../../../custom/seller.png")} alt="" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-9 mt-1">
                                                                                    <h5>{item.name.split(',')[0]}</h5>
                                                                                    <span>{/\d/.test(item.address.split(',')[0].split(' ')[0]) === false ? item.address : item.address.slice(item.address.indexOf(',') + 1).trim()}</span>
                                                                                    <div className="">
                                                                                        <span className="" style={{ color: '#34b934' }}>{item.range + '/meter'}</span>
                                                                                        <div
                                                                                            style={{
                                                                                                marginTop: "-1px",
                                                                                                // marginLeft: "20px",
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: "space-between",
                                                                                                // margin: "15px 0"
                                                                                            }}
                                                                                        >
                                                                                            <StarRatings
                                                                                                rating={item.rating}
                                                                                                starDimension="20px"
                                                                                                starSpacing="3px"
                                                                                                starRatedColor="yellow"
                                                                                                name="rating"
                                                                                            />
                                                                                            <button onClick={(e) => this.getEstablishmentDetails(item)} type="button" className="btn btn-warning" style={{ marginTop: '27px' }}>Check In</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                :
                                                                null}
                                                        <div className="col-12 p-2">
                                                            <div className="mt-5 f-btn trip-btn" style={{ textAlign: 'center' }}>
                                                                {/*<Link to={}>*/}
                                                                <button onClick={(e) => this.getAddressByGoogle()} style={{ padding: '50px', fontSize: '1.2rem' }}>Check In Manually</button>
                                                                {/*</Link>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>

                                        {this.latLng === null ?
                                            <LatLng getLatLng={this.getLatLng} />
                                            : null}
                                    </div>
                                </div>
                            </div>
                        }
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.open}
                            onClose={(e) => this.setState({ open: false })}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={this.state.open}>
                                <Box sx={modalStyle}>
                                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                        <div className="modal-body">
                                            {this.state.error ?
                                                <div className='text-center'>
                                                    <h5>
                                                        {this.state.msg}
                                                    </h5>
                                                    <button onClick={(e) => this.setState({ open: false })} className='btn btn-primary'>Ok</button>
                                                </div>
                                                :
                                                <>
                                                    <div className="circle__box">
                                                        <div className="circle__wrapper circle__wrapper--right">
                                                            <div className="circle__whole circle__right"></div>
                                                        </div>
                                                        <div className="circle__wrapper circle__wrapper--left">
                                                            <div className="circle__whole circle__left"></div>
                                                        </div>
                                                        <div className="circle__checkmark">
                                                        </div>
                                                    </div>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <h5>Check-in Created Successfully.</h5>
                                                        <h6><Link to='/trip' style={{ color: "grey" }}>Click here </Link> for go to
                                                            Check-in dashboard.</h6>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Typography>
                                </Box>
                            </Fade>
                        </Modal>


                    </>
                    :
                    <Redirect to={'/sign-in'} />
                }





            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(CreateTrip);