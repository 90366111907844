import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PageNotFound from "../../common/PageNotFound";
import { Offline, Online } from "react-detect-offline";
import InternetConnection from "../../common/InternetConnection";
import Select from "react-select";
import {axios, url} from "../../../store/actions/actions";
import Loader from "../../common/Loader/Loader";


class ViewTourFundRequest extends React.Component {

  state = {
    tar: null
  }

  componentDidMount() {
    this.loadTourRequest()
  }

  loadTourRequest = () => {
    let pathname = window.location.pathname;

    let pathurl = pathname.split("/");

    const data = {
      id: pathurl[pathurl.length - 1]
    }

    let endpoint = `account/get-tar`
    let token = this.props.user.token

    axios
      .post(endpoint, data, token)
      .then((res)=>{
        let responseData = res.data;
        this.setState({
          tar: responseData.tar
        })
        console.log({'Team response data---------':responseData.team})
      })
    }

  render() {
    return (
            <>
            {this.props.user.login===true?
                <>
                    <Online>
                    {this.props.user.employee_profile==='admin'?
                      <div className="container-fluid">
                        <div className="row justify-content-end">
                          <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-10" : "col-12"}>
                            <div className="col-md-10 d-block m-auto mt-lg-0 mt-4">
                            {/*// <!-- Card Basic Info -->*/}
                              <div className="card mt-4 mb-4" id="basic-info">
                                <div className="card-header">
                                  <h5>Tour Advance Request</h5>
                                </div>
                                {this.state.tar?
                                <div className="card-body pt-0">
                                  <div className="row">
                                    <div className="row col-6">
                                      <div className="col-6">
                                          Team:
                                        {this.state.tar.team.name}
                                      </div>
                                    </div>
                                    <div className="row col-6">
                                      <div className="col-6">
                                          Employee:
                                        {this.state.tar.employee.first_name}
                                      </div>
                                    </div>
                                    <div className="col-6 mt-3">
                                      <div className="input-group input-group-static">
                                        {/*<label>Enter Amount</label>*/}
                                        {/*<input type="text" className="form-control"*/}
                                        {/*onChange={(e) => this.setState({amount: e.target.value})}*/}
                                        {/*/>*/}
                                          Amount:
                                        {this.state.tar.amount}
                                      </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <div className="input-group input-group-static">
                                        {/*<label>Remarks</label>*/}
                                        {/*<input type="text" className="form-control"*/}
                                        {/*onChange={(e) => this.setState({remark: e.target.value})}*/}
                                        {/*/>*/}
                                          Remarks:
                                        {this.state.tar.remark}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :<Loader/>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    :
                        <PageNotFound/>
                    }
                    </Online>
                    <Offline>
                        <InternetConnection/>
                    </Offline>
                </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>
    );
  }
}

const mapStateToProps = (state) => ({
  user:state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(ViewTourFundRequest);
