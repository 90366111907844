import React from "react";
import "../Customer/Style";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PageNotFound from "../../common/PageNotFound";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Offline, Online } from "react-detect-offline";
import InternetConnection from "../../common/InternetConnection";
import { axios } from "../../../store/actions/actions";
import { Multiselect } from "multiselect-react-dropdown";
import TextField from "@mui/material/TextField";
import DataCards from "./DataCards";
import DeleteIcon from "@mui/icons-material/Delete";
import PurposeTable from "./PurposeTable";
import VisitTable from "./VisitTable";
import VisitDataTable from "../VisitDash/VisitDataTable";
import CircularProgress from '@mui/material/CircularProgress';

class EmpDataTable extends React.Component {
  state = {
    executives: null,
    all_visits: null,
    visits: null,
    filterVisits: null,
    states: null,
    cities: null,

    selected_executive_list: [],
    fromDate: null,
    toDate: null,
    selected_state_list: [],
    selected_city_list: [],
    filterExecutive: null,

    all_card_data: null,
    card_data: null,
    customVisit: null,

    executiveName: null,
    from_date: null,
    end_date: null,
    filterResultData: null,
    isLoading: false
  };

  componentDidMount() {
    this.loadReport();
    this.loadExexcutives();
    this.loadDataCardsWithAxios();
    this.getExexcutiveReport();
  }

  loadReport = () => {
    this.setState({
      isLoading: true
    })
    let endpoint = 'teams/get-team-employee-report'
    let token = this.props.user.token
    let data = {
      user: this.props.user.username
    }

    console.log(endpoint, "first", data, token)

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data
      this.setState({
        filterResultData: responseData.employee,
        isLoading: false
      });
      console.log(res.data, "User Wala Hai")
    })
  }

  loadDataCardsWithAxios = () => {
    let data = {
      id: this.state.filterExecutive,
    };
    console.log("my data", this.state.filterExecutive);
    let endpoint = `visit/data-cards`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      this.setState({
        all_card_data: responseData,
        card_data: responseData,
      });
      console.log({ "Card data---------": responseData });
    });
  };

  loadExexcutives = () => {
    let endpoint = `employee/get-sales-executives`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ executives: responseData.executives });
      console.log({ "Executives response data---------": responseData });
    });
  };

  getExexcutiveReport = () => {
    let states = [];
    let cities = [];

    if (
      this.state.selected_state_list !== null &&
      this.state.selected_state_list !== []
    ) {
      console.log("state filter");
      this.state.selected_state_list.map((state, index) =>
        states.push(state["id"])
      );
    }

    if (
      this.state.selected_city_list !== null &&
      this.state.selected_city_list !== []
    ) {
      console.log("state filter");
      this.state.selected_city_list.map((city) => cities.push(city["id"]));
    }
    let endpoint = `visit/get-executive-report?employee_in=${this.state.filterExecutive ? this.state.filterExecutive : ""
      }&state_in=${states}&station_in=${cities}&from_date=${this.state.fromDate ? this.state.fromDate : ""
      }&to_date=${this.state.toDate ? this.state.toDate : ""}`;

    console.log(endpoint);
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      this.loadDataCardsWithAxios();
      const responseData = response.data;
      function getUniqueListBy(arr, key) {
        return [
          ...new Map(arr.map((item) => [item.customer[key], item])).values(),
        ];
      }

      const arr1 = getUniqueListBy(responseData, "company");

      function removeDup(arr, key) {
        return [...new Map(arr.map((item) => [item[key], item])).values()];
      }

      let state_list = [];
      let cities_list = [];

      responseData.map((visit) =>
        visit.station ? state_list.push(visit.station.state) : null
      );
      responseData.map((visit) =>
        visit.station ? cities_list.push(visit.station) : null
      );

      let state_obj_array = removeDup(state_list, "id");
      let cities_obj_array = removeDup(cities_list, "id");

      this.setState({
        all_visits: arr1,
        visits: arr1,
        filterVisits: arr1,
        states: state_obj_array,
        cities: cities_obj_array,
      });
      console.log({ "state---------": state_obj_array });
      console.log({ "station---------": cities_obj_array });
      console.log({ "Executive response data---------": responseData });
    });
  };

  onFilterExecutiveSelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      selected_executive_list: selectedList,
    });

    let idList = [];
    selectedList.map((item) => idList.push(item["id"]));

    this.setState({
      filterExecutive: idList,
    });

    let satrt = setTimeout(this.getExexcutiveReport, 500);
  };

  onFilterExecutiveRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      selected_executive_list: selectedList,
    });
    let idList = [];
    selectedList.map((item) => idList.push(item["id"]));

    this.setState({
      filterExecutive: idList,
    });
    let satrt = setTimeout(this.getExexcutiveReport, 500);
  };

  onFilterCitySelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      selected_city_list: selectedList,
    });
    let satrt = setTimeout(this.getExexcutiveReport, 500);
  };

  onFilterCityRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      selected_city_list: selectedList,
    });
    let satrt = setTimeout(this.getExexcutiveReport, 500);
  };

  onFilterStateSelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      selected_state_list: selectedList,
    });

    let satrt = setTimeout(this.getExexcutiveReport, 500);
  };

  onFilterStateRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);

    this.setState({
      selected_state_list: selectedList,
    });

    let satrt = setTimeout(this.getExexcutiveReport, 500);
  };

  handleCallback = (childData) => {
    if (childData.length === 0) {
      this.setState({
        filterVisits: this.state.visits,
      });
    } else {
      let obj = this.state.visits;

      let filterVisit = obj.filter((item) =>
        item.working_status
          ? item.working_status.questionnaire
            ? item.working_status.questionnaire.some((quest) =>
              childData.includes(quest.option)
            )
            : null
          : null
      );

      // let filterVisit = obj.filter(item =>
      //      item.working_status?item.working_status.questionnaire.some(quest => quest.option===childData)
      // :null)

      //     let filterVisit = obj.filter(item =>
      //          item.working_status!==null
      // )

      // let filterVisit = obj.filter((item) =>{
      //     if(item.working_status!==null || item.working_status.questionnaire!==null || item.working_status!==undefined && item.working_status.questionnaire!==undefined) {
      //         return item.working_status.questionnaire.some(quest => childData.includes(quest.option))
      //     }
      // })

      this.setState({
        filterVisits: filterVisit,
      });

      console.log(".....................", childData);
      console.log(".....................", filterVisit);
    }
  };

  clear = () => {
    let responseData = this.state.all_visits;
    function getUniqueListBy(arr, key) {
      return [
        ...new Map(arr.map((item) => [item.customer[key], item])).values(),
      ];
    }

    const arr1 = getUniqueListBy(responseData, "company");

    function removeDup(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    }

    let state_list = [];
    let cities_list = [];

    responseData.map((visit) =>
      visit.station ? state_list.push(visit.station.state) : null
    );
    responseData.map((visit) =>
      visit.station ? cities_list.push(visit.station) : null
    );

    let state_obj_array = removeDup(state_list, "id");
    let cities_obj_array = removeDup(cities_list, "id");

    this.setState({
      filterVisits: arr1,
      states: state_obj_array,
      cities: cities_obj_array,
      card_data: this.state.all_card_data,

      selected_executive_list: [],
      fromDate: null,
      toDate: null,
      selected_state_list: [],
      selected_city_list: [],
    });
  };

  selectFromDate = (date) => {
    this.setState({
      fromDate: date,
    });
    if (this.state.toDate !== null) {
      let satrt = setTimeout(this.getExexcutiveReport, 500);
    }
  };

  selectToDate = (date) => {
    this.setState({
      toDate: date,
    });
    if (this.state.fromDate !== null) {
      let satrt = setTimeout(this.getExexcutiveReport, 500);
    }
  };

  formSubmitHandlelr = () => {
    console.log("first");
    let endpoint = "employee/user-report-srk";
    const data = {
      user: this.state.filterExecutive,
      sDate: this.state.fromDate,
      eDate: this.state.toDate,
    };

    console.log(data, "Data form submit");

    let token = this.props.user.token;
    this.setState({
      isLoading: true
    })
    axios.post(endpoint, data, token).then((res) => {
      this.setState({
        isLoading: false
      })
      let responseData = res.data;
      console.log(responseData, "Response Data");
      this.setState({
        filterResultData: responseData.employees,
      });
    });
  };

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            {/* <Online> */}
            {this.props.user.employee_profile === "admin" ? (
              <>
                <div className="container-fluid homepage-view">
                  <div className="row justify-content-end">
                    <div
                      className={
                        this.props.sidebar === undefined ||
                          this.props.sidebar === true
                          ? "d-none d-lg-block col-10"
                          : "d-none d-lg-block col-12"
                      }
                    >
                      <div
                        className={
                          this.state.selected_executive_list.length === 0 &&
                            this.state.selected_state_list.length === 0 &&
                            this.state.selected_city_list.length === 0 &&
                            this.state.fromDate === null &&
                            this.state.toDate === null
                            ? "f-btn-display"
                            : "f-btn d-flex justify-content-end mb-2"
                        }
                      >
                        <button onClick={this.clear}>Clear</button>
                      </div>
                      <div className="mb-5 card">
                        <div className="row p-3 align-items-center justify-content-center">
                          {this.state.executives ? (
                            <div className="col-3">
                              <div className="form-check-inline w-100">
                                <>
                                  {this.state.executives ? (
                                    <Multiselect
                                      placeholder="Select Executive"
                                      options={this.state.executives} // Options to display in the dropdown
                                      onSelect={this.onFilterExecutiveSelect} // Function will trigger on select event
                                      onRemove={this.onFilterExecutiveRemove} // Function will trigger on remove event
                                      displayValue="first_name" // Property name to display in the dropdown options
                                      style={{
                                        searchBox: { fontSize: "15px" },
                                      }}
                                    />
                                  ) : null}
                                </>
                              </div>
                            </div>
                          ) : null}

                          {/*{this.state.states?*/}
                          <div className="col-3">
                            <div className="col-12 p-1 w-100">
                              <div className="d-flex mt-2 p-1 align-items-center w-100">
                                <TextField
                                  id="date"
                                  label="From"
                                  type="date"
                                  size="small"
                                  value="2023-07-22"
                                  onChange={(e) =>
                                    this.selectFromDate(e.target.value)
                                  }
                                  // onChange={(e) => this.setState({fromDate: e.target.value})}
                                  sx={{ width: 220 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <p
                                  className="mx-4 my-0"
                                  style={{ fontSize: "24px" }}
                                >
                                  to
                                </p>

                                <TextField
                                  id="date"
                                  label="To"
                                  type="date"
                                  size="small"
                                  value="2023-08-22"
                                  // onChange={(e) => this.setState({toDate: e.target.value})}
                                  onChange={(e) =>
                                    this.selectToDate(e.target.value)
                                  }
                                  sx={{ width: 220 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {/*:null}*/}

                          {/* {this.state.states ? (
                              <div className="col-3">
                                <div className="form-check-inline w-100">
                                  <div className="">
                                    <Multiselect
                                      placeholder="Select State"
                                      options={this.state.states} // Options to display in the dropdown
                                      onSelect={this.onFilterStateSelect} // Function will trigger on select event
                                      onRemove={this.onFilterStateRemove} // Function will trigger on remove event
                                      displayValue="state" // Property name to display in the dropdown options
                                      style={{
                                        searchBox: { fontSize: "15px" },
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {this.state.cities ? (
                              <div className="col-3">
                                <div className="form-check-inline w-100">
                                  <div className="">
                                    <Multiselect
                                      placeholder="Select City"
                                      options={this.state.cities}
                                      onSelect={this.onFilterCitySelect}
                                      onRemove={this.onFilterCityRemove}
                                      displayValue="station"
                                      style={{
                                        searchBox: { fontSize: "15px" },
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null} */}
                          <div className="col-3">
                            <button
                              className="btn btn-primary w-75 m-auto my-3"
                              onClick={this.formSubmitHandlelr}
                            >
                              Search
                            </button>
                          </div>
                        </div>

                      </div>


                      {/* <PurposeTable parentCallback={this.handleCallback}/>
                          <DataCards data={this.state.card_data}/>
                          <VisitTable visit={this.state.filterVisits}/> */}
                    </div>
                  </div>
                </div>

                {/* Ye Pehle Se Tha */}
                <div className="d-none px-2 mt-4">
                  <div
                    className={
                      this.state.selected_executive_list.length === 0 &&
                        this.state.selected_state_list.length === 0 &&
                        this.state.selected_city_list.length === 0 &&
                        this.state.fromDate === null &&
                        this.state.toDate === null
                        ? "f-btn-display"
                        : "f-btn d-flex justify-content-end mb-2"
                    }
                  >
                    <button onClick={this.clear}>Clear</button>
                  </div>
                  <div className="mb-5 card">
                    <div className="row p-3 align-items-center">
                      {this.state.executives ? (
                        <>
                          <div className="col-12">
                            <h4 className="text-center">Search Employee Report</h4>
                          </div>
                          <div className="col-12">
                            <div className="form-check-inline w-100">
                              <>
                                {this.state.executives ? (
                                  <Multiselect
                                    placeholder="Select Executive"
                                    options={this.state.executives} // Options to display in the dropdown
                                    onSelect={this.onFilterExecutiveSelect} // Function will trigger on select event
                                    onRemove={this.onFilterExecutiveRemove} // Function will trigger on remove event
                                    displayValue="first_name" // Property name to display in the dropdown options
                                    style={{
                                      searchBox: { fontSize: "15px" },
                                    }}
                                  />
                                ) : null}
                              </>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {/*{this.state.states?*/}
                      <div className="col-12 my-2">
                        <div className="col-12 p-0 w-100">
                          <div className="d-flex mt-2 p-1 align-items-center w-100">
                            <TextField
                              id="date"
                              label="From"
                              type="date"
                              size="small"
                              onChange={(e) =>
                                this.selectFromDate(e.target.value)
                              }
                              // onChange={(e) => this.setState({fromDate: e.target.value})}

                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <p
                              className="mx-4 my-0"
                              style={{ fontSize: "24px" }}
                            >
                              to
                            </p>

                            <TextField
                              id="date"
                              label="To"
                              type="date"
                              size="small"
                              // onChange={(e) => this.setState({toDate: e.target.value})}
                              onChange={(e) =>
                                this.selectToDate(e.target.value)
                              }
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/*:null}*/}

                      {this.state.states ? (
                        <div className="col-6">
                          <div className="form-check-inline w-100">
                            <div className="">
                              <Multiselect
                                placeholder="Select State"
                                options={this.state.states} // Options to display in the dropdown
                                onSelect={this.onFilterStateSelect} // Function will trigger on select event
                                onRemove={this.onFilterStateRemove} // Function will trigger on remove event
                                displayValue="state" // Property name to display in the dropdown options
                                style={{
                                  searchBox: { fontSize: "15px" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {this.state.cities ? (
                        <div className="col-6">
                          <div className="form-check-inline w-100">
                            <div className="">
                              <Multiselect
                                placeholder="Select City"
                                options={this.state.cities}
                                onSelect={this.onFilterCitySelect}
                                onRemove={this.onFilterCityRemove}
                                displayValue="station"
                                style={{
                                  searchBox: { fontSize: "15px" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* <PurposeTable parentCallback={this.handleCallback}/>
                          <DataCards data={this.state.card_data}/>
                          <VisitTable visit={this.state.filterVisits}/> */}
                </div>

                {/* Ye naya Wala Hai  */}
                <div className="d-lg-none px-2 mt-4">
                  <div
                    className={
                      this.state.selected_executive_list.length === 0 &&
                        this.state.selected_state_list.length === 0 &&
                        this.state.selected_city_list.length === 0 &&
                        this.state.fromDate === null &&
                        this.state.toDate === null
                        ? "f-btn-display"
                        : "f-btn d-flex justify-content-end mb-2"
                    }
                  >
                    <button onClick={this.clear}>Clear</button>
                  </div>
                  <div className="mb-5 card">
                    <div className="row p-3 align-items-center">
                      {this.state.executives ? (
                        <>
                          <div className="col-12">
                            <h4 className="text-center my-4">Search employee report</h4>
                          </div>
                          <div className="col-12">
                            <div className="form-check-inline w-100">
                              <>
                                {this.state.executives ? (
                                  <Multiselect
                                    placeholder="Select Executive"
                                    options={this.state.executives} // Options to display in the dropdown
                                    onSelect={this.onFilterExecutiveSelect} // Function will trigger on select event
                                    onRemove={this.onFilterExecutiveRemove} // Function will trigger on remove event
                                    displayValue="first_name" // Property name to display in the dropdown options
                                    style={{
                                      searchBox: { fontSize: "15px" },
                                    }}
                                  />
                                ) : null}
                              </>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {/*{this.state.states?*/}
                      <div className="col-12 my-2">
                        <div className="col-12 p-0 w-100">
                          <div className="d-flex mt-2 p-1 align-items-center w-100">
                            <TextField
                              id="date"
                              label="From"
                              type="date"
                              size="small"
                              value="2003-03-21"
                              onChange={(e) =>
                                this.selectFromDate(e.target.value)
                              }
                              // onChange={(e) => this.setState({fromDate: e.target.value})}

                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <p
                              className="mx-4 my-0"
                              style={{ fontSize: "24px" }}
                            >
                              to
                            </p>

                            <TextField
                              id="date"
                              label="To"
                              type="date"
                              size="small"
                              // onChange={(e) => this.setState({toDate: e.target.value})}
                              onChange={(e) =>
                                this.selectToDate(e.target.value)
                              }
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary w-75 m-auto my-3"
                        onClick={this.formSubmitHandlelr}
                      >
                        Search
                      </button>
                    </div>
                  </div>

                  {/* <PurposeTable parentCallback={this.handleCallback}/>
                          <DataCards data={this.state.card_data}/>
                          <VisitTable visit={this.state.filterVisits}/> */}
                </div>
                {/* User Report  */}
                <div className="container-fluid homepage-view pb-4">
                  <div className="row justify-content-end">
                    <div className={
                      this.props.sidebar === undefined ||
                        this.props.sidebar === true
                        ? "d-none d-lg-block col-10"
                        : "d-none d-lg-block col-12"
                    }>
                      <div
                        className="dataTable-container table-responsive card p-3"
                        id="visitTable"
                      >
                        <table className="table align-items-center mb-0 dataTable-table kv-table">
                          <thead>
                            <tr>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{
                                  width: "4.8032%",
                                  cursor: "pointer",
                                }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("serial_num")
                                  }
                                >
                                  S.No
                                </a>
                              </th>

                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "7.538%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("station")
                                  }
                                >
                                  EMPLOYEE
                                </a>
                              </th>

                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                data-sortable=""
                                style={{
                                  width: "11.074%",
                                  cursor: "pointer",
                                }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("customer")
                                  }
                                >
                                  CUSTOMER
                                </a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                data-sortable=""
                                style={{ width: "7.938%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) => this.sortVisitTable("date")}
                                >
                                  DATE
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "7.538%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("station")
                                  }
                                >
                                  STATION
                                </a>
                              </th>

                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "6.204%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("board")
                                  }
                                >
                                  BOARD
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "7.738%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("student_str")
                                  }
                                >
                                  STUDENT STR
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{
                                  width: "11.274%",
                                  cursor: "pointer",
                                }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("contact")
                                  }
                                >
                                  CONTATC PERSON INFO
                                </a>
                              </th>
                              <th
                                className="text-center d-none text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "9.006%", cursor: "pointer" }}
                              >
                                <div
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                // onClick={(e)=>this.sortVisitTable("working_status")}
                                >
                                  ACTIONS
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.filterResultData ? this.state.filterResultData.length > 0
                              ? this.state.filterResultData.map(
                                (item, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.employee.first_name + " " + item.employee.last_name} </td>
                                    <td>{item.customer.company}</td>
                                    <td>{item.date}</td>
                                    <td>{item.station ? item.station.station : null}</td>

                                    <td>{item.customer.board}</td>
                                    <td>{item.student_str}</td>
                                    <td>{item.contact_person_name}</td>

                                  </tr>
                                )
                              ) : this.state.isLoading ? (
                                <tr>
                                  <td colSpan={8}>
                                    <p className="d-flex justify-content-center mb-0 align-items-center">
                                      <CircularProgress />
                                    </p>
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td colSpan={8}>
                                    <p className="alert alert-success text-white mb-0 text-center">Sorry No Data</p>
                                  </td>
                                </tr>
                              )
                              : this.state.isLoading ? (
                                <tr>
                                  <td colSpan={8}>
                                    <p className="d-flex justify-content-center mb-0 align-items-center">
                                      <CircularProgress />
                                    </p>
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td colSpan={8}>
                                    <p className="alert alert-success text-white mb-0 text-center">Sorry No Data</p>
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <PageNotFound />
            )}
            {/* </Online> */}
            {/* <Offline>
              <InternetConnection />
            </Offline> */}
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
  user: state.userData.userData,
});

export default connect(mapStateToProps)(EmpDataTable);
