export const stations = [
        {
            "state": "ANDAMAN AND NICOBAR ISLANDS",
            "station": "PORT BLAIR",
            "lat": "11.6645348",
            "lng": "92.7390448"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "VISAKHAPATNAM",
            "lat": "17.7231276",
            "lng": "83.3012842"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "VIJAYAWADA",
            "lat": "16.5087586",
            "lng": "80.6185102"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "GUNTUR",
            "lat": "16.2915189",
            "lng": "80.4541588"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "NELLORE",
            "lat": "14.4493717",
            "lng": "79.9873763"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "KURNOOL",
            "lat": "15.8309251",
            "lng": "78.0425373"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "RAJAMAHENDRAVARAM",
            "lat": "17.0050454",
            "lng": "81.7804732"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "TIRUPATI",
            "lat": "13.77928955",
            "lng": "79.83512262283737"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "KADAPA",
            "lat": "14.4752936",
            "lng": "78.8216861"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "KAKINADA",
            "lat": "17.1213126",
            "lng": "82.18422479792069"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "ANANTAPUR",
            "lat": "14.6546235",
            "lng": "77.55625984224562"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "VIZIANAGARAM",
            "lat": "18.1120819",
            "lng": "83.4052196224888"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "ELURU",
            "lat": "17.015219000000002",
            "lng": "81.08045423126018"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "ONGOLE",
            "lat": "15.5075545",
            "lng": "80.06080046754784"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "NANDYAL",
            "lat": "15.52481495",
            "lng": "78.41517103591221"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "MACHILIPATNAM",
            "lat": "16.1817369",
            "lng": "81.1348181"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "ADONI",
            "lat": "39.913451",
            "lng": "9.185837"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "TENALI",
            "lat": "16.2377735",
            "lng": "80.6464219"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "PRODDATUR",
            "lat": "14.752265900000001",
            "lng": "78.54855180269425"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "CHITTOOR",
            "lat": "13.1601048",
            "lng": "79.15555061803596"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "HINDUPUR",
            "lat": "13.8306127",
            "lng": "77.492635"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "BHIMAVARAM",
            "lat": "16.5427689",
            "lng": "81.527344"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "MADANAPALLE",
            "lat": "13.57325965",
            "lng": "78.47914644847344"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "GUNTAKAL",
            "lat": "15.11965065",
            "lng": "77.45529027323644"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "SRIKAKULAM",
            "lat": "18.32002205",
            "lng": "83.91607719937166"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "DHARMAVARAM",
            "lat": "14.42234715",
            "lng": "77.72006886674166"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "GUDIVADA",
            "lat": "16.4329976",
            "lng": "80.9937151"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "NARASARAOPET",
            "lat": "16.2389242",
            "lng": "80.0472879"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "TADIPATRI",
            "lat": "14.9069559",
            "lng": "78.0097071"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "TADEPALLIGUDEM",
            "lat": "16.8102437",
            "lng": "81.526592"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "AMARAVATI",
            "lat": "16.5096679",
            "lng": "80.5184535"
        },
        {
            "state": "ANDHRA PRADESH",
            "station": "CHILAKALURIPET",
            "lat": "16.0949502",
            "lng": "80.1658779"
        },
        {
            "state": "BIHAR",
            "station": "PATNA",
            "lat": "25.6093239",
            "lng": "85.1235252"
        },
        {
            "state": "BIHAR",
            "station": "GAYA",
            "lat": "24.7964355",
            "lng": "85.0079563"
        },
        {
            "state": "BIHAR",
            "station": "BHAGALPUR",
            "lat": "25.28669805",
            "lng": "87.13225355111148"
        },
        {
            "state": "BIHAR",
            "station": "MUZAFFARPUR",
            "lat": "26.1486581",
            "lng": "85.34001282479626"
        },
        {
            "state": "BIHAR",
            "station": "PURNIA",
            "lat": "26.0",
            "lng": "87.5"
        },
        {
            "state": "BIHAR",
            "station": "DARBHANGA",
            "lat": "26.08314325",
            "lng": "86.03257096612822"
        },
        {
            "state": "BIHAR",
            "station": "BIHAR SHARIF",
            "lat": "25.193859",
            "lng": "85.5208617"
        },
        {
            "state": "BIHAR",
            "station": "ARRAH",
            "lat": "25.62345725",
            "lng": "84.59683868653386"
        },
        {
            "state": "BIHAR",
            "station": "BEGUSARAI",
            "lat": "25.5127194",
            "lng": "86.09057109140021"
        },
        {
            "state": "BIHAR",
            "station": "KATIHAR",
            "lat": "25.560900349999997",
            "lng": "87.64765353997149"
        },
        {
            "state": "BIHAR",
            "station": "MUNGER",
            "lat": "25.22081165",
            "lng": "86.51720367235285"
        },
        {
            "state": "BIHAR",
            "station": "CHHAPRA",
            "lat": "25.7784105",
            "lng": "84.7514507"
        },
        {
            "state": "BIHAR",
            "station": "BETTIAH",
            "lat": "26.791072749999998",
            "lng": "84.56010678772793"
        },
        {
            "state": "BIHAR",
            "station": "SAHARSA",
            "lat": "25.83264165",
            "lng": "86.61489283342814"
        },
        {
            "state": "BIHAR",
            "station": "HAJIPUR",
            "lat": "25.7205805",
            "lng": "85.25555961402884"
        },
        {
            "state": "BIHAR",
            "station": "SASARAM",
            "lat": "24.9001003",
            "lng": "84.0182110684575"
        },
        {
            "state": "BIHAR",
            "station": "DEHRI",
            "lat": "24.9258862",
            "lng": "84.17287231549471"
        },
        {
            "state": "BIHAR",
            "station": "SIWAN",
            "lat": "26.1310043",
            "lng": "84.39125660595198"
        },
        {
            "state": "BIHAR",
            "station": "MOTIHARI",
            "lat": "26.66951195",
            "lng": "84.95741062485514"
        },
        {
            "state": "BIHAR",
            "station": "NAWADA",
            "lat": "24.818123999999997",
            "lng": "85.5185562336921"
        },
        {
            "state": "BIHAR",
            "station": "BAGAHA",
            "lat": "27.05901115",
            "lng": "84.20646375475478"
        },
        {
            "state": "BIHAR",
            "station": "BUXAR",
            "lat": "25.562071500000002",
            "lng": "84.01567204651289"
        },
        {
            "state": "BIHAR",
            "station": "KISHANGANJ",
            "lat": "26.130435300000002",
            "lng": "87.94010318229245"
        },
        {
            "state": "BIHAR",
            "station": "SITAMARHI",
            "lat": "26.5876547",
            "lng": "85.5056744"
        },
        {
            "state": "BIHAR",
            "station": "JAMALPUR",
            "lat": "25.3297912",
            "lng": "86.45677664617543"
        },
        {
            "state": "BIHAR",
            "station": "JEHANABAD",
            "lat": "25.15247135",
            "lng": "85.00687848982191"
        },
        {
            "state": "BIHAR",
            "station": "AURANGABAD",
            "lat": "19.877263",
            "lng": "75.3390241"
        },
        {
            "state": "BIHAR",
            "station": "LAKHISARAI",
            "lat": "25.167702900000002",
            "lng": "86.05935924304018"
        },
        {
            "state": "CHANDIGARH",
            "station": "CHANDIGARH",
            "lat": "30.7334421",
            "lng": "76.7797143"
        },
        {
            "state": "CHHATTISGARH",
            "station": "RAIPUR",
            "lat": "21.2379469",
            "lng": "81.6336833"
        },
        {
            "state": "CHHATTISGARH",
            "station": "DURG",
            "lat": "21.19903535",
            "lng": "81.3979545573657"
        },
        {
            "state": "CHHATTISGARH",
            "station": "NAYA RAIPUR",
            "lat": "21.1610268",
            "lng": "81.7864412"
        },
        {
            "state": "CHHATTISGARH",
            "station": "KORBA",
            "lat": "22.3547468",
            "lng": "82.7110855"
        },
        {
            "state": "CHHATTISGARH",
            "station": "BILASPUR",
            "lat": "28.8680526",
            "lng": "79.29838501460121"
        },
        {
            "state": "CHHATTISGARH",
            "station": "RAJNANDGAON",
            "lat": "20.9727404",
            "lng": "80.69155548263583"
        },
        {
            "state": "CHHATTISGARH",
            "station": "PAKHANJORE",
            "lat": null,
            "lng": null
        },
        {
            "state": "CHHATTISGARH",
            "station": "JAGDALPUR",
            "lat": "19.0870758",
            "lng": "82.0235715"
        },
        {
            "state": "CHHATTISGARH",
            "station": "AMBIKAPUR",
            "lat": "23.1226343",
            "lng": "83.1981886"
        },
        {
            "state": "CHHATTISGARH",
            "station": "CHIRMIRI",
            "lat": "23.1898407",
            "lng": "82.3498026"
        },
        {
            "state": "CHHATTISGARH",
            "station": "DHAMTARI",
            "lat": "20.7084545",
            "lng": "81.5481305"
        },
        {
            "state": "CHHATTISGARH",
            "station": "RAIGARH",
            "lat": "22.5",
            "lng": "83.5"
        },
        {
            "state": "CHHATTISGARH",
            "station": "MAHASAMUND",
            "lat": "21.1882332",
            "lng": "82.48484762499999"
        },
        {
            "state": "DAMAN AND DIU",
            "station": "DAMAN",
            "lat": "20.42000485",
            "lng": "72.86376290300566"
        },
        {
            "state": "DELHI",
            "station": "DELHI",
            "lat": "28.6273928",
            "lng": "77.1716954"
        },
        {
            "state": "DADRA AND NAGAR HAVELI",
            "station": "SILVASSA",
            "lat": "20.2736768",
            "lng": "73.0045787"
        },
        {
            "state": "GOA",
            "station": "PANAJI",
            "lat": "15.4989946",
            "lng": "73.8282141"
        },
        {
            "state": "GOA",
            "station": "VASCO",
            "lat": "44.3506606",
            "lng": "7.8271156"
        },
        {
            "state": "GOA",
            "station": "MORMUGAO",
            "lat": "15.37128395",
            "lng": "73.87924144288202"
        },
        {
            "state": "GOA",
            "station": "MARGAO",
            "lat": "15.272923",
            "lng": "73.9583159"
        },
        {
            "state": "GUJARAT",
            "station": "AHMEDABAD",
            "lat": "23.0216238",
            "lng": "72.5797068"
        },
        {
            "state": "GUJARAT",
            "station": "SURAT",
            "lat": "45.9383",
            "lng": "3.2553"
        },
        {
            "state": "GUJARAT",
            "station": "VADODARA",
            "lat": "22.2973142",
            "lng": "73.1942567"
        },
        {
            "state": "GUJARAT",
            "station": "RAJKOT",
            "lat": "22.3053263",
            "lng": "70.8028377"
        },
        {
            "state": "GUJARAT",
            "station": "BHAVNAGAR",
            "lat": "21.7718836",
            "lng": "72.1416449"
        },
        {
            "state": "GUJARAT",
            "station": "JAMNAGAR",
            "lat": "22.453729600000003",
            "lng": "70.0513626911195"
        },
        {
            "state": "GUJARAT",
            "station": "JUNAGADH",
            "lat": "21.5174104",
            "lng": "70.4642754"
        },
        {
            "state": "GUJARAT",
            "station": "GANDHIDHAM",
            "lat": "23.0718743",
            "lng": "70.131715"
        },
        {
            "state": "GUJARAT",
            "station": "NADIAD",
            "lat": "22.6895535",
            "lng": "72.8713603"
        },
        {
            "state": "GUJARAT",
            "station": "GANDHINAGAR",
            "lat": "23.2232877",
            "lng": "72.6492267"
        },
        {
            "state": "GUJARAT",
            "station": "ANAND",
            "lat": "22.5584995",
            "lng": "72.9625629"
        },
        {
            "state": "GUJARAT",
            "station": "MORBI",
            "lat": "22.8176662",
            "lng": "70.8345928"
        },
        {
            "state": "GUJARAT",
            "station": "KHAMBHAT",
            "lat": "22.3166806",
            "lng": "72.6243126"
        },
        {
            "state": "GUJARAT",
            "station": "SURENDRANAGAR",
            "lat": "22.75",
            "lng": "71.666667"
        },
        {
            "state": "GUJARAT",
            "station": "BHARUCH",
            "lat": "21.75",
            "lng": "73.0"
        },
        {
            "state": "GUJARAT",
            "station": "VAPI",
            "lat": "20.3735482",
            "lng": "72.9084376"
        },
        {
            "state": "GUJARAT",
            "station": "NAVSARI",
            "lat": "20.952407",
            "lng": "72.9323831"
        },
        {
            "state": "GUJARAT",
            "station": "VERAVAL",
            "lat": "20.9101099",
            "lng": "70.365279"
        },
        {
            "state": "GUJARAT",
            "station": "PORBANDAR",
            "lat": "21.6409",
            "lng": "69.611"
        },
        {
            "state": "GUJARAT",
            "station": "GODHRA",
            "lat": "22.7785001",
            "lng": "73.6245157"
        },
        {
            "state": "GUJARAT",
            "station": "BHUJ",
            "lat": "23.2472446",
            "lng": "69.668339"
        },
        {
            "state": "GUJARAT",
            "station": "ANKLESHWAR",
            "lat": "21.6293206",
            "lng": "72.9945103"
        },
        {
            "state": "GUJARAT",
            "station": "BOTAD",
            "lat": "22.1686",
            "lng": "71.6685"
        },
        {
            "state": "GUJARAT",
            "station": "PATAN",
            "lat": "23.77405735",
            "lng": "71.68373465668734"
        },
        {
            "state": "GUJARAT",
            "station": "PALANPUR",
            "lat": "24.170100599999998",
            "lng": "72.43169574086093"
        },
        {
            "state": "GUJARAT",
            "station": "DAHOD",
            "lat": "22.8358542",
            "lng": "74.2556782"
        },
        {
            "state": "GUJARAT",
            "station": "JETPUR",
            "lat": "21.7543725",
            "lng": "70.6189391"
        },
        {
            "state": "GUJARAT",
            "station": "VALSAD",
            "lat": "20.4324018",
            "lng": "73.1411715553577"
        },
        {
            "state": "GUJARAT",
            "station": "KALOL",
            "lat": "22.6103179",
            "lng": "73.4617061"
        },
        {
            "state": "GUJARAT",
            "station": "GONDAL",
            "lat": "21.9349644",
            "lng": "70.7849752761015"
        },
        {
            "state": "GUJARAT",
            "station": "DEESA",
            "lat": "24.2594977",
            "lng": "72.1803348"
        },
        {
            "state": "GUJARAT",
            "station": "AMRELI",
            "lat": "20.866667",
            "lng": "70.75"
        },
        {
            "state": "GUJARAT",
            "station": "AMRELI",
            "lat": "20.866667",
            "lng": "70.75"
        },
        {
            "state": "GUJARAT",
            "station": "MAHUVA",
            "lat": "21.0914409",
            "lng": "71.7621979"
        },
        {
            "state": "GUJARAT",
            "station": "MEHSANA",
            "lat": "23.6015557",
            "lng": "72.3867981"
        },
        {
            "state": "HIMACHAL PRADESH",
            "station": "SHIMLA",
            "lat": "31.1041526",
            "lng": "77.1709729"
        },
        {
            "state": "HARYANA",
            "station": "FARIDABAD",
            "lat": "28.4031478",
            "lng": "77.3105561"
        },
        {
            "state": "HARYANA",
            "station": "GURGAON",
            "lat": "28.42826235",
            "lng": "77.00270014657752"
        },
        {
            "state": "HARYANA",
            "station": "PANIPAT",
            "lat": "29.3912753",
            "lng": "76.9771675"
        },
        {
            "state": "HARYANA",
            "station": "AMBALA",
            "lat": "30.3843674",
            "lng": "76.770421"
        },
        {
            "state": "HARYANA",
            "station": "YAMUNANAGAR",
            "lat": "30.2112003",
            "lng": "77.28638972360895"
        },
        {
            "state": "HARYANA",
            "station": "ROHTAK",
            "lat": "28.9010899",
            "lng": "76.5801935"
        },
        {
            "state": "HARYANA",
            "station": "HISAR",
            "lat": "29.080640950000003",
            "lng": "75.788754109493"
        },
        {
            "state": "HARYANA",
            "station": "KARNAL",
            "lat": "29.6803266",
            "lng": "76.9896254"
        },
        {
            "state": "HARYANA",
            "station": "SONIPAT",
            "lat": "28.9953758",
            "lng": "77.0233627"
        },
        {
            "state": "HARYANA",
            "station": "PANCHKULA",
            "lat": "30.7137692",
            "lng": "76.86827600027706"
        },
        {
            "state": "HARYANA",
            "station": "BHIWANI",
            "lat": "28.7931703",
            "lng": "76.1391283"
        },
        {
            "state": "HARYANA",
            "station": "SIRSA",
            "lat": "29.583333",
            "lng": "75.083333"
        },
        {
            "state": "HARYANA",
            "station": "BAHADURGARH",
            "lat": "28.6933239",
            "lng": "76.9332373"
        },
        {
            "state": "HARYANA",
            "station": "JIND",
            "lat": "29.301826249999998",
            "lng": "76.33847076788838"
        },
        {
            "state": "HARYANA",
            "station": "THANESAR",
            "lat": "29.993039500000002",
            "lng": "76.82922282678291"
        },
        {
            "state": "HARYANA",
            "station": "KAITHAL",
            "lat": "29.822820749999998",
            "lng": "76.37830986647243"
        },
        {
            "state": "HARYANA",
            "station": "REWARI",
            "lat": "28.1956468",
            "lng": "76.6165179"
        },
        {
            "state": "HARYANA",
            "station": "PALWAL",
            "lat": "28.176958900000002",
            "lng": "77.37311203790532"
        },
        {
            "state": "JHARKHAND",
            "station": "JAMSHEDPUR",
            "lat": "22.8015194",
            "lng": "86.2029579"
        },
        {
            "state": "JHARKHAND",
            "station": "DHANBAD",
            "lat": "23.7952809",
            "lng": "86.4309638"
        },
        {
            "state": "JHARKHAND",
            "station": "RANCHI",
            "lat": "23.3700501",
            "lng": "85.3250387"
        },
        {
            "state": "JHARKHAND",
            "station": "BOKARO STEEL CITY",
            "lat": "23.6579595",
            "lng": "86.0839161"
        },
        {
            "state": "JHARKHAND",
            "station": "DEOGHAR",
            "lat": "24.4766423",
            "lng": "86.60673245386945"
        },
        {
            "state": "JHARKHAND",
            "station": "PHUSRO",
            "lat": "23.7601823",
            "lng": "85.9755003"
        },
        {
            "state": "JHARKHAND",
            "station": "HAZARIBAGH",
            "lat": "23.9675151",
            "lng": "85.43884568595615"
        },
        {
            "state": "JHARKHAND",
            "station": "GIRIDIH",
            "lat": "24.1608897",
            "lng": "86.25113503301655"
        },
        {
            "state": "JHARKHAND",
            "station": "RAMGARH",
            "lat": "23.598775949999997",
            "lng": "85.53691564663127"
        },
        {
            "state": "JHARKHAND",
            "station": "MEDININAGAR",
            "lat": "24.02265335",
            "lng": "84.11191072812582"
        },
        {
            "state": "JHARKHAND",
            "station": "CHIRKUNDA",
            "lat": "23.7451314",
            "lng": "86.7869794"
        },
        {
            "state": "JHARKHAND",
            "station": "JHUMRI TELAIYA",
            "lat": "24.4348984",
            "lng": "85.529513"
        },
        {
            "state": "JHARKHAND",
            "station": "SAHIBGANJ",
            "lat": "25.2820998",
            "lng": "87.61850784289338"
        },
        {
            "state": "JAMMU AND KASHMIR",
            "station": "SRINAGAR",
            "lat": "34.0747444",
            "lng": "74.8204443"
        },
        {
            "state": "JAMMU AND KASHMIR",
            "station": "JAMMU",
            "lat": "32.7185614",
            "lng": "74.8580917"
        },
        {
            "state": "JAMMU AND KASHMIR",
            "station": "ANANTNAG",
            "lat": "33.7368773",
            "lng": "75.1455138"
        },
        {
            "state": "KARNATAKA",
            "station": "BENGALURU",
            "lat": "12.9767936",
            "lng": "77.590082"
        },
        {
            "state": "KARNATAKA",
            "station": "HUBLI",
            "lat": "15.3518378",
            "lng": "75.1379848"
        },
        {
            "state": "KARNATAKA",
            "station": "MYSORE",
            "lat": "12.3051828",
            "lng": "76.6553609"
        },
        {
            "state": "KARNATAKA",
            "station": "GULBARGA",
            "lat": "17.166667",
            "lng": "77.083333"
        },
        {
            "state": "KARNATAKA",
            "station": "MANGALORE",
            "lat": "12.8698101",
            "lng": "74.8430082"
        },
        {
            "state": "KARNATAKA",
            "station": "BELGAUM",
            "lat": "15.8572666",
            "lng": "74.5069343"
        },
        {
            "state": "KARNATAKA",
            "station": "DAVANGERE",
            "lat": "14.4679807",
            "lng": "75.9195682"
        },
        {
            "state": "KARNATAKA",
            "station": "BELLARY",
            "lat": "15.1433952",
            "lng": "76.9193876"
        },
        {
            "state": "KARNATAKA",
            "station": "BIJAPUR",
            "lat": "18.7935679",
            "lng": "80.815939"
        },
        {
            "state": "KARNATAKA",
            "station": "SHIMOGA",
            "lat": "13.9326093",
            "lng": "75.574978"
        },
        {
            "state": "KARNATAKA",
            "station": "TUMKUR",
            "lat": "13.3400771",
            "lng": "77.1006208"
        },
        {
            "state": "KARNATAKA",
            "station": "RAICHUR",
            "lat": "16.083333",
            "lng": "77.166667"
        },
        {
            "state": "KARNATAKA",
            "station": "BIDAR",
            "lat": "43.4391702",
            "lng": "-1.5898909"
        },
        {
            "state": "KARNATAKA",
            "station": "HOSPET",
            "lat": "15.266493",
            "lng": "76.3872297"
        },
        {
            "state": "KARNATAKA",
            "station": "HASSAN",
            "lat": "13.0070817",
            "lng": "76.0992703"
        },
        {
            "state": "KARNATAKA",
            "station": "GADAG",
            "lat": "15.4206667",
            "lng": "75.6225644"
        },
        {
            "state": "KARNATAKA",
            "station": "UDUPI",
            "lat": "13.3419169",
            "lng": "74.7473232"
        },
        {
            "state": "KARNATAKA",
            "station": "ROBERTSONPET",
            "lat": "12.9581302",
            "lng": "78.2743674"
        },
        {
            "state": "KARNATAKA",
            "station": "BHADRAVATI",
            "lat": "20.1666702",
            "lng": "79.17211379657827"
        },
        {
            "state": "KARNATAKA",
            "station": "CHITRADURGA",
            "lat": "14.2266443",
            "lng": "76.4005122"
        },
        {
            "state": "KARNATAKA",
            "station": "HARIHAR",
            "lat": "14.5116874",
            "lng": "75.809288"
        },
        {
            "state": "KARNATAKA",
            "station": "KOLAR",
            "lat": "13.1369996",
            "lng": "78.1339606"
        },
        {
            "state": "KARNATAKA",
            "station": "MANDYA",
            "lat": "12.5238888",
            "lng": "76.8961961"
        },
        {
            "state": "KARNATAKA",
            "station": "CHIKKAMAGALLOORU",
            "lat": null,
            "lng": null
        },
        {
            "state": "KARNATAKA",
            "station": "CHIKMAGALUR",
            "lat": "13.318014",
            "lng": "75.7738743"
        },
        {
            "state": "KARNATAKA",
            "station": "GANGAWATI",
            "lat": "15.7212791",
            "lng": "76.7392516"
        },
        {
            "state": "KARNATAKA",
            "station": "RANEBENNURU",
            "lat": "14.6165795",
            "lng": "75.6341529"
        },
        {
            "state": "KARNATAKA",
            "station": "RAMANAGARA",
            "lat": "12.7252766",
            "lng": "77.2804797"
        },
        {
            "state": "KARNATAKA",
            "station": "BAGALKOT",
            "lat": "16.1853166",
            "lng": "75.6967919"
        },
        {
            "state": "KERALA",
            "station": "THIRUVANANTHAPURAM",
            "lat": "8.5241122",
            "lng": "76.9360573"
        },
        {
            "state": "KERALA",
            "station": "KOCHI",
            "lat": "9.931308",
            "lng": "76.2674136"
        },
        {
            "state": "KERALA",
            "station": "CALICUT",
            "lat": "11.2450558",
            "lng": "75.7754716"
        },
        {
            "state": "KERALA",
            "station": "KOLLAM",
            "lat": "8.8879509",
            "lng": "76.5955013"
        },
        {
            "state": "KERALA",
            "station": "THRISSUR",
            "lat": "10.5256264",
            "lng": "76.2132542"
        },
        {
            "state": "KERALA",
            "station": "KANNUR",
            "lat": "11.8763836",
            "lng": "75.3737973"
        },
        {
            "state": "KERALA",
            "station": "KASARAGOD",
            "lat": "12.5035577",
            "lng": "74.9907022"
        },
        {
            "state": "KERALA",
            "station": "ALAPPUZHA",
            "lat": "9.5006651",
            "lng": "76.41241428564258"
        },
        {
            "state": "KERALA",
            "station": "ALAPPUZHA",
            "lat": "9.5006651",
            "lng": "76.41241428564258"
        },
        {
            "state": "KERALA",
            "station": "PALAKKAD",
            "lat": "10.78789845",
            "lng": "76.47408711165895"
        },
        {
            "state": "KERALA",
            "station": "KOTTAYAM",
            "lat": "9.6287383",
            "lng": "76.64553257390992"
        },
        {
            "state": "KERALA",
            "station": "KOTHAMANGALAM",
            "lat": "10.13328035",
            "lng": "76.73612208673686"
        },
        {
            "state": "KERALA",
            "station": "MALAPPURAM",
            "lat": "11.10684475",
            "lng": "76.10995510466662"
        },
        {
            "state": "KERALA",
            "station": "MANJERI",
            "lat": "11.1202289",
            "lng": "76.1198109"
        },
        {
            "state": "KERALA",
            "station": "THALASSERY",
            "lat": "11.83565105",
            "lng": "75.64490544488862"
        },
        {
            "state": "KERALA",
            "station": "PONNANI",
            "lat": "10.77491915",
            "lng": "75.97975130462257"
        },
        {
            "state": "LAKSHADWEEP",
            "station": "KAVARATTI",
            "lat": "10.55905",
            "lng": "72.63656879807692"
        },
        {
            "state": "MAHARASHTRA",
            "station": "MUMBAI",
            "lat": "19.0785451",
            "lng": "72.878176"
        },
        {
            "state": "MAHARASHTRA",
            "station": "PUNE",
            "lat": "18.521428",
            "lng": "73.8544541"
        },
        {
            "state": "MAHARASHTRA",
            "station": "NAGPUR",
            "lat": "21.1498134",
            "lng": "79.0820556"
        },
        {
            "state": "MAHARASHTRA",
            "station": "NASHIK",
            "lat": "20.0112475",
            "lng": "73.7902364"
        },
        {
            "state": "MAHARASHTRA",
            "station": "PIMPRI-CHINCHWAD",
            "lat": "18.6279288",
            "lng": "73.8009829"
        },
        {
            "state": "MAHARASHTRA",
            "station": "AURANGABAD",
            "lat": "19.877263",
            "lng": "75.3390241"
        },
        {
            "state": "MAHARASHTRA",
            "station": "SOLAPUR",
            "lat": "17.84990665",
            "lng": "75.27632027348457"
        },
        {
            "state": "MAHARASHTRA",
            "station": "BHIWANDI",
            "lat": "19.362530800000002",
            "lng": "73.07847467842203"
        },
        {
            "state": "MAHARASHTRA",
            "station": "AMRAVATI",
            "lat": "21.15454115",
            "lng": "77.64429617998744"
        },
        {
            "state": "MAHARASHTRA",
            "station": "NANDED",
            "lat": "19.09400875",
            "lng": "77.48319215130235"
        },
        {
            "state": "MAHARASHTRA",
            "station": "KOLHAPUR",
            "lat": "16.7028412",
            "lng": "74.2405329"
        },
        {
            "state": "MAHARASHTRA",
            "station": "SANGLI-MIRAJ-KUPWAD",
            "lat": "16.8502534",
            "lng": "74.5948885"
        },
        {
            "state": "MAHARASHTRA",
            "station": "JALGAON",
            "lat": "20.84288265",
            "lng": "75.52598140310484"
        },
        {
            "state": "MAHARASHTRA",
            "station": "AKOLA",
            "lat": "20.76181225",
            "lng": "77.1921156663574"
        },
        {
            "state": "MAHARASHTRA",
            "station": "LATUR",
            "lat": "18.35159075",
            "lng": "76.75542361334745"
        },
        {
            "state": "MAHARASHTRA",
            "station": "MALEGAON",
            "lat": "20.590250150000003",
            "lng": "74.5714979275197"
        },
        {
            "state": "MAHARASHTRA",
            "station": "DHULE",
            "lat": "21.13050145",
            "lng": "74.47891180551228"
        },
        {
            "state": "MAHARASHTRA",
            "station": "AHMEDNAGAR",
            "lat": "19.162772500000003",
            "lng": "74.85802430085195"
        },
        {
            "state": "MAHARASHTRA",
            "station": "NANDURBAR",
            "lat": "21.51450155",
            "lng": "74.54070114836503"
        },
        {
            "state": "MAHARASHTRA",
            "station": "ICHALKARANJI",
            "lat": "16.6959348",
            "lng": "74.4555755"
        },
        {
            "state": "MAHARASHTRA",
            "station": "CHANDRAPUR",
            "lat": "20.0967555",
            "lng": "79.50454752230621"
        },
        {
            "state": "MAHARASHTRA",
            "station": "JALNA",
            "lat": "19.9182227",
            "lng": "75.86862461584283"
        },
        {
            "state": "MAHARASHTRA",
            "station": "PARBHANI",
            "lat": "19.29031365",
            "lng": "76.60290343431203"
        },
        {
            "state": "MAHARASHTRA",
            "station": "BHUSAWAL",
            "lat": "20.99327605",
            "lng": "75.83998255383139"
        },
        {
            "state": "MAHARASHTRA",
            "station": "SATARA",
            "lat": "17.63612885",
            "lng": "74.29827807601782"
        },
        {
            "state": "MAHARASHTRA",
            "station": "BEED",
            "lat": "18.9918442",
            "lng": "75.90978399809711"
        },
        {
            "state": "MAHARASHTRA",
            "station": "PANDHARPUR",
            "lat": "17.7255921",
            "lng": "75.30019362933085"
        },
        {
            "state": "MAHARASHTRA",
            "station": "YAVATMAL",
            "lat": "20.325703750000002",
            "lng": "78.11691396684715"
        },
        {
            "state": "MAHARASHTRA",
            "station": "KAMPTEE",
            "lat": "21.2170645",
            "lng": "79.1964178"
        },
        {
            "state": "MAHARASHTRA",
            "station": "GONDIA",
            "lat": "-0.0891",
            "lng": "97.8567"
        },
        {
            "state": "MAHARASHTRA",
            "station": "ACHALPUR",
            "lat": "21.240792499999998",
            "lng": "77.42779700393686"
        },
        {
            "state": "MAHARASHTRA",
            "station": "OSMANABAD",
            "lat": "18.16984395",
            "lng": "76.11796321159572"
        },
        {
            "state": "MAHARASHTRA",
            "station": "HINGANGHAT",
            "lat": "20.475194950000002",
            "lng": "78.74239647465441"
        },
        {
            "state": "MAHARASHTRA",
            "station": "WARDHA",
            "lat": "20.82562315",
            "lng": "78.61314549522919"
        },
        {
            "state": "MAHARASHTRA",
            "station": "BARSHI",
            "lat": "18.182991899999998",
            "lng": "75.74392483608787"
        },
        {
            "state": "MAHARASHTRA",
            "station": "CHALISGAON",
            "lat": "20.502461",
            "lng": "74.96552743211548"
        },
        {
            "state": "MAHARASHTRA",
            "station": "AMALNER",
            "lat": null,
            "lng": null
        },
        {
            "state": "MAHARASHTRA",
            "station": "KHAMGAON",
            "lat": "20.650223949999997",
            "lng": "76.55918676833416"
        },
        {
            "state": "MAHARASHTRA",
            "station": "AKOT",
            "lat": "21.04746095",
            "lng": "77.05527606973357"
        },
        {
            "state": "MAHARASHTRA",
            "station": "UDGIR",
            "lat": "18.43743615",
            "lng": "77.11052147515852"
        },
        {
            "state": "MAHARASHTRA",
            "station": "BHANDARA",
            "lat": "21.12304765",
            "lng": "79.79390882119336"
        },
        {
            "state": "MAHARASHTRA",
            "station": "PARLI",
            "lat": "26.9238875",
            "lng": "76.9112706"
        },
        {
            "state": "MEGHALAYA",
            "station": "SHILLONG",
            "lat": "25.5760446",
            "lng": "91.8825282"
        },
        {
            "state": "MANIPUR",
            "station": "IMPHAL",
            "lat": "24.7991162",
            "lng": "93.9364419"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "INDORE",
            "lat": "22.7203616",
            "lng": "75.8681996"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "BHOPAL",
            "lat": "23.2584857",
            "lng": "77.401989"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "JABALPUR",
            "lat": "23.1608938",
            "lng": "79.9497702"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "GWALIOR",
            "lat": "26.2037247",
            "lng": "78.1573628"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "UJJAIN",
            "lat": "23.1885131",
            "lng": "75.7716562"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "SAGAR",
            "lat": "23.80961225",
            "lng": "78.75911360578634"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "DEWAS",
            "lat": "23.0",
            "lng": "76.166667"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "SATNA",
            "lat": "24.5",
            "lng": "81.0"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "RATLAM",
            "lat": "23.4805919",
            "lng": "74.91778971237613"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "REWA",
            "lat": "24.756718",
            "lng": "81.65493468694177"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "KATNI",
            "lat": "23.8339621",
            "lng": "80.392456"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "SINGRAULI",
            "lat": "24.1974432",
            "lng": "82.6661453"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "BURHANPUR",
            "lat": "26.2697215",
            "lng": "82.99442536730413"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "KHANDWA",
            "lat": "21.9778642",
            "lng": "76.5688282326971"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "MORENA",
            "lat": "26.166667",
            "lng": "77.5"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "BHIND",
            "lat": "26.5",
            "lng": "78.75"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "CHHINDWARA",
            "lat": "22.139831049999998",
            "lng": "78.80964495467987"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "GUNA",
            "lat": "24.5",
            "lng": "77.5"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "SHIVPURI",
            "lat": "25.37524125",
            "lng": "77.82811932629714"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "VIDISHA",
            "lat": "23.5225788",
            "lng": "77.8148001"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "CHHATARPUR",
            "lat": "24.75",
            "lng": "79.75"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "DAMOH",
            "lat": "23.75",
            "lng": "79.583333"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "MANDSAUR",
            "lat": "24.2651306",
            "lng": "75.38718193725012"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "KHARGONE",
            "lat": "21.825599",
            "lng": "75.614182"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "NEEMUCH",
            "lat": "24.63044655",
            "lng": "75.18339614635457"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "PITHAMPUR",
            "lat": "22.6105576",
            "lng": "75.6790283"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "HOSHANGABAD",
            "lat": "22.6001502",
            "lng": "77.92664521413192"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "ITARSI",
            "lat": "22.6124961",
            "lng": "77.762753"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "SEHORE",
            "lat": "23.1156882",
            "lng": "77.06623939176504"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "BETUL",
            "lat": "21.8796162",
            "lng": "77.87568124563079"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "SEONI",
            "lat": "22.275878900000002",
            "lng": "79.721044651047"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "DATIA",
            "lat": "25.75",
            "lng": "78.5"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "NAGDA",
            "lat": "23.587548050000002",
            "lng": "75.67567912676502"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "DHANPURI",
            "lat": "23.2088994",
            "lng": "81.53243045798138"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "DHAR",
            "lat": "22.5",
            "lng": "75.25"
        },
        {
            "state": "MADHYA PRADESH",
            "station": "BALAGHAT",
            "lat": "21.966667",
            "lng": "80.333333"
        },
        {
            "state": "MIZORAM",
            "station": "AIZAWL",
            "lat": "23.7435236",
            "lng": "92.7382905"
        },
        {
            "state": "NAGALAND",
            "station": "DIMAPUR",
            "lat": "25.911744",
            "lng": "93.721703"
        },
        {
            "state": "NAGALAND",
            "station": "KOHIMA",
            "lat": "25.75",
            "lng": "94.166667"
        },
        {
            "state": "ODISHA",
            "station": "BHUBANESWAR",
            "lat": "20.28105765",
            "lng": "85.81140263163533"
        },
        {
            "state": "ODISHA",
            "station": "CUTTACK",
            "lat": "20.4686",
            "lng": "85.8792"
        },
        {
            "state": "ODISHA",
            "station": "ROURKELA",
            "lat": "22.253999399999998",
            "lng": "84.8577520139704"
        },
        {
            "state": "ODISHA",
            "station": "BERHAMPUR",
            "lat": "21.49308515",
            "lng": "86.63272982626935"
        },
        {
            "state": "ODISHA",
            "station": "SAMBALPUR",
            "lat": "21.4706422",
            "lng": "83.98368580351055"
        },
        {
            "state": "ODISHA",
            "station": "PURI",
            "lat": "19.8076083",
            "lng": "85.8252538"
        },
        {
            "state": "ODISHA",
            "station": "BALASORE",
            "lat": "21.5002888",
            "lng": "86.9202627"
        },
        {
            "state": "ODISHA",
            "station": "BHADRAK",
            "lat": "21.0633288",
            "lng": "86.5053731"
        },
        {
            "state": "ODISHA",
            "station": "BARIPADA",
            "lat": "21.9297099",
            "lng": "86.73165610583777"
        },
        {
            "state": "ODISHA",
            "station": "BALANGIR",
            "lat": "20.74978325",
            "lng": "83.44097217446247"
        },
        {
            "state": "ODISHA",
            "station": "JHARSUGUDA",
            "lat": "21.87705125",
            "lng": "84.00905063735019"
        },
        {
            "state": "ODISHA",
            "station": "JEYPORE",
            "lat": "18.89891745",
            "lng": "82.5590760883135"
        },
        {
            "state": "PUNJAB",
            "station": "LUDHIANA",
            "lat": "30.9090157",
            "lng": "75.851601"
        },
        {
            "state": "PUNJAB",
            "station": "AMRITSAR",
            "lat": "31.6343083",
            "lng": "74.8736788"
        },
        {
            "state": "PUNJAB",
            "station": "JALANDHAR",
            "lat": "31.29201065",
            "lng": "75.56805772253911"
        },
        {
            "state": "PUNJAB",
            "station": "PATIALA",
            "lat": "30.2090874",
            "lng": "76.3398720856221"
        },
        {
            "state": "PUNJAB",
            "station": "BATHINDA",
            "lat": "30.17911535",
            "lng": "75.04710156312628"
        },
        {
            "state": "PUNJAB",
            "station": "HOSHIARPUR",
            "lat": "31.60857425",
            "lng": "75.84644246890946"
        },
        {
            "state": "PUNJAB",
            "station": "BATALA",
            "lat": "31.8193025",
            "lng": "75.1999941"
        },
        {
            "state": "PUNJAB",
            "station": "MOHALI",
            "lat": "30.7041168",
            "lng": "76.7176885"
        },
        {
            "state": "PUNJAB",
            "station": "ABOHAR",
            "lat": "30.1450543",
            "lng": "74.1956597"
        },
        {
            "state": "PUNJAB",
            "station": "PATHANKOT",
            "lat": "32.2692452",
            "lng": "75.6528858"
        },
        {
            "state": "PUNJAB",
            "station": "MOGA",
            "lat": "30.7839866",
            "lng": "75.16057422541162"
        },
        {
            "state": "PUNJAB",
            "station": "MALERKOTLA",
            "lat": "30.533129",
            "lng": "75.8807604"
        },
        {
            "state": "PUNJAB",
            "station": "KHANNA",
            "lat": "25.5656914",
            "lng": "80.0634887"
        },
        {
            "state": "PUNJAB",
            "station": "MUKTASAR",
            "lat": null,
            "lng": null
        },
        {
            "state": "PUNJAB",
            "station": "BARNALA",
            "lat": "30.3704685",
            "lng": "75.504017418548"
        },
        {
            "state": "PUNJAB",
            "station": "FIROZPUR",
            "lat": "30.885100350000002",
            "lng": "74.66014081248886"
        },
        {
            "state": "PUNJAB",
            "station": "KAPURTHALA",
            "lat": "31.38524095",
            "lng": "75.30552273989395"
        },
        {
            "state": "PUNJAB",
            "station": "PHAGWARA",
            "lat": "31.2206734",
            "lng": "75.7696463"
        },
        {
            "state": "PUNJAB",
            "station": "ZIRAKPUR",
            "lat": "30.6557257",
            "lng": "76.8200648"
        },
        {
            "state": "PUNJAB",
            "station": "RAJPURA",
            "lat": "30.4776857",
            "lng": "76.5937658"
        },
        {
            "state": "PUDUCHERRY",
            "station": "PONDICHERRY",
            "lat": "11.9340568",
            "lng": "79.8306447"
        },
        {
            "state": "PUDUCHERRY",
            "station": "OZHUKARAI",
            "lat": "11.9414045",
            "lng": "79.8064577"
        },
        {
            "state": "PUDUCHERRY",
            "station": "KARAIKAL",
            "lat": "10.91571",
            "lng": "79.8375761"
        },
        {
            "state": "RAJASTHAN",
            "station": "JAIPUR",
            "lat": "26.9154576",
            "lng": "75.8189817"
        },
        {
            "state": "RAJASTHAN",
            "station": "JODHPUR",
            "lat": "26.275682850000003",
            "lng": "73.02969731064445"
        },
        {
            "state": "RAJASTHAN",
            "station": "KOTA",
            "lat": "25.1737019",
            "lng": "75.8574194"
        },
        {
            "state": "RAJASTHAN",
            "station": "BIKANER",
            "lat": "28.0159286",
            "lng": "73.3171367"
        },
        {
            "state": "RAJASTHAN",
            "station": "AJMER",
            "lat": "26.454041",
            "lng": "74.64046556098174"
        },
        {
            "state": "RAJASTHAN",
            "station": "UDAIPUR",
            "lat": "24.578721",
            "lng": "73.6862571"
        },
        {
            "state": "RAJASTHAN",
            "station": "BHILWARA",
            "lat": "25.48877345",
            "lng": "74.69961283584024"
        },
        {
            "state": "RAJASTHAN",
            "station": "ALWAR",
            "lat": "27.5",
            "lng": "76.5"
        },
        {
            "state": "RAJASTHAN",
            "station": "BHARATPUR",
            "lat": "27.26521245",
            "lng": "77.36912554739122"
        },
        {
            "state": "RAJASTHAN",
            "station": "SRI GANGANAGAR",
            "lat": "29.9270556",
            "lng": "73.8761519"
        },
        {
            "state": "RAJASTHAN",
            "station": "SIKAR",
            "lat": "27.662826000000003",
            "lng": "75.02792628691331"
        },
        {
            "state": "RAJASTHAN",
            "station": "PALI",
            "lat": "48.8588897",
            "lng": "2.3200410217200766"
        },
        {
            "state": "RAJASTHAN",
            "station": "BARMER",
            "lat": "25.5819034",
            "lng": "71.61966242777196"
        },
        {
            "state": "RAJASTHAN",
            "station": "TONK",
            "lat": "26.12214725",
            "lng": "75.66375373932236"
        },
        {
            "state": "RAJASTHAN",
            "station": "KISHANGARH",
            "lat": "26.5885585",
            "lng": "74.861097"
        },
        {
            "state": "RAJASTHAN",
            "station": "CHITTORGARH",
            "lat": "24.718026000000002",
            "lng": "74.47214697200876"
        },
        {
            "state": "RAJASTHAN",
            "station": "BEAWAR",
            "lat": "26.0992137",
            "lng": "74.3127044"
        },
        {
            "state": "RAJASTHAN",
            "station": "HANUMANGARH",
            "lat": "29.5760254",
            "lng": "74.3342203"
        },
        {
            "state": "RAJASTHAN",
            "station": "DHOLPUR",
            "lat": "23.3812393",
            "lng": "76.816535"
        },
        {
            "state": "RAJASTHAN",
            "station": "GANGAPUR",
            "lat": "26.439003149999998",
            "lng": "76.69394713291197"
        },
        {
            "state": "RAJASTHAN",
            "station": "SAWAI MADHOPUR",
            "lat": "26.22914115",
            "lng": "76.30453277017932"
        },
        {
            "state": "RAJASTHAN",
            "station": "CHURU",
            "lat": "28.2061443",
            "lng": "74.69190729963461"
        },
        {
            "state": "RAJASTHAN",
            "station": "BARAN",
            "lat": "24.9171512",
            "lng": "76.69640322489136"
        },
        {
            "state": "RAJASTHAN",
            "station": "MAKRANA",
            "lat": "27.0388103",
            "lng": "74.7271171"
        },
        {
            "state": "RAJASTHAN",
            "station": "NAGAUR",
            "lat": "27.0607859",
            "lng": "74.17667537582712"
        },
        {
            "state": "RAJASTHAN",
            "station": "HINDAUN",
            "lat": "26.7325006",
            "lng": "77.0363121"
        },
        {
            "state": "RAJASTHAN",
            "station": "BHIWADI",
            "lat": "28.2039413",
            "lng": "76.837441"
        },
        {
            "state": "RAJASTHAN",
            "station": "BUNDI",
            "lat": "25.5",
            "lng": "75.833333"
        },
        {
            "state": "RAJASTHAN",
            "station": "SUJANGARH",
            "lat": "27.7011154",
            "lng": "74.4649358"
        },
        {
            "state": "RAJASTHAN",
            "station": "JHUNJHUNU",
            "lat": "28.1287995",
            "lng": "75.3992581"
        },
        {
            "state": "RAJASTHAN",
            "station": "BANSWARA",
            "lat": "23.4930788",
            "lng": "74.34840231310622"
        },
        {
            "state": "RAJASTHAN",
            "station": "SARDARSHAHAR",
            "lat": "28.4403",
            "lng": "74.4895"
        },
        {
            "state": "RAJASTHAN",
            "station": "FATEHPUR",
            "lat": "25.8435395",
            "lng": "80.91800397088501"
        },
        {
            "state": "RAJASTHAN",
            "station": "DAUSA",
            "lat": "26.80486585",
            "lng": "76.44374569929329"
        },
        {
            "state": "RAJASTHAN",
            "station": "KARAULI",
            "lat": "26.51668105",
            "lng": "77.05772976517363"
        },
        {
            "state": "SIKKIM",
            "station": "GANGTOK",
            "lat": "27.329046",
            "lng": "88.6122673"
        },
        {
            "state": "TELANGANA",
            "station": "HYDERABAD",
            "lat": "17.360589",
            "lng": "78.4740613"
        },
        {
            "state": "TELANGANA",
            "station": "WARANGAL",
            "lat": "17.9819448",
            "lng": "79.5969982"
        },
        {
            "state": "TELANGANA",
            "station": "NIZAMABAD",
            "lat": "26.055317950000003",
            "lng": "82.99313867937335"
        },
        {
            "state": "TELANGANA",
            "station": "KARIMNAGAR",
            "lat": "18.4346438",
            "lng": "79.1322648"
        },
        {
            "state": "TELANGANA",
            "station": "RAMAGUNDAM",
            "lat": "18.7615156",
            "lng": "79.4787848"
        },
        {
            "state": "TELANGANA",
            "station": "KHAMMAM",
            "lat": "17.5",
            "lng": "80.333333"
        },
        {
            "state": "TELANGANA",
            "station": "MAHBUBNAGAR",
            "lat": "16.7434538",
            "lng": "77.9923191"
        },
        {
            "state": "TELANGANA",
            "station": "NALGONDA",
            "lat": "16.8579636",
            "lng": "79.21749353315548"
        },
        {
            "state": "TELANGANA",
            "station": "ADILABAD",
            "lat": "19.5",
            "lng": "78.5"
        },
        {
            "state": "TELANGANA",
            "station": "SIDDIPET",
            "lat": "18.005585099999998",
            "lng": "78.89611304969961"
        },
        {
            "state": "TELANGANA",
            "station": "SURYAPET",
            "lat": "17.08001385",
            "lng": "79.7925329742427"
        },
        {
            "state": "TELANGANA",
            "station": "MIRYALAGUDA",
            "lat": "16.8710119",
            "lng": "79.5617353"
        },
        {
            "state": "TELANGANA",
            "station": "JAGTIAL",
            "lat": "18.82135895",
            "lng": "78.91506632525903"
        },
        {
            "state": "TELANGANA",
            "station": "MANCHERIAL",
            "lat": "18.9812967",
            "lng": "79.51980961374048"
        },
        {
            "state": "TELANGANA",
            "station": "KOTHAGUDEM",
            "lat": "17.5456307",
            "lng": "80.6116162"
        },
        {
            "state": "TAMIL NADU",
            "station": "CHENNAI",
            "lat": "13.0836939",
            "lng": "80.270186"
        },
        {
            "state": "TAMIL NADU",
            "station": "COIMBATORE",
            "lat": "11.0018115",
            "lng": "76.9628425"
        },
        {
            "state": "TAMIL NADU",
            "station": "MADURAI",
            "lat": "9.9261153",
            "lng": "78.1140983"
        },
        {
            "state": "TAMIL NADU",
            "station": "TIRUCHIRAPPALLI",
            "lat": "10.804973",
            "lng": "78.6870296"
        },
        {
            "state": "TAMIL NADU",
            "station": "TIRUPUR",
            "lat": "11.127834",
            "lng": "77.3415747"
        },
        {
            "state": "TAMIL NADU",
            "station": "SALEM",
            "lat": "44.9391565",
            "lng": "-123.033121"
        },
        {
            "state": "TAMIL NADU",
            "station": "ERODE",
            "lat": "11.369204400000001",
            "lng": "77.67662686841793"
        },
        {
            "state": "TAMIL NADU",
            "station": "TIRUNELVELI",
            "lat": "8.722388",
            "lng": "77.710247"
        },
        {
            "state": "TAMIL NADU",
            "station": "VELLORE",
            "lat": "12.7948109",
            "lng": "79.0006410968549"
        },
        {
            "state": "TAMIL NADU",
            "station": "TUTICORIN",
            "lat": "8.8052602",
            "lng": "78.1452745"
        },
        {
            "state": "TAMIL NADU",
            "station": "GUDIYATHAM",
            "lat": "12.95797185",
            "lng": "78.76767510274176"
        },
        {
            "state": "TAMIL NADU",
            "station": "NAGERCOIL",
            "lat": "8.1880471",
            "lng": "77.4290492"
        },
        {
            "state": "TAMIL NADU",
            "station": "THANJAVUR",
            "lat": "10.7860267",
            "lng": "79.1381497"
        },
        {
            "state": "TAMIL NADU",
            "station": "DINDIGUL",
            "lat": "10.3303299",
            "lng": "78.0673979084697"
        },
        {
            "state": "TAMIL NADU",
            "station": "VANIYAMBADI",
            "lat": "12.610081749999999",
            "lng": "78.75987014206322"
        },
        {
            "state": "TAMIL NADU",
            "station": "CUDDALORE",
            "lat": "11.7564329",
            "lng": "79.7634644"
        },
        {
            "state": "TAMIL NADU",
            "station": "KOMARAPALAYAM",
            "lat": "11.4425129",
            "lng": "77.6939806"
        },
        {
            "state": "TAMIL NADU",
            "station": "KANCHIPURAM",
            "lat": "12.836393",
            "lng": "79.7053304"
        },
        {
            "state": "TAMIL NADU",
            "station": "AMBUR",
            "lat": "12.7929067",
            "lng": "78.6999168287325"
        },
        {
            "state": "TAMIL NADU",
            "station": "TIRUVANNAMALAI",
            "lat": "12.22721295",
            "lng": "79.07015554906167"
        },
        {
            "state": "TAMIL NADU",
            "station": "PUDUKKOTTAI",
            "lat": "10.5",
            "lng": "78.833333"
        },
        {
            "state": "TAMIL NADU",
            "station": "KUMBAKONAM",
            "lat": "10.9645549",
            "lng": "79.37173036154906"
        },
        {
            "state": "TAMIL NADU",
            "station": "RAJAPALAYAM",
            "lat": "9.403158399999999",
            "lng": "77.51826437085998"
        },
        {
            "state": "TAMIL NADU",
            "station": "HOSUR",
            "lat": "12.7328844",
            "lng": "77.8309478"
        },
        {
            "state": "TAMIL NADU",
            "station": "KARAIKUDI",
            "lat": "10.0728444",
            "lng": "78.7795194"
        },
        {
            "state": "TAMIL NADU",
            "station": "NEYVELI",
            "lat": "11.535987",
            "lng": "79.4824392"
        },
        {
            "state": "TAMIL NADU",
            "station": "NAGAPATTINAM",
            "lat": "10.805627600000001",
            "lng": "79.824659783024"
        },
        {
            "state": "TAMIL NADU",
            "station": "VILUPPURAM",
            "lat": "11.9137874",
            "lng": "79.50789297942772"
        },
        {
            "state": "TAMIL NADU",
            "station": "PARAMAKUDI",
            "lat": "9.48671505",
            "lng": "78.67227177440276"
        },
        {
            "state": "TAMIL NADU",
            "station": "TIRUCHENGODE",
            "lat": "11.38845225",
            "lng": "77.97079099881387"
        },
        {
            "state": "TAMIL NADU",
            "station": "KOVILPATTI",
            "lat": "9.0653134",
            "lng": "77.83047762243062"
        },
        {
            "state": "TAMIL NADU",
            "station": "THENI-ALLINAGARAM",
            "lat": "10.0127737",
            "lng": "77.478234"
        },
        {
            "state": "TAMIL NADU",
            "station": "KADAYANALLUR",
            "lat": "9.0842049",
            "lng": "77.3461957"
        },
        {
            "state": "TAMIL NADU",
            "station": "POLLACHI",
            "lat": "10.67047305",
            "lng": "77.00991258704545"
        },
        {
            "state": "TAMIL NADU",
            "station": "OOTY",
            "lat": "11.4126769",
            "lng": "76.7030504"
        },
        {
            "state": "TRIPURA",
            "station": "AGARTALA",
            "lat": "23.8312377",
            "lng": "91.2823821"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "KANPUR",
            "lat": "26.4609135",
            "lng": "80.3217588"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "LUCKNOW",
            "lat": "26.8381",
            "lng": "80.9346001"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "GHAZIABAD",
            "lat": "28.7218316",
            "lng": "77.45268496448504"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "AGRA",
            "lat": "27.1752554",
            "lng": "78.0098161"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "VARANASI",
            "lat": "25.3356491",
            "lng": "83.0076292"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MEERUT",
            "lat": "29.000652549999998",
            "lng": "77.76822875372174"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "ALLAHABAD",
            "lat": "25.4381302",
            "lng": "81.8338005"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BAREILLY",
            "lat": "28.4157769",
            "lng": "79.45264865437258"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "ALIGARH",
            "lat": "27.833333",
            "lng": "78.166667"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MORADABAD",
            "lat": "28.8638424",
            "lng": "78.80577833091104"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "SAHARANPUR",
            "lat": "29.9880774",
            "lng": "77.5081299470512"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "GORAKHPUR",
            "lat": "26.6711433",
            "lng": "83.36457243864551"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "FAIZABAD",
            "lat": "26.63807555",
            "lng": "82.05902434378625"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "FIROZABAD",
            "lat": "27.17736635",
            "lng": "78.38991197951182"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "JHANSI",
            "lat": "25.531031149999997",
            "lng": "78.65268901605349"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MUZAFFARNAGAR",
            "lat": "29.44800635",
            "lng": "77.74068502568672"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MATHURA",
            "lat": "27.633333",
            "lng": "77.583333"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BUDAUN",
            "lat": "28.06831165",
            "lng": "79.04607312079293"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "RAMPUR",
            "lat": "24.890090999999998",
            "lng": "83.73254274787365"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "SHAHJAHANPUR",
            "lat": "27.912633149999998",
            "lng": "79.74656294869826"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "FARRUKHABAD",
            "lat": "27.43719385",
            "lng": "79.48912946503123"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MAU",
            "lat": "26.0376878",
            "lng": "83.49793418499047"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "HAPUR",
            "lat": "28.740612499999997",
            "lng": "77.83542556519009"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "NOIDA",
            "lat": "28.54322285",
            "lng": "77.33274829733952"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "ETAWAH",
            "lat": "26.718324350000003",
            "lng": "79.09025375025163"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MIRZAPUR",
            "lat": "25.101047700000002",
            "lng": "82.62574935763521"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BULANDSHAHR",
            "lat": "28.38886085",
            "lng": "77.97479772598118"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "SAMBHAL",
            "lat": "28.61875255",
            "lng": "78.55087405404802"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "AMROHA",
            "lat": "28.9233969",
            "lng": "78.48831693262684"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "HARDOI",
            "lat": "27.33857665",
            "lng": "80.0975264641719"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "FATEHPUR",
            "lat": "25.8435395",
            "lng": "80.91800397088501"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "RAEBARELI",
            "lat": "26.25252795",
            "lng": "81.17502108631214"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "ORAI",
            "lat": "25.9359555",
            "lng": "79.42432768008604"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "SITAPUR",
            "lat": "27.5046392",
            "lng": "80.82946583420815"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BAHRAICH",
            "lat": "27.7336958",
            "lng": "81.47732127661058"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MODINAGAR",
            "lat": "28.8261624",
            "lng": "77.54165604660876"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "UNNAO",
            "lat": "26.57550365",
            "lng": "80.61376177782856"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "JAUNPUR",
            "lat": "25.7955927",
            "lng": "82.48834097504385"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "LAKHIMPUR",
            "lat": "27.985060150000002",
            "lng": "80.75384538357649"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "HATHRAS",
            "lat": "27.57324325",
            "lng": "78.11173866751716"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BANDA",
            "lat": "-5.5",
            "lng": "126.5"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "PILIBHIT",
            "lat": "28.55",
            "lng": "80.1"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BARABANKI",
            "lat": "26.93823105",
            "lng": "81.38609761204991"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MUGHALSARAI",
            "lat": "25.3015095",
            "lng": "83.172958"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "KHURJA",
            "lat": "28.2059068",
            "lng": "77.87571443846723"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "GONDA",
            "lat": "27.1096669",
            "lng": "81.9183291218813"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MAINPURI",
            "lat": "27.2098219",
            "lng": "79.04813700847944"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "LALITPUR",
            "lat": "24.70038485",
            "lng": "78.51866758648347"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "ETAH",
            "lat": "27.5541146",
            "lng": "78.60078640304634"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "DEORIA",
            "lat": "26.42384715",
            "lng": "83.76273169028727"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "UJHANI",
            "lat": "27.9951096",
            "lng": "79.0153138"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "GHAZIPUR",
            "lat": "25.603508400000003",
            "lng": "83.50745404887138"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "SULTANPUR",
            "lat": "26.242510850000002",
            "lng": "82.29616931685918"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "AZAMGARH",
            "lat": "26.02269675",
            "lng": "83.02887343114848"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BIJNOR",
            "lat": "29.40604895",
            "lng": "78.48087839917878"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "SAHASWAN",
            "lat": "28.111908",
            "lng": "78.74639723157563"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BASTI",
            "lat": "26.724789",
            "lng": "82.79326865024002"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "CHANDAUSI",
            "lat": "28.449104",
            "lng": "78.7828513"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "AKBARPUR",
            "lat": "26.424310650000002",
            "lng": "82.5679341735663"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BALLIA",
            "lat": "25.877932549999997",
            "lng": "84.11995931460379"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MUBARAKPUR",
            "lat": "26.0875514",
            "lng": "83.2905851"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "TANDA",
            "lat": "28.98100145",
            "lng": "78.97480687399917"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "GREATER NOIDA",
            "lat": "28.4670734",
            "lng": "77.5137649"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "SHIKOHABAD",
            "lat": "27.0366038",
            "lng": "78.6514361805649"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "SHAMLI",
            "lat": "29.5008816",
            "lng": "77.34838258540861"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BARAUT",
            "lat": "29.154147700000003",
            "lng": "77.30595350833059"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "KHAIR",
            "lat": "27.9843591",
            "lng": "77.73740537405565"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "KASGANJ",
            "lat": "27.883846050000002",
            "lng": "78.63489003747873"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "AURAIYA",
            "lat": "26.65573395",
            "lng": "79.51504701181831"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "KHATAULI",
            "lat": "29.29320165",
            "lng": "77.74500681677128"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "DEOBAND",
            "lat": "29.7253279",
            "lng": "77.67249791858319"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "NAGINA",
            "lat": "29.538422",
            "lng": "78.51514669104847"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MAHOBA",
            "lat": "25.36176885",
            "lng": "79.70499475331877"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "MURADNAGAR",
            "lat": "28.7733808",
            "lng": "77.5033582"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "BHADOHI",
            "lat": "25.422922749999998",
            "lng": "82.48843708088575"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "DADRI",
            "lat": "28.602515699999998",
            "lng": "76.19098981248771"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "PRATAPGARH",
            "lat": "25.75",
            "lng": "81.75"
        },
        {
            "state": "UTTAR PRADESH",
            "station": "NAJIBABAD",
            "lat": "29.607202100000002",
            "lng": "78.28537443588678"
        },
        {
            "state": "UTTARAKHAND",
            "station": "DEHRADUN",
            "lat": "30.3255646",
            "lng": "78.0436813"
        },
        {
            "state": "UTTARAKHAND",
            "station": "HARIDWAR",
            "lat": "29.9384473",
            "lng": "78.1452985"
        },
        {
            "state": "UTTARAKHAND",
            "station": "ROORKEE",
            "lat": "29.8693496",
            "lng": "77.8902124"
        },
        {
            "state": "UTTARAKHAND",
            "station": "HALDWANI",
            "lat": "29.2144809",
            "lng": "79.5279012"
        },
        {
            "state": "UTTARAKHAND",
            "station": "RUDRAPUR",
            "lat": "28.974744",
            "lng": "79.3963951"
        },
        {
            "state": "UTTARAKHAND",
            "station": "KASHIPUR",
            "lat": "29.2117573",
            "lng": "78.9617306"
        },
        {
            "state": "UTTARAKHAND",
            "station": "RISHIKESH",
            "lat": "30.1086537",
            "lng": "78.2916193"
        },
        {
            "state": "WEST BENGAL",
            "station": "KOLKATA",
            "lat": "22.5414185",
            "lng": "88.35769124388872"
        },
        {
            "state": "WEST BENGAL",
            "station": "ASANSOL",
            "lat": "23.6871297",
            "lng": "86.9746587"
        },
        {
            "state": "WEST BENGAL",
            "station": "SILIGURI",
            "lat": "26.7164127",
            "lng": "88.4309916"
        },
        {
            "state": "WEST BENGAL",
            "station": "DURGAPUR",
            "lat": "23.5350475",
            "lng": "87.3380425"
        },
        {
            "state": "WEST BENGAL",
            "station": "BARDHAMAN",
            "lat": "23.2495714",
            "lng": "87.8681751"
        },
        {
            "state": "WEST BENGAL",
            "station": "MALDA",
            "lat": "25.0057449",
            "lng": "88.1398483"
        },
        {
            "state": "WEST BENGAL",
            "station": "BAHARAMPUR",
            "lat": "24.1044927",
            "lng": "88.2510635"
        },
        {
            "state": "WEST BENGAL",
            "station": "HABRA",
            "lat": "22.8400629",
            "lng": "88.6630678"
        },
        {
            "state": "WEST BENGAL",
            "station": "JALPAIGURI",
            "lat": "26.4733048",
            "lng": "88.6788668614395"
        },
        {
            "state": "WEST BENGAL",
            "station": "KHARAGPUR",
            "lat": "25.133173050000003",
            "lng": "86.52504036339789"
        },
        {
            "state": "WEST BENGAL",
            "station": "SHANTIPUR",
            "lat": "23.2464",
            "lng": "88.4437"
        },
        {
            "state": "WEST BENGAL",
            "station": "DANKUNI",
            "lat": "22.6820899",
            "lng": "88.2907444"
        },
        {
            "state": "WEST BENGAL",
            "station": "DHULIAN",
            "lat": "24.6786543",
            "lng": "87.954654"
        },
        {
            "state": "WEST BENGAL",
            "station": "RANAGHAT",
            "lat": "23.1738124",
            "lng": "88.563677"
        },
        {
            "state": "WEST BENGAL",
            "station": "HALDIA",
            "lat": "22.028124",
            "lng": "88.0632655"
        },
        {
            "state": "WEST BENGAL",
            "station": "RAIGANJ",
            "lat": "25.680653900000003",
            "lng": "88.12464576019889"
        },
        {
            "state": "WEST BENGAL",
            "station": "KRISHNANAGAR",
            "lat": "23.4058481",
            "lng": "88.4958935"
        },
        {
            "state": "WEST BENGAL",
            "station": "NABADWIP",
            "lat": "23.3889007",
            "lng": "88.37243900644481"
        },
        {
            "state": "WEST BENGAL",
            "station": "MIDNAPORE",
            "lat": "22.4671958",
            "lng": "87.17500550608798"
        },
        {
            "state": "WEST BENGAL",
            "station": "BALURGHAT",
            "lat": "25.2634866",
            "lng": "88.78900293567636"
        },
        {
            "state": "WEST BENGAL",
            "station": "BASIRHAT",
            "lat": "22.6611962",
            "lng": "88.8660215"
        },
        {
            "state": "WEST BENGAL",
            "station": "BANKURA",
            "lat": "23.13195425",
            "lng": "87.20739720367507"
        },
        {
            "state": "WEST BENGAL",
            "station": "CHAKDAHA",
            "lat": "23.0774983",
            "lng": "88.5320399"
        },
        {
            "state": "WEST BENGAL",
            "station": "DARJEELING",
            "lat": "27.0377554",
            "lng": "88.263176"
        },
        {
            "state": "WEST BENGAL",
            "station": "ALIPURDUAR",
            "lat": "26.4851573",
            "lng": "89.5246926"
        },
        {
            "state": "WEST BENGAL",
            "station": "PURULIA",
            "lat": "23.032189",
            "lng": "89.5911025"
        },
        {
            "state": "WEST BENGAL",
            "station": "JANGIPUR",
            "lat": "24.4561534",
            "lng": "88.1073968"
        },
        {
            "state": "WEST BENGAL",
            "station": "BANGAON",
            "lat": "23.0434655",
            "lng": "88.8317765"
        },
        {
            "state": "WEST BENGAL",
            "station": "COOCH BEHAR",
            "lat": "26.3223696",
            "lng": "89.4592445"
        },
        {
            "state": "WEST BENGAL",
            "station": "BOLPUR",
            "lat": "23.661154",
            "lng": "87.6962424"
        },
        {
            "state": "WEST BENGAL",
            "station": "KANTHI",
            "lat": "25.0645116",
            "lng": "77.5197458"
        }
    ]

export const states = [
        {
            "state": "ANDAMAN AND NICOBAR ISLANDS"
        },
        {
            "state": "ANDHRA PRADESH"
        },
        {
            "state": "BIHAR"
        },
        {
            "state": "CHANDIGARH"
        },
        {
            "state": "CHHATTISGARH"
        },
        {
            "state": "DAMAN AND DIU"
        },
        {
            "state": "DELHI"
        },
        {
            "state": "DADRA AND NAGAR HAVELI"
        },
        {
            "state": "GOA"
        },
        {
            "state": "GUJARAT"
        },
        {
            "state": "HIMACHAL PRADESH"
        },
        {
            "state": "HARYANA"
        },
        {
            "state": "JHARKHAND"
        },
        {
            "state": "JAMMU AND KASHMIR"
        },
        {
            "state": "KARNATAKA"
        },
        {
            "state": "KERALA"
        },
        {
            "state": "LAKSHADWEEP"
        },
        {
            "state": "MAHARASHTRA"
        },
        {
            "state": "MEGHALAYA"
        },
        {
            "state": "MANIPUR"
        },
        {
            "state": "MADHYA PRADESH"
        },
        {
            "state": "MIZORAM"
        },
        {
            "state": "NAGALAND"
        },
        {
            "state": "ODISHA"
        },
        {
            "state": "PUNJAB"
        },
        {
            "state": "PUDUCHERRY"
        },
        {
            "state": "RAJASTHAN"
        },
        {
            "state": "SIKKIM"
        },
        {
            "state": "TELANGANA"
        },
        {
            "state": "TAMIL NADU"
        },
        {
            "state": "TRIPURA"
        },
        {
            "state": "UTTAR PRADESH"
        },
        {
            "state": "UTTARAKHAND"
        },
        {
            "state": "WEST BENGAL"
        }
    ]

export const all_divisions = [
    {'name': 'SALES TEAM'},
    {'name': 'OPERATIONS'},
    {'name': 'EDITORIAL'},
    {'name': 'PRODUCTION'},
    {'name': 'ADMIN'},
    {'name': 'DISPATCH'},
    {'name': 'HR'}
]

export const books = [
    {'name': 'OPEN SKIES(ENG. READER)', 'id': '567'},
    {'name': 'NEW ESSENTIAL OF ENGLISH GRAMMER', 'id': '567'},
    {'name': 'NEW WISDOM TREE INCLUDE YOGA', 'id': '567'},
    {'name': 'KASTURI(HINDI READER)', 'id': '567'},
    {'name': 'VAYAKARAN SAROVAR', 'id': '567'},
    {'name': 'SUBODH SANSKRIT MALA', 'id': '567'},
    {'name': 'O MY MOTHER LAND(VALUE EDUCATION)', 'id': '567'},
    {'name': 'MATHEMATICS LAB MANUAL', 'id': '567'},
    {'name': 'PATHFINDER SOCIAL STUDIES', 'id': '567'},
    {'name': 'MATH WIZARD', 'id': '567'},
]

export const boards = [
    {'name': 'Board of secondary education (Andhra Pradesh)'},
    {'name': 'Board of intermediate education  (Andhra Pradesh)'},
    {'name': 'Central Board Of Secondary Education'},
    {'name': 'Assam Higher Secondary Education Council'},
    {'name': 'Board of Secondary Education, Assam'},
    {'name': 'Bihar School Examination Board'},
    {'name': 'Chhatisgarh Board Of Secondary Education'},
    {'name': 'Goa Board Of Secondary And Higher Secondary Education'},
    {'name': 'Gujarat Secondary And Higher Secondary Education Board'},
    {'name': 'Haryana Board of School Education'},
    {'name': 'Himachal Pradesh Board of School Education'},
    {'name': 'Jammu and Kashmir State Board of School Education'},
    {'name': 'Jharkhand Academic Council,Ranchi'},
    {'name': 'Govt. Of Karnataka Dept. Of Pre-University Education'},
    {'name': 'Karnataka Secondary Education, Examination Board'},
    {'name': 'Kerala Board Of Public Examination'},
    {'name': 'Kerala Board Of Higher Secondary Education'},
    {'name': 'Secondary School Leaving Certificate Examination Board Kerala'},
    {'name': 'Board of Secondary Education,  Madhya Pradesh'},
    {'name': 'Maharashtra State Board Of Secondary And Higher Secondary Education'},
    {'name': 'Board of Secondary Education, Manipur'},
    {'name': 'Council of Higher Secondary Education, Manipur'},
    {'name': 'Meghalaya Board Of School Education'},
    {'name': 'Mizoram Board of School Education'},
    {'name': 'Nagaland Board of School Education'},
    {'name': 'Council of Higher Secondary Education, Odisha'},
    {'name': 'Board of Secondary Education, Odisha'},
    {'name': 'Punjab School Education Board'},
    {'name': 'Board of Secondary Education Rajasthan'},
    {'name': 'Rajasthan State Open School, Jaipur'},
    {'name': 'Central Board Of Secondary Education'},
    {'name': 'State Board Of School Examinations(Sec.) & Board Of Higher Secondary Examinations, Tamil Nadu'},
    {'name': 'Telangana State Board Of Intermediate Education, Nampally Hyderabad-500001'},
    {'name': 'Telangana State Board Of Secondary Education, Chapel Road, Nampally, Hyderabad-500001'},
    {'name': 'Tripura Board Of Secondary Education'},
    {'name': 'Board of School Education Uttarakhand'},
    {'name': 'U.P. Board Of High School & Intermediate Education'},
    {'name': 'Board of Secondary Education Kant Shahjahanpur U.P.'},
    {'name': 'West Bengal Board Of Secondary Education'},
    {'name': 'west Bengal Council Of Higher Secondary Education'},
    {'name': 'West Bengal Board Of Primary Education'},
    {'name': 'Higher Secondary Certificate Examination Board, Ghadinagar'},
    {'name': 'Gujarat Secondary Education Board (GSEB) Vadodara, Gujarat.'},
    {'name': 'Board of Secondary Education, Tamilnadu'},
    {'name': 'Tamilnadu Board of public Examination'},
    {'name': 'Department of Education, Puducherry'},
]


















