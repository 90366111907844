import React, { useState, useEffect } from 'react';
import {
    axios,
    google_map_api_key,
  } from "../../../store/actions/actions";
import 'leaflet/dist/leaflet.css';

function GoogleAPITesting() {
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const [places, setPlaces] = useState([]); // State to store nearby places
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');

    // Function to get current location using Geolocation API
    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLat(position.coords.latitude);
                    setLong(position.coords.longitude);
                },
                (error) => {
                    console.error("Error fetching location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    // Function to get nearby places like schools and book stores
    const getNearbyPlaces = async (lat, lng) => {
        try {
            // API call for schools
            const schoolResponse = await axios.get(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${lng}&radius=300&type=school&key=${google_map_api_key}`);
            const schools = schoolResponse.data.results;

            // API call for book stores
            const bookStoreResponse = await axios.get(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${lng}&radius=300&type=book_store&key=${google_map_api_key}`);
            const bookStores = bookStoreResponse.data.results;

            // Merging both results
            const mergedPlaces = [...schools, ...bookStores];

            // Setting merged results to state
            setPlaces(mergedPlaces);
        } catch (error) {
            console.error("Error fetching places:", error);
        }
    };

    // Function to get city, state, and pincode using Geocoding API
    const getLocationDetails = async (lat, lng) => {
        try {
            const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${google_map_api_key}`;
            const response = await axios.get(endpoint);
            const results = response.data.results;
            
            if (results.length > 0) {
                const addressComponents = results[0].address_components;

                // Iterate through address components to find city, state, and pincode
                addressComponents.forEach(component => {
                    const types = component.types;

                    if (types.includes('locality')) {
                        setCity(component.long_name);
                    }
                    if (types.includes('administrative_area_level_1')) {
                        setState(component.long_name);
                    }
                    if (types.includes('postal_code')) {
                        setPincode(component.long_name);
                    }
                });
            }
        } catch (error) {
            console.error("Error fetching location details:", error);
        }
    };

    // Fetch current location on component mount
    useEffect(() => {
        getCurrentLocation();
    }, []);

    // Fetch nearby places and location details when lat and long are set
    useEffect(() => {
        if (lat && long) {
            getNearbyPlaces(lat, long);
            getLocationDetails(lat, long);
        }
    }, [lat, long]);

    return (
        <div>
            <h3>Your Location</h3>
            <p>Latitude: {lat}</p>
            <p>Longitude: {long}</p>

            <h3>Location Details:</h3>
            <p>City: {city}</p>
            <p>State: {state}</p>
            <p>Pincode: {pincode}</p>

            <h3>Nearby Schools and Book Sellers (within 300 meters):</h3>
            <ul>
                {places.length > 0 ? (
                    places.map((place) => (
                        <li key={place.place_id}>{place.name}</li>
                    ))
                ) : (
                    <li>No places found within 300 meters.</li>
                )}
            </ul>
        </div>
    );
}

export default GoogleAPITesting;
