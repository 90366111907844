import React from "react";
import { connect } from "react-redux";
import {
  axios,
  sideBar,
  url,
  userLogin,
  modalStyle,
} from "../../../store/actions/actions";
import "../../common/imageCase.css";
import { Link, Redirect } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import StarRatings from "react-star-ratings/build/star-ratings";
import Stack from "@mui/material/Stack";
import {
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

class ScheduleVisit extends React.Component {
  state = {
    all_customers: null,
    all_employee: null,
    result: null,

    employee: null,
    start_date: null,
    start_time: null,

    show_start_time: null,

    customer: null,
    customer_type: null,
    company: null,
    cust_phone: null,
    website: null,
    rating: null,

    street: null,
    city: null,
    pin_code: null,
    state: null,
    area: null,
    route: null,
    landmark: null,
    sector: null,
    block: null,

    lat: null,
    long: null,
    srklong: null,
    map_address: null,

    search_text: null,

    submit: false,
    open: null,
    msg: null,

    manual_employee: null,
    manual_customer: null,
    manual_date_time: null,
    allCustomerLoaded:false
  };

  componentDidMount() {
    this.loadSelectCustomers();
    this.loadSelectEmployees();

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          this.setState({ lat:lat, srklong:lon });
      });
  } else {
      alert("Geolocation is not supported in your browser.");
  }
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  loadSelectCustomers = () => {
    let endpoint = `customer/select-customer`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      console.log({ "Customers Response data------------>": responseData });
      this.setState({
        all_customers: responseData.customers,
      });
      this.setState({
        allCustomerLoaded:true
      })
    });
  };

  loadSelectEmployees = () => {
    let endpoint = `/account/get-employees`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({
        all_employee: responseData.employee,
      });
      console.log({ "Employee data---------": responseData.employee });
    });
  };

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  dateFormat = (dateType, date) => {
    var date = new Date(date),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    if (dateType === "start_date") {
      this.setState({
        start_date: [date.getFullYear(), mnth, day].join("-"),
      });
    }
    if (dateType === "end_date") {
      this.setState({
        end_date: [date.getFullYear(), mnth, day].join("-"),
      });
    }
    console.log([date.getFullYear(), mnth, day].join("-"));
  };

  timeFormat = (timeType, time) => {
    if (timeType === "start_time") {
      this.setState({
        start_time: `${time.$H}:${time.$m}`,
        show_start_time: time,
      });
    }
    if (timeType === "end_time") {
      this.setState({
        end_time: `${time.$H}:${time.$m}`,
        show_end_time: time,
      });
    }
    console.log(time);
    console.log(`${time.$H}:${time.$m}`);
  };

  // test = (text) => {
  //     var input = document.getElementById("search-text2");
  //     console.log(text)
  //     input.addEventListener("keypress", function(event) {
  //       if (event.key === "Enter") {
  //         // event.preventDefault();
  //         // document.getElementById("myBtn").click();
  //           console.log('**********************************Enter*********************************')
  //       }
  //     });
  // }

  search = () => {
    let endpoint = `customer/establishment-search?query=${this.state.search_text}`;
    let token = this.props.user.token;
    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      if (responseData.success === true) {
        this.setState({ result: responseData.result });
      }
      console.log({ "Response---------": responseData });
    });
  };

  getEstablishmentDetails = (obj) => {
    let endpoint = `customer/get-establishment-details/${obj.place_id}`;
    let token = this.props.user.token;
    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      console.log({
        "**************** response data---------": responseData,
      });

      this.setState({
        customer: obj,

        company: responseData.name,
        cust_phone: responseData.phone,
        website: responseData.website,
        rating: responseData.rating === null ? 0 : responseData.rating,

        street: responseData.address.street
          ? responseData.address.street + ", "
          : "",
        city: responseData.address.city,
        pin_code: responseData.address.pin_code,
        state: responseData.address.state,
        area: responseData.address.area ? responseData.address.area + ", " : "",
        route: responseData.address.route
          ? responseData.address.route + ", "
          : "",
        landmark: responseData.address.landmark
          ? responseData.address.landmark + ", "
          : "",
        sector: responseData.address.sector
          ? responseData.address.sector + ", "
          : "",
        block: responseData.address.block
          ? responseData.address.block + ", "
          : "",
        lat: obj.geometry.location.lat,
        long: obj.geometry.location.lng,
        map_address: `${responseData.name}, ${responseData.address.city}, ${responseData.address.state}`,
      });
    });
  };

  manualScheduleVisitHandler = (event) => {
    event.preventDefault();
    const data = {
      manual_employee: this.state.manual_employee,
      manual_customer: this.state.manual_customer,
      manual_date_time: this.state.manual_date_time,
      lat: this.state.lat,
      long: this.state.srklong
  };

    console.log(data, "-==-=-=-=-=")

    
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          console.log(lon, "Longitude")
          this.setState({ lat:lat, srklong:lon });
      });
  } else {
      alert("Geolocation is not supported in your browser.");
  }

    let endpoint = "visit/manual-schedule-visit";
    let token = this.props.user.token;
    console.log(token, "Token", data);

    axios.post(endpoint, data, token).then((res) => {
      this.setState({
        open: true,
      });
      console.log("Sharukh", res);
    });
  };

  submit = () => {
    this.setState({ submit: false });
    const data = {
      street_name: this.state.city
        ? this.state.street +
          this.state.block +
          this.state.sector +
          this.state.route +
          this.state.landmark +
          this.state.area
        : "",
      dist: this.state.city,
      pin_code: this.state.pin_code,
      state: this.state.state,

      customer_type: this.state.customer_type,
      company: this.state.company,
      cust_phone: this.state.cust_phone,
      rating: this.state.rating,
      website: this.state.website,

      address_type: this.state.address_type,
      lat: this.state.lat,
      long: this.state.long,
      map_address: `${this.state.company}, ${this.state.city}, ${this.state.state}`,

      scheduled_for: this.state.employee,
      start_date: this.state.start_date,
      start_time: this.state.start_time,
    };
    console.log(data);

    let endpoint = `visit/scheduled-visit`;
    let token = this.props.user.token;


    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      if (responseData.success === true) {
        this.setState({ submit: true, open: true, msg: responseData.msg });
      } else {
        this.setState({ open: true, msg: responseData.msg });
      }
      console.log({ "Response---------": responseData });
    });
  };

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="d-none d-lg-block container-fluid">
              <div className="row justify-content-end">
                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "col-10"
                      : "col-12"
                  }
                >
                  <div className="row mt-4 mb-5 justify-content-center">
                    <div
                      className="col-md-10 mt-lg-0 mt-4"
                      style={{ textAlign: "-webkit-center" }}
                    >
                      <div className="s01">
                        <div className="card inner-form d-none">
                          <div className="schedule-visit-search-bar ">
                            <div className="input-field first-wrap">
                              <input
                                id="search-text"
                                type="text"
                                placeholder="What are you looking for?"
                                onChange={(e) =>
                                  this.setState({ search_text: e.target.value })
                                }
                              />
                            </div>
                            <div className="input-field third-wrap">
                              <button
                                className="btn-search"
                                onClick={this.search}
                                type="button"
                              >
                                Search 
                              </button>
                            </div>
                          </div>

                          {this.state.customer === null && this.state.result ? (
                            <div className="mt-3">
                              {this.state.result.map((item) => (
                                <div
                                  className="p-2"
                                  style={{ textAlign: "start" }}
                                >
                                  <div
                                    className="card"
                                    style={{
                                      boxShadow:
                                        "0 4px 6px -1px rgb(0 0 0 / 10%), -2px -2px 4px -1px rgb(0 0 0 / 6%)",
                                    }}
                                  >
                                    <div
                                      className="row"
                                      style={{ padding: "5px" }}
                                    >
                                      <div
                                        className="col-lg-3 mt-1"
                                        style={{ textAlign: "-webkit-center" }}
                                      >
                                        <div
                                          className="card"
                                          style={{
                                            maxWidth: "225px",
                                            maxHeight: "200px",
                                            border: "none",
                                            padding: "0px 7px 7px 7px",
                                            background: "#f3efe0",
                                          }}
                                        >
                                          {/*<img style={{maxHeight: '166px'}} src={item.type==='SCHOOL'?require("../../../custom/school.png"):require("../../../custom/seller.png")} alt=""/>*/}
                                          <img
                                            style={{ maxHeight: "166px" }}
                                            src={
                                              item.icon.split("/")[
                                                item.icon.split("/").length - 1
                                              ] === "school-71.png"
                                                ? require("../../../custom/school.png")
                                                : item.icon.split("/")[
                                                    item.icon.split("/")
                                                      .length - 1
                                                  ] === "shopping-71.png"
                                                ? require("../../../custom/seller.png")
                                                : item.icon
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-9 mt-1">
                                        <h5>{item.name.split(",")[0]}</h5>
                                        {/*<h5>Halwasiya Vidya Vihar School</h5>*/}
                                        <span>
                                          {/\d/.test(
                                            item.formatted_address
                                              .split(",")[0]
                                              .split(" ")[0]
                                          ) === false
                                            ? item.formatted_address
                                            : item.formatted_address
                                                .slice(
                                                  item.formatted_address.indexOf(
                                                    ","
                                                  ) + 1
                                                )
                                                .trim()}
                                        </span>
                                        {/*<span>Rohtak gate, Bhiwani(127021)</span>*/}
                                        <div className="">
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <StarRatings
                                              rating={item.rating}
                                              // rating={4}
                                              starDimension="20px"
                                              starSpacing="3px"
                                              starRatedColor="yellow"
                                              name="rating"
                                            />
                                            <button
                                              onClick={(e) =>
                                                this.getEstablishmentDetails(
                                                  item
                                                )
                                              }
                                              type="button"
                                              className="btn btn-warning"
                                              style={{
                                                marginTop: "35px",
                                                float: "right",
                                                marginRight: "10px",
                                              }}
                                            >
                                              Schedule
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}

                          {this.state.customer ? (
                            <div className="card mt-4 p-3">
                              <div className="row">
                                <div className="col-12 mt-2">
                                  <h4>{this.state.customer.name}</h4>
                                  <span>
                                    {/\d/.test(
                                      this.state.customer.formatted_address
                                        .split(",")[0]
                                        .split(" ")[0]
                                    ) === false
                                      ? this.state.customer.formatted_address
                                      : this.state.customer.formatted_address
                                          .slice(
                                            this.state.customer.formatted_address.indexOf(
                                              ","
                                            ) + 1
                                          )
                                          .trim()}
                                  </span>
                                  {/*<div className="row">*/}
                                  <div className="mt-3 mb-5">
                                    <div
                                      className="m-0 btn w-25 srkw bg-gradient-dark dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      id="navbarDropdownMenuLink2"
                                      aria-expanded="false"
                                    >
                                      {this.state.customer_type
                                        ? this.state.customer_type
                                        : "Select Customer Type"}
                                    </div>
                                    <ul
                                      className="dropdown-menu w-25 srkw mt-1"
                                      aria-labelledby="navbarDropdownMenuLink2"
                                    >
                                      <li>
                                        <div
                                          className="dropdown-item"
                                          onClick={(e) =>
                                            this.setState({
                                              customer_type: "SCHOOL",
                                            })
                                          }
                                        >
                                          SCHOOL
                                        </div>
                                      </li>
                                      <li>
                                        <div
                                          className="dropdown-item"
                                          onClick={(e) =>
                                            this.setState({
                                              customer_type: "SELLER",
                                            })
                                          }
                                        >
                                          SELLER
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  {/*</div>*/}
                                </div>
                                {/*<div className=''>*/}
                                <div
                                  className="col-12 mt-2"
                                  style={{
                                    paddingLeft: "25px",
                                    paddingRight: "25px",
                                  }}
                                >
                                  {this.state.all_employee ? (
                                    <Autocomplete
                                      disablePortal
                                      id="combo-box-demo"
                                      options={this.state.all_employee}
                                      onChange={(e, value) =>
                                        this.setState({ employee: value.id })
                                      }
                                      getOptionLabel={(option) =>
                                        option.last_name
                                          ? option.first_name +
                                            " " +
                                            option.last_name
                                          : option.first_name
                                      }
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{
                                            "& > img": {
                                              mr: 2,
                                              flexShrink: 0,
                                            },
                                          }}
                                          {...props}
                                        >
                                          <img
                                            style={{ borderRadius: "50%" }}
                                            // loading="lazy"
                                            width="30"
                                            src={
                                              option.pic
                                                ? url + option.pic
                                                : url + "/static/agent.png"
                                            }
                                            // srcSet={`${url + option.pic} 2x`}
                                            alt=""
                                          />
                                          {option.last_name
                                            ? option.first_name +
                                              " " +
                                              option.last_name
                                            : option.first_name}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select Employee"
                                        />
                                      )}
                                    />
                                  ) : null}
                                </div>
                                <div
                                  className="col-6 mt-4 schedule-visit"
                                  style={{ paddingLeft: "25px" }}
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <MobileDatePicker
                                      label="Start Date"
                                      value={this.state.start_date}
                                      onChange={(e) =>
                                        this.dateFormat("start_date", e.$d)
                                      }
                                      // onChange={(e) => console.log(e)}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <div
                                  className="col-6 mt-4 schedule-visit"
                                  style={{ paddingRight: "25px" }}
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <MobileTimePicker
                                      label="Start Time"
                                      value={this.state.show_start_time}
                                      onChange={(e) =>
                                        this.timeFormat("start_time", e)
                                      }
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </div>
                                {/*</div>*/}
                              </div>

                              <div className="row mt-4">
                                <div className="col-12 mt-4">
                                  <button
                                    className="btn bg-gradient-dark d-block m-auto"
                                    onClick={(e) =>
                                      this.state.customer_type
                                        ? this.submit()
                                        : this.setState({
                                            open: true,
                                            msg: "Please select customer type.",
                                          })
                                    }
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>

                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={this.state.open}
                                onClose={(e) => this.setState({ open: false })}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 500,
                                }}
                              >
                                <Fade in={this.state.open}>
                                  <Box sx={modalStyle}>
                                    {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                    {/*  Text in a modal*/}
                                    {/*</Typography>*/}
                                    <Typography
                                      id="transition-modal-description"
                                      sx={{ mt: 2 }}
                                    >
                                      <div className="modal-body">
                                        {/*    <div className="circle__box">*/}
                                        {/*        <div className="circle__wrapper circle__wrapper--right">*/}
                                        {/*            <div className="circle__whole circle__right"></div>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="circle__wrapper circle__wrapper--left">*/}
                                        {/*            <div className="circle__whole circle__left"></div>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="circle__checkmark">*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        <div style={{ textAlign: "center" }}>
                                          <h5>{this.state.msg}</h5>
                                          {/*<h6><Link to='/visit' style={{color: "grey"}}>Click here </Link> for go to visit dashboard.</h6>*/}
                                        </div>
                                      </div>
                                    </Typography>
                                  </Box>
                                </Fade>
                              </Modal>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Add Schedule Visit Form  */}
                      <div
                        className=" p-1 row justify-content-center"
                        style={{ marginBottom: "110px" }}
                      >
                        {/* Manual Form  */}

                        {/* BackButton  */}

                        <form className="card col-md-6 mt-4 p-4" autocomplete="off">
                          <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={12}>
                              <h4 className="text-center mb-3">
                                Add Schedule Visit Manually
                              </h4>
                              <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "auto" }}
                                options={this.state.all_customers}
                                autoHighlight
                                disabled={!this.state.allCustomerLoaded}
                                getOptionLabel={(option) =>
                                  option.company
                                }
                                renderOption={(props, option, { selected }) => (
                                  <div {...props}>({option.company})</div>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select a Customers"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password",
                                    }}
                                  />
                                )}
                                onChange={(event, value) => {
                                  console.log(value.id);
                                  this.setState({
                                    manual_employee: value.id,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "auto" }}
                                options={this.state.all_employee}
                                autoHighlight
                                getOptionLabel={(option) =>
                                  option.first_name
                                }
                                renderOption={(props, option, index) => (
                                  <Box
                                    key={option.id}
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    ({option.first_name})
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select an Employee"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                onChange={(event, value) => {
                                  console.log(value.id);
                                  this.setState({
                                    manual_customer: value.id,
                                  });
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} >
                              <TextField
                                label="Date and Time"
                                type="datetime-local"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(event, value) => {
                                  // console.log(event.target.value, "++++++++++++++++",value);
                                  this.setState({
                                    manual_date_time: event.target.value,
                                  });
                                }}
                                required
                                sx={{width:"100%"}}
                              />
                            </Grid>

                            <Grid item>
                              <Button
                                onClick={this.manualScheduleVisitHandler}
                                variant="contained"
                                direction="center"
                                color="info"
                                type="submit"
                              >
                                Add Schedule Visit
                              </Button>
                            </Grid>
                          </Grid>
                        </form>

                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          open={this.state.open}
                          onClose={(e) => this.setState({ open: false })}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                            timeout: 500,
                          }}
                        >
                          <Fade in={this.state.open}>
                            <Box sx={modalStyle}>
                              <Typography
                                id="transition-modal-description"
                                sx={{ mt: 2 }}
                              >
                                <div className="modal-body">
                                  {this.state.error ? (
                                    <div className="text-center">
                                      <h5>{this.state.msg}</h5>
                                      <button
                                        onClick={(e) =>
                                          this.setState({ open: false })
                                        }
                                        className="btn btn-primary"
                                      >
                                        Ok
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="circle__box">
                                        <div className="circle__wrapper circle__wrapper--right">
                                          <div className="circle__whole circle__right"></div>
                                        </div>
                                        <div className="circle__wrapper circle__wrapper--left">
                                          <div className="circle__whole circle__left"></div>
                                        </div>
                                        <div className="circle__checkmark"></div>
                                      </div>
                                      <div style={{ textAlign: "center" }}>
                                        <h5>
                                          Scheduled visit created successfully.
                                        </h5>
                                        <h6>
                                          <Link
                                            to="/visit"
                                            style={{ color: "grey" }}
                                          >
                                            Click here{" "}
                                          </Link>{" "}
                                          for go to Visit DashBoard.
                                        </h6>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Typography>
                            </Box>
                          </Fade>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile Sections for search using map or location  */}

            <div className="container-fluid d-none my-3">
              <div className="row">
                <div className="col-12">
                  <Stack direction="row" justifyContent="center" spacing={3}>
                    <Button variant="contained" color="success"></Button>
                    <Button variant="contained" href="#contained-buttons">
                      Link
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>

            <div className="d-lg-none p-1" style={{ marginBottom: "110px" }}>
              <div className="mt-3 d-none">
                <div className="main-search">
                  <div className="searchInputWrapper">
                    <input
                      className="searchInput"
                      type="text"
                      id="search-text2"
                      onChange={(e) =>
                        this.setState({ search_text: e.target.value })
                      }
                      // onChange={(e)=>this.test(e.target.value)}
                      placeholder="focus here to search"
                    />
                    <i
                      className="searchInputIcon fa fa-search"
                      onClick={this.search}
                    ></i>
                  </div>
                </div>

                {this.state.customer === null && this.state.result ? (
                  <div className="mt-3 p-1">
                    {this.state.result.map((item) => (
                      <div className={"col-12"}>
                        <div
                          className="card mt-3"
                          style={{
                            boxShadow:
                              "0 4px 6px -1px rgb(0 0 0 / 10%), -2px -2px 4px -1px rgb(0 0 0 / 6%)",
                          }}
                        >
                          <div className="row" style={{ padding: "5px" }}>
                            <div className="col-3 mt-1">
                              <img
                                style={{ width: "100%" }}
                                src={
                                  item.icon.split("/")[
                                    item.icon.split("/").length - 1
                                  ] === "school-71.png"
                                    ? require("../../../custom/school.png")
                                    : item.icon.split("/")[
                                        item.icon.split("/").length - 1
                                      ] === "shopping-71.png"
                                    ? require("../../../custom/seller.png")
                                    : item.icon
                                }
                                alt=""
                              />
                            </div>
                            <div className="col-9 mt-1">
                              <h6 style={{ whiteSpace: "break-spaces" }}>
                                {item.name.split(",")[0]}
                              </h6>
                              {/*<span style={{whiteSpace: 'break-spaces', marginTop: '-8px'}}>{this.containsNumbers(item.address.split(',')[0].split(' ')[0])===false?item.address:item.address.slice(item.address.indexOf(',')+1).trim()}</span>*/}
                              <span
                                style={{
                                  whiteSpace: "break-spaces",
                                  marginTop: "-8px",
                                }}
                              >
                                {/\d/.test(
                                  item.formatted_address
                                    .split(",")[0]
                                    .split(" ")[0]
                                ) === false
                                  ? item.formatted_address
                                  : item.formatted_address
                                      .slice(
                                        item.formatted_address.indexOf(",") + 1
                                      )
                                      .trim()}
                              </span>
                              <div className="">
                                <div
                                  style={{
                                    // marginTop: "-12px",
                                    // marginLeft: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    // margin: "0 0"
                                  }}
                                >
                                  <StarRatings
                                    rating={item.rating}
                                    starDimension="15px"
                                    starSpacing="2px"
                                    starRatedColor="yellow"
                                    name="rating"
                                  />

                                  <button
                                    onClick={(e) =>
                                      this.getEstablishmentDetails(item)
                                    }
                                    type="button"
                                    className="btn btn-warning"
                                    style={{ top: "4px", float: "right" }}
                                  >
                                    Schedule
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}

                {this.state.customer ? (
                  <div className="card mt-4 p-1 text-center">
                    <div className="row">
                      <div className="col-12 mt-2">
                        <h4>{this.state.customer.name}</h4>
                        <span>
                          {/\d/.test(
                            this.state.customer.formatted_address
                              .split(",")[0]
                              .split(" ")[0]
                          ) === false
                            ? this.state.customer.formatted_address
                            : this.state.customer.formatted_address
                                .slice(
                                  this.state.customer.formatted_address.indexOf(
                                    ","
                                  ) + 1
                                )
                                .trim()}
                        </span>
                        {/*<div className="row">*/}
                        <div className="mt-3 mb-5">
                          <div
                            className="m-0 btn srkw bg-gradient-dark dropdown-toggle"
                            data-bs-toggle="dropdown"
                            id="navbarDropdownMenuLink2"
                            aria-expanded="false"
                          >
                            {this.state.customer_type
                              ? this.state.customer_type
                              : "Select Customer Type"}
                          </div>
                          <ul
                            className="dropdown-menu srkw mt-1"
                            aria-labelledby="navbarDropdownMenuLink2"
                          >
                            <li>
                              <div
                                className="dropdown-item"
                                onClick={(e) =>
                                  this.setState({ customer_type: "SCHOOL" })
                                }
                              >
                                SCHOOL
                              </div>
                            </li>
                            <li>
                              <div
                                className="dropdown-item"
                                onClick={(e) =>
                                  this.setState({ customer_type: "SELLER" })
                                }
                              >
                                SELLER
                              </div>
                            </li>
                          </ul>
                        </div>
                        {/*</div>*/}
                      </div>
                      {/*<div className=''>*/}
                      <div
                        className="col-12 mt-2"
                        style={{ paddingLeft: "25px", paddingRight: "25px" }}
                      >
                        {this.state.all_employee ? (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={this.state.all_employee}
                            onChange={(e, value) =>
                              this.setState({ employee: value.id })
                            }
                            getOptionLabel={(option) =>
                              option.last_name
                                ? option.first_name + " " + option.last_name
                                : option.first_name
                            }
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{
                                  "& > img": {
                                    mr: 2,
                                    flexShrink: 0,
                                  },
                                }}
                                {...props}
                              >
                                <img
                                  style={{ borderRadius: "50%" }}
                                  // loading="lazy"
                                  width="30"
                                  src={
                                    option.pic
                                      ? url + option.pic
                                      : url + "/static/agent.png"
                                  }
                                  // srcSet={`${url + option.pic} 2x`}
                                  alt=""
                                />
                                {option.last_name
                                  ? option.first_name + " " + option.last_name
                                  : option.first_name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="Select Employee" />
                            )}
                          />
                        ) : null}
                      </div>
                      <div
                        className="col-6 mt-4 schedule-visit"
                        style={{ paddingLeft: "25px" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            label="Start Date"
                            value={this.state.start_date}
                            onChange={(e) =>
                              this.dateFormat("start_date", e.$d)
                            }
                            // onChange={(e) => console.log(e)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                      <div
                        className="col-6 mt-4 schedule-visit"
                        style={{ paddingRight: "25px" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            label="Start Time"
                            value={this.state.show_start_time}
                            onChange={(e) => this.timeFormat("start_time", e)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                      {/*</div>*/}
                    </div>

                    <div className="row mt-4">
                      <div className="col-12 mb-4">
                        <button
                          className="btn bg-gradient-dark d-block m-auto"
                          onClick={(e) =>
                            this.state.customer_type
                              ? this.submit()
                              : this.setState({
                                  open: true,
                                  msg: "Please select customer type.",
                                })
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={this.state.open}
                      onClose={(e) => this.setState({ open: false })}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={this.state.open}>
                        <Box sx={modalStyle}>
                          {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                          {/*  Text in a modal*/}
                          {/*</Typography>*/}
                          <Typography
                            id="transition-modal-description"
                            sx={{ mt: 2 }}
                          >
                            <div className="modal-body">
                              {this.state.submit === true ? (
                                <>
                                  <div className="circle__box">
                                    <div className="circle__wrapper circle__wrapper--right">
                                      <div className="circle__whole circle__right"></div>
                                    </div>
                                    <div className="circle__wrapper circle__wrapper--left">
                                      <div className="circle__whole circle__left"></div>
                                    </div>
                                    <div className="circle__checkmark"></div>
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <h5>{this.state.msg}</h5>
                                    <h6>
                                      <Link
                                        to="/visit"
                                        style={{ color: "grey" }}
                                      >
                                        Click here{" "}
                                      </Link>{" "}
                                      for go to visit dashboard.
                                    </h6>
                                  </div>
                                </>
                              ) : (
                                <div style={{ textAlign: "center" }}>
                                  <h5>{this.state.msg}</h5>
                                </div>
                              )}
                            </div>
                          </Typography>
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                ) : null}
              </div>

              {/* Manual Form  */}

              {/* BackButton  */}

              <Link to="/visit">
                <ArrowBackIcon className="mt-4" style={{ fontSize: "30px" }} />
              </Link>

              <form className="card mt-4 p-4" autocomplete="off">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <h4 className="text-center mb-3">
                      Add Schedule Visit Manually
                    </h4>
                    <Autocomplete
                      id="country-select-demo"
                      sx={{ width: "auto" }}
                      options={this.state.all_customers}
                      autoHighlight
                      disabled={!this.state.allCustomerLoaded}
                      getOptionLabel={(option) => option.company}
                      renderOption={(props, option, { selected }) => (
                        <div {...props}>({option.company})</div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a Customers"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          
                        />
                      )}
                      onChange={(event, value) => {
                        console.log(value.id);
                        this.setState({
                          manual_employee: value.id,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      id="country-select-demo"
                      sx={{ width: "auto" }}
                      options={this.state.all_employee}
                      autoHighlight
                      getOptionLabel={(option) => option.first_name}
                      renderOption={(props, option, index) => (
                        <Box
                          key={option.id}
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          ({option.first_name})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select an Employee"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                      onChange={(event, value) => {
                        console.log(value.id);
                        this.setState({
                          manual_customer: value.id,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Date and Time"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event, value) => {
                        // console.log(event.target.value, "++++++++++++++++",value);
                        this.setState({
                          manual_date_time: event.target.value,
                        });
                      }}
                      required
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={this.manualScheduleVisitHandler}
                      variant="contained"
                      direction="center"
                      color="info"
                      type="submit"
                    >
                      Add Schedule Visit
                    </Button>
                  </Grid>
                </Grid>
              </form>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.open}
                onClose={(e) => this.setState({ open: false })}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={this.state.open}>
                  <Box sx={modalStyle}>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      <div className="modal-body">
                        {this.state.error ? (
                          <div className="text-center">
                            <h5>{this.state.msg}</h5>
                            <button
                              onClick={(e) => this.setState({ open: false })}
                              className="btn btn-primary"
                            >
                              Ok
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="circle__box">
                              <div className="circle__wrapper circle__wrapper--right">
                                <div className="circle__whole circle__right"></div>
                              </div>
                              <div className="circle__wrapper circle__wrapper--left">
                                <div className="circle__whole circle__left"></div>
                              </div>
                              <div className="circle__checkmark"></div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                              <h5>Scheduled visit created successfully.</h5>
                              <h6>
                                <Link to="/visit" style={{ color: "grey" }}>
                                  Click here{" "}
                                </Link>{" "}
                                for go to Visit DashBoard.
                              </h6>
                            </div>
                          </>
                        )}
                      </div>
                    </Typography>
                  </Box>
                </Fade>
              </Modal>
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleVisit);
