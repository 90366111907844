import React from "react";
import '../Customer/Style';
import { connect } from "react-redux";
import {Redirect} from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {axios, sideBar, userLogin} from "../../../store/actions/actions";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


class SampleRequest extends React.Component {

  state={
        sample_array: [
            {
                id: "sample_" +new Date().valueOf(),
                book: null,
                from: null,
                to: null,
                class_from: null,
                class_to: null,
                selected_from: null,
                selected_to: null,
                quantity: null,
                price: null,

                n_from: null,
                n_to: null,
            }
        ],

        series: null
    }

    componentDidMount() {
        this.loadBooks()
        // this.toeknExpiryCheck()

    }

    loadBooks = () => {
        let endpoint = `books/get-books`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Books Response data----------->': responseData})
                this.setState({
                    series: responseData.books
                })
            })
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

  addSample = () => {
    let obj_array = this.state.sample_array;
    let obj = {
        id: "sample_" +new Date().valueOf(),

        selected_from: null,
        selected_to: null,
        book: null,
        from: null,
        to: null,
        class_from: null,
        class_to: null,
        quantity: null,
        price: null,

        n_from: null,
        n_to: null,

    };
    obj_array.push(obj)
    this.setState({
        sample_array: obj_array
    });
  };

  removeSample = (id) => {
    let arr = this.state.sample_array
    let filteredArray = arr.filter((item) => item.id !== id);
    this.setState({
        sample_array: filteredArray
    })
  };

  saveValueToObject = (id, key, value) => {
    let obj_array = this.state.sample_array
    let filter_obj = obj_array.filter((i)=>(i.id===id))
    if (key==='book') {
        let obj = this.state.series.filter((i)=>(i.title.toLowerCase().includes(value.toLowerCase())))
        obj_array.map((i, index) => (i.id === id ? i['selected_from'] = obj[0].book : null))
        obj_array.map((i, index) => (i.id === id ? i['selected_to'] = obj[0].book : null))
        obj_array.map((i, index) => (i.id === id ? i[key] = obj[0].id : null))
    } else {
        if (key === 'from'){
            obj_array.map((i, index) => (i.id === id ? i['class_from'] = value : null))
            obj_array.map((i, index) => (i.id === id ? i['n_from'] = value.n : null))
            obj_array.map((i, index) => (i.id === id ? i[key] = value.id : null))
            obj_array.map((i, index) => (i.id === id ? i['price'] = this.calculateAmount(filter_obj): null))
        }

        if (key === 'to'){
            obj_array.map((i, index) => (i.id === id ? i['class_to'] = value : null))
            obj_array.map((i, index) => (i.id === id ? i['n_to'] = value.n : null))
            obj_array.map((i, index) => (i.id === id ? i[key] = value.id : null))
            obj_array.map((i, index) => (i.id === id ? i['price'] = this.calculateAmount(filter_obj): null))
        }

        if (key === 'quantity') {
            obj_array.map((i, index) => (i.id === id ? i[key] = value: null))
            obj_array.map((i, index) => (i.id === id ? i['price'] = this.calculateAmount(filter_obj): null))
        }
    }

    this.setState({
        sample_obj: obj_array
    })

  };

  calculateAmount = (obj) => {
      let amount_array = []
      if (obj[0].n_to===null){
          obj[0].selected_from.map((item)=> {
              if (item.n===obj[0].n_from) {
                  amount_array.push(Number(item.price))
              }
          })
          return obj[0].quantity===null || obj[0].quantity===0?amount_array[0]:obj[0].quantity*amount_array[0]

      } else {
          for (let step = obj[0].n_from; step < obj[0].n_to + 1; step++) {
              obj[0].selected_from.map((item) => {
                  if (item.n === step) {
                      amount_array.push(Number(item.price))
                  }
              })
          }
          const sum = amount_array.reduce((partialSum, a) => partialSum + a, 0);
          return obj[0].quantity === null || obj[0].quantity === 0 ? sum : obj[0].quantity * sum
      }
  }

  submit = () => {
    const data = {
        obj: this.state.sample_array
    }
    let endpoint = `books/sample-request`
    let token = this.props.user.token

    axios
        .post(endpoint, data, token)
        .then((res) => {
            let responseData = res.data;
            console.log({ 'Submit Response data----------->': responseData})
            // this.setState({
            //     series: responseData.books
            // })
        })
  }

  render() {
      console.log('sample array------------------>', this.state.sample_array)
    return (
      <>
        {this.props.user.login === true ?
          <>
              <div className="container-fluid">
                <div className="row justify-content-end">
                    <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "d-none d-lg-block col-10" : "d-none d-lg-block col-12"}>

         <div>
            <div className="content-page">
               <div className="container-fluid mt-4">
                  <div className="row">
                     <div className=" col-sm-12 col-lg-11 col-md-12" style={{ marginLeft: "auto", marginRight: "auto" }}>
                        <div className="card">

                           <div className="sample-thead mt-2" style={{ padding: "50px" }}>
                              <div className="">
                                 <div
                                    className="add-ins float-right d-flex mb-3"
                                    style={{ cursor: "pointer" }}
                                    onClick={this.addSample}
                                 >
                                    <img src={require("../../../custom/icons/plus.png")} height="30" />
                                    <p className="m-1">ADD SAMPLE</p>
                                 </div>
                              </div>
                              <table className="table mt-2">
                                 <thead>
                                    <tr>
                                       <th >S. No.</th>
                                       <th>Book</th>
                                       <th>From</th>
                                       <th>To</th>
                                       <th>Qty.</th>
                                       <th>Price</th>
                                       <th>Action</th>
                                    </tr>
                                 </thead>
                                 {this.state.sample_array
                                    ? this.state.sample_array.map((sample, index) => (
                                         <tbody>
                                            <tr>
                                               <td width="7%" className="text-center">
                                                  <div style={{marginTop: '16px'}}><span className="">{index + 1}</span>.</div>
                                               </td>
                                               <td width="43%">
                                                  {this.state.series?
                                                        <Autocomplete
                                                            className='pl-1 pr-1'
                                                            id="free-solo-demo"
                                                            size={'small'}
                                                            style={{marginTop: '16px'}}
                                                            options={this.state.series.map((option) => option.title)}
                                                            onChange={(event, value) =>
                                                                this.saveValueToObject(sample.id, "book", value)
                                                            }
                                                            renderInput={(params) => <TextField {...params} label="Select Book"/>}
                                                        />
                                                  :null}
                                               </td>

                                               <td width="10%">
                                                   <div className="">
                                                       {sample.selected_from?
                                                              <Autocomplete
                                                                  className='pl-1 pr-1'
                                                                  id="free-solo-demo"
                                                                  size={'small'}
                                                                  style={{marginTop: '16px'}}
                                                                  options={sample.selected_from.map((option) => option.classes__class_name)}
                                                                  onChange={(event, value) =>
                                                                      this.saveValueToObject(sample.id, "from", sample.selected_from.filter((i)=>(i.classes__class_name===value))[0])
                                                                  }
                                                                  value={
                                                                    sample.class_from
                                                                      ? sample.class_from.classes__class_name
                                                                      : ""
                                                                  }
                                                                  renderInput={(params) => <TextField {...params} label="From" variant="standard"/>}
                                                              />
                                                           :
                                                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                    <InputLabel id="demo-simple-select-standard-label">From</InputLabel>
                                                    <Select
                                                      labelId="demo-simple-select-standard-label"
                                                      id="demo-simple-select-standard"
                                                      value={sample.class_from?sample.class_from:''}
                                                      onChange={(e) =>
                                                         this.saveValueToObject(sample.id, "from", e.target.value)
                                                      }
                                                      label="From"
                                                    >
                                                      <MenuItem value={null}>
                                                        <em>None</em>
                                                      </MenuItem>
                                                      {sample.selected_from?sample.selected_from.map((item)=>
                                                          <MenuItem value={item}>{item.classes__class_name}</MenuItem>
                                                      ):null}
                                                    </Select>
                                                  </FormControl>}
                                                </div>
                                               </td>

                                               <td width="10%">
                                                     <div className="">
                                                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                        <InputLabel id="demo-simple-select-standard-label">To</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-standard-label"
                                                          id="demo-simple-select-standard"
                                                          value={sample.class_to?sample.class_to:''}
                                                          onChange={(e) =>
                                                             this.saveValueToObject(sample.id, "to", e.target.value)
                                                          }
                                                          label="To"
                                                        >
                                                          <MenuItem value={null}>
                                                            <em>None</em>
                                                          </MenuItem>
                                                          {sample.selected_from?sample.selected_to.map((item)=>
                                                              <MenuItem value={item}>{item.classes__class_name}</MenuItem>
                                                          ):null}
                                                        </Select>
                                                      </FormControl>
                                                     </div>
                                               </td>

                                               <td width="10%">
                                                  <div id="div_id_qty" className="form-group">
                                                     <div className="">
                                                        <input
                                                           type="number"
                                                           id={sample.id}
                                                           value={sample.quantity?parseInt(sample.quantity):''}
                                                            onChange={(e) =>
                                                               this.saveValueToObject(sample.id, "quantity", e.target.value===''?0:e.target.value)
                                                            }
                                                           className="form-control w-100 p-2"
                                                           style={{background: "#ededed", marginTop: '16px'}}
                                                        />
                                                     </div>
                                                  </div>
                                               </td>

                                               <td width="10%">
                                                  <div id="div_id_qty" className="form-group">
                                                     <div className="">
                                                        <input
                                                           type="number"
                                                           id={sample.id}
                                                           value={sample.price?parseInt(sample.price):''}
                                                            onChange={(e) =>
                                                               this.saveValueToObject(sample.id, "price", e.target.value===''?0:e.target.value)
                                                            }
                                                           className="form-control w-100 p-2"
                                                           style={{background: "#ededed", marginTop: '16px'}}
                                                        />
                                                     </div>
                                                  </div>
                                               </td>

                                               <td width="10%" className="text-center">
                                                  <div
                                                     className="btn btn-xs"
                                                     style={{color: "red", fontSize: "15px", marginTop: '16px'}}
                                                     onClick={(e) => this.removeSample(sample.id)}
                                                  >
                                                      X
                                                  </div>
                                               </td>
                                            </tr>
                                         </tbody>
                                      ))
                                    : null}
                              </table>
                           </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <button
                                        className="btn bg-gradient-dark d-block m-auto"
                                        onClick={this.submit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
                    </div>
                    <div className={"d-lg-none col-12"}>
                        <div>
        <div className="content-page">
           <div className=" mt-4">
              <div className="row">
                 <div className=" col-sm-12 col-lg-11 col-md-12" style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div className="card">

                       <div className="sample-thead mt-2" style={{ padding: "20px 30px" }}>
                          <div className="">
                             <div
                                className="add-ins float-right d-flex mb-3"
                                style={{ cursor: "pointer" }}
                                onClick={this.addSample}
                             >
                                <img src={require("../../../custom/icons/plus.png")} height="30" />
                                <p className="m-1">ADD SAMPLE</p>
                             </div>
                          </div>
                          <div className="table-responsive">
                              <table className="table mt-2">
                             <thead>
                                <tr>
                                   <th >S. No.</th>
                                   <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                   <th>From</th>
                                   <th>To</th>
                                   <th>&nbsp;&nbsp;Qty.&nbsp;&nbsp;</th>
                                   <th>&nbsp;&nbsp;Price&nbsp;&nbsp;</th>
                                   <th>Action</th>
                                </tr>
                             </thead>
                             {this.state.sample_array
                                ? this.state.sample_array.map((sample, index) => (
                                     <tbody>
                                        <tr>
                                           <td width="7%" className="text-center">
                                              <div style={{marginTop: '16px'}}><span className="">{index + 1}</span>.</div>
                                           </td>
                                           <td width="43%">
                                              {this.state.series?
                                                    <Autocomplete
                                                        className='pl-1 pr-1'
                                                        id="free-solo-demo"
                                                        size={'small'}
                                                        style={{marginTop: '16px', width: "100%"}}
                                                        options={this.state.series.map((option) => option.title)}
                                                        onChange={(event, value) =>
                                                            this.saveValueToObject(sample.id, "book", value)
                                                        }
                                                        renderInput={(params) => <TextField {...params} label="Select Book" />}
                                                  />
                                              :null}
                                           </td>

                                           <td width="10%">
                                                 {/*<div className="">*/}
                                                 {/*   <input*/}
                                                 {/*      type="number"*/}
                                                 {/*      id={sample.id}*/}
                                                 {/*      value={sample.from?sample.from:''}*/}
                                                 {/*       onChange={(e) =>*/}
                                                 {/*          this.saveValueToObject(sample.id, "from", e.target.value)*/}
                                                 {/*       }*/}
                                                 {/*      className="form-control w-100 p-2"*/}
                                                 {/*      style={{background: "#ededed"}}*/}
                                                 {/*   />*/}
                                                 {/*</div>*/}
                                               <div className="">
                                              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-standard-label">From</InputLabel>
                                                <Select
                                                  labelId="demo-simple-select-standard-label"
                                                  id="demo-simple-select-standard"
                                                  value={sample.class_from?sample.class_from:''}
                                                  onChange={(e) =>
                                                     this.saveValueToObject(sample.id, "from", e.target.value)
                                                  }
                                                  label="From"
                                                >
                                                  <MenuItem value={null}>
                                                    <em>None</em>
                                                  </MenuItem>
                                                  {sample.selected_from?sample.selected_from.map((item)=>
                                                      <MenuItem value={item}>{item.classes__class_name}</MenuItem>
                                                  ):null}
                                                </Select>
                                              </FormControl>
                                            </div>
                                           </td>

                                           <td width="10%">
                                                 <div className="">
                                                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                    <InputLabel id="demo-simple-select-standard-label">To</InputLabel>
                                                    <Select
                                                      labelId="demo-simple-select-standard-label"
                                                      id="demo-simple-select-standard"
                                                      value={sample.class_to?sample.class_to:''}
                                                      onChange={(e) =>
                                                         this.saveValueToObject(sample.id, "to", e.target.value)
                                                      }
                                                      label="To"
                                                    >
                                                      <MenuItem value={null}>
                                                        <em>None</em>
                                                      </MenuItem>
                                                      {sample.selected_from?sample.selected_to.map((item)=>
                                                          <MenuItem value={item}>{item.classes__class_name}</MenuItem>
                                                      ):null}
                                                    </Select>
                                                  </FormControl>
                                                 </div>
                                           </td>

                                           <td width="10%">
                                              <div id="div_id_qty" className="form-group">
                                                 <div className="">
                                                    <input
                                                       type="number"
                                                       id={sample.id}
                                                       value={sample.quantity?parseInt(sample.quantity):''}
                                                        onChange={(e) =>
                                                           this.saveValueToObject(sample.id, "quantity", e.target.value===''?0:e.target.value)
                                                        }
                                                       className="form-control w-100 p-2"
                                                       style={{background: "#ededed", marginTop: '16px'}}
                                                    />
                                                 </div>
                                              </div>
                                           </td>

                                           <td width="10%">
                                              <div id="div_id_qty" className="form-group">
                                                 <div className="">
                                                    <input
                                                       type="number"
                                                       id={sample.id}
                                                       value={sample.price?parseInt(sample.price):''}
                                                        onChange={(e) =>
                                                           this.saveValueToObject(sample.id, "price", e.target.value===''?0:e.target.value)
                                                        }
                                                       className="form-control w-100 p-2"
                                                       style={{background: "#ededed", marginTop: '16px'}}
                                                    />
                                                 </div>
                                              </div>
                                           </td>

                                           <td width="10%" className="text-center">
                                              <div
                                                 className="btn btn-xs"
                                                 style={{color: "red", fontSize: "15px", marginTop: '16px'}}
                                                 onClick={(e) => this.removeSample(sample.id)}
                                              >
                                                  X
                                              </div>
                                           </td>
                                        </tr>
                                     </tbody>
                                  ))
                                : null}
                          </table>
                          </div>
                       </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <button
                                    className="btn bg-gradient-dark d-block m-auto"
                                    onClick={this.submit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
                    </div>
                </div>
              </div>
          </>
          :
          <Redirect to={'/sign-in'} />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
    user:state.userData.userData
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SampleRequest);


