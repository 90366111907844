import React from "react";
import {connect} from "react-redux";

class TripGraph extends React.Component {

    state = {
        view: 'bio',
    }

    render() {
        return(
            <>
                <div className="row mt-3">
                    <div className="col-lg-7 col-md-12">
                        <div className="card">
                            <div className="card-header pb-0 p-3">
                                <h6 className="mb-0">Type of Visit</h6>
                                <div className="d-flex align-items-center">
                                        <span className="badge badge-md badge-dot me-4">
                                            <i className="bg-primary"></i>
                                            <span className="text-dark text-xs">Organic search</span>
                                        </span>
                                    <span className="badge badge-md badge-dot me-4">
                                            <i className="bg-dark"></i>
                                            <span className="text-dark text-xs">Referral</span>
                                        </span>
                                    <span className="badge badge-md badge-dot me-4">
                                            <i className="bg-info"></i>
                                            <span className="text-dark text-xs">Social media</span>
                                        </span>
                                </div>
                            </div>
                            <div className="card-body p-3">
                                <div className="chart">
                                    <canvas id="chart-linew" className="chart-canvas" height="300"
                                            style={{display: "block", boxSizing: "border-box", height: "300px", width: "100%"}}
                                    width="831"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" col-lg-5 col-md-12 mt-4 mt-lg-0">
                        <div className="card h-100">
                            <div className="card-header pb-0 p-3">
                                <div className="d-flex align-items-center">
                                    <h6 className="mb-0">Referrals</h6>
                                    <button type="button"
                                            className="btn btn-icon-only btn-rounded btn-outline-secondary mb-0 ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
                                            data-bs-toggle="tooltip" data-bs-placement="bottom" title=""
                                            data-bs-original-title="See which websites are sending traffic to your website">
                                        <i className="material-icons text-sm">priority_high</i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body p-3">
                                <div className="row">
                                    <div className="col-lg-5 col-12 text-center">
                                        <div className="chart mt-5">
                                            <canvas id="chart-doughnut" className="chart-canvas" height="200"
                                                    width="831"
                                                    style={{display: "block", boxSizing: "border-box", height: "200px", width: "100%"}}/>
                                        </div>
                                        <a className="btn btn-sm bg-gradient-secondary mt-4">See all referrals</a>
                                    </div>
                                    <div className="col-lg-7 col-12">
                                        <div className="table-responsive">
                                            <table className="table align-items-center mb-0">
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div>
                                                                <img src="../../../assets/img/small-logos/logo-xd.svg"
                                                                     className="avatar avatar-sm me-2" alt="logo_xd"/>
                                                            </div>
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">Delhi</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="text-xs font-weight-bold"> 25% </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div>
                                                                <img
                                                                    src="../../../assets/img/small-logos/logo-atlassian.svg"
                                                                    className="avatar avatar-sm me-2"
                                                                    alt="logo_atlassian"/>
                                                            </div>
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">Uttar Pradesh</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="text-xs font-weight-bold"> 13% </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div>
                                                                <img
                                                                    src="../../../../assets/img/small-logos/logo-slack.svg"
                                                                    className="avatar avatar-sm me-2" alt="logo_slack"/>
                                                            </div>
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">Haryana</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="text-xs font-weight-bold"> 12% </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div>
                                                                <img
                                                                    src="../../../assets/img/small-logos/logo-spotify.svg"
                                                                    className="avatar avatar-sm me-2"
                                                                    alt="logo_spotify"/>
                                                            </div>
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">Punjab</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="text-xs font-weight-bold"> 37% </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div>
                                                                <img src="../../../assets/img/small-logos/logo-jira.svg"
                                                                     className="avatar avatar-sm me-2" alt="logo_jira"/>
                                                            </div>
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">Bihar</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="text-xs font-weight-bold"> 13% </span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state =>({
    sidebar:state.sideBarData.sidebarData.sidebar
})

export default connect(mapStateToProps)(TripGraph)