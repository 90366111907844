import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PageNotFound from "../../common/PageNotFound";
import { Offline, Online } from "react-detect-offline";
import InternetConnection from "../../common/InternetConnection";
import Select from "react-select";
import {axios, url, userLogin} from "../../../store/actions/actions";


class EditTourFundRequest extends React.Component {

  state = {
    tar: null,

    teams: null,
    team_id: null,
    team_balance: null,
    amount: null,
    remark_text: null,
    remark_file: null
  }

  componentDidMount() {
    this.loadTourAdvanceRequest()
    this.loadEmployee()
        this.toeknExpiryCheck()
    }

toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if(today >= varDate) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined })
    }

    let data = {
        emp_id: this.props.user.employee_id
    }

    let endpoint = `employee/check-employee-update`
    let token = this.props.user.token

    axios
        .post(endpoint, data, token)
        .then((res) => {
            let responseData = res.data;
            console.log({ 'Update Response data----------': responseData})
            if (responseData.success===true){
                window.localStorage.clear();
                this.props.userLoginHandler({ undefined })
            }
        })
    }

  loadTourAdvanceRequest = () => {
    let pathname = window.location.pathname;

    let pathurl = pathname.split("/");
    let id = pathurl[pathurl.length - 1];
    let endpoint = `account/tar/${id}`

    let token = this.props.user.token

    axios
      .get(endpoint, token)
      .then((res)=>{
        let responseData = res.data;
        this.setState({
          tar: responseData.tar
        })
        console.log({'TAR response data---------':responseData})
      })
    }

  loadEmployee = () => {
    let endpoint = `teams/get-employee-team`
    let token = this.props.user.token

    axios
      .get(endpoint, token)
      .then((res)=>{
        let responseData = res.data;
        this.setState({
          teams: responseData.team
        })
        console.log({'Team response data---------':responseData.team})
      })
    }

    teamBalance = (team) => {
      this.setState({
          team_id: team.id,
          team_balance: team.balance
      })
    }

    submitRemark = (id) => {
        let data = new FormData();
        data.append("remark_id", id);
        data.append("remark_text", this.state.remark_text);
        data.append("remark_file", this.state.remark_file);

        let endpoint = `teams/team-account`
        let token = this.props.user.token

        axios
          .post(endpoint, data, token)
          .then((res)=>{
            let responseData = res.data;
            if (responseData.success!==false){
                this.submitRemark(responseData.id)
            }
            console.log({'response data---------':responseData})
          })
    }

    submit = () => {
      const data = {
          id: this.state.team_id,
          amount: this.state.amount,
      }

      let endpoint = `teams/team-account`
      let token = this.props.user.token

     axios
      .post(endpoint, data, token)
      .then((res)=>{
        let responseData = res.data;
        if (responseData.success!==false){
            this.submitRemark()
        }
        console.log({'response data---------':responseData})
      })
    }

  render() {
    return (
            <>
            {this.props.user.login===true?
                <>
                    <Online>
                    {this.props.user.employee_profile==='admin'?
                      <div className="container-fluid">
                        <div className="row justify-content-end">
                          <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-10" : "col-12"}>
                            <div className="col-md-10 d-block m-auto mt-lg-0 mt-4">
                            {/*// <!-- Card Basic Info -->*/}
                              <div className="card mt-4 mb-4" id="basic-info">
                                <div className="card-header">
                                  <h5>Tour Advance Request</h5>
                                </div>
                                  {this.state.tar?
                                <div className="card-body pt-0">
                                  <div className="row">
                                    <div className="row col-12">
                                      <div className="col-6">
                                        {this.state.teams?
                                          <Select
                                            placeholder="Select Team"
                                            options={this.state.teams.map((team, index)=>(
                                                {value: team, label: team.name}
                                            ))}
                                            onChange={(e)=>this.teamBalance(e.value)}
                                          />
                                        :null}
                                      </div>
                                      {this.state.team_balance?
                                      <div className="col-6">
                                        <label>Current Balance</label>
                                        <h6>{this.state.team_balance}</h6>
                                      </div>
                                      :null}
                                    </div>
                                    <div className="col-6 mt-3">
                                      <div className="input-group input-group-static">
                                        <label>Enter Amount</label>
                                        <input type="text" className="form-control"
                                               value={this.state.tar.amount}
                                        onChange={(e) => this.setState({amount: e.target.value})}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <div className="input-group input-group-static">
                                        <label>Remarks</label>
                                        <input type="text" className="form-control"
                                               value={this.state.tar.remark}
                                        onChange={(e) => this.setState({remark: e.target.value})}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                   <button onClick={this.submit} className="btn bg-gradient-dark btn-md mt-6" onClick={this.updatePassword}>
                                       Submit
                                   </button>
                                </div>
                                      :null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    :
                        <PageNotFound/>
                    }
                    </Online>
                    <Offline>
                        <InternetConnection/>
                    </Offline>
                </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>
    );
  }
}

const mapStateToProps = (state) => ({
  user:state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTourFundRequest);
