import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import VisitDataTable from "../VisitDash/VisitDataTable";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Loader from "../../common/Loader/Loader";
import Typography from "@mui/material/Typography";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";

import {
  axios,
  sideBar,
  userLogin,
  modalStyle,
} from "../../../store/actions/actions";
import { Link, Redirect } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zindex: 10000000,
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CreateTadas(props) {
  const [visit, setVisit] = useState([]);
  const [customer, setCustomer] = useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const companySearchHandler = (event) => {
    event.preventDefault();
    setVisit([]); // Reset visit to an empty array before making the API call

    const endpoint = 'customer/srk-testing';
    const token = props.user.token;
    const data = {
      search_text: customer,
    };

    console.log('Kaam Kar Rha Hai', endpoint);
    axios
      .post('visit/visit-by-customer', data, token)
      .then((response) => {
        console.log(response, '--------------------');
        const responseData = response.data;
        setVisit(responseData.visit); // Update the visit state with the received data
        console.log(responseData, '+++++++++++++++++++++++++++++');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <>
      {props.user.login === true ? (
        <div className="row justify-content-end px-4">
          <div className={
            props.sidebar === undefined ||
              props.sidebar === true
              ? "col-10"
              : "col-12"
          }>
            <div className="row">
              <div className="col-12">
                <h5 className="text-center fs-3 fw-bold">Add TADA</h5>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-bottom">
                    <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center">
                      <h5 className="mb-0">Visits</h5>
                      <div className="d-flex gap-3 flex-wrap">

                        <TextField
                          label="Enter Company Name" onChange={(e) => {
                            setCustomer(e.target.value)
                          }}
                        />

                        <Stack spacing={3} direction="row">
                          <Button variant="contained" onClick={companySearchHandler}>Search</Button>
                        </Stack>

                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row" id="clients">
                      <div className="col-12">
                        <div className="py-3">
                          {visit.length > 0 ? (
                            <div className="table-responsive px-2">
                              <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                <div className="dataTable-container" id="visitTable">
                                  <table className="table align-items-center mb-0 dataTable-table kv-table">
                                    <thead className="text-center">
                                      <tr>
                                        <th
                                          className="text-uppercase text-center text-xxs font-weight-bolder opacity-7"
                                          data-sortable=""
                                          style={{ width: "4.8032%", cursor: "pointer" }}
                                        >
                                          <a

                                            className="dataTable-sorter"

                                          >
                                            S.NO
                                          </a>
                                        </th>
                                        <th
                                          className="text-uppercase text-center text-xxs font-weight-bolder opacity-7 ps-2"
                                          data-sortable=""
                                          style={{ width: "7.938%", cursor: "pointer" }}
                                        >
                                          <a

                                            className="dataTable-sorter"

                                          >
                                            DATE
                                          </a>
                                        </th>
                                        <th
                                          className="text-uppercase text-center text-xxs font-weight-bolder opacity-7 ps-2"
                                          data-sortable=""
                                          style={{ width: "11.074%", cursor: "pointer" }}
                                        >
                                          <a

                                            className="dataTable-sorter"

                                          >
                                            CUSTOMER
                                          </a>
                                        </th>
                                        <th
                                          className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                          data-sortable=""
                                          style={{ width: "9.006%", cursor: "pointer" }}
                                        >
                                          <div

                                            className="dataTable-sorter"

                                          >
                                            ACTIONS
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>

                                    {visit.length > 0 ? (
                                      <tbody>


                                        {visit.map((item, index) => (
                                          <tr>
                                            <td>
                                              <h6 className="mb-0 text-center font-weight-normal text-sm">
                                                {index + 1}
                                              </h6>
                                            </td>

                                            <td className="align-middle text-center">
                                              <p className="text-sm font-weight-normal mb-0">
                                                {item.date}
                                              </p>
                                            </td>
                                            <td className="align-middle text-center">
                                              <p className="text-sm font-weight-normal mb-0">
                                                {item.company}
                                              </p>
                                            </td>

                                            <td className="align-middle text-center">
                                              <div className="d-flex justify-content-center align-items-center">

                                                <button className="btn btn-primary" onClick={handleOpen}>Add Tada</button>
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>) : (
                                      <p>No visits found.</p>
                                    )}
                                  </table>
                                </div>

                                <div className="datatable-mui-pagination">
                                  <Pagination
                                    color="secondary"
                                    size="large"
                                  />
                                </div>

                              </div>
                            </div>
                          ) : (
                            <Loader />
                          )}

                          <Modal
                            open={open} 
                            
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style = {{zIndex:1000000}}
                          >
                            <Box sx={style}>
                              <Typography id="modal-modal-title" variant="h6" component="h2">
                                Text in a modal
                              </Typography>
                              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                              </Typography>
                            </Box>
                          </Modal>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Redirect to={"/sign-in"} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTadas);
