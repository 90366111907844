import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper, InfoWindow} from 'google-maps-react';
import {google_map_api_key, url} from "../../store/actions/actions";

export class MapContainer extends Component {
  state = {
      marker: null,
      showingInfoWindow: false,
      markPos: null,

      customer: null,
      customer_id: null,
      customer_phone: null,
      customer_type: null,
      customer_last_visit: null,
  }

    onMarkerClick = () =>
    this.setState({
      showingInfoWindow: true
    });

    onMapClick = () =>
    this.setState({
      showingInfoWindow: false
    });

    render() {
    return (
      <div id='googleMaps' className="position-relative">
      {this.props.station?this.props.station.map((station, index)=>(
        <div className="google_map">
        <Map

          style={{width: '100%', height: '150%', position: 'relative'}}

          onClick={this.onMapClick}
          google={this.props.google}

          zoom={14}

          initialCenter={{
            lat: station.lat,
            lng: station.lng
          }}

          center={{
            lat: station.lat,
            lng: station.lng
          }}
        >
            {/*{this.props.marker?this.props.marker.map((mark, index)=>(*/}
            {/*  <Marker*/}
            {/*      title={'The marker`s title will appear as a tooltip.'}*/}
            {/*      name={'SOMA'}*/}
            {/*      // label={JSON.stringify(index+1)}*/}
            {/*      position={{lat: mark.lat, lng: mark.lng}}*/}
            {/*      // position={{lat: 28.7982071, lng: 76.1466884}}*/}
            {/*      onClick={(e)=>this.onMarkerClick(this.setState({, markPos: {lat: mark.lat, lng: mark.lng}}))}*/}
            {/*  />*/}
            {/*)):null}*/}


            {this.props.station?this.props.station.map((station, index)=>(
              station.customer?station.customer.map((cust, index)=>(
                <Marker
                  title={cust.company}
                  name={cust.company}
                  position={{lat: cust.address_by_location?cust.address_by_location.geolocation.split(",")[0]:null, lng: cust.address_by_location?cust.address_by_location.geolocation.split(",")[1]:null}}
                  onClick={(e)=>this.onMarkerClick(this.setState({
                      customer: cust.company,
                      customer_id: cust.id,
                      customer_phone: cust.phone,
                      customer_type: cust.type,
                      customer_last_visit: cust.last_visit.date,
                      markPos: {lat: cust.address_by_location?cust.address_by_location.geolocation.split(",")[0]:null, lng: cust.address_by_location?cust.address_by_location.geolocation.split(",")[1]:null},
                  }))}

                    icon={cust.type==='SCHOOL'?{
                      url: url+"/static/school.png",
                      anchor: new this.props.google.maps.Point(10,10),
                      scaledSize: new this.props.google.maps.Size(35,35)
                    }: {

                      url: url+"/static/book-shop.png",
                      anchor: new this.props.google.maps.Point(10,10),
                      scaledSize: new this.props.google.maps.Size(35,35)
                    }
                  }

                />
              )):null
            )):null}

            <InfoWindow
                visible={this.state.showingInfoWindow}
                position={this.state.markPos}
                >
                <div>
                  <a href={'/customer/customer-detail/'+this.state.customer_id}>
                    <h6>
                        {this.state.customer}
                    </h6>
                  </a>
                  <p>{this.state.customer_phone}</p>
                  <p>{this.state.customer_type}</p>
                  <p>{this.state.customer_last_visit}</p>
                </div>
            </InfoWindow>

        </Map>
        </div>
      )):
        <div className="google_map">
        <Map

          style={{width: '100%', height: '150%', position: 'relative'}}


          onClick={this.onMapClick}
          google={this.props.google}

          zoom={14}

          initialCenter={{
            lat: 28.6162284,
            lng: 77.3876115
          }}

          center={{
            lat: 28.6162284,
            lng: 77.3876115
          }}
        >
            <Marker
              position={{lat: 28.6162284, lng: 77.3876115}}
            />
        </Map>
        </div>
      }
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: (google_map_api_key)
})(MapContainer)