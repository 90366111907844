import React from "react";
import { connect } from "react-redux";
import HomeDataCards from "./DataCards";
import { axios, url } from "../../../store/actions/actions";
import HomeEmployeeDataTable from "./EmployeeDataTable";
import VisitDataTable from "./VisitDataTable";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Redirect } from "react-router-dom";
import SampleDataTable from "./SampleDataTable";
import TadaDataTable from "./TadaDataTable";
import HomePageDash2 from "./HomePageDash2";
import Testingsrk from "../Testingsrk/testingsrk";
import GoogleMap from "../../GoogleMap/GoogleMap";

class HomePage extends React.Component {
  state = {
    visits: null,
    filterVisits: null,
    employees: null,
    filterEmployees: null,
    all_employee: null,
    states: null,
    cities: null,
    samples: null,
    tada: null,
    purpose: null,
    quest: null,
    cards: null,

    // filter
    f_employee: [],
    f_state: [],
    f_city: [],
    f_purpose: [],
    f_quest: [],
    fromDate: null,
    toDate: null,

    type: "today",
  };

  componentDidMount() {
    this.loadDataCards();
    this.loadEmployees();
    this.loadVisits();
    this.loadSelectEmployees();
    this.loadStations();
    this.loadSampleRequests();
    this.loadTada();
    this.loadQuest();
  }

  

  loadSelectEmployees = () => {
    let endpoint = `/account/get-employees`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({
        all_employee: responseData.employee,
      });
      console.log({ "Employee data---------": responseData.employee });
    });
  };

  loadStations = () => {
    let endpoint = `/common/get-stations`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({
        states: responseData.state,
        cities: responseData.city,
      });
      console.log({ "Stations data------------>": responseData });
    });
  };

  loadDataCards = () => {
    let endpoint = "common/home-data-cards";
    let data = {
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
    };
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log("Cards Response data---------------------->", responseData);
      this.setState({
        cards: responseData,
        type: null,
        from: null,
        to: null,
      });
    });
  };

  loadVisits = () => {
    this.setState({
      visits: null,
      filterVisits: null,
    });
    let endpoint = "common/home-visits";
    let data = {
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
    };
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log("Visit Response data------------------------>", responseData);

      const objWithIndex = responseData.visits.map((obj, index) =>
        Object.assign(obj, { index: index + 1 })
      );

      console.log("--------------------------------------", objWithIndex);

      this.setState({
        visits: objWithIndex,
        filterVisits: objWithIndex,
      });
    });
  };

  loadQuest = () => {
    let endpoint = "common/home-quest";
    let data = {
      type: this.state.type,
    };
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log("Quest Response data--------------------->", responseData);
      this.setState({
        quest: responseData.quest,
        purpose: responseData.purpose,
      });
    });
  };

  loadEmployees = () => {
    let endpoint = "common/home-emp";
    let data = {
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
    };

    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log(
        "Employees Response data---------------------->",
        responseData
      );
      this.setState({
        employees: responseData.employees,
        filterEmployees: responseData.employees,
      });
    });
  };

  loadTada = () => {
    let endpoint = "common/home-tada";
    let data = {
      type: this.state.type,
    };
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log("Tada Response data---------------------->", responseData);
      const objWithIndex = responseData.tada.map((obj, index) =>
        Object.assign(obj, { index: index + 1 })
      );
      this.setState({
        tada: objWithIndex,
      });
    });
  };

  loadSampleRequests = () => {
    let endpoint = "common/home-samples";
    let data = {
      type: this.state.type,
    };

    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      console.log({ "Samples response data----------------->": responseData });
      const objWithIndex = responseData.samples.map((obj, index) =>
        Object.assign(obj, { index: index + 1 })
      );
      this.setState({ samples: objWithIndex });
    });
  };

  filterByDate = () => {
    this.setState({
      filterVisits: null,
      filterEmployees: null,
    });

    var vtList1 = [];
    var vtList2 = [];
    var vtList3 = [];
    var vtList4 = [];
    var vtList5 = [];
    var empList1 = [];

    let name = [...new Set(this.state.f_name)];
    let purpose = [...new Set(this.state.f_purpose)];
    let quest = [...new Set(this.state.f_quest)];
    let state = [...new Set(this.state.f_state)];
    let station = [...new Set(this.state.f_city)];

    let data = {
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
    };
    let token = this.props.user.token;

    let endpoint1 = "common/home-data-cards";
    let endpoint2 = "common/home-emp";
    let endpoint3 = "common/home-visit";

    axios.post(endpoint1, data, token).then((res) => {
      let responseData = res.data;
      console.log("Cards Response data--------------------->", responseData);
      this.setState({
        cards: responseData,
        type: null,
        from: null,
        to: null,
      });
    });

    axios.post(endpoint2, data, token).then((res) => {
      let responseData = res.data;
      console.log("Employees Response data-------------------->", responseData);
      responseData.employees.map((i, index) =>
        name.length !== 0
          ? i.first_name
              .toLowerCase()
              .includes(name ? name.toLowerCase() : null)
            ? empList1.push(i)
            : null
          : null
      );
      this.setState({
        employees: responseData.employees,
      });
      let fa = setTimeout(
        (e) =>
          this.filterActionEmp(
            empList1.length === 0 ? responseData.employees : empList1
          ),
        100
      );
    });

    axios.post(endpoint3, data, token).then((res) => {
      let responseData = res.data;
      const objWithIndex = responseData.visits.map((obj, index) =>
        Object.assign(obj, { index: index + 1 })
      );

      this.setState({
        visits: objWithIndex,
      });

      var vt1 = objWithIndex;
      vt1.map((i, index) =>
        name.length !== 0
          ? i.employee.toLowerCase().includes(name.toLowerCase())
            ? vtList1.push(i)
            : null
          : null
      );
      var vt2 = vtList1.length !== 0 ? vtList1 : objWithIndex;
      vt2.map((i, index) =>
        purpose.length !== 0
          ? i.purpose !== null
            ? purpose.includes(i.purpose)
              ? vtList2.push(i)
              : null
            : null
          : null
      );
      var vt3 =
        vtList2.length !== 0
          ? vtList2
          : vtList1.length !== 0
          ? vtList1
          : objWithIndex;
      vt3.map((i, index) =>
        state.length !== 0
          ? state.includes(i.customer.state)
            ? vtList3.push(i)
            : null
          : null
      );
      var vt4 =
        vtList3.length !== 0
          ? vtList3
          : vtList2.length !== 0
          ? vtList2
          : vtList1.length !== 0
          ? vtList1
          : objWithIndex;
      vt4.map((i, index) =>
        station.length !== 0
          ? station.includes(i.customer.station)
            ? vtList4.push(i)
            : null
          : null
      );
      var vt5 =
        vtList4.length !== 0
          ? vtList4
          : vtList3.length !== 0
          ? vtList3
          : vtList2.length !== 0
          ? vtList2
          : vtList1.length !== 0
          ? vtList1
          : objWithIndex;
      vt5.map((i, index) =>
        quest.length !== 0
          ? i.quest !== null
            ? quest.map((item) =>
                item.includes(i.quest) ? vtList5.push(i) : null
              )
            : null
          : null
      );

      var vtList =
        vtList5.length !== 0
          ? vtList5
          : vtList4.length !== 0
          ? vtList4
          : vtList3.length !== 0
          ? vtList3
          : vtList2.length !== 0
          ? vtList2
          : vtList1;
      console.log("vtlist----------------------->", vtList);
      let fa = setTimeout(
        (e) => this.filterActionVt(vtList.length === 0 ? objWithIndex : vtList),
        100
      );
    });
  };

  filterActionVt = (vt) => {
    var set = new Set(vt);
    const objWithIndex = [...set].map((obj, index) =>
      Object.assign(obj, { index: index + 1 })
    );
    this.setState({
      filterVisits: objWithIndex,
    });
    console.log(vt);
  };

  filterActionEmp = (emp) => {
    this.setState({
      filterEmployees: emp,
    });
    console.log(emp);
  };

  filterPage = () => {
    if (this.state.fromDate !== null && this.state.toDate !== null) {
      this.filterByDate();
    } else {
      let name = [...new Set(this.state.f_name)];
      let purpose = [...new Set(this.state.f_purpose)];
      let quest = [...new Set(this.state.f_quest)];
      let state = [...new Set(this.state.f_state)];
      let station = [...new Set(this.state.f_city)];

      var vtList1 = [];
      var vtList2 = [];
      var vtList3 = [];
      var vtList4 = [];
      var vtList5 = [];
      var empList1 = [];

      var vt1 = this.state.visits;
      var emp = this.state.employees;
      emp.map((i, index) =>
        name.length !== 0
          ? i.first_name
              .toLowerCase()
              .includes(name ? name.toLowerCase() : null)
            ? empList1.push(i)
            : null
          : null
      );
      vt1.map((i, index) =>
        name.length !== 0
          ? i.employee.toLowerCase().includes(name.toLowerCase())
            ? vtList1.push(i)
            : null
          : null
      );
      var vt2 = vtList1.length !== 0 ? vtList1 : this.state.visits;
      vt2.map((i, index) =>
        purpose.length !== 0
          ? i.purpose !== null
            ? purpose.includes(i.purpose)
              ? vtList2.push(i)
              : null
            : null
          : null
      );
      var vt3 =
        vtList2.length !== 0
          ? vtList2
          : vtList1.length !== 0
          ? vtList1
          : this.state.visits;
      vt3.map((i, index) =>
        state.length !== 0
          ? state.includes(i.customer.state)
            ? vtList3.push(i)
            : null
          : null
      );
      var vt4 =
        vtList3.length !== 0
          ? vtList3
          : vtList2.length !== 0
          ? vtList2
          : vtList1.length !== 0
          ? vtList1
          : this.state.visits;
      vt4.map((i, index) =>
        station.length !== 0
          ? station.includes(i.customer.station)
            ? vtList4.push(i)
            : null
          : null
      );
      var vt5 =
        vtList4.length !== 0
          ? vtList4
          : vtList3.length !== 0
          ? vtList3
          : vtList2.length !== 0
          ? vtList2
          : vtList1.length !== 0
          ? vtList1
          : this.state.visits;
      vt5.map((i, index) =>
        quest.length !== 0
          ? i.quest !== null
            ? quest.map((item) =>
                item.includes(i.quest) ? vtList5.push(i) : null
              )
            : null
          : null
      );
      this.setState({
        filterVisits: null,
        filterEmployees: null,
      });

      var vtList =
        vtList5.length !== 0
          ? vtList5
          : vtList4.length !== 0
          ? vtList4
          : vtList3.length !== 0
          ? vtList3
          : vtList2.length !== 0
          ? vtList2
          : vtList1;

      let fa = setTimeout((e) => this.filterAction(vtList, empList1), 500);
    }
  };

  filterAction = (vt, emp) => {
    var set = new Set(vt);
    const objWithIndex = [...set].map((obj, index) =>
      Object.assign(obj, { index: index + 1 })
    );
    this.setState({
      filterVisits: objWithIndex,
      filterEmployees: emp,
    });
    console.log(vt);
    console.log(emp);
  };

  filterAction2 = (vt) => {
    var set = new Set(vt);
    const objWithIndex = [...set].map((obj, index) =>
      Object.assign(obj, { index: index + 1 })
    );
    this.setState({
      filterVisits: objWithIndex,
    });
  };

  filterButton = () => {
    this.setState({
      showMainfilter: this.state.showMainfilter === true ? false : true,
      f_employee:
        this.state.showMainfilter === false ? null : this.state.f_employee,
      f_state: this.state.showMainfilter === false ? null : this.state.f_state,
      f_city: this.state.showMainfilter === false ? null : this.state.f_city,
      f_purpose:
        this.state.showMainfilter === false ? null : this.state.f_purpose,
      f_quest: this.state.showMainfilter === false ? null : this.state.f_quest,
    });
  };

  DateWise = (type) => {
    this.setState({
      type: type,
    });
    let ab = setTimeout(this.loadDataCards, 200);
    // let bc = setTimeout(this.loadEmployees, 500)
    let cd = setTimeout(this.loadVisits, 200);
  };

  handleCallback = (purpose) => {
    let myList = [];
    var vt = this.state.visits;
    vt.map((i, index) =>
      purpose
        ? i.purpose !== null
          ? i.purpose.toLowerCase().includes(purpose.toLowerCase())
            ? myList.push(i)
            : null
          : null
        : null
    );

    this.setState({
      filterVisits: null,
    });

    let fa = setTimeout((e) => this.filterAction2(myList), 500);
  };

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          
          <>
            {this.props.user.employee_profile === "executive" ? (
              // <div className="row justify-content-end pe-3">
              //   <div className="col-md-6">
              //   <GoogleMap />
              //   </div>
              // </div>
              <HomePageDash2 />
            ) : (
              <>
                <div className="container-fluid homepage-view">
                  <div className="row justify-content-end">
                    <div
                      className={
                        this.props.sidebar === undefined ||
                        this.props.sidebar === true
                          ? "d-none d-lg-block col-10"
                          : "d-none d-lg-block col-12"
                      }
                    >
                      <div className="">
                        <div className="homePageButtons">
                          <div className="f-btn mb-3 d-flex justify-content-between mb-2">
                            <button
                              className="nav-pills srk-active py-2"
                              onClick={(e) => this.DateWise("today")}
                            >
                              Today
                            </button>
                            <button onClick={(e) => this.DateWise("yesterday")}>
                              Yesterday
                            </button>
                            <button onClick={(e) => this.DateWise("weak")}>
                              Weekly
                            </button>
                            <button
                              className="ms-auto"
                              onClick={this.filterButton}
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                        {this.state.showMainfilter === true ? (
                          <div
                            className="card f-card p-4"
                            style={{
                              width: "600px",
                              position: "absolute",
                              top: "125px",
                              right: "32px",
                              zIndex: "10",
                              boxShadow: "0px 0px 6px #bdbbbb",
                            }}
                          >
                            <h6>FILTERS</h6>
                            {this.state.clear ? (
                              <p
                                onClick={this.clearFilter}
                                style={{ color: "#5f5fcb", cursor: "pointer" }}
                              >
                                Clear
                              </p>
                            ) : null}
                            <div className="row p-1">
                              <div className="col-12 mt-2">
                                <div className="d-flex align-items-center">
                                  <TextField
                                    id="date"
                                    label="From"
                                    type="date"
                                    size="small"
                                    fullWidth
                                    onChange={(e) =>
                                      this.setState({
                                        fromDate: e.target.value,
                                      })
                                    }
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                  <p
                                    className="mx-4 my-0"
                                    style={{ fontSize: "24px" }}
                                  >
                                    to
                                  </p>

                                  <TextField
                                    id="date"
                                    label="To"
                                    type="date"
                                    size="small"
                                    onChange={(e) =>
                                      this.setState({ toDate: e.target.value })
                                    }
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 mt-3">
                                {this.state.all_employee ? (
                                  <Autocomplete
                                    disablePortal
                                    size="small"
                                    id="combo-box-demo"
                                    options={this.state.all_employee}
                                    onChange={(e, value) =>
                                      this.setState({
                                        f_employee: value.first_name,
                                      })
                                    }
                                    getOptionLabel={(option) =>
                                      option.last_name
                                        ? option.first_name +
                                          " " +
                                          option.last_name
                                        : option.first_name
                                    }
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": {
                                            mr: 2,
                                            flexShrink: 0,
                                          },
                                        }}
                                        {...props}
                                      >
                                        <img
                                          style={{ borderRadius: "50%" }}
                                          // loading="lazy"
                                          width="30"
                                          src={
                                            option.pic
                                              ? url + option.pic
                                              : url + "/static/agent.png"
                                          }
                                          // srcSet={`${url + option.pic} 2x`}
                                          alt=""
                                        />
                                        {option.last_name
                                          ? option.first_name +
                                            " " +
                                            option.last_name
                                          : option.first_name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Employee"
                                      />
                                    )}
                                  />
                                ) : null}
                              </div>
                              <div className="col-6 mt-3">
                                <Autocomplete
                                  multiple
                                  size="small"
                                  id="tags-standard"
                                  options={this.state.states}
                                  getOptionLabel={(option) => option.state}
                                  onChange={(e, value) =>
                                    value.map((item) =>
                                      this.state.f_state.push(item.state)
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select State"
                                    />
                                  )}
                                />
                              </div>
                              <div className="col-6 mt-3">
                                {/*<Stack spacing={3} sx={{ width: 500 }}>*/}
                                <Autocomplete
                                  multiple
                                  size="small"
                                  id="tags-standard"
                                  options={this.state.cities}
                                  getOptionLabel={(option) => option.station}
                                  onChange={(e, value) =>
                                    value.map((item) =>
                                      this.state.f_city.push(item.station)
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select City"
                                      // placeholder="Favorites"
                                    />
                                  )}
                                />
                                {/*</Stack>*/}
                              </div>
                              <div className="col-6 mt-3">
                                {/*<Stack spacing={3} sx={{ width: 500 }}>*/}
                                <Autocomplete
                                  multiple
                                  size="small"
                                  id="tags-standard"
                                  options={this.state.purpose}
                                  getOptionLabel={(option) => option.choice}
                                  onChange={(e, value) =>
                                    value.map((item) =>
                                      this.state.f_purpose.push(item.choice)
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Purpose"
                                      // placeholder="Favorites"
                                    />
                                  )}
                                />
                                {/*</Stack>*/}
                              </div>

                             

                              <div className="col-6 mt-3">
                                {/*<Stack spacing={3} sx={{ width: 500 }}>*/}
                                <Autocomplete
                                  multiple
                                  size="small"
                                  id="tags-standard"
                                  options={this.state.quest}
                                  getOptionLabel={(option) => option.option}
                                  onChange={(e, value) =>
                                    value.map((item) =>
                                      this.state.f_quest.push(item.option)
                                    )
                                  }
                                  // onChange={(e, value)=>console.log(value)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Questionnaire"
                                      // placeholder="Favorites"
                                    />
                                  )}
                                />
                                {/*</Stack>*/}
                              </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                              <button
                                onClick={this.filterPage}
                                className="btn my-0 bg-gradient-dark d-block"
                              >
                                Apply Filters
                              </button>
                              {/*<button onClick={this.test} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>*/}
                            </div>
                          </div>
                        ) : null}
                        {this.state.cards ? (
                          <HomeDataCards
                            data={this.state.cards}
                            parentCallback={this.handleCallback}
                          />
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-2">
                                <Stack spacing={1}>
                                  <Skeleton
                                    variant="rounded"
                                    style={{
                                      height: "257px",
                                      borderRadius: "12px",
                                    }}
                                  />
                                </Stack>
                              </div>
                              <div className="col-5">
                                <Stack spacing={1}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                </Stack>
                              </div>
                              <div className="col-5">
                                <div className="row">
                                  <div className="col-6">
                                    <Skeleton
                                      variant="rounded"
                                      style={{
                                        height: "100px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Skeleton
                                      variant="rounded"
                                      style={{
                                        height: "100px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-6 mt-5">
                                    <Skeleton
                                      variant="rounded"
                                      style={{
                                        height: "100px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-6 mt-5">
                                    <Skeleton
                                      variant="rounded"
                                      style={{
                                        height: "100px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          {this.props.user.employee_profile === "admin" ? (
                            <div className="col-12">
                              {this.state.filterVisits ? (
                                <VisitDataTable
                                  visit={this.state.filterVisits}
                                />
                              ) : (
                                <div
                                  className="card py-3"
                                  style={{ marginTop: "15px" }}
                                >
                                  <div className="table-responsive px-2">
                                    <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                      <div className="dataTable-top">
                                        <h5 className="mb-0 ps-3">Visits</h5>
                                      </div>
                                      <div
                                        className="dataTable-container"
                                        id="employeeTable"
                                      >
                                        <Stack spacing={1}>
                                          {/* For variant="text", adjust the height via font-size */}
                                          <Skeleton
                                            variant="text"
                                            sx={{ fontSize: "1rem" }}
                                          />
                                          {/* For other variants, adjust the size with `width` and `height` */}
                                          {/*<Skeleton variant="circular" width={40} height={40} />*/}
                                          <Skeleton
                                            variant="rectangular"
                                            style={{ height: "500px" }}
                                          />
                                          {/*<Skeleton variant="rounded" width={210} height={60} />*/}
                                        </Stack>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : null}
                          <div className="col-12">
                            {this.state.filterEmployees ? (
                              <HomeEmployeeDataTable
                                emp={this.state.filterEmployees}
                              />
                            ) : (
                              <div
                                className="card py-3"
                                style={{ marginTop: "15px" }}
                              >
                                <div className="table-responsive px-2">
                                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                    <div className="dataTable-top">
                                      <h5 className="mb-0 ps-3">
                                        Active Employee's
                                      </h5>
                                    </div>
                                    <div
                                      className="dataTable-container"
                                      id="employeeTable"
                                    >
                                      <Stack spacing={1}>
                                        <Skeleton
                                          variant="text"
                                          sx={{ fontSize: "1rem" }}
                                        />
                                        <Skeleton
                                          variant="rectangular"
                                          style={{ height: "500px" }}
                                        />
                                      </Stack>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-12">
                            {this.state.samples ? (
                              <SampleDataTable samples={this.state.samples} />
                            ) : (
                              <div
                                className="card py-3"
                                style={{ marginTop: "15px" }}
                              >
                                <div className="table-responsive px-2">
                                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                    <div className="dataTable-top">
                                      <h5 className="mb-0 ps-3">Samples</h5>
                                    </div>
                                    <div
                                      className="dataTable-container"
                                      id="employeeTable"
                                    >
                                      <Stack spacing={1}>
                                        {/* For variant="text", adjust the height via font-size */}
                                        <Skeleton
                                          variant="text"
                                          sx={{ fontSize: "1rem" }}
                                        />
                                        {/* For other variants, adjust the size with `width` and `height` */}
                                        {/*<Skeleton variant="circular" width={40} height={40} />*/}
                                        <Skeleton
                                          variant="rectangular"
                                          style={{ height: "500px" }}
                                        />
                                        {/*<Skeleton variant="rounded" width={210} height={60} />*/}
                                      </Stack>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-6 d-none">
                            {this.state.tada ? (
                              <TadaDataTable tada={this.state.tada} />
                            ) : (
                              <div
                                className="card py-3"
                                style={{ marginTop: "15px" }}
                              >
                                <div className="table-responsive px-2">
                                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                    <div className="dataTable-top">
                                      <h5 className="mb-0 ps-3">TA/DA</h5>
                                    </div>
                                    <div
                                      className="dataTable-container"
                                      id="employeeTable"
                                    >
                                      <Stack spacing={1}>
                                        {/* For variant="text", adjust the height via font-size */}
                                        <Skeleton
                                          variant="text"
                                          sx={{ fontSize: "1rem" }}
                                        />
                                        {/* For other variants, adjust the size with `width` and `height` */}
                                        {/*<Skeleton variant="circular" width={40} height={40} />*/}
                                        <Skeleton
                                          variant="rectangular"
                                          style={{ height: "500px" }}
                                        />
                                        {/*<Skeleton variant="rounded" width={210} height={60} />*/}
                                      </Stack>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-lg-none mb-5">
                      <div className="">
                        {this.state.cards ? (
                          <HomeDataCards data={this.state.cards} />
                        ) : (
                          <div>
                            <div className="p-3 mt-3 row">
                              <div className="col-12">
                                <Stack spacing={1}>
                                  <Skeleton
                                    variant="rounded"
                                    style={{
                                      height: "280px",
                                      borderRadius: "12px",
                                    }}
                                  />
                                </Stack>
                              </div>
                              <div className="col-12">
                                <Stack spacing={1}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", height: "45px" }}
                                  />
                                </Stack>
                              </div>

                              <div className="col-12">
                                <div className="row">
                                  <div className="col-6">
                                    <Skeleton
                                      variant="rounded"
                                      style={{
                                        height: "100px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Skeleton
                                      variant="rounded"
                                      style={{
                                        height: "100px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-6 mt-5">
                                    <Skeleton
                                      variant="rounded"
                                      style={{
                                        height: "100px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-6 mt-5">
                                    <Skeleton
                                      variant="rounded"
                                      style={{
                                        height: "100px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-12">
                            {this.state.employees ? (
                              <HomeEmployeeDataTable
                                emp={this.state.employees}
                              />
                            ) : (
                              <div
                                className="card py-3"
                                style={{ marginTop: "15px" }}
                              >
                                <div className="table-responsive px-2">
                                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                    <div className="dataTable-top">
                                      <h5 className="mb-0 ps-3">
                                        Active Employee's
                                      </h5>
                                    </div>
                                    <div
                                      className="dataTable-container"
                                      id="employeeTable"
                                    >
                                      <Stack spacing={1}>
                                        <Skeleton
                                          variant="text"
                                          sx={{ fontSize: "1rem" }}
                                        />
                                        <Skeleton
                                          variant="rectangular"
                                          style={{ height: "500px" }}
                                        />
                                      </Stack>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-12">
                            {this.state.filterVisits ? (
                              <VisitDataTable visit={this.state.filterVisits} />
                            ) : (
                              <div
                                className="card py-3"
                                style={{ marginTop: "15px" }}
                              >
                                <div className="table-responsive px-2">
                                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                    <div className="dataTable-top">
                                      <h5 className="mb-0 ps-3">Visits</h5>
                                    </div>
                                    <div
                                      className="dataTable-container"
                                      id="employeeTable"
                                    >
                                      <Stack spacing={1}>
                                        {/* For variant="text", adjust the height via font-size */}
                                        <Skeleton
                                          variant="text"
                                          sx={{ fontSize: "1rem" }}
                                        />
                                        {/* For other variants, adjust the size with `width` and `height` */}
                                        {/*<Skeleton variant="circular" width={40} height={40} />*/}
                                        <Skeleton
                                          variant="rectangular"
                                          style={{ height: "500px" }}
                                        />
                                        {/*<Skeleton variant="rounded" width={210} height={60} />*/}
                                      </Stack>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(HomePage);
