import React from "react";
import {connect} from "react-redux";
import {axios, sideBar, userLogin} from "../../../store/actions/actions";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import {Link, Redirect} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class CreateTada extends React.Component {

    handleImageSelected = this.handleImageSelected.bind(this);
    uploadTadaImages = this.uploadTadaImages.bind(this);

    state = {
      visit_date: null,
      teams: null,
      visible: true,
      team_id: null,

      visit_obj: [],

      image: null,
      instanceImage: null,

      da1: 0,
      da2: 0,
      da3: 0,
      total: 0,

      redirect: false
    }

    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    componentDidMount() {
      this.loadTeam()
      this.toeknExpiryCheck()
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

    loadTeam = () => {
      let endpoint = 'teams/select-team'
      let token = this.props.user.token
      axios
          .get(endpoint, token)
          .then((res)=>{
              let responseData = res.data;
              this.setState({teams: responseData.teams})
              console.log({'team response data---------':responseData.teams})
          })
    }

    getVisits = (selectedOption) => {
      const data = {
          visit_date: this.state.visit_date,
          team_id: selectedOption.value,
          visit_id: null
      }
      let endpoint = 'tada/get-tada-visit2'
      let token = this.props.user.token
      axios
          .post(endpoint, data, token)
          .then((res)=>{
              let responseData = res.data;
              console.log({'tada visits ----------':responseData})
              responseData.visit.map((item) => {
                  let obj = {visit_id: item.id, customer_id: item.customer.id, company: item.customer.company, working_status: item.working_status?item.working_status:null, station: item.customer.station.station+', '+item.customer.station.state.state, remark: null}
                  this.state.visit_obj.push(obj)
                  this.setState({
                      visit_obj: this.state.visit_obj,
                      team_id: selectedOption.value
                  })
              })
              console.log({'tada visits ----------':responseData})
              console.log({'array ----------':this.state.visit_obj})
          })
  }

    saveValueToObject = (id, key, value) => {
        let obj_array = this.state.visit_obj

        obj_array.map((i, index) => (i.visit_id === id ? i[key] = value : null))
        this.setState({
            visit_obj: obj_array
        })
    };

    setAmount = (key, value) => {
        if (key==='da1') {
            this.setState({
                da1: value
            })
            const myTimeout = setTimeout(this.gtotal, 100)
        }
        if (key==='da2') {
            this.setState({
                da2: value
            })
            const myTimeout = setTimeout(this.gtotal, 100)
        }
        if (key==='da3') {
            this.setState({
                da3: value
            })
            const myTimeout = setTimeout(this.gtotal, 100)
        }
    }

    gtotal = () => {
        console.log('working')
        this.setState({
            total: parseInt(this.state.da1)+parseInt(this.state.da2)+parseInt(this.state.da3)
        })
    }

    imageArray = []
    instanceImageArray = []
    removeDBImageArray = []

    handleImageSelected(event){
        console.log(...event.target.files)
        this.imageArray.push(...event.target.files)
        this.setState({
                image: this.imageArray
            })
        const files = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            console.log(reader.result)
          this.instanceImageArray.push(reader.result)
            this.setState({
              instanceImage: this.instanceImageArray
          })
        }
        reader.readAsDataURL(files);
    }

    removeImage(item){
      this.instanceImageArray.pop(this.instanceImageArray[item])
      this.imageArray.pop(this.imageArray[item])
      this.setState({
          instanceImage: this.instanceImageArray,
          image: this.imageArray
      })
    }

    submit = (images) => {
        const data = {
            team_id: this.state.team_id,
            obj: this.state.visit_obj,
            da1: this.state.da1,
            da2: this.state.da2,
            da3: this.state.da3,
            total: this.state.total,
            images: images
        }

        let endpoint = `tada/`
        let token = this.props.user.token
        axios
          .post(endpoint, data, token)
          .then((res)=>{
              let responseData = res.data;
              console.log({'submit---------':responseData})
              if (responseData.success===true) {
                  this.setState({
                      redirect: true
                  })
              }
          })
    }

    uploadTadaImages(event) {
        event.preventDefault()
        let data = new FormData();
        if (this.imageArray.length!==0) {
            for (const key of Object.keys(this.state.image)) {
                data.append('images', this.state.image[key])
            }
        } else {
            data.append('images', null)
        }

        let endpoint = `tada/upload-slips`
        let token = this.props.user.token
        axios
          .post(endpoint, data, token)
          .then((res)=>{
              let responseData = res.data;
              console.log({'submit---------':responseData})
              if (responseData.success===true) {
                  this.submit(responseData.id)
              }
          })
    }

    render() {
        return (
            <>
            {this.props.user.login===true?
                <>
                    {this.state.redirect===false?
                        <>
                        {/*<--------------------Mobile View-------------------->*/}
                            <>
                                <div className="rstada-box py-1">
                                    <div className="row mt-3 align-items-center" style={{width: "95%", maxWidth: "100%", marginInline: "auto", marginBottom: "15px"}}>
                                            <div className="col-6">
                                                <div className="d-flex mt-2 p-1 align-items-center">
                                                  <TextField
                                                    id="date"
                                                    label="Visit Date"
                                                    type="date"
                                                    size="small"

                                                    onChange={(e) => this.setState({visit_date: e.target.value})}

                                                    sx={{ width: 400 }}
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                  />
                                                </div>
                                            </div>
                                            <div className="col-6 mt-2">
                                                {this.state.visit_date?
                                                <div className="input-group input-group-static is-filled">
                                                      <Select
                                                          placeholder="Select Team"

                                                          options={
                                                              this.state.teams?this.state.teams.map((item, index)=>(
                                                                {value: item.id, label: item.name}
                                                              )):null
                                                          }

                                                          onChange={this.getVisits}
                                                      />
                                                </div>
                                                :null}
                                            </div>
                                        </div>


                                    {this.state.visit_obj.length!==0?
                                    <>
                                        <div className="accordion">

                                            {this.state.visit_obj?this.state.visit_obj.map((item, index)=>(
                                            <div className="accordion-item">
                                                <div className="accordion-item-header fw-bold"
                                                    onClick={(e)=>document.getElementById(item.visit_id).style.maxHeight==="0px"?
                                                    document.getElementById(item.visit_id).style.maxHeight="169px":
                                                    document.getElementById(item.visit_id).style.maxHeight="0px"}
                                                >
                                                    <p>{index+1+"."}&nbsp;&nbsp;</p>
                                                    <p className="fw-bold">Visit</p>
                                                </div>

                                                <div className="accordion-item-body" id={item.visit_id} style={{maxHeight: "0px"}}>
                                                    <div className="accordion-item-body-content">
                                                        <p className="fw-bold mb-3">{item.company}</p>
                                                        <div className="info">
                                                            <div className="station d-flex">
                                                                <p className="info-head fw-bold me-2 py-1">Station:</p>
                                                                <p className="py-1">{item.station}</p>
                                                            </div>
                                                            <div className="station d-flex">
                                                                <p className="info-head fw-bold py-1 me-2">Purpose:</p>
                                                                {/*<p className="py-1">none</p>*/}
                                                                <p className="py-1">{item.purpose}</p>
                                                            </div>
                                                            <div className="station d-flex">
                                                                {/*<p className="info-head fw-bold  py-1 me-2">Remark:</p>*/}
                                                                {/*<p className="py-1">Remark</p>*/}
                                                                <TextField
                                                                   className="col-11"
                                                                   id="standard-multiline-flexible"
                                                                   multiline
                                                                   maxRows={4}
                                                                   label="Remark"
                                                                   variant="filled"
                                                                   size="small"
                                                                   value={item.remark?item.remark:''}
                                                                   onChange={(e) => this.saveValueToObject(item.visit_id, 'remark', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )):null}
                                        </div>

                                        <div className="row justify-content-around">
                                            <div className="col-md-11" style={{border: "1px solid rgb(230 230 230)", borderRadius: "5px"}}>
                                                <div className="row justify-content-end align-items-center" style={{height: "120px"}}>
                                                {this.state.instanceImage?this.state.instanceImage.map((instImg, index)=>(

                                                    <div className="col-md-1 my-1 p-0 tada-img mx-3 d-flex justify-content-center" style={{height: "60px", width: "100px"}}>
                                                        <img
                                                        className="img-fluid h-100"

                                                        onClick={(e)=>this.removeImage(index)}
                                                        src={instImg}
                                                        alt="Preview"/>
                                                        <i className="fa-solid fa-xmark tada-close"></i>
                                                    </div>
                                                    )):null}
                                                    <div className="col-md-1 d-flex justify-content-center align-items-center ">
                                                <label htmlFor='imgupload' >
                                               <AddToPhotosIcon
                                                   style={{color: 'green'}}
                                                   fontSize='large'
                                               />
                                           </label>
                                            </div>
                                                </div>
                                            </div>

                                        </div>

                                        <input id="imgupload" className="file-upload" type="file" accept="image/*"
                                            onChange={this.handleImageSelected}
                                            style={{
                                                display: "none"
                                            }}
                                        />
                                        <div className="billing w-100 px-4">
                                        <div className="station d-flex w-100 justify-content-between my-2">
                                            <p className="info-head fw-bold py-1 me-2 m-0" style={{color: "#000", fontSize: "10px"}}>DA
                                                Local Outstation (night stay)</p>

                                              <span className="form-group">
                                                 <input
                                                    style={{color: "#000", background: "#bcbcbc", width: "80px", borderRadius: "5px", padding: "5px"}}
                                                    placeholder={'0'}
                                                    type="number"
                                                    className="form-control disable-input"
                                                    onChange={(e)=>this.setAmount('da1', e.target.value)}
                                                 />
                                              </span>
                                        </div>
                                        <div className="station d-flex w-100 justify-content-between my-2">
                                            <p className="info-head fw-bold py-1 me-2 m-0" style={{color: "#000", fontSize: "10px"}}>DA
                                                Local Outstation (night stay)</p>

                                              <span className="form-group">
                                                 <input
                                                    style={{color: "#000", background: "#bcbcbc", width: "80px", borderRadius: "5px", padding: "5px"}}
                                                    placeholder={'0'}
                                                    type="number"
                                                    className="form-control disable-input"
                                                    onChange={(e)=>this.setAmount('da2', e.target.value)}
                                                 />
                                              </span>
                                        </div>
                                        <div className="station d-flex w-100 justify-content-between my-2">
                                            <p className="info-head fw-bold py-1 me-2 m-0" style={{color: "#000", fontSize: "10px"}}>Fare in
                                                case of Outstation (nightstay)<br/>
                                                    Bus/Train ticket is mandatory</p>

                                              <span className="form-group">
                                                 <input
                                                    style={{color: "#000", background: "#bcbcbc", width: "80px", borderRadius: "5px", padding: "5px"}}
                                                    placeholder={'0'}
                                                    type="number"
                                                    className="form-control disable-input"
                                                    onChange={(e)=>this.setAmount('da3', e.target.value)}
                                                 />
                                              </span>
                                        </div>
                                        <div className="station d-flex w-100 justify-content-between my-2">
                                            <p className="info-head fw-bold py-1 me-2 m-0" style={{color: "#000", fontSize: "10px"}}>Grand
                                                Total</p>

                                              <span className="form-group">
                                                 <input
                                                    style={{color: "#000", background: "#bcbcbc", width: "80px", borderRadius: "5px", padding: "5px"}}
                                                    placeholder={'0'}
                                                    type="number"
                                                    className="form-control disable-input"
                                                    onChange={(e)=>this.setAmount('da4', e.target.value)}
                                                 />
                                              </span>
                                        </div>
                                        <div className="station d-flex w-100 justify-content-center my-2">
                                            <button className="btn bg-primary text-white px-3 py-2">SUBMIT</button>
                                        </div>
                                    </div>
                                    </>
                                    :null}
                                </div>
                            </>
                        </>
                    :<Redirect to={'/tada'} />}
                </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    user:state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTada);

