import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { connect } from "react-redux";
import { axios, url } from "../../../store/actions/actions";
import { Detector } from "react-detect-offline";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PageNotFound from "../../common/PageNotFound";
import InternetConnection from "../../common/InternetConnection";
import {Link, Redirect} from "react-router-dom";
import Loader from "../../common/Loader/Loader";
import CircleIcon from '@mui/icons-material/Circle';
import FilterListIcon from "@mui/icons-material/FilterList";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


class TeamTransactions extends React.Component {

    state = {
        teams: null,
        team_id: null,
        balance: null,
        transaction: null,

        fromDate: null,
        toDate: null,
        transaction_type: null
    }

    componentDidMount() {
        this.loadTeams()
    }

    loadTeams = () => {
        let endpoint = `/teams/dash`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                this.setState({
                    teams: responseData
                })
                console.log({ 'Team response data---------': responseData })
            })
    }

    loadTransactions = (event, value) => {
        let id = value ? value.id : this.state.team_id
        this.setState({ team_id: id })
        let data = {
            team_id: id,
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
            transaction_type: this.state.transaction_type,
        }
        let endpoint = 'account/get-team-transaction'
        let token = this.props.user.token
        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                this.setState({
                    transaction: responseData.transaction,
                    balance: responseData.balance
                })
                console.log({ 'transaction response data---------': responseData })
            })
    }

    render() {
        return (
            <>
                {this.props.user.login === true ?
                    <><Detector
                        render={({ online }) => (
                            <div className={online ? "normal" : "warning"}>
                                {online ?
                                    this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                        <>
                                            <div className="container-fluid">
                                                <div className="row justify-content-end">
                                                    <div
                                                        className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-10" : "col-12"}>
                                                        <div className="col-md-10 d-none d-lg-block m-auto mt-lg-0 mt-4">
                                                            {/*// <!-- Card Basic Info -->*/}
                                                            <div className="card mt-4 mb-4" id="basic-info">
                                                                <div className="card-header">
                                                                    <h5>Transactions</h5>
                                                                </div>
                                                                {this.state.team_id ?
                                                                    <div onClick={(e) => this.setState({ showfilter: this.state.showfilter === true ? false : true })}>
                                                                        <FilterListIcon style={{ cursor: "pointer", marginRight: "18", marginTop: "-64", float: "right" }} />
                                                                    </div>
                                                                    : null}
                                                                {this.state.showfilter === true ?
                                                                    <div className="card p-4" style={{ width: "700px", position: "absolute", top: "50px", right: "10px", zIndex: "10", boxShadow: "0px 0px 6px #bdbbbb" }}>
                                                                        <h6>FILTERS</h6>
                                                                        {this.state.clear ? <p onClick={this.clearFilter} style={{ color: "#5f5fcb", cursor: "pointer" }}>Clear</p> : null}
                                                                        <div className="row p-1">
                                                                            <div className="col-12 p-1">
                                                                                <p>Date range</p>
                                                                                <div className="d-flex mt-2 p-1 align-items-center"  >
                                                                                    <TextField
                                                                                        id="date"
                                                                                        label="From"
                                                                                        type="date"
                                                                                        size="small"

                                                                                        onChange={(e) => this.setState({ fromDate: e.target.value })}

                                                                                        sx={{ width: 220 }}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                        }}
                                                                                    />
                                                                                    <p className="mx-4 my-0" style={{ fontSize: "24px" }}>to</p>

                                                                                    <TextField
                                                                                        id="date"
                                                                                        label="To"
                                                                                        type="date"
                                                                                        size="small"

                                                                                        onChange={(e) => this.setState({ toDate: e.target.value })}

                                                                                        sx={{ width: 220 }}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 p-1 mt-2">
                                                                                <p>Transactions</p>
                                                                                <div className="form-check-inline">
                                                                                    <Box sx={{ minWidth: 220 }}>
                                                                                        <FormControl fullWidth>
                                                                                            <InputLabel id="demo-simple-select-label">Select transaction-type</InputLabel>
                                                                                            <Select
                                                                                                labelId="demo-simple-select-label"
                                                                                                id="demo-simple-select"
                                                                                                label="Select Transaction-type"
                                                                                                onChange={(e) => this.setState({ transaction_type: e.target.value })}
                                                                                            >
                                                                                                <MenuItem value='ALL' selected={true}>All</MenuItem>
                                                                                                <MenuItem value='CREDITED'>Credited</MenuItem>
                                                                                                <MenuItem value='DEBITED'>Debited</MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Box>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-end mt-3">
                                                                            <button onClick={this.loadTransactions} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>
                                                                        </div>

                                                                    </div>
                                                                    : null}
                                                                <div className="card-body pt-0">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            {this.state.teams ?
                                                                                <Autocomplete
                                                                                    disablePortal
                                                                                    id="combo-box-demo"
                                                                                    options={this.state.teams}
                                                                                    onChange={this.loadTransactions}
                                                                                    getOptionLabel={(option) => option.name}
                                                                                    // sx={{ width: 300 }}
                                                                                    renderOption={(props, option) => (
                                                                                        <Box component="li" sx={{
                                                                                            '& > img': {
                                                                                                mr: 2,
                                                                                                flexShrink: 0
                                                                                            }
                                                                                        }} {...props}>
                                                                                            <img
                                                                                                style={{ borderRadius: '50%' }}
                                                                                                // loading="lazy"
                                                                                                width="30"
                                                                                                src={option.logo ? url + option.logo : url + '/static/team.png'}
                                                                                                srcSet={`${url + option.logo} 2x`}
                                                                                                alt=""
                                                                                            />
                                                                                            {option.name}
                                                                                        </Box>
                                                                                    )}
                                                                                    renderInput={(params) =>
                                                                                        <TextField {...params}
                                                                                            label="Select Team" />}
                                                                                />
                                                                                : null}
                                                                        </div>
                                                                        <div className="col-12 mt-5">
                                                                            {/*{this.state.balance ?*/}
                                                                            {/*    <p className="m-1 text-center">*/}
                                                                            {/*        <CurrencyRupeeIcon/>{this.state.balance}*/}
                                                                            {/*    </p>*/}
                                                                            {/*    : null}*/}
                                                                            <Timeline position="alternate">
                                                                                {this.state.transaction ? this.state.transaction.map((t, index) => (
                                                                                    <>
                                                                                        <p className="m-1 text-center">
                                                                                            <CurrencyRupeeIcon />{t.current_balance}
                                                                                        </p>
                                                                                        <TimelineItem>
                                                                                            <TimelineOppositeContent
                                                                                                sx={{ m: 'auto 0' }}
                                                                                                align="right"
                                                                                                variant="body2"
                                                                                                color="text.secondary"
                                                                                            >
                                                                                                {new Date(t.time_stamp).toLocaleString('en-US', {
                                                                                                    day: 'numeric',
                                                                                                    month: 'short',
                                                                                                    year: 'numeric',
                                                                                                    hour: 'numeric',
                                                                                                    minute: 'numeric',
                                                                                                    hour12: true
                                                                                                })}
                                                                                            </TimelineOppositeContent>
                                                                                            <TimelineSeparator>
                                                                                                <TimelineConnector />
                                                                                                <TimelineDot>
                                                                                                    {t.transaction_type === 'CREDITED' ?
                                                                                                        <img
                                                                                                            src={require('../../../custom/icons/down-arrow.png')}
                                                                                                            height={25} />
                                                                                                        :
                                                                                                        <img
                                                                                                            src={require('../../../custom/icons/up-arrow.png')}
                                                                                                            height={25} />
                                                                                                    }

                                                                                                    {/*<ArrowDownwardIcon style={{color: 'blue'}}/>*/}
                                                                                                </TimelineDot>
                                                                                                <TimelineConnector />
                                                                                            </TimelineSeparator>
                                                                                            <TimelineContent
                                                                                                sx={{ py: '12px', px: 2 }}>
                                                                                                <Typography variant="p"
                                                                                                    component="span">
                                                                                                    <CurrencyRupeeIcon />{t.amount}
                                                                                                </Typography>
                                                                                                <Typography style={{
                                                                                                    color: t.transaction_type === 'CREDITED' ? 'green' : 'red',
                                                                                                    fontSize: '16px'
                                                                                                }} variant="h6"><CircleIcon
                                                                                                        style={{
                                                                                                            fontSize: '16px',
                                                                                                            marginTop: '-3px',
                                                                                                            marginRight: '4px'
                                                                                                        }} />{t.transaction_type}
                                                                                                </Typography>
                                                                                            </TimelineContent>
                                                                                        </TimelineItem>
                                                                                    </>
                                                                                )) : this.state.team_id && this.state.transaction === null ?
                                                                                    <Loader /> : null}
                                                                            </Timeline>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-lg-none">
                                    <div className="dashOption-mv mt-4 px-2">
                                        <div className="">
                                            <div className="button-bar-mv d-flex">
                                                <Link to='/team' className="btn btn-mv "><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                                {this.props.user.employee_profile === 'admin' ?
                                                <Link to="/team/create-team" className="btn btn-mv "><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create</Link>
                                                :null}
                                                {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                                <Link to="/account/team-account" className="btn btn-mv "><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create Budget</Link>
                                                :null}
                                                {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                                <Link to="/account/team-transactions" className="btn btn-mv active"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Transactions</Link>
                                                :null}
                                            </div>
                                        </div>
                                    </div>
                                            <div className="col-12 d-lg-none m-auto mt-lg-0 mt-4 px-2">
                                                {/*// <!-- Card Basic Info -->*/}
                                                <div className="card mt-4 mb-4" id="basic-info">
                                                    <div className="card-header">
                                                        <h5>Transactions</h5>
                                                    </div>
                                                    {this.state.team_id ?
                                                        <div onClick={(e) => this.setState({ showfilter: this.state.showfilter === true ? false : true })}>
                                                            <FilterListIcon style={{ cursor: "pointer", marginRight: "18", marginTop: "-64", float: "right" }} />
                                                        </div>
                                                        : null}
                                                    {this.state.showfilter === true ?
                                                        <div className="card p-4 mx-2" style={{ width: "96%", position: "absolute", top: "50px", right: "0", zIndex: "10", boxShadow: "0px 0px 6px #bdbbbb" }}>
                                                            <h6>FILTERS</h6>
                                                            {this.state.clear ? <p onClick={this.clearFilter} style={{ color: "#5f5fcb", cursor: "pointer" }}>Clear</p> : null}
                                                            <div className="row p-1">
                                                                <div className="col-12 p-1">
                                                                    <p>Date range</p>
                                                                    <div className="d-flex mt-2 p-1 align-items-center"  >
                                                                        <TextField
                                                                            id="date"
                                                                            label="From"
                                                                            type="date"
                                                                            size="small"

                                                                            onChange={(e) => this.setState({ fromDate: e.target.value })}

                                                                            sx={{ width: 220 }}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                        <p className="mx-4 my-0" style={{ fontSize: "24px" }}>to</p>

                                                                        <TextField
                                                                            id="date"
                                                                            label="To"
                                                                            type="date"
                                                                            size="small"

                                                                            onChange={(e) => this.setState({ toDate: e.target.value })}

                                                                            sx={{ width: 220 }}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 p-1 mt-2">
                                                                    <p>Transactions</p>
                                                                    <div className="form-check-inline">
                                                                        <Box sx={{ minWidth: 220 }}>
                                                                            <FormControl fullWidth>
                                                                                <InputLabel id="demo-simple-select-label">Select transaction-type</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    label="Select Transaction-type"
                                                                                    onChange={(e) => this.setState({ transaction_type: e.target.value })}
                                                                                >
                                                                                    <MenuItem value='ALL' selected={true}>All</MenuItem>
                                                                                    <MenuItem value='CREDITED'>Credited</MenuItem>
                                                                                    <MenuItem value='DEBITED'>Debited</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Box>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-end mt-3">
                                                                <button onClick={this.loadTransactions} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>
                                                            </div>

                                                        </div>
                                                        : null}
                                                    <div className="card-body pt-0">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {this.state.teams ?
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="combo-box-demo"
                                                                        options={this.state.teams}
                                                                        onChange={this.loadTransactions}
                                                                        getOptionLabel={(option) => option.name}
                                                                        // sx={{ width: 300 }}
                                                                        renderOption={(props, option) => (
                                                                            <Box component="li" sx={{
                                                                                '& > img': {
                                                                                    mr: 2,
                                                                                    flexShrink: 0
                                                                                }
                                                                            }} {...props}>
                                                                                <img
                                                                                    style={{ borderRadius: '50%' }}
                                                                                    // loading="lazy"
                                                                                    width="30"
                                                                                    src={option.logo ? url + option.logo : url + '/static/team.png'}
                                                                                    srcSet={`${url + option.logo} 2x`}
                                                                                    alt=""
                                                                                />
                                                                                {option.name}
                                                                            </Box>
                                                                        )}
                                                                        renderInput={(params) =>
                                                                            <TextField {...params}
                                                                                label="Select Team" />}
                                                                    />
                                                                    : null}
                                                            </div>
                                                            <div className="col-12 mt-5 ">
                                                                {/*{this.state.balance ?*/}
                                                                {/*    <p className="m-1 text-center">*/}
                                                                {/*        <CurrencyRupeeIcon/>{this.state.balance}*/}
                                                                {/*    </p>*/}
                                                                {/*    : null}*/}
                                                                <Timeline position="alternate">
                                                                    {this.state.transaction ? this.state.transaction.map((t, index) => (
                                                                        <>
                                                                            <p className="m-1 text-center">
                                                                                <CurrencyRupeeIcon />{t.current_balance}
                                                                            </p>
                                                                            <TimelineItem>
                                                                                <TimelineOppositeContent
                                                                                    sx={{ m: 'auto 0' }}
                                                                                    align="right"
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                >
                                                                                    {new Date(t.time_stamp).toLocaleString('en-US', {
                                                                                        day: 'numeric',
                                                                                        month: 'short',
                                                                                        year: 'numeric',
                                                                                        hour: 'numeric',
                                                                                        minute: 'numeric',
                                                                                        hour12: true
                                                                                    })}
                                                                                </TimelineOppositeContent>
                                                                                <TimelineSeparator>
                                                                                    <TimelineConnector />
                                                                                    <TimelineDot>
                                                                                        {t.transaction_type === 'CREDITED' ?
                                                                                            <img
                                                                                                src={require('../../../custom/icons/down-arrow.png')}
                                                                                                height={25} />
                                                                                            :
                                                                                            <img
                                                                                                src={require('../../../custom/icons/up-arrow.png')}
                                                                                                height={25} />
                                                                                        }

                                                                                        {/*<ArrowDownwardIcon style={{color: 'blue'}}/>*/}
                                                                                    </TimelineDot>
                                                                                    <TimelineConnector />
                                                                                </TimelineSeparator>
                                                                                <TimelineContent
                                                                                    sx={{ py: '12px', px: 2 }}>
                                                                                    <Typography variant="p"
                                                                                        component="span">
                                                                                        <CurrencyRupeeIcon />{t.amount}
                                                                                    </Typography>
                                                                                    <Typography style={{
                                                                                        color: t.transaction_type === 'CREDITED' ? 'green' : 'red',
                                                                                        fontSize: '16px'
                                                                                    }} variant="h6"><CircleIcon
                                                                                            style={{
                                                                                                fontSize: '16px',
                                                                                                marginTop: '-3px',
                                                                                                marginRight: '4px'
                                                                                            }} />{t.transaction_type}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        </>
                                                                    )) : this.state.team_id && this.state.transaction === null ?
                                                                        <Loader /> : null}
                                                                </Timeline>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </>
                                        :
                                        <PageNotFound />
                                    : <InternetConnection />
                                }
                            </div>
                        )}
                    />
                    </>
                    :
                    <Redirect to={'/sign-in'} />
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(TeamTransactions);