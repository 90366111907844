import React from "react";
import {connect} from "react-redux";
import {axios, userLogin} from "../../../store/actions/actions";
import Pagination from '@mui/material/Pagination';
import '../../common/common.css'
import TextField from '@mui/material/TextField';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


class SRDataTable extends React.Component {

    state = {
        samples: this.props.samples.slice(0, 10).map(i => {return i}),
        page: this.props.page,
        quan: this.props.samples.length,
        pageNum: this.props.pageNum,
    }

    componentDidMount() {
        console.log('samples================>', this.state.samples)
        console.log('quan================>', this.state.quan)
        console.log('page================>', this.state.page)
    }

    printTable = () => {
        //Get the HTML of div
        var divElements = document.getElementById('employeeTable').innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML =
          "<html><head><title></title></head><body>" +
          divElements + "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;

    }

    pagination = (e, p) => {
        this.setState({
            pageNum: p
        })
        this.props.pagination(e, p)
    }

  render() {
    return (
        <>
            <div className="row my-3" id="clients">
                  <div className="col-12">
                      <div className="card py-3">
                              <div className="table-responsive px-2">
                                  <div
                                      className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                      <div className="dataTable-top">
                                          <h5 className="mb-0 ps-3" >Submitted Samples</h5>
                                          <div className="d-flex justify-content-end align-items-center" style={{position: "relative"}}>
                                              <LocalPrintshopIcon className='cursor-pointer me-3' onClick={this.printTable}/>
                                              <TextField size="small" id="outlined-basic" label="Search..." variant="outlined" onChange={this.handleSearch}/>
                                          </div>
                                      </div>
                                      <div className="dataTable-container" id='employeeTable'>
                                          <table className="table align-items-center mb-3 dataTable-table">
                                              <thead>
                                              <tr>
                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "4.8032%", cursor: 'pointer'}}>
                                                      <a
                                                      // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                      className="dataTable-sorter">S.NO
                                                  </a></th>
                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("state")}
                                                      >
                                                      <a
                                                      className="dataTable-sorter text-center">
                                                      Book</a></th>

                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("state")}
                                                      >
                                                      <a
                                                      className="dataTable-sorter text-center">
                                                      Class</a></th>
                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("city")}
                                                      >
                                                      <a
                                                      className="dataTable-sorter d-block text-center">
                                                      Quantity</a></th>
                                                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("status")}
                                                      >
                                                      <a
                                                      className="dataTable-sorter">
                                                      Price</a>
                                                  </th>
                                                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("division")}
                                                      >
                                                      <a
                                                      className="dataTable-sorter">
                                                      Date Time</a>
                                                  </th>
                                                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("name")}
                                                  ><a
                                                      className="dataTable-sorter">Employee
                                                  </a></th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {this.state.samples.map((item, index) => (
                                                  index <= this.state.quan ?
                                                  <tr>
                                                      <td>
                                                          <div className="d-flex px-2 py-1">
                                                              <div className="d-flex flex-column justify-content-center">
                                                                  <h6 className="mb-0 font-weight-normal text-sm">{index+1}</h6>
                                                                  {/*<h6 className="mb-0 font-weight-normal text-sm">{index+1+this.state.index_page}</h6>*/}
                                                              </div>
                                                          </div>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.book_name}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.class_name}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.quantity}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.price}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.date}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.employee_name}
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  :null
                                              ))}
                                              </tbody>
                                          </table>
                                      </div>
                                      {this.state.page===1?null:
                                          <div className="datatable-mui-pagination">
                                            <Pagination count={this.state.page} defaultPage={this.state.pageNum} color="secondary" onChange={this.pagination} size="large"/>
                                      </div>}
                                  </div>
                      </div>
                      </div>
      </div>
            </div>
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
    user:state.userData.userData
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SRDataTable);

