import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { connect } from "react-redux";
import { axios, sideBar, userLogin } from "../../store/actions/actions";
import { Link } from "react-router-dom";
import "../../kavi/css/style.css";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    window.addEventListener("scroll", this.scroll, true);
  }
  state = {
    sidebar: true,
    navbar: "block",
    path: null,
    showDrop: false,
  };

  componentDidMount() {
    this.toeknExpiryCheck();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  scroll = () => {
    let top = window.pageYOffset || document.documentElement.scrollTop;
    var element1 = document.getElementById("nav-change-onscroll");
    var element2 = document.getElementById("navbarBlur");
    if (top !== 0) {
      // element1.classList.add("nav-sticky");
      // element2.classList.add("navbarShadow");
    }
    if (top === 0) {
      element1.classList.remove("nav-sticky");
      element2.classList.remove("navbarShadow");
    }
  };

  sideBar = () => {
    if (this.props.sidebar === true) {
      this.props.sideBarHandler({
        sidebar: false,
      });
    } else {
      this.props.sideBarHandler({
        sidebar: true,
      });
    }
  };

  handleCallback = (childData) => {
    this.setState({ action: childData });
  };

  logout = () => {
    window.localStorage.clear();
    this.props.userLoginHandler({ undefined });
  };

  mobileNav = (data) => {
    this.setState({ path: data });
  };

  render() {
    let pathname = window.location.pathname;
    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];
    let path2 = pathurl[pathurl.length - 2];

    return (
      <>
        {/* {this.props.user.login === true ? */}
        {this.props.user.login !== true ? null : (
          <>
            <div
              className="d-none d-lg-block container-fluid nav-sticky homepage-view"
              id="nav-change-onscroll"
            >
              <div className="row">
                {this.props.sidebar === undefined ||
                this.props.sidebar === true ? (
                  <div className="col-2">
                    <Sidebar
                      path={path}
                      path2={path2}
                      parentCallback={this.handleCallback}
                    />
                  </div>
                ) : null}

                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "col-10 nav-change"
                      : "col-12 nav-change"
                  }
                >
                  <nav
                    className="navbar srk-navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 shadow-none border-radius-xl z-index-sticky"
                    id="navbarBlur"
                    data-scroll="true"
                  >
                    <div className="container-fluid d-block d-flex align-items-center justify-content-center py-1 px-3 homepage-view">
                      <nav aria-label="breadcrumb" className="d-none">
                        <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-2 me-3">
                          <h6 className="ms-2 font-weight-bolder mb-0">
                            Profile
                          </h6>
                        </ol>
                      </nav>

                      <div
                        className="offcanvas offcanvas-end w-50 srk-side-menus"
                        tabIndex="-1"
                        id="offcanvasExample"
                        aria-labelledby="offcanvasExampleLabel"
                      >
                        <div className="col-auto">
                          <div className="sidenav-toggler sidenav-toggler-inner d-lg-block d-none">
                            <a
                              href="javascript:void();"
                              className="nav-link text-body p-0"
                            >
                              <div
                                className="sidenav-toggler-inner"
                                onClick={this.sideBar}
                              >
                                <i className="sidenav-toggler-line"></i>
                                <i className="sidenav-toggler-line"></i>
                                <i className="sidenav-toggler-line"></i>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="offcanvas-header">
                          <h5
                            className="offcanvas-title text-light ms-1 font-weight-bold text-white"
                            id="offcanvasExampleLabel"
                          >
                            Employee
                          </h5>
                          <p
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                          ></p>
                        </div>

                        <div className="offcanvas-body">
                          <div className="srk-menus  text-light mx-auto d-md-none  col-12">
                            <ul className="navbar-nav justify-content-start flex-column ">
                              <li className="py-2 nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  dashboard
                                </i>
                                <a
                                  href="/"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Dashboard
                                </a>
                              </li>
                              <li className="py-2 nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  public
                                </i>
                                <Link
                                  to={
                                    path === "public-view"
                                      ? null
                                      : "public-view"
                                  }
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Public View
                                </Link>
                              </li>
                              <li className="py-2 nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  edit
                                </i>
                                <a
                                  href="../srk/editprofile.html"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Edit{" "}
                                </a>
                              </li>
                              <li className="py-2 nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  visibility
                                </i>
                                <a
                                  href="/"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  View{" "}
                                </a>
                              </li>
                              <li className="py-2 nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  add_circle_outline
                                </i>
                                <a
                                  href="/"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Create{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      {/*----------------Dash Nav----------------*/}
                      {path === "" || path === "/" || path === "sign-in" ? (
                        <div className="srk-menu mx-auto  col-9"></div>
                      ) : null}

                      {/*----------------Map Activity Nav----------------*/}
                      {path === "map" ? (
                        <div className="srk-menu mx-auto  col-9"></div>
                      ) : null}

                      {/*----------------Tour plan Activity Nav----------------*/}
                      {path === "tour-plan" ? (
                        <div className="srk-menu mx-auto  col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link to="/" className="ms-1 opacity-5 text-dark">
                                Dashboard
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Employee Nav----------------*/}
                      {path === "employee" ||
                      path === "sign-in" ||
                      path2 === "edit-employee" ||
                      path2 === "edit-employee-admin" ||
                      path === "create-employee" ||
                      path2 === "employee-view" ||
                      path === "public-view" ||
                      path === "view-profile" ? (
                        <div className="srk-menu mx-auto  col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link to="/" className="ms-1 opacity-5 text-dark">
                                Dashboard
                              </Link>
                            </li>
                            {/*<li className="nav-item mx-2 d-flex align-items-center">*/}
                            {/*    <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">*/}
                            {/*        public*/}
                            {/*    </i>*/}
                            {/*    <Link to="public-view" className="ms-1 opacity-5 text-dark">Public View*/}
                            {/*    </Link>*/}
                            {/*</li>*/}

                            {this.props.user.employee_profile === "admin" ? (
                              <li className="nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  edit
                                </i>
                                <Link
                                  to={
                                    "/employee/edit-employee/" +
                                    this.props.user.employee_id
                                  }
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Edit{" "}
                                </Link>
                              </li>
                            ) : null}

                            {this.props.user.employee_profile === "admin" ? (
                              <li className="nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  add_circle_outline
                                </i>
                                <Link
                                  to="/employee/create-employee"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Create
                                </Link>
                              </li>
                            ) : null}
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                add_circle_outline
                              </i>
                              <Link
                                to="/create-station"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Create Station
                              </Link>
                            </li>
                            {this.props.user.employee_profile === "admin" ||
                            this.props.user.employee_profile ===
                              "accountant" ? (
                              <li className="nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  add_circle_outline
                                </i>
                                <Link
                                  to="/employee/employee-transactions"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Transactions
                                </Link>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Teams Nav----------------*/}
                      {path === "team" ||
                      path2 === "edit-team" ||
                      path === "detail-view" ||
                      path === "create-team" ||
                      path2 === "team-view" ||
                      path2 === "project-view" ||
                      path === "team-account" ||
                      path === "team-transactions" ? (
                        <div className="srk-menu mx-auto justify-self-center col-md-9 d-md-block d-none">
                          <ul className="navbar-nav justify-content-start align-items-center">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link to="/team">Dashboard</Link>
                            </li>
                            {this.props.user.employee_profile === "admin" ? (
                              <li className="nav-item mx-2 align-items-center d-flex">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  add_circle_outline
                                </i>
                                <Link to="/team/create-team">Create Team</Link>
                              </li>
                            ) : null}
                            {this.props.user.employee_profile === "admin" ||
                            this.props.user.employee_profile ===
                              "accountant" ? (
                              <li className="nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  add_circle_outline
                                </i>
                                <Link
                                  to="/account/team-account"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Create Budget
                                </Link>
                              </li>
                            ) : null}
                            {this.props.user.employee_profile === "admin" ||
                            this.props.user.employee_profile ===
                              "accountant" ? (
                              <li className="nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  add_circle_outline
                                </i>
                                <Link
                                  to="/account/team-transactions"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Transactions
                                </Link>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Customer Nav----------------*/}
                      {path === "customer" ||
                      path2 === "edit-customer" ||
                      path === "create-customer" ||
                      path2 === "customer-detail" ? (
                        <div className="srk-menu mx-auto col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 align-items-center d-flex">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/customer"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Dashboard
                              </Link>
                            </li>
                            <li className="nav-item mx-2 align-items-center d-flex">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                add_circle_outline
                              </i>
                              {/* {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'executive' ? ( */}
                              <Link
                                to="/customer/create-customer"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Create
                              </Link>
                              {/* ) : null} */}
                            </li>
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Visit Nav----------------*/}
                      {path === "visit" ||
                      path2 === "edit-visit" ||
                      path2 === "visit-view" ||
                      path === "create-visit" ||
                      path === "create-new-visit" ||
                      path === "timeline-visit" ||
                      path === "schedule-visit" ? (
                        <div className="srk-menu mx-auto col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/visit"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Dashboard
                              </Link>
                            </li>
                            {/*<li className="nav-item mx-2 d-flex align-items-center">*/}
                            {/*    <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">*/}
                            {/*        add_circle_outline*/}
                            {/*    </i>*/}
                            {/*    <Link to="/visit/create-visit"*/}
                            {/*       className="ms-1 opacity-5 text-dark">Add School Visit</Link>*/}
                            {/*</li>*/}
                            {this.props.user.employee_profile === "admin" ? (
                              <li className="nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  add_circle_outline
                                </i>
                                <Link
                                  to="/visit/create-new-visit"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Add Visit
                                </Link>
                              </li>
                            ) : null}
                            {this.props.user.employee_profile === "admin" ||
                            this.props.user.employee_profile ===
                              "coordinator" ||
                            this.props.user.employee_profile === "manager" ? (
                              <li className="nav-item mx-2 d-flex align-items-center">
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                  add_circle_outline
                                </i>
                                <Link
                                  to="/visit/schedule-visit"
                                  className="ms-1 opacity-5 text-dark"
                                >
                                  Schedule Visit
                                </Link>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Tada Nav----------------*/}
                      {path === "tada" || path === "create-tada" ? (
                        <div className="srk-menu mx-auto col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/tada"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Dashboard
                              </Link>
                            </li>
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                add_circle_outline
                              </i>
                              <Link
                                to="/tada/create-tada"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Add Tada
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Trip Nav----------------*/}
                      {path === "trip" ||
                      path === "create-trip" ||
                      path === "edit-trip" ||
                      path === "trip-view" ? (
                        <div className="srk-menu mx-auto col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/trip"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Dashboard
                              </Link>
                            </li>

                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                add_circle_outline
                              </i>
                              <Link
                                to="/trip/create-trip"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Add Trip
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Tour Fund Nav----------------*/}
                      {path === "tour" ||
                      path === "request" ||
                      path === "approve" ? (
                        <div className="srk-menu mx-auto col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/tour/request"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Request
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Report Nav----------------*/}
                      {path === "report" ? (
                        <div className="srk-menu mx-auto col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/report"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Dashboard
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Sample Request Nav----------------*/}
                      {path === "sample-request" ||
                      path === "sample" ||
                      path === "view-sample-request" ? (
                        <div className="srk-menu mx-auto col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/sample"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Dashboard
                              </Link>
                            </li>

                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/sample/sample-request"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Request
                              </Link>
                            </li>

                            <li className="nav-item mx-2 d-none align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/sample/view-sample-request"
                                className="ms-1 opacity-5 text-dark"
                              >
                                View Sample Request
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : null}

                      {/*----------------Attendance Nav----------------*/}
                      {path === "attendance" ? (
                        <div className="srk-menu mx-auto col-9">
                          <ul className="navbar-nav justify-content-start ">
                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                dashboard
                              </i>
                              <Link
                                to="/sample-request"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Dashboard
                              </Link>
                            </li>

                            <li className="nav-item mx-2 d-flex align-items-center">
                              <i className="material-icons fixed-plugin-button-nav cursor-pointer px-2">
                                add_circle_outline
                              </i>
                              <Link
                                to="/attendance/punch"
                                className="ms-1 opacity-5 text-dark"
                              >
                                Punch
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : null}

                      <div className="col-md-3">
                        <div
                          className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
                          id="navbar"
                        >
                          <ul
                            className="ms-md-auto  navbar-nav  justify-content-end"
                            style={{ display: "none" }}
                          >
                            <li className="nav-item d-md-none d-block">
                              <a
                                className="nav-link text-body px-3 p-0 position-relative"
                                data-bs-toggle="offcanvas"
                                href="#offcanvasExample"
                                role="button"
                                aria-controls="offcanvasExample"
                              >
                                <i className="material-icons me-sm-1">
                                  more_vert
                                </i>
                              </a>
                            </li>
                            <li className="nav-item">
                              <div
                                onClick={this.logout}
                                className="nav-link text-body p-0 position-relative"
                              >
                                <i className="material-icons me-sm-1">
                                  account_circle
                                </i>
                              </div>
                            </li>
                            <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                              <a
                                href="javascript:;"
                                className="nav-link text-body p-0"
                                id="iconNavbarSidenav"
                              >
                                <div className="sidenav-toggler-inner">
                                  <i className="sidenav-toggler-line"></i>
                                  <i className="sidenav-toggler-line"></i>
                                  <i className="sidenav-toggler-line"></i>
                                </div>
                              </a>
                            </li>
                            <li className="nav-item px-3">
                              <a
                                href="javascript:;"
                                className="nav-link text-body p-0"
                              >
                                <i className="material-icons fixed-plugin-button-nav cursor-pointer">
                                  settings
                                </i>
                              </a>
                            </li>
                            <li className="nav-item dropdown pe-2">
                              <a
                                href="javascript:;"
                                className="nav-link text-body p-0 position-relative"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons cursor-pointer">
                                  notifications
                                </i>
                                <span className="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
                                  <span className="small">11</span>
                                  <span className="visually-hidden">
                                    unread notifications
                                  </span>
                                </span>
                              </a>
                              <ul
                                className="dropdown-menu dropdown-menu-end p-2 me-sm-n4"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <li className="mb-2">
                                  <a
                                    className="dropdown-item border-radius-md"
                                    href="javascript:;"
                                  >
                                    <div className="d-flex align-items-center py-1">
                                      <span className="material-icons">
                                        email
                                      </span>
                                      <div className="ms-2">
                                        <h6 className="text-sm font-weight-normal my-auto">
                                          Check new messages
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li className="mb-2">
                                  <a
                                    className="dropdown-item border-radius-md"
                                    href="javascript:;"
                                  >
                                    <div className="d-flex align-items-center py-1">
                                      <span className="material-icons">
                                        podcasts
                                      </span>
                                      <div className="ms-2">
                                        <h6 className="text-sm font-weight-normal my-auto">
                                          Manage podcast session
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item border-radius-md"
                                    href="javascript:;"
                                  >
                                    <div className="d-flex align-items-center py-1">
                                      <span className="material-icons">
                                        shopping_cart
                                      </span>
                                      <div className="ms-2">
                                        <h6 className="text-sm font-weight-normal my-auto">
                                          Payment successfully completed
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <div className="d-lg-none header-mv py-3">
              <div className="container d-flex align-items-center justify-content-between">
                <div className="profile-mv d-flex align-items-center">
                  <img
                    onClick={(e) =>
                      this.setState({
                        showDrop: this.state.showDrop === false ? true : false,
                      })
                    }
                    className=""
                    src={require("../../kavi/images/profile.png")}
                    alt="Profile Image"
                  />
                  <span
                    className="nav-link-text ms-2 ps-1"
                    style={{ color: "antiquewhite" }}
                  >
                    {this.props.user.employee_full_name}
                  </span>
                </div>
                {this.state.showDrop === true ? (
                  <div className="log-option large-shadow">
                    <ul className="ps-1 py-2 m-0">
                      <li className="option nav-link">My Profile</li>
                      <li className="option nav-link">Settings</li>
                      <li onClick={this.logout} className="option nav-link">
                        Logout
                      </li>
                    </ul>
                  </div>
                ) : null}
                {/* <div className="search-mv d-flex justify-content-center ms-md-auto m-md-2">
          <img className="search-input-icon-mv" src={require("../../kavi/icons/search.png")} alt="search-icon" />
          <input className="form-control  form-control-mv input w-100" type="text" placeholder="Search" />
      </div> */}
                <div className="notfi-sett-mv d-flex align-items-center justify-content-end">
                  <img
                    className="head-icon-mv setting-mv"
                    src={require("../../kavi/icons/setting.png")}
                    alt="icon"
                  />
                  <img
                    className="head-icon-mv notify-mv ps-2"
                    src={require("../../kavi/icons/bell.png")}
                    alt="icon"
                  />
                </div>
              </div>
            </div>

            {/* <------------------Mobile Nav---------------->*/}
            {/*<div className="d-lg-none bottom-nav-mv mb-3">*/}
            {/*    <div className="p-2">*/}
            {/*        <div className="navbox-mv gap-3 flex-nowrap overflow-scroll">*/}
            {/*            <Link to='/' className="menu">*/}
            {/*                <div*/}
            {/*                    className="nav-icon-box d-flex justify-content-center align-items-center flex-column" onClick={(e)=>this.mobileNav('')}>*/}
            {/*                    <i className={this.state.path==='' || path===''?"fa-solid fa-house active-micon":"fa-solid fa-house micon"}></i>*/}
            {/*                    <p className={this.state.path==='' || path===''?"m-0 m-caption active-c":"m-0 m-caption"}>Home</p>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*            <Link to='/team' className="menu">*/}
            {/*                <div*/}
            {/*                    className="nav-icon-box d-flex justify-content-center align-items-center flex-column"  onClick={(e)=>this.mobileNav('team')}>*/}
            {/*                    <i className={this.state.path==='team' || path==='team'?"fa-solid fa-people-group active-micon":"fa-solid fa-people-group micon"}></i>*/}
            {/*                    <p className={this.state.path==='team' || path==='team'?"m-0 m-caption active-c":"m-0 m-caption"}>Team</p>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*            <Link to='/customer' className="menu">*/}
            {/*                <div*/}
            {/*                    className="nav-icon-box d-flex justify-content-center align-items-center flex-column"onClick={(e)=>this.mobileNav('customer')}>*/}
            {/*                    <i className={this.state.path==='customer' || path==='customer'?"fa-solid fa-crosshairs active-micon":"fa-solid fa-crosshairs micon"}></i>*/}
            {/*                    <p className={this.state.path==='customer' || path==='customer'?"m-0 m-caption active-c":"m-0 m-caption"}>Customer</p>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*            <Link to='/visit' className="menu">*/}
            {/*                <div*/}
            {/*                    className="nav-icon-box d-flex justify-content-center align-items-center flex-column"onClick={(e)=>this.mobileNav('visit')}>*/}
            {/*                    <i className={this.state.path==='visit' || path==='visit'?"fa-regular fa-flag active-micon":"fa-regular fa-flag micon"}></i>*/}
            {/*                    <p className={this.state.path==='visit' || path==='visit'?"m-0 m-caption active-c":"m-0 m-caption"}>Visit</p>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*            <Link to='/trip' className="menu">*/}
            {/*                <div*/}
            {/*                    className="nav-icon-box d-flex justify-content-center align-items-center flex-column"onClick={(e)=>this.mobileNav('trip')}>*/}
            {/*                    <i className={this.state.path==='trip' || path==='trip'?"fa-solid fa-road active-micon":"fa-solid fa-road micon"}></i>*/}
            {/*                    <p className={this.state.path==='trip' || path==='trip'?"m-0 m-caption active-c":"m-0 m-caption"}>Check In</p>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*            <Link to='/tada' className="menu">*/}
            {/*                <div*/}
            {/*                    className="nav-icon-box d-flex justify-content-center align-items-center flex-column" onClick={(e)=>this.mobileNav('tada')}>*/}
            {/*                    <i className={this.state.path==='tada' || path==='tada'?"fa-solid fa-bag-shopping active-micon":"fa-solid fa-bag-shopping micon"}></i>*/}
            {/*                    <p className={this.state.path==='tada' || path==='tada'?"m-0 m-caption active-c":"m-0 m-caption"}>Tada</p>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*            <Link to='/attendance' className="menu">*/}
            {/*                <div*/}
            {/*                    className="nav-icon-box d-flex justify-content-center align-items-center flex-column" onClick={(e)=>this.mobileNav('attendance')}>*/}
            {/*                    <i className={this.state.path==='attendance' || path==='attendance'?"fa-solid fa-circle-info active-micon":"fa-solid fa-circle-info micon"}></i>*/}
            {/*                    <p className={this.state.path==='attendance' || path==='attendance'?"m-0 m-caption active-c":"m-0 m-caption"}>Attendance</p>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*            <Link to='/tour' className="menu">*/}
            {/*                <div*/}
            {/*                    className="nav-icon-box d-flex justify-content-center align-items-center flex-column" onClick={(e)=>this.mobileNav('tour')}>*/}
            {/*                    <i className={this.state.path==='tour' || path==='tour'?"fa-solid fa-circle-info active-micon":"fa-solid fa-circle-info micon"}></i>*/}
            {/*                    <p className={this.state.path==='tour' || path==='tour'?"m-0 m-caption active-c":"m-0 m-caption"}>Tour</p>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*            <Link to='/sample' className="menu">*/}
            {/*                <div*/}
            {/*                    className="nav-icon-box d-flex justify-content-center align-items-center flex-column" onClick={(e)=>this.mobileNav('sample')}>*/}
            {/*                    <i className={this.state.path==='sample' || path==='sample'?"fa-solid fa-circle-info active-micon":"fa-solid fa-circle-info micon"}></i>*/}
            {/*                    <p className={this.state.path==='sample' || path==='sample'?"m-0 m-caption active-c":"m-0 m-caption"}>Sample Request</p>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="d-lg-none bottom-nav-mv">
              <div className="rs-btn-box py-3" id="rs-btn-box">
                <div className="left d-flex justify-content-around">
                  {/* Bottom Menu  */}
                  <Link to="/">
                    <div
                      className="d-flex flex-column align-items-center justify-content-center"
                      onClick={(e) => this.mobileNav("")}
                    >
                      <i
                        className={
                          path === ""
                            ? "rs-icon rs-active fa-solid fa-house active-micon"
                            : "rs-icon fa-solid fa-house active-micon"
                        }
                      ></i>
                      <p
                        className={
                          path === ""
                            ? "rs-text rs-active m-0 p-0"
                            : "rs-text m-0 p-0"
                        }
                      >
                        Home
                      </p>
                    </div>
                  </Link>
                  <Link to="/trip">
                    <div
                      className="d-flex flex-column align-items-center justify-content-center"
                      onClick={(e) => this.mobileNav("trip")}
                    >
                      <i
                        className={
                          path === "trip" || path === "create-trip"
                            ? "rs-icon rs-active fa-solid fa-crosshairs micon"
                            : "rs-icon fa-solid fa-crosshairs micon"
                        }
                      ></i>
                      <p
                        className={
                          path === "trip" || path === "create-trip"
                            ? "rs-text rs-active m-0 p-0"
                            : "rs-text m-0 p-0"
                        }
                      >
                        Check In
                      </p>
                    </div>
                  </Link>
                  <Link to="/visit">
                    <div
                      style={{ left: "12px" }}
                      className="d-flex flex-column position-relative align-items-center justify-content-center"
                      onClick={(e) => this.mobileNav("visit")}
                    >
                      <i
                        className={
                          path === "visit" || path === "create-new-visit"
                            ? "rs-icon rs-active fa-regular fa-flag micon"
                            : "rs-icon fa-regular fa-flag micon"
                        }
                      ></i>
                      <p
                        className={
                          path === "visit" || path === "create-new-visit"
                            ? "rs-text rs-active m-0 p-0"
                            : "rs-text m-0 p-0"
                        }
                      >
                        Visit
                      </p>
                    </div>
                  </Link>
                  <Link to="/attendance">
                    <div
                      className="d-flex flex-column align-items-center justify-content-center"
                      onClick={(e) => this.mobileNav("attendance")}
                    >
                      <i
                        className={
                          path === "attendance" || path === "punch"
                            ? "rs-icon rs-active fa-solid fa-people-group micon"
                            : "rs-icon fa-solid fa-people-group micon"
                        }
                      ></i>
                      <p
                        className={
                          path === "attendance" || path === "punch"
                            ? "rs-text rs-active m-0 p-0"
                            : "rs-text m-0 p-0"
                        }
                      >
                        Attendance
                      </p>
                    </div>
                  </Link>
                  <div
                    className="rs-pop"
                    onClick={(e) =>
                      this.state.show_menu === true
                        ? this.setState({ show_menu: false })
                        : this.setState({ show_menu: true })
                    }
                  >
                    <i className="rs-icon-badge fa-solid fa-road micon"></i>
                  </div>
                </div>
              </div>
            </div>

            {this.state.show_menu === true ? (
              <div className="d-lg-none bottom-nav-mv menu-bar">
                <div
                  className="rs-btn-box py-1"
                  id="rs-btn-box"
                  style={{ transition: "5s ease" }}
                >
                  <div className="head pt-2 d-flex justify-content-around">
                    {/*<p></p>*/}
                    <p className="fw-bold">Quick Actions</p>
                    <p
                      className="fw-lighter"
                      onClick={(e) => this.setState({ show_menu: false })}
                    >
                      Cancel
                    </p>
                  </div>
                  <div className="option my-5">
                    {/*<p className="ps-3 rs-head">Pre-approve Entry</p>*/}
                    <div className="left gap-3 flex-wrap d-flex justify-content-around">
                      {this.props.user.employee_profile !== "executive" ? (
                        <>
                          <Link to="/employee">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              onClick={(e) =>
                                this.setState({ show_menu: false })
                              }
                            >
                              <i className="rs-icon fa-solid fa-house micon"></i>
                              <p className="rs-text m-0 p-0">Employee</p>
                            </div>
                          </Link>
                          <Link to="/customer">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              onClick={(e) =>
                                this.setState({ show_menu: false })
                              }
                            >
                              <i className="rs-icon fa-solid fa-crosshairs micon"></i>
                              <p className="rs-text m-0 p-0">Customer</p>
                            </div>
                          </Link>
                        </>
                      ) : null}

                      <Link to="/team">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          onClick={(e) => this.setState({ show_menu: false })}
                        >
                          <i className="rs-icon fa-solid fa-people-group micon"></i>
                          <p className="rs-text m-0 p-0">Team</p>
                        </div>
                      </Link>
                      <Link to="/visit">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          onClick={(e) => this.setState({ show_menu: false })}
                        >
                          <i className="rs-icon fa-regular fa-flag micon"></i>
                          <p className="rs-text m-0 p-0">Visit</p>
                        </div>
                      </Link>
                      <Link to="/trip">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          onClick={(e) => this.setState({ show_menu: false })}
                        >
                          <i className="rs-icon fa-solid fa-crosshairs micon"></i>
                          <p className="rs-text m-0 p-0">Check In</p>
                        </div>
                      </Link>
                      <Link to="/srf">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          onClick={(e) => this.setState({ show_menu: false })}
                        >
                          <i className="rs-icon fa-solid fa-crosshairs micon"></i>
                          <p className="rs-text m-0 p-0">SRF</p>
                        </div>
                      </Link>
                      {this.props.user.employee_profile !== "executive" ? (
                        <>
                          <Link to="/tada" className="d-none">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              onClick={(e) =>
                                this.setState({ show_menu: false })
                              }
                            >
                              <i className="rs-icon fa-solid fa-people-group micon"></i>
                              <p className="rs-text m-0 p-0">Ta/Da</p>
                            </div>
                          </Link>
                          <Link to="/sample">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              onClick={(e) =>
                                this.setState({ show_menu: false })
                              }
                            >
                              <i className="rs-icon fa-regular fa-flag micon"></i>
                              <p className="rs-text m-0 p-0">Sample Request</p>
                            </div>
                          </Link>
                          <Link to="/tour" className="d-none">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              onClick={(e) =>
                                this.setState({ show_menu: false })
                              }
                            >
                              <i className="rs-icon fa-solid fa-crosshairs micon"></i>
                              <p className="rs-text m-0 p-0">Tour Fund</p>
                            </div>
                          </Link>
                          <Link to="/tour-plan">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              onClick={(e) =>
                                this.setState({ show_menu: false })
                              }
                            >
                              <i className="rs-icon fa-solid fa-crosshairs micon"></i>
                              <p className="rs-text m-0 p-0">Tour Plan</p>
                            </div>
                          </Link>
                          <Link to="/report" className="d-none">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              onClick={(e) =>
                                this.setState({ show_menu: false })
                              }
                            >
                              <i class="fa-solid rs-icon  fa-book"></i>
                              <p className="rs-text m-0 p-0">Report</p>
                            </div>
                          </Link>
                        </>
                      ) : null}
                      <Link to="/tour-plan">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          onClick={(e) => this.setState({ show_menu: false })}
                        >
                          <i className="rs-icon fa-solid fa-crosshairs micon"></i>
                          <p className="rs-text m-0 p-0">Tour Plan</p>
                        </div>
                      </Link>
                      <Link to="/attendance">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          onClick={(e) => this.setState({ show_menu: false })}
                        >
                          <i className="rs-icon fa-solid fa-people-group micon"></i>
                          <p className="rs-text m-0 p-0">Attendance</p>
                        </div>
                      </Link>
                    </div>
                    <div className="left  d-flex justify-content-around"></div>
                  </div>

                  {this.props.user.username === "@user907" ? (
                    <div className="option mt-5 mb-2">
                      <div className="left d-flex justify-content-around">
                        <Link to="/find">
                          <div
                            className="d-flex flex-column align-items-center justify-content-center"
                            onClick={(e) => this.setState({ show_menu: false })}
                          >
                            <i className="rs-icon fa-solid fa-crosshairs micon"></i>
                            <p className="rs-text m-0 p-0">Find</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
  user: state.userData.userData,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
