import React from "react";
import {axios, sideBar, url, userLogin} from "../../../store/actions/actions";
import { connect } from "react-redux";
// import '../../../kavi/css/material-dashboard.css'
import '../../../kavi/css/style.css'

class DataCards extends React.Component {

    state = {
        all_emp: null,
        manager_emp: null,
        admin_emp: null,
        executive_emp: null,

        new_emp: null,
        new_admin: null,
        new_executive: null,
        new_manager: null
    }

    componentDidMount() {
        this.toeknExpiryCheck()
        this.loadDataCardsWithAxios()
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

    loadDataCardsWithAxios = () => {
        let endpoint = `employee/data-cards`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                // console.log({'response data---------':responseData})
                this.setState({ all_emp: responseData.all_emp })
                this.setState({ admin_emp: responseData.admin_emp })
                this.setState({ manager_emp: responseData.manager_emp })
                this.setState({ executive_emp: responseData.executive_emp })
                this.setState({ new_executive: responseData.new_executive })
                this.setState({ new_manager: responseData.new_manager })
                this.setState({ new_admin: responseData.new_admin })
                this.setState({ new_emp: responseData.new_emp })
            })
    }

    render() {
        return (
            <>
                <div className="d-lg-none ">
                    <div className="row flex-nowrap overflow-scroll">
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#45454B" }} className="d-none panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>All Employee</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.state.all_emp}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }} className="line-truncate-twoline">
                                    <span>+{this.state.new_emp} </span>employee’s added this month
                                </p>

                            </div>
                        </div>
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#D34471" }} className="d-none panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Admin</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.state.admin_emp}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }} className="line-truncate-twoline">
                                    <span>+{this.state.new_admin} </span>Admin employee’s added this
                                    month</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#5796E8" }} className="d-none panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between  align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Manager</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.state.manager_emp}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }}><span>+{this.state.new_manager} </span>Manager employee’s added
                                    this month</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#64A459" }} className="d-none panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between  align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Executive</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.state.executive_emp}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }}><span>+{this.state.new_executive} </span>Executive employee’s added
                                    this month</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-none d-lg-block ">
                    <div className="row mt-3">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">weekend</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">All Employee</p>
                                        <h4 className="mb-0">{this.state.all_emp}</h4>
                                    </div>
                                </div>
                                <hr className="dark horizontal my-0" />
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.state.new_emp} </span>
                                        employee's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-0 mt-4">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">leaderboard</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Admin</p>
                                        <h4 className="mb-0">{this.state.admin_emp}</h4>
                                    </div>
                                </div>
                                <hr className="dark horizontal my-0" />
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.state.new_admin} </span>
                                        admin employee's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">store</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Manager</p>
                                        <h4 className="mb-0">{this.state.manager_emp}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark" />
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.state.new_manager} </span>
                                        manager employee's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                            <div className="card">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">person_add</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Executive</p>
                                        <h4 className="mb-0">{this.state.executive_emp}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark" />
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.state.new_executive} </span>
                                        manager employee's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar
})

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DataCards)
