import React from "react";
import { connect } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import PageNotFound from "../../common/PageNotFound";
import { Offline, Online } from "react-detect-offline";
import InternetConnection from "../../common/InternetConnection";
import DataCards from "../EmployeeDash/DataCards";
import TourRequestDataTable from "./TourRequestDataTable";


class TourFundDash extends React.Component {

  state = {

  }

  render() {
    return (
      <>
        {this.props.user.login === true ?
          <>
            <Online>
              {this.props.user.login ?
                <>
                  <div className="container-fluid homepage-view d-none d-lg-block">
                    <div className="row justify-content-end">
                      <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-10" : "col-12"}>
                        <DataCards />
                        <TourRequestDataTable />
                      </div>
                    </div>
                  </div>
                  <div className="px-2 d-lg-none">
                        <div className="dashOption-mv mt-4 ">
            <div className="">
                <div className="dashOption-mv mt-4">
                        <div className="">
                            <div className="button-bar-mv d-flex">
                                <Link to='/tour' className="btn btn-mv active"><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                <Link to="/tour/request" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Request</Link>
                            </div>
                        </div>
                </div>
            </div>
        </div>
                        <DataCards />
                        <TourRequestDataTable />
                  </div>
                </>
                :
                <PageNotFound />
              }
            </Online>
            <Offline>
              <InternetConnection />
            </Offline>
          </>
          :
          <Redirect to={'/sign-in'} />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(TourFundDash);
