import React from "react";
import MarkedLocations from "../../GoogleMap/MarkedLocations";
import {axios} from "../../../store/actions/actions";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";


class ProjectView extends React.Component {

    state = {
        station: null,
        marker: null
    }

    componentDidMount() {
        this.loadStation()
    }

    getMarkers = (station) => {
        const array = []
        station.map((station, index)=>(
          station.customer?station.customer.map((cust, index)=>(
              array.push({lat: cust.address_by_location?cust.address_by_location.geolocation.split(",")[0]:null, lng: cust.address_by_location?cust.address_by_location.geolocation.split(",")[1]:null})
          )):null
        ))

        this.setState({marker: array, show: true})
    }

    loadStation = () => {
        let pathname = window.location.pathname;
        let pathurl = pathname.split("/");
        let path = pathurl[pathurl.length - 1];

        let endpoint = `common/station/${path}`
        let token = this.props.user.token
            axios
                .get(endpoint, token)
                .then((res)=>{
                    let responseData = res.data;
                    this.setState({
                        station: responseData.station,
                    })
                    this.getMarkers(responseData.station)

                    console.log({'Station response data---------':responseData})
                })
    }
  render() {
    return (
            <>
            {this.props.user.login===true?
                <>
                    <div className="d-lg-block d-none">
                        <div className="container-fluid">
                            <div className="row justify-content-end">
                              <div
                                className={
                                  this.props.sidebar === undefined || this.props.sidebar === true
                                    ? "col-10"
                                    : "col-12"
                                }
                              >
                                  <h2>Marked Customers In Map</h2>
                                  <MarkedLocations station={this.state.station} marker={this.state.marker}/>
                              </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-lg-none mt-4">
                        <div className="container-fluid">
                            <div className="row justify-content-end">
                              <div>
                                  <h2>Marked Customers In Map</h2>
                                  <MarkedLocations station={this.state.station} marker={this.state.marker}/>
                              </div>
                            </div>
                        </div>
                    </div>
                </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>
    );
  }
}

const mapStateToProps = (state) => ({
    user:state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(ProjectView);
