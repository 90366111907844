import React, { useEffect, useRef } from 'react';
import { Map } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

function LatLng(props) {
  const mapRef = useRef();

  useEffect(() => {
    getLatLng()
  });

  const getLatLng = (() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    map.locate({
      setView: true,
    });

    map.on('locationfound', handleOnLocationFound);
  })

  function handleOnLocationFound(event) {
    props.getLatLng(event.latlng)
  }

  return (
          <Map ref={mapRef} style={{display: 'none'}}/>
    );
}

export default LatLng;
