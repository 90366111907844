import React from "react";
import {axios, sideBar, userLogin} from "../../../store/actions/actions";
import {connect} from "react-redux";

class CustomerDataCard extends React.Component {

    state = {
        all_samples: null,
        approved_samples: null,
        pending_samples: null,
        declined_samples: null
    }

    componentDidMount() {
        this.loadDataCards()
        this.toeknExpiryCheck()
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

    loadDataCards = () => {
        let endpoint = `books/data-cards`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res)=>{
                let responseData = res.data;
                console.log({'response data--------->':responseData})
                this.setState({all_samples: responseData.all_samples})
                this.setState({approved_samples: responseData.approve_samples})
                this.setState({pending_samples: responseData.pending_samples})
                this.setState({declined_samples: responseData.declined_samples})
            })
    }

    render() {
        return(
            <>
                    <div className="d-none d-lg-block">
                    <div className="row mt-3">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">weekend</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">All Samples</p>
                                        <h4 className="mb-0">{this.state.all_samples}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark"/>
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+0 </span>sellers added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-0 mt-4">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">leaderboard</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Approved</p>
                                        <h4 className="mb-0">{this.state.approved_samples}</h4>
                                    </div>
                                    <hr className="horizontal my-0 dark"/>
                                    <div className="card-footer p-3">
                                        <p className="mb-0">
                                            <span className="text-success text-sm font-weight-bolder">+0 </span>sellers added this month
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">store</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Pending</p>
                                        <h4 className="mb-0">{this.state.pending_samples}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark"/>
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+0 </span>sellers added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                            <div className="card">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">person_add</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Rejected</p>
                                        <h4 className="mb-0">0</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark"/>
                                <div className="card-footer p-3">
                                    <p className="mb-0">Just updated</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                <div className="d-lg-none ">
                    <div className="row">
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#45454B" }} className="panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>All Samples</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.state.all_samples}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }} className="line-truncate-twoline">
                                    <span>+0 </span>samples added this month
                                </p>

                            </div>
                        </div>
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#D34471" }} className="panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Approved</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.state.approved_samples}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }} className="line-truncate-twoline">
                                    <span>+0 </span>Samples approved this
                                    month</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#5796E8" }} className="panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between  align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Pending</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.state.pending_samples}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }}><span>+0 </span>Samples pending
                                    this month</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#64A459" }} className="panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between  align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Rejected</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.state.declined_samples}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }}><span>+0 </span>Rejected samples
                                    this month</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state =>({
    user:state.userData.userData,
    sidebar:state.sideBarData.sidebarData.sidebar
})

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDataCard)