import React from "react";
import { axios } from "../../../store/actions/actions";
import { connect } from "react-redux";
// import '../../../kavi/css/material-dashboard.css'
import '../../../kavi/css/style.css'
import TourIcon from '@mui/icons-material/Tour';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import Loadingfade from "../../common/Loadingfade";

class VisitDataCards extends React.Component {

    render() {

        return (
            <>
                <div className="d-lg-none ">
                    <div className="row flex-nowrap overflow-scroll">
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#45454B" }} className="d-none panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                    <TourIcon style={{ fontSize: 40 }} />
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>All Visits</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.props.data.all_visits}</p>
                                </div>
                                

                            </div>
                        </div>
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#D34471" }} className="d-none panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                    <ScheduleSendIcon  style={{ fontSize: 40 }}/>
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Incomplete Visits</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.props.data.incomp_visits}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#64A459" }} className="d-none panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                    <DepartureBoardIcon style={{fontSize:40}} />
                                <div className="panel-caption-mv d-flex justify-content-between  align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Today Visits</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.props.data.today_visits}</p>
                                </div>
                                {/*<p style={{ fontSize: "10px", marginBottom: "0" }}><span>+{this.props.data.new_executive} </span>Executive employee’s added*/}
                                {/*    this month</p>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-none d-lg-block ">
                    <div className="row mt-3">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">weekend</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">All Visit</p>
                                        <h4 className="mb-0">{this.props.data.all_visits}</h4>
                                    </div>
                                </div>
                                <hr className="dark horizontal my-0" />
                                <div className="card-footer p-3">
                                    {/*<p className="mb-0">*/}
                                    {/*    <span className="text-success text-sm font-weight-bolder">+{this.props.data.new_visit} </span>*/}
                                    {/*    visit's added this month*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 mt-sm-0 mt-4">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">leaderboard</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Incomplete Visits</p>
                                        <h4 className="mb-0">{this.props.data.incomp_visits}</h4>
                                    </div>
                                </div>
                                <hr className="dark horizontal my-0" />
                                <div className="card-footer p-3">
                                    {/*<p className="mb-0">*/}
                                    {/*    <span className="text-success text-sm font-weight-bolder">+{this.props.data.new_school_visit} </span>*/}
                                    {/*    new school visit's added this month*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 mt-lg-0 mt-4">
                            <div className="card">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">person_add</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Today Visits</p>
                                        <h4 className="mb-0">{this.props.data.today_visits}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark" />
                                <div className="card-footer p-3">
                                    {/*<p className="mb-0">*/}
                                    {/*    <span className="text-success text-sm font-weight-bolder">+{this.props.data.new_executive} </span>*/}
                                    {/*    manager employee's added this month*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar
})

export default connect(mapStateToProps)(VisitDataCards)