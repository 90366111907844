import {SIDEBAR, USER_LOGIN} from '../constants'

const sideBarState = {
    sidebarData: []
}

const userState = {
    userData: []
}

export function sideBarData(state = sideBarState, action) {
    switch (action.type) {
        case SIDEBAR:
            return {
                ...state,
                sidebarData: action.data
            }
        default:
            return state
    }
}

export function userData(state = userState, action) {
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                userData: action.data
            }
        default:
            return state
    }
}
