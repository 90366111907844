import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  borderRadius:"10px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function VModal(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const { alerts } = props;

  return (
    <React.Fragment>
      <Modal
        style={{ zIndex: 1000000 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: "auto" }}>
          <h2 id="child-modal-title">Please Enter Correct Details</h2>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">
              {props.userMessage}
            </Alert>
          </Stack>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default VModal;
