import React from "react";
import { connect } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {axios, sideBar, userLogin} from "../../../store/actions/actions";
import TextField from "@mui/material/TextField";
import Select from "react-select";


class Punch2 extends React.Component {

    state = {
        input: true,
        f_coords: null,
        m_coords: null,
        dis: null,
        f_acc: null,
        m_acc: null,
    }

    sendLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.punch)
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    punch = (position) => {
        console.log('pos---------------------->', position)

        this.setState({
            m_acc: position.coords.accuracy,
            m_coords: `${position.coords.latitude},${position.coords.longitude}`
        })

        const data = {
            f_coords: this.state.f_coords,
            m_coords: `${position.coords.latitude},${position.coords.longitude}`
        }
        let endpoint = `customer/get-distance`;

        axios
            .post(endpoint, data)
            .then((res) => {
                let responseData = res.data;
                console.log('Dis-------------->', responseData)
                this.setState({
                    dis: responseData.distance
                })
            });
    }

    start = () => {
        if (this.state.f_coords!==null) {
            this.setState({input: false})
            this.sendLocation()
        }
    }

    render() {
        return (
            <>
                {this.props.user.login === true ?
                    <>
                        {this.state.input === true ?
                            <div >
                            <div className="row mt-4 align-items-center"
                                 style={{width: "100%", maxWidth: "100%", marginInline: "auto", marginBottom: "15px"}}>
                                <div className="col-8">
                                    <div className="d-flex mt-2 p-1 align-items-center">
                                        <TextField id="standard-basic" label="Coords..." variant="standard"
                                                   onChange={(e) => this.setState({f_coords: e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="d-flex mt-2 p-1 align-items-center">
                                        <TextField id="standard-basic" label="Accuracy" variant="standard"
                                                   onChange={(e) => this.setState({f_acc: e.target.value})}/>
                                    </div>
                                </div>
                            </div>
                            </div>
                            :
                            <div className='text-center mt-5'><h4>Distance: {this.state.dis}</h4></div>
                        }


                        {/*{this.state.status==='done'?null:*/}
                            <div className="row mt-5">
                           <div className="punch-btn col-12 mt-5">
                               <button onClick={this.start} id="bigbutton"><span>Locate</span></button>
                           </div>
                        </div>
                        {/*}*/}

                        <div className="col-12 p-3 mb-5 mt-4">
                  <div className="card py-3">
                      <div className="table-responsive px-2">
                          <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                              <div className="dataTable-container"
                                   // style={{height: "580.641px"}}
                              >
                                  <table className="table align-items-center mb-3 dataTable-table" id="empe">
                                      <thead>

                                          <tr>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter">Type
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Coords
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Accuracy
                                              </a></th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                              <tr>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            K
                                                        </h6>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {this.state.f_coords}
                                                        </h6>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {this.state.f_acc}
                                                        </h6>
                                                      </div>
                                                  </td>
                                              </tr>
                                      </tbody>
                                      <tbody>
                                              <tr>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            N
                                                        </h6>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {this.state.m_coords}
                                                        </h6>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {this.state.m_acc}
                                                        </h6>
                                                      </div>
                                                  </td>
                                              </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

                    </>
                    :
                    <Redirect to={'/sign-in'} />
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
})

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Punch2)