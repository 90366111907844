import React from "react";
import { connect } from "react-redux";
import { axios, userLogin, modalStyle } from "../../../store/actions/actions";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader/Loader";
import Pagination from "@mui/material/Pagination";
import "../../common/common.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextField from "@mui/material/TextField";
import { Multiselect } from "multiselect-react-dropdown";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

class AttendanceTable extends React.Component {
    state = {
        attendance: null,
        quan: null,
        total: null,
        filterAttendance: null,

        // sort
        nameSort: null,
        stateSort: null,
        stationSort: null,
        citySort: null,
        divisionSort: null,

        // pagination
        page: null,

        open: false,
        isReversed: false,
        isAscending: true,

        searchText: null
    };

    style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    componentDidMount() {
        this.loadAttendance();
    }

    loadAttendance = () => {
        this.setState({ open: false });
        let endpoint = `employee/attendance-data-table`;
        let token = this.props.user.token;

        axios.get(endpoint, token).then((response) => {
            const responseData = response.data;
            console.log({
                "Employee attendance response data-------------->": responseData,
            });
            this.setState({ attendance: responseData.attendance });
            this.setState({ filterAttendance: responseData.attendance });
            this.setState({ quan: responseData.count });
            this.setState({ page: responseData.page });
            this.setState({ total: responseData.count });
        });

        console.log(this.state.filterAttendance, "+-+-+-+-+-");
    };

    sortPunchIn = (data) => {
        this.setState((prevState) => ({
            filterAttendance: [...prevState.filterAttendance].reverse(),
            // Toggle the isReversed flag
            isReversed: !prevState.isReversed,
        }));
    };

    sortPunchOut = (data) => {
        this.setState((prevState) => ({
            filterAttendance: [...prevState.filterAttendance].reverse(),
            // Toggle the isReversed flag
            isReversed: !prevState.isReversed,
        }));
    };

    sortEmployee = () => {
        this.setState((prevState) => ({
            filterAttendance: [...prevState.filterAttendance].sort((a, b) => {
                if (prevState.isAscending) {
                    return a.employee_name.localeCompare(b.employee_name); // Ascending order
                } else {
                    return b.employee_name.localeCompare(a.employee_name); // Descending order
                }
            }),
            isAscending: !prevState.isAscending, // Toggle the sorting order
        }));
    };

    handlePage = (e, p) => {
        console.log("--------------------", p);
        const data = {
            from: p === 1 ? 0 : (p - 1) * 10,
            to: p === 1 ? 10 : p * 10,
        };
        console.log("--------------------", data);
        let endpoint = `employee/attendance-pagination`;
        let token = this.props.user.token;

        axios.post(endpoint, data, token).then((response) => {
            const responseData = response.data;
            this.setState({ filterAttendance: responseData.attendance });
            console.log({ "Pagination response data---------": responseData });
        });
    };

    printTable = () => {
        //Get the HTML of div
        var divElements = document.getElementById("employeeTable").innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML =
            "<html><head><title></title></head><body>" + divElements + "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;
    };


    // ************************ Search Filter ***********************
    // Search Input 
    inputChangeHandler = (e) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    // Clear Input Field When click on Clear button
    clearSearchInputHandler = (e) => {
        this.setState({
            searchText: "",
            attendance: null,
            filterAttendance: null,
        });
        this.loadAttendance();
        console.log("Chal To rha hai bhai", e.target.value);
    };



    // New Handle Seach 
    handleSearchButton = () => {
        console.log("first")
        this.setState({
            attendance: null,
            filterAttendance: null,
        });

        let data = {
            searchText: this.state.searchText
        }

        this.setState({ open: false });
        let endpoint = `employee/search-attendance-by-date`;
        let token = this.props.user.token;

        axios.post(endpoint, data, token).then((response) => {
            const responseData = response.data;
            console.log({
                "Employee attendance response data-------------->": responseData,
            });
            this.setState({ attendance: responseData.attendance });
            this.setState({ filterAttendance: responseData.attendance });
            this.setState({ quan: responseData.count });
            this.setState({ page: responseData.page });
            this.setState({ total: responseData.count });
        });

        this.setState({
            searchText:null
        })
        console.log(this.state.filterAttendance, "+-+-+-+-+-");
    };


    render() {
        return (
            <>
                <div className="row my-3 px-2" id="clients">
                    <div className="col-12">
                        <div className="card py-3">
                            {this.state.attendance ? (
                                <div className="table-responsive px-2">
                                    <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                        <div className="dataTable-top">
                                            <div className="d-flex gap-3 w-100 justify-content-between align-items-center">
                                                <div className="d-flex gap-3">
                                                    <h5 className="mb-0">Attendance</h5>
                                                    <LocalPrintshopIcon
                                                        className="cursor-pointer me-3"
                                                        onClick={this.printTable}
                                                    />
                                                </div>
                                                {this.state.searchText !== "" &&
                                                    this.state.searchText !== null ? (

                                                    <ClearIcon onClick={this.clearSearchInputHandler} />
                                                ) : null}
                                            </div>


                                            <div
                                                className=" d-flex w-100 gap-3 justify-content-center align-items-stretch"
                                                style={{ position: "relative" }}
                                            >

                                                {/* <TextField
                                                    label="Date and Time"
                                                    type="date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event, value) => {
                                                        // console.log(event.target.value, "++++++++++++++++",value);
                                                        this.setState({
                                                            searchText: event.target.value,
                                                        });
                                                    }}
                                                    required
                                                /> */}

                                                <TextField
                                                    size="small"
                                                    id="outlined-basic"
                                                    label="Search by date..."
                                                    variant="outlined"
                                                    onChange={this.inputChangeHandler}
                                                />

                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DateCalendar />
                                                </LocalizationProvider> */}

                                                <Button variant="contained" onClick={this.handleSearchButton}>
                                                    Search
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="dataTable-container" id="employeeTable">
                                            <table className="table text-center align-items-center mb-3 dataTable-table">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                            data-sortable=""
                                                            style={{ width: "20%", cursor: "pointer" }}
                                                            onClick={(e) => this.sortEmployee("name")}
                                                        >
                                                            <a className="dataTable-sorter">Employee</a>
                                                        </th>
                                                        <th
                                                            className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                            data-sortable=""
                                                            style={{ width: "20%", cursor: "pointer" }}
                                                            onClick={(e) => this.sortPunchIn("state")}
                                                        >
                                                            <a className="dataTable-sorter text-center">
                                                                Punch-In
                                                            </a>
                                                        </th>
                                                        <th
                                                            className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                            data-sortable=""
                                                            style={{ width: "20%", cursor: "pointer" }}
                                                            onClick={(e) => this.sortPunchOut("state")}
                                                        >
                                                            <a className="dataTable-sorter text-center">
                                                                Punch-Out
                                                            </a>
                                                        </th>
                                                        {/* <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("city")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter d-block text-center">
                                                  Punch-In Location</a></th> */}
                                                        {/* <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("city")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter d-block text-center">
                                                  Punch-Out Location</a></th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.filterAttendance
                                                        ? this.state.filterAttendance.map((item, index) =>
                                                            index <= this.state.quan ? (
                                                                <tr>
                                                                    <td>
                                                                        <p className="text-sm text-center font-weight-normal mb-0">
                                                                            {item.employee_name}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="text-sm text-center font-weight-normal mb-0">
                                                                            {item.punch_in
                                                                                ? new Date(
                                                                                    item.punch_in
                                                                                ).toLocaleString("en-IN", {
                                                                                    day: "numeric",
                                                                                    month: "short",
                                                                                    year: "numeric",
                                                                                    hour: "numeric",
                                                                                    minute: "numeric",
                                                                                    hour12: true,
                                                                                })
                                                                                : null}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="text-sm text-center font-weight-normal mb-0">
                                                                            {/*{item.book[0].classes.class_name+' - '+item.book[item.book.length-1].classes.class_name}*/}
                                                                            {item.punch_out
                                                                                ? new Date(
                                                                                    item.punch_out
                                                                                ).toLocaleString("en-IN", {
                                                                                    day: "numeric",
                                                                                    month: "short",
                                                                                    year: "numeric",
                                                                                    hour: "numeric",
                                                                                    minute: "numeric",
                                                                                    hour12: true,
                                                                                })
                                                                                : null}
                                                                        </p>
                                                                    </td>
                                                                    {/* <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.punch_in_address}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.punch_out_address}
                                                          </p>
                                                      </td> */}
                                                                </tr>
                                                            ) : null
                                                        )
                                                        : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        {this.state.page === 1 ? null : (
                                            <div className="datatable-mui-pagination">
                                                <Pagination
                                                    count={this.state.page}
                                                    color="secondary"
                                                    onChange={this.handlePage}
                                                    size="large"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <Loader />
                            )}
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={this.state.open}
                                onClose={(e) => this.setState({ open: false })}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={this.state.open}>
                                    <Box sx={modalStyle}>
                                        <Typography
                                            id="transition-modal-description"
                                            sx={{ mt: 2 }}
                                        >
                                            {this.state.is_delete ? (
                                                <>
                                                    <div className="circle__box">
                                                        <div className="circle__wrapper circle__wrapper--right">
                                                            <div className="circle__whole circle__right delete_circle__right"></div>
                                                        </div>
                                                        <div className="circle__wrapper circle__wrapper--left">
                                                            <div className="circle__whole circle__left delete_circle__left"></div>
                                                        </div>
                                                        <div className="circle__checkmark delete_circle__checkmark"></div>
                                                    </div>

                                                    <div style={{ textAlign: "center" }}>
                                                        <h5>Employee deleted successfully.</h5>
                                                        <h6>
                                                            <div
                                                                onClick={this.loadEmployeePageWithAxios}
                                                                style={{ color: "grey", cursor: "pointer" }}
                                                            >
                                                                Click here{" "}
                                                            </div>
                                                            to go Employee Dashboard
                                                        </h6>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="modal-body">
                                                    <div style={{ textAlign: "center" }}>
                                                        <h5>
                                                            Click the Delete button to delete this Employee.
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={(e) => this.delete("delete")}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </Typography>
                                    </Box>
                                </Fade>
                            </Modal>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    sidebar: state.sideBarData.sidebarData.sidebar,
    user: state.userData.userData,
});

const mapDispatchToProps = (dispatch) => ({
    userLoginHandler: (data) => dispatch(userLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceTable);
