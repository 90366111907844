import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import {
  axios,
  url,
  userLogin,
  modalStyle,
} from "../../../store/actions/actions";
import "../../common/imageCase.css";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

class EditTeam extends React.Component {
  state = {
    team_id: null,

    name: null,

    stateOptions: [
      { state: "HARYANA" },
      { state: "PUNJAB" },
      { state: "UP" },
      { state: "DELHI" },
    ],

    selectedState: null,
    selectedStateList: null,
    stateSelectedValues: null,

    headOptions: [
      { name: "Option 1️⃣", id: 1 },
      { name: "Option 2️⃣", id: 2 },
    ],
    selectedHead: null,
    selectedHeadList: null,
    headSelectedValues: null,

    memberOptions: [
      { name: "Option 1️⃣", id: 1 },
      { name: "Option 2️⃣", id: 2 },
    ],
    selectedMembers: null,
    selectedMembersList: null,
    membersSelectedValues: null,

    coordinatorOptions: [
      { name: "Option 1️⃣", id: 1 },
      { name: "Option 2️⃣", id: 2 },
    ],
    selectedCoordinator: null,
    coordinator: null,

    logo: null,
    old_logo: null,

    open: false,
  };

  componentDidMount() {
    this.loadHeadsAndMembers();
    this.loadTeam();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "Update Response data----------": responseData });
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  loadTeam = () => {
    let pathname = window.location.pathname;

    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];

    let endpoint = `teams/${path}`;
    let token = this.props.user.token;
    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({
        team_id: responseData.id,
        stateSelectedValues: responseData.state,
        headSelectedValues: responseData.heads,
        membersSelectedValues: responseData.member,
        selectedCoordinator: responseData.coordinator,
        name: responseData.name,
        old_logo: responseData.logo,
      });

      console.log({ "Team data---------": responseData });
    });
  };

  loadHeadsAndMembers = () => {
    let endpoint = `teams/get-members-heads`;
    let token = this.props.user.token;
    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({ coordinatorOptions: responseData.coordinators });
      this.setState({ headOptions: responseData.heads });
      this.setState({ memberOptions: responseData.members });

      console.log({ "Heads and Members data---------": responseData });
    });
  };

  onStateSelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      selectedState: selectedItem,
      selectedStateList: selectedList,
    });
  };

  onStateRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      selectedStateList: selectedList,
    });
  };

  onHeadSelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      selectedHead: selectedItem,
      selectedHeadList: selectedList,
    });
  };

  onHeadRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      selectedHeadList: selectedList,
    });
  };

  onMemberSelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      selectedMember: selectedItem,
      selectedMembersList: selectedList,
    });
  };

  onMemberRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      selectedMembersList: selectedList,
    });
  };

  handleFileSelected = (e) => {
    const files = e.target.files[0];
    this.setState({ logo: files });
  };

  submitLogo = (id) => {
    let data = new FormData();
    data.append("logo", this.state.logo);
    data.append("id", id);
    let endpoint = `teams/`;
    let token = this.props.user.token;
    axios.put(endpoint, data, token).then((res) => {
      let responseData = res.data;
      // if(responseData.success===true){
      //     this.setState({
      //         open: true
      //     })
      // }

      console.log({ "Response---------": responseData });
    });
  };

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  submit = () => {
    const data = {
      team_id: this.state.team_id,
      name: this.state.name,
      heads: this.state.selectedHeadList
        ? this.state.selectedHeadList
        : this.state.headSelectedValues,
      members: this.state.selectedMembersList
        ? this.state.selectedMembersList
        : this.state.membersSelectedValues,
      state: this.state.selectedStateList
        ? this.state.selectedStateList
        : this.state.stateSelectedValues,
      coordinator: this.state.coordinator
        ? this.state.coordinator
        : this.state.selectedCoordinator
        ? this.state.selectedCoordinator.id
        : null,
    };
    this.setState({
      open: true,
    });

    let endpoint = `teams/`;
    let token = this.props.user.token;
    axios.put(endpoint, data, token).then((res) => {
      let responseData = res.data;
      if (responseData.success === true) {
        this.submitLogo(responseData.id);
      }
      console.log({ "Response---------": responseData });
    });
  };

  render() {
    console.log({ "Check Data---------": this.state.selectedMembersList });
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="d-lg-block d-none">
              <div className="container-fluid">
                <div className="row justify-content-end">
                  <div
                    className={
                      this.props.sidebar === undefined ||
                      this.props.sidebar === true
                        ? "col-10"
                        : "col-12"
                    }
                  >
                    <div className="row mt-5 mb-5 justify-content-center">
                      <div className="col-md-10 mt-lg-0 mt-4">
                        <div className="card mt-4" id="basic-info">
                          <div className="card-header">
                            <h5>Edit Team</h5>
                          </div>
                          <div className="card-body pt-0">
                            <div className="row">
                              <div className="row my-2">
                                <div className="col-6">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">Name</label>
                                    <input
                                      defaultValue={this.state.name}
                                      placeholder="Team name"
                                      onChange={(e) =>
                                        this.setState({ name: e.target.value })
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <label className="form-label">State</label>
                                  <Multiselect
                                    placeholder="Select State"
                                    options={this.state.stateOptions} // Options to display in the dropdown
                                    selectedValues={
                                      this.state.stateSelectedValues
                                    } // Preselected value to persist in dropdown
                                    onSelect={this.onStateSelect} // Function will trigger on select event
                                    onRemove={this.onStateRemove} // Function will trigger on remove event
                                    displayValue="state" // Property name to display in the dropdown options
                                    style={{
                                      searchBox: { fontSize: "15px" },
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row mt-4">
                                {this.state.coordinatorOptions ? (
                                  <div className="col-6 p-3 mt-3">
                                    <Autocomplete
                                      disablePortal
                                      size="small"
                                      id="combo-box-demo"
                                      value={
                                        this.state.selectedCoordinator
                                          ? this.state.selectedCoordinator
                                          : null
                                      }
                                      options={this.state.coordinatorOptions}
                                      onChange={(e, value) =>
                                        this.setState({ coordinator: value.id })
                                      }
                                      getOptionLabel={(option) =>
                                        option.last_name
                                          ? option.first_name +
                                            " " +
                                            option.last_name
                                          : option.first_name
                                      }
                                      // sx={{ width: 300 }}
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{
                                            "& > img": {
                                              mr: 2,
                                              flexShrink: 0,
                                            },
                                          }}
                                          {...props}
                                        >
                                          <img
                                            style={{ borderRadius: "50%" }}
                                            // loading="lazy"
                                            width="30"
                                            src={
                                              option.pic
                                                ? url + option.pic
                                                : url + "/static/agent.png"
                                            }
                                            // srcSet={`${url + option.pic} 2x`}
                                            alt=""
                                          />
                                          {option.last_name
                                            ? option.first_name +
                                              " " +
                                              option.last_name
                                            : option.first_name}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select Coordinator"
                                        />
                                      )}
                                    />
                                  </div>
                                ) : null}
                                <div className="col-6">
                                  <label className="form-label">Heads</label>
                                  <Multiselect
                                    placeholder="Select Team Heads"
                                    options={this.state.headOptions} // Options to display in the dropdown
                                    selectedValues={
                                      this.state.headSelectedValues
                                    } // Preselected value to persist in dropdown
                                    onSelect={this.onHeadSelect} // Function will trigger on select event
                                    onRemove={this.onHeadRemove} // Function will trigger on remove event
                                    displayValue="first_name" // Property name to display in the dropdown options
                                    style={{
                                      searchBox: { fontSize: "15px" },
                                    }}
                                  />
                                </div>
                                <div className="col-12">
                                  <label className="form-label">Members</label>
                                  <Multiselect
                                    placeholder="Select Team Members"
                                    options={this.state.memberOptions} // Options to display in the dropdown
                                    selectedValues={
                                      this.state.membersSelectedValues
                                    } // Preselected value to persist in dropdown
                                    onSelect={this.onMemberSelect} // Function will trigger on select event
                                    onRemove={this.onMemberRemove} // Function will trigger on remove event
                                    displayValue="first_name" // Property name to display in the dropdown options
                                    style={{
                                      searchBox: { fontSize: "15px" },
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="mt-3">
                                <label
                                  htmlFor="formFileLg"
                                  className="form-label"
                                >
                                  Upload Team Logo
                                </label>
                                <input
                                  className="form-control form-control-lg"
                                  id="formFileLg"
                                  onChange={this.handleFileSelected}
                                  style={{
                                    color: "red",
                                    backgroundColor: "#e5e5e5",
                                  }}
                                  // defaultValue=""
                                  type="file"
                                />
                                {this.state.old_logo ? (
                                  // <img src={this.state.logo}/>
                                  <div className="image-area mt-4">
                                    <img
                                      src={url + this.state.old_logo}
                                      alt="Preview"
                                    />
                                    <div
                                      className="remove-image"
                                      onClick={() =>
                                        this.setState({ old_logo: null })
                                      }
                                      style={{ display: "inline" }}
                                    >
                                      &#215;
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="row my-4">
                              <div className="col-12 mt-4">
                                <button
                                  className="btn bg-gradient-dark d-block m-auto"
                                  onClick={this.submit}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>

                          <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.open}
                            onClose={(e) => this.setState({ open: false })}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                            <Fade in={this.state.open}>
                              <Box sx={modalStyle}>
                                {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                {/*  Text in a modal*/}
                                {/*</Typography>*/}
                                <Typography
                                  id="transition-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  <div className="modal-body">
                                    <div className="circle__box">
                                      <div className="circle__wrapper circle__wrapper--right">
                                        <div className="circle__whole circle__right"></div>
                                      </div>
                                      <div className="circle__wrapper circle__wrapper--left">
                                        <div className="circle__whole circle__left"></div>
                                      </div>
                                      <div className="circle__checkmark"></div>
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                      <h5>Team Updated Successfully.</h5>
                                      <h6>
                                        <Link
                                          to="/team"
                                          style={{ color: "grey" }}
                                        >
                                          Click here{" "}
                                        </Link>{" "}
                                        for go to team dashboard.
                                      </h6>
                                    </div>
                                  </div>
                                </Typography>
                              </Box>
                            </Fade>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-lg-none">
              <div className="px-2">
                <div className="row justify-content-end">
                  <div>
                    <div className="row mb-5 justify-content-center">
                      <div className="col-md-10 mt-lg-0 ">
                        <div className="card mt-4 card-kmv" id="basic-info">
                          <div className="card-header">
                            <h5>Edit Team</h5>
                          </div>
                          <div className="card-body pt-0">
                            <div className="">
                              <div className="row my-2">
                                <div className="col-12">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">Name</label>
                                    <input
                                      defaultValue={this.state.name}
                                      placeholder="Team name"
                                      onChange={(e) =>
                                        this.setState({ name: e.target.value })
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <label className="form-label">State</label>
                                  <Multiselect
                                    placeholder="Select State"
                                    options={this.state.stateOptions} // Options to display in the dropdown
                                    selectedValues={
                                      this.state.stateSelectedValues
                                    } // Preselected value to persist in dropdown
                                    onSelect={this.onStateSelect} // Function will trigger on select event
                                    onRemove={this.onStateRemove} // Function will trigger on remove event
                                    displayValue="state" // Property name to display in the dropdown options
                                    style={{
                                      searchBox: { fontSize: "15px" },
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <label className="form-label">Heads</label>
                                  <Multiselect
                                    placeholder="Select Team Heads"
                                    options={this.state.headOptions} // Options to display in the dropdown
                                    selectedValues={
                                      this.state.headSelectedValues
                                    } // Preselected value to persist in dropdown
                                    onSelect={this.onHeadSelect} // Function will trigger on select event
                                    onRemove={this.onHeadRemove} // Function will trigger on remove event
                                    displayValue="first_name" // Property name to display in the dropdown options
                                    style={{
                                      searchBox: { fontSize: "15px" },
                                    }}
                                  />
                                </div>
                                <div className="col-12 mt-2">
                                  <label className="form-label">Members</label>
                                  <Multiselect
                                    className=""
                                    placeholder="Select Team Members"
                                    options={this.state.memberOptions} // Options to display in the dropdown
                                    selectedValues={
                                      this.state.membersSelectedValues
                                    } // Preselected value to persist in dropdown
                                    onSelect={this.onMemberSelect} // Function will trigger on select event
                                    onRemove={this.onMemberRemove} // Function will trigger on remove event
                                    displayValue="first_name" // Property name to display in the dropdown options
                                    style={{
                                      searchBox: { fontSize: "15px" },
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="mt-3">
                                <label
                                  htmlFor="formFileLg"
                                  className="form-label"
                                >
                                  Upload Team Logo
                                </label>
                                <input
                                  className="form-control form-control-lg"
                                  id="formFileLg"
                                  onChange={this.handleFileSelected}
                                  style={{
                                    color: "red",
                                    backgroundColor: "#e5e5e5",
                                  }}
                                  // defaultValue=""
                                  type="file"
                                />
                                {this.state.old_logo ? (
                                  // <img src={this.state.logo}/>
                                  <div className="image-area mt-4">
                                    <img
                                      src={url + this.state.old_logo}
                                      alt="Preview"
                                    />
                                    <div
                                      className="remove-image"
                                      onClick={() =>
                                        this.setState({ old_logo: null })
                                      }
                                      style={{ display: "inline" }}
                                    >
                                      &#215;
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="row my-4">
                              <div className="col-12 mt-4">
                                <button
                                  className="btn bg-gradient-dark d-block m-auto"
                                  onClick={this.submit}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>

                          <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.open}
                            onClose={(e) => this.setState({ open: false })}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                            <Fade in={this.state.open}>
                              <Box sx={modalStyle}>
                                {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                {/*  Text in a modal*/}
                                {/*</Typography>*/}
                                <Typography
                                  id="transition-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  <div className="modal-body">
                                    <div className="circle__box">
                                      <div className="circle__wrapper circle__wrapper--right">
                                        <div className="circle__whole circle__right"></div>
                                      </div>
                                      <div className="circle__wrapper circle__wrapper--left">
                                        <div className="circle__whole circle__left"></div>
                                      </div>
                                      <div className="circle__checkmark"></div>
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                      <h5>Team Updated Successfully.</h5>
                                      <h6>
                                        <Link
                                          to="/team"
                                          style={{ color: "grey" }}
                                        >
                                          Click here{" "}
                                        </Link>{" "}
                                        for go to team dashboard.
                                      </h6>
                                    </div>
                                  </div>
                                </Typography>
                              </Box>
                            </Fade>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTeam);
