import React from "react";
import { connect } from "react-redux";
import {
  axios,
  modalStyle,
  url,
  userLogin,
} from "../../../store/actions/actions";
import { Link } from "react-router-dom";
import DeleteModal from "../../common/Modals/deleteModal";
import Loader from "../../common/Loader/Loader";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "@mui/material/Pagination";
import "../../common/common.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Multiselect } from "multiselect-react-dropdown";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";

class DataTable extends React.Component {
  state = {
    trip: null,
    quan: null,
    total: null,
    city: null,
    filterTrips: null,
    selected_state: null,

    is_delete: false,
    delete_trip_id: null,
    open: false,

    // sort
    nameSort: null,
    stateSort: null,
    stationSort: null,
    citySort: null,
    divisionSort: null,

    // pagination
    page: null,

    // filter
    filter: false,
    all_states: null,
    showfilter: false,
    fromDate: null,
    toDate: null,
    stateFilter: null,
    divisionFilter: null,
    clear: false,

    index_page: false,
    searchText: "",
    searchTripText: null
  };

  componentDidMount() {
    // this.loadStates();
    this.loadTripPageWithAxios();
  }

  loadTripPageWithAxios = () => {
    this.setState({
      trip: null,
      filterTrips: null,
    });

    let endpoint = `trip/trip-datatable`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ trip: responseData.trip });
      this.setState({ filterTrips: responseData.trip });
      this.setState({ quan: responseData.count });
      this.setState({ page: responseData.page });
      this.setState({ total: responseData.count });
    });
  };

  loadStates = () => {
    let endpoint = `employee/states`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ all_states: responseData.state });
    });
  };

  dateFormat = (inputDate, format) => {
    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    //replace the month
    format = format.replace("MM", month.toString().padStart(2, "0"));

    //replace the year
    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }

    //replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  };

  inputChangeHandler = (e) => {
    this.setState({
      searchText: e.target.value,
    });

    // let searchTextCheck = this.state.searchText;
    // console.log(searchTextCheck, "Search Input Text")

    // // if (searchTextCheck.length < 1) {
    // // } else {
    // //   this.loadTripPageWithAxios();
    // // }

    // console.log("Change Ho Raha Hai", this.state.searchText);
  };

  // Clear Input Field When click on Clear button
  clearSearchInputHandler = (e) => {
    this.setState({
      searchText: "",
    });
    this.loadTripPageWithAxios();
    console.log("Chal To rha hai bhai", e.target.value);
  };

  handleSearch = () => {
    this.setState({
      trip: null,
      filterTrips: null,
    });

    let data = {
      searchTexts: this.state.searchText,
    };

    console.log(data, "Data Hai Isme");

    let endpoint = `trip/search-trip-srk`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      this.setState({ trip: responseData.trip });
      this.setState({ filterTrips: responseData.trip });
      this.setState({ quan: responseData.count });
      this.setState({ page: responseData.page });
      this.setState({ total: responseData.count });
      console.log({ "Search response data---------": responseData.trip });
    });
  };

  //   Original Handle Search
  //   handleSearch = (e) => {
  //     let text = e.target.value;
  //     let endpoint = `trip/search-trip?search=${text}`;
  //     let token = this.props.user.token;

  //     axios.get(endpoint, token).then((response) => {
  //       const responseData = response.data;
  //       this.setState({ filterTrips: responseData });
  //       console.log({ "response data---------": responseData });
  //     });
  //   };

  handlePage = (e, p) => {
    console.log("--------------------", p);
    this.setState({ index_page: p === 1 ? 0 : (p - 1) * 10 });
    const data = {
      from: p === 1 ? 0 : (p - 1) * 10,
      to: p === 1 ? 10 : p * 10,
    };
    console.log("--------------------", data);
    let endpoint = `trip/pagination`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      this.setState({ filterTrips: responseData.trip });
      console.log({ "response data---------": responseData });
    });
  };

  sortEmpTable = (data) => {
    let arr = this.state.filterTrips;

    if (data === "name") {
      if (this.state.nameSort === null || this.state.nameSort === "lower") {
        this.setState({
          nameSort: "upper",
        });
        arr.sort(function (a, b) {
          if (a.first_name.toLowerCase() < b.first_name.toLowerCase())
            return -1;
          return 0;
        });
      }

      if (this.state.nameSort === "upper") {
        this.setState({
          nameSort: "lower",
        });
        arr.sort(function (a, b) {
          if (a.first_name.toLowerCase() > b.first_name.toLowerCase())
            return -1;
          return 0;
        });
      }
      this.setState({
        filterTrips: arr,
      });
    }

    if (data === "state") {
      if (this.state.stateSort === null || this.state.stateSort === "lower") {
        this.setState({
          stateSort: "upper",
        });
        arr.sort(function (a, b) {
          let state_a = a.state.map((st, index) => st.state.toLowerCase());
          let state_b = b.state.map((st, index) => st.state.toLowerCase());

          if (state_a < state_b) return -1;
          return 0;
        });
      }

      if (this.state.stateSort === "upper") {
        this.setState({
          stateSort: "lower",
        });
        arr.sort(function (a, b) {
          let state_a = a.state.map((st, index) => st.state.toLowerCase());
          let state_b = b.state.map((st, index) => st.state.toLowerCase());

          if (state_a > state_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterTrips: arr,
      });
    }

    if (data === "city") {
      if (this.state.citySort === null || this.state.citySort === "lower") {
        this.setState({
          citySort: "upper",
        });
        arr.sort(function (a, b) {
          let station_a = a.station.map((st, index) =>
            st.station.toLowerCase()
          );
          let station_b = b.station.map((st, index) =>
            st.station.toLowerCase()
          );

          if (station_a < station_b) return -1;
          return 0;
        });
      }

      if (this.state.citySort === "upper") {
        this.setState({
          citySort: "lower",
        });
        arr.sort(function (a, b) {
          let station_a = a.station.map((st, index) =>
            st.station.toLowerCase()
          );
          let station_b = b.station.map((st, index) =>
            st.station.toLowerCase()
          );

          if (station_a > station_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterTrips: arr,
      });
    }

    if (data === "status") {
      if (this.state.statusSort === null || this.state.statusSort === "lower") {
        this.setState({
          statusSort: "upper",
        });
        arr.sort(function (a, b) {
          let status_a =
            a.is_admin === true
              ? "Admin"
              : a.is_manager === true
              ? "Manager"
              : a.is_executive === true
              ? "Executive"
              : null;
          let status_b =
            b.is_admin === true
              ? "Admin"
              : b.is_manager === true
              ? "Manager"
              : b.is_executive === true
              ? "Executive"
              : null;

          if (status_a < status_b) return -1;
          return 0;
        });
      }
      if (this.state.statusSort === "upper") {
        this.setState({
          statusSort: "lower",
        });
        arr.sort(function (a, b) {
          let status_a =
            a.is_admin === true
              ? "Admin"
              : a.is_manager === true
              ? "Manager"
              : a.is_executive === true
              ? "Executive"
              : null;
          let status_b =
            b.is_admin === true
              ? "Admin"
              : b.is_manager === true
              ? "Manager"
              : b.is_executive === true
              ? "Executive"
              : null;

          if (status_a > status_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterTrips: arr,
      });
    }

    if (data === "division") {
      if (
        this.state.divisionSort === null ||
        this.state.divisionSort === "lower"
      ) {
        this.setState({
          divisionSort: "upper",
        });
        arr.sort(function (a, b) {
          let division_a = a.division.map((st, index) => st.name.toLowerCase());
          let division_b = b.division.map((st, index) => st.name.toLowerCase());

          if (division_a < division_b) return -1;
          return 0;
        });
      }

      if (this.state.divisionSort === "upper") {
        this.setState({
          divisionSort: "lower",
        });
        arr.sort(function (a, b) {
          let division_a = a.division.map((st, index) => st.name.toLowerCase());
          let division_b = b.division.map((st, index) => st.name.toLowerCase());

          if (division_a > division_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterTrips: arr,
      });
    }
  };

  onFilterStateSelect = (selectedList, selectedItem) => {
    this.setState({
      stateFilter: selectedList,
    });
  };

  onFilterStateRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      stateFilter: selectedList,
    });
  };

  delete = () => {
    let endpoint = `trip/${this.state.delete_trip_id}`;
    let token = this.props.user.token;

    axios.delete(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({
        is_delete: true,
      });
      console.log({ "delete response data---------": responseData });
    });
  };

  applyFilter = () => {
    this.setState({
      filter: true,
      clear: true,
    });
    let states = [];

    if (this.state.stateFilter !== null && this.state.stateFilter !== []) {
      console.log("state filter");
      this.state.stateFilter.map((state, index) => states.push(state.id));
    }

    let endpoint = `trip/filter-trip?state_in=${states}&from_date=${
      this.state.fromDate ? this.state.fromDate : ""
    }&to_date=${this.state.toDate ? this.state.toDate : ""}`;
    console.log(endpoint);
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ filterTrips: responseData });
      console.log({
        ">>>>>>>>>>>>>>>>>>>>>>response data---------": responseData,
      });
    });
  };

  clearFilter = () => {
    console.log(this.state.trip);
    this.setState({
      clear: false,
      stateFilter: null,
      divisionFilter: null,
      filterTrips: this.state.trip,
      fromDate: null,
      toDate: null,
    });
  };

  render() {
    return (
      <>
        <div className="d-none d-lg-block row my-4" id="clients">
          <div className="col-12">
            <div className="card py-3">
              {this.state.trip ? (
                <div className="table-responsive px-2">
                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div className="dataTable-top">
                      <h5 className="mb-0 ps-3">Trip</h5>
                      {/*<div className="dataTable-dropdown">*/}
                      {/*    <label>*/}
                      {/*    <select className="dataTable-selector"*/}
                      {/*            onChange={(e) => this.setState({quan: e.target.value})}*/}
                      {/*    >*/}
                      {/*        <option value="4" selected>5</option>*/}
                      {/*        <option value="9">10</option>*/}
                      {/*        <option value="19">20</option>*/}
                      {/*        /!*<option value={this.state.total}>All</option>*!/*/}

                      {/*    </select> entries per page</label></div>*/}
                      <div
                        className=" d-flex justify-content-end align-items-center"
                        style={{ position: "relative" }}
                      >
                        <LocalPrintshopIcon
                          className="cursor-pointer me-3"
                          onClick={this.printTable}
                        />
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Search..."
                          variant="outlined"
                          className="d-none"
                          onChange={(e) => {
                            this.setState({
                              searchTripText: e.target.value
                            });
                            console.log(e.target.value);
                          }}
                          // onChange={this.handleSearch}
                        />
                        <div
                          onClick={(e) =>
                            this.setState({
                              showfilter:
                                this.state.showfilter === true ? false : true,
                            })
                          }

                          className="d-none"
                        >
                          <FilterListIcon
                            style={{ cursor: "pointer", marginLeft: "15px" }}
                          />
                        </div>
                        {this.state.showfilter === true ? (
                          <div
                            className="card p-4"
                            style={{
                              width: "700px",
                              position: "absolute",
                              top: "50px",
                              right: "0",
                              zIndex: "10",
                              boxShadow: "0px 0px 6px #bdbbbb",
                            }}
                          >
                            <h6>FILTERS</h6>
                            {this.state.clear ? (
                              <p
                                onClick={this.clearFilter}
                                style={{ color: "#5f5fcb", cursor: "pointer" }}
                              >
                                Clear
                              </p>
                            ) : null}
                            <div className="row p-1">
                              <div className="col-12 p-1">
                                <p>Date range</p>
                                <div className="d-flex mt-2 p-1 align-items-center">
                                  <TextField
                                    id="date"
                                    label="From"
                                    type="date"
                                    size="small"
                                    onChange={(e) =>
                                      this.setState({
                                        fromDate: e.target.value,
                                      })
                                    }
                                    sx={{ width: 220 }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                  <p
                                    className="mx-4 my-0"
                                    style={{ fontSize: "24px" }}
                                  >
                                    to
                                  </p>

                                  <TextField
                                    id="date"
                                    label="To"
                                    type="date"
                                    size="small"
                                    onChange={(e) =>
                                      this.setState({ toDate: e.target.value })
                                    }
                                    sx={{ width: 220 }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 p-1 mt-2">
                                <p>State</p>
                                <div className="form-check-inline">
                                  <div className="">
                                    {this.state.all_states ? (
                                      <Multiselect
                                        placeholder="Seclect State"
                                        options={this.state.all_states} // Options to display in the dropdown
                                        onSelect={this.onFilterStateSelect} // Function will trigger on select event
                                        onRemove={this.onFilterStateRemove} // Function will trigger on remove event
                                        displayValue="state" // Property name to display in the dropdown options
                                        style={{
                                          searchBox: { fontSize: "15px" },
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                              <button
                                onClick={this.applyFilter}
                                className="btn my-0 bg-gradient-dark d-block"
                              >
                                Apply Filters
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/*<div>*/}
                      {/*</div>*/}
                    </div>
                    <div className="dataTable-container" id="tripTable">
                      <table className="table align-items-center mb-0 dataTable-table">
                        <thead>
                          <tr>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "4.8032%", cursor: "pointer" }}
                            >
                              <a
                                // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                className="dataTable-sorter"
                                onClick={(e) => this.sortEmpTable("serial_num")}
                              >
                                S.NO
                              </a>
                            </th>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("name")}
                            >
                              <a className="dataTable-sorter">Customer</a>
                            </th>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("state")}
                            >
                              <a className="dataTable-sorter text-center">
                                Employee
                              </a>
                            </th>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("city")}
                            >
                              <a className="dataTable-sorter d-block text-center">
                                Review
                              </a>
                            </th>
                            <th
                              className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("division")}
                            >
                              <a className="dataTable-sorter">Date</a>
                            </th>
                            <th
                              className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "15%" }}
                            >
                              <a className="dataTable-sorter">Action</a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filterTrips
                            ? this.state.filterTrips.map((trip, index) =>
                                index <= this.state.quan ? (
                                  <tr>
                                    <td>
                                      <div className="d-flex px-2 py-1">
                                        <div className="d-flex flex-column justify-content-center">
                                          <h6 className="mb-0 font-weight-normal text-sm">
                                            {index + 1 + this.state.index_page}
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex px-2 py-1">
                                        <div>
                                          <img
                                            src="../../../assets/img/team-2.jpg"
                                            className="avatar avatar-sm me-3"
                                            alt="avatar image"
                                          />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                          <h6 className="mb-0 font-weight-normal text-sm">
                                            {trip.customer
                                              ? trip.customer.company
                                              : null}
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex  align-items-center">
                                        <img
                                          src={
                                            trip.employee.pic
                                              ? url + trip.employee.pic
                                              : url +
                                                "/static/dreambox_logo.jpg"
                                          }
                                          className="avatar avatar-sm me-3"
                                          alt="avatar image"
                                        />
                                        <h6 className="mb-0 font-weight-normal text-sm">
                                          {trip.employee.last_name
                                            ? trip.employee.first_name +
                                              " " +
                                              trip.employee.last_name
                                            : trip.employee.first_name}
                                        </h6>
                                      </div>
                                    </td>
                                    <td className="align-middle text-center text">
                                      <span className="badge badge-dot me-4">
                                        <i className="bg-info"></i>
                                        <span className="text-dark text-xs">
                                          positive
                                        </span>
                                      </span>
                                    </td>
                                    <td className="align-middle text-center">
                                      <p className="text-sm font-weight-normal mb-0">
                                        {trip.date}
                                      </p>
                                    </td>
                                    <td className="align-middle text-center">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <Link to={"/trip/view-trip/" + trip.id}>
                                          <RemoveRedEyeIcon
                                            style={{
                                              color: "grey",
                                              margin: "0px 20px 0px 0px",
                                            }}
                                          />
                                        </Link>
                                        {/*<Link to={"/trip/edit-trip/" + trip.id}>*/}
                                        {/*    <ModeEditIcon style={{color: "#12b312", margin:"0px 15px"}}/>*/}
                                        {/*</Link>*/}
                                        <div
                                          onClick={(e) =>
                                            this.setState({
                                              delete_trip_id: trip.id,
                                              open: true,
                                            })
                                          }
                                        >
                                          <DeleteIcon
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ) : null
                              )
                            : null}
                        </tbody>
                      </table>
                    </div>
                    {this.state.page === 1 ? null : (
                      <div className="datatable-mui-pagination">
                        <Pagination
                          count={this.state.page}
                          color="secondary"
                          onChange={this.handlePage}
                          size="large"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>

        <div className="d-lg-none trip-mv mt-3">
          <div className="card p-3">
            <div className="head-content-mv">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0 pt-2 pb-3">Trip</h5>
                {this.state.searchText !== "" &&
                this.state.searchText !== null ? (
                  <ClearIcon onClick={this.clearSearchInputHandler} />
                ) : null}
              </div>
              {/*<div className="dataTable-dropdown">*/}
              {/*    <label>*/}
              {/*    <select className="dataTable-selector"*/}
              {/*            onChange={(e) => this.setState({quan: e.target.value})}*/}
              {/*    >*/}
              {/*        <option value="4" selected>5</option>*/}
              {/*        <option value="9">10</option>*/}
              {/*        <option value="19">20</option>*/}
              {/*        /!*<option value={this.state.total}>All</option>*!/*/}

              {/*    </select> entries per page</label></div>*/}

              <div
                className=" d-flex justify-content-between w-100 align-items-center"
                style={{ position: "relative", gap: "5px" }}
              >
                {/* <LocalPrintshopIcon className='cursor-pointer me-3' onClick={this.printTable}/> */}
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Search..."
                  variant="outlined"
                  value={this.state.searchText}
                  onChange={this.inputChangeHandler}
                />
                <Button variant="contained" onClick={this.handleSearch}>
                  Search
                </Button>
                <div
                  onClick={(e) =>
                    this.setState({
                      showfilter: this.state.showfilter === true ? false : true,
                    })
                  }
                >
                  {/* <FilterListIcon style={{cursor: "pointer", marginLeft: "15px"}}/> */}
                </div>
                {this.state.showfilter === true ? (
                  <div
                    className="card p-4"
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "50px",
                      right: "0",
                      zIndex: "10",
                      boxShadow: "0px 0px 6px #bdbbbb",
                    }}
                  >
                    <h6>FILTERS</h6>
                    {this.state.clear ? (
                      <p
                        onClick={this.clearFilter}
                        style={{ color: "#5f5fcb", cursor: "pointer" }}
                      >
                        Clear
                      </p>
                    ) : null}
                    <div className="row p-1">
                      <div className="col-12 p-1">
                        <p>Date range</p>
                        <div className="d-flex mt-2 p-1 align-items-center">
                          <TextField
                            id="date"
                            label="From"
                            type="date"
                            size="small"
                            onChange={(e) =>
                              this.setState({ fromDate: e.target.value })
                            }
                            sx={{ width: 220 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <p className="mx-4 my-0" style={{ fontSize: "24px" }}>
                            to
                          </p>

                          <TextField
                            id="date"
                            label="To"
                            type="date"
                            size="small"
                            onChange={(e) =>
                              this.setState({ toDate: e.target.value })
                            }
                            sx={{ width: 220 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6 p-1 mt-2">
                        <p>State</p>
                        <div className="form-check-inline">
                          <div className="">
                            <Multiselect
                              placeholder="Seclect State"
                              options={this.state.all_states} // Options to display in the dropdown
                              onSelect={this.onFilterStateSelect} // Function will trigger on select event
                              onRemove={this.onFilterStateRemove} // Function will trigger on remove event
                              displayValue="state" // Property name to display in the dropdown options
                              style={{
                                searchBox: { fontSize: "15px" },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 p-1 mt-2">
                        <p>Division</p>
                        <div className="form-check-inline">
                          <>
                            <Multiselect
                              placeholder="Seclect Division"
                              options={this.state.all_divisions} // Options to display in the dropdown
                              onSelect={this.onFilterDivisionSelect} // Function will trigger on select event
                              onRemove={this.onFilterDivisionRemove} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                              style={{
                                searchBox: { fontSize: "15px" },
                              }}
                            />
                          </>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        onClick={this.applyFilter}
                        className="btn my-0 bg-gradient-dark d-block"
                      >
                        Apply Filters
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              {/*<div>*/}
              {/*</div>*/}
              {this.state.trip ? (
                <div className="table-responsive px-2">
                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div className="dataTable-top"></div>
                    <div className="dataTable-container" id="tripTable">
                      <table className="table align-items-center mb-0 dataTable-table">
                        <thead>
                          <tr>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("name")}
                            >
                              <a className="dataTable-sorter">Customer</a>
                            </th>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("state")}
                            >
                              <a className="dataTable-sorter text-center">
                                Employee
                              </a>
                            </th>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("city")}
                            >
                              <a className="dataTable-sorter d-block text-center">
                                Review
                              </a>
                            </th>
                            <th
                              className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("division")}
                            >
                              <a className="dataTable-sorter">Date</a>
                            </th>
                            <th
                              className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "15%" }}
                            >
                              <a className="dataTable-sorter">Action</a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filterTrips
                            ? this.state.filterTrips.map((trip, index) =>
                                index <= this.state.quan ? (
                                  <tr>
                                    <td>
                                      <div className="d-flex px-2 py-1">
                                        <div>
                                          <img
                                            src="../../../assets/img/team-2.jpg"
                                            className="avatar avatar-sm me-3"
                                            alt="avatar image"
                                          />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                          <h6 className="mb-0 font-weight-normal text-sm">
                                            {trip.customer
                                              ? trip.customer.company
                                              : null}
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex  align-items-center">
                                        <img
                                          src={
                                            trip.employee.pic
                                              ? url + trip.employee.pic
                                              : url +
                                                "/static/dreambox_logo.jpg"
                                          }
                                          className="avatar avatar-sm me-3"
                                          alt="avatar image"
                                        />
                                        <h6 className="mb-0 font-weight-normal text-sm">
                                          {trip.employee.last_name
                                            ? trip.employee.first_name +
                                              " " +
                                              trip.employee.last_name
                                            : trip.employee.first_name}
                                        </h6>
                                      </div>
                                    </td>
                                    <td className="align-middle text-center text">
                                      <span className="badge badge-dot me-4">
                                        <i className="bg-info"></i>
                                        <span className="text-dark text-xs">
                                          positive
                                        </span>
                                      </span>
                                    </td>
                                    <td className="align-middle text-center">
                                      <p className="text-sm font-weight-normal mb-0">
                                        {trip.date}
                                      </p>
                                    </td>
                                    <td className="align-middle text-center">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <Link to={"/trip/view-trip/" + trip.id}>
                                          <RemoveRedEyeIcon
                                            style={{
                                              color: "grey",
                                              margin: "0px 20px 0px 0px",
                                            }}
                                          />
                                        </Link>
                                        {/*<Link to={"/trip/edit-trip/" + trip.id}>*/}
                                        {/*    <ModeEditIcon style={{color: "#12b312", margin:"0px 15px"}}/>*/}
                                        {/*</Link>*/}
                                        <div
                                          onClick={(e) =>
                                            this.setState({
                                              delete_trip_id: trip.id,
                                              open: true,
                                            })
                                          }
                                        >
                                          <DeleteIcon
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ) : null
                              )
                            : null}
                        </tbody>
                      </table>
                    </div>
                    {this.state.page === 1 ? null : (
                      <div className="datatable-mui-pagination">
                        <Pagination
                          count={this.state.page}
                          color="secondary"
                          onChange={this.handlePage}
                          size="large"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={(e) => this.setState({ open: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Box sx={modalStyle}>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                {this.state.is_delete ? (
                  <>
                    <div className="circle__box">
                      <div className="circle__wrapper circle__wrapper--right">
                        <div className="circle__whole circle__right delete_circle__right"></div>
                      </div>
                      <div className="circle__wrapper circle__wrapper--left">
                        <div className="circle__whole circle__left delete_circle__left"></div>
                      </div>
                      <div className="circle__checkmark deletethis.loadCustomerPageWithAxios_circle__checkmark"></div>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <h5>Trip deleted successfully.</h5>
                      <h6>
                        <div
                          onClick={(e) => this.setState({ open: false })}
                          style={{ color: "grey", cursor: "pointer" }}
                        >
                          <Link to="/trip" style={{ color: "grey" }}>
                                  Click here{" "}
                                </Link>
                        </div>
                        to go Trip Dashboard
                      </h6>
                    </div>
                  </>
                ) : (
                  <div className="modal-body">
                    <div style={{ textAlign: "center" }}>
                      <h5>Click the Delete button to delete this visit.</h5>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={(e) => this.delete()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )}
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
  user: state.userData.userData,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
