import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import {google_map_api_key} from "../../store/actions/actions";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      address: '',

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      place_id: null,

      mapCenter: {
        lat: 28.6161796,
        lng: 77.3898004
      }
    };
  }

  handleChange = address => {
      console.log('--------------------------->', address)
    this.setState({ address });
  };

  handleSelect = address => {
    console.log('address-----------', address);
    geocodeByAddress(address)
    .then(results => {
          this.setState({place_id: results[0].place_id})
        })
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log('latLng------------', latLng);

        // update center state
        this.setState({ mapCenter: latLng });

      })
      .catch(error => console.error('Error', error));
  };

  submit = () => {
    var data = {
        'latLng':this.state.mapCenter,
        'address': this.state.address,
        'place_id': this.state.place_id
    }
    this.changeHandler(data)
  }

  changeHandler = (data) =>{
    this.props.parentCallback(data)
  }

  test = (address) => {
      this.setState({})
      console.log('--------------------------->', address)
  }

  render() {
    return (
      <div id='googleMaps' className="position-relative">
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <>
            <div className="d-none d-lg-block google_map_input">
              <input
                  // onClick={this.setState({address:e.target.value})}
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                })}
                  className="w-50"
              />
              {/*{this.state.place_id?*/}
              <button
                  className="mb-0 mx-3 btn btn-secondary btn-sm google_map_submit_button"
                  onClick={(e)=>this.test(suggestions)}
                  type="button">Submit
              </button>
              {/*:null}*/}
              <div className="autocomplete-dropdown-container google_map_dropdown" style={{width:"100%", height:"auto", zIndex:"2000"}}>
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-lg-none google_map_input d-flex flex-column">
            <input
                // onClick={this.setState({address:e.target.value})}
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
                className="w-100"
            />
            {this.state.place_id?
            <button
                className="my-3 btn  bg-secondary ms-auto mb-0 js-btn-next text-white"
                onClick={this.submit}
                type="button">Submit
            </button>
            :null}
            <div className="autocomplete-dropdown-container google_map_dropdown" style={{width:"100%", height:"auto", zIndex:"2000"}}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
          </>
          )}
        </PlacesAutocomplete>
        <div className="google_map">
        <Map
          google={this.props.google}
          initialCenter={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
          center={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
        >
          <Marker
            position={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng
            }} />
        </Map>
        </div>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: (google_map_api_key)
})(MapContainer)