import React from "react";
import { connect } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import TadaDataTable from "./TadaDataTable";
// import DataCards.js from "./DataCards.js";
import DataCards from '../EmployeeDash/DataCards'

class TadaDash extends React.Component {

    render() {
        return (
            <>
                {this.props.user.login === true ?
                    <>
                        <div className="container-fluid homepage-view">
                            <div className="row justify-content-end">
                                <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "d-none d-lg-block col-10" : "d-none d-lg-block col-12"}>
                                    <DataCards />
                                    <TadaDataTable />
                                </div>
                            </div>
                        </div>
                        <div className="px-2 d-lg-none">
                        <div className="dashOption-mv mt-4 ">
                            <div className="">
                                <div className="dashOption-mv mt-4">
                                        <div className="">
                                            <div className="button-bar-mv d-flex">
                                                <Link to='/tada' className="btn btn-mv active"><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                                <Link to="/tada/create-tada" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Add Tada</Link>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                            <DataCards />
                            <TadaDataTable />
                        </div>
                    </>
                    :
                    <Redirect to={'/sign-in'} />
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
})

export default connect(mapStateToProps)(TadaDash)