import React from "react";
import { axios, sideBar, userLogin } from "../../../store/actions/actions";
import { connect } from "react-redux";
import Loadingfade from "../../common/Loadingfade";

class CustomerDataCard extends React.Component {
  state = {
    all_cust: null,
    all_seller: null,
    all_school: null,
    new_cust: null,
    new_school: null,
    new_seller: null,
  };

  componentDidMount() {
    this.loadDataCardsWithAxios();
    this.toeknExpiryCheck();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "Update Response data----------": responseData });
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  loadDataCardsWithAxios = () => {
    let endpoint = `customer/data-cards`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      // console.log({'response data---------':responseData})
      this.setState({ all_cust: responseData.all_cust });
      this.setState({ all_seller: responseData.all_seller });
      this.setState({ all_school: responseData.all_school });
      this.setState({ new_cust: responseData.new_cust });
      this.setState({ new_school: responseData.new_school });
      this.setState({ new_seller: responseData.new_seller });
    });
  };

  render() {
    return (
      <>
        <div className="d-none d-lg-block">
          <div className="row mt-3">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card mb-2">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">weekend</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">All Customer</p>
                    <h4 className="mb-0">{this.state.all_cust}</h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
                <div className="card-footer p-3">
                  <p className="mb-0">
                    <span className="text-success text-sm font-weight-bolder">
                      +{this.state.new_cust}{" "}
                    </span>
                    customers added this month
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-0 mt-4">
              <div className="card mb-2">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">leaderboard</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">School</p>
                    <h4 className="mb-0">{this.state.all_school}</h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
                <div className="card-footer p-3">
                  <p className="mb-0">
                    <span className="text-success text-sm font-weight-bolder">
                      +{this.state.new_school}{" "}
                    </span>
                    schools added this month
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
              <div className="card mb-2">
                <div className="card-header p-3 pt-2 bg-transparent">
                  <div className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">store</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">Seller</p>
                    <h4 className="mb-0">{this.state.all_seller}</h4>
                  </div>
                </div>
                <hr className="horizontal my-0 dark" />
                <div className="card-footer p-3">
                  <p className="mb-0">
                    <span className="text-success text-sm font-weight-bolder">
                      +{this.state.new_seller}{" "}
                    </span>
                    sellers added this month
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
              <div className="card">
                <div className="card-header p-3 pt-2 bg-transparent">
                  <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">person_add</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">In-active</p>
                    <h4 className="mb-0">0</h4>
                  </div>
                </div>
                <hr className="horizontal my-0 dark" />
                <div className="card-footer p-3">
                  <p className="mb-0">Just updated</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-none">
          <div className="row flex-nowrap overflow-scroll">
            <div className="col">
              <div className="panel-mv mt-3">
                <img
                  style={{ background: "#45454B" }}
                  className="panel-icon-mv d-none"
                  src={require("../../../kavi/icons/all employe.png")}
                  alt="pannel"
                />
                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                  <p
                    className="my-2"
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    All Customer
                  </p>
                  <p
                    className="m-0"
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {this.state.all_cust != null ? (
                      <p>{this.state.all_cust}</p>
                    ) : (
                      // write height and width in pixels
                      <Loadingfade
                        spacing="1"
                        variant="rectangular"
                        height="10px"
                        width="25px"
                      />
                    )}
                  </p>
                </div>
                <p className="mb-0">
                  {this.state.new_school != null ? (
                    <span className="text-success text-sm font-weight-bolder">
                      +{this.state.new_school}{" "}
                    </span>
                  ) : (
                    // write height and width in pixels
                    <Loadingfade
                      spacing="1"
                      variant="rectangular"
                      height="5px"
                      width="15px"
                    />
                  )}
                  schools added this month
                </p>
              </div>
            </div>
            <div className="col">
              <div className="panel-mv mt-3">
                <img
                  style={{ background: "rgb(100, 164, 89)" }}
                  className="d-none panel-icon-mv"
                  src={require("../../../kavi/icons/all employe.png")}
                  alt="pannel"
                />
                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                  <p
                    className="my-2"
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    All School
                  </p>
                  <p
                    className="m-0"
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {this.state.all_school != null ? (
                      <p>{this.state.all_school}</p>
                    ) : (
                      // write height and width in pixels
                      <Loadingfade
                        spacing="1"
                        variant="rectangular"
                        height="10px"
                        width="25px"
                      />
                    )}
                  </p>
                </div>
                <p className="mb-0">
                  {this.state.new_school != null ? (
                    <span className="text-success text-sm font-weight-bolder">
                      +{this.state.new_school}{" "}
                    </span>
                  ) : (
                    // write height and width in pixels
                    <Loadingfade
                      spacing="1"
                      variant="rectangular"
                      height="5px"
                      width="15px"
                    />
                  )}
                  schools added this month
                </p>
              </div>
            </div>
            <div className="col">
              <div className="panel-mv mt-3">
                <img
                  style={{ background: "#D34471" }}
                  className="d-none panel-icon-mv"
                  src={require("../../../kavi/icons/all employe.png")}
                  alt="pannel"
                />
                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                  <p
                    className="my-2"
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    All Seller
                  </p>
                  <p
                    className="m-0"
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {this.state.all_seller != null ? (
                      <p>{this.state.all_seller}</p>
                    ) : (
                      // write height and width in pixels
                      <Loadingfade
                        spacing="1"
                        variant="rectangular"
                        height="10px"
                        width="25px"
                      />
                    )}
                  </p>
                </div>
                <p className="mb-0">
                  {this.state.new_seller != null ? (
                    <span className="text-success text-sm font-weight-bolder">
                      +{this.state.new_seller}{" "}
                    </span>
                  ) : (
                    // write height and width in pixels
                    <Loadingfade
                      spacing="1"
                      variant="rectangular"
                      height="5px"
                      width="15px"
                    />
                  )}
                  sellers added this month
                </p>
              </div>
            </div>
            <div className="col">
              <div className="panel-mv mt-3">
                <img
                  style={{ background: "#5796E8" }}
                  className="d-none panel-icon-mv"
                  src={require("../../../kavi/icons/all employe.png")}
                  alt="pannel"
                />
                <div className="panel-caption-mv d-flex justify-content-between  align-items-center">
                  <p
                    className="my-2"
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    In Active
                  </p>
                  <p
                    className="m-0"
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    0
                  </p>
                </div>
                <p className="mb-0">Just updated</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDataCard);
