import React from "react";
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { axios, modalStyle } from "../../../store/actions/actions";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

class ScheduledVisitDataTable extends React.Component {
  state = {
    visits: null,
    filterVisits: null,
    pages: null,
    pageItems: null,

    open: null,
    msg: null,
  };

  componentDidMount() {
    this.setState({
      visits: this.props.visit,
      filterVisits: this.props.visit.slice(0, 10).map((i) => {
        return i;
      }),
      pages: Math.ceil(this.props.visit.length / 10),
    });

    console.log(this.state.visits, "Visits Hai Bhauiyya");
  }

  handelPagination = (event, value) => {
    let start = 10 * (value - 1);
    let end = 10 * value;
    let items = this.state.visits.slice(start, end).map((i) => {
      return i;
    });

    this.setState({
      filterVisits: items,
    });
    console.log("items", items);
  };

  checkIn = (obj) => {
    const data = {
      customer: obj.customer_id,
      s_visit: obj.id,
      date: new Date().toISOString().slice(0, 10),
      manually: true,
    };

    let endpoint = `trip/`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log(responseData, "Response Data");
      if (responseData.success === true) {
        this.parentCallback();
      } else {
        this.setState({
          msg: responseData.msg,
          open: true,
        });
      }
      console.log({ "response data---------": responseData });
    });
  };

  parentCallback = () => {
    // this.props.loadScheduledVisits();
    window.location.reload();
  };

  render() {
    return (
      <>
        <div className="row" id="clients">
          <div className="col-12">
            <div className="card py-3">
              <h5 className="mb-3 ps-4">Scheduled Visits</h5>
              <div className="table-responsive px-2">
                <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                  <div className="dataTable-top"></div>
                  <div className="dataTable-container" id="employeeTable">
                    {this.state.filterVisits ? (
                      this.state.filterVisits.length > 0 ? (
                        <table className="table align-items-center mb-3 dataTable-table">
                          <thead>
                            <tr>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "4.8032%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortEmpTable("serial_num")
                                  }
                                >
                                  S.NO
                                </a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("name")}
                              >
                                <a className="dataTable-sorter">Schedule by</a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("name")}
                              >
                                <a className="dataTable-sorter">Customer</a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("name")}
                              >
                                <a className="dataTable-sorter">Type</a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("name")}
                              >
                                <a className="dataTable-sorter">Date</a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("status")}
                              >
                                <a className="dataTable-sorter">Time</a>
                              </th>

                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("status")}
                              >
                                <a className="dataTable-sorter">Action</a>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.filterVisits
                              ? this.state.filterVisits.map((item, index) => (
                                  <tr>
                                    <td>
                                      {/*<Link to={'/visit/visit-view/'+item.id}>*/}
                                      <div className="d-flex px-2 py-1">
                                        <div className="d-flex flex-column justify-content-center">
                                          <h6 className="mb-0 font-weight-normal text-sm">
                                            {item.index}
                                          </h6>
                                        </div>
                                      </div>
                                      {/*</Link>*/}
                                    </td>
                                    <td>
                                      {/*<Link to={'/visit/visit-view/'+item.id}>*/}
                                      <div className="d-flex  align-items-center">
                                        <h6 className="mb-0 font-weight-normal text-sm">
                                          {item.scheduled_by__first_name +
                                            " " +
                                            item.scheduled_by__last_name}
                                        </h6>
                                      </div>
                                      {/*</Link>*/}
                                    </td>
                                    <td>
                                      {/*<Link to={'/visit/visit-view/'+item.id}>*/}
                                      <h6 className="mb-0 font-weight-normal text-sm">
                                        {item.customer__company}
                                      </h6>
                                      {/*</Link>*/}
                                    </td>
                                    <td>
                                      <h6 className="mb-0 font-weight-normal text-sm">
                                        {item.customer__type}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="mb-0 font-weight-normal text-sm">
                                        {item.schedule_date}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="mb-0 font-weight-normal text-sm">
                                        {item.schedule_time}
                                      </h6>
                                    </td>
                                    {/*<td>*/}
                                    {/*    <h6 className="mb-0 font-weight-normal text-sm">{item.status}</h6>*/}
                                    {/*</td>*/}
                                    <td className="align-middle text-center">
                                      <div className="d-flex justify-content-center align-items-center">
                                        {this.props.user.employee_full_name ===
                                          item.employee__first_name +
                                            " " +
                                            item.employee__last_name ||
                                        this.props.user.employee_profile ===
                                          "admin" ||
                                        this.props.user.employee_profile ===
                                          "coordinator" ? (
                                          <>
                                            {/*<Link to={"/tour/edit/" + tr.id}>*/}
                                            <ModeEditIcon
                                              style={{
                                                color: "#528dde",
                                                margin: "0px 20px 0px 0px",
                                              }}
                                            />
                                            {/*</Link>*/}
                                            {/*<div onClick={(e) => this.delete(item.id)} >*/}
                                            <DeleteIcon
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                            />
                                            {/*</div>*/}
                                          </>
                                        ) : (
                                          <div className="f-btn sv-f-btn">
                                            {item.status === "Completed" ? (
                                              item.status 
                                            ) : item.check_in === true ? (
                                              <button
                                                style={{
                                                  background: "#75e141",
                                                }}
                                                onClick={(e) =>
                                                  this.checkIn(item)
                                                }
                                              >
                                                Check In
                                              </button>
                                            ) : (
                                              <button
                                                onClick={(e) =>
                                                  this.setState({
                                                    open: true,
                                                    msg: "You are not in the Check-In area.",
                                                  })
                                                }
                                              >
                                                Check In
                                              </button>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              : "null"}
                          </tbody>
                        </table>
                      ) : (
                        <h4 style={{ textAlign: "center", color: "#bbb1b1" }}>
                          No Schedule Visits for today
                        </h4>
                      )
                    ) : null}

                    {this.state.filterVisits &&
                    this.state.filterVisits.length > 0 ? (
                      this.state.pages === 1 ? null : (
                        <div className="datatable-mui-pagination">
                          <Pagination
                            count={this.state.pages}
                            color="secondary"
                            onChange={this.handelPagination}
                            size="large"
                          />
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={(e) => this.setState({ open: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Box sx={modalStyle}>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <div className="modal-body">
                  <div style={{ textAlign: "center" }}>
                    <h5>{this.state.msg}</h5>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ background: "rgb(94 127 255)" }}
                      onClick={(e) => this.setState({ open: false })}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(ScheduledVisitDataTable);
