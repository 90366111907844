import React from "react";
import { connect } from "react-redux";
import "../../../custom/css/srkcutom.css";
import CustomerSalesTable from "./CustomerSalesTable";
import CustomerDataTable from "./CustomerDataTable";
import CustomerDataCard from "./CustomerDataCard";
import { Link, Redirect } from "react-router-dom";
// import Loadingfade from "../../common/Loadingfade";

class CustomerDash extends React.Component {
  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="px-3 d-lg-none">
              <div className="dashOption-mv mt-4">
                <div className="">
                  <div className="button-bar-mv d-flex">
                    <Link
                      to="/customer"
                      className="btn btn-mv active text-white"
                    >
                      <img
                        src={require("../../../kavi/icons/dashbord.png")}
                        alt="btn-icon"
                      />
                      Dashboard
                    </Link>

                    <Link
                      to="/customer/create-customer"
                      className="btn btn-mv text-white active"
                    >
                      <img
                        src={require("../../../kavi/icons/add.png")}
                        alt="btn-icon"
                      />
                      Create
                    </Link>
                    {/* {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'executive' ?
                                <Link to="/customer/create-customer" className="btn btn-mv active"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create</Link>
                              :null} */}
                  </div>
                </div>
              </div>
              {/* <CustomerSalesTable /> */}
              {/* <CustomerDataCard /> */}
              <div className="">
                <CustomerDataTable />
              </div>
            </div>
            <div className="container-fluid homepage-view">
              <div className="row justify-content-end">
                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "d-none d-lg-block col-10"
                      : "d-none d-lg-block col-12"
                  }
                >
                  {/*<CustomerDataCard/>*/}
                  {this.props.user.employee_profile === "admin"? (

                  <CustomerSalesTable />
                  ):null}
                  
                  <CustomerDataTable />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(CustomerDash);
