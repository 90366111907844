import React from "react";
import "../Customer/Style";
import { connect } from "react-redux";
import {
  axios,
  sideBar,
  userLogin,
  google_map_api_key,
  modalStyle,
} from "../../../store/actions/actions";
import { boards, states } from "../../common/objects";
import GoogleMap from "../../GoogleMap/GoogleMap";
import Select from "react-select";
import StarRatings from "react-star-ratings/build/star-ratings";
import { Link, Redirect } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";
import InternetConnection from "../../common/InternetConnection";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

class CreateCustomer extends React.Component {
  state = {
    street_name: null,
    city: null,
    pin_code: null,
    state: null,
    plot_num: null,
    transport_name: null,
    nearest_station: null,
    special_instruction: null,

    bank_name: null,
    account_holder_name: null,
    account: null,
    ifsc: null,
    bank_phone: null,
    remark: null,
    is_default: 0,

    principle_name: null,
    principle_landline: null,
    school_strength: null,
    date_of_finalization: null,

    assign_to: null,
    customer_type: "SCHOOL",
    company: null,
    board: null,
    cust_phone: null,
    cust_mobile: null,
    cust_name: null,
    email: null,
    rating: null,
    gst_num: null,
    pan_num: null,
    website: null,

    address_type: "address",
    lat: null,
    long: null,
    map_address: null,

    users: null,
    open: null,

    expire: false,
    bygoogle: false,
    isTeacher: false,

    customerExist: false,
  };

  componentDidMount() {
    this.toeknExpiryCheck();
    this.toeknExpiryCheck();
    this.getlatlong();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "Update Response data----------": responseData });
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  getlatlong = () => {
    if (this.state.bygoogle === true) {
      this.submit();
    } else {
      let address = `${this.state.company}, ${this.state.city}, ${this.state.state}`;
      let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${google_map_api_key}`;
      
      // let leafapiKey = "9e2f56e07c8141f1a795b4a11dffe23f";
      // let endpoint = `https://api.opencagedata.com/geocode/v1/json?q=${address}&key=${leafapiKey}`;

      // axios.get(endpoint).then((response) => {
      //   const responseData = response.data.results[0].geometry.location;
      //   this.setState({
      //     map_address: address,
      //     lat: responseData.lat,
      //     long: responseData.lng,
      //   });
      //   let sub = setTimeout(this.submit, 300);
      //   console.log({ "get loc response data---------": responseData });
      // });

      axios
        .get(endpoint)
        .then((response) => {
          var data = response.data;
          console.log(data, "-=-=-=-=-=-=-=-=");
          if (data.results.length > 0) {
            var lat = data.results[0].geometry.lat;
            var lon = data.results[0].geometry.lng;
            console.log(lat, lon, "-=-=-=-=-=-=");
            this.setState({
              map_address: address,
              lat: lat,
              long: lon,
            });
            let sub = setTimeout(this.submit, 300);
          } else {
            alert(`City not found`);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }
  };

  PrvNxt = (data) => {
    console.log(data);
    if (data === "info") {
      document.getElementById("show-info").style.display = "block";
      document.getElementById("show-bank").style.display = "none";
      document.getElementById("show-address").style.display = "none";
      document.getElementById("show-auth").style.display = "none";

      if (this.state.customer_type === "SELLER") {
        document.getElementById("bank-progress").classList.remove("js-active");
      }
      document.getElementById("auth-progress").classList.remove("js-active");
      document.getElementById("info-progress").classList.add("js-active");
    }

    if (data === "address") {
      document.getElementById("show-address").style.display = "block";
      document.getElementById("show-info").style.display = "none";
      document.getElementById("show-bank").style.display = "none";
      document.getElementById("show-auth").style.display = "none";
      if (this.state.customer_type === "SELLER") {
        document.getElementById("bank-progress").classList.remove("js-active");
      }
      document.getElementById("auth-progress").classList.remove("js-active");
      document.getElementById("address-progress").classList.add("js-active");
      document.getElementById("info-progress").classList.remove("js-active");
    }

    if (data === "bank") {
      document.getElementById("show-info").style.display = "none";
      document.getElementById("show-bank").style.display = "block";
      document.getElementById("show-address").style.display = "none";
      document.getElementById("show-auth").style.display = "none";
      if (this.state.customer_type === "SELLER") {
        document.getElementById("bank-progress").classList.add("js-active");
      }
      document.getElementById("auth-progress").classList.remove("js-active");
      document.getElementById("address-progress").classList.add("js-active");
      document.getElementById("info-progress").classList.add("js-active");
    }

    if (data === "auth") {
      document.getElementById("show-info").style.display = "none";
      document.getElementById("show-bank").style.display = "none";
      document.getElementById("show-address").style.display = "none";
      document.getElementById("show-auth").style.display = "block";
      if (this.state.customer_type === "SELLER") {
        document.getElementById("bank-progress").classList.add("js-active");
      }
      document.getElementById("auth-progress").classList.add("js-active");
      document.getElementById("address-progress").classList.add("js-active");
      document.getElementById("info-progress").classList.add("js-active");
    }
  };

  MvPrvNxt = (data) => {
    console.log(data);
    if (data === "info") {
      document.getElementById("show-info-mv").style.display = "block";
      document.getElementById("show-bank-mv").style.display = "none";
      document.getElementById("show-address-mv").style.display = "none";
      document.getElementById("show-auth-mv").style.display = "none";

      if (this.state.customer_type === "SELLER") {
        document
          .getElementById("bank-progress-mv")
          .classList.remove("js-active");
      }
      document.getElementById("auth-progress-mv").classList.remove("js-active");
      document.getElementById("info-progress-mv").classList.add("js-active");
    }

    if (data === "address") {
      document.getElementById("show-address-mv").style.display = "block";
      document.getElementById("show-info-mv").style.display = "none";
      document.getElementById("show-bank-mv").style.display = "none";
      document.getElementById("show-auth-mv").style.display = "none";
      if (this.state.customer_type === "SELLER") {
        document
          .getElementById("bank-progress-mv")
          .classList.remove("js-active");
      }
      document.getElementById("auth-progress-mv").classList.remove("js-active");
      document.getElementById("address-progress-mv").classList.add("js-active");
      document.getElementById("info-progress-mv").classList.remove("js-active");
    }

    if (data === "bank") {
      document.getElementById("show-info-mv").style.display = "none";
      document.getElementById("show-bank-mv").style.display = "block";
      document.getElementById("show-address-mv").style.display = "none";
      document.getElementById("show-auth-mv").style.display = "none";
      if (this.state.customer_type === "SELLER") {
        document.getElementById("bank-progress-mv").classList.add("js-active");
      }
      document.getElementById("auth-progress-mv").classList.remove("js-active");
      document.getElementById("address-progress-mv").classList.add("js-active");
      document.getElementById("info-progress-mv").classList.add("js-active");
    }

    if (data === "auth") {
      document.getElementById("show-info-mv").style.display = "none";
      document.getElementById("show-bank-mv").style.display = "none";
      document.getElementById("show-address-mv").style.display = "none";
      document.getElementById("show-auth-mv").style.display = "block";
      if (this.state.customer_type === "SELLER") {
        document.getElementById("bank-progress-mv").classList.add("js-active");
      }
      document.getElementById("auth-progress-mv").classList.add("js-active");
      document.getElementById("address-progress-mv").classList.add("js-active");
      document.getElementById("info-progress-mv").classList.add("js-active");
    }
  };

  submit = () => {
    const data = {
      street_name:
        this.state.getbygoogle === true
          ? this.state.street +
            this.state.block +
            this.state.sector +
            this.state.route +
            this.state.landmark +
            this.state.area
          : this.state.street_name,
      dist: this.state.city,
      pin_code: this.state.pin_code,
      state: this.state.state,
      transport_name: this.state.transport_name,
      nearest_station:
        this.state.nearest_station === null
          ? this.state.city
          : this.state.nearest_station,
      special_instruction: this.state.special_instruction,

      bank_name: this.state.bank_name,
      account_holder_name: this.state.account_holder_name,
      account: this.state.account,
      ifsc: this.state.ifsc,
      bank_phone: this.state.bank_phone,
      remark: this.state.remark,
      is_default: this.state.is_default,

      principle_name: this.state.principle_name,
      principle_landline: this.state.principle_landline,
      school_strength: this.state.school_strength,
      date_of_finalization: this.state.date_of_finalization,

      customer_type: this.state.customer_type,
      company: this.state.company,
      board: this.state.board,
      cust_name: this.state.cust_name,
      cust_phone: this.state.cust_phone,
      cust_mobile: this.state.cust_mobile,
      email: this.state.email,
      gst_num: this.state.gst_num,
      pan_num: this.state.pan_num,
      rating: this.state.rating,
      website: this.state.website,

      address_type: this.state.address_type,
      lat: this.state.lat,
      long: this.state.long,
      map_address: this.state.map_address,
      getbygoogle: null,
    };

    console.log("-------------->", data);

    let token = this.props.user.token;

    let endpoint = `customer/`;
    if (this.state.company !== null && this.state.lat !== null) {
      axios.post(endpoint, data, token).then((res) => {
        let responseData = res.data;
        if (responseData.success === true) {
          this.setState({
            open: true,
          });
        } else if (responseData.msg === "Customer already exist.") {
          this.setState({
            customerExist: true,
          });
        }
        console.log({ "response data---------": responseData });
      });
    } else {
      console.log("failed ho gya bhai ye to.");
    }
  };

  getEstablishmentDetails = (id) => {
    //  -------> call by backend
    let endpoint = `customer/get-establishment-details/${id}`;
    let token = this.props.user.token;
    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;

      console.log({ "**************** response data---------": responseData });

      this.setState({
        company: responseData.name,
        cust_phone: responseData.phone,
        website: responseData.website,
        rating: responseData.rating,

        street: responseData.address.street
          ? responseData.address.street + ", "
          : "",
        city: responseData.address.city,
        pin_code: responseData.address.pin_code,
        state: responseData.address.state,
        area: responseData.address.area ? responseData.address.area + ", " : "",
        route: responseData.address.route
          ? responseData.address.route + ", "
          : "",
        landmark: responseData.address.landmark
          ? responseData.address.landmark + ", "
          : "",
        sector: responseData.address.sector
          ? responseData.address.sector + ", "
          : "",
        block: responseData.address.block
          ? responseData.address.block + ", "
          : "",
        getbygoogle: true,
      });
    });

    //  -------> call by google
    // let endpoint = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${id}&key=${google_map_api_key}`
    // axios
    //     .get(endpoint)
    //     .then((res)=>{
    //             let responseData = res.data;
    //             var address_components = responseData.results[0].address_components;
    //
    //             for (var i = 0; i < address_components.length; i++)
    //             {
    //                if (address_components[i].types[0] === "political") {
    //                     this.setState({area: address_components[i].long_name})
    //                     console.log('area= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "street_number") {
    //                     this.setState({street: address_components[i].long_name})
    //                     console.log('street= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "route") {
    //                     this.setState({route: address_components[i].long_name})
    //                     console.log('route= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "administrative_area_level_1" && address_components[i].types[1] === "political") {
    //                     this.setState({state: address_components[i].short_name})
    //                     console.log('state= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "administrative_area_level_2" && address_components[i].types[1] === "political") {
    //                     this.setState({city: address_components[i].long_name})
    //                     console.log('city= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "postal_code") {
    //                     this.setState({pin_code: address_components[i].long_name})
    //                     console.log('zipcode= '+ address_components[i].long_name)
    //                }
    //             }
    //         });
  };

  handleCallback = (childData) => {
    this.getEstablishmentDetails(childData.place_id);
    this.getInfo(childData.place_id);
    this.setState({
      lat: childData.latLng.lat,
      long: childData.latLng.lng,
      map_address: childData.address,
      bygoogle: true,
    });
  };

  getInfo = (id) => {
    let endpoint = `customer/get-establishment-details/${id}`;
    let token = this.props.user.token;
    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ company: responseData.name });
      this.setState({ cust_phone: responseData.phone });
      this.setState({ website: responseData.website });
      this.setState({ rating: responseData.rating });
      console.log(JSON.stringify(responseData));
    });
  };

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <Online>
              <>
                <button className="btn" onClick={this.getlatlong}>
                  Get Latlong
                </button>
                <div className="container-fluid">
                  <div className="row justify-content-end">
                    <div
                      className={
                        this.props.sidebar === undefined ||
                        this.props.sidebar === true
                          ? "d-none d-lg-block col-10"
                          : "d-none d-lg-block col-12"
                      }
                    >
                      <div className="row mt-5 mb-5 justify-content-center">
                        <div className="col-12">
                          <div className="multisteps-form mb-2">
                            <div className="row">
                              <div className="col-12 col-lg-10 m-auto">
                                <div className="card">
                                  <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                                      <div className="multisteps-form__progress">
                                        <button
                                          className="multisteps-form__progress-btn js-active"
                                          id="address-progress"
                                          onClick={(e) =>
                                            this.PrvNxt("address")
                                          }
                                          type="button"
                                          title="Address"
                                        >
                                          Address
                                        </button>
                                        <button
                                          className="multisteps-form__progress-btn"
                                          id="info-progress"
                                          onClick={(e) => this.PrvNxt("info")}
                                          type="button"
                                          title="User Info"
                                        >
                                          <span>Information</span>
                                        </button>
                                        {this.state.customer_type ===
                                        "SELLER" ? (
                                          <button
                                            className="multisteps-form__progress-btn"
                                            id="bank-progress"
                                            onClick={(e) => this.PrvNxt("bank")}
                                            type="button"
                                            title="Bank Details"
                                          >
                                            Bank Details
                                          </button>
                                        ) : null}
                                        <button
                                          className="multisteps-form__progress-btn"
                                          id="auth-progress"
                                          onClick={(e) => this.PrvNxt("auth")}
                                          type="button"
                                          title="Authorization"
                                        >
                                          Authorization
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <form className="multisteps-form__form">
                                      {/*-----------------------Address----------------------*/}
                                      <div id="show-address">
                                        <div className="row justify-content-center">
                                          <div className="col-md-7 d-none">
                                            <h5 className="font-weight-bold mb-0">
                                              Address
                                            </h5>
                                            <p className="mb-0 text-sm">
                                              Tell us where are you living
                                            </p>

                                            <div className="google_map_class">
                                              <GoogleMap
                                                parentCallback={
                                                  this.handleCallback
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-8">
                                            <div className="multisteps-form__content">
                                              {/*{this.state.address_type==='address'?*/}
                                              <>
                                                <div className="row">
                                                  <div className="col-12">
                                                    <div className="dropdown mt-4">
                                                      <div
                                                        className="m-0 btn w-70 srkw bg-gradient-dark dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        id="navbarDropdownMenuLink2"
                                                        aria-expanded="false"
                                                      >
                                                        {this.state
                                                          .customer_type
                                                          ? this.state
                                                              .customer_type
                                                          : "Select Customer Type"}
                                                      </div>
                                                      <ul
                                                        className="dropdown-menu w-50 srkw"
                                                        aria-labelledby="navbarDropdownMenuLink2"
                                                      >
                                                        <li>
                                                          <div
                                                            className="dropdown-item"
                                                            onClick={(e) =>
                                                              this.setState({
                                                                customer_type:
                                                                  "SCHOOL",
                                                              })
                                                            }
                                                          >
                                                            SCHOOL
                                                          </div>
                                                        </li>
                                                        <li>
                                                          <div
                                                            className="dropdown-item"
                                                            onClick={(e) =>
                                                              this.setState({
                                                                customer_type:
                                                                  "SELLER",
                                                              })
                                                            }
                                                          >
                                                            SELLER
                                                          </div>
                                                        </li>
                                                        <li>
                                                          <div
                                                            className="dropdown-item"
                                                            onClick={(e) =>
                                                              this.setState({
                                                                customer_type:
                                                                  "TEACHER",
                                                              })
                                                            }
                                                          >
                                                            Teacher
                                                          </div>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row mt-3">
                                                  <div className="col-12 ">
                                                    <div className="input-group input-group-dynamic">
                                                      {/*<label className="form-label">Street Name</label>*/}
                                                      <input
                                                        placeholder="Street Name"
                                                        onChange={(e) =>
                                                          this.setState({
                                                            street:
                                                              e.target.value,
                                                          })
                                                        }
                                                        defaultValue={
                                                          this.state.city
                                                            ? this.state
                                                                .street +
                                                              this.state.block +
                                                              this.state
                                                                .sector +
                                                              this.state.route +
                                                              this.state
                                                                .landmark +
                                                              this.state.area
                                                            : null
                                                        }
                                                        className="multisteps-form__input form-control"
                                                        type="text"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row mt-3">
                                                  <div className="col-12 ">
                                                    <div className="input-group input-group-dynamic">
                                                      {/*<label className="form-label">City</label>*/}
                                                      <input
                                                        placeholder="City"
                                                        onChange={(e) =>
                                                          this.setState({
                                                            city: e.target
                                                              .value,
                                                          })
                                                        }
                                                        defaultValue={
                                                          this.state.city
                                                        }
                                                        className="multisteps-form__input form-control"
                                                        type="text"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row mt-3">
                                                  <div className="col-12 ">
                                                    <div className="input-group input-group-dynamic">
                                                      {/*<label className="form-label">Pin Code</label>*/}
                                                      <input
                                                        placeholder="Pin Code"
                                                        onChange={(e) =>
                                                          this.setState({
                                                            pin_code:
                                                              e.target.value,
                                                          })
                                                        }
                                                        defaultValue={
                                                          this.state.pin_code
                                                        }
                                                        className="multisteps-form__input form-control"
                                                        type="text"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row mt-3">
                                                  <div className="col-12 ">
                                                    <div className="">
                                                      {/*<label className="form-label">State</label>*/}
                                                      <Select
                                                        placeholder="Select State"
                                                        value={
                                                          this.state.state
                                                            ? {
                                                                value:
                                                                  this.state
                                                                    .state,
                                                                label:
                                                                  this.state
                                                                    .state,
                                                              }
                                                            : null
                                                        }
                                                        options={states.map(
                                                          (st, index) => ({
                                                            value: st.state,
                                                            label: st.state,
                                                          })
                                                        )}
                                                        onChange={(e) =>
                                                          this.setState({
                                                            state: e.value,
                                                          })
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row mt-3">
                                                  <div className="col-6">
                                                    <div className="input-group input-group-dynamic">
                                                      {/*<label className="form-label">*/}
                                                      {/*  Transport Name*/}
                                                      {/*</label>*/}
                                                      <input
                                                        placeholder="Transport Name"
                                                        onChange={(e) =>
                                                          this.setState({
                                                            transport_name:
                                                              e.target.value,
                                                          })
                                                        }
                                                        className="multisteps-form__input form-control"
                                                        type="text"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-6">
                                                    <div className="input-group input-group-dynamic">
                                                      {/*<label className="form-label">*/}
                                                      {/*  Nearest Station*/}
                                                      {/*</label>*/}
                                                      <input
                                                        placeholder="Nearest Station"
                                                        onChange={(e) =>
                                                          this.setState({
                                                            nearest_station:
                                                              e.target.value,
                                                          })
                                                        }
                                                        className="multisteps-form__input form-control"
                                                        defaultValue={
                                                          this.state.city
                                                        }
                                                        type="text"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row mt-3">
                                                  <div className="col-12">
                                                    <div className="input-group input-group-dynamic">
                                                      {/*<label className="form-label">*/}

                                                      {/*  Special Instruction*/}
                                                      {/*</label>*/}
                                                      <textarea
                                                        placeholder="Special Instruction"
                                                        onChange={(e) =>
                                                          this.setState({
                                                            special_instruction:
                                                              e.target.value,
                                                          })
                                                        }
                                                        className="mt-4 multisteps-form__textarea form-control"
                                                        rows="3"
                                                      ></textarea>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                              {/*:*/}
                                              {/*<div className="google_map_class">*/}
                                              {/*  <GoogleMap parentCallback = {this.handleCallback} />*/}
                                              {/*</div>*/}
                                              {/*}*/}

                                              <div className="button-row d-flex mt-4">
                                                <button
                                                  className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                                  type="button"
                                                  title="Next"
                                                  onClick={(e) =>
                                                    this.PrvNxt("info")
                                                  }
                                                >
                                                  Next
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/*-----------------------Info----------------------*/}
                                      <div
                                        id="show-info"
                                        style={{ display: "none" }}
                                      >
                                        <h5 className="font-weight-bolder mb-0">
                                          Create Your New Account
                                        </h5>
                                        <p className="mb-0 text-sm">
                                          Mandatory informations
                                        </p>
                                        <div className="multisteps-form__content">
                                          <div className="row mt-3">
                                            <div className="col-12 mt-3">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">Company Name</label>*/}
                                                <input
                                                  placeholder="Customer Name"
                                                  defaultValue={
                                                    this.state.company
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      company: e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__input form-control"
                                                  type="text"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 mt-4">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">*/}
                                                {/*  Phone Number*/}
                                                {/*</label>*/}
                                                <input
                                                  placeholder="Phone Number"
                                                  minlength="11"
                                                  maxlength="11"
                                                  defaultValue={
                                                    this.state.cust_phone
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      cust_phone:
                                                        e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__input form-control"
                                                  type="tel"
                                                  pattern="[0-9]{3}-[0-9]{8}"
                                                />
                                              </div>
                                            </div>

                                            <div className="col-12 col-sm-6 mt-4">
                                              <div className="input-group input-group-dynamic">
                                                {boards ? (
                                                  <Autocomplete
                                                    className="pl-1 pr-1"
                                                    id="free-solo-demo"
                                                    size={"small"}
                                                    sx={{ width: "100%" }}
                                                    onChange={(
                                                      event,
                                                      newValue
                                                    ) => {
                                                      this.setState({
                                                        board: newValue,
                                                      });
                                                    }}
                                                    options={boards.map(
                                                      (option) => option.name
                                                    )}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label="Select Board"
                                                      />
                                                    )}
                                                  />
                                                ) : null}
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 mt-4">
                                              <div className="input-group input-group-dynamic">
                                                <label className="form-label">
                                                  Rating:
                                                </label>
                                                {this.state.rating ? (
                                                  <div
                                                    style={{
                                                      marginTop: "5px",
                                                      marginLeft: "50px",
                                                    }}
                                                  >
                                                    <StarRatings
                                                      rating={this.state.rating}
                                                      starDimension="15px"
                                                      starSpacing="1px"
                                                      starRatedColor="yellow"
                                                      name="rating"
                                                    />
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row mt-3">
                                            <div className="col-12 col-sm-12">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">Email</label>*/}
                                                <input
                                                  placeholder="Email"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      email: e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__input form-control"
                                                  type="text"
                                                />
                                              </div>
                                            </div>

                                            <div className="col-12 mt-4">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">Websites</label>*/}
                                                <input
                                                  placeholder="Websites"
                                                  defaultValue={
                                                    this.state.website
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      website: e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__input form-control"
                                                  type="text"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row mt-3">
                                            <div className="col-12 col-sm-6">
                                              <div className="input-group input-group-dynamic">
                                                <input
                                                  placeholder="GST No."
                                                  onChange={(e) =>
                                                    this.setState({
                                                      gst_num: e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__input form-control"
                                                  type="text"
                                                  minlength="15"
                                                  maxlength="15"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                              <div className="input-group input-group-dynamic">
                                                <input
                                                  placeholder="PAN Number"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      pan_num: e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__input form-control"
                                                  type="text"
                                                  maxlength="10"
                                                  minlength="10"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="button-row d-flex mt-4">
                                            <button
                                              className="btn bg-gradient-light mb-0 js-btn-prev"
                                              type="button"
                                              title="Prev"
                                              onClick={(e) =>
                                                this.PrvNxt("address")
                                              }
                                            >
                                              Prev
                                            </button>
                                            <button
                                              className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                              type="button"
                                              title="Next"
                                              onClick={(e) =>
                                                this.state.customer_type ===
                                                "SELLER"
                                                  ? this.PrvNxt("bank")
                                                  : this.PrvNxt("auth")
                                              }
                                            >
                                              Next
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      {/*-----------------------Bank Details----------------------*/}
                                      <div
                                        id="show-bank"
                                        style={{ display: "none" }}
                                      >
                                        <h5 className="font-weight-bolder mb-0">
                                          Bank Deatails
                                        </h5>
                                        {/* <!-- <p className="mb-0 text-sm">Please provide at least one social link</p> --> */}
                                        <div className="multisteps-form__content">
                                          <div className="row mt-3">
                                            <div className="col-12">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">*/}
                                                {/*  Bank Name*/}
                                                {/*</label>*/}
                                                <input
                                                  placeholder="Bank Name"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      bank_name: e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__input form-control"
                                                  type="text"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">*/}
                                                {/*  Account Number*/}
                                                {/*</label>*/}
                                                <input
                                                  placeholder="Account Number"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      account: e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__input form-control"
                                                  type="text"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">*/}
                                                {/*  Account Holder Name*/}
                                                {/*</label>*/}
                                                <input
                                                  placeholder="Account Holder Name"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      account_holder_name:
                                                        e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__input form-control"
                                                  type="text"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">remark:</label>*/}
                                                <div className="col-12 mt-3">
                                                  <div className="input-group input-group-dynamic">
                                                    <textarea
                                                      placeholder="Remark"
                                                      onChange={(e) =>
                                                        this.setState({
                                                          remark:
                                                            e.target.value,
                                                        })
                                                      }
                                                      className="multisteps-form__textarea form-control"
                                                      rows="3"
                                                    ></textarea>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">*/}
                                                {/*  Phone Number*/}
                                                {/*</label>*/}
                                                <input
                                                  placeholder="Phone Number"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      bank_phone:
                                                        e.target.value,
                                                    })
                                                  }
                                                  pattern="[0-9]{3}-[0-9]{8}"
                                                  className="multisteps-form__input form-control"
                                                  type="tel"
                                                  minlength="11"
                                                  maxlength="11"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                              <div className="input-group input-group-dynamic">
                                                {/*<label className="form-label">*/}
                                                {/*  IFSC:*/}
                                                {/*</label>*/}
                                                <input
                                                  placeholder="IFSC Code"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      ifsc: e.target.value,
                                                    })
                                                  }
                                                  pattern="[0-9]{3}-[0-9]{8}"
                                                  className="multisteps-form__input form-control"
                                                  type="tel"
                                                  minlength="11"
                                                  maxlength="11"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="button-row d-flex mt-4 col-12">
                                              <button
                                                className="btn bg-gradient-light mb-0 js-btn-prev"
                                                type="button"
                                                title="Prev"
                                                onClick={(e) =>
                                                  this.PrvNxt("info")
                                                }
                                              >
                                                Prev
                                              </button>
                                              <button
                                                className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                                type="button"
                                                title="Next"
                                                onClick={(e) =>
                                                  this.PrvNxt("auth")
                                                }
                                              >
                                                Next
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/*-----------------------Information----------------------*/}
                                      <div
                                        id="show-auth"
                                        style={{ display: "none" }}
                                      >
                                        <h5 className="font-weight-bolder mb-0">
                                          Authorised By:
                                        </h5>
                                        {/* <!-- <p className="mb-0 text-sm">Optional informations</p> --> */}
                                        <div className="multisteps-form__content mt-3">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <div className="col-12 mt-3">
                                                <div className="input-group input-group-dynamic">
                                                  {/*<label className="form-label">*/}
                                                  {/*  Name of Principle*/}
                                                  {/*</label>*/}
                                                  <input
                                                    placeholder="Name of Principle"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        principle_name:
                                                          e.target.value,
                                                      })
                                                    }
                                                    className="multisteps-form__input form-control"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-6 mt-3">
                                                <div className="row">
                                                  <div className="col-12">
                                                    {/*<label className="form-label">Date</label>*/}
                                                  </div>
                                                  <div className="col-12">
                                                    <div className="input-group input-group-dynamic">
                                                      <input
                                                        placeholder="Date"
                                                        onChange={(e) =>
                                                          this.setState({
                                                            date_of_finalization:
                                                              e.target.value,
                                                          })
                                                        }
                                                        className="multisteps-form__input form-control"
                                                        type="date"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="col-12 mt-3">
                                                <div className="input-group input-group-dynamic">
                                                  {/*<label className="form-label">*/}
                                                  {/*  Landline*/}
                                                  {/*</label>*/}
                                                  <input
                                                    placeholder="Landline"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        principle_landline:
                                                          e.target.value,
                                                      })
                                                    }
                                                    className="multisteps-form__input form-control"
                                                    type="tel"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-6 mt-3">
                                                <div className="row">
                                                  <div className="col-12">
                                                    {/*<label className="form-label">Strength</label>*/}
                                                  </div>
                                                  <div className="col-12">
                                                    <div className="input-group input-group-dynamic">
                                                      <input
                                                        placeholder="Strength"
                                                        onChange={(e) =>
                                                          this.setState({
                                                            school_strength:
                                                              e.target.value,
                                                          })
                                                        }
                                                        className="multisteps-form__input form-control"
                                                        type="number"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="button-row d-flex mt-4">
                                            <button
                                              onClick={(e) =>
                                                this.state.customer_type ===
                                                "SELLER"
                                                  ? this.PrvNxt("bank")
                                                  : this.PrvNxt("info")
                                              }
                                              className="btn bg-gradient-light mb-0 js-btn-prev"
                                              type="button"
                                              title="Prev"
                                            >
                                              Prev
                                            </button>
                                            <button
                                              className="btn bg-gradient-dark ms-auto mb-0"
                                              type="button"
                                              title="Send"
                                              onClick={this.getlatlong}
                                            >
                                              {/* Desktop */}
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>

                                  <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={this.state.open}
                                    onClose={(e) =>
                                      this.setState({ open: false })
                                    }
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                      timeout: 500,
                                    }}
                                  >
                                    <Fade in={this.state.open}>
                                      <Box sx={modalStyle}>
                                        {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                        {/*  Text in a modal*/}
                                        {/*</Typography>*/}
                                        <Typography
                                          id="transition-modal-description"
                                          sx={{ mt: 2 }}
                                        >
                                          <div className="modal-body">
                                            <div className="circle__box">
                                              <div className="circle__wrapper circle__wrapper--right">
                                                <div className="circle__whole circle__right"></div>
                                              </div>
                                              <div className="circle__wrapper circle__wrapper--left">
                                                <div className="circle__whole circle__left"></div>
                                              </div>
                                              <div className="circle__checkmark"></div>
                                            </div>
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <h5>
                                                Customer Created Successfully.
                                              </h5>
                                              <h6>
                                                <Link
                                                  to="/customer"
                                                  style={{ color: "grey" }}
                                                >
                                                  Click here{" "}
                                                </Link>{" "}
                                                for go to customer dashboard.
                                              </h6>
                                            </div>
                                          </div>
                                        </Typography>
                                      </Box>
                                    </Fade>
                                  </Modal>

                                  {/* Customer Already Exist */}

                                  <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={this.state.customerExist}
                                    onClose={(e) =>
                                      this.setState({ customerExist: false })
                                    }
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                      timeout: 500,
                                    }}
                                  >
                                    <Fade in={this.state.customerExist}>
                                      <Box sx={modalStyle}>
                                        {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                        {/*  Text in a modal*/}
                                        {/*</Typography>*/}
                                        <Typography
                                          id="transition-modal-description"
                                          sx={{ mt: 2, py: 5 }}
                                        >
                                          <div style={{ textAlign: "center" }}>
                                            <h5>Customer Already Created.</h5>
                                          </div>
                                        </Typography>
                                      </Box>
                                    </Fade>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* mobile view */}

                <div className="d-lg-none">
                  <div className="dashOption-mv mt-4 px-2">
                    <div className="">
                      <div className="button-bar-mv d-flex">
                        <Link to="/customer" className="btn btn-mv">
                          <img
                            src={require("../../../kavi/icons/dashbord.png")}
                            alt="btn-icon"
                          />
                          Dashboard
                        </Link>

                        {this.props.user.employee_profile === "admin" ? (
                          <Link
                            to="/customer/create-customer"
                            className="btn btn-mv active"
                          >
                            <img
                              src={require("../../../kavi/icons/add.png")}
                              alt="btn-icon"
                            />
                            Create
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className=" row mt-3 mb-5 px-2 justify-content-center">
                    <div className="col-12">
                      <div className="multisteps-form mb-2">
                        <div
                          className="card-header p-0 mb-3"
                          style={{ borderRadius: "10px" }}
                        >
                          <div className="pt-4 pb-3">
                            <div className="multisteps-form__progress">
                              <button
                                className="multisteps-form__progress-btn js-active"
                                id="address-progress-mv"
                                onClick={(e) => this.MvPrvNxt("address")}
                                type="button"
                                title="Address"
                              >
                                Address
                              </button>
                              <button
                                className="multisteps-form__progress-btn"
                                id="info-progress-mv"
                                onClick={(e) => this.MvPrvNxt("info")}
                                type="button"
                                title="User Info"
                              >
                                <span>Information</span>
                              </button>
                              {this.state.customer_type === "SELLER" ? (
                                <button
                                  className="multisteps-form__progress-btn"
                                  id="bank-progress-mv"
                                  onClick={(e) => this.MvPrvNxt("bank")}
                                  type="button"
                                  title="Bank Details"
                                >
                                  Bank Details
                                </button>
                              ) : null}
                              <button
                                className="multisteps-form__progress-btn"
                                id="auth-progress-mv"
                                onClick={(e) => this.MvPrvNxt("auth")}
                                type="button"
                                title="Authorization"
                              >
                                Authorization
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-lg-10 m-auto">
                            <div className="card">
                              <div className="card-body">
                                <form className="multisteps-form__form">
                                  {/*-----------------------Address----------------------*/}
                                  <div id="show-address-mv">
                                    <div className="row justify-content-center">
                                      <div className="col-md-7 d-none">
                                        <h5
                                          className="font-weight-bold mb-0"
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Address
                                        </h5>
                                        <p
                                          className="my-2"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500 !important",
                                            color: "#656565 !important",
                                          }}
                                        >
                                          Tell us where are you living
                                        </p>

                                        <div className="google_map_class">
                                          <GoogleMap
                                            parentCallback={this.handleCallback}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-msd-5 col-md-8 mb-5">
                                        <div className="multisteps-form__content">
                                          {/*{this.state.address_type==='address'?*/}
                                          <>
                                            <div className="row">
                                              <div className="col-12">
                                                <div className="dropdown dropdown-mv mt-4">
                                                  <div
                                                    className="m-0 btn w-100 srkw srkw-mv dropdown-toggle d-flex justify-content-between align-items-center"
                                                    data-bs-toggle="dropdown"
                                                    id="navbarDropdownMenuLink2"
                                                    aria-expanded="false"
                                                  >
                                                    {this.state.customer_type
                                                      ? this.state.customer_type
                                                      : "Select Customer Type"}
                                                  </div>
                                                  <ul
                                                    className="dropdown-menu w-50 srkw"
                                                    style={{
                                                      background: "#404643",
                                                    }}
                                                    aria-labelledby="navbarDropdownMenuLink2"
                                                  >
                                                    <li>
                                                      <div
                                                        className="dropdown-item"
                                                        onClick={(e) =>
                                                          this.setState({
                                                            customer_type:
                                                              "SCHOOL",
                                                          })
                                                        }
                                                      >
                                                        SCHOOL
                                                      </div>
                                                    </li>
                                                    <li>
                                                      <div
                                                        className="dropdown-item"
                                                        onClick={(e) =>
                                                          this.setState({
                                                            customer_type:
                                                              "SELLER",
                                                          })
                                                        }
                                                      >
                                                        SELLER
                                                      </div>
                                                    </li>
                                                    <li>
                                                      <div
                                                        className="dropdown-item"
                                                        onClick={(e) =>
                                                          this.setState({
                                                            customer_type:
                                                              "TEACHER",
                                                          })
                                                        }
                                                      >
                                                        TEACHER
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-12 ">
                                                <label>Street Name</label>
                                                <div className="input-group input-group-dynamic">
                                                  <input
                                                    placeholder="Street Name"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        street: e.target.value,
                                                      })
                                                    }
                                                    defaultValue={
                                                      this.state.city
                                                        ? this.state.street +
                                                          this.state.block +
                                                          this.state.sector +
                                                          this.state.route +
                                                          this.state.landmark +
                                                          this.state.area
                                                        : null
                                                    }
                                                    className="multisteps-form__input form-control form-control-mv"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-12 ">
                                                <label className="">City</label>
                                                <div className="input-group input-group-dynamic">
                                                  <input
                                                    placeholder="City"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        city: e.target.value,
                                                      })
                                                    }
                                                    defaultValue={
                                                      this.state.city
                                                    }
                                                    className="multisteps-form__input form-control form-control-mv"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-12 ">
                                                <label className="form-label">
                                                  Pin Code
                                                </label>
                                                <div className="input-group input-group-dynamic">
                                                  <input
                                                    placeholder="Pin Code"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        pin_code:
                                                          e.target.value,
                                                      })
                                                    }
                                                    defaultValue={
                                                      this.state.pin_code
                                                    }
                                                    className="multisteps-form__input form-control form-control-mv"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-12 ">
                                                <div className="">
                                                  <label className="form-label">
                                                    State
                                                  </label>
                                                  <Select
                                                    placeholder="Select State"
                                                    value={
                                                      this.state.state
                                                        ? {
                                                            value:
                                                              this.state.state,
                                                            label:
                                                              this.state.state,
                                                          }
                                                        : null
                                                    }
                                                    options={states.map(
                                                      (st, index) => ({
                                                        value: st.state,
                                                        label: st.state,
                                                      })
                                                    )}
                                                    onChange={(e) =>
                                                      this.setState({
                                                        state: e.value,
                                                      })
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-6">
                                                <label className="form-label">
                                                  Transport Name
                                                </label>
                                                <div className="input-group input-group-dynamic">
                                                  <input
                                                    placeholder="Transport Name"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        transport_name:
                                                          e.target.value,
                                                      })
                                                    }
                                                    className="multisteps-form__input form-control form-control-mv"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-6">
                                                <label className="form-label">
                                                  Nearest Station
                                                </label>
                                                <div className="input-group input-group-dynamic">
                                                  <input
                                                    placeholder="Nearest Station"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        nearest_station:
                                                          e.target.value,
                                                      })
                                                    }
                                                    className="multisteps-form__input form-control form-control-mv"
                                                    defaultValue={
                                                      this.state.city
                                                    }
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-12">
                                                <label className="form-label">
                                                  Special Instruction
                                                </label>
                                                <div className="input-group input-group-dynamic">
                                                  <textarea
                                                    placeholder="Special Instruction"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        special_instruction:
                                                          e.target.value,
                                                      })
                                                    }
                                                    className="mt-1 multisteps-form__textarea form-control form-control-mv"
                                                    rows="3"
                                                  ></textarea>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                          {/*:*/}
                                          {/*<div className="google_map_class">*/}
                                          {/*  <GoogleMap parentCallback = {this.handleCallback} />*/}
                                          {/*</div>*/}
                                          {/*}*/}

                                          <div className="button-row d-flex mt-4">
                                            <button
                                              className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                              type="button"
                                              title="Next"
                                              onClick={(e) =>
                                                this.MvPrvNxt("info")
                                              }
                                            >
                                              Next
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/*-----------------------Info----------------------*/}
                                  <div
                                    id="show-info-mv"
                                    style={{ display: "none" }}
                                  >
                                    <h5 className="font-weight-bolder mb-0">
                                      Create Your New Account
                                    </h5>
                                    <p className="mb-0 text-sm">
                                      Mandatory informations
                                    </p>
                                    <div className="multisteps-form__content">
                                      <div className="row mt-3">
                                        <div className="col-12 mt-3">
                                          <label>Customer Name</label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Customer Name"
                                              defaultValue={this.state.company}
                                              onChange={(e) =>
                                                this.setState({
                                                  company: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mt-4">
                                          <label className="form-label">
                                            Phone Number
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            {boards ? (
                                              <Autocomplete
                                                className="pl-1 pr-1"
                                                id="free-solo-demo"
                                                size={"small"}
                                                sx={{ width: "100%" }}
                                                onChange={(event, newValue) => {
                                                  this.setState({
                                                    board: newValue,
                                                  });
                                                }}
                                                options={boards.map(
                                                  (option) => option.name
                                                )}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Select Board"
                                                  />
                                                )}
                                              />
                                            ) : null}
                                          </div>
                                        </div>

                                        <div className="col-12 col-sm-6 mt-4">
                                          <label className="form-label">
                                            Phone Number
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Phone Number"
                                              minlength="11"
                                              maxlength="11"
                                              defaultValue={
                                                this.state.cust_phone
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  cust_phone: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="tel"
                                              pattern="[0-9]{3}-[0-9]{8}"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mt-4">
                                          <label>Rating:</label>
                                          <div className="input-group input-group-dynamic">
                                            {this.state.rating ? (
                                              <div
                                                style={{
                                                  marginTop: "5px",
                                                  marginLeft: "50px",
                                                }}
                                              >
                                                <StarRatings
                                                  rating={this.state.rating}
                                                  starDimension="15px"
                                                  starSpacing="1px"
                                                  starRatedColor="yellow"
                                                  name="rating"
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-3">
                                        <div className="col-12 col-sm-12">
                                          <label>Email</label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Email"
                                              onChange={(e) =>
                                                this.setState({
                                                  email: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-12 mt-4">
                                          <label>Websites</label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Websites"
                                              defaultValue={this.state.website}
                                              onChange={(e) =>
                                                this.setState({
                                                  website: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-3">
                                        <div className="col-6 col-sm-6">
                                          <label>GST No.</label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="GST No."
                                              onChange={(e) =>
                                                this.setState({
                                                  gst_num: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                              minlength="15"
                                              maxlength="15"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-6 col-sm-6 mt-sm-0">
                                          <label>Pan Number</label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="PAN Number"
                                              onChange={(e) =>
                                                this.setState({
                                                  pan_num: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                              maxlength="10"
                                              minlength="10"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="button-row d-flex mt-4">
                                        <button
                                          className="btn btn-mv bg-gradient-light mb-0 js-btn-prev"
                                          type="button"
                                          title="Prev"
                                          onClick={(e) =>
                                            this.MvPrvNxt("address")
                                          }
                                        >
                                          Prev
                                        </button>
                                        <button
                                          className="btn btn-mv bg-gradient-dark ms-auto mb-0 js-btn-next"
                                          type="button"
                                          title="Next"
                                          onClick={(e) =>
                                            this.state.customer_type ===
                                            "SELLER"
                                              ? this.MvPrvNxt("bank")
                                              : this.MvPrvNxt("auth")
                                          }
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  {/*-----------------------Bank Details----------------------*/}
                                  <div
                                    id="show-bank-mv"
                                    className="mb-5"
                                    style={{ display: "none" }}
                                  >
                                    <h5 className="font-weight-bolder mb-0">
                                      Bank Details
                                    </h5>
                                    {/* <!-- <p className="mb-0 text-sm">Please provide at least one social link</p> --> */}
                                    <div className="multisteps-form__content">
                                      <div className="row mt-3">
                                        <div className="col-12">
                                          <label className="form-label">
                                            Bank Name
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Bank Name"
                                              onChange={(e) =>
                                                this.setState({
                                                  bank_name: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <label className="form-label">
                                            Account Number
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Account Number"
                                              onChange={(e) =>
                                                this.setState({
                                                  account: e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <label className="form-label">
                                            Account Holder Name
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Account Holder Name"
                                              onChange={(e) =>
                                                this.setState({
                                                  account_holder_name:
                                                    e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <label className="form-label">
                                            remark:
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <div className="col-12 mt-3">
                                              <div className="input-group input-group-dynamic">
                                                <textarea
                                                  placeholder="Remark"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      remark: e.target.value,
                                                    })
                                                  }
                                                  className="multisteps-form__textarea form-control"
                                                  rows="3"
                                                ></textarea>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <label className="form-label">
                                            Phone Number
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Phone Number"
                                              onChange={(e) =>
                                                this.setState({
                                                  bank_phone: e.target.value,
                                                })
                                              }
                                              pattern="[0-9]{3}-[0-9]{8}"
                                              className="multisteps-form__input form-control"
                                              type="tel"
                                              minlength="11"
                                              maxlength="11"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <label className="form-label">
                                            IFSC:
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="IFSC Code"
                                              onChange={(e) =>
                                                this.setState({
                                                  ifsc: e.target.value,
                                                })
                                              }
                                              pattern="[0-9]{3}-[0-9]{8}"
                                              className="multisteps-form__input form-control"
                                              type="tel"
                                              minlength="11"
                                              maxlength="11"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="button-row d-flex mt-4 col-12">
                                          <button
                                            className="btn btn-mv bg-gradient-light mb-0 js-btn-prev"
                                            type="button"
                                            title="Prev"
                                            onClick={(e) =>
                                              this.MvPrvNxt("info")
                                            }
                                          >
                                            Prev
                                          </button>
                                          <button
                                            className="btn btn-mv bg-gradient-dark ms-auto mb-0 js-btn-next"
                                            type="button"
                                            title="Next"
                                            onClick={(e) =>
                                              this.MvPrvNxt("auth")
                                            }
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/*-----------------------Information----------------------*/}
                                  <div
                                    id="show-auth-mv"
                                    className="mb-5"
                                    style={{ display: "none" }}
                                  >
                                    <h5 className="font-weight-bolder mb-0">
                                      Authorised By:
                                    </h5>
                                    {/* <!-- <p className="mb-0 text-sm">Optional informations</p> --> */}
                                    <div className="multisteps-form__content mt-3">
                                      <div className="row">
                                        <div className="col-12 mt-3">
                                          <label className="form-label">
                                            Name of Principle
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Name of Principle"
                                              onChange={(e) =>
                                                this.setState({
                                                  principle_name:
                                                    e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <label className="form-label">
                                            Date
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Date"
                                              onChange={(e) =>
                                                this.setState({
                                                  date_of_finalization:
                                                    e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="date"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <label className="form-label">
                                            Landline
                                          </label>
                                          <div className="input-group input-group-dynamic">
                                            <input
                                              placeholder="Landline"
                                              onChange={(e) =>
                                                this.setState({
                                                  principle_landline:
                                                    e.target.value,
                                                })
                                              }
                                              className="multisteps-form__input form-control"
                                              type="tel"
                                            />
                                          </div>
                                          <div className="col-12 mt-3">
                                            <label className="form-label">
                                              Strength
                                            </label>
                                            <div className="input-group input-group-dynamic">
                                              <input
                                                placeholder="Strength"
                                                onChange={(e) =>
                                                  this.setState({
                                                    school_strength:
                                                      e.target.value,
                                                  })
                                                }
                                                className="multisteps-form__input form-control"
                                                type="number"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="button-row d-flex mt-4">
                                        <button
                                          onClick={(e) =>
                                            this.state.customer_type ===
                                            "SELLER"
                                              ? this.MvPrvNxt("bank")
                                              : this.MvPrvNxt("info")
                                          }
                                          className="btn btn-mv bg-gradient-light mb-0 js-btn-prev"
                                          type="button"
                                          title="Prev"
                                        >
                                          Prev
                                        </button>
                                        <button
                                          className="btn btn-mv bg-gradient-dark ms-auto mb-0"
                                          type="button"
                                          title="Send"
                                          // onClick={(e) => this.submit()}
                                          onClick={this.getlatlong}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>

                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={this.state.open}
                                onClose={(e) => this.setState({ open: false })}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 500,
                                }}
                              >
                                <Fade in={this.state.open}>
                                  <Box sx={modalStyle}>
                                    {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                    {/*  Text in a modal*/}
                                    {/*</Typography>*/}
                                    <Typography
                                      id="transition-modal-description"
                                      sx={{ mt: 2 }}
                                    >
                                      <div className="modal-body">
                                        <div className="circle__box">
                                          <div className="circle__wrapper circle__wrapper--right">
                                            <div className="circle__whole circle__right"></div>
                                          </div>
                                          <div className="circle__wrapper circle__wrapper--left">
                                            <div className="circle__whole circle__left"></div>
                                          </div>
                                          <div className="circle__checkmark"></div>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <h5>
                                            Customer Created Successfully.
                                          </h5>
                                          <h6>
                                            <Link
                                              to="/customer"
                                              style={{ color: "grey" }}
                                            >
                                              Click here{" "}
                                            </Link>{" "}
                                            for go to customer dashboard.
                                          </h6>
                                        </div>
                                      </div>
                                    </Typography>
                                  </Box>
                                </Fade>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </Online>
            <Offline>
              <InternetConnection />
            </Offline>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomer);
