import React from "react";
import { connect } from "react-redux";
import {axios, url, userLogin, modalStyle} from "../../../store/actions/actions";
import { Link, Redirect } from "react-router-dom";
import "../../../custom/css/srkcutom.css";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {components} from "react-select";

class TeamDash extends React.Component {

    state = {
        teams: null,
        filterTeams: [],

        open: false,
        delete_team_id: null,
        is_delete: false
    }

    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };


    componentDidMount() {
        this.loadTeams()
        this.toeknExpiryCheck()
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

    loadTeams = () => {
        this.setState({open: false})
        let endpoint = `teams/dash`
        let token = this.props.user.token
        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                this.setState({
                    teams: responseData,
                    filterTeams: responseData
                })

                console.log({ 'Team response data---------': responseData })
            })
    }

    handleSearch = (data) => {
        let obj = this.state.teams
        let filterTeams = obj.filter((i)=>(i.name.toLowerCase().includes(data.toLowerCase())))
        this.setState({
            filterTeams: filterTeams
        })
    }


    delete = (data) => {
        this.setState({
            open: true,
            delete_team_id: data
        })
        if (data==='delete') {
            let endpoint = `teams/${this.state.delete_team_id}`
            let token = this.props.user.token

            axios
                .delete(endpoint, token)
                .then((response) => {
                    const responseData = response.data;
                    this.setState({
                        is_delete: true
                    })
                    console.log({'delete response data---------': responseData})
                });
            }
    }

    render() {
        return (
            <>
                {this.props.user.login === true ?
                    <>
                        <div className="container-fluid homepage-view">
                            <div className="row justify-content-end">
                                <div
                                    className={
                                        this.props.sidebar === undefined || this.props.sidebar === true
                                            ? "d-none d-lg-block col-10"
                                            : "d-none d-lg-block col-12"
                                    }
                                >

                                    <div className="">
                                        <div className="d-flex justify-content-end"><TextField size="small" id="outlined-basic" label="Search team..." variant="outlined" onChange={(e)=>this.handleSearch(e.target.value)}/></div>

                                        <div className="row">

                                            {this.state.filterTeams.length!==0 ? this.state.filterTeams.map((team, index) => (
                                                <div className="col-md-4">
                                                    <div className="team-card mt-3">
                                                        <div className="card w-100  my-2  p-3" style={{ width: "" }}>
                                                            <img className="card-img-top" style={{ height: "150px" }} src={team.logo===null?require('../../../custom/img/Team-default.jpg'):url + team.logo} alt="Card image cap" />
                                                            <div className="card-body p-0">
                                                                <h5 className="card-title" style={{textTransform: "uppercase"}}>{team.name}</h5>
                                                                <div className="btn-box d-flex justify-content-between align-items-center">
                                                                    <Link to={`/team/team-view/${team.id}`} className="btn" style={{ background: "#404643", padding: "10px 15px", borderRadius: ".25rem" }}>View Team</Link>
                                                                    {this.props.user.employee_profile !== 'executive' ?
                                                                    <>
                                                                    <Link to={`team/edit-team/${team.id}`} className="btn" style={{ background: "#64A459", padding: "10px 15px", borderRadius: ".25rem" }}>Edit</Link>

                                                                    </>
                                                                    :null}

                                                                    {this.props.user.employee_profile === 'admin' ?
                                                                    <>
                                                                    <button onClick={(e) => this.delete(team.id)} className="btn" style={{ background: "#B62619", padding: "10px 15px", borderRadius: ".25rem" }}>Delete Team</button>
                                                                    </>
                                                                    :null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) :
                                                <div className='col-12'>
                                                    <h1>No Teams Found</h1>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={this.state.open}
                                        onClose={(e) => this.setState({ open: false })}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                            timeout: 500,
                                        }}
                                    >
                                        <Fade in={this.state.open}>
                                            <Box sx={modalStyle}>
                                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                    {this.state.is_delete ?
                                                        <>
                                                            <div className="circle__box">
                                                                <div className="circle__wrapper circle__wrapper--right">
                                                                    <div className="circle__whole circle__right delete_circle__right"></div>
                                                                </div>
                                                                <div className="circle__wrapper circle__wrapper--left">
                                                                    <div className="circle__whole circle__left delete_circle__left"></div>
                                                                </div>
                                                                <div className="circle__checkmark delete_circle__checkmark"
                                                                >
                                                                </div>
                                                            </div>

                                                            <div style={{textAlign: 'center'}}>
                                                                <h5>Team deleted successfully.</h5>
                                                                <h6><div onClick={this.loadTeams} style={{color: "grey", cursor: "pointer"}} >Click
                                                                    here </div>to go Teams Dashboard</h6>
                                                            </div>
                                                        </>

                                                        :

                                                        <div className="modal-body">
                                                            <div style={{textAlign: 'center'}}>
                                                                <h5>Click the Delete button to delete Team.</h5>
                                                                <button type="button" className="btn btn-danger"
                                                                        onClick={(e)=>this.delete('delete')}>Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </Typography>
                                            </Box>
                                        </Fade>
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        <div className="d-lg-none px-2">
                        <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.open}
                        onClose={(e) => this.setState({ open: false })}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.open}>
                            <Box sx={modalStyle}>
                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                    {this.state.is_delete ?
                                        <>
                                            <div className="circle__box">
                                                <div className="circle__wrapper circle__wrapper--right">
                                                    <div className="circle__whole circle__right delete_circle__right"></div>
                                                </div>
                                                <div className="circle__wrapper circle__wrapper--left">
                                                    <div className="circle__whole circle__left delete_circle__left"></div>
                                                </div>
                                                <div className="circle__checkmark delete_circle__checkmark"
                                                >
                                                </div>
                                            </div>

                                            <div style={{textAlign: 'center'}}>
                                                <h5>Team deleted successfully.</h5>
                                                <h6><div onClick={this.loadTeams} style={{color: "grey", cursor: "pointer"}} >Click
                                                    here </div>to go Teams Dashboard</h6>
                                            </div>
                                        </>

                                        :

                                        <div className="modal-body">
                                            <div style={{textAlign: 'center'}}>
                                                <h5>Click the Delete button to delete Team.</h5>
                                                <button type="button" className="btn btn-danger"
                                                        onClick={(e)=>this.delete('delete')}>Delete
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>

                  <div className="dashOption-mv mt-4">
                                        <div className="">
                                            <div className="button-bar-mv d-flex">
                                                <Link to='/team' className="btn btn-mv active"><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                                {this.props.user.employee_profile === 'admin' ?
                                                <Link to="/team/create-team" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create</Link>
                                                :null}
                                                {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                                <Link to="/account/team-account" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create Budget</Link>
                                                :null}
                                                {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                                <Link to="/account/team-transactions" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Transactions</Link>
                                                :null}
                                            </div>
                                        </div>
                              </div>
                              {this.props.user.employee_profile !== "executive"? (
                                    <div className="search-mv d-flex ms-md-auto m-md-2 mt-4 m-0">
                                        <div className="position-relative w-100">
                                            <input className="form-control  form-control-mv input w-100" style={{paddingLeft: '30px'}} type="text" placeholder="Search" />
                                            <img className="search-input-icon-mv" src={require("../../../kavi/icons/search.png")} alt="search-icon" />
                                        </div>
                                    </div>
                                    ):null}
                                    <div className="team-card-mv mt-3">
                                        <div className="row">
                                            {this.state.teams ? this.state.teams.map((team, index) => (
                                                <div className="col-md-4">
                                                    <div className="card w-100  my-2  p-3" style={{ width: "" }}>
                                                        <img src={url + team.logo} className="card-img-top" alt="Card image cap" />
                                                        <div className="card-body p-0">
                                                            <h5 className="card-title text-uppercase">{team.name}</h5>
                                                            <div className="btn-box-mv d-flex justify-content-between align-items-center">
                                                                <Link to={`/team/team-view/${team.id}`}>
                                                                <button className="btn btn-mv" style={{ background: "#404643", padding: "10px 15px", borderRadius: ".25rem" }}>View Team</button>
                                                                </Link>
                                                                {this.props.user.employee_profile !== "executive"? (
                                                                 <Link to={`team/edit-team/${team.id}`}>
                                                                 <button className="btn btn-mv" style={{ background: "#64A459", padding: "10px 15px", borderRadius: ".25rem" }}>Edit</button>
                                                                 </Link>
                                                                ):null}
                                                                <>
                                                               
                                                                {this.props.user.employee_profile === "admin"? (
                                                                 <button onClick={(e) => this.delete(team.id)} className="btn btn-mv" style={{ background: "#B62619", padding: "10px 15px", borderRadius: ".25rem" }}>Delete Team</button>
                                                                ):null}
                                                                </>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : null}
                                        </div>
                                    </div>
                                </div>



                    </>
                    :
                    <Redirect to={'/sign-in'} />
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamDash);
