import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import {google_map_api_key} from "../../store/actions/actions";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      address: '',

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      place_id: null,

      mapCenter: {
        lat: this.props.lat,
        lng: this.props.lng
      }
    };
  }


  render() {
    return (
      <>
        <div className='d-none d-lg-block'>
          <div id='googleMaps' className="position-relative"
            style={{
              height: "172px"
            }}
        >
          <div className="google_map">
          <Map
            google={this.props.google}
            style={{
              height: "50%"
            }}
            initialCenter={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng
            }}
            center={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng
            }}
          >
          </Map>
          </div>
        </div>
        </div>
      
        <div className='d-lg-none'>
        <div id='googleMaps' className="position-relative"
          style={{
            height: "172px"
          }}
      >
        <div className="google_map">
        <Map
          google={this.props.google}
          style={{
            height: "100%"
          }}
          initialCenter={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
          center={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
        >
        </Map>
        </div>
      </div>
        </div>
      </>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: (google_map_api_key)
})(MapContainer)