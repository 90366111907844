import React from "react";
import {connect} from "react-redux";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";

class VisitDataTable extends React.Component {

    state = {
        visits: null,
        filterVisits: null,
        pages: null,
        pageItems: null,
    }

    componentDidMount() {

        this.setState({
            visits: this.props.visit,
            filterVisits: this.props.visit.slice(0, 10).map(i => {return i}),
            pages: Math.ceil(this.props.visit.length/10)
        })
    }

    handelPagination = (event, value) => {
        let start = 10 * (value - 1)
        let end = 10 * value
        let items = this.state.visits.slice(start, end).map(i => {
            return i
        });

        this.setState({
            filterVisits: items,    
        })
        console.log('items', items)
    }

    render() {
        return(
          <>
             <div className="mb-5 row my-3" id="clients">
                  <div className="col-12">
                      <div className="card py-3">
                              <div className="table-responsive px-2">
                                  <div
                                      className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                      <div className="dataTable-top">

                                          <h5 className="mb-0 ps-3" >Incomplete Visits</h5>
                                      </div>
                                      <div className="dataTable-container" id='employeeTable'>
                                          {this.props.visit.length!==0?
                                          <>
                                          <table className="table align-items-center mb-3 dataTable-table">
                                              <thead>
                                              <tr>
                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "4.8032%", cursor: 'pointer'}}>
                                                      <a
                                                      // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                      className="dataTable-sorter"
                                                      onClick={(e)=>this.sortEmpTable("serial_num")}>S.NO
                                                  </a></th>
                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("name")}
                                                  ><a
                                                      className="dataTable-sorter">Name
                                                  </a></th><th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("name")}
                                                  ><a
                                                      className="dataTable-sorter">Customer
                                                  </a></th>
                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("state")}
                                                      >
                                                      <a
                                                      className="dataTable-sorter text-center">
                                                      Purpose</a></th>
                                                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("status")}
                                                      >
                                                      <a
                                                      className="dataTable-sorter">
                                                      Question</a></th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {/*{this.props.visit.map((item, index)=>(*/}
                                                  {this.state.filterVisits?this.state.filterVisits.map((item, index)=>(
                                                      <tr>
                                                          <td>
                                                          <Link to={'/visit/visit-view/'+item.id}>
                                                              <div className="d-flex px-2 py-1">
                                                                  <div
                                                                      className="d-flex flex-column justify-content-center">
                                                                      <h6 className="mb-0 font-weight-normal text-sm">{item.index}
                                                                      </h6>
                                                                  </div>
                                                              </div>
                                                          </Link>

                                                          </td>
                                                          <td>
                                                          <Link to={'/visit/visit-view/'+item.id}>
                                                              <div className="d-flex  align-items-center">
                                                                  <h6 className="mb-0 font-weight-normal text-sm">{item.employee?item.employee:null}</h6>
                                                              </div>
                                                          </Link>
                                                          </td>
                                                          <td >

                                                          <Link to={'/visit/visit-view/'+item.id}>
                                                             <h6 className="mb-0 font-weight-normal text-sm">
                                                                  {item.customer ? item.customer.customer.company: null}
                                                             </h6>
                                                          </Link>
                                                          </td>
                                                          <td>
                                                          <Link to={'/visit/visit-view/'+item.id}>
                                                              <h6 className="mb-0 font-weight-normal text-sm">{item.purpose?item.purpose:null}</h6>
                                                          </Link>
                                                          </td>
                                                          <td className="align-middle text-center">
                                                          <Link to={'/visit/visit-view/'+item.id}>
                                                              {item.quest!==null && item.quest.length!==0?item.quest.map((vt, index)=>(
                                                                  <div style={{height: item.quest.length>=3?"30px":"none", overflow: "overlay", display: "block"}}>
                                                                      <p className="text-sm text-center font-weight-normal mb-0">
                                                                          {vt}
                                                                      </p>
                                                                        {item.quest.length > 1 ?<hr/>:null}
                                                                  </div>
                                                              )):null}
                                                          </Link>
                                                          </td>
                                                      </tr>
                                              )):null}
                                              </tbody>
                                          </table>
                                          {this.state.pages===1?null:
                                              <div className="datatable-mui-pagination">
                                                <Pagination count={this.state.pages} color="secondary" onChange={this.handelPagination} size="large"/>
                                              </div>
                                          }
                                          </>
                                              :
                                          <h4 style={{textAlign: 'center', color: '#bbb1b1'}}>
                                              No Visits for today
                                          </h4>}
                                      </div>
                                  </div>
                              </div>
                      </div>
                  </div>
            </div>
          </>
        )
    }
}

const mapStateToProps = state =>({
    user:state.userData.userData,
    sidebar:state.sideBarData.sidebarData.sidebar
})

export default connect(mapStateToProps)(VisitDataTable)