import React, { Component } from "react";
import { axios } from "../../../store/actions/actions";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { url } from "axios";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Customloader from "../../common/Loader/Customloader";
import VModal from "../../common/ValidationModal/VModal";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  minHeight: "140px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

class LeafMapActivity extends Component {
  state = {
    lang_lat: null,
    all_employee: null,
    employee: null,
    f_date: null,
    t_date: null,
    s_date: null,
    position: null,
    loader: false,
    validation_modal: false,
    errorMessage: null,
  };

  componentDidMount() {
    this.loadSelectEmployees();
    this.initializeMap();
  }

  // Handling Modal
  // handleOpen = () => {
  //   setOpen(true);
  // };

  handleClose = () => {
    this.setState({
      validation_modal: false,
    });
  };

  // Fetch Employee List
  loadSelectEmployees = () => {
    let endpoint = `account/get-employees`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      console.log(responseData, "All Employee")
      this.setState({
        all_employee: responseData.employee,
      });
    });
  };

  // Leaf Map Settings
  initializeMap = () => {
    this.map = L.map("map").setView([22.552048, 80.571861], 5);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
      maxZoom: 18,
    }).addTo(this.map);

    this.markers = L.layerGroup().addTo(this.map);
  };

  // Leaf Map Settings After Post Request
  leaf_settings = (positions) => {
    if (this.markers) {
      positions.forEach((position) => {
        L.marker(position).addTo(this.markers);
      });
    }
  };

  // OnClick Submit Button
  submit = () => {
    this.setState(
      {
        validation_modal:false,
      }
    )
    if (this.state.employee === null) {
      this.setState({
        validation_modal: true,
        errorMessage:
          "Select Any Users And Choose Any Date Either Single Date or range between two days",
      });
    } else if (this.state.s_date === null) {
      if (this.state.f_date === null && this.state.t_date === null) {
        this.setState({
          validation_modal: true,
          errorMessage: "Select Any Date Either Single Or Range Date",
        });
      } else {
        this.loadData();
      }
    } else {
      this.loadData();
    }
  };

  loadData = () => {
    this.setState({
      loader: true,
    });

    const data = {
      emp: this.state.employee,
      s_date: this.state.s_date,
      f_date: this.state.f_date,
      t_date: this.state.t_date,
    };

    let endpoint = `http://localhost:8000/employee/wert`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let finalData = [];
      let responseData = res.data;

      responseData.srklang.forEach((item, index) => {
        let coordinates = item.punch_in_lat_lng
          .split(",")
          .map((coord) => parseFloat(coord));
        finalData.push(coordinates);
      });

      this.setState({
        lang_lat: finalData,
        position: finalData,
      });

      this.leaf_settings(finalData);
      this.setState({
        loader: false,
      });
    });
  };

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="d-lg-block">
              <div className="container-fluid homepage-view">
                <div className="row justify-content-end">
                  <div
                    className={
                      this.props.sidebar === undefined ||
                      this.props.sidebar === true
                        ? "col-md-10"
                        : "col-12"
                    }
                  >
                    <div className="row my-md-0 my-4 align-items-center">
                      {this.state.all_employee ? (
                        <div className="col-md-3">
                          <div className="form-check-inline w-100">
                            <>
                              <Autocomplete
                                disablePortal
                                size="small"
                                id="combo-box-demo"
                                options={this.state.all_employee}
                                onChange={(e, value) =>
                                  this.setState({ employee: value.id })
                                }
                                getOptionLabel={(option) =>
                                  option.last_name
                                    ? option.first_name + " " + option.last_name
                                    : option.first_name
                                }
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{
                                      "& > img": {
                                        mr: 2,
                                        flexShrink: 0,
                                      },
                                    }}
                                    {...props}
                                  >
                                    <img
                                      style={{ borderRadius: "50%" }}
                                      // loading="lazy"
                                      width="30"
                                      // src={option.pic?url + option.pic:url+'/static/agent.png'}
                                      srcSet={`${url + option.pic} 2x`}
                                      alt=""
                                    />
                                    {option.last_name
                                      ? option.first_name +
                                        " " +
                                        option.last_name
                                      : option.first_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Employee"
                                  />
                                )}
                              />
                            </>
                          </div>
                        </div>
                      ) : null}

                      <div className="col-md-3">
                        <div className="col-12 p-1 w-100">
                          <div className="d-flex mt-2 p-1 align-items-center w-100">
                            <TextField
                              id="date"
                              label="Date"
                              type="date"
                              size="small"
                              onChange={(e) =>
                                this.setState({ s_date: e.target.value })
                              }
                              sx={{ width: "100%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="col-12 p-1 w-100">
                          <div className="d-flex mt-2 p-1 align-items-center w-100">
                            <TextField
                              id="date"
                              label="From"
                              type="date"
                              size="small"
                              onChange={(e) =>
                                this.setState({ f_date: e.target.value })
                              }
                              sx={{ width: "50%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />

                            <p
                              className="mx-4 my-0"
                              style={{ fontSize: "24px" }}
                            >
                              to
                            </p>

                            <TextField
                              id="date"
                              label="To"
                              type="date"
                              size="small"
                              onChange={(e) =>
                                this.setState({ t_date: e.target.value })
                              }
                              sx={{ width: "50%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-1">
                        <div className="f-btn map-activity-submit">
                          <button onClick={this.submit}>Submit</button>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12"></div>
                      <div className="col-12 position-relative">
                        <p>
                          {this.state.loader == true ? <Customloader /> : null}
                        </p>
                        <div
                          id="map"
                          style={{ height: "800px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Handling Modal  */}
            <Modal
              style={{ zIndex: 1000000 }}
              open={this.state.validation_modal}
              onClose={this.handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box sx={{ ...style, width: "auto" }}>
                <h4 id="child-modal-title">Please Enter Correct Details</h4>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{this.state.errorMessage}</Alert>
                </Stack>
              </Box>
            </Modal>

            <div className="d-lg-none mt-4">
              <div className="container-fluid">
                <div className="row justify-content-end">
                  <div>
                    <div
                      id="map"
                      style={{ height: "800px", width: "1500px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(LeafMapActivity);
