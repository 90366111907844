import React from "react";
import {connect} from "react-redux";
import {axios, userLogin, modalStyle} from "../../../store/actions/actions";
import Loader from "../../common/Loader/Loader";
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import '../../common/common.css'
import TextField from '@mui/material/TextField';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DataCards from "./DataCards";
import {Link, Redirect} from "react-router-dom";


class SRDataTable extends React.Component {

    state = {
        samples: null,
        quan: null,
        total: null,
        filterSamples: null,

        // sort
        nameSort: null,
        stateSort: null,
        stationSort: null,
        citySort: null,
        divisionSort: null,

        // pagination
        page: null,

        open: false,
        index_page: null,
    }

    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    componentDidMount() {
        this.loadSamples()
    }

    loadSamples = () => {
        this.setState({open: false})
        let endpoint = `books/data-table`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                console.log({'Samples response data-------------->':responseData})
                this.setState({samples: responseData.samples})
                this.setState({filterSamples: responseData.samples})
                this.setState({quan: responseData.count})
                this.setState({page: responseData.page})
                this.setState({total: responseData.count})
            });
    }

    sampleApproveOrDenied = (id, status) => {
        let data = {
            id: id,
            status: status
        }
        let endpoint = `books/sample-status-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((response) => {
                const responseData = response.data;
                this.loadSamples()
                console.log({'Status update response data------------->':responseData})
            });
    }

    delete = (id) => {
        let data = {
            id: id
        }
        let endpoint = `books/sample-request-delete`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((response) => {
                const responseData = response.data;
                this.loadSamples()
                console.log({'Sample delete response data------------->':responseData})
            });
    }

    handlePage = (e, p) => {
        console.log('--------------------',p)
        this.setState({index_page: p===1?0:(p-1)*10})
        const data = {
            from: p===1?0:(p-1)*10,
            to: p===1?10:p*10
        }
        console.log('--------------------',data)
        let endpoint = `books/pagination`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({filterSamples: responseData.employee})
                console.log({'Pagination response data---------': responseData})
            });
    }

    printTable = () => {
        //Get the HTML of div
        var divElements = document.getElementById('employeeTable').innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML =
          "<html><head><title></title></head><body>" +
          divElements + "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;

    }

  render() {
    return (
        <>
            {this.props.user.login === true ?
                <>
                    <div className="px-3">
                        <div className="row justify-content-end">
                            <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-md-10" : "col-12"}>
                                {/* <DataCards/> */}
                                <div className="row my-3" id="clients">
                                      <div className="col-12">
                                          <div className="card py-3">
                                              {this.state.samples ?
                                                  <div className="table-responsive px-2">
                                                      <div
                                                          className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                                          <div className="dataTable-top">

                                                              <h5 className="mb-0 ps-3" >Sample Requests</h5>
                                                              <div className=" d-flex justify-content-end align-items-center" style={{position: "relative"}}>
                                                                  <LocalPrintshopIcon className='cursor-pointer me-3' onClick={this.printTable}/>
                                                                  <TextField size="small" id="outlined-basic" className="d-none" label="Search..." variant="outlined" onChange={this.handleSearch}/>
                                                              </div>
                                                          </div>
                                                          <div className="dataTable-container" id='employeeTable'>
                                                              <table className="table align-items-center mb-3 dataTable-table">
                                                                  <thead>
                                                                  <tr>
                                                                      <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                          data-sortable="" style={{width: "4.8032%", cursor: 'pointer'}}>
                                                                          <a
                                                                          // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                                          className="dataTable-sorter">S.NO
                                                                      </a></th>
                                                                      <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                          data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                                          onClick={(e)=>this.sortEmpTable("name")}
                                                                      ><a
                                                                          className="dataTable-sorter">Employee
                                                                      </a></th>
                                                                      <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                                          data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                                          onClick={(e)=>this.sortEmpTable("state")}
                                                                          >
                                                                          <a
                                                                          className="dataTable-sorter text-center">
                                                                          Book</a></th>
                                                                      <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                                          data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                                          onClick={(e)=>this.sortEmpTable("state")}
                                                                          >
                                                                          <a
                                                                          className="dataTable-sorter text-center">
                                                                          Class</a></th>
                                                                      <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                                          data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                                          onClick={(e)=>this.sortEmpTable("city")}
                                                                          >
                                                                          <a
                                                                          className="dataTable-sorter d-block text-center">
                                                                          Quantity</a></th>
                                                                      <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                          data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                                          onClick={(e)=>this.sortEmpTable("status")}
                                                                          >
                                                                          <a
                                                                          className="dataTable-sorter">
                                                                          Price</a></th><th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                          data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                                          onClick={(e)=>this.sortEmpTable("status")}
                                                                          >
                                                                          <a
                                                                          className="dataTable-sorter">
                                                                          Status</a></th>
                                                                      <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                          data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                                          onClick={(e)=>this.sortEmpTable("division")}
                                                                          >
                                                                          <a
                                                                          className="dataTable-sorter">
                                                                          Date Time</a></th>
                                                                      <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                          data-sortable="" style={{width: "15%"}}><a
                                                                          className="dataTable-sorter">
                                                                          Action</a></th>
                                                                  </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                  {this.state.filterSamples ? this.state.filterSamples.map((item, index) => (
                                                                      index <= this.state.quan ?
                                                                          <tr>
                                                                              <td>
                                                                                  <div className="d-flex px-2 py-1">
                                                                                      <div
                                                                                          className="d-flex flex-column justify-content-center">
                                                                                          <h6 className="mb-0 font-weight-normal text-sm">{index+1+this.state.index_page}
                                                                                          </h6>
                                                                                      </div>
                                                                                  </div>
                                                                              </td>
                                                                              <td>
                                                                                  <p className="text-sm text-center font-weight-normal mb-0">
                                                                                      {item.employee_name}
                                                                                  </p>
                                                                              </td>
                                                                              <td>
                                                                                  <p className="text-sm text-center font-weight-normal mb-0">
                                                                                      {item.book_name}
                                                                                  </p>
                                                                              </td>
                                                                              <td>
                                                                                  <p className="text-sm text-center font-weight-normal mb-0">
                                                                                      {/*{item.book[0].classes.class_name+' - '+item.book[item.book.length-1].classes.class_name}*/}
                                                                                      {item.class_name}
                                                                                  </p>
                                                                              </td>
                                                                              <td>
                                                                                  <p className="text-sm text-center font-weight-normal mb-0">
                                                                                      {item.quantity}
                                                                                  </p>
                                                                              </td>
                                                                              <td>
                                                                                  <p className="text-sm text-center font-weight-normal mb-0">
                                                                                      {item.price}
                                                                                  </p>
                                                                              </td>
                                                                              <td>
                                                                                  <p className="text-sm text-center font-weight-normal mb-0">
                                                                                      {item.status}
                                                                                  </p>
                                                                              </td>
                                                                              <td>
                                                                                  <p className="text-sm text-center font-weight-normal mb-0">

                                                                                      {item.timeStamp?
                                                                                        new Date(item.timeStamp).toLocaleString('en-IN', {
                                                                                            day: 'numeric',
                                                                                            month: 'short',
                                                                                            year: 'numeric',
                                                                                            hour: 'numeric',
                                                                                            minute: 'numeric',
                                                                                            hour12: true
                                                                                        }):null}
                                                                                  </p>
                                                                              </td>
                                                                              <td className="align-middle text-center">
                                                                                  <div className="d-flex justify-content-center align-items-center">
                                                                                      {/*<Link to={"/tour/view/" + item.id}>*/}
                                                                                      {/*<RemoveRedEyeIcon style={{color: "grey", margin:"0px 20px 0px 0px"}}/>*/}
                                                                                  {/*</Link>*/}
                                                                                  {/*<Link to={"/tour/edit/" + item.id}>*/}
                                                                                  {/*    <ModeEditIcon style={{color: "#528dde", margin:"0px 20px 0px 0px"}}/>*/}
                                                                                  {/*</Link>*/}
                                                                                  <div onClick={(e)=>this.sampleApproveOrDenied(item.id, 'Approved')}>
                                                                                      <CheckIcon style={{color: "#12b312", cursor: "pointer", margin:"0px 20px 0px 0px"}}/>
                                                                                  </div>
                                                                                  <div onClick={(e)=>this.sampleApproveOrDenied(item.id, 'Rejected')}>
                                                                                      <CloseIcon style={{color: "#de2f2f", cursor: "pointer", margin:"0px 20px 0px 0px"}}/>
                                                                                  </div>
                                                                                  <div onClick={(e) => this.delete(item.id)} >
                                                                                      <DeleteIcon style={{color: "red", cursor: "pointer"}}/>
                                                                                  </div>
                                                                                  </div>
                                                                              </td>
                                                                          </tr>
                                                                          : null
                                                                  )) : null}
                                                                  </tbody>
                                                              </table>
                                                          </div>
                                                          {this.state.page===1?null:
                                                              <div className="datatable-mui-pagination">
                                                                <Pagination count={this.state.page} color="secondary" onChange={this.handlePage} size="large"/>
                                                          </div>}
                                                      </div>
                                                  </div>
                                                  :
                                                  <Loader/>
                                              }
                                            <Modal
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                open={this.state.open}
                                                onClose={(e) => this.setState({ open: false })}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                    timeout: 500,
                                                }}
                                            >
                                                <Fade in={this.state.open}>
                                                    <Box sx={modalStyle}>
                                                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                            {this.state.is_delete ?
                                                                <>
                                                                    <div className="circle__box">
                                                                        <div className="circle__wrapper circle__wrapper--right">
                                                                            <div className="circle__whole circle__right delete_circle__right"></div>
                                                                        </div>
                                                                        <div className="circle__wrapper circle__wrapper--left">
                                                                            <div className="circle__whole circle__left delete_circle__left"></div>
                                                                        </div>
                                                                        <div className="circle__checkmark delete_circle__checkmark"
                                                                        >
                                                                        </div>
                                                                    </div>

                                                                    <div style={{textAlign: 'center'}}>
                                                                        <h5>Employee deleted successfully.</h5>
                                                                        <h6><div onClick={this.loadEmployeePageWithAxios} style={{color: "grey", cursor: "pointer"}} >Click
                                                                            here </div>to go Employee Dashboard</h6>
                                                                    </div>
                                                                </>

                                                                :

                                                                <div className="modal-body">
                                                                    <div style={{textAlign: 'center'}}>
                                                                        <h5>Click the Delete button to delete this Employee.</h5>
                                                                        <button type="button" className="btn btn-danger"
                                                                                onClick={(e)=>this.delete('delete')}>Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Fade>
                                            </Modal>

                                          </div>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
                :
                <Redirect to={'/sign-in'} />
            }
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
    user:state.userData.userData
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SRDataTable);

