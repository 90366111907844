import React, { useEffect, useState } from 'react'
import { axios, url } from '../../store/actions/actions';
import ReactDOM from 'react-dom';
import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css';
import { connect } from "react-redux";

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import AcUnitIcon from '@mui/icons-material/AcUnit';


const board = {
    columns: [
        {
            id: 1,
            title: 'Created',
            cards: [
                {
                    id: 1,
                    title: 'Amber Series Book Class-4',
                    description: 'Change The Book Cover',
                },
                {
                    id: 2,
                    title: 'Open Skies Book-5',
                    description: 'Remove All Audio From Every page.',
                },
                {
                    id: 3,
                    title: 'Kasturi Book-2',
                    description: 'Fix Bug In Questions Bank',
                },
            ],
        },
        {
            id: 2,
            title: 'Progress',
            cards: [],
        },
        {
            id: 3,
            title: 'Complete',
            cards: [],
        },
    ],
};

function Uncontrolled(props) {

    const [srflist, setSrfList] = useState(null);


    useEffect(() => {
        getAllEmployees()
    }, [])

    const getAllEmployees = () => {
        let token = props.user.token
        let endpoint = "common/load-employee"
        axios.get(endpoint, token).then((res) => {
            console.log(res)
            setSrfList(res.data.srf)
        })
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-end">
                <div className="col-md-10">
                    <div>
                        <Board
                            allowRemoveLane
                            allowRenameColumn
                            allowRemoveCard
                            onLaneRemove={console.log}
                            onCardRemove={console.log}
                            onLaneRename={console.log}
                            initialBoard={board}
                            allowAddCard={{ on: "top" }}
                            onNewCardConfirm={draftCard => ({
                                id: new Date().getTime(),
                                ...draftCard
                            })}
                            onCardNew={console.log}
                        />
                    </div>
                </div>
                <div className="col-md-10">

                    <VerticalTimeline >
                        {srflist ? (
                            srflist.length > 0 ? (
                                srflist.map((item, index) => {

                                    const datetimeString = item.created_at;

                                    const dateObject = new Date(datetimeString);

                                    const formattedDate = dateObject.toLocaleDateString();

                                    return (
                                        <VerticalTimelineElement
                                            key={index}
                                            className="vertical-timeline-element--work"
                                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                            date={`${formattedDate}`} // Combine date and year
                                            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                            icon={<AcUnitIcon />}
                                            sx={{borderRight:"1px solid red"}}
                                        >

                                            {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                                            <div className="d-flex justify-content-between">
                                                <p className='mt-0'>
                                                    <span> <b>From:</b> {item.froms.first_name} {item.froms.last_name ? item.froms.last_name : null} </span>
                                                </p>

                                                <p className='mt-0'>
                                                    <span> <b>to:</b> {item.to.first_name} {item.to.last_name ? item.to.last_name : null} </span>
                                                </p>
                                            </div>

                                            {/* Loop through item.cc */}
                                            <p className="vertical-timeline-element-title mt-0">
                                                <span className='fw-normal fs-6'><b>CC:</b> </span>
                                                {item.cc.map((ccitem, index) => (
                                                    <>
                                                        <span key={index}>
                                                            {ccitem.first_name} {ccitem.last_name ? ccitem.last_name : null}
                                                        </span>{item.cc.length > 1 ? "," :null}&nbsp;
                                                    </>
                                                ))}
                                            </p>

                                            <div className="d-flex justify-content-between">
                                                <p className='mt-0'>
                                                    <span> <b>Category:</b> {item.category ? item.category : null} </span>
                                                </p>

                                                <p className='mt-0'>
                                                    <span> <b>Purpose:</b> {item.purpose ? item.purpose : null} </span>
                                                </p>
                                            </div>

                                            <p className="vertical-timeline-element-title mt-0">
                                                <span className='fw-normal fs-6'><b>Message:</b> </span>{item.message ? item.message : null}
                                            </p>

                                            <p className="vertical-timeline-element-title mt-0">
                                                <span className='fw-normal fs-6'><b>Created By:</b> </span> {item.created_by.first_name} {item.last_name ? item.last_name : null}
                                            </p>
                                        </VerticalTimelineElement>
                                    );
                                })
                            ) : null
                        ) : null}



                    </VerticalTimeline>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(Uncontrolled);

// export default ;
