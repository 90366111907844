import React from "react";
import { Link } from "react-router-dom";
import './Modal.css'
import {axios} from "../../../store/actions/actions";
import {connect} from "react-redux";

class DeleteModal extends React.Component {

    state ={
        is_delete: false,
    }

    componentDidMount() {
        document.getElementById("openModal").click()
        console.log('open modal')
    }

    delete = () => {
        let endpoint = this.props.endpoint
        let token = this.props.user.token
        axios
            .delete(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({
                    is_delete: true
                })
                console.log({'response data---------':responseData})
            });
    }

  render() {

    return (
        <>
            <button type="button" id="openModal" style={{display: "none"}} className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop">
            </button>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{right: "-100px"}}
                    >
                        <div className="modal-body">
                            <div className="modal-header">
                                <h5 className="modal-title">Modal title</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            {this.state.is_delete === false ?
                                <div>
                                    <h5>{this.props.confirmation_text}</h5>
                                    <button type="button" className="btn btn-danger" onClick={this.delete}>Delete</button>
                                </div>
                                :
                                <>
                                    <div className="circle__box">
                                        <div className="circle__wrapper circle__wrapper--right">
                                            <div className="circle__whole circle__right delete_circle__right"></div>
                                        </div>
                                        <div className="circle__wrapper circle__wrapper--left">
                                            <div className="circle__whole circle__left delete_circle__left"></div>
                                        </div>
                                        <div className="circle__checkmark delete_circle__checkmark"
                                        >
                                        </div>
                                    </div>

                                    <div style={{textAlign: 'center'}}>
                                        <h5>{this.props.text}</h5>
                                        <h6><Link to={this.props.link} style={{color: "grey"}}>Click
                                            here </Link>{this.props.urlText}</h6>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
  }
}

const mapStateToProps = state =>({
    user:state.userData.userData
})

export default connect(mapStateToProps)(DeleteModal)
