import React from "react";
import {connect} from "react-redux";
import {axios, url, userLogin} from "../../../store/actions/actions";
import {Link} from "react-router-dom";
import DeleteModal from "../../common/Modals/deleteModal";
import Loader from "../../common/Loader/Loader";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Pagination from '@mui/material/Pagination';
import '../../common/common.css'
import FilterListIcon from '@mui/icons-material/FilterList';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';


class EmpDataTable extends React.Component {

    state = {
        tar: null,
        filter_tar: null,
        quan: null,
        total: null,

        del_name: null,
        del_endpoint: null,
        showModal: false,

        // sort
        nameSort: null,
        amountSort: null,
        statusSort: null,
        dateSort: null,
        // divisionSort: null,

        // pagination
        page: null,

        // filter
        filter: false,
        showfilter: false,
        fromDate: null,
        toDate: null,
        clear: false,

        index_page: null
    }

    componentDidMount() {
        this.loadTarRequests()
    }

    loadTarRequests = () => {
        let endpoint = `account/tar-datatable`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({tr: responseData.tar})
                this.setState({filter_tar: responseData.tar})
                this.setState({quan: responseData.count})
                this.setState({page: responseData.page})
                this.setState({total: responseData.count})
                console.log({'tour response data---------':responseData.tar})
            });
    }

    handleSearch = (e) => {
        let text = e.target.value
        let endpoint = `account/search-tar?search=${text}`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({filter_tar: responseData})
                console.log({'response data---------': responseData})
            });
    }

    handlePage = (e, p) => {
        console.log('--------------------',p)
        this.setState({index_page: p===1?0:(p-1)*10})
        const data = {
            from: p===1?0:(p-1)*10,
            to: p===1?10:p*10
        }
        console.log('--------------------',data)
        let endpoint = `account/tar-pagination`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({filter_tar: responseData.tar})
                console.log({'response data---------': responseData})
            });
    }

    approve = (id1, id2, id3, amount) => {

        const data = {
            tar_id: id1,
            employee_id: id2,
            team_acc_id: id3,
            amount: amount
        }
        console.log(data)
        let endpoint = `account/tar-approve`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((response) => {
                const responseData = response.data;
                if (responseData.success===true) {
                    this.loadTarRequests()
                }
                console.log({'response data---------': responseData})
            });
    }
    
    denied = (id) => {
        const data = {
            tar_id: id
        }
        let endpoint = `account/tar-denied`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((response) => {
                const responseData = response.data;
                if (responseData.success===true) {
                    this.loadTarRequests()
                }
                console.log({'response data---------': responseData})
            });
    }

    sortEmpTable = (data) => {
        let arr = this.state.filter_tar

        if (data==='name') {
            if (this.state.nameSort===null || this.state.nameSort==='lower') {
                this.setState({
                    nameSort: 'upper'
                })
                arr.sort(function (a, b) {
                    if (a.first_name.toLowerCase() < b.first_name.toLowerCase())
                        return -1;
                    return 0;
                })
            }

            if (this.state.nameSort==="upper") {
                this.setState({
                    nameSort: 'lower'
                })
                arr.sort(function (a, b) {
                    if (a.first_name.toLowerCase() > b.first_name.toLowerCase())
                        return -1;
                    return 0;
                })
            }
            this.setState({
                filter_tar: arr
            })
        }

        if (data==='state') {
            if (this.state.stateSort===null || this.state.stateSort==='lower') {
                this.setState({
                    stateSort: 'upper'
                })
                arr.sort(function (a, b) {
                    let state_a = a.state.map((st, index) => (st.state.toLowerCase()))
                    let state_b = b.state.map((st, index) => (st.state.toLowerCase()))

                    if (state_a < state_b
                    ) return -1;
                    return 0;
                })
            }

            if (this.state.stateSort==='upper') {
                this.setState({
                    stateSort: 'lower'
                })
                arr.sort(function (a, b) {
                    let state_a = a.state.map((st, index) => (st.state.toLowerCase()))
                    let state_b = b.state.map((st, index) => (st.state.toLowerCase()))

                    if (state_a > state_b
                    ) return -1;
                    return 0;
                })
            }

            this.setState({
                filter_tar: arr
            })
        }

        if (data==='city') {
            if (this.state.citySort===null || this.state.citySort==='lower') {
                this.setState({
                    citySort: 'upper'
                })
                arr.sort(function (a, b) {
                    let station_a = a.station.map((st, index) => (st.station.toLowerCase()))
                    let station_b = b.station.map((st, index) => (st.station.toLowerCase()))

                    if (station_a < station_b
                    ) return -1;
                    return 0;
                })
            }

            if (this.state.citySort==='upper') {
                this.setState({
                    citySort: 'lower'
                })
                arr.sort(function (a, b) {
                    let station_a = a.station.map((st, index) => (st.station.toLowerCase()))
                    let station_b = b.station.map((st, index) => (st.station.toLowerCase()))

                    if (station_a > station_b
                    ) return -1;
                    return 0;
                })
            }

            this.setState({
                filter_tar: arr
            })
        }

        if (data==='status') {
            if (this.state.statusSort===null || this.state.statusSort==='lower') {
                this.setState({
                    statusSort: 'upper'
                })
                arr.sort(function (a, b) {
                    let status_a = a.is_admin === true ? 'Admin' : a.is_manager === true ? 'Manager' : a.is_executive === true ? 'Executive' : null
                    let status_b = b.is_admin === true ? 'Admin' : b.is_manager === true ? 'Manager' : b.is_executive === true ? 'Executive' : null

                    if (status_a < status_b
                    ) return -1;
                    return 0;
                })
            }
            if (this.state.statusSort==='upper') {
                this.setState({
                    statusSort: 'lower'
                })
                arr.sort(function (a, b) {
                    let status_a = a.is_admin === true ? 'Admin' : a.is_manager === true ? 'Manager' : a.is_executive === true ? 'Executive' : null
                    let status_b = b.is_admin === true ? 'Admin' : b.is_manager === true ? 'Manager' : b.is_executive === true ? 'Executive' : null

                    if (status_a > status_b
                    ) return -1;
                    return 0;
                })
            }

            this.setState({
                filter_tar: arr
            })
        }

        if (data==='division') {
            if (this.state.divisionSort===null || this.state.divisionSort==='lower') {
                this.setState({
                    divisionSort: 'upper'
                })
                arr.sort(function (a, b) {
                    let division_a = a.division.map((st, index) => (st.name.toLowerCase()))
                    let division_b = b.division.map((st, index) => (st.name.toLowerCase()))

                    if (division_a < division_b
                    ) return -1;
                    return 0;
                })
            }

            if (this.state.divisionSort==='upper') {
                this.setState({
                    divisionSort: 'lower'
                })
                arr.sort(function (a, b) {
                    let division_a = a.division.map((st, index) => (st.name.toLowerCase()))
                    let division_b = b.division.map((st, index) => (st.name.toLowerCase()))

                    if (division_a > division_b
                    ) return -1;
                    return 0;
                })
            }

            this.setState({
                filter_tar: arr
            })
        }
    }

    delete = async (name, id) => {
        console.log(name)
        await this.setState({
            del_name: name,
            del_endpoint: `account/tar/${id}`,
            showModal: true
        })
    }

    applyFilter = () => {
        this.setState({
            filter: true,
            clear: true
        })
        let endpoint = `employee/filter-employee?from_date=${this.state.fromDate?this.state.fromDate:''}&to_date=${this.state.toDate?this.state.toDate:''}`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({filter_tar: responseData})
                console.log({'>>>>>>>>>>>>>>>>>>>>>>response data---------': responseData})
            });
    }

    clearFilter = () => {
        console.log(this.state.tr)
        this.setState({
            clear: false,
            filter_tar: this.state.tr,
            fromDate: null,
            toDate: null
        })
    }

  render() {
    return (
      <>
        <div className="row my-4" id="clients">
              <div className="col-12">
                  <div className="card py-3">
                      {this.state.tr ?
                          <div className="table-responsive px-2">
                              <div
                                  className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                  <div className="dataTable-top">

                                      <h5 className="mb-0 ps-3" >Tour Requets</h5>
                                      {/*<div className="dataTable-dropdown">*/}
                                      {/*    <label>*/}
                                      {/*    <select className="dataTable-selector"*/}
                                      {/*            onChange={(e) => this.setState({quan: e.target.value})}*/}
                                      {/*    >*/}
                                      {/*        <option value="4" selected>5</option>*/}
                                      {/*        <option value="9">10</option>*/}
                                      {/*        <option value="19">20</option>*/}
                                      {/*        /!*<option value={this.state.total}>All</option>*!/*/}

                                      {/*    </select> entries per page</label></div>*/}
                                      <div className=" d-flex justify-content-end align-items-center" style={{position: "relative"}}>
                                          <TextField size="small" id="outlined-basic" label="Search..." variant="outlined" onChange={this.handleSearch}/>
                                          <div onClick={(e)=>this.setState({showfilter: this.state.showfilter===true?false:true})}>
                                            <FilterListIcon style={{cursor: "pointer", marginLeft: "15px"}}/>
                                          </div>
                                          {this.state.showfilter===true?
                                            <div className="card p-4" style={{width: "700px", position: "absolute", top: "50px", right: "0", zIndex: "10", boxShadow: "0px 0px 6px #bdbbbb"}}>
                                              <h6>FILTERS</h6>
                                                {this.state.clear?<p onClick={this.clearFilter} style={{color: "#5f5fcb", cursor: "pointer"}}>Clear</p>:null}
                                              <div className="row p-1">
                                                  <div className="col-12 p-1">
                                                      <p>Date range</p>
                                                      <div className="d-flex mt-2 p-1 align-items-center"  >
                                                          <TextField
                                                            id="date"
                                                            label="From"
                                                            type="date"
                                                            size="small"

                                                            onChange={(e) => this.setState({fromDate: e.target.value})}

                                                            sx={{ width: 220 }}
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                          />
                                                          <p className="mx-4 my-0" style={{fontSize: "24px"}}>to</p>

                                                          <TextField
                                                            id="date"
                                                            label="To"
                                                            type="date"
                                                            size="small"

                                                            onChange={(e) => this.setState({toDate: e.target.value})}

                                                            sx={{ width: 220 }}
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="d-flex justify-content-end mt-3">
                                                  <button onClick={this.applyFilter} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>
                                              </div>

                                          </div>
                                          :null}
                                      </div>
                                      {/*<div>*/}
                                      {/*</div>*/}
                                  </div>
                                  <div className="dataTable-container" style={{height: "580.641px"}}>
                                      <table className="table align-items-center mb-0 dataTable-table" id="empe">
                                          <thead>
                                          <tr>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "4.8032%", cursor: 'pointer'}}>
                                                  <a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter"
                                                  // onClick={(e)=>this.sortEmpTable("serial_num")}
                                                  >S.NO
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}
                                                  // onClick={(e)=>this.sortEmpTable("name")}
                                              ><a
                                                  className="dataTable-sorter text-center">Team
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  // onClick={(e)=>this.sortEmpTable("name")}
                                              ><a
                                                  className="dataTable-sorter text-center">Name
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}
                                                  // onClick={(e)=>this.sortEmpTable("state")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter text-center">
                                                  Amount</a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  // onClick={(e)=>this.sortEmpTable("city")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter d-block text-center">
                                                  Status</a></th>
                                              <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "10%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("status")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter">
                                                  Date</a></th>
                                              <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%"}}><a
                                                  className="dataTable-sorter">
                                                  Action</a></th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.filter_tar ? this.state.filter_tar.map((tr, index) => (
                                              index <= this.state.quan ?
                                                  <tr>
                                                      <td>
                                                          <div className="d-flex px-2 py-1">
                                                              <div
                                                                  className="d-flex flex-column justify-content-center">
                                                                  <h6 className="mb-0 font-weight-normal text-sm">{index+1+this.state.index_page}
                                                                  </h6>
                                                              </div>
                                                          </div>
                                                      </td>
                                                      <td>
                                                          <div className="d-flex  align-items-center">
                                                              <img src={tr.team?tr.team.logo?url+tr.team.logo:require('../../../custom/img/Team-default.jpg'):null}
                                                                   className="avatar avatar-sm me-3"
                                                                   alt="avatar image"/>
                                                              <h6 className="mb-0 font-weight-normal text-sm">{tr.team?tr.team.name:null}</h6>
                                                          </div>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {tr.employee?tr.employee.last_name?tr.employee.first_name+' '+tr.employee.last_name:tr.employee.first_name:null}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">

                                                              <CurrencyRupeeIcon style={{fontSize: '22px'}}/>{tr.amount}.00
                                                          </p>
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          <p className="text-sm font-weight-normal mb-0"
                                                            style={{color: tr.is_transferred===true?'#528dde':tr.is_approved===true?'#12b312':tr.is_denied===true?'red':"blue"}}
                                                          >
                                                              {tr.is_transferred===true?'Transferred':tr.is_approved===true?'Approved':tr.is_denied===true?'Denied':"Pending"}
                                                          </p>
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          <p className="text-sm font-weight-normal mb-0">
                                                              {tr.time_stamp}
                                                          </p>
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          <div className="d-flex justify-content-center align-items-center">
                                                              <Link to={"/tour/view/" + tr.id}>
                                                              <RemoveRedEyeIcon style={{color: "grey", margin:"0px 20px 0px 0px"}}/>
                                                          </Link>
                                                          <Link to={"/tour/edit/" + tr.id}>
                                                              <ModeEditIcon style={{color: "#528dde", margin:"0px 20px 0px 0px"}}/>
                                                          </Link>
                                                          {tr.is_approved!==true?
                                                          <div onClick={(e)=>this.approve(tr.id, tr.employee.id, tr.team.id, tr.amount)}>
                                                              <CheckIcon style={{color: "#12b312", cursor: "pointer", margin:"0px 20px 0px 0px"}}/>
                                                          </div>
                                                          :null}
                                                          {tr.is_denied!==true?
                                                          <div onClick={(e)=>this.denied(tr.id)}>
                                                              <CloseIcon style={{color: "#de2f2f", cursor: "pointer", margin:"0px 20px 0px 0px"}}/>
                                                          </div>
                                                          :null}
                                                          <div onClick={(e) => this.delete(tr.employee?tr.employee.first_name:null, tr.id)} >
                                                              <DeleteIcon style={{color: "red", cursor: "pointer"}}/>
                                                          </div>
                                                          </div>
                                                      </td>
                                                  </tr>
                                                  : null
                                          )) : null}
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                                  {this.state.page===1?null:
                                      <div className="datatable-mui-pagination mt-5">
                                        <Pagination count={this.state.page} color="secondary" onChange={this.handlePage} size="large"/>
                                      </div>
                                  }
                          </div>
                          :
                          <Loader/>
                      }

                      {/*{this.state.showModal === true && this.state.del_endpoint ?*/}
                      {/*   <DeleteModal*/}
                      {/*      endpoint={this.state.del_endpoint}*/}
                      {/*      confirmation_text={"Click the Delete button to delete "+this.state.del_name}*/}
                      {/*      text={this.state.del_name+" Deleted Successfully."}*/}
                      {/*      link="/tour"*/}
                      {/*      urlText="for go to employee dashboard."*/}
                      {/*   />*/}
                      {/*: null}*/}
                  </div>
              </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
    user:state.userData.userData
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmpDataTable);

