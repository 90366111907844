import React from "react";
import { Link } from "react-router-dom";
import './Modal.css'

class SubmitModal extends React.Component {

    componentDidMount() {
        document.getElementById("openModal").click()
    }

  render() {

    return (
        <>
            <button type="button" id="openModal" style={{display: "none"}} className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop">
            </button>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{right: "-100px"}}
                    >
                        <div className="modal-body">
                            <div className="modal-header">
                                <h5 className="modal-title">Modal title</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="circle__box">
                                <div className="circle__wrapper circle__wrapper--right">
                                    <div className="circle__whole circle__right"></div>
                                </div>
                                <div className="circle__wrapper circle__wrapper--left">
                                    <div className="circle__whole circle__left"></div>
                                </div>
                                <div className="circle__checkmark">
                                </div>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <h5>{this.props.text}</h5>
                                <h6><Link to={this.props.link} style={{color: "grey"}}>Click here </Link>{this.props.urlText}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
  }
}

export default SubmitModal;
