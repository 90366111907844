import React, { useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

function FarziCustomer() {
    const [cityName, setCityName] = useState('');
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);

    const handleCitySearch = () => {
        const apiKey = 'YOUR_OPEN_CAGE_API_KEY';
        const endpoint = `https://api.opencagedata.com/geocode/v1/json?q=${cityName}&key=${apiKey}`;

        fetch(endpoint)
            .then((response) => response.json())
            .then((data) => {
                if (data.results.length > 0) {
                    const { lat, lng } = data.results[0].geometry;
                    setLat(lat);
                    setLong(lng);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div>
            <input
                type="text"
                placeholder="Enter City Name"
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
            />
            <button onClick={handleCitySearch}>Search</button>
            <p>City Name: {cityName}</p>
            <p>Latitude: {lat}</p>
            <p>Longitude: {long}</p>
        </div>
    );
}

export default FarziCustomer;
