import React from "react";
import "../Customer/Style";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { axios, url } from "../../../store/actions/actions";
import Loader from "../../common/Loader/Loader";
import "../../common/common.css";
import DeleteIcon from "@mui/icons-material/Delete";

class VisitView2 extends React.Component {
  state = {
    visit: null,
    samples: null,
    instance_samples_array: null,
    page: 1,
  };

  componentDidMount() {
    this.loadVisit();
    // console.log(this.prop.user)
  }

  my_array = [];

  loadVisit = () => {
    let pathname = window.location.pathname;

    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];
    let endpoint = `visit/${path}`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({
        visit: [responseData.visit],
        instance_samples_array: responseData.samples,
      });
      console.log({ "Visit response data--------->": responseData });
    });
  };

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            {this.state.visit ? (
              this.state.visit.map((vt, index) => (
                <>
                  <div className="d-none d-lg-block container-fluid mb-5">
                    <div className="row justify-content-end">
                      <div
                        className={
                          this.props.sidebar === undefined ||
                            this.props.sidebar === true
                            ? "col-9 me-5"
                            : "col-10 me-5"
                        }
                      >
                        <section className="signup-step-container">
                          <div className="container position-relative">
                            <div className="">
                              <div className="position-relative">
                                <div className="wizard step-wrapper w-100 row">
                                  <div className="wizard-inner w-100 col-md-12">
                                    <div className="connecting-line"></div>
                                    <ul className="nav nav-tabs gap-5" role="tablist">
                                      <li
                                        role="presentation"
                                        className={
                                          this.state.page === 1
                                            ? "active"
                                            : "disabled"
                                        }
                                      >
                                        <a
                                          onClick={(e) =>
                                            this.setState({ page: 1 })
                                          }
                                          data-bs-toggle="tab"
                                          aria-controls="step1"
                                          role="tab"
                                          aria-expanded="true"
                                        >
                                          <span>Basic Info</span>{" "}
                                        </a>
                                      </li>
                                      <li
                                        role="presentation"
                                        className={
                                          this.state.page === 2
                                            ? "active"
                                            : "disabled"
                                        }
                                      >
                                        <a
                                          onClick={(e) =>
                                            this.setState({ page: 2 })
                                          }
                                          data-bs-toggle="tab"
                                          aria-controls="step2"
                                          role="tab"
                                          aria-expanded="false"
                                        >
                                          {" "}
                                          <span>Contact Details</span>{" "}
                                        </a>
                                      </li>
                                      <li
                                        role="presentation"
                                        className={
                                          this.state.page === 3
                                            ? "active"
                                            : "disabled"
                                        }
                                      >
                                        <a
                                          onClick={(e) =>
                                            this.setState({ page: 3 })
                                          }
                                          data-bs-toggle="tab"
                                          aria-controls="step3"
                                          role="tab"
                                        >
                                          <span>Work Status</span>{" "}
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                {this.state.page === 1 ? (
                                  <div className="row mt-5">
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Employee
                                      </label>
                                      <div className="">
                                        <input
                                          value={
                                            vt.employee.last_name
                                              ? vt.employee.first_name +
                                              " " +
                                              vt.employee.last_name
                                              : vt.employee.first_name
                                          }
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: 600,
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Serial No.
                                      </label>
                                      <div className="">
                                        <input
                                          value={vt.serial_number}
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Date
                                      </label>
                                      <div className="">
                                        <input
                                          value={vt.date}
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="date"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Customer
                                      </label>
                                      <div className="">
                                        <input
                                          value={vt.customer.company}
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Station
                                      </label>
                                      <div className="">
                                        <input
                                          value={
                                            vt.station
                                              ? vt.station.station +
                                              "(" +
                                              vt.station.state.state +
                                              ")"
                                              : null
                                          }
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Board
                                      </label>
                                      <div className="">
                                        <input
                                          value={
                                            vt.board ? vt.board.boards : null
                                          }
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Student
                                      </label>
                                      <div className="">
                                        <input
                                          value={vt.student_str}
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Purpose
                                      </label>
                                      <div className="">
                                        <input
                                          value={vt.purpose}
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Visit Type
                                      </label>
                                      <div className="">
                                        <input
                                          value={
                                            vt.is_old === true ? "Old" : "New"
                                          }
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <button
                                        type="button"
                                        className="next-step next"
                                        id="step2-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#step2"
                                        role="tab"
                                        onClick={(e) =>
                                          this.setState({ page: 2 })
                                        }
                                        aria-controls="step2"
                                        aria-selected="false"
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.page === 2 ? (
                                  <div className="row mt-5">
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Contact Person Name
                                      </label>
                                      <div className="">
                                        <input
                                          value={vt.contact_person_name}
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: 600,
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Contact No.
                                      </label>
                                      <div className="">
                                        <input
                                          value={vt.contact_no}
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Email Id
                                      </label>
                                      <div className="">
                                        <input
                                          value={vt.email}
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                      <button
                                        type="button"
                                        className="next-step next"
                                        id="step2-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#step2"
                                        role="tab"
                                        onClick={(e) =>
                                          this.setState({ page: 3 })
                                        }
                                        aria-controls="step2"
                                        aria-selected="false"
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.page === 3 ? (
                                  <div className="row mt-5">
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Employee
                                      </label>
                                      <div className="">
                                        <input
                                          value={
                                            vt.employee.last_name
                                              ? vt.employee.first_name +
                                              " " +
                                              vt.employee.last_name
                                              : vt.employee.first_name
                                          }
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: 600,
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Status
                                      </label>
                                      <div className="">
                                        <input
                                          value={
                                            vt.working_status
                                              ? vt.working_status.working_status
                                                ? vt.working_status
                                                  .working_status.choice
                                                : null
                                              : null
                                          }
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Questionnaire
                                      </label>
                                      <div className="">
                                        <input
                                          value={
                                            vt.working_status
                                              ? vt.working_status.questionnaire.map(
                                                (qst, index) =>
                                                  " " + qst.option
                                              )
                                              : null
                                          }
                                          className="multisteps-form__input form-control"
                                          style={{
                                            backgroundColor: "#d9e1e0",
                                            padding: "12px",
                                            color: "rgb(116 110 110)",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                          type="text"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12 mt-3 mb-3">
                                      <div
                                        className="card"
                                        style={{
                                          background: "rgb(217, 225, 224)",
                                        }}
                                      >
                                        <div className="sample-thead mt-2">
                                          <div className="table-responsive">
                                            <table className="table mt-2">
                                              <thead>
                                                <tr>
                                                  <th>S. No.</th>
                                                  <th>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                  </th>
                                                  <th>From</th>
                                                  <th>To</th>
                                                  <th>
                                                    &nbsp;&nbsp;Qty.&nbsp;&nbsp;
                                                  </th>
                                                  <th>
                                                    &nbsp;&nbsp;Price&nbsp;&nbsp;
                                                  </th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state
                                                  .instance_samples_array
                                                  ? this.state.instance_samples_array.map(
                                                    (sample, index) => (
                                                      <tr>
                                                        <td
                                                          width="7%"
                                                          className="text-center"
                                                        >
                                                          <div>
                                                            <span className="">
                                                              {index + 1}
                                                            </span>
                                                            .
                                                          </div>
                                                        </td>
                                                        <td width="43%">
                                                          <p>
                                                            {sample.book[0].title ? sample.book[0].title : null}
                                                          </p>
                                                        </td>

                                                        <td width="10%">
                                                          <p>
                                                            {sample.book[0] && sample.book[0].classes && sample.book[0].classes.class_name ? sample.book[0].classes.class_name : null}
                                                          </p>
                                                        </td>

                                                        <td width="10%">
                                                          <p>
                                                            {sample.book.length > 0 && sample.book[sample.book.length - 1].classes && sample.book[sample.book.length - 1].classes.class_name ? sample.book[sample.book.length - 1].classes.class_name : null}
                                                          </p>
                                                        </td>

                                                        <td width="10%">
                                                          <p className="text-center">
                                                            {sample.quantity ? sample.quantity : null}
                                                          </p>
                                                        </td>

                                                        <td width="10%">
                                                          <p className="text-center">
                                                            {sample.price ? sample.price : null}
                                                          </p>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                          <div className="d-flex justify-content-center align-items-center">
                                                            <div onClick={(e) => this.delete(sample.id)}>
                                                              <DeleteIcon
                                                                style={{
                                                                  color: "red",
                                                                  cursor: "pointer",
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </td>

                                                      </tr>
                                                    )
                                                  )
                                                  : null}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                      <label
                                        className="form-label"
                                        style={{ color: "black" }}
                                      >
                                        Remark
                                      </label>
                                      <audio
                                        className="w-100"
                                        src={
                                          vt.working_status
                                            ? url + vt.working_status.remark
                                            : null
                                        }
                                        controls
                                      ></audio>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                      <button
                                        type="button"
                                        className="next-step next"
                                        id="step2-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#step2"
                                        role="tab"
                                        onClick={(e) =>
                                          this.setState({ page: 2 })
                                        }
                                        aria-controls="step2"
                                        aria-selected="false"
                                      >
                                        Previous
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>

                  {/* Mobile Sections  */}

                  <div className="d-lg-none">
                    <div className="dashOption-mv my-3 px-2">
                      <div className="">
                        <div className="button-bar-mv d-flex">
                          <Link to="/visit" className="btn btn-mv ">
                            <img
                              src={require("../../../kavi/icons/dashbord.png")}
                              alt="btn-icon"
                            />
                            Dashboard
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="container-fluid px-2"
                      style={{ marginBottom: "100px" }}
                    >
                      <div className="row justify-content-end">
                        <section className="signup-step-container">
                          <div className="position-relative">
                            <div
                              className="wizard step-wrapper w-100 row"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="wizard-inner w-100 col-md-12">
                                <div className="connecting-line"></div>
                                <ul className="nav nav-tabs gap-3" role="tablist">
                                  <li
                                    role="presentation"
                                    className={
                                      this.state.page === 1
                                        ? "active"
                                        : "disabled"
                                    }
                                  >
                                    <a
                                      onClick={(e) =>
                                        this.setState({ page: 1 })
                                      }
                                      data-bs-toggle="tab"
                                      aria-controls="step1"
                                      role="tab"
                                      aria-expanded="true"
                                    >
                                      <span>Basic Info</span>{" "}
                                    </a>
                                  </li>
                                  <li
                                    role="presentation"
                                    className={
                                      this.state.page === 2
                                        ? "active"
                                        : "disabled"
                                    }
                                  >
                                    <a
                                      onClick={(e) =>
                                        this.setState({ page: 2 })
                                      }
                                      data-bs-toggle="tab"
                                      aria-controls="step2"
                                      role="tab"
                                      aria-expanded="false"
                                    >
                                      {" "}
                                      <span>Contact Details</span>{" "}
                                    </a>
                                  </li>
                                  <li
                                    role="presentation"
                                    className={
                                      this.state.page === 3
                                        ? "active"
                                        : "disabled"
                                    }
                                  >
                                    <a
                                      onClick={(e) =>
                                        this.setState({ page: 3 })
                                      }
                                      data-bs-toggle="tab"
                                      aria-controls="step3"
                                      role="tab"
                                    >
                                      <span>Work Status</span>{" "}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {this.state.page === 1 ? (
                              <div className="row mt-5">
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Employee
                                  </label>
                                  <div className="">
                                    <input
                                      value={
                                        vt.employee.last_name
                                          ? vt.employee.first_name +
                                          " " +
                                          vt.employee.last_name
                                          : vt.employee.first_name
                                      }
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Serial No.
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.serial_number}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Date
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.date}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="date"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Customer
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.customer.company}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Station
                                  </label>
                                  <div className="">
                                    <input
                                      value={
                                        vt.station
                                          ? vt.station.station +
                                          "(" +
                                          vt.station.state.state +
                                          ")"
                                          : null
                                      }
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Board
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.board ? vt.board.boards : null}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Student
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.student_str}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Purpose
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.purpose}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Visit Type
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.is_old === true ? "Old" : "New"}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 d-flex mb-2">
                                  <button
                                    type="button"
                                    className="next-step btn btn-dark m-auto next"
                                    id="step2-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#step2"
                                    role="tab"
                                    onClick={(e) => this.setState({ page: 2 })}
                                    aria-controls="step2"
                                    aria-selected="false"
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            ) : null}
                            {this.state.page === 2 ? (
                              <div className="row mt-5">
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Contact Person Name
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.contact_person_name}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Contact No.
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.contact_no}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Email Id
                                  </label>
                                  <div className="">
                                    <input
                                      value={vt.email}
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12 d-flex mb-2">
                                  <button
                                    type="button"
                                    className="next-step btn btn-dark m-auto next"
                                    id="step2-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#step2"
                                    role="tab"
                                    onClick={(e) => this.setState({ page: 3 })}
                                    aria-controls="step2"
                                    aria-selected="false"
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            ) : null}
                            {this.state.page === 3 ? (
                              <div className="row mt-5">
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Employee
                                  </label>
                                  <div className="">
                                    <input
                                      value={
                                        vt.employee.last_name
                                          ? vt.employee.first_name +
                                          " " +
                                          vt.employee.last_name
                                          : vt.employee.first_name
                                      }
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Status
                                  </label>
                                  <div className="">
                                    <input
                                      value={
                                        vt.working_status
                                          ? vt.working_status.working_status
                                            ? vt.working_status.working_status
                                              .choice
                                            : null
                                          : null
                                      }
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                  <label
                                    className="form-label"
                                    style={{ color: "black" }}
                                  >
                                    Questionnaire
                                  </label>
                                  <div className="">
                                    <input
                                      value={
                                        vt.working_status
                                          ? vt.working_status.questionnaire.map(
                                            (qst, index) => " " + qst.option
                                          )
                                          : null
                                      }
                                      className="multisteps-form__input form-control"
                                      style={{
                                        backgroundColor: "#d9e1e0",
                                        padding: "12px",
                                        color: "rgb(116 110 110)",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <label
                                  className="form-label"
                                  style={{ color: "black" }}
                                >
                                  Remark
                                </label>
                                <div className="col-md-12 mb-2">
                                  <audio
                                    className="w-100"
                                    src={
                                      vt.working_status
                                        ? url + vt.working_status.remark
                                        : null
                                    }
                                    controls
                                  ></audio>
                                </div>
                                <div className="col-md-12 d-flex mb-2">
                                  <button
                                    type="button"
                                    className="next-step btn btn-dark m-auto next"
                                    id="step2-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#step2"
                                    role="tab"
                                    onClick={(e) => this.setState({ page: 2 })}
                                    aria-controls="step2"
                                    aria-selected="false"
                                  >
                                    Previous
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(VisitView2);
