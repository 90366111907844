import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import "../../../custom/css/srkcutom.css";
import { axios, sideBar, userLogin } from "../../../store/actions/actions";
import { url } from "../../../store/actions/actions";
import Loader from "../../common/Loader/Loader";
import TeamViewGoogleMap from "../../GoogleMap/TeamViewGoogleMap";

class TeamView extends React.Component {
  state = {
    team_id: null,
    teams: null,
    members: null,
    heads: null,
    stations: null,
    filtered_stations: null,
  };

  componentDidMount() {
    this.loadTeams();
    this.toeknExpiryCheck();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "Update Response data----------": responseData });
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  loadTeams = () => {
    let pathname = window.location.pathname;
    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];

    let endpoint = `teams/${path}`;
    let token = this.props.user.token;
    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      
      console.log(this.state.members,"-=-=-=-=-=-=-=-=-=", responseData.member)

      this.setState({
        team_id: responseData.id,
        teams: responseData,
        name: responseData.name,
        members: responseData.member,
        heads: responseData.heads,
        // stations: responseData.station,
      });

      

      console.log({ "Team response data---------": responseData });
    });
  };

  filterMemberProjects = async (value) => {
    let stations = this.state.stations;

    var filteredArray = stations.filter((station) =>
      station.employee.some((employee) => employee.first_name === value)
    );

    this.setState({ filtered_stations: filteredArray });
    // .map(station => {
    //   let n = Object.assign({}, station, {'employee': station.employee.filter(
    //     employee => employee.first_name === value
    //   )})
    //   return n;
    // })
    //
    // console.log('===================>',value)
    // console.log('===================>',filteredArray)
  };
  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="d-none d-lg-block">
              <div className="container-fluid">
                <div className="row justify-content-end">
                  <div
                    className={
                      this.props.sidebar === undefined ||
                      this.props.sidebar === true
                        ? "col-10"
                        : "col-12"
                    }
                  >
                    {
                      this.state.teams ? (
                        <div className="card">
                          <div className="card-header">
                            <div className="row justify-content-center align-items-center">
                              <div className="col-12 d-flex justify-content-end">
                                <img
                                  src={url + this.state.teams.logo}
                                  className="w-25 rounded-img d-block img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="col-12">
                                <h6 className="text-center">{this.state.name}</h6>
                                <h6 className="fw-bold fs-4">Heads</h6>
                                <div className="d-flex gap-5">
                                {this.state.heads.map((head, index) => (
                                  <h6>
                                    {head.last_name
                                      ? head.first_name + " " + head.last_name
                                      : head.first_name}
                                  </h6>
                                ))}
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-center align-items-center">
                              <div className="col-12">
                                <div
                                  className="card card-plain"
                                  style={{ overflow: "overlay" }}
                                >
                                  <div className="card-body d-flex ps-0">
                                    {this.state.members.map(
                                      (employee, index) => (
                                        <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                          <div
                                            onClick={() =>
                                              this.filterMemberProjects(
                                                employee.first_name
                                              )
                                            }
                                            className="avatar avatar-lg rounded-circle border border-primary"
                                          >
                                            <img
                                              alt="Image placeholder"
                                              className="p-1"
                                              src={
                                                employee.pic
                                                  ? url + employee.pic
                                                  : require("../../../custom/img/team-1.jpg")
                                              }
                                            />
                                          </div>
                                          <p className="mb-0 text-sm">
                                            {employee.first_name +
                                              " " +
                                              employee.last_name}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Loader />
                      )
                      // null
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="d-lg-none  px-2">
              {
                this.state.teams ? (
                  <div className="card card-kmv mt-4">
                    <div className="card-header">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-12">
                          <img
                            src={url + this.state.teams.logo}
                            className="w-100 rounded-img d-block img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="col-12 mt-2">
                          <h6 className="text-uppercase fs-5">
                            {this.state.name}
                          </h6>
                          {this.state.heads.map((head, index) => (
                            <h6>
                              {head.last_name
                                ? head.first_name + " " + head.last_name
                                : head.first_name}
                            </h6>
                          ))}
                        </div>
                      </div>

                      <div className="row justify-content-center align-items-center">
                        <div className="col-12">
                          <div
                            className="card card-plain"
                            style={{ overflow: "overlay" }}
                          >
                            <div className="card-body d-flex ps-0">
                              {this.state.members.map((employee, index) => (
                                <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                  <div
                                    onClick={() =>
                                      this.filterMemberProjects(
                                        employee.first_name
                                      )
                                    }
                                    className="avatar avatar-lg rounded-circle border border-primary"
                                  >
                                    <img
                                      alt="Image placeholder"
                                      className="p-1"
                                      src={
                                        employee.pic
                                          ? url + employee.pic
                                          : require("../../../custom/img/team-1.jpg")
                                      }
                                    />
                                  </div>
                                  <p className="mb-0 text-sm">
                                    {employee.first_name}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                ) : (
                  <Loader />
                )
                // null
              }
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamView);
