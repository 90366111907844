import React from "react";
import DataCards from "../EmployeeDash/DataCards";
import { connect } from "react-redux";
import { axios, modalStyle, url } from "../../../store/actions/actions";
import TripGraph from "./TripGraph";
import TripsTableCard from "./TripsTableCard";
import LatLng from "../../GoogleMap/getLatLng";
// import axios from "axios";
import { Stack, Skeleton } from "@mui/material";
import { Link, Redirect } from "react-router-dom";
import ScheduledVisitDataTable from "../VisitDash/ScheduledVisitDataTable";

class TripDash extends React.Component {
  state = {
    scheduledVisits: null,
    latLng: null,
    filterScheduledVisits: null,
    type:null
  };
  
  latLng = null;


  getLatLng = (data) => {
    this.latLng = data;
    this.setState({ latLng: data });
    // this.getLocation(data);
    this.loadScheduledVisits(data);
  };

  loadScheduledVisits = (location) => {
    this.setState({
      scheduledVisits: null,
      filterScheduledVisits: null,
    });


    let data = {
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
      lat: this.state.latLng ? this.state.latLng.lat : location.lat,
      lng: this.state.latLng ? this.state.latLng.lng : location.lng,
    };

    console.log(data, "Data Hai bHaiyya");

    let endpoint = "common/home-scheduled-visit";
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;

      console.log(
        responseData,
        "home-scheduled-visit home-scheduled-visit home-scheduled-visit"
      );

      const objWithIndex = responseData.visits.map((obj, index) =>
        Object.assign(obj, { index: index + 1 })
      );
      console.log(objWithIndex.length, "Object With Index");
      this.setState({
        scheduledVisits: objWithIndex,
        filterScheduledVisits: objWithIndex,
      });
    });
  };
  render() {
    return (
      <>
        {this.latLng === null ? <LatLng getLatLng={this.getLatLng} /> : null}
        {this.props.user.login === true ? (
          <>
            <div className="container-fluid homepage-view d-none d-lg-block">
              <div className="row justify-content-end">
                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "col-10"
                      : "col-12"
                  }
                >
                  {this.props.user.employee_profile === "admin"? (
                    <DataCards />
                  ):null}
                  {/*<TripGraph />*/}
                  <TripsTableCard />
                </div>
              </div>
            </div>
            <div className="px-2 d-lg-none">
              <div className="dashOption-mv mt-4 ">
                <div className="">
                  <div className="dashOption-mv mt-4">
                    <div className="">
                      <div className="button-bar-mv d-flex">
                        <Link to="/trip" className="btn btn-mv active">
                          <img
                            src={require("../../../kavi/icons/dashbord.png")}
                            alt="btn-icon"
                          />
                          Dashboard
                        </Link>
                        <Link to="/trip/create-trip" className="btn btn-mv">
                          <img
                            src={require("../../../kavi/icons/add.png")}
                            alt="btn-icon"
                          />
                          Add Trip
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.user.employee_profile === "admin"? (
                <DataCards />
              ):null}
              {/*<TripGraph />*/}
              <div className="row">
                <div className="col-12 mt-3">
                  <ScheduledVisitDataTable
                    visit={this.state.scheduledVisits}
                    loadScheduledVisits={(e) =>
                      this.loadScheduledVisits(this.state.latLng)
                    }
                  />
                </div>
              </div>
              <TripsTableCard />
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(TripDash);
