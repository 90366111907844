import React from "react";
import { connect } from "react-redux";
import {
  axios,
  url,
  userLogin,
  modalStyle,
} from "../../../store/actions/actions";
import Loader from "../../common/Loader/Loader";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Multiselect } from "multiselect-react-dropdown";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import VisitDataCards from "./VisitDataCards";
import "../../common/common.css";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import IncompVisitDataTable from "./IncompVisitDataTable";
import ScheduledVisitDataTable from "./ScheduledVisitDataTable";
import Loadingfade from "../../common/Loadingfade";
import ClearIcon from "@mui/icons-material/Clear";
class VisitDataTable extends React.Component {
  state = {
    all_employee: null,
    employee: null,
    visit: null,
    quan: null,
    total: null,
    filterVisits: null,
    states: null,
    stations: null,
    boards: null,
    all_states: null,
    all_boards: null,

    fromDate: null,
    cust_type: null,
    station_wise: null,
    state_wise: null,
    board_wise: null,
    working_status: null,

    // sort
    serialnumSort: null,
    typeSort: null,
    empSort: null,
    custSort: null,
    dateSort: null,
    stationSort: null,
    boardSort: null,
    studentSort: null,
    contactSort: null,
    working_statusSort: null,

    // filter
    filter: null,
    clear: null,
    boardFilter: null,
    stateFilter: null,
    showfilter: null,
    showMainfilter: null,

    // pagination
    page: null,

    // data-cards
    card_data: null,
    open: false,
    delete_visit_id: null,
    is_delete: false,

    index_page: null,

    // Search and Filter
    searchText: "",
  };

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  componentDidMount() {
    this.loadVisitPageWithAxios();
    this.loadBoards();
    this.loadStates();
    this.loadDataCardsWithAxios();
    this.loadEmployees();
    this.toeknExpiryCheck();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "Update Response data----------": responseData });
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  loadEmployees = () => {
    let endpoint = `/account/get-employees`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({
        all_employee: responseData.employee,
      });
      console.log({ "Employee data---------": responseData.employee });
    });
  };

  loadDataCardsWithAxios = () => {
    let data = {
      id: this.state.employee,
    };
    let endpoint = `visit/data-cards`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      this.setState({ card_data: responseData });
    });
  };

  loadVisitPageWithAxios = () => {
    let endpoint = `visit/visit-data-table`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      console.log({ "visit response data---------": responseData });
      this.setState({ visit: responseData.visit });
      this.setState({ filterVisits: responseData.visit });
      this.setState({ quan: responseData.visit.length });
      this.setState({ total: responseData.visit.length });
      this.setState({ page: responseData.page });
    });
  };

  loadStates = () => {
    let endpoint = `employee/states`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ all_states: responseData.state });
      console.log({ "states response data---------": responseData.state });
    });
  };

  loadBoards = () => {
    let endpoint = `common/get-boards`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ all_boards: responseData.boards });
      console.log({ "boards response data---------": responseData.boards });
    });
  };

  // ************************ Search Filter ***********************
  // Search Input
  inputChangeHandler = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  // Clear Input Field When click on Clear button
  clearSearchInputHandler = (e) => {
    this.setState({
      searchText: "",
      visit: null,
      filterVisits: null,
    });
    this.loadVisitPageWithAxios();
    console.log("Chal To rha hai bhai", e.target.value);
  };

  // New Handle Seach
  handleSearch = () => {
    this.setState({
      visit: null,
      filterVisits: null,
    });

    let data = {
      searchText: this.state.searchText,
    };

    let endpoint = `visit/search-visit-by-customer`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      console.log({ "search response data---------": responseData });
      this.setState({
        visit: responseData.visit,
        filterVisits: responseData.visit,
        quan: responseData.visit.length,
        total: responseData.visit.length,
        page: responseData.page,
      });
    });
  };

  // Old Handle Search
  // handleSearch = (e) => {
  //   let text = e.target.value;
  //   console.log(text);
  //   let endpoint = `visit/search-visit?search=${text}`;
  //   let token = this.props.user.token;

  //   axios.get(endpoint, token).then((response) => {
  //     const responseData = response.data;
  //     console.log({ "search response data---------": responseData });
  //     this.setState({ filterVisits: responseData.visit });
  //   });
  // };

  onFilterStateSelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      stateFilter: selectedList,
    });
  };

  onFilterStateRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      stateFilter: selectedList,
    });
  };

  onFilterBoardSelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      boardFilter: selectedList,
    });
  };

  onFilterBoardRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      boardFilter: selectedList,
    });
  };

  applyFilter = () => {
    this.setState({
      filter: true,
      clear: true,
      showfilter: false,
    });

    let states = [];
    let boards = [];

    if (this.state.stateFilter !== null && this.state.stateFilter !== []) {
      console.log("state filter");
      this.state.stateFilter.map((state, index) => states.push(state.id));
    }

    if (this.state.boardFilter !== null && this.state.boardFilter !== []) {
      console.log("board filter");
      this.state.boardFilter.map((board, index) => boards.push(board.id));
    }

    let endpoint = `visit/filter-visit?board_in=${boards}&state_in=${states}&from_date=${
      this.state.fromDate ? this.state.fromDate : ""
    }&to_date=${this.state.toDate ? this.state.toDate : ""}`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ filterVisits: responseData });
      console.log({ "Filtered response data---------": responseData });
    });
  };

  applyMainFilter = () => {
    this.loadDataCardsWithAxios();
    this.setState({
      filter: true,
      clear: true,
      showMainfilter: false,
    });

    let states = [];
    let boards = [];

    if (this.state.stateFilter !== null && this.state.stateFilter !== []) {
      console.log("state filter");
      this.state.stateFilter.map((state, index) => states.push(state.id));
    }

    if (this.state.boardFilter !== null && this.state.boardFilter !== []) {
      console.log("board filter");
      this.state.boardFilter.map((board, index) => boards.push(board.id));
    }

    let endpoint = `visit/filter-visit?employee_in=${
      this.state.employee ? this.state.employee : ""
    }&board_in=${boards}&state_in=${states}&from_date=${
      this.state.fromDate ? this.state.fromDate : ""
    }&to_date=${this.state.toDate ? this.state.toDate : ""}`;
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>...", endpoint);
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({
        filterVisits: responseData.visit,
        total: responseData.visit.length,
        quan: responseData.visit.length,
        page: responseData.page,
      });
    });
  };

  clearFilter = () => {
    this.setState({
      clear: false,
      employee: null,
      stateFilter: null,
      boardFilter: null,
      filterVisits: this.state.visit,
      fromDate: null,
      toDate: null,
    });
  };

  handlePage = (e, p) => {
    console.log("--------------------", p);
    this.setState({ index_page: p === 1 ? 0 : (p - 1) * 10 });
    const data = {
      from: p === 1 ? 0 : (p - 1) * 10,
      to: p === 1 ? 10 : p * 10,
      filter_name: this.state.employee,
    };
    console.log("--------------------", data);
    let endpoint = `visit/pagination`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      this.setState({ filterVisits: responseData.visit });
      console.log({ "pagination response data---------": responseData });
    });
  };

  printTable = () => {
    console.log("run");
    //Get the HTML of div
    var divElements = document.getElementById("visitTable").innerHTML;
    //Get the HTML of whole page
    var oldPage = document.body.innerHTML;
    //Reset the page's HTML with div's HTML only
    document.body.innerHTML =
      "<html><head><title></title></head><body>" + divElements + "</body>";
    //Print Page
    window.print();
    //Restore orignal HTML
    document.body.innerHTML = oldPage;
  };

  sortVisitTable = (data) => {
    let arr = this.state.filterVisits;

    if (data === "serial_num") {
      this.setState({
        filterVisits: arr.reverse(),
      });
    }

    if (data === "date") {
      if (this.state.dateSort === null || this.state.dateSort === "lower") {
        this.setState({
          dateSort: "upper",
        });
        arr.sort(function (a, b) {
          let date_a = a.date;
          let date_b = b.date;

          if (date_a < date_b) return -1;
          return 0;
        });
      }

      if (this.state.dateSort === "upper") {
        this.setState({
          dateSort: "lower",
        });
        arr.sort(function (a, b) {
          let date_a = a.date;
          let date_b = b.date;

          if (date_a > date_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterVisits: arr,
      });
    }

    if (data === "employee") {
      if (this.state.empSort === null || this.state.empSort === "lower") {
        this.setState({
          empSort: "upper",
        });
        arr.sort(function (a, b) {
          let date_a = a.employee.toLowerCase();
          let date_b = b.employee.toLowerCase();

          if (date_a < date_b) return -1;
          return 0;
        });
      }

      if (this.state.empSort === "upper") {
        this.setState({
          empSort: "lower",
        });
        arr.sort(function (a, b) {
          let date_a = a.employee.toLowerCase();
          let date_b = b.employee.toLowerCase();

          if (date_a > date_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterVisits: arr,
      });
    }

    if (data === "type") {
      if (this.state.typeSort === null || this.state.typeSort === "lower") {
        this.setState({
          typeSort: "upper",
        });
        arr.sort(function (a, b) {
          let date_a = a.customer.customer.type.toLowerCase();
          let date_b = b.customer.customer.type.toLowerCase();

          if (date_a < date_b) return -1;
          return 0;
        });
      }

      if (this.state.typeSort === "upper") {
        this.setState({
          typeSort: "lower",
        });
        arr.sort(function (a, b) {
          let date_a = a.customer.customer.type.toLowerCase();
          let date_b = b.customer.customer.type.toLowerCase();

          if (date_a > date_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterVisits: arr,
      });
    }

    if (data === "customer") {
      if (this.state.custSort === null || this.state.custSort === "lower") {
        this.setState({
          custSort: "upper",
        });
        arr.sort(function (a, b) {
          let customer_a = a.customer.customer.company.toLowerCase();
          let customer_b = b.customer.customer.company.toLowerCase();

          if (customer_a < customer_b) return -1;
          return 0;
        });
      }

      if (this.state.custSort === "upper") {
        this.setState({
          custSort: "lower",
        });
        arr.sort(function (a, b) {
          let customer_a = a.customer.customer.company.toLowerCase();
          let customer_b = b.customer.customer.company.toLowerCase();

          if (customer_a > customer_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterVisits: arr,
      });
    }

    if (data === "station") {
      if (
        this.state.stationSort === null ||
        this.state.stationSort === "lower"
      ) {
        this.setState({
          stationSort: "upper",
        });
        arr.sort(function (a, b) {
          let station_a = a.customer.station.toLowerCase();
          let station_b = b.customer.station.toLowerCase();

          if (station_a < station_b) return -1;
          return 0;
        });
      }
      if (this.state.stationSort === "upper") {
        this.setState({
          stationSort: "lower",
        });
        arr.sort(function (a, b) {
          let station_a = a.customer.station.toLowerCase();
          let station_b = b.customer.station.toLowerCase();

          if (station_a > station_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterVisits: arr,
      });
    }
  };

  delete = (data) => {
    this.setState({
      open: true,
      delete_visit_id: data,
    });
    if (data === "delete") {
      let endpoint = `visit/${this.state.delete_visit_id}`;
      let token = this.props.user.token;

      axios.delete(endpoint, token).then((response) => {
        const responseData = response.data;
        this.setState({
          is_delete: true,
        });
        console.log({ "delete response data---------": responseData });
      });
    }
  };

  render() {
    return (
      <>
        <div className="d-lg-block d-none">
          <div className="f-btn d-flex justify-content-end">
            <button
              onClick={(e) =>
                this.setState({
                  showMainfilter:
                    this.state.showMainfilter === true ? false : true,
                })
              }
            >
              Filter
            </button>
          </div>
          {this.state.showMainfilter === true ? (
            <div
              className="card f-card p-4"
              style={{
                width: "700px",
                position: "absolute",
                top: "142px",
                right: "10px",
                zIndex: "10",
                boxShadow: "0px 0px 6px #bdbbbb",
              }}
            >
              <h6>FILTERS</h6>
              {this.state.clear ? (
                <p
                  onClick={this.clearFilter}
                  style={{ color: "#5f5fcb", cursor: "pointer" }}
                >
                  Clear
                </p>
              ) : null}
              <div className="row p-1">
                <div className="col-12 p-1">
                  <p>Date range</p>
                  <div className="d-flex mt-2 p-1 align-items-center">
                    <TextField
                      id="date"
                      label="From"
                      type="date"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        this.setState({ fromDate: e.target.value })
                      }
                      // sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <p className="mx-4 my-0" style={{ fontSize: "24px" }}>
                      to
                    </p>

                    <TextField
                      id="date"
                      label="To"
                      type="date"
                      size="small"
                      onChange={(e) =>
                        this.setState({ toDate: e.target.value })
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 p-1 mt-2">
                  <p>Employee</p>
                  {this.state.all_employee ? (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={this.state.all_employee}
                      onChange={(e, value) =>
                        this.setState({ employee: value.id })
                      }
                      getOptionLabel={(option) =>
                        option.last_name
                          ? option.first_name + " " + option.last_name
                          : option.first_name
                      }
                      // sx={{ width: 300 }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                        >
                          <img
                            style={{ borderRadius: "50%" }}
                            // loading="lazy"
                            width="30"
                            src={
                              option.pic
                                ? url + option.pic
                                : url + "/static/agent.png"
                            }
                            // srcSet={`${url + option.pic} 2x`}
                            alt=""
                          />
                          {option.last_name
                            ? option.first_name + " " + option.last_name
                            : option.first_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Employee" />
                      )}
                    />
                  ) : null}
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <button
                  onClick={this.applyMainFilter}
                  className="btn my-0 bg-gradient-dark d-block"
                >
                  Apply Filters
                </button>
              </div>
            </div>
          ) : null}
          {this.state.card_data ? (
            <>
              <VisitDataCards data={this.state.card_data} />
            </>
          ) : null}
          <IncompVisitDataTable />
          <ScheduledVisitDataTable />

          {/* Visit Starts From Here  */}

          <div className="row my-4" id="clients">
            <div className="col-12">
              <div className="card py-3">
                {this.state.visit ? (
                  <div className="table-responsive px-2">
                    <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                      <div className="dataTable-top">
                        <h5 className="mb-0 ps-3">Visits</h5>

                        {/*  <div className=" d-flex justify-content-end align-items-center" style={{position: "relative"}}>*/}
                        {/*    <LocalPrintshopIcon className='cursor-pointer me-3' onClick={this.printTable}/>*/}
                        {/*    <TextField size="small" id="outlined-basic" label="Search..." variant="outlined" onChange={this.handleSearch}/>*/}
                        {/*    <div onClick={(e)=>this.setState({showfilter: this.state.showfilter===true?false:true})}>*/}
                        {/*      <FilterListIcon style={{cursor: "pointer", marginLeft: "15px"}}/>*/}
                        {/*    </div>*/}
                        {/*    {this.state.showfilter===true?*/}
                        {/*      <div className="card p-4" style={{width: "700px", position: "absolute", top: "50px", right: "0", zIndex: "10", boxShadow: "0px 0px 6px #bdbbbb"}}>*/}
                        {/*        <h6>FILTERS</h6>*/}
                        {/*          {this.state.clear?<p onClick={this.clearFilter} style={{color: "#5f5fcb", cursor: "pointer"}}>Clear</p>:null}*/}
                        {/*        <div className="row p-1">*/}
                        {/*            <div className="col-12 p-1">*/}
                        {/*                <p>Date range</p>*/}
                        {/*                <div className="d-flex mt-2 p-1 align-items-center"  >*/}
                        {/*                    <TextField*/}
                        {/*                      id="date"*/}
                        {/*                      label="From"*/}
                        {/*                      type="date"*/}
                        {/*                      size="small"*/}

                        {/*                      onChange={(e) => this.setState({fromDate: e.target.value})}*/}

                        {/*                      sx={{ width: 220 }}*/}
                        {/*                      InputLabelProps={{*/}
                        {/*                        shrink: true,*/}
                        {/*                      }}*/}
                        {/*                    />*/}
                        {/*                    <p className="mx-4 my-0" style={{fontSize: "24px"}}>to</p>*/}

                        {/*                    <TextField*/}
                        {/*                      id="date"*/}
                        {/*                      label="To"*/}
                        {/*                      type="date"*/}
                        {/*                      size="small"*/}

                        {/*                      onChange={(e) => this.setState({toDate: e.target.value})}*/}

                        {/*                      sx={{ width: 220 }}*/}
                        {/*                      InputLabelProps={{*/}
                        {/*                        shrink: true,*/}
                        {/*                      }}*/}
                        {/*                    />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-6 p-1 mt-2">*/}
                        {/*                <p>State</p>*/}
                        {/*                <div className="form-check-inline">*/}
                        {/*                        <div className="">*/}
                        {/*                          <Multiselect*/}
                        {/*                              placeholder="Select State"*/}
                        {/*                              options={this.state.all_states} // Options to display in the dropdown*/}
                        {/*                              onSelect={this.onFilterStateSelect} // Function will trigger on select event*/}
                        {/*                              onRemove={this.onFilterStateRemove} // Function will trigger on remove event*/}
                        {/*                              displayValue="state" // Property name to display in the dropdown options*/}
                        {/*                              style={{*/}
                        {/*                                  searchBox: {fontSize: "15px"}*/}
                        {/*                              }}*/}
                        {/*                          />*/}
                        {/*                        </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-6 p-1 mt-2">*/}
                        {/*                <p>Board</p>*/}
                        {/*                <div className="form-check-inline">*/}
                        {/*                    <>*/}
                        {/*                      {this.state.all_boards?*/}
                        {/*                      <Multiselect*/}
                        {/*                          placeholder="Select Board"*/}
                        {/*                          options={this.state.all_boards} // Options to display in the dropdown*/}
                        {/*                          onSelect={this.onFilterBoardSelect} // Function will trigger on select event*/}
                        {/*                          onRemove={this.onFilterBoardRemove} // Function will trigger on remove event*/}
                        {/*                          displayValue="boards" // Property name to display in the dropdown options*/}
                        {/*                          style={{*/}
                        {/*                              searchBox: {fontSize: "15px"}*/}
                        {/*                          }}*/}
                        {/*                      />*/}
                        {/*                      :null}*/}
                        {/*                    </>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="d-flex justify-content-end mt-3">*/}
                        {/*            <button onClick={this.applyFilter} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}
                        {/*    :null}*/}
                        {/*</div>*/}
                      </div>
                      <div
                        className="dataTable-container"
                        id="visitTable"
                        // style={{ minHeight: "580.641px" }}
                      >
                        <table className="table align-items-center mb-0 dataTable-table">
                          <thead>
                            <tr>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "4.8032%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("serial_num")
                                  }
                                >
                                  S.NO
                                </a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                data-sortable=""
                                style={{ width: "7.938%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) => this.sortVisitTable("date")}
                                >
                                  DATE
                                </a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                data-sortable=""
                                style={{ width: "11.074%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("customer")
                                  }
                                >
                                  CUSTOMER
                                </a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                data-sortable=""
                                style={{ width: "11.074%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) => this.sortVisitTable("type")}
                                >
                                  TYPE
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "7.538%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("station")
                                  }
                                >
                                  STATION
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "7.538%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("employee")
                                  }
                                >
                                  EMPLOYEE
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "7.538%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("purpose")
                                  }
                                >
                                  Purpose
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "9.006%", cursor: "pointer" }}
                              >
                                <div
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  // onClick={(e)=>this.sortVisitTable("working_status")}
                                >
                                  ACTIONS
                                </div>
                              </th>
                            </tr>
                          </thead>
                          {this.state.filterVisits
                            ? this.state.filterVisits.map((item, index) =>
                                index <= this.state.quan ? (
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="d-flex px-2 py-1">
                                          <div className="d-flex flex-column justify-content-center">
                                            <h6 className="mb-0 font-weight-normal text-sm">
                                              {index +
                                                1 +
                                                this.state.index_page}
                                            </h6>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.date}
                                        </p>
                                      </td>
                                      <td className="align-middle text-center">
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.customer
                                            ? item.customer.customer.company
                                            : null}
                                        </p>
                                      </td>
                                      <td>
                                        {item.customer ? (
                                          item.customer.customer.type ===
                                          "SCHOOL" ? (
                                            <span className="badge bg-primary">
                                              SCHOOL
                                            </span>
                                          ) : (
                                            <span className="badge bg-success">
                                              SELLER
                                            </span>
                                          )
                                        ) : null}
                                      </td>
                                      <td className="align-middle text-center">
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.customer.station
                                            ? item.customer.station +
                                              "(" +
                                              item.customer.state +
                                              ")"
                                            : null}
                                        </p>
                                      </td>
                                      <td className="align-middle text-center">
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.employee ? item.employee : null}
                                        </p>
                                      </td>
                                      <td className="align-middle text-center">
                                        {/*<p className="text-sm font-weight-normal mb-0">{item.purpose?item.purpose:null}</p>*/}
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.purpose}
                                        </p>
                                      </td>

                                      <td className="align-middle text-center">
                                        <div className="d-flex justify-content-center align-items-center">
                                          <Link
                                            to={"/visit/visit-view/" + item.id}
                                          >
                                            <RemoveRedEyeIcon
                                              style={{
                                                color: "grey",
                                                margin: "0px 5px",
                                              }}
                                            />
                                          </Link>
                                          <Link
                                            to={"/visit/edit-visit/" + item.id}
                                          >
                                            <ModeEditIcon
                                              style={{
                                                color: "#12b312",
                                                margin: "0px 15px",
                                              }}
                                            />
                                          </Link>
                                          {this.props.user.employee_profile ===
                                          "admin" ? (
                                            <div
                                              onClick={(e) =>
                                                this.delete(item.id)
                                              }
                                            >
                                              <DeleteIcon
                                                style={{
                                                  color: "red",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : null
                              )
                            : null}
                        </table>
                      </div>
                      {this.state.page === 1 ? null : (
                        <div className="datatable-mui-pagination saruk">
                          <Pagination
                            count={this.state.page}
                            color="secondary"
                            onChange={this.handlePage}
                            size="large"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}

                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.open}
                  onClose={(e) => this.setState({ open: false })}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={this.state.open}>
                    <Box sx={modalStyle}>
                      <Typography
                        id="transition-modal-description"
                        sx={{ mt: 2 }}
                      >
                        {this.state.is_delete ? (
                          <>
                            <div className="circle__box">
                              <div className="circle__wrapper circle__wrapper--right">
                                <div className="circle__whole circle__right delete_circle__right"></div>
                              </div>
                              <div className="circle__wrapper circle__wrapper--left">
                                <div className="circle__whole circle__left delete_circle__left"></div>
                              </div>
                              <div className="circle__checkmark delete_circle__checkmark"></div>
                            </div>

                            <div style={{ textAlign: "center" }}>
                              <h5>{this.props.text}</h5>
                              <h6>
                                <Link
                                  to={this.props.link}
                                  style={{ color: "grey" }}
                                >
                                  Click here{" "}
                                </Link>
                                {this.props.urlText}
                              </h6>
                            </div>
                          </>
                        ) : (
                          <div className="modal-body">
                            <div style={{ textAlign: "center" }}>
                              <h5>
                                Click the Delete button to delete this visit.
                              </h5>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => this.delete("delete")}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </Typography>
                    </Box>
                  </Fade>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <div className="d-lg-none">
          <div className="f-btn d-none justify-content-end">
            <button
              onClick={(e) =>
                this.setState({
                  showMainfilter:
                    this.state.showMainfilter === true ? false : true,
                })
              }
            >
              Filter
            </button>
          </div>

          {this.state.showMainfilter === true ? (
            <div
              className="card f-card p-4"
              style={{
                width: "90%",
                position: "absolute",
                top: "14rem",
                right: "1.2rem",
                zIndex: "10",
                boxShadow: "0px 0px 6px #bdbbbb",
              }}
            >
              <h6>FILTERS</h6>
              {this.state.clear ? (
                <p
                  onClick={this.clearFilter}
                  style={{ color: "#5f5fcb", cursor: "pointer" }}
                >
                  Clear
                </p>
              ) : null}
              <div className="row p-1">
                <div className="col-12 p-1">
                  <p>Date range</p>
                  <div className="d-flex mt-2 p-1 align-items-center">
                    <TextField
                      id="date"
                      label="From"
                      type="date"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        this.setState({ fromDate: e.target.value })
                      }
                      // sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <p className="mx-4 my-0" style={{ fontSize: "24px" }}>
                      to
                    </p>

                    <TextField
                      id="date"
                      label="To"
                      type="date"
                      size="small"
                      onChange={(e) =>
                        this.setState({ toDate: e.target.value })
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 p-1 mt-2">
                  <p>Employee</p>
                  {this.state.all_employee ? (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={this.state.all_employee}
                      onChange={(e, value) =>
                        this.setState({ employee: value.id })
                      }
                      getOptionLabel={(option) =>
                        option.last_name
                          ? option.first_name + " " + option.last_name
                          : option.first_name
                      }
                      // sx={{ width: 300 }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                        >
                          <img
                            style={{ borderRadius: "50%" }}
                            // loading="lazy"
                            width="30"
                            src={
                              option.pic
                                ? url + option.pic
                                : url + "/static/agent.png"
                            }
                            // srcSet={`${url + option.pic} 2x`}
                            alt=""
                          />
                          {option.last_name
                            ? option.first_name + " " + option.last_name
                            : option.first_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Employee" />
                      )}
                    />
                  ) : null}
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <button
                  onClick={this.applyMainFilter}
                  className="btn my-0 bg-gradient-dark d-block"
                >
                  Apply Filters
                </button>
              </div>
            </div>
          ) : null}
          {this.state.card_data ? (
            <VisitDataCards data={this.state.card_data} />
          ) : null}

          <IncompVisitDataTable />
          <ScheduledVisitDataTable />
          <div className="row" style={{ marginBottom: "110px" }} id="clients">
            <div className="col-12">
              <div className="card p-3">
                <div className="d-flex justify-content-between">
                  <h5 className="mb-2">Visits</h5>
                  {this.state.searchText !== "" &&
                  this.state.searchText !== null ? (
                    <ClearIcon onClick={this.clearSearchInputHandler} />
                  ) : null}
                </div>

                <div
                  className=" d-flex justify-content-between w-100 align-items-center"
                  style={{ position: "relative", gap: "5px" }}
                >
                  {/* <LocalPrintshopIcon className='cursor-pointer me-3' onClick={this.printTable}/> */}
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Search..."
                    variant="outlined"
                    value={this.state.searchText}
                    onChange={this.inputChangeHandler}
                  />
                  <Button variant="contained" onClick={this.handleSearch}>
                    Search
                  </Button>
                  <div
                    onClick={(e) =>
                      this.setState({
                        showfilter:
                          this.state.showfilter === true ? false : true,
                      })
                    }
                  >
                    {/* <FilterListIcon style={{cursor: "pointer", marginLeft: "15px"}}/> */}
                  </div>
                  {this.state.showfilter === true ? (
                    <div
                      className="card p-4"
                      style={{
                        width: "100%",
                        position: "absolute",
                        top: "50px",
                        right: "0",
                        zIndex: "10",
                        boxShadow: "0px 0px 6px #bdbbbb",
                      }}
                    >
                      <h6>FILTERS</h6>
                      {this.state.clear ? (
                        <p
                          onClick={this.clearFilter}
                          style={{ color: "#5f5fcb", cursor: "pointer" }}
                        >
                          Clear
                        </p>
                      ) : null}
                      <div className="row p-1">
                        <div className="col-12 p-1">
                          <p>Date range</p>
                          <div className="d-flex mt-2 p-1 align-items-center">
                            <TextField
                              id="date"
                              label="From"
                              type="date"
                              size="small"
                              onChange={(e) =>
                                this.setState({ fromDate: e.target.value })
                              }
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <p
                              className="mx-4 my-0"
                              style={{ fontSize: "24px" }}
                            >
                              to
                            </p>

                            <TextField
                              id="date"
                              label="To"
                              type="date"
                              size="small"
                              onChange={(e) =>
                                this.setState({ toDate: e.target.value })
                              }
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-6 p-1 mt-2">
                          <p>State</p>
                          <div className="form-check-inline">
                            <div className="">
                              <Multiselect
                                placeholder="Seclect State"
                                options={this.state.all_states} // Options to display in the dropdown
                                onSelect={this.onFilterStateSelect} // Function will trigger on select event
                                onRemove={this.onFilterStateRemove} // Function will trigger on remove event
                                displayValue="state" // Property name to display in the dropdown options
                                style={{
                                  searchBox: { fontSize: "15px" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6 p-1 mt-2">
                          <p>Division</p>
                          <div className="form-check-inline">
                            <>
                              <Multiselect
                                placeholder="Seclect Division"
                                options={this.state.all_divisions} // Options to display in the dropdown
                                onSelect={this.onFilterDivisionSelect} // Function will trigger on select event
                                onRemove={this.onFilterDivisionRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                style={{
                                  searchBox: { fontSize: "15px" },
                                }}
                              />
                            </>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-3">
                        <button
                          onClick={this.applyFilter}
                          className="btn my-0 bg-gradient-dark d-block"
                        >
                          Apply Filters
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                {this.state.visit ? (
                  <div className="table-responsive px-2">
                    <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                      <div className="dataTable-top">
                        {/*  <div className=" d-flex justify-content-end align-items-center" style={{position: "relative"}}>*/}
                        {/*    <LocalPrintshopIcon className='cursor-pointer me-3' onClick={this.printTable}/>*/}
                        {/*    <TextField size="small" id="outlined-basic" label="Search..." variant="outlined" onChange={this.handleSearch}/>*/}
                        {/*    <div onClick={(e)=>this.setState({showfilter: this.state.showfilter===true?false:true})}>*/}
                        {/*      <FilterListIcon style={{cursor: "pointer", marginLeft: "15px"}}/>*/}
                        {/*    </div>*/}
                        {/*    {this.state.showfilter===true?*/}
                        {/*      <div className="card p-4" style={{width: "100%", position: "absolute", top: "50px", right: "0", zIndex: "10", boxShadow: "0px 0px 6px #bdbbbb"}}>*/}
                        {/*        <h6>FILTERS</h6>*/}
                        {/*          {this.state.clear?<p onClick={this.clearFilter} style={{color: "#5f5fcb", cursor: "pointer"}}>Clear</p>:null}*/}
                        {/*        <div className="row p-1">*/}
                        {/*            <div className="col-12 p-1">*/}
                        {/*                <p>Date range</p>*/}
                        {/*                <div className="d-flex mt-2 p-1 align-items-center"  >*/}
                        {/*                    <TextField*/}
                        {/*                      id="date"*/}
                        {/*                      label="From"*/}
                        {/*                      type="date"*/}
                        {/*                      size="small"*/}

                        {/*                      onChange={(e) => this.setState({fromDate: e.target.value})}*/}

                        {/*                      sx={{ width: 220 }}*/}
                        {/*                      InputLabelProps={{*/}
                        {/*                        shrink: true,*/}
                        {/*                      }}*/}
                        {/*                    />*/}
                        {/*                    <p className="mx-4 my-0" style={{fontSize: "24px"}}>to</p>*/}

                        {/*                    <TextField*/}
                        {/*                      id="date"*/}
                        {/*                      label="To"*/}
                        {/*                      type="date"*/}
                        {/*                      size="small"*/}

                        {/*                      onChange={(e) => this.setState({toDate: e.target.value})}*/}

                        {/*                      sx={{ width: 220 }}*/}
                        {/*                      InputLabelProps={{*/}
                        {/*                        shrink: true,*/}
                        {/*                      }}*/}
                        {/*                    />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-6 p-1 mt-2">*/}
                        {/*                <p>State</p>*/}
                        {/*                <div className="form-check-inline">*/}
                        {/*                        <div className="">*/}
                        {/*                          <Multiselect*/}
                        {/*                              placeholder="Select State"*/}
                        {/*                              options={this.state.all_states} // Options to display in the dropdown*/}
                        {/*                              onSelect={this.onFilterStateSelect} // Function will trigger on select event*/}
                        {/*                              onRemove={this.onFilterStateRemove} // Function will trigger on remove event*/}
                        {/*                              displayValue="state" // Property name to display in the dropdown options*/}
                        {/*                              style={{*/}
                        {/*                                  searchBox: {fontSize: "15px"}*/}
                        {/*                              }}*/}
                        {/*                          />*/}
                        {/*                        </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-6 p-1 mt-2">*/}
                        {/*                <p>Board</p>*/}
                        {/*                <div className="form-check-inline">*/}
                        {/*                    <>*/}
                        {/*                      {this.state.all_boards?*/}
                        {/*                      <Multiselect*/}
                        {/*                          placeholder="Select Board"*/}
                        {/*                          options={this.state.all_boards} // Options to display in the dropdown*/}
                        {/*                          onSelect={this.onFilterBoardSelect} // Function will trigger on select event*/}
                        {/*                          onRemove={this.onFilterBoardRemove} // Function will trigger on remove event*/}
                        {/*                          displayValue="boards" // Property name to display in the dropdown options*/}
                        {/*                          style={{*/}
                        {/*                              searchBox: {fontSize: "15px"}*/}
                        {/*                          }}*/}
                        {/*                      />*/}
                        {/*                      :null}*/}
                        {/*                    </>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="d-flex justify-content-end mt-3">*/}
                        {/*            <button onClick={this.applyFilter} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}
                        {/*    :null}*/}
                        {/*</div>*/}
                      </div>
                      <div className="dataTable-container" id="visitTable">
                        <table className="table align-items-center mb-0 dataTable-table kv-table">
                          <thead>
                            <tr>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "4.8032%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("serial_num")
                                  }
                                >
                                  S.No
                                </a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                data-sortable=""
                                style={{ width: "7.938%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) => this.sortVisitTable("date")}
                                >
                                  DATE
                                </a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                data-sortable=""
                                style={{ width: "11.074%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("customer")
                                  }
                                >
                                  CUSTOMER
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "7.538%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("station")
                                  }
                                >
                                  STATION
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "7.538%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("station")
                                  }
                                >
                                  EMPLOYEE
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "6.204%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) => this.sortVisitTable("board")}
                                >
                                  BOARD
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "7.738%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("student_str")
                                  }
                                >
                                  STUDENT STR
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "11.274%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortVisitTable("contact")
                                  }
                                >
                                  CONTATC PERSON INFO
                                </a>
                              </th>
                              <th
                                className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "9.006%", cursor: "pointer" }}
                              >
                                <div
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  // onClick={(e)=>this.sortVisitTable("working_status")}
                                >
                                  ACTIONS
                                </div>
                              </th>
                            </tr>
                          </thead>
                          {this.state.filterVisits
                            ? this.state.filterVisits.map((item, index) =>
                                index <= this.state.quan ? (
                                  <tbody>
                                    <tr>
                                      <Link to={"/visit/visit-view/" + item.id}>
                                        <td>
                                          <div className="d-flex px-2 py-1">
                                            <div className="d-flex flex-column justify-content-center">
                                              <h6 className="mb-0 font-weight-normal text-sm">
                                                {index +
                                                  1 +
                                                  this.state.index_page}
                                              </h6>
                                            </div>
                                          </div>
                                        </td>
                                      </Link>
                                      <td>
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.date}
                                        </p>
                                      </td>
                                      <td className="align-middle text-center">
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.customer
                                            ? item.customer.customer.company +
                                              "(" +
                                              item.customer.customer.type +
                                              ")"
                                            : null}
                                        </p>
                                      </td>
                                      <td className="align-middle text-center">
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.customer.station
                                            ? item.customer.station +
                                              "(" +
                                              item.customer.state +
                                              ")"
                                            : null}
                                        </p>
                                      </td>
                                      <td className="align-middle text-center">
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.employee
                                            ? item.employee.last_name
                                              ? item.employee.first_name +
                                                " " +
                                                item.employee.last_name
                                              : item.employee.first_name
                                            : null}
                                        </p>
                                      </td>
                                      <td className="align-middle text-center">
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.customer.board
                                            ? item.customer.board.boards
                                            : null}
                                        </p>
                                      </td>
                                      <td className="align-middle text-center">
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.student_str}
                                        </p>
                                      </td>
                                      <td className="align-middle text-center">
                                        <p className="text-sm font-weight-normal mb-0">
                                          {item.contact_no
                                            ? item.contact_person_name +
                                              "(" +
                                              item.contact_no +
                                              ")"
                                            : item.contact_person_name}
                                        </p>
                                      </td>

                                      <td className="align-middle text-center">
                                        <div className="d-flex justify-content-center align-items-center">
                                          <Link
                                            to={"/visit/visit-view/" + item.id}
                                          >
                                            <RemoveRedEyeIcon
                                              style={{
                                                color: "grey",
                                                margin: "0px 5px",
                                              }}
                                            />
                                          </Link>
                                          {/* <Link
                                          to={"/visit/edit-visit/" + item.id}
                                        >
                                          <ModeEditIcon
                                            style={{
                                              color: "#12b312",
                                              margin: "0px 15px",
                                            }}
                                          />
                                        </Link> */}
                                          {this.props.user.employee_profile ===
                                          "admin" ? (
                                            <div
                                              onClick={(e) =>
                                                this.delete(item.id)
                                              }
                                            >
                                              <DeleteIcon
                                                style={{
                                                  color: "red",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : null
                              )
                            : null}
                        </table>
                      </div>
                      {this.state.page === 1 ? null : (
                        <div className="datatable-mui-pagination">
                          <Pagination
                            count={this.state.page}
                            color="secondary"
                            onChange={this.handlePage}
                            size="large"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}

                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.open}
                  onClose={(e) => this.setState({ open: false })}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={this.state.open}>
                    <Box sx={modalStyle}>
                      <Typography
                        id="transition-modal-description"
                        sx={{ mt: 2 }}
                      >
                        {this.state.is_delete ? (
                          <>
                            <div className="circle__box">
                              <div className="circle__wrapper circle__wrapper--right">
                                <div className="circle__whole circle__right delete_circle__right"></div>
                              </div>
                              <div className="circle__wrapper circle__wrapper--left">
                                <div className="circle__whole circle__left delete_circle__left"></div>
                              </div>
                              <div className="circle__checkmark delete_circle__checkmark"></div>
                            </div>

                            <div style={{ textAlign: "center" }}>
                              <h5>{this.props.text}</h5>
                              <h6>
                                <Link
                                  to={this.props.link}
                                  style={{ color: "grey" }}
                                >
                                  Click here{" "}
                                </Link>
                                {this.props.urlText}
                              </h6>
                            </div>
                          </>
                        ) : (
                          <div className="modal-body">
                            <div style={{ textAlign: "center" }}>
                              <h5>
                                Click the Delete button to delete this visit.
                              </h5>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => this.delete("delete")}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </Typography>
                    </Box>
                  </Fade>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisitDataTable);
