import React from "react";
import '../Customer/Style';
import { connect } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import PageNotFound from "../../common/PageNotFound";
import { Offline, Online } from "react-detect-offline";
import InternetConnection from "../../common/InternetConnection";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import TextField from "@mui/material/TextField";
import FilterListIcon from "@mui/icons-material/FilterList";
import {Multiselect} from "multiselect-react-dropdown";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";
import Loader from "../../common/Loader/Loader";
import {axios} from "../../../store/actions/actions";

class CustomerVisits extends React.Component {

  state = {
    visit: null,
    filterVisits: null,
    quan: null,
    total: null,
    page: null,

    filter: false,
    clear: false,
    showfilter: false,
    stateFilter: null,
    boardFilter: null,
    purposeFilter: null,
    fromDate: null,
    toDate: null,

    all_states: null,
    all_boards: null,
    all_purpose: [
        {id: 5, choice: 'FOLLOW UP'},
        {id: 4, choice: 'SURVEY'},
        {id: 3, choice: 'SAMPLING'},
        {id: 2, choice: 'CLOSURE'},
        {id: 1, choice: 'PAYMENT'},
    ]
  }

    componentDidMount() {
        this.loadStates()
        this.loadBoards()
        this.loadVisits()
    }

    loadVisits = () => {
        let pathname = window.location.pathname;
        let pathurl = pathname.split("/");
        let path = pathurl[pathurl.length - 1];

        const data = {
          id: path
        }

        let endpoint = `visit/customer-visit`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({
                    visit: responseData.visit,
                    filterVisits: responseData.visit,
                    quan: responseData.visit.length,
                    total: responseData.visit.length,
                    page: responseData.page
                })
                console.log({'response data---------':responseData})
            });
    }

    loadStates = () => {
        let endpoint = `employee/states`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({all_states: responseData.state})
                console.log({'states response data---------':responseData.state})
            });
    }

    loadBoards = () => {
        let endpoint = `common/get-boards`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({all_boards: responseData.boards})
                console.log({'boards response data---------':responseData.boards})
            });
    }

    applyFilter = () => {
        this.setState({
            filter: true,
            clear: true,
            showfilter: false
        })

        let states = []
        let boards = []
        let purposes = []

        let pathname = window.location.pathname;
        let pathurl = pathname.split("/");
        let path = pathurl[pathurl.length - 1];

        if (this.state.stateFilter!==null && this.state.stateFilter!==[]) {
            console.log('state filter')
            this.state.stateFilter.map((state, index) => (
                states.push(state.id)
            ))
        }

        if (this.state.boardFilter!==null && this.state.boardFilter!==[]){
            console.log('board filter')
            this.state.boardFilter.map((board, index)=>(
                boards.push(board.id)
            ))
        }

        if (this.state.purposeFilter!==null && this.state.purposeFilter!==[]){
            console.log('board filter')
            this.state.purposeFilter.map((purpose, index)=>(
                purposes.push(purpose.id)
            ))
        }

        let endpoint = `visit/filter-visit?working_status_in=${purposes}&customer_in=${path}&state_in=${states}&from_date=${this.state.fromDate?this.state.fromDate:''}&to_date=${this.state.toDate?this.state.toDate:''}`
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>...', endpoint)
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({filterVisits: responseData})
                console.log({'Filtered response data---------': responseData})
            });
    }

    clearFilter = () => {
        this.setState({
            clear: false,
            employee: null,
            stateFilter: null,
            boardFilter: null,
            filterVisits: this.state.visit,
            fromDate: null,
            toDate: null,
        })
    }

    onFilterStateSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList',selectedList)
        console.log('selectedItem',selectedItem)
        this.setState({
            stateFilter: selectedList
        })
    }

    onFilterStateRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList',selectedList)
        console.log('removedItem',removedItem)
        this.setState({
            stateFilter: selectedList
        })
    }

    onFilterBoardSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList',selectedList)
        console.log('selectedItem',selectedItem)
        this.setState({
            boardFilter: selectedList
        })
    }

    onFilterPurposeRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList',selectedList)
        console.log('removedItem',removedItem)
        this.setState({
            purposeFilter: selectedList
        })
    }

    onFilterPurposeSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList',selectedList)
        console.log('selectedItem',selectedItem)
        this.setState({
            purposeFilter: selectedList
        })
    }

    onFilterBoardRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList',selectedList)
        console.log('removedItem',removedItem)
        this.setState({
            boardFilter: selectedList
        })
    }

    handleSearch = (data) => {
      console.log(data)
        let myList = []
        var tmpObj = this.state.visit
        tmpObj.map((i, index) => (i.customer.company.toLowerCase().includes(data.toLowerCase())? myList.push(i) : null))
        console.log(myList)

        // const objWithIndex = myList.map((obj, index)=>(Object.assign(obj,{index: index+1})))

        this.setState({
          filter: true,
          filterVisits: myList,
          // pages: Math.ceil(myList.length/this.state.pageItems)
        })
    }

    printTable = () => {
        console.log('run')
        //Get the HTML of div
        var divElements = document.getElementById('visitTable').innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML =
          "<html><head><title></title></head><body>" +
          divElements + "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;

    }

  render() {
    return (
      <>
        {/*{this.props.user.login === true ?*/}
        {/*  <>*/}
        {/*    <Online>*/}
        {/*      {this.props.user.employee_profile === 'admin' ?*/}
        {/*        <>*/}
        {/*          <div className="container-fluid">*/}
        {/*            <div className="row justify-content-end">*/}
        {/*              <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "d-none d-lg-block col-10" : "d-none d-lg-block col-12"}>*/}
                        <div className="row my-4" id="clients">
                              <div className="col-12">
                                  <div className="card py-3">
                                      {this.state.visit ?
                                          <div className="table-responsive px-2">
                                              <div
                                                  className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                                  <div className="dataTable-top">

                                                      <h5 className="mb-0 ps-3" >Visits</h5>
                                                        <div className=" d-flex justify-content-end align-items-center" style={{position: "relative"}}>
                                                          <LocalPrintshopIcon className='cursor-pointer me-3' onClick={this.printTable}/>
                                                          <TextField size="small" id="outlined-basic" label="Search..." variant="outlined" onChange={(e)=>this.handleSearch(e.target.value)}/>
                                                          <div onClick={(e)=>this.setState({showfilter: this.state.showfilter===true?false:true})}>
                                                            <FilterListIcon style={{cursor: "pointer", marginLeft: "15px"}}/>
                                                          </div>
                                                          {this.state.showfilter===true?
                                                            <div className="card p-4" style={{width: "700px", position: "absolute", top: "50px", right: "0", zIndex: "10", boxShadow: "0px 0px 6px #bdbbbb"}}>
                                                              <h6>FILTERS</h6>
                                                                {this.state.clear?<p onClick={this.clearFilter} style={{color: "#5f5fcb", cursor: "pointer"}}>Clear</p>:null}
                                                              <div className="row p-1">
                                                                  <div className="col-12 p-1">
                                                                      <p>Date range</p>
                                                                      <div className="d-flex mt-2 p-1 align-items-center"  >
                                                                          <TextField
                                                                            id="date"
                                                                            label="From"
                                                                            type="date"
                                                                            size="small"

                                                                            onChange={(e) => this.setState({fromDate: e.target.value})}

                                                                            sx={{ width: 220 }}
                                                                            InputLabelProps={{
                                                                              shrink: true,
                                                                            }}
                                                                          />
                                                                          <p className="mx-4 my-0" style={{fontSize: "24px"}}>to</p>

                                                                          <TextField
                                                                            id="date"
                                                                            label="To"
                                                                            type="date"
                                                                            size="small"

                                                                            onChange={(e) => this.setState({toDate: e.target.value})}

                                                                            sx={{ width: 220 }}
                                                                            InputLabelProps={{
                                                                              shrink: true,
                                                                            }}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-12 p-1 mt-2">
                                                                      <p>Purpose</p>
                                                                      <div className="form-check-inline">
                                                                          <>
                                                                            {this.state.all_purpose?
                                                                            <Multiselect
                                                                                placeholder="Select Purpose"
                                                                                options={this.state.all_purpose} // Options to display in the dropdown
                                                                                onSelect={this.onFilterPurposeSelect} // Function will trigger on select event
                                                                                onRemove={this.onFilterPurposeRemove} // Function will trigger on remove event
                                                                                displayValue="choice" // Property name to display in the dropdown options
                                                                                style={{
                                                                                    searchBox: {fontSize: "15px"}
                                                                                }}
                                                                            />
                                                                            :null}
                                                                          </>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                              <div className="d-flex justify-content-end mt-3">
                                                                  <button onClick={this.applyFilter} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>
                                                              </div>

                                                          </div>
                                                          :null}
                                                      </div>
                                                      {/*<div>*/}
                                                      {/*</div>*/}
                                                  </div>
                                                  <div className="dataTable-container" id="visitTable" style={{height: "580.641px"}}>
                                                      <table className="table align-items-center mb-0 dataTable-table">
                                                          <thead>
                                                          <tr>
                                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                  data-sortable="" style={{width: "4.8032%", cursor: 'pointer'}}>
                                                                  <a
                                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                                  className="dataTable-sorter"
                                                                  onClick={(e)=>this.sortVisitTable("serial_num")}>S.NO
                                                              </a></th>
                                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                                  data-sortable="" style={{width: "7.938%", cursor: 'pointer'}}>
                                                                  <a
                                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                                  className="dataTable-sorter"
                                                                  onClick={(e)=>this.sortVisitTable("date")}>
                                                                  DATE</a></th>
                                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                                  data-sortable="" style={{width: "11.074%", cursor: 'pointer'}}>
                                                                  <a
                                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                                  className="dataTable-sorter"
                                                                  onClick={(e)=>this.sortVisitTable("customer")}>
                                                                  Employee</a></th>
                                                              <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                  data-sortable="" style={{width: "7.538%", cursor: 'pointer'}}>
                                                                  <a
                                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                                  className="dataTable-sorter"
                                                                  onClick={(e)=>this.sortVisitTable("station")}>
                                                                  Board</a></th>
                                                              <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                  data-sortable="" style={{width: "6.204%", cursor: 'pointer'}}>
                                                                  <a
                                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                                  className="dataTable-sorter"
                                                                  onClick={(e)=>this.sortVisitTable("board")}>
                                                                  PURPOSE</a></th>

                                                          </tr>
                                                          </thead>
                                                          {this.state.filterVisits ? this.state.filterVisits.map((item, index) => (
                                                              index <= this.state.quan ?
                                                                  <tbody>
                                                                  <tr>
                                                                      <Link to={"/visit/visit-view/"+item.id}>
                                                                      <td>
                                                                          <div className="d-flex px-2 py-1">
                                                                              <div
                                                                                  className="d-flex flex-column justify-content-center">
                                                                                  <h6 className="mb-0 font-weight-normal text-sm">{index+1}
                                                                                  </h6>
                                                                              </div>
                                                                          </div>
                                                                      </td>
                                                                      </Link>
                                                                      <td>
                                                                          <p className="text-sm font-weight-normal mb-0">{item.date}</p>
                                                                      </td>
                                                                      <td className="align-middle text-center">
                                                                          <p className="text-sm font-weight-normal mb-0">{item.employee?item.employee.last_name ? item.employee.first_name+' '+item.employee.last_name: item.employee.first_name:null}</p>
                                                                      </td>
                                                                      <td className="align-middle text-center">
                                                                          <p className="text-sm font-weight-normal mb-0">{item.board?item.board.boards:null}</p>
                                                                      </td>
                                                                      <td className="align-middle text-center">
                                                                          <p className="text-sm font-weight-normal mb-0">{item.working_status? item.working_status.working_status.choice : null}</p>
                                                                      </td>

                                                                  </tr>
                                                                  </tbody>
                                                                  : null
                                                          )) : null}
                                                      </table>
                                                  </div>
                                                  {this.state.page===1?null:
                                                      <div className="datatable-mui-pagination">
                                                        <Pagination count={this.state.page} color="secondary" onChange={this.handlePage} size="large"/>
                                                  </div>}
                                              </div>
                                          </div>
                                          :
                                          <Loader/>
                                      }
                                  </div>
                              </div>
                        </div>
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </>*/}
        {/*        :*/}
        {/*        <PageNotFound />*/}
        {/*      }*/}
        {/*    </Online>*/}
        {/*    <Offline>*/}
        {/*      <InternetConnection />*/}
        {/*    </Offline>*/}
        {/*  </>*/}
        {/*  :*/}
        {/*  <Redirect to={'/sign-in'} />*/}
        {/*}*/}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(CustomerVisits);
