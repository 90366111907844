import React from "react";
import {url} from "../../../store/actions/actions";
import {connect} from "react-redux";
import Pagination from "@mui/material/Pagination";

class HomeEmployeeDataTable extends React.Component {

    state = {
        employees: null,
        filterEmployees: null,
        pages: null
    }

    componentDidMount() {
        const objWithIndex = this.props.emp.map((obj, index)=>(Object.assign(obj,{index: index+1})))

        this.setState({
            employees: objWithIndex,
            filterEmployees: objWithIndex.slice(0, 10).map(i => {return i}),
            pages: Math.ceil(this.props.emp.length/10)
        })
    }

    handelPagination = (event, value) => {
        let start = 10 * (value - 1)
        let end = 10 * value
        let items = this.state.employees.slice(start, end).map(i => {
            return i
        });

        this.setState({
            filterEmployees: items,
        })
        console.log('items', items)
    }

    render() {
    return(
      <>
        <div className="row my-3" id="clients">
              <div className="col-12">
                  <div className="card py-3">
                          <div className="table-responsive px-2">
                              <div
                                  className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                  <div className="dataTable-top">
                                      <h5 className="mb-0 ps-3" >Active Employees</h5>
                                  </div>
                                  <div className="dataTable-container" id='employeeTable'>
                                      {this.props.emp.length!==0?
                                      <>
                                      <table className="table align-items-center mb-3 dataTable-table">
                                          <thead>
                                          <tr>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "4.8032%", cursor: 'pointer'}}>
                                                  <a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter"
                                                  onClick={(e)=>this.sortEmpTable("serial_num")}>S.NO
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("name")}
                                              ><a
                                                  className="dataTable-sorter">Name
                                              </a></th>
                                              {/*<th className="text-uppercase text-xxs font-weight-bolder opacity-7"*/}
                                              {/*    data-sortable="" style={{width: "20%", cursor: "pointer"}}*/}
                                              {/*    onClick={(e)=>this.sortEmpTable("name")}*/}
                                              {/*><a*/}
                                              {/*    className="dataTable-sorter">Visit*/}
                                              {/*</a></th>*/}
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("state")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter text-center">
                                                  Station</a></th>
                                              <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("status")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter">
                                                  Profile</a></th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.filterEmployees?this.state.filterEmployees.map((emp, index)=>(
                                                  <tr>
                                                      <td>
                                                          <div className="d-flex px-2 py-1">
                                                              <div
                                                                  className="d-flex flex-column justify-content-center">
                                                                  <h6 className="mb-0 font-weight-normal text-sm">{emp.index}
                                                                  </h6>
                                                              </div>
                                                          </div>
                                                      </td>
                                                      <td>
                                                          <div className="d-flex  align-items-center">
                                                              <img src={emp.pic?url+emp.pic:url + "/static/dreambox_logo.jpg"}
                                                                   className="avatar avatar-sm me-3"
                                                                   alt="avatar image"/>
                                                              <h6 className="mb-0 font-weight-normal text-sm">{emp.last_name?emp.first_name+' '+emp.last_name:emp.first_name}</h6>
                                                          </div>
                                                      </td>
                                                      {/*<td >*/}
                                                      {/*   /!*<div style={{height: "23px", overflow: "scroll", display: "block"}}>*!/*/}
                                                      {/*    {emp.visit.length!==0?emp.visit.map((vt, index)=>(*/}
                                                      {/*        <div>*/}
                                                      {/*            <p className="text-sm text-center font-weight-normal mb-0">*/}
                                                      {/*                {vt}*/}
                                                      {/*            </p>*/}
                                                      {/*              {emp.visit.length > 1 ?<hr/>:null}*/}
                                                      {/*        </div>*/}
                                                      {/*    )):null}*/}
                                                      {/*   /!*</div>*!/*/}
                                                      {/*</td>*/}
                                                      <td>
                                                         {/*<div style={{height: "23px", overflow: "scroll", display: "block"}}>*/}
                                                          <div style={{height: emp.station.length>=3?"100px":"none", overflow: "overlay", display: "block"}}>
                                                          {emp.station.map((st, index)=>(
                                                          <div>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {st}
                                                          </p>
                                                          {emp.station.length > 1 ?<hr/>:null}
                                                          </div>
                                                          ))}
                                                          </div>
                                                          {/*</div>*/}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          <p className="text-sm font-weight-normal mb-0">
                                                              {emp.is_admin===true?'Admin':null}
                                                              {emp.is_manager===true?'Manager':null}
                                                              {emp.is_accountant===true?'Accountant':null}
                                                              {emp.is_executive===true?'Executive':null}
                                                              {emp.is_coordinator===true?'Coordinator':null}
                                                          </p>
                                                      </td>
                                                  </tr>
                                          )):null}
                                          </tbody>
                                      </table>
                                      {this.state.pages===1?null:
                                          <div className="datatable-mui-pagination">
                                            <Pagination count={this.state.pages} color="secondary" onChange={this.handelPagination} size="large"/>
                                          </div>
                                      }
                                      </>
                                          :
                                          <h4 style={{textAlign: 'center', color: '#bbb1b1'}}>
                                              No Active Employees Today
                                          </h4>
                                      }

                                  </div>
                              </div>
                          </div>
                  </div>
              </div>
        </div>
      </>

        )
    }
}

const mapStateToProps = state =>({
    user:state.userData.userData,
    sidebar:state.sideBarData.sidebarData.sidebar
})

export default connect(mapStateToProps)(HomeEmployeeDataTable)