import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper, InfoWindow} from 'google-maps-react';
import {google_map_api_key, url} from "../../store/actions/actions";

export class MapContainer extends Component {
  state = {
      marker: null,
      showingInfoWindow: false,
      markPos: null,

      customer: null,
      customer_id: null,
      date: null,
  }

    onMarkerClick = () =>
    this.setState({
      showingInfoWindow: true
    });

    onMapClick = () =>
    this.setState({
      showingInfoWindow: false
    });

    render() {
    return (
      <div id='googleMaps' className="position-relative">
      {this.props.station?
        <div className="google_map">
        <Map
          style={{width: '100%', height: '240%', position: 'relative'}}

          onClick={this.onMapClick}
          google={this.props.google}

          zoom={14}

          initialCenter={{
            lat: this.props.station.customer__station__lat,
            lng: this.props.station.customer__station__lng
          }}

          center={{
            lat: this.props.station.customer__station__lat,
            lng: this.props.station.customer__station__lng
          }}
        >
            {this.props.attendance?this.props.attendance.punch_in_lat_lng?
                <Marker
                  title={'Punch In'}
                  name={'Punch In'}
                  position={{lat: this.props.attendance.punch_in_lat_lng.split(',')[0], lng: this.props.attendance.punch_in_lat_lng.split(',')[1]}}

                />
              :null:null}
            {this.props.attendance?this.props.attendance.punch_out_lat_lng?
                <Marker
                  title={'Punch Out'}
                  name={'Punch Out'}
                  position={{lat: this.props.attendance.punch_out_lat_lng.split(',')[0], lng: this.props.attendance.punch_out_lat_lng.split(',')[1]}}
                />
              :null:null}

            {this.props.marker?this.props.marker.map((mark, index)=>(
                <Marker
                  title={mark.customer__company}
                  name={mark.customer__company}
                  position={{lat: mark.customer__address_by_location__geolocation?mark.customer__address_by_location__geolocation.split(',')[0]:null, lng: mark.customer__address_by_location__geolocation?mark.customer__address_by_location__geolocation.split(",")[1]:null}}
                  onClick={(e)=>this.onMarkerClick(
                      this.setState({
                          customer: mark.customer__company,
                          customer_id: mark.customer__id,
                          date: mark.date,
                          markPos: {lat: mark.customer__address_by_location__geolocation?mark.customer__address_by_location__geolocation.split(',')[0]:null, lng: mark.customer__address_by_location__geolocation?mark.customer__address_by_location__geolocation.split(",")[1]:null},
                      })
                  )}

                  icon={{
                     url: url+"/static/school.png",
                     anchor: new this.props.google.maps.Point(10,10),
                     scaledSize: new this.props.google.maps.Size(35,35)
                  }}
                />
              )):null}

            <InfoWindow
                visible={this.state.showingInfoWindow}
                position={this.state.markPos}
                >
                <div className={'p-2'}>
                  <a href={'/customer/customer-detail/'+this.state.customer_id}>
                    <h6>
                        {this.state.customer}
                    </h6>
                  </a>
                  <p>{this.state.date}</p>
                </div>
            </InfoWindow>

        </Map>
        </div>
      :
        <div className="google_map">
        <Map

          style={{width: '100%', height: '240%', position: 'relative'}}


          onClick={this.onMapClick}
          google={this.props.google}

          zoom={14}

          initialCenter={{
            lat: 28.6162284,
            lng: 77.3876115
          }}

          center={{
            lat: 28.6162284,
            lng: 77.3876115
          }}
        >
            <Marker
              position={{lat: 28.6162284, lng: 77.3876115}}
            />
        </Map>
        </div>
      }
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: (google_map_api_key)
})(MapContainer)