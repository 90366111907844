import {SIDEBAR, USER_LOGIN} from '../constants'
import {default as ax} from "axios";

export const sideBar =(data)=>{
    return {
        type:SIDEBAR,
        data:data
    }
}

export const userLogin =(data)=>{
    return {
        type:USER_LOGIN,
        data:data
    }
}

export const set_url = () => {
    var domain = window.location.hostname;
    if (domain.length===9){
        return 'http://localhost:8000'
    } else {
        var domain_name = domain.slice(0,-10)
        return `http://${domain_name}.localhost:8000`
    }
}

export const axios = ax.create({
    // baseURL: set_url(),
    // baseURL:`http://localhost:8000`,
    baseURL:`https://apitest.souvenirpublisher.in/`,

    // baseURL:`https://apitest.mydova.in`,
    // baseURL:`https://souvenir.mydova.in/`,
    
    
})

// export const url = set_url()
// export const url = `https://apitest.mydova.in`;


export const url = `https://apitest.souvenirpublisher.in/`
// export const url = `http://localhost:8000`;


export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    '@media (max-width: 600px)': {
        width: '95%',  // 600px se niche bhi 60%
      },
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
    borderRadius: '15px'
};
export const google_map_api_key = 'AIzaSyBNqTdgvUBkCDJplidQjVlEKdm_VIU5qe0'
// Old
// export const google_map_api_key = `AIzaSyAQsdAypekPAxdJOjefqcjm4pWJ3t2XGAY`;

export const geoapify_key = `f1b736c3afc34cb4983f1aaef873abff`;


