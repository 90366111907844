import React from "react";
import {connect} from "react-redux";
import {userLogin} from "../../../store/actions/actions";
import {Link} from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import '../../common/common.css'


class EmpDataTable extends React.Component {
    
  render() {
      console.log('_________________________________',this.props.visit)
    return (
      <>
        <div className="d-none d-lg-block">
        <div className="row my-4 mb-5" id="clients">
              <div className="col-12">
                  <div className="card py-3">
                      <div className="table-responsive px-2">
                          <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                              <div className="dataTable-container"
                                   // style={{height: "580.641px"}}
                              >
                                  <table className="table align-items-center mb-0 dataTable-table" id="empe">
                                      <thead>

                                          <tr>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter">Customer
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Executive
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}

                                                  >
                                                  <a
                                                  className="dataTable-sorter ">
                                                  State</a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">City
                                              </a></th>

                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Action
                                              </a></th>
                                          </tr>
                                      </thead>
                                      {this.props.visit?this.props.visit.map((item)=>(
                                      <tbody>
                                              <tr>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.customer?item.customer.company:null}
                                                        </h6>
                                                      <br/>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                                {item.employee.first_name}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.station?item.station.state.state:null}
                                                        </h6>
                                                      <br/>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.station?item.station.station:null}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                  </td>
                                                  <td className="align-middle text-center">
                                                      <div className="d-flex ">

                                                          <Link to={"/visit/visit-view/" + item.id}>
                                                            <RemoveRedEyeIcon style={{color: "grey", margin:"0px 5px"}}/>
                                                          </Link>
                                                          {/*<Link to={"/visit/edit-visit/" + item.id}>*/}
                                                          {/*    <ShareIcon style={{color: "#12b312", margin:"0px 15px"}}/>*/}
                                                          {/*</Link>*/}
                                                      </div>
                                                  </td>
                                              </tr>
                                      </tbody>
                                      )):null}
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
        </div>
        <div className="d-lg-none">
        <div className="row my-4 mb-5" id="clients">
              <div className="col-12">
                  <div className="card py-3 card-kmv">
                      <div className="table-responsive px-2">
                          <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                              <div className="dataTable-container"
                                   // style={{height: "580.641px"}}
                              >
                                  <table className="table align-items-center mb-0 dataTable-table kv-table" id="empe">
                                      <thead>

                                          <tr>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter">Customer
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Executive
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}

                                                  >
                                                  <a
                                                  className="dataTable-sorter ">
                                                  State</a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">City
                                              </a></th>

                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Action
                                              </a></th>
                                          </tr>
                                      </thead>
                                      {this.props.visit?this.props.visit.map((item)=>(
                                      <tbody>
                                              <tr>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.customer.company}
                                                        </h6>
                                                      <br/>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                                {item.employee.first_name}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.station?item.station.state.state:null}
                                                        </h6>
                                                      <br/>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="align-items-center">
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.station?item.station.station:null}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                  </td>
                                                  <td className="align-middle text-center">
                                                      <div className="d-flex ">

                                                          <Link to={"/visit/visit-view/" + item.id}>
                                                            <RemoveRedEyeIcon style={{color: "grey", margin:"0px 5px"}}/>
                                                          </Link>
                                                          {/*<Link to={"/visit/edit-visit/" + item.id}>*/}
                                                          {/*    <ShareIcon style={{color: "#12b312", margin:"0px 15px"}}/>*/}
                                                          {/*</Link>*/}
                                                      </div>
                                                  </td>
                                              </tr>
                                      </tbody>
                                      )):null}
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
    user:state.userData.userData
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmpDataTable);

