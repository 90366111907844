import React from "react";
import {axios, userLogin, modalStyle} from "../../store/actions/actions";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';

class SignIn extends React.Component {

    state = {
        username: null,
        password: null,
        login: false,

        errorMessage: null,
        open: false,

        page: null
    }

    getToken = async (resp) => {
        const data = {
            username: resp.username,
            password: this.state.password
        }

        let endpoint = `get-token/`
        console.log(endpoint, "Endpoint")
        await axios
            .post(endpoint, data)
            .then((res) => {
                let responseData = res.data;
                console.log('Token--------------------->', responseData)
                this.props.userLoginHandler({
                    login: true,
                    schema: resp.schema,
                    expiry_date: resp.expiry_date,
                    username: resp.username,
                    employee_full_name: resp.employee_full_name,
                    employee_first_name: resp.employee_first_name,
                    employee_last_name: resp.employee_last_name,
                    employee_id: resp.employee_id,
                    employee_profile: resp.profile,
                    token: {
                            headers: {
                                'Authorization': 'Bearer ' + responseData.access
                            }
                    }
                })

                this.setState({login: responseData ? true : null, showLoader: false})
            })
            .catch((error) => console.log({error}));
    }

    checkUser = () => {
        this.setState({
            errorMessage: null,
            open: false,
            showLoader: true
        })
        const data = {
            username: this.state.username,
            password: this.state.password
        }

        let endpoint = `employee/login`

        axios
            .post(endpoint, data)
            .then((response) => {
                const responseData = response.data;
                if (responseData.success===true) {
                    console.log(responseData)
                    this.getToken(responseData);

                } else {
                    // document.getElementById("login-error").innerText = responseData.msg;
                    console.log(responseData)
                    this.setState({
                        showLoader: false,
                        errorMessage: responseData.msg
                    })
                    let timeOut = setTimeout(this.openModal, 300)
                }
            })
            .catch((error) =>
                    this.setState({
                        errorMessage: error
                    }));
    }

    openModal = () => {
        this.setState({
            open: true
        })
    }

    render() {
            let schema = window.location.hostname.slice(0,-10)
        return(
            <>
                {this.props.user.login===true?
                    <Redirect to={'/'}/>
                :
                    <>
                    <div className="main-content  mt-0">
                    <div className="page-header align-items-start min-height-300 m-3 border-radius-xl"
                         style={{backgroundImage: "url('https://images.unsplash.com/photo-1491466424936-e304919aada7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1949&q=80')"}}>
                      <span className="mask bg-gradient-dark opacity-6"></span>
                    </div>
                    <div className="container mb-4">
                      <div className="row mt-lg-n12 mt-md-n12 mt-n12 justify-content-center">
                        <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
                          <div className="card mt-8">
                            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">

                                <div className="bg-gradient-success shadow-success border-radius-lg py-3 pe-1 text-center py-4">
                                <h4 className="font-weight-bolder text-white mt-1">Sign In</h4>
                                <p className="mb-1 text-sm text-white">Enter your email and password to Sign In</p>
                          </div>

                            </div>
                            <div className="card-body">

                                <form role="form" className="text-start">
                                    <div className="input-group input-group-static mb-4">
                                        <label>Email</label>
                                        <input type="email" className="form-control lower-input"
                                               placeholder="john@email.com"
                                               onChange={(e) => this.setState({username: e.target.value})}
                                        />
                                    </div>
                                    <div className="input-group input-group-static mb-4">
                                        <label>Passwords</label>
                                        <input type="password" className="form-control lower-input"
                                               placeholder="•••••••••••••"
                                               onChange={(e) => this.setState({password: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-check form-switch d-flex align-items-center mb-3">
                                        <input className="form-check-input" type="checkbox" id="rememberMe" checked=""/>
                                        <label className="form-check-label mb-0 ms-3" htmlFor="rememberMe">Remember
                                            me</label>
                                    </div>
                                    {this.state.showLoader ?
                                        <Box className="justify-content-center" sx={{display: 'flex'}}>
                                            <CircularProgress/>
                                        </Box>
                                        :
                                        <div className="text-center">
                                            <button onClick={this.checkUser} type="button"
                                                    className="btn bg-gradient-dark w-100 mt-3 mb-0">Sign in
                                            </button>
                                        </div>
                                    }
                                    {schema===''?
                                    <div className='text-center mt-4'>
                                  <span
                                      style={{
                                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                          color: 'rgb(123, 128, 154)',
                                          fontSize: '0.875rem',
                                          fontWeight: '300'
                                      }}
                                  >Don't have an account?
                                      <Link to="/sign-up"
                                         style={{fontWeight: '600', color: 'rgb(26, 115, 232)'}}>&nbsp; Sign up</Link>
                                  </span>
                                    </div>
                                        :null}
                                </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.open}
                        onClose={(e) => this.setState({ open: false })}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.open}>
                            <Box sx={modalStyle}>
                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                    <div className="modal-body">
                                        <div className="d-flex justify-content-center" style={{ textAlign: 'center' }}>
                                            <h5 style={{color: 'red'}}>{this.state.errorMessage}</h5>
                                        </div>
                                    </div>
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = state =>({
    user:state.userData.userData
})

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps,mapDispatchToProps)(SignIn)

