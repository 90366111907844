import React from "react";
import {connect} from "react-redux";
import EditTripComp from "./EditTripComp";
import {Redirect} from "react-router-dom";
import ViewTripComponent from "./ViewTripComponent";

class ViewTrip extends React.Component {

  render() {

    return (
            <>
            {this.props.user.login===true?
                <>
        <div className="row justify-content-end">
            <div className={this.props.sidebar===undefined || this.props.sidebar===true?"col-md-10":"col-12"}>
                  <ViewTripComponent token={this.props.user.token}/>
            </div>
        </div>
      </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>
    );
  }
}

const mapStateToProps = (state) => ({
    user:state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(ViewTrip);

