import React from 'react';
// import Loader from 'react-loader-spinner';
import "../../common/Loader/Customloader.css"

const Customloader = () => {
  return (
    <div id='lppp'>
        <div className="custom-loader">
        <p></p>
        </div>
    </div>
  );
};

export default Customloader;
