import React from "react";
import { connect } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import '../../../custom/css/srkcutom.css'
import DataCards from "./DataCards";
import AttendanceDataTable from "./AttendanceDataTable";
import { Hidden } from "@mui/material";


class AttendanceDash extends React.Component {

    render() {
        return (
            <>
                {this.props.user.login === true ?
                    <>
                        <div className="">
                            <div className="row justify-content-end homepage-view">
                                <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "d-none d-lg-block col-10" : "d-none d-lg-block col-12"}>
                                    
                                    {this.props.user.employee_profile === 'admin' ?
                                       <>
                                       
                                        <DataCards />
                                       </>
                                    :null}
                                     <AttendanceDataTable/>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-end">
                            <div className='d-lg-none'>
                                  <div className="dashOption-mv mt-4" style={{overflow:"hidden"}}>
                                      <div className="">
                                          <div className="button-bar-mv d-flex">
                                              <Link to='/attendance' className="btn btn-mv active"><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                              <Link to="/attendance/punch" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Punch</Link>
                                          </div>
                                      </div>
                                  </div>
                                  {/* <DataCards /> */}
                                  <div>
                                    <AttendanceDataTable />
                                  </div>
                                </div>
                        </div>

                    </>
                    :
                    <Redirect to={'/sign-in'} />
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    sidebar: state.sideBarData.sidebarData.sidebar,
    user: state.userData.userData
})

export default connect(mapStateToProps)(AttendanceDash)