import React, { useState } from 'react';
import { connect } from "react-redux";
import { Grid, Card, Typography } from '@mui/material';
import axios from 'axios';

function Srktesting(props) {
    const [address, updateAddress] = useState("")
    const [geolocation, updategeolocation] = useState("")
    const [datetime, updatedatetime] = useState("")

    const formhandler = (e) => {
        e.preventDefault()
        console.log("Kaam kar Rha hai", address, geolocation, datetime)

        let token = props.user.token

        let endpoint = "common/srk-crud-api"
        
        let data = {
            address : address,
            geolocation : geolocation,
            datetime : datetime,
        }

        axios.post(`http://127.0.0.1:8000/${endpoint}` , data, token)
        .then(
            (res => {
                console.log(res, "+-+-+-+-")
            })
        ) 
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-8">
                        <div className="card p-3">
                        <form onSubmit={formhandler}>
                            <div class="mb-3">
                                <label for="address" class="form-label">Address</label>
                                <input type="text" class="form-control border" id="address" name = "address" value={address} onChange={(e) => updateAddress(e.target.value)} />
                            </div>
                            <div class="mb-3">
                                <label for="geolocation" class="form-label">Geo Location</label>
                                <input type="url" class="form-control border" id="geolocation" name = "location" value={geolocation} aria-describedby="emailHelp" onChange={(e) => updategeolocation(e.target.value)}/>
                            </div>
                            <div class="mb-3">
                                <label for="datetime" class="form-label">Date & Time</label>
                                <input type="datetime-local" name = "datetime" class="form-control border" value={datetime} onChange={(e) => updatedatetime(e.target.value)} id="datetime" />
                            </div>
                            <button type="submit"  class="btn btn-primary">Submit</button>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    sidebar: state.sideBarData.sidebarData.sidebar,
    user: state.userData.userData,
  });
  
  export default connect(mapStateToProps)(Srktesting);
  