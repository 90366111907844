import React from "react";
import "../Customer/Style";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { axios, url, modalStyle } from "../../../store/actions/actions";
import Loader from "../../common/Loader/Loader";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import TextField from "@mui/material/TextField";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import {default as MUISelect} from "@mui/material/Select/Select";

class EditVisit extends React.Component {
  state = {
    my_array: [],

    visit: null,
    id: null,
    contact_person_name: null,
    contact_no: null,
    customer: null,
    cust_type: null,

    working_status_id: null,
    purpose: null,
    instance_questionnaire: null,
    questionnaire: [],
    text_remark: null,
    status: null,
    recordState: null,
    start: false,
    mp3: false,

    sample_array: [
        {
            id: "sample_" +new Date().valueOf(),
            book: null,
            from: null,
            to: null,
            class_from: null,
            class_to: null,
            selected_from: null,
            selected_to: null,
            quantity: null,
            price: null,

            n_from: null,
            n_to: null,

            instance_samples: null,
        }
    ],

    series: null
  };

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  componentDidMount() {
    this.loadVisit();
    this.loadWorkingStatus();
    this.loadBooks()
  }


  loadBooks = () => {
        let endpoint = `books/get-books`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                console.log({'Books Response data----------->': responseData})
                this.setState({
                    series: responseData.books
                })
            })
    }

  loadWorkingStatus = () => {
    let endpoint = `visit/working-status`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ status: responseData.status });
      console.log({
        "Working status response data---------": responseData,
      });
    });
  };

  loadVisit = () => {
    let pathname = window.location.pathname;

    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];
    let endpoint = `visit/${path}`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      
      this.setState({
        visit: [responseData.visit],

        instance_samples_array: responseData.samples,

        id: responseData.visit.id,

        purpose: responseData.visit.working_status
          ? responseData.visit.working_status.working_status
            ? responseData.visit.working_status.working_status.choice
            : null
          : null,
        mp3: responseData.visit
          ? responseData.visit.working_status
            ? responseData.visit.working_status.remark
              ? url + responseData.visit.working_status.remark
              : null
            : null
          : null,
        text_remark: responseData.visit
          ? responseData.visit.working_status
            ? responseData.visit.working_status.text_remark
              ? responseData.visit.working_status.text_remark
              : null
            : null
          : null,

        customer: responseData.visit.customer.company,
        cust_type: responseData.visit.customer.type,

        contact_person_name: responseData.visit.contact_person_name,
        contact_no: responseData.visit.contact_no,

        working_status_id: responseData.visit.working_status
          ? responseData.visit.working_status.id
          : null,
      });
      if (
        responseData.visit.working_status.questionnaire !== null ||
        responseData.visit.working_status.questionnaire !== undefined
      ) {
        this.getInstQuestionnaire(
          responseData.visit.working_status.working_status.choice
        );
      }
      this.storeQuest(
        responseData.visit.working_status
          ? responseData.visit.working_status.questionnaire
            ? responseData.visit.working_status.questionnaire
            : null
          : null
      );
      console.log({ "Visit response data---------": responseData });
    });
  };

  getInstQuestionnaire = (status) => {
    this.setState({ purpose: status });
    const data = {
      status: status,
    };

    let endpoint = `visit/questionnaires`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      this.setState({ quest: responseData.quest });
      console.log({
        "Questionnaire response data---------": responseData,
      });
    });
  };

  getQuestionnaire = (status) => {
    this.setState({
      purpose: status,
      my_array: [],
      mp3: null,
      text_remark: null,
    });
    const data = {
      status: status,
    };

    let endpoint = `visit/questionnaires`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      this.setState({ quest: responseData.quest });
      console.log({
        "Questionnaire response data---------": responseData,
      });
    });
  };

  handleQuestionnaire = (item) => {
    let check = this.state.my_array.includes(item);
    if (check === false) {
      this.state.my_array.push(item);
    } else {
      let index = this.state.my_array.indexOf(item);

      if (index > -1) {
        this.state.my_array.splice(index, 1);
      }
    }
    this.setState({ questionnaire: this.state.my_array });
    console.log(this.state.my_array);
  };

  storeQuest = (obj) => {
    obj.map((item) => this.state.my_array.push(item.option));
    this.setState({
      instance_questionnaire: obj,
    });
    console.log("---------->", this.state.my_array);
  };

  start = () => {
    this.setState({
      recordState: RecordState.START,
      start: true,
    });

  };

  stop = () => {
    this.setState({
      recordState: RecordState.STOP,
      start: false,
    });
    console.log("audioData...............", this.state.recordState);
  };

  onStop = (audioData) => {
    console.log("audioData...............", audioData);
    console.log("audioData...............", audioData.blob);
    this.setState({
      mp3: audioData.url,
      recordState: audioData.blob,
    });
  };

  saveQuestionnaire = () => {
    let pathname = window.location.pathname;
    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];
    const data = {
      visit_id: path,
      working_status: this.state.purpose,
      questionnaire: this.state.questionnaire,
      remark: this.state.text_remark,
    };
    console.log(data);

    let endpoint = `visit/save-working-status`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      if (responseData.success === true) {
        if (this.state.recordState !== null) {
          this.saveAudio(responseData.id);
        }
        this.setState({
          working_status_id: responseData.id,
        });
      }
      console.log({
        "Working status response data---------": responseData,
      });
    });
  };

  saveAudio = () => {
    let data = new FormData();
    data.append("file", this.state.recordState);
    data.append("id", this.state.working_status_id);

    console.log("------------------------>", this.state.working_status_id);

    let endpoint = `visit/save-working-status`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      console.log({
        "Working status response data---------": responseData,
      });
    });
  };

  addSample = () => {
    let obj_array = this.state.sample_array;
    let obj = {
        id: "sample_" +new Date().valueOf(),

        selected_from: null,
        selected_to: null,
        book: null,
        from: null,
        to: null,
        class_from: null,
        class_to: null,
        quantity: null,
        price: null,

        n_from: null,
        n_to: null,

    };
    obj_array.push(obj)
    this.setState({
        sample_array: obj_array
    });
  };

  removeSample = (id) => {
    let arr = this.state.sample_array
    let filteredArray = arr.filter((item) => item.id !== id);
    this.setState({
        sample_array: filteredArray
    })
  };

  saveValueToObject = (id, key, value) => {
    let obj_array = this.state.sample_array
    let filter_obj = obj_array.filter((i)=>(i.id===id))
    if (key==='book') {
        let obj = this.state.series.filter((i)=>(i.title.toLowerCase().includes(value.toLowerCase())))
        obj_array.map((i, index) => (i.id === id ? i['selected_from'] = obj[0].book : null))
        obj_array.map((i, index) => (i.id === id ? i['selected_to'] = obj[0].book : null))
        obj_array.map((i, index) => (i.id === id ? i[key] = obj[0].id : null))
    } else {
        if (key === 'from'){
            obj_array.map((i, index) => (i.id === id ? i['class_from'] = value : null))
            obj_array.map((i, index) => (i.id === id ? i['n_from'] = value.n : null))
            obj_array.map((i, index) => (i.id === id ? i[key] = value.id : null))
            obj_array.map((i, index) => (i.id === id ? i['price'] = this.calculateAmount(filter_obj): null))
        }

        if (key === 'to'){
            obj_array.map((i, index) => (i.id === id ? i['class_to'] = value : null))
            obj_array.map((i, index) => (i.id === id ? i['n_to'] = value.n : null))
            obj_array.map((i, index) => (i.id === id ? i[key] = value.id : null))
            obj_array.map((i, index) => (i.id === id ? i['price'] = this.calculateAmount(filter_obj): null))
        }

        if (key === 'quantity') {
            obj_array.map((i, index) => (i.id === id ? i[key] = value: null))
            obj_array.map((i, index) => (i.id === id ? i['price'] = this.calculateAmount(filter_obj): null))
        }
    }

    this.setState({
        sample_obj: obj_array
    })

  };

  calculateAmount = (obj) => {
      let amount_array = []
      if (obj[0].n_to===null){
          obj[0].selected_from.map((item)=> {
              if (item.n===obj[0].n_from) {
                  amount_array.push(Number(item.price))
              }
          })
          return obj[0].quantity===null || obj[0].quantity===0?amount_array[0]:obj[0].quantity*amount_array[0]

      } else {
          for (let step = obj[0].n_from; step < obj[0].n_to + 1; step++) {
              obj[0].selected_from.map((item) => {
                  if (item.n === step) {
                      amount_array.push(Number(item.price))
                  }
              })
          }
          const sum = amount_array.reduce((partialSum, a) => partialSum + a, 0);
          return obj[0].quantity === null || obj[0].quantity === 0 ? sum : obj[0].quantity * sum
      }
  }

  loadSubmitSamples = () => {
    let pathname = window.location.pathname;

    let pathurl = pathname.split("/");
    let id = pathurl[pathurl.length - 1];
    const data = {
      id: id
    }

    var config = {
        method: 'post',
        url: 'books/submit-sample',
        data: data,
        headers: this.props.user.token.headers
    };

    axios(config)
        .then((res) => {
            let responseData = res.data;
            console.log({ 'Submit Samples Response data-------$$$$$$$$$$$$$$$$$$$4----->': responseData})
            this.setState({
                instance_samples_array: responseData.samples
            })
        })
  }

  delete = (id) => {
    const data = {
      id: id
    }

    var config = {
        method: 'delete',
        url: 'books/submit-sample',
        data: data,
        headers: this.props.user.token.headers

    };

    axios(config)
        .then((res)=>{
            let responseData = res.data;
            console.log({'response data---------':responseData})
            this.loadSubmitSamples()
        })
  }

  submit = () => {
    let pathname = window.location.pathname;

    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];
    const data = {
      visit_id: path,
      contact_person_name: this.state.contact_person_name,
      contact_person_phone: this.state.contact_no,
      samples: this.state.sample_array
    };

    console.log({ "data---------": data });
    let endpoint = `visit/`;
    let token = this.props.user.token;

    axios.put(endpoint, data, token).then((res) => {
      let responseData = res.data;
      if (responseData.success === true) {
        if (this.state.purpose !== null) {
          if (this.state.questionnaire.length !== 0) {
            this.saveQuestionnaire(path);
          } else {
            this.saveAudio();
          }
        }
        this.setState({
          open: true,
        });
      }
      console.log({ "submit---------": responseData });
    });
  };

  render() {
      console.log('Mp3------------------------------------>', this.state.mp3)
    return (
      <>
        {this.props.user.login === true ? (
          <>
            {this.state.visit ? (
              this.state.visit.map((vt, index) => (
                <div className="container-fluid">
                  <div className="row justify-content-end">
                    <div
                      className={
                        this.props.sidebar === undefined ||
                        this.props.sidebar === true
                          ? "d-none d-lg-block col-9 me-5"
                          : "d-none d-lg-block col-10 me-5"
                      }
                    >
                      <>
                        <div className="card mt-4">
                          <div className="card-body p-3">
                            <div id="basic-info">
                              <div>
                                <div>
                                  <h5 className="font-weight-bolder mb-2">
                                    Basic Info
                                  </h5>
                                </div>
                              </div>
                              <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Employee
                                      </label>
                                      <input
                                        value={
                                          vt.employee.last_name
                                            ? vt.employee.first_name +
                                              " " +
                                              vt.employee.last_name
                                            : vt.employee.first_name
                                        }
                                        className="multisteps-form__input form-control border-bottom mt-4"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Serial no.
                                      </label>
                                      <input
                                        value={vt.serial_number}
                                        className="multisteps-form__input form-control mt-4"
                                        type="text"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Customer
                                      </label>
                                      <input
                                        value={
                                          this.state.customer +
                                          "(" +
                                          this.state.cust_type +
                                          ")"
                                        }
                                        className="multisteps-form__input form-control mt-4"
                                        type="text"
                                      />

                                      <Link
                                        style={{ marginTop: "18px" }}
                                        to={
                                          "/customer/edit-customer/" +
                                          vt.customer.id
                                        }
                                      >
                                        <ModeEditIcon
                                          style={{ color: "#12b312" }}
                                        />
                                      </Link>
                                    </div>
                                  </div>

                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Date</label>
                                      <input
                                        value={vt.date}
                                        className="multisteps-form__input form-control mt-4"
                                        type="text"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Station
                                      </label>
                                      <input
                                        value={
                                          vt.station
                                            ? vt.station.station +
                                              "(" +
                                              vt.station.state.state +
                                              ")"
                                            : null
                                        }
                                        className="multisteps-form__input form-control mt-4"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card mt-4">
                          <div className="card-body p-3">
                            <div id="basic-info">
                              <div>
                                <div>
                                  <h5 className="font-weight-bolder mb-2">
                                    Contact Person Details
                                  </h5>
                                </div>
                              </div>
                              <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Name</label>
                                      <input
                                        defaultValue={
                                          this.state.contact_person_name
                                        }
                                        className="multisteps-form__input form-control mt-4"
                                        type="text"
                                        onChange={(e) =>
                                          this.setState({
                                            contact_person_name: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Phone
                                      </label>
                                      <input
                                        defaultValue={this.state.contact_no}
                                        className="multisteps-form__input form-control mt-4"
                                        type="text"
                                        onChange={(e) =>
                                          this.setState({
                                            contact_no: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*  -----------  Working Status ---------- */}

                        <div className="card mt-4">
                          <div className="card-body p-3">
                            <div id="basic-info">
                              <div>
                                <div>
                                  <h5 className="font-weight-bolder mb-2">
                                    Working Status
                                  </h5>
                                </div>
                              </div>
                              <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-12 input-group input-group-static">
                                    <label className="form-label">
                                      Purpose of visit
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <div className="dropdown mt-5">
                                      <div
                                        className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        id="navbarDropdownMenuLink2"
                                        aria-expanded="false"
                                      >
                                        {/*Purpose of visit*/}
                                        {this.state.purpose
                                          ? this.state.purpose
                                          : "Purpose of visit"}
                                      </div>
                                      <ul
                                        className="dropdown-menu w-50 srkw"
                                        aria-labelledby="navbarDropdownMenuLink2"
                                      >
                                        {this.state.status
                                          ? this.state.status.map(
                                              (st, index) => (
                                                <li>
                                                  <div
                                                    className="dropdown-item"
                                                    onClick={(e) =>
                                                      this.getQuestionnaire(
                                                        st.choice
                                                      )
                                                    }
                                                  >
                                                    {st.choice}
                                                  </div>
                                                </li>
                                              )
                                            )
                                          : null}
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                {this.state.quest ? (
                                  <div className="col-12 w-100 d-flex justify-content-center quest-button">
                                    <div className="card-box card w-75 p-5">
                                      <div className="arrow-up"></div>

                                      {this.state.quest.map((qst, index) => (
                                        <div className="row justify-content-center mt-5">
                                          <div className="col-md-8">
                                            <p>{qst}</p>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="btn-box">
                                              <button
                                                onClick={(e) =>
                                                  this.handleQuestionnaire(qst)
                                                }
                                                type="submit"
                                                className={
                                                  this.state.my_array.includes(
                                                    qst
                                                  ) === true
                                                    ? "btn btn-confirm btn-primary"
                                                    : "btn btn-confirm btn-color"
                                                }
                                              >
                                                Yes
                                              </button>

                                              <button
                                                onClick={(e) =>
                                                  this.handleQuestionnaire(qst)
                                                }
                                                type="submit"
                                                className={
                                                  this.state.my_array.includes(
                                                    qst
                                                  ) === true
                                                    ? "btn btn-confirm btn-color"
                                                    : "btn btn-confirm btn-primary"
                                                }
                                              >
                                                No
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                      <div className="row justify-content-center">
                                        <div className="col-md-9">
                                          <div className="microphone d-flex flex-column align-items-center">
                                            <div>
                                              <div
                                                className="audio-view"
                                                style={{
                                                  display: "none",
                                                }}
                                              >
                                                <AudioReactRecorder
                                                  canvasWidth={500}
                                                  canvasHeight={100}
                                                  state={this.state.recordState}
                                                  onStop={this.onStop}
                                                />
                                              </div>

                                              {this.state.start === false ? (
                                                <div>
                                                  <div
                                                    className="p-4 position-relative"
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                    onClick={this.start}
                                                  >
                                                    <button
                                                      id="speech"
                                                      className="btn micBtn"
                                                    >
                                                      <i
                                                        className="fa fa-microphone"
                                                        aria-hidden="true"
                                                        style={{
                                                          fontSize: "35px",
                                                          position: "absolute",
                                                          top: "21px",
                                                          right: "27px",
                                                        }}
                                                      ></i>
                                                    </button>
                                                  </div>

                                                  {this.state.mp3 ? (
                                                    <div
                                                      className="audioPlayer col-2 ms-3 d-inline-block"
                                                      style={{
                                                        width: "100%",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: "10px",
                                                      }}
                                                    >
                                                      <audio
                                                        src={this.state.mp3}
                                                        controls
                                                      ></audio>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : (
                                                <div
                                                  className="p-4 position-relative"
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                  onClick={this.stop}
                                                >
                                                  <button
                                                    id="speech"
                                                    className="btn micBtn"
                                                  >
                                                    <i
                                                      className="fa fa-microphone"
                                                      aria-hidden="true"
                                                      style={{
                                                        fontSize: "35px",
                                                        position: "absolute",
                                                        top: "21px",
                                                        right: "27px",
                                                      }}
                                                    ></i>
                                                  </button>
                                                  <div className="pulse-ring"></div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="">
                                        <div className="input-group input-group-static is-filled">
                                          <TextField
                                            id="standard-multiline-flexible"
                                            label="Remark"
                                            multiline
                                            maxRows={4}
                                            defaultValue={
                                              this.state.text_remark
                                                ? this.state.text_remark
                                                : ""
                                            }
                                            className="form-control"
                                            onFocus="focused(this)"
                                            onChange={(e) =>
                                              this.setState({
                                                text_remark: e.target.value,
                                              })
                                            }
                                            onfocusout="defocused(this)"
                                            variant="standard"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card mt-4">

                       <div className="sample-thead mt-2" style={{ padding: "20px 30px" }}>
                          <div className="table-responsive">
                              <table className="table mt-2">

                             <thead>
                                <tr>
                                   <th >S. No.</th>
                                   <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                   <th>From</th>
                                   <th>To</th>
                                   <th>&nbsp;&nbsp;Qty.&nbsp;&nbsp;</th>
                                   <th>&nbsp;&nbsp;Price&nbsp;&nbsp;</th>
                                   <th>Action</th>
                                </tr>
                             </thead>
                              <tbody>
                             {this.state.instance_samples_array
                                ? this.state.instance_samples_array.map((sample, index) => (

                                        <tr>
                                           <td width="7%" className="text-center">
                                              <div><span className="">{index + 1}</span>.</div>
                                           </td>
                                           <td width="43%">
                                              <p>{sample.book[0].title.title}</p>
                                           </td>

                                           <td width="10%">
                                             <p>{sample.book[0].classes.class_name}</p>
                                           </td>

                                           <td width="10%">
                                             <p>{sample.book[sample.book.length-1].classes.class_name}</p>
                                           </td>

                                           <td width="10%">
                                              <p className='text-center'>{sample.quantity}</p>
                                           </td>

                                           <td width="10%">
                                              <p className='text-center'>{sample.price}</p>
                                           </td>
                                           <td className="align-middle text-center">
                                              <div className="d-flex justify-content-center align-items-center">
                                                  <div onClick={(e) => this.delete(sample.id)} >
                                                      <DeleteIcon style={{color: "red", cursor: "pointer"}}/>
                                                  </div>
                                              </div>
                                           </td>
                                        </tr>
                                  ))
                                : null}
                              </tbody>
                          </table>
                          </div>
                       </div>
                    </div>

                        {this.state.purpose==='SAMPLING'?
                            <div className="card mt-4">
                                                                                <div className="sample-thead mt-4" style={{ padding: "20px 30px" }}>
                                                                                                              <div className="">
                                                                                                                 <div
                                                                                                                    className="add-ins float-right d-flex mb-3"
                                                                                                                    style={{ cursor: "pointer" }}
                                                                                                                    onClick={this.addSample}
                                                                                                                 >
                                                                                                                    <img src={require("../../../custom/icons/plus.png")} height="30" />
                                                                                                                    <p className="m-1">ADD NEW SAMPLE</p>
                                                                                                                 </div>
                                                                                                              </div>
                                                                                                  <div className="table-responsive">
                                                                                                      <table className="table mt-2">
                         <thead>
                            <tr>
                               <th >S. No.</th>
                               <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                               <th>From</th>
                               <th>To</th>
                               <th>&nbsp;&nbsp;Qty.&nbsp;&nbsp;</th>
                               <th>&nbsp;&nbsp;Price&nbsp;&nbsp;</th>
                               <th>Action</th>
                            </tr>
                         </thead>
                         {this.state.sample_array
                            ? this.state.sample_array.map((sample, index) => (
                                 <tbody>
                                    <tr>
                                       <td width="7%" className="text-center">
                                          <div style={{marginTop: '16px'}}><span className="">{index + 1}</span>.</div>
                                       </td>
                                       <td width="43%">
                                          {this.state.series?
                                                <Autocomplete
                                                    className='pl-1 pr-1'
                                                    id="free-solo-demo"
                                                    size={'small'}
                                                    style={{marginTop: '16px', width: "100%"}}
                                                    options={this.state.series.map((option) => option.title)}
                                                    onChange={(event, value) =>
                                                        this.saveValueToObject(sample.id, "book", value)
                                                    }
                                                    renderInput={(params) => <TextField {...params} label="Select Book" />}
                                              />
                                          :null}
                                       </td>

                                       <td width="10%">
                                             {/*<div className="">*/}
                                             {/*   <input*/}
                                             {/*      type="number"*/}
                                             {/*      id={sample.id}*/}
                                             {/*      value={sample.from?sample.from:''}*/}
                                             {/*       onChange={(e) =>*/}
                                             {/*          this.saveValueToObject(sample.id, "from", e.target.value)*/}
                                             {/*       }*/}
                                             {/*      className="form-control w-100 p-2"*/}
                                             {/*      style={{background: "#ededed"}}*/}
                                             {/*   />*/}
                                             {/*</div>*/}
                                           <div className="">
                                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">From</InputLabel>
                                            <MUISelect
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              value={sample.class_from?sample.class_from:''}
                                              onChange={(e) =>
                                                 this.saveValueToObject(sample.id, "from", e.target.value)
                                              }
                                              label="From"
                                            >
                                              <MenuItem value={null}>
                                                <em>None</em>
                                              </MenuItem>
                                              {sample.selected_from?sample.selected_from.map((item)=>
                                                  <MenuItem value={item}>{item.classes__class_name}</MenuItem>
                                              ):null}
                                            </MUISelect>
                                          </FormControl>
                                        </div>
                                       </td>

                                       <td width="10%">
                                             <div className="">
                                              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-standard-label">To</InputLabel>
                                                <MUISelect
                                                  labelId="demo-simple-select-standard-label"
                                                  id="demo-simple-select-standard"
                                                  value={sample.class_to?sample.class_to:''}
                                                  onChange={(e) =>
                                                     this.saveValueToObject(sample.id, "to", e.target.value)
                                                  }
                                                  label="To"
                                                >
                                                  <MenuItem value={null}>
                                                    <em>None</em>
                                                  </MenuItem>
                                                  {sample.selected_from?sample.selected_to.map((item)=>
                                                      <MenuItem value={item}>{item.classes__class_name}</MenuItem>
                                                  ):null}
                                                </MUISelect>
                                              </FormControl>
                                             </div>
                                       </td>

                                       <td width="10%">
                                          <div id="div_id_qty" className="form-group">
                                             <div className="">
                                                <input
                                                   type="number"
                                                   id={sample.id}
                                                   value={sample.quantity?parseInt(sample.quantity):''}
                                                    onChange={(e) =>
                                                       this.saveValueToObject(sample.id, "quantity", e.target.value===''?0:e.target.value)
                                                    }
                                                   className="form-control w-100 p-2"
                                                   style={{background: "#ededed", marginTop: '16px'}}
                                                />
                                             </div>
                                          </div>
                                       </td>

                                       <td width="10%">
                                          <div id="div_id_qty" className="form-group">
                                             <div className="">
                                                <input
                                                   type="number"
                                                   id={sample.id}
                                                   value={sample.price?parseInt(sample.price):''}
                                                    onChange={(e) =>
                                                       this.saveValueToObject(sample.id, "price", e.target.value===''?0:e.target.value)
                                                    }
                                                   className="form-control w-100 p-2"
                                                   style={{background: "#ededed", marginTop: '16px'}}
                                                />
                                             </div>
                                          </div>
                                       </td>

                                       <td width="10%" className="text-center">
                                          <div
                                             className="btn btn-xs"
                                             style={{color: "red", fontSize: "15px", marginTop: '16px'}}
                                             onClick={(e) => this.removeSample(sample.id)}
                                          >
                                              X
                                          </div>
                                       </td>
                                    </tr>
                                 </tbody>
                              ))
                            : null}
                      </table>
                                                                                                    </div>
                                                                                                           </div>
                            </div>
                        :null}


                        <div className="row my-4">
                          <div className="col-12">
                            <button
                              className="btn bg-gradient-dark d-block m-auto"
                              onClick={this.submit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    </div>

                    <div
                      className={"d-lg-none col-12"}
                    >
                      <>
                        <div className="card mt-4">
                          <div className="card-body p-3">
                            <div id="basic-info">
                              <div>
                                <div>
                                  <h5 className="font-weight-bolder mb-2">
                                    Basic Info
                                  </h5>
                                </div>
                              </div>
                              <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Employee
                                      </label>
                                      <input
                                        value={
                                          vt.employee.last_name
                                            ? vt.employee.first_name +
                                              " " +
                                              vt.employee.last_name
                                            : vt.employee.first_name
                                        }
                                        className="multisteps-form__input border-bottom form-control pb-0 mt-4"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Serial no.
                                      </label>
                                      <input
                                        value={vt.serial_number}
                                        className="multisteps-form__input form-control border-bottom mt-4"
                                        type="text"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Customer
                                      </label>
                                      <input
                                        value={
                                          this.state.customer +
                                          "(" +
                                          this.state.cust_type +
                                          ")"
                                        }
                                        className="multisteps-form__input form-control border-bottom mt-4"
                                        type="text"
                                      />

                                      <Link
                                        style={{ marginTop: "18px" }}
                                        to={
                                          "/customer/edit-customer/" +
                                          vt.customer.id
                                        }
                                      >
                                        <ModeEditIcon
                                          style={{ color: "#12b312" }}
                                        />
                                      </Link>
                                    </div>
                                  </div>

                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Date</label>
                                      <input
                                        value={vt.date}
                                        className="multisteps-form__input form-control border-bottom mt-4"
                                        type="text"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Station
                                      </label>
                                      <input
                                        value={
                                          vt.station
                                            ? vt.station.station +
                                              "(" +
                                              vt.station.state.state +
                                              ")"
                                            : null
                                        }
                                        className="multisteps-form__input form-control border-bottom mt-4"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card mt-4">
                          <div className="card-body p-3">
                            <div id="basic-info">
                              <div>
                                <div>
                                  <h5 className="font-weight-bolder mb-2">
                                    Contact Person Details
                                  </h5>
                                </div>
                              </div>
                              <div className="card-body pt-0">
                                <div className="row mt-3">
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">Name</label>
                                      <input
                                        defaultValue={
                                          this.state.contact_person_name
                                        }
                                        className="multisteps-form__input form-control border-bottom mt-4"
                                        type="text"
                                        
                                        onChange={(e) =>
                                          this.setState({
                                            contact_person_name: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Phone
                                      </label>
                                      <input
                                        defaultValue={this.state.contact_no}
                                        className="multisteps-form__input form-control border-bottom mt-4"
                                        type="text"
                                        onChange={(e) =>
                                          this.setState({
                                            contact_no: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*  -----------  Working Status ---------- */}

                        <div className="card mt-4">
                          <div className="card-body">
                            <div id="basic-info">
                              <div>
                                <div>
                                  <h5 className="font-weight-bolder mb-1">
                                    Working Status
                                  </h5>
                                </div>
                              </div>
                              <div className="p-1">
                                <div className="row mt-3">
                                  <div className="col-12 input-group input-group-static">
                                    <label className="form-label">
                                      Purpose of visit
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <div className="dropdown mt-5">
                                      <div
                                        className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        id="navbarDropdownMenuLink2"
                                        aria-expanded="false"
                                      >
                                        {/*Purpose of visit*/}
                                        {this.state.purpose
                                          ? this.state.purpose
                                          : "Purpose of visit"}
                                      </div>
                                      <ul
                                        className="dropdown-menu w-50 srkw"
                                        aria-labelledby="navbarDropdownMenuLink2"
                                      >
                                        {this.state.status
                                          ? this.state.status.map(
                                              (st, index) => (
                                                <li>
                                                  <div
                                                    className="dropdown-item"
                                                    onClick={(e) =>
                                                      this.getQuestionnaire(
                                                        st.choice
                                                      )
                                                    }
                                                  >
                                                    {st.choice}
                                                  </div>
                                                </li>
                                              )
                                            )
                                          : null}
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                {this.state.quest ? (
                                  <div className="mt-2 card col-12 w-100 d-flex justify-content-center quest-button">
                                    <div className="pe-3 ps-3">
                                      <div className="arrow-up"></div>

                                      {this.state.quest.map((qst, index) => (
                                        <div className="row justify-content-center mt-5">
                                          <div className="col-md-8">
                                            <p>{qst}</p>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="btn-box">
                                              <button
                                                onClick={(e) =>
                                                  this.handleQuestionnaire(qst)
                                                }
                                                type="submit"
                                                className={
                                                  this.state.my_array.includes(
                                                    qst
                                                  ) === true
                                                    ? "btn btn-confirm btn-primary"
                                                    : "btn btn-confirm btn-color"
                                                }
                                              >
                                                Yes
                                              </button>

                                              <button
                                                onClick={(e) =>
                                                  this.handleQuestionnaire(qst)
                                                }
                                                type="submit"
                                                className={
                                                  this.state.my_array.includes(
                                                    qst
                                                  ) === true
                                                    ? "btn btn-confirm btn-color"
                                                    : "btn btn-confirm btn-primary"
                                                }
                                              >
                                                No
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                      <div className="row justify-content-center">
                                        <div className="col-md-9">
                                          <div className="microphone d-flex flex-column align-items-center">
                                            <div>
                                              <div
                                                className="audio-view"
                                                style={{
                                                  display: "none",
                                                }}
                                              >
                                                <AudioReactRecorder
                                                  canvasWidth={500}
                                                  canvasHeight={100}
                                                  state={this.state.recordState}
                                                  onStop={this.onStop}
                                                />
                                              </div>

                                              {this.state.start === false ? (
                                                <div>
                                                  <div
                                                    className="p-4 position-relative"
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                    onClick={this.start}
                                                  >
                                                    <button
                                                      id="speech"
                                                      className="btn micBtn"
                                                    >
                                                      <i
                                                        className="fa fa-microphone"
                                                        aria-hidden="true"
                                                        style={{
                                                          fontSize: "35px",
                                                          position: "absolute",
                                                          top: "21px",
                                                          right: "27px",
                                                        }}
                                                      ></i>
                                                    </button>
                                                  </div>

                                                  {this.state.mp3 ? (
                                                    <div
                                                      className="audioPlayer col-2 ms-3 d-inline-block"
                                                      style={{
                                                        width: "100%",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: "10px",
                                                      }}
                                                    >
                                                      <audio
                                                        src={this.state.mp3}
                                                        controls
                                                      ></audio>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : (
                                                <div
                                                  className="p-4 position-relative"
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                  onClick={this.stop}
                                                >
                                                  <button
                                                    id="speech"
                                                    className="btn micBtn"
                                                  >
                                                    <i
                                                      className="fa fa-microphone"
                                                      aria-hidden="true"
                                                      style={{
                                                        fontSize: "35px",
                                                        position: "absolute",
                                                        top: "21px",
                                                        right: "27px",
                                                      }}
                                                    ></i>
                                                  </button>
                                                  <div className="pulse-ring"></div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mb-3">
                                        <div className="input-group input-group-static is-filled">
                                          <TextField
                                            id="standard-multiline-flexible"
                                            label="Remark"
                                            multiline
                                            maxRows={4}
                                            defaultValue={
                                              this.state.text_remark
                                                ? this.state.text_remark
                                                : ""
                                            }
                                            className="form-control"
                                            onFocus="focused(this)"
                                            onChange={(e) =>
                                              this.setState({
                                                text_remark: e.target.value,
                                              })
                                            }
                                            onfocusout="defocused(this)"
                                            variant="standard"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card mt-4">

                       <div className="sample-thead mt-2" style={{ padding: "20px 30px" }}>
                          <div className="table-responsive">
                              <table className="table mt-2">

                             <thead>
                                <tr>
                                   <th >S. No.</th>
                                   <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                   <th>From</th>
                                   <th>To</th>
                                   <th>&nbsp;&nbsp;Qty.&nbsp;&nbsp;</th>
                                   <th>&nbsp;&nbsp;Price&nbsp;&nbsp;</th>
                                   <th>Action</th>
                                </tr>
                             </thead>
                              <tbody>
                             {this.state.instance_samples_array
                                ? this.state.instance_samples_array.map((sample, index) => (

                                        <tr>
                                           <td width="7%" className="text-center">
                                              <div><span className="">{index + 1}</span>.</div>
                                           </td>
                                           <td width="43%">
                                              <p>{sample.book[0].title.title}</p>
                                           </td>

                                           <td width="10%">
                                             <p>{sample.book[0].classes.class_name}</p>
                                           </td>

                                           <td width="10%">
                                             <p>{sample.book[sample.book.length-1].classes.class_name}</p>
                                           </td>

                                           <td width="10%">
                                              <p className='text-center'>{sample.quantity}</p>
                                           </td>

                                           <td width="10%">
                                              <p className='text-center'>{sample.price}</p>
                                           </td>
                                           <td className="align-middle text-center">
                                              <div className="d-flex justify-content-center align-items-center">
                                                  <div onClick={(e) => this.delete(sample.id)} >
                                                      <DeleteIcon style={{color: "red", cursor: "pointer"}}/>
                                                  </div>
                                              </div>
                                           </td>
                                        </tr>
                                  ))
                                : null}
                              </tbody>
                          </table>
                          </div>
                       </div>
                    </div>

                        {this.state.purpose==='SAMPLING'?
                            <div className="card mt-4">
                                                                                <div className="sample-thead mt-4" style={{ padding: "20px 30px" }}>
                                                                                                              <div className="">
                                                                                                                 <div
                                                                                                                    className="add-ins float-right d-flex mb-3"
                                                                                                                    style={{ cursor: "pointer" }}
                                                                                                                    onClick={this.addSample}
                                                                                                                 >
                                                                                                                    <img src={require("../../../custom/icons/plus.png")} height="30" />
                                                                                                                    <p className="m-1">ADD NEW SAMPLE</p>
                                                                                                                 </div>
                                                                                                              </div>
                                                                                                  <div className="table-responsive">
                                                                                                      <table className="table mt-2">
                         <thead>
                            <tr>
                               <th >S. No.</th>
                               <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                               <th>From</th>
                               <th>To</th>
                               <th>&nbsp;&nbsp;Qty.&nbsp;&nbsp;</th>
                               <th>&nbsp;&nbsp;Price&nbsp;&nbsp;</th>
                               <th>Action</th>
                            </tr>
                         </thead>
                         {this.state.sample_array
                            ? this.state.sample_array.map((sample, index) => (
                                 <tbody>
                                    <tr>
                                       <td width="7%" className="text-center">
                                          <div style={{marginTop: '16px'}}><span className="">{index + 1}</span>.</div>
                                       </td>
                                       <td width="43%">
                                          {this.state.series?
                                                <Autocomplete
                                                    className='pl-1 pr-1'
                                                    id="free-solo-demo"
                                                    size={'small'}
                                                    style={{marginTop: '16px', width: "100%"}}
                                                    options={this.state.series.map((option) => option.title)}
                                                    onChange={(event, value) =>
                                                        this.saveValueToObject(sample.id, "book", value)
                                                    }
                                                    renderInput={(params) => <TextField {...params} label="Select Book" />}
                                              />
                                          :null}
                                       </td>

                                       <td width="10%">
                                             {/*<div className="">*/}
                                             {/*   <input*/}
                                             {/*      type="number"*/}
                                             {/*      id={sample.id}*/}
                                             {/*      value={sample.from?sample.from:''}*/}
                                             {/*       onChange={(e) =>*/}
                                             {/*          this.saveValueToObject(sample.id, "from", e.target.value)*/}
                                             {/*       }*/}
                                             {/*      className="form-control w-100 p-2"*/}
                                             {/*      style={{background: "#ededed"}}*/}
                                             {/*   />*/}
                                             {/*</div>*/}
                                           <div className="">
                                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">From</InputLabel>
                                            <MUISelect
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              value={sample.class_from?sample.class_from:''}
                                              onChange={(e) =>
                                                 this.saveValueToObject(sample.id, "from", e.target.value)
                                              }
                                              label="From"
                                            >
                                              <MenuItem value={null}>
                                                <em>None</em>
                                              </MenuItem>
                                              {sample.selected_from?sample.selected_from.map((item)=>
                                                  <MenuItem value={item}>{item.classes__class_name}</MenuItem>
                                              ):null}
                                            </MUISelect>
                                          </FormControl>
                                        </div>
                                       </td>

                                       <td width="10%">
                                             <div className="">
                                              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-standard-label">To</InputLabel>
                                                <MUISelect
                                                  labelId="demo-simple-select-standard-label"
                                                  id="demo-simple-select-standard"
                                                  value={sample.class_to?sample.class_to:''}
                                                  onChange={(e) =>
                                                     this.saveValueToObject(sample.id, "to", e.target.value)
                                                  }
                                                  label="To"
                                                >
                                                  <MenuItem value={null}>
                                                    <em>None</em>
                                                  </MenuItem>
                                                  {sample.selected_from?sample.selected_to.map((item)=>
                                                      <MenuItem value={item}>{item.classes__class_name}</MenuItem>
                                                  ):null}
                                                </MUISelect>
                                              </FormControl>
                                             </div>
                                       </td>

                                       <td width="10%">
                                          <div id="div_id_qty" className="form-group">
                                             <div className="">
                                                <input
                                                   type="number"
                                                   id={sample.id}
                                                   value={sample.quantity?parseInt(sample.quantity):''}
                                                    onChange={(e) =>
                                                       this.saveValueToObject(sample.id, "quantity", e.target.value===''?0:e.target.value)
                                                    }
                                                   className="form-control w-100 p-2"
                                                   style={{background: "#ededed", marginTop: '16px'}}
                                                />
                                             </div>
                                          </div>
                                       </td>

                                       <td width="10%">
                                          <div id="div_id_qty" className="form-group">
                                             <div className="">
                                                <input
                                                   type="number"
                                                   id={sample.id}
                                                   value={sample.price?parseInt(sample.price):''}
                                                    onChange={(e) =>
                                                       this.saveValueToObject(sample.id, "price", e.target.value===''?0:e.target.value)
                                                    }
                                                   className="form-control w-100 p-2"
                                                   style={{background: "#ededed", marginTop: '16px'}}
                                                />
                                             </div>
                                          </div>
                                       </td>

                                       <td width="10%" className="text-center">
                                          <div
                                             className="btn btn-xs"
                                             style={{color: "red", fontSize: "15px", marginTop: '16px'}}
                                             onClick={(e) => this.removeSample(sample.id)}
                                          >
                                              X
                                          </div>
                                       </td>
                                    </tr>
                                 </tbody>
                              ))
                            : null}
                      </table>
                                                                                                    </div>
                                                                                                           </div>
                            </div>
                        :null}


                        <div className="row mt-4" style={{marginBottom: '130px'}}>
                          <div className="col-12">
                            <button
                              className="btn bg-gradient-dark d-block m-auto"
                              onClick={this.submit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Loader />
            )}

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={this.state.open}
              onClose={(e) => this.setState({ open: false })}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.open}>
                <Box sx={modalStyle}>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    <div className="modal-body">
                      <div className="circle__box">
                        <div className="circle__wrapper circle__wrapper--right">
                          <div className="circle__whole circle__right"></div>
                        </div>
                        <div className="circle__wrapper circle__wrapper--left">
                          <div className="circle__whole circle__left"></div>
                        </div>
                        <div className="circle__checkmark"></div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <h5>Visit Updated Successfully.</h5>
                        <h6>
                          <Link to="/visit" style={{ color: "grey" }}>
                            Click here{" "}
                          </Link>{" "}
                          for go to visit dashboard.
                        </h6>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </Fade>
            </Modal>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(EditVisit);
