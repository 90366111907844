import React from "react";
import {connect} from "react-redux";
import {axios, url, userLogin, modalStyle} from "../../../store/actions/actions";
import {Link} from "react-router-dom";
import Loader from "../../common/Loader/Loader";
import TextField from "@mui/material/TextField";
import FilterListIcon from "@mui/icons-material/FilterList";
import {Multiselect} from "multiselect-react-dropdown";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";


class customerDataTable extends React.Component {

    state = {
        customer: null,
        quan: null,
        total: null,
        city: null,
        filterCusts: null,
        selected_state: null,
        states: null,

        del_name: null,
        del_endpoint: null,
        showModal: false,

        // sort
        nameSort: null,
        locationSort: null,
        dateSort: null,
        employeeSort: null,

        // filter
        filter: false,
        all_states: null,
        all_stations: null,
        showfilter: false,
        fromDate: null,
        toDate: null,
        stateFilter: null,
        cityFilter: null,
        clear: false,

        open: false,
        delete_customer_id: null,
        is_delete: false,
        index_page: null
    }

    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    componentDidMount() {
        this.loadStates()
        this.loadStations()
        this.loadCustomerPageWithAxios()
        this.toeknExpiryCheck()
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

    loadStates = () => {
        let endpoint = `employee/states`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({all_states: responseData.state})
                console.log({'states response data---------':responseData.state})
            });
    }

    loadStations = () => {
        let endpoint = `employee/stations`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({all_stations: responseData.stations})
                console.log({'states response data---------':responseData.stations})
            });
    }

    loadCustomerPageWithAxios = () => {
        this.setState({open: false})
        let endpoint = `customer/data-table`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                console.log(responseData,"-=-=-=-=-=-=")
                this.setState({customer: responseData.customer})
                this.setState({filterCusts: responseData.customer})
                this.setState({quan: responseData.customer.length})
                this.setState({page: responseData.page})
                this.setState({total: responseData.customer.length})
                this.setState({states: responseData.states})

                console.log({'response data---------':responseData.customer})
            });
    }

    sortCustTable = (data) => {
        let arr = this.state.filterCusts

        if (data==='name') {
            if (this.state.nameSort===null || this.state.nameSort==='lower') {
                this.setState({
                    nameSort: 'upper'
                })
                arr.sort(function (a, b) {
                    if (a.company.toLowerCase() < b.company.toLowerCase())
                        return -1;
                    return 0;
                })
            }

            if (this.state.nameSort==="upper") {
                this.setState({
                    nameSort: 'lower'
                })
                arr.sort(function (a, b) {
                    if (a.company.toLowerCase() > b.company.toLowerCase())
                        return -1;
                    return 0;
                })
            }
            this.setState({
                filterCusts: arr
            })
        }

        if (data==='location') {
            if (this.state.locationSort===null || this.state.locationSort==='lower') {
                this.setState({
                    locationSort: 'upper'
                })
                arr.sort(function (a, b) {
                    let location_a = a.station.toLowerCase()
                    let location_b = b.station.toLowerCase()

                    if (location_a < location_b
                    ) return -1;
                    return 0;
                })
            }

            if (this.state.locationSort==='upper') {
                this.setState({
                    locationSort: 'lower'
                })
                arr.sort(function (a, b) {
                    let location_a = a.station.toLowerCase()
                    let location_b = b.station.toLowerCase()

                    if (location_a > location_b
                    ) return -1;
                    return 0;
                })
            }

            this.setState({
                filterCusts: arr
            })
        }

        if (data==='date') {

            if (this.state.dateSort===null || this.state.dateSort==='lower') {
                this.setState({
                    dateSort: 'upper'
                })
                arr.sort(function (a, b) {
                    let date_a = new Date(a.date.slice(3,5)+'-'+a.date.slice(0,2)+'-'+a.date.slice(6,10))
                    let date_b = new Date(b.date.slice(3,5)+'-'+b.date.slice(0,2)+'-'+b.date.slice(6,10))

                    if (date_a < date_b
                    ) return -1;
                    return 0;
                })
            }

            if (this.state.dateSort==='upper') {
                this.setState({
                    dateSort: 'lower'
                })
                arr.sort(function (a, b) {
                    let date_a = new Date(a.date.slice(3,5)+'-'+a.date.slice(0,2)+'-'+a.date.slice(6,10))
                    let date_b = new Date(b.date.slice(3,5)+'-'+b.date.slice(0,2)+'-'+b.date.slice(6,10))

                    if (date_a > date_b
                    ) return -1;
                    return 0;
                })
            }

            this.setState({
                filterCust: arr
            })
        }

        if (data==='employee') {
            if (this.state.employeeSort===null || this.state.employeeSort==='lower') {
                this.setState({
                    employeeSort: 'upper'
                })
                arr.sort(function (a, b) {
                    let employee_a = a.employee.map((name, index) => (name.toLowerCase()))
                    let employee_b = b.employee.map((name, index) => (name.toLowerCase()))

                    if (employee_a < employee_b
                    ) return -1;
                    return 0;
                })
            }
            if (this.state.employeeSort==='upper') {
                this.setState({
                    employeeSort: 'lower'
                })
                arr.sort(function (a, b) {
                    let employee_a = a.employee.map((name, index) => (name.toLowerCase()))
                    let employee_b = b.employee.map((name, index) => (name.toLowerCase()))

                    if (employee_a > employee_b
                    ) return -1;
                    return 0;
                })
            }

            this.setState({
                filterCusts: arr
            })
        }
    }

    handleSearch = (e) => {
        let text = e.target.value
        let endpoint = `customer/search-customer?search=${text}`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({filterCusts: responseData})
                console.log({'response data filterCusts---------': responseData})
            });
    }

    handlePage = (e, p) => {
        console.log('--------------------',p)
        this.setState({index_page: p===1?0:(p-1)*10})
        const data = {
            from: p===1?0:(p-1)*10,
            to: p===1?10:p*10
        }
        console.log('--------------------',data)
        let endpoint = `customer/pagination`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({filterCusts: responseData.customer})
                console.log({'response data---------': responseData})
            });
    }

    onFilterStateSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList',selectedList)
        console.log('selectedItem',selectedItem)
        this.setState({
            stateFilter: selectedList
        })
    }

    onFilterStateRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList',selectedList)
        console.log('removedItem',removedItem)
        this.setState({
            stateFilter: selectedList
        })
    }

    onFilterCitySelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList',selectedList)
        console.log('selectedItem',selectedItem)
        this.setState({
            cityFilter: selectedList
        })
    }

    onFilterCityRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList',selectedList)
        console.log('removedItem',removedItem)
        this.setState({
            cityFilter: selectedList
        })
    }

    applyFilter = () => {
        this.setState({
            filter: true,
            clear: true
        })
        let states = []
        let city = []

        if (this.state.stateFilter!==null && this.state.stateFilter!==[]) {
            console.log('state filter')
            this.state.stateFilter.map((state, index) => (
                states.push(state.id)
            ))
        }

        if (this.state.cityFilter!==null && this.state.cityFilter!==[]){
            console.log('city filter')
            this.state.cityFilter.map((div, index)=>(
                city.push(div.id)
            ))
        }

        let endpoint = `customer/filter-customer?station_in=${city}&state_in=${states}&from_date=${this.state.fromDate?this.state.fromDate:''}&to_date=${this.state.toDate?this.state.toDate:''}`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({filterCusts: responseData})
                console.log({'>>>>>>>>>>>>>>>>>>>>>>response data---------': responseData})
            });
    }

    clearFilter = () => {
        console.log(this.state.employee)
        this.setState({
            clear: false,
            filterState: null,
            filterDivision: null,
            filterEmployees: this.state.employee,
            fromDate: null,
            toDate: null
        })
    }

    delete = (data) => {
        this.setState({
            open: true,
            delete_customer_id: data
        })
        if (data==='delete') {
            let endpoint = `customer/${this.state.delete_customer_id}`
            let token = this.props.user.token

            axios
                .delete(endpoint, token)
                .then((response) => {
                    const responseData = response.data;
                    this.setState({
                        is_delete: true
                    })
                    console.log({'delete response data---------': responseData})
                });
            }
    }

    printTable = () => {
        console.log('run')
        //Get the HTML of div
        var divElements = document.getElementById('customerTable').innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML =
          "<html><head><title></title></head><body>" +
          divElements + "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;

    }

  render() {
    return (
      <>
        <div className="row my-4 mt-3" id="clients">
              <div className="col-12">
                  <div className="card py-3">
                      {this.state.customer ?
                          <div className="table-responsive px-2">
                              <div
                                  className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                  <div className="dataTable-top">

                                      <h5 className="mb-0 ps-2" >Customers</h5>
                                      <div className=" d-flex justify-content-end align-items-center" style={{position: "relative"}}>
                                          <LocalPrintshopIcon className='cursor-pointer me-3' onClick={this.printTable}/>
                                          <TextField size="small" id="outlined-basic" label="Search..." variant="outlined" onChange={this.handleSearch}/>
                                          <div onClick={(e)=>this.setState({showfilter: this.state.showfilter===true?false:true})}>
                                            <FilterListIcon style={{cursor: "pointer", marginLeft: "15px"}}/>
                                          </div>
                                          {this.state.showfilter===true?
                                            <div className="card p-4" style={{width: "700px", position: "absolute", top: "50px", right: "0", zIndex: "10", boxShadow: "0px 0px 6px #bdbbbb"}}>
                                              <h6>FILTERS</h6>
                                                {this.state.clear?<p onClick={this.clearFilter} style={{color: "#5f5fcb", cursor: "pointer"}}>Clear</p>:null}
                                              <div className="row p-1">
                                                  <div className="col-12 p-1">
                                                      <p>Date range</p>
                                                      <div className="d-flex mt-2 p-1 align-items-center"  >
                                                          <TextField
                                                            id="date"
                                                            label="From"
                                                            type="date"
                                                            size="small"

                                                            onChange={(e) => this.setState({fromDate: e.target.value})}

                                                            sx={{ width: 220 }}
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                          />
                                                          <p className="mx-4 my-0" style={{fontSize: "24px"}}>to</p>

                                                          <TextField
                                                            id="date"
                                                            label="To"
                                                            type="date"
                                                            size="small"

                                                            onChange={(e) => this.setState({toDate: e.target.value})}

                                                            sx={{ width: 220 }}
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="col-6 p-1 mt-2">
                                                      <p>State</p>
                                                      <div className="form-check-inline">
                                                          <div className="">
                                                            {this.state.all_states?
                                                            <Multiselect
                                                                placeholder="Seclect State"
                                                                options={this.state.all_states} // Options to display in the dropdown
                                                                onSelect={this.onFilterStateSelect} // Function will trigger on select event
                                                                onRemove={this.onFilterStateRemove} // Function will trigger on remove event
                                                                displayValue="state" // Property name to display in the dropdown options
                                                                style={{
                                                                    searchBox: {fontSize: "15px"}
                                                                }}
                                                            />
                                                            :null}
                                                          </div>
                                                      </div>
                                                  </div>

                                                  <div className="col-6 p-1 mt-2">
                                                      <p>City</p>
                                                      <div className="form-check-inline">
                                                          <>
                                                            {this.state.all_stations?
                                                            <Multiselect
                                                                placeholder="Select City"
                                                                options={this.state.all_stations} // Options to display in the dropdown
                                                                onSelect={this.onFilterCitySelect} // Function will trigger on select event
                                                                onRemove={this.onFilterCityRemove} // Function will trigger on remove event
                                                                displayValue="station" // Property name to display in the dropdown options
                                                                style={{
                                                                    searchBox: {fontSize: "15px"}
                                                                }}
                                                            />
                                                            :null}
                                                          </>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="d-flex justify-content-end mt-3">
                                                  <button onClick={this.applyFilter} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>
                                              </div>

                                          </div>
                                          :null}
                                      </div>
                                      {/*<div>*/}
                                      {/*</div>*/}
                                  </div>
                                  <div className="dataTable-container" id='customerTable'>
                                      <table className="table align-items-center mb-0 dataTable-table" id="custe">
                                          <thead>
                                          <tr>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "5%", cursor: 'pointer'}}>
                                                  <a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter"
                                                  onClick={(e)=>this.sortCustTable("serial_num")}>S.NO
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "19%", cursor: 'pointer'}}>
                                                  <a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter"
                                                  onClick={(e)=>this.sortCustTable("name")}>Name
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "19%", cursor: 'pointer'}}>
                                                  <a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter"
                                                  onClick={(e)=>this.sortCustTable("type")}>Type
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "13%", cursor: 'pointer'}}><a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter"
                                                  onClick={(e)=>this.sortCustTable("location")}>
                                                  Location</a></th>
                                              <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "12%", cursor: 'pointer'}}><a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter"
                                                  onClick={(e)=>this.sortCustTable("date")}>
                                                  Date</a></th>
                                              <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "9%", cursor: 'pointer'}}><a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter"
                                                  onClick={(e)=>this.sortCustTable("employee")}>
                                                  Employee</a></th>
                                              <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "16%"}}><a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter"
                                              >
                                                  Action</a></th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.filterCusts ? this.state.filterCusts.map((cust, index) => (
                                              index <= this.state.quan ?
                                                  <tr>
                                                      <td>
                                                          <div className="d-flex px-2 py-1">
                                                              <div
                                                                  className="d-flex flex-column justify-content-center">
                                                                  <h6 className="mb-0 font-weight-normal text-sm">{index+1+this.state.index_page}
                                                                  </h6>
                                                              </div>
                                                          </div>
                                                      </td>
                                                      <td>
                                                          <Link to={"/customer/customer-detail/"+cust.id} className="d-flex px-2 py-1">
                                                              <div>
                                                                  <img src={url + "/static/dreambox_logo.jpg"}
                                                                       className="avatar avatar-sm me-3"
                                                                       alt="avatar image"/>
                                                              </div>
                                                              <div
                                                                  className="d-flex flex-column justify-content-center">
                                                                  <h6 className="mb-0 font-weight-normal text-sm">{cust.company}</h6>
                                                              </div>
                                                          </Link>
                                                      </td>
                                                      <td>{cust.type==='SCHOOL'?
                                                          <span className="badge bg-primary">SCHOOL</span>
                                                          :null}
                                                          {cust.type==='SELLER'?
                                                          <span className="badge bg-success">SELLER</span>
                                                          :null}
                                                          {cust.type==='TEACHER'?
                                                          <span className="badge bg-success">TEACHER</span>
                                                          :null}
                                                      </td>
                                                      <td>
                                                          <p className="text-sm font-weight-normal mb-0">{cust.station}</p>
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          <p className="text-sm font-weight-normal mb-0">{cust.date}</p>
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          <p className="text-sm font-weight-normal mb-0">
                                                              {cust.employee?cust.employee.map((emp, index) => (
                                                                  emp + ','
                                                              )):null}
                                                          </p>
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          <div className="d-flex justify-content-center align-items-center">
                                                              <Link to={"/customer/customer-detail/" + cust.id}>
                                                                <RemoveRedEyeIcon style={{color: "grey", margin:"0px 5px"}}/>
                                                              </Link>
                                                              <Link to={"/customer/edit-customer/" + cust.id}>
                                                                  <ModeEditIcon style={{color: "#12b312", margin:"0px 15px"}}/>
                                                              </Link>
                                                              <div className="d-none" onClick={(e) => this.delete(cust.id)} >
                                                                  <DeleteIcon style={{color: "red", cursor: "pointer"}}/>
                                                              </div>
                                                          </div>
                                                      </td>
                                                  </tr>
                                                  : null
                                          )) : null
                                          }
                                          </tbody>
                                      </table>
                                      {this.state.page===1?null:
                                      <div className="datatable-mui-pagination">
                                        <Pagination count={this.state.page} color="secondary" onChange={this.handlePage} size="large"/>
                                      </div>}
                                  </div>
                              </div>
                          </div>
                          :
                          <Loader/>
                      }

                        <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.open}
                        onClose={(e) => this.setState({ open: false })}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.open}>
                            <Box sx={modalStyle}>
                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                    {this.state.is_delete ?
                                        <>
                                            <div className="circle__box">
                                                <div className="circle__wrapper circle__wrapper--right">
                                                    <div className="circle__whole circle__right delete_circle__right"></div>
                                                </div>
                                                <div className="circle__wrapper circle__wrapper--left">
                                                    <div className="circle__whole circle__left delete_circle__left"></div>
                                                </div>
                                                <div className="circle__checkmark delete_circle__checkmark"
                                                >
                                                </div>
                                            </div>

                                            <div style={{textAlign: 'center'}}>
                                                <h5>Customer deleted successfully.</h5>
                                                <h6><div onClick={this.loadCustomerPageWithAxios} style={{color: "grey", cursor: "pointer"}} >Click
                                                    here </div>to go Customer Dashboard</h6>
                                            </div>
                                        </>

                                        :

                                        <div className="modal-body">
                                            <div style={{textAlign: 'center'}}>
                                                <h5>Click the Delete button to delete Customer.</h5>
                                                <button type="button" className="btn btn-danger"
                                                        onClick={(e)=>this.delete('delete')}>Delete
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>
                  </div>
              </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
    user:state.userData.userData
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(customerDataTable);

