import React from "react";
import { connect } from "react-redux";
import {
  axios,
  google_map_api_key,
  modalStyle,
} from "../../../store/actions/actions";
import VisitDataTable from "./VisitDataTable";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Redirect } from "react-router-dom";
import ScheduledVisitDataTable from "./ScheduledVisitDataTable";
import LatLng from "../../GoogleMap/getLatLng";
import AccessAlarmSharpIcon from "@mui/icons-material/AccessAlarmSharp";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Loadingfade from "../../common/Loadingfade";
import IncompletevisitDatatable from "./IncompletevisitDatatable";

class HomePage extends React.Component {
  state = {
    visits: null,
    filterVisits: null,
    scheduledVisits: null,
    filterScheduledVisits: null,

    incompletevisits: null,
    filterincompletevisits: null,

    employees: null,
    filterEmployees: null,
    all_employee: null,
    states: null,
    cities: null,
    samples: null,
    tada: null,
    purpose: null,
    quest: null,

    // filter
    f_employee: [],
    f_state: [],
    f_city: [],
    f_purpose: [],
    f_quest: [],
    fromDate: null,
    toDate: null,

    status: null,

    address: [],
    latLng: null,
    punch_in: null,
    punch_in_address: null,
    punch_out: null,
    punch_out_address: null,

    type: "today",
    open: false,

    userLat: null,
    userLong: null,
    userAddress: null,
  };

  latLng = null;

  getLatLng = (data) => {
    this.latLng = data;
    this.setState({ latLng: data });
    this.getLocation(data);
    this.loadScheduledVisits(data);
  };

  componentDidMount() {
    document.getElementById("rs-btn-box").style.display = "block";
    this.punchInCheck();
    this.loadDataCards();
    // this.loadIncompleteVisit();
    this.loadVisits();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;

          console.log(lat, "lat", lon)

          this.setState({
            userLat: lat,
            userLong: lon,
          });

          let leafapiKey = "9e2f56e07c8141f1a795b4a11dffe23f";
          let reverseGeocodeEndpoint = `https://api.opencagedata.com/geocode/v1/json?q=${this.state.userLat}+${this.state.userLong}&key=${leafapiKey}`;

          console.log(
            "-------------============================================================="
          );

          axios
            .get(reverseGeocodeEndpoint)
            .then((response) => {
              var data = response.data;
              console.log(data, "-=-=-=-=-=-=-=-=");
              if (data.results.length > 0) {
                var address = data.results[0].formatted;
                this.setState({
                  userAddress:address
                })
                console.log(address, "-=-=-=-=-=-=");
              } else {
                alert(`Address not found`);
              }
            })
            .catch((error) => console.error("Error:", error));

          console.log(lat, "*********", lon);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  getLocation = (position) => {
    // let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=32.607872,74.7386713&key=${google_map_api_key}`
    let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${google_map_api_key}`;
    axios.get(endpoint).then((res) => {
      console.log(res, "-=-=-=-=-=-= Google API")
      let responseData = res.data.results[0].address_components;
      let adrs_len = responseData.length;
      for (let step = 0; step < adrs_len; step++) {
        let index = adrs_len - step;
        if (index !== adrs_len) {
          this.state.address.push(responseData[index].long_name);
        }
      }
      if (this.state.address.length <= 1) {
        this.getLocationAgain(position);
      }
    });
  };

  getLocationAgain = (position) => {
    // let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=32.607872,74.7386713&key=${google_map_api_key}`
    let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${google_map_api_key}`;
    axios.get(endpoint).then((res) => {
      let responseData = res.data.results[1].address_components;
      let adrs_len = responseData.length;
      for (let step = 0; step < adrs_len; step++) {
        let index = adrs_len - step;
        if (index !== adrs_len) {
          this.state.address.push(responseData[index].long_name);
        }
      }
      if (this.state.address.length <= 1) {
        this.getLocationAgain2(position);
      }
    });
  };

  getLocationAgain2 = (position) => {
    // let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=32.607872,74.7386713&key=${google_map_api_key}`
    let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${google_map_api_key}`;
    axios.get(endpoint).then((res) => {
      let responseData = res.data.results[2].address_components;
      let adrs_len = responseData.length;
      for (let step = 0; step < adrs_len; step++) {
        let index = adrs_len - step;
        if (index !== adrs_len) {
          this.state.address.push(responseData[index].long_name);
        }
      }
    });
  };

  punchInCheck = () => {
    let endpoint = "employee/punch-in-check";
    let data = {
      username: this.props.user.username,
    };
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log("first", responseData);
      this.setState({
        status: responseData.status,
        punch_in: responseData.punch_in,
        punch_in_address: responseData.punch_in_address,
        punch_out: responseData.punch_out,
        punch_out_address: responseData.punch_out_address,
      });
    });
  };

  punch = () => {
    // if (this.state.address.length>=1) {

    let data = {
      status: this.state.status,
      address: this.state.address,
      lat: this.state.userLat,
      lng: this.state.userLong,
      addresss:this.state.userAddress
    };

    console.log(data, "-=-=-=-=-=-=");

    let endpoint = "employee/punch";
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log(responseData, "Punch Wala Hai");
      this.setState({
        status: responseData.status,
        punch_in: responseData.punch_in,
        punch_in_address: responseData.punch_in_address,
        punch_out: responseData.punch_out,
        punch_out_address: responseData.punch_out_address,
        open: false,
      });
    });
  
  };

  loadDataCards = () => {
    let endpoint = "common/home-data-cards";
    let data = {
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
    };
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      this.setState({
        survey: responseData.survey,
        sampling: responseData.sampling,
        followup: responseData.followup,
        closure: responseData.closure,
      });
    });
  };

  loadVisits = () => {
    this.setState({
      visits: null,
      filterVisits: null,
    });
    let endpoint = "common/home-visits";
    let data = {
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
    };
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;

      const objWithIndex = responseData.visits.map((obj, index) =>
        Object.assign(obj, { index: index + 1 })
      );
      this.setState({
        visits: objWithIndex,
        filterVisits: objWithIndex,
      });
    });
  };

  loadScheduledVisits = (location) => {
    this.setState({
      scheduledVisits: null,
      filterScheduledVisits: null,
    });

    let data = {
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
      lat: this.state.latLng ? this.state.latLng.lat : location.lat,
      lng: this.state.latLng ? this.state.latLng.lng : location.lng,
      userLat : this.state.userLat,
      userLong : this.state.userLong,
    };

    console.log(data, "Data Hai Bhaiyya");

    let endpoint = "common/home-scheduled-visit";
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;

      console.log(responseData, "Schedule visit Homepage Sharukh");

      const objWithIndex = responseData.visits.map((obj, index) =>
        Object.assign(obj, { index: index + 1 })
      );
      this.setState({
        scheduledVisits: objWithIndex,
        filterScheduledVisits: objWithIndex,
      });
    });
  };

  loadIncompleteVisit = () => {
    this.setState({
      incompletevisits: null,
      filterincompletevisits: null,
    });
    let endpoint = "visit/incomp-visit-data-table";
    let data = {
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
    };
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;

      const objWithIndex = responseData.visits.map((obj, index) =>
        Object.assign(obj, { index: index + 1 })
      );

      this.setState({
        incompletevisits: objWithIndex,
        filterincompletevisits: objWithIndex,
      });
    });
  };

  filterByType = (day) => {
    this.setState({
      type: day,
    });

    let fa = setTimeout((e) => this.filterFunc(), 200);
  };

  filterFunc = () => {
    this.loadDataCards();
    this.loadScheduledVisits();
    this.loadVisits();
  };

  handleCallback = (purpose) => {
    let myList = [];
    var vt = this.state.visits;
    vt.map((i, index) =>
      purpose
        ? i.purpose !== null
          ? i.purpose.toLowerCase().includes(purpose.toLowerCase())
            ? myList.push(i)
            : null
          : null
        : null
    );

    this.setState({
      filterVisits: null,
    });

    let fa = setTimeout((e) => this.filterAction2(myList), 500);
  };

  render() {
    return (
      <>
        {this.latLng === null ? <LatLng getLatLng={this.getLatLng} /> : null}
        {this.props.user.login === true ? (
          <>
            <div className="container-fluid homepage-view">
              <div className="row justify-content-end">
                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "d-none d-lg-block col-10"
                      : "d-none d-lg-block col-12"
                  }
                >
                  <div className="">
                    <div className="row">
                      <div className="col-12">
                        {this.state.filterScheduledVisits ? (
                          <ScheduledVisitDataTable
                            visit={this.state.filterScheduledVisits}
                          />
                        ) : (
                          <div
                            className="card py-3"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="table-responsive px-2">
                              <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                <div className="dataTable-top">
                                  <h5 className="mb-0 ps-3">
                                    Scheduled Visits
                                  </h5>
                                </div>
                                <div
                                  className="dataTable-container"
                                  id="employeeTable"
                                >
                                  <Stack spacing={1}>
                                    {/* For variant="text", adjust the height via font-size */}
                                    <Skeleton
                                      variant="text"
                                      sx={{ fontSize: "1rem" }}
                                    />
                                    {/* For other variants, adjust the size with `width` and `height` */}
                                    {/*<Skeleton variant="circular" width={40} height={40} />*/}
                                    <Skeleton
                                      variant="rectangular"
                                      style={{ height: "300px" }}
                                    />
                                    {/*<Skeleton variant="rounded" width={210} height={60} />*/}
                                  </Stack>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Mobile  */}
                <div className="d-lg-none mb-5">
                  <div className="">
                    <div className="row">
                      <div className="col-12 mt-2">
                        <div className="nav-wrapper position-relative end-0">
                          <ul
                            className="nav nav-pills nav-fill p-1"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <div
                                className="nav-link mb-0 px-0 py-1 active "
                                data-bs-toggle="tab"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => this.filterByType("today")}
                                role="tab"
                                aria-selected="true"
                              >
                                Today
                              </div>
                            </li>
                            <li className="nav-item">
                              <div
                                className="nav-link mb-0 px-0 py-1 "
                                data-bs-toggle="tab"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => this.filterByType("yesterday")}
                                role="tab"
                                aria-selected="false"
                              >
                                Yesterday
                              </div>
                            </li>
                            <div
                              className="moving-tab position-absolute nav-link"
                              style={
                                this.state.type === "yesterday"
                                  ? {
                                      padding: "0px",
                                      transition: "all 0.5s ease 0s",
                                      transform: "translate3d(180px, 0px, 0px)",
                                      width: "50%",
                                    }
                                  : {
                                      padding: "0px",
                                      transition: "all 0.5s ease 0s",
                                      transform: "translate3d(0px, 0px, 0px)",
                                      width: "50%",
                                    }
                              }
                            >
                              <div
                                className="nav-link mb-0 px-0 py-1 active "
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected="true"
                              >
                                -
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                      {this.state.type === "today" ? (
                        <div>
                          <div className="row">
                            <div className="col-12 d-none">
                              <h4>Kaise ho mere Bhai</h4>
                            </div>
                            <div className={"col-12 mt-2"}>
                              <div
                                className="f-btn dash-att-btn"
                                style={{ textAlign: "center" }}
                              >
                                <button
                                  onClick={(e) =>
                                    this.state.status === "Punch In"
                                      ? this.punch()
                                      : this.state.status !== "done"
                                      ? this.setState({
                                          open: true,
                                        })
                                      : null
                                  }
                                  style={{
                                    fontSize: "1.2rem",
                                    backgroundImage:
                                      this.state.status === "Punch Out"
                                        ? "linear-gradient(195deg, rgb(89 233 94), rgb(170 255 0))"
                                        : "",
                                  }}
                                >
                                  {this.state.status === "Punch Out"
                                    ? "On Duty"
                                    : this.state.status === "done"
                                    ? "Duty Completed"
                                    : "Off Duty"}
                                </button>
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div
                                className="card p-2"
                                style={{ height: "55px", boxShadow: "none" }}
                              >
                                <div className={"row mt-1"}>
                                  <div className="col-12">
                                    <h6
                                      style={{ lineHeight: "1" }}
                                      className="mb-0"
                                    >
                                      Punch In: &nbsp;
                                    </h6>
                                    <p style={{ fontSize: "12px" }}>
                                      {this.state.punch_in
                                        ? new Date(
                                            this.state.punch_in
                                          ).toLocaleString("en-IN", {
                                            day: "numeric",
                                            month: "short",
                                            year: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          })
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div
                                className="card p-2"
                                style={{ height: "55px", boxShadow: "none" }}
                              >
                                <div className={"row mt-1"}>
                                  <div className="col-12">
                                    <h6
                                      style={{ lineHeight: "1" }}
                                      className="mb-0"
                                    >
                                      Punch Out: &nbsp;
                                    </h6>
                                    <p style={{ fontSize: "12px" }}>
                                      {this.state.punch_out
                                        ? new Date(
                                            this.state.punch_out
                                          ).toLocaleString("en-IN", {
                                            day: "numeric",
                                            month: "short",
                                            year: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          })
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-12">
                        <div className="row flex-nowrap overflow-scroll">
                          <div className="col">
                            <div className="panel-mv d-flex align-items-center gap-3 mt-2">
                              <AccessAlarmSharpIcon />
                              <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                <p
                                  className="my-2"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    lineHeight: "1",
                                  }}
                                >
                                  Survey
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  {this.state.survey != null ? (
                                    <p>
                                      {" "}
                                      &nbsp;&nbsp;&nbsp; {
                                        this.state.survey
                                      }{" "}
                                    </p>
                                  ) : (
                                    <Loadingfade
                                      variant="rectangular"
                                      spacing="1"
                                      height="10px"
                                      width="25px"
                                    />
                                  )}
                                </p>
                              </div>
                              <p
                                style={{ fontSize: "10px", marginBottom: "0" }}
                                className="line-truncate-twoline"
                              ></p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="panel-mv d-flex gap-3 mt-2">
                              <AccessAlarmSharpIcon />
                              <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                <p
                                  className="my-2"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    lineHeight: "1",
                                  }}
                                >
                                  Sampling
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  {this.state.sampling != null ? (
                                    <p>
                                      {" "}
                                      &nbsp;&nbsp;&nbsp;{this.state.sampling}
                                    </p>
                                  ) : (
                                    <Loadingfade
                                      variant="rectangular"
                                      spacing="1"
                                      height="10px"
                                      width="25px"
                                    />
                                  )}
                                </p>
                              </div>
                              <p
                                style={{ fontSize: "10px", marginBottom: "0" }}
                                className="line-truncate-twoline"
                              >
                                {/*<span>+{this.state.new_emp} </span>employee’s added this month*/}
                              </p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="panel-mv d-flex gap-3 mt-2">
                              <AccessAlarmSharpIcon />
                              <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                <p
                                  className="my-2"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    lineHeight: "1",
                                  }}
                                >
                                  Followup
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  {this.state.followup != null ? (
                                    <p>
                                      {" "}
                                      &nbsp;&nbsp;&nbsp;{this.state.sampling}
                                    </p>
                                  ) : (
                                    <Loadingfade
                                      variant="rectangular"
                                      spacing="1"
                                      height="10px"
                                      width="25px"
                                    />
                                  )}
                                </p>
                              </div>
                              <p
                                style={{ fontSize: "10px", marginBottom: "0" }}
                                className="line-truncate-twoline"
                              >
                                {/*<span>+{this.state.new_emp} </span>employee’s added this month*/}
                              </p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="panel-mv d-flex gap-3 mt-2">
                              <AccessAlarmSharpIcon />
                              <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                <p
                                  className="my-2"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    lineHeight: "1",
                                  }}
                                >
                                  Closure
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  {this.state.closure != null ? (
                                    <p>
                                      {" "}
                                      &nbsp;&nbsp;&nbsp;{this.state.closure}
                                    </p>
                                  ) : (
                                    <Loadingfade
                                      variant="rectangular"
                                      spacing="1"
                                      height="10px"
                                      width="25px"
                                    />
                                  )}
                                </p>
                              </div>
                              <p
                                style={{ fontSize: "10px", marginBottom: "0" }}
                                className="line-truncate-twoline"
                              >
                                {/*<span>+{this.state.new_emp} </span>employee’s added this month*/}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Schedule Visit Code  */}
                      <div className="col-12 mt-3">
                        {this.state.filterScheduledVisits ? (
                          <ScheduledVisitDataTable
                            visit={this.state.filterScheduledVisits}
                            loadScheduledVisits={(e) =>
                              this.loadScheduledVisits(this.state.latLng)
                            }
                          />
                        ) : (
                          <div
                            className="card py-3"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="table-responsive px-2">
                              <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                <div className="dataTable-top">
                                  <h5 className="mb-0 ps-3">
                                    Scheduled Visits
                                  </h5>
                                </div>
                                <div
                                  className="dataTable-container"
                                  id="employeeTable"
                                >
                                  <Stack spacing={1}>
                                    {/* For variant="text", adjust the height via font-size */}
                                    <Skeleton
                                      variant="text"
                                      sx={{ fontSize: "1rem" }}
                                    />
                                    {/* For other variants, adjust the size with `width` and `height` */}
                                    {/*<Skeleton variant="circular" width={40} height={40} />*/}
                                    <Skeleton
                                      variant="rectangular"
                                      style={{ height: "300px" }}
                                    />
                                    {/*<Skeleton variant="rounded" width={210} height={60} />*/}
                                  </Stack>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Visit Code  */}
                      <div className="col-12">
                        {this.state.filterVisits ? (
                          <VisitDataTable visit={this.state.filterVisits} />
                        ) : (
                          <div
                            className="card py-3"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="table-responsive px-2">
                              <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                <div className="dataTable-top">
                                  <h5 className="mb-0 ps-3">Visits</h5>
                                </div>
                                <div
                                  className="dataTable-container"
                                  id="employeeTable"
                                >
                                  <Stack spacing={1}>
                                    {/* For variant="text", adjust the height via font-size */}
                                    <Skeleton
                                      variant="text"
                                      sx={{ fontSize: "1rem" }}
                                    />
                                    {/* For other variants, adjust the size with `width` and `height` */}
                                    {/*<Skeleton variant="circular" width={40} height={40} />*/}
                                    <Skeleton
                                      variant="rectangular"
                                      style={{ height: "300px" }}
                                    />
                                    {/*<Skeleton variant="rounded" width={210} height={60} />*/}
                                  </Stack>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Visit Code  */}
                        {/* <div className="d-flex justify-content-center">
                          <button className="btn btn-primary" onClick={this.getLocation()}>
                            Click here to get client address
                          </button>
                        </div> */}
                      <div className="col-12 d-none">
                        {this.state.filterincompletevisits ? (
                          <IncompletevisitDatatable
                            visit={this.state.filterincompletevisits}
                          />
                        ) : (
                          <div
                            className="card py-3"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="table-responsive px-2">
                              <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                <div className="dataTable-top">
                                  <h5 className="mb-0 ps-3">
                                    Incomplete Visits
                                  </h5>
                                </div>
                                <div
                                  className="dataTable-container"
                                  id="employeeTable"
                                >
                                  <Stack spacing={1}>
                                    {/* For variant="text", adjust the height via font-size */}
                                    <Skeleton
                                      variant="text"
                                      sx={{ fontSize: "1rem" }}
                                    />
                                    {/* For other variants, adjust the size with `width` and `height` */}
                                    {/*<Skeleton variant="circular" width={40} height={40} />*/}
                                    <Skeleton
                                      variant="rectangular"
                                      style={{ height: "300px" }}
                                    />
                                    {/*<Skeleton variant="rounded" width={210} height={60} />*/}
                                  </Stack>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={this.state.open}
              onClose={(e) => this.setState({ open: false })}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.open}>
                <Box sx={modalStyle}>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    <div className="modal-body">
                      <div style={{ textAlign: "center" }}>
                        <h5>Are you sure you want to punch out?</h5>

                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ float: "left", background: "#acadb1" }}
                          onClick={(e) => this.setState({ open: false })}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ float: "right" }}
                          onClick={this.punch}
                        >
                          Punch Out
                        </button>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </Fade>
            </Modal>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(HomePage);
