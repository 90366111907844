import React from "react";
import { connect } from "react-redux";
import { axios, sideBar, url, userLogin } from "../../../store/actions/actions";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { Link, Redirect } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Loader from "../../common/Loader/Loader";
import CircleIcon from "@mui/icons-material/Circle";
import CheckIcon from "@mui/icons-material/Check";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";

class TadaView extends React.Component {
  state = {
    visit_item: null,
  };

  componentDidMount() {
    this.loadTadaVisit();
    this.toeknExpiryCheck();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "Update Response data----------": responseData });
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  loadTadaVisit = () => {
    console.log("load...................");
    let pathname = window.location.pathname;

    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];
    const data = {
      visit_id: path,
    };
    let endpoint = "tada/get-tada-visit";
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      this.setState({ visit_item: responseData.visit });
      console.log({ "tada visit item ----------": responseData.visit });
    });
  };

  render() {
    let obj = this.state.visit_item;
    return (
      <>
      <Loader />
        {this.props.user.login === true ? (
          <>
            <div className="container-fluid">
              <div className="row justify-content-end">
                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "col-lg-10"
                      : "col-12"
                  }
                >
                  <div className="col-lg-10 d-block m-auto mt-lg-0 mt-4">
                    {/* <!-- Card Basic Info -->*/}
                    <div className="card mt-4" id="basic-info">
                      <div className="card-body pt-0">
                        <div id="basic-info" className="mt-3">
                          <div>
                            <div>
                              <h5 className="font-weight-bolder mb-2">
                                TA/DA Details
                              </h5>
                            </div>
                          </div>
                          <div className="card-body pt-0">
                            {obj ? (
                              <div className="row mt-3">
                                <div className="col-6 ">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Tada No.
                                    </label>
                                    <input
                                      value={obj.tada.tada_num}
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 ">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Created at
                                    </label>
                                    <input
                                      value={new Date(
                                        obj.time_stamp
                                      ).toLocaleString("en-US", {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                      })}
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 mt-3">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Team Head
                                    </label>
                                    <input
                                      value={
                                        obj.tada.team
                                          ? obj.tada.team.heads[0]
                                            ? obj.tada.team.heads[0].last_name
                                              ? obj.tada.team.heads[0]
                                                  .first_name +
                                                " " +
                                                obj.tada.team.heads[0].last_name
                                              : obj.tada.team.heads[0]
                                                  .first_name
                                            : null
                                          : null
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 mt-3">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Employee
                                    </label>
                                    <input
                                      value={
                                        obj.tada.employee.last_name
                                          ? obj.tada.employee.first_name +
                                            " " +
                                            obj.tada.employee.last_name
                                          : obj.tada.employee.first_name
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 mt-5 mb-3">
                                  <div
                                    className="dataTable-container"
                                    // style={{height: "580.641px"}}
                                  >
                                    <table
                                      className="table align-items-center mb-0 dataTable-table"
                                      id="empe"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                            data-sortable=""
                                            style={{
                                              width: "20%",
                                              cursor: "pointer",
                                            }}
                                            // onClick={(e)=>this.sortEmpTable("name")}
                                          >
                                            <a className="dataTable-sorter">
                                              S.NO
                                            </a>
                                          </th>
                                          <th
                                            className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                            data-sortable=""
                                            style={{
                                              width: "20%",
                                              cursor: "pointer",
                                            }}
                                            // onClick={(e)=>this.sortEmpTable("city")}
                                          >
                                            <a className="dataTable-sorter d-block text-center">
                                              Customer
                                            </a>
                                          </th>
                                          <th
                                            className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                            data-sortable=""
                                            style={{
                                              width: "20%",
                                              cursor: "pointer",
                                            }}
                                            // onClick={(e)=>this.sortEmpTable("status")}
                                          >
                                            <a className="dataTable-sorter">
                                              Date
                                            </a>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {obj.visit
                                          ? obj.visit.map((item, index) => (
                                              <tr>
                                                <td>
                                                  <p className="text-sm text-center font-weight-normal mb-0">
                                                    {index + 1}
                                                  </p>
                                                </td>
                                                <td>
                                                  <p className="text-sm text-center font-weight-normal mb-0">
                                                    {item.customer.company}
                                                  </p>
                                                </td>
                                                <td>
                                                  <p className="text-sm text-center font-weight-normal mb-0">
                                                    {item.date}
                                                  </p>
                                                </td>
                                              </tr>
                                            ))
                                          : null}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="col-6 mt-3">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Approved by
                                    </label>
                                    <input
                                      value={
                                        obj.tada.approved_by
                                          ? obj.tada.approved_by.last_name
                                            ? obj.tada.approved_by.first_name +
                                              " " +
                                              obj.tada.approved_by.last_name
                                            : obj.tada.approved_by.first_name
                                          : null
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 mt-3">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Approved date
                                    </label>
                                    <input
                                      value={obj.tada.approved_date}
                                      className="multisteps-form__input form-control mt-4"
                                      type="date"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 mt-3">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Dispatched by
                                    </label>
                                    <input
                                      value={
                                        obj.tada.dispatched_by
                                          ? obj.tada.dispatched_by.last_name
                                            ? obj.tada.dispatched_by
                                                .first_name +
                                              " " +
                                              obj.tada.dispatched_by.last_name
                                            : obj.tada.dispatched_by.first_name
                                          : null
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 mt-3">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Dispatched date
                                    </label>
                                    <input
                                      value={
                                        obj.tada.dispatched_date
                                          ? obj.tada.dispatched_date
                                          : null
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="date"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3 mt-5">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      DA1( da local outstation )
                                    </label>
                                    <input
                                      value={obj.da1_amount}
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3 mt-5">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      DA2( fare in case of outstation )
                                    </label>
                                    <input
                                      value={obj.da2_amount}
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3 mt-5">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      DA3( transport courier expenses )
                                    </label>
                                    <input
                                      value={obj.da3_amount}
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3 mt-5">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Total Amount
                                    </label>
                                    <input
                                      value={
                                        parseInt(obj.da1_amount) +
                                        parseInt(obj.da2_amount) +
                                        parseInt(obj.da3_amount)
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 row mt-4">
                                  <h6 className="">Images:</h6>
                                  {obj.images
                                    ? obj.images.map((img, index) => (
                                        <div className="col-3 image-area mt-4 mx-2">
                                          <img
                                            src={url + img.images}
                                            alt="Preview"
                                          />
                                        </div>
                                      ))
                                    : null}
                                </div>
                                <div className="col-12 mt-5">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">Remark</label>
                                    <input
                                      value={obj.remark}
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <Loader />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TadaView);
