import React from "react";
import { connect } from "react-redux";
import {
  axios,
  url,
  userLogin,
  modalStyle,
} from "../../../store/actions/actions";
import { states } from "../../common/objects";
import { Link, Redirect } from "react-router-dom";
import "../../../custom/css/srkcutom.css";
import Loader from "../../common/Loader/Loader";
import Select from "react-select";
import "../../common/common.css";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class EditEmployee extends React.Component {
  state = {
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    dob: null,
    gender: null,

    house_num: null,
    area: null,
    street: null,
    city: null,
    pin_code: null,
    state: null,

    image: null,
    photo: null,

    current_pass: null,
    pass1: null,
    pass2: null,

    employee: null,

    open: false,
    error: false,
  };

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  componentDidMount() {
    this.loadEmployee();
  }

  loadEmployee = () => {
    let pathname = window.location.pathname;
    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];
    let endpoint = `employee/${path}`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((res) => {
      let responseData = res.data;
      this.setState({
        employee: responseData.employee,
        first_name: responseData.employee.first_name,
        last_name: responseData.employee.last_name,
        email: responseData.employee.email,
        phone: responseData.employee.phone,
        dob: responseData.employee.dob,
        gender: responseData.employee.gender,
        image: responseData.employee.pic
          ? url + responseData.employee.pic
          : null,
        area: responseData.employee.address[0]
          ? responseData.employee.address[0].area
          : null,
        street: responseData.employee.address[0]
          ? responseData.employee.address[0].street
          : null,
        pin_code: responseData.employee.address[0]
          ? responseData.employee.address[0].pin_code
          : null,
        house_num: responseData.employee.address[0]
          ? responseData.employee.address[0].house_num
          : null,
        city: responseData.employee.address[0]
          ? responseData.employee.address[0].distt
          : null,
        state: responseData.employee.address[0]
          ? responseData.employee.address[0].state
          : null,
      });
      console.log({ "Emp response data---------": responseData.employee });
    });
  };

  submit = () => {
    let  datas = {
      emp_id: this.props.user.employee_id,
      fname: this.state.first_name,
      lname: this.state.last_name,
      email: this.state.email,
      phone: this.state.phone,
      gender: this.state.gender,
      dob: this.state.dob,

      house_num: this.state.house_num,
      pin_code: this.state.pin_code,
      street: this.state.street,
      area: this.state.area,
      city: this.state.city,
      state: this.state.state,

      update: false,
    };

    console.log(datas, "Sharukh Khan");

    let endpoint = `employee/`;

    let token = this.props.user.tokens;
    axios.put("employee/", datas, token).then((res) => {
      console.log("Sharukh CLG");

      let responseData = res.data;
      if (responseData.success === false) {
        this.setState({
          error: true,
        });
      }
      if (responseData.success === true) {
        this.setState({
          open: true,
        });
        this.submitImage();
        // this.props.userLoginHandler({
        //     employee_full_name: this.state.first_name+' '+this.state.last_name,
        //     employee_first_name: this.state.first_name,
        //     employee_last_name: this.state.last_name,
        //     login: true,
        //     expiry_date: this.props.user.expiry_date,
        //     username: this.props.user.username,
        //     employee_id: this.props.user.employee_id,
        //     employee_profile: this.props.user.profile,
        //     token: this.props.user.token
        // })
      }
      console.log({ "response data---------": responseData });
    });
  };

  submitImage = () => {
    let data = new FormData();
    data.append("id", this.props.user.employee_id);
    data.append("image", this.state.photo);

    console.log(data);

    let endpoint = `employee/upload-profile-pic`;
    let token = this.props.user.token;
    axios.put(endpoint, data, token).then((res) => {
      let responseData = res.data;
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
      if (responseData.success === true) {
        console.log("image uploaded successfully");
      }

      console.log({ "response data---------": responseData });
    });
  };

  updatePassword = () => {
    const data = {
      username: this.props.user.username,
      current_pass: this.state.current_pass,
      pass1: this.state.pass1,
      pass2: this.state.pass2,
    };

    console.log(data);

    let endpoint = `employee/update-password`;
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      if (responseData.success === true) {
        this.setState({
          open: true,
        });
      }
      console.log({ "response data---------": responseData });
    });
  };

  handleFileSelected = (e) => {
    const files = e.target.files[0];
    this.setState({ photo: files });
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        // photo: files,
        image: reader.result,
      });
    };
    reader.readAsDataURL(files);
  };

  render() {
    console.log(this.state.image);
    console.log(this.state.image);
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="container-fluid">
              <div className="row justify-content-end">
                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "d-none d-lg-block col-md-10"
                      : "d-none d-lg-block col-12"
                  }
                >
                  {this.state.employee ? (
                    <div className="col-md-10 d-block m-auto mt-lg-0 mt-4">
                      {/*{this.state.employee?this.state.employee.map((emp, index)=>(*/}
                      <>
                        {/*// <!-- Card Profile -->*/}
                        <div
                          className="card card-body edit-profile"
                          id="profile"
                        >
                          <div className="row justify-content-center align-items-center">
                            <div className="col-sm-auto col-4">
                              <div className="avatar avatar-xl position-relative">
                                <label htmlFor="imgupload">
                                  <img
                                    src={
                                      this.state.image
                                        ? this.state.image
                                        : require("../../../custom/img/agent.png")
                                    }
                                    style={{ height: "80px", width: "80px" }}
                                    alt="bruce"
                                    className="upload-button rounded-circle shadow-sm"
                                  />
                                </label>
                                <input
                                  id="imgupload"
                                  className="file-upload"
                                  type="file"
                                  accept="image/*"
                                  onChange={this.handleFileSelected}
                                  style={{
                                    display: "none",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-auto col-8 my-auto">
                              <div className="h-100">
                                <h5 className="mb-1 font-weight-bolder">
                                  {this.state.employee.last_name
                                    ? this.state.employee.first_name +
                                      " " +
                                      this.state.employee.last_name +
                                      "(" +
                                      this.state.employee.user.username +
                                      ")"
                                    : this.state.employee.first_name}
                                </h5>
                                <p className="mb-0 font-weight-normal text-sm">
                                  {this.state.employee.is_admin === true
                                    ? "Admin/ "
                                    : null}
                                  {this.state.employee.is_manager === true
                                    ? "Manager/ "
                                    : null}
                                  {this.state.employee.is_executive === true
                                    ? "Executive/ "
                                    : null}
                                  {this.state.employee.is_coordinator === true
                                    ? "Coordinator/ "
                                    : null}
                                  {this.state.employee.division.map(
                                    (div, index) => div.name
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 justify-content-md-end  justify-content-center d-flex"></div>
                          </div>
                        </div>

                        {/*// <!-- Card Basic Info -->*/}
                        <div className="card mt-4" id="basic-info">
                          <div className="card-header">
                            <h5>Basic Info</h5>
                          </div>
                          <div className="card-body pt-0">
                            <div className="row">
                              <div className="col-6">
                                <div className="input-group input-group-static">
                                  <label>First Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      this.state.employee.first_name
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        first_name: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="input-group input-group-static">
                                  <label>Last Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.state.employee.last_name}
                                    onChange={(e) =>
                                      this.setState({
                                        last_name: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-12 input-group input-group-static">
                                    <label className="form-label">Gender</label>
                                  </div>
                                  <div className="col-12">
                                    <div className="dropdown mt-5">
                                      <div
                                        className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        id="navbarDropdownMenuLink2"
                                        aria-expanded="false"
                                      >
                                        {this.state.gender
                                          ? this.state.gender
                                          : "Select Gender"}
                                      </div>
                                      <ul
                                        className="dropdown-menu w-50 srkw"
                                        aria-labelledby="navbarDropdownMenuLink2"
                                      >
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            onClick={(e) =>
                                              this.setState({ gender: "MALE" })
                                            }
                                          >
                                            Male
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            onClick={(e) =>
                                              this.setState({
                                                gender: "FEMALE",
                                              })
                                            }
                                          >
                                            Female
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="input-group input-group-static is-filled">
                                  <label>D.O.B</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    onFocus="focused(this)"
                                    defaultValue={this.state.employee.dob}
                                    onChange={(e) =>
                                      this.setState({ dob: e.target.value })
                                    }
                                    onfocusout="defocused(this)"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-6 mt-4">
                              <div className="col-12 input-group input-group-static">
                                <label className="form-label">Address:</label>
                              </div>
                              <button
                                className="w-50 btn srkw bg-gradient-dark dropdown-toggle mt-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                              >
                                Current Address
                              </button>
                            </div>
                            {this.state.employee.address.map((adrs, index) => (
                              <div className="collapse" id="collapseExample">
                                <div className="col-12 card card-body">
                                  <div className="row mt-4">
                                    <div className="col-6">
                                      <div className="input-group input-group-static is-filled">
                                        <label>House no:</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="house_num"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.house_num}
                                          onChange={(e) =>
                                            this.setState({
                                              house_num: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="input-group input-group-static is-filled">
                                        <label>Street:</label>
                                        <input
                                          type="tel"
                                          className="form-control"
                                          id="street"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.street}
                                          onChange={(e) =>
                                            this.setState({
                                              street: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <div className="input-group input-group-static is-filled">
                                        <label>Area:</label>
                                        <input
                                          type="tel"
                                          className="form-control"
                                          id="area"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.area}
                                          onChange={(e) =>
                                            this.setState({
                                              area: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6 mt-3">
                                      <div className="input-group input-group-static is-filled">
                                        <label>City:</label>
                                        <input
                                          type="tel"
                                          className="form-control"
                                          id="city"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.distt}
                                          onChange={(e) =>
                                            this.setState({
                                              city: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6 mt-3">
                                      <div className="input-group input-group-static is-filled">
                                        <label>Pin code:</label>
                                        <input
                                          type="tel"
                                          className="form-control"
                                          id="pin_code"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.pin_code}
                                          onChange={(e) =>
                                            this.setState({
                                              pin_code: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6 mt-3">
                                      <div className="input-group input-group-static is-filled">
                                        <label>State:</label>
                                        <Select
                                          placeholder="Select State"
                                          value={
                                            this.state.state
                                              ? {
                                                  value: this.state.state,
                                                  label: this.state.state,
                                                }
                                              : null
                                          }
                                          options={states.map((st, index) => ({
                                            value: st.state,
                                            label: st.state,
                                          }))}
                                          onChange={(e) =>
                                            this.setState({ state: e.value })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="row mt-4">
                              <div className="col-6">
                                <div className="input-group input-group-static">
                                  <label>Email</label>
                                  <input
                                    type="email"
                                    className="form-control lower-input"
                                    defaultValue={this.state.employee.email}
                                    onChange={(e) =>
                                      this.setState({ email: e.target.value })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="input-group input-group-static">
                                  <label>Phone Number</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.state.employee.phone}
                                    onChange={(e) =>
                                      this.setState({ phone: e.target.value })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row my-4">
                              <div className="col-12">
                                <button
                                  className="btn bg-gradient-dark d-block m-auto"
                                  onClick={this.submit}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*// <!-- Card Change Password -->*/}
                        <div className="card mt-4" id="password">
                          <div className="card-header">
                            <h5>Change Password</h5>
                          </div>
                          <div className="card-body pt-0">
                            <div className="input-group input-group-outline">
                              {/*<label className="form-label">Current Password</label>*/}
                              <input
                                type="password"
                                placeholder="Current Password"
                                className="form-control lower-input"
                                onChange={(e) =>
                                  this.setState({
                                    current_pass: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="input-group input-group-outline my-4">
                              {/*<label className="form-label">New Password</label>*/}
                              <input
                                type="password"
                                placeholder="New Password"
                                className="form-control lower-input"
                                onChange={(e) =>
                                  this.setState({ pass1: e.target.value })
                                }
                              />
                            </div>
                            <div className="input-group input-group-outline">
                              {/*<label className="form-label">Confirm New Password</label>*/}
                              <input
                                type="password"
                                placeholder="Confirm New Password"
                                className="form-control lower-input"
                                onChange={(e) =>
                                  this.setState({ pass2: e.target.value })
                                }
                              />
                            </div>
                            <h5 className="mt-5">Password requirements</h5>
                            <p className="text-muted mb-2">
                              Please follow this guide for a strong password:
                            </p>
                            <ul className="text-muted ps-4 mb-0 float-start">
                              <li>
                                <span className="text-sm">
                                  One special characters
                                </span>
                              </li>
                              <li>
                                <span className="text-sm">
                                  Min 6 characters
                                </span>
                              </li>
                              <li>
                                <span className="text-sm">
                                  One number (2 are recommended)
                                </span>
                              </li>
                              <li>
                                <span className="text-sm">Change it often</span>
                              </li>
                            </ul>
                            <button
                              className="btn bg-gradient-dark btn-sm float-end mt-6 mb-0"
                              onClick={this.updatePassword}
                            >
                              Update password
                            </button>
                          </div>
                        </div>
                      </>
                      {/*)):null}*/}
                    </div>
                  ) : (
                    <Loader />
                  )}

                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open}
                    onClose={(e) => this.setState({ open: false })}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={this.state.open}>
                      <Box sx={modalStyle}>
                        {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                        {/*  Text in a modal*/}
                        {/*</Typography>*/}
                        <Typography
                          id="transition-modal-description"
                          sx={{ mt: 2 }}
                        >
                          <div className="modal-body">
                            <div className="circle__box">
                              <div className="circle__wrapper circle__wrapper--right">
                                <div className="circle__whole circle__right"></div>
                              </div>
                              <div className="circle__wrapper circle__wrapper--left">
                                <div className="circle__whole circle__left"></div>
                              </div>
                              <div className="circle__checkmark"></div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                              <h5>Employee Updated Successfully.</h5>
                              <h6>
                                <Link to="/" style={{ color: "grey" }}>
                                  Click here{" "}
                                </Link>{" "}
                                for go to employee dashboard.
                              </h6>
                            </div>
                          </div>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>

                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.error}
                    onClose={(e) => this.setState({ error: false })}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={this.state.error}>
                      <Box sx={modalStyle}>
                        <Typography
                          id="transition-modal-description"
                          sx={{ mt: 2 }}
                        >
                          <div className="modal-body">
                            <div style={{ textAlign: "center" }}>
                              <h5>
                                Sorry, the email address is already taken.
                              </h5>
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{
                                  float: "right",
                                  background: "#acadb1",
                                }}
                                onClick={(e) => this.setState({ error: false })}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="d-lg-none">
              <div className="dashOption-mv mt-4 px-2">
                <div className="">
                  <div className="button-bar-mv d-flex">
                    <Link to="/employee" className="btn btn-mv ">
                      <img
                        src={require("../../../kavi/icons/dashbord.png")}
                        alt="btn-icon"
                      />
                      Dashboard
                    </Link>
                    <Link
                      to={
                        "/employee/edit-employee/" + this.props.user.employee_id
                      }
                      className="btn btn-mv active"
                    >
                      <img
                        src={require("../../../kavi/icons/edit.png")}
                        alt="btn-icon"
                      />
                      Edit
                    </Link>
                    {this.props.user.employee_profile === "admin" ? (
                      <Link
                        to="/employee/create-employee"
                        className="btn btn-mv"
                      >
                        <img
                          src={require("../../../kavi/icons/add.png")}
                          alt="btn-icon"
                        />
                        Create
                      </Link>
                    ) : null}
                    {/* <Link to="/create-station" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create Station</Link> */}
                    {this.props.user.employee_profile === "admin" ||
                    this.props.user.employee_profile === "accountant" ? (
                      <Link
                        to="/employee/employee-transactions"
                        className="btn btn-mv"
                      >
                        <img
                          src={require("../../../kavi/icons/add.png")}
                          alt="btn-icon"
                        />
                        Transactions
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
              {this.state.employee ? (
                <div className=" px-2 d-block mt-lg-0 mt-4">
                  <>
                    {/*// <!-- Card Profile -->*/}
                    <div className="card card-body edit-profile" id="profile">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-sm-auto col-4">
                          <div className="avatar avatar-xl position-relative">
                            <label htmlFor="imgupload">
                              <img
                                src={
                                  this.state.image
                                    ? this.state.image
                                    : require("../../../custom/img/agent.png")
                                }
                                style={{ height: "80px", width: "80px" }}
                                alt="bruce"
                                className="upload-button w-100 rounded-circle shadow-sm"
                              />
                            </label>
                            <input
                              id="imgupload"
                              className="file-upload"
                              type="file"
                              accept="image/*"
                              onChange={this.handleFileSelected}
                              style={{
                                display: "none",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-auto col-8 my-auto">
                          <div className="h-100">
                            <h5 className="mb-1 font-weight-bolder">
                              {this.state.employee.last_name
                                ? this.state.employee.first_name +
                                  " " +
                                  this.state.employee.last_name +
                                  "(" +
                                  this.state.employee.user.username +
                                  ")"
                                : this.state.employee.first_name}
                            </h5>
                            <p className="mb-0 font-weight-normal text-sm">
                              {this.state.employee.is_admin === true
                                ? "Admin/ "
                                : null}
                              {this.state.employee.is_manager === true
                                ? "Manager/ "
                                : null}
                              {this.state.employee.is_executive === true
                                ? "Executive/ "
                                : null}
                              {this.state.employee.division.map(
                                (div, index) => div.name
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 justify-content-md-end d-none justify-content-center d-flex">
                          {/*<label className="form-check-label mb-0">*/}
                          {/*    <small id="profileVisibility"> Switch to invisible </small>*/}
                          {/*</label>*/}
                          <div className="form-check form-switch ms-2 my-auto">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault23"
                              checked=""
                              onChange="visible()"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*// <!-- Card Basic Info -->*/}
                    <div className="card mt-4" id="basic-info">
                      <div className="card-header pb-2">
                        <h5>Basic Information</h5>
                      </div>
                      <div className="card-body pt-0">
                        <div className="row">
                          <div className="col-12 mt-3">
                            <div className="input-group input-group-static">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control form-control-mv"
                                defaultValue={this.state.employee.first_name}
                                onChange={(e) =>
                                  this.setState({ first_name: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            <div className="input-group input-group-static">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control form-control-mv"
                                defaultValue={this.state.employee.last_name}
                                onChange={(e) =>
                                  this.setState({ last_name: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <label className="form-label">Gender</label>
                            <div className="dropdown">
                              <div
                                className="m-0 btn w-100 srkw bg-gradient-dark dropdown-toggle"
                                data-bs-toggle="dropdown"
                                id="navbarDropdownMenuLink2"
                                aria-expanded="false"
                              >
                                {this.state.gender
                                  ? this.state.gender
                                  : "Select Gender"}
                              </div>
                              <ul
                                className="dropdown-menu w-100 srkw"
                                aria-labelledby="navbarDropdownMenuLink2"
                              >
                                <li>
                                  <div
                                    className="dropdown-item"
                                    onClick={(e) =>
                                      this.setState({ gender: "MALE" })
                                    }
                                  >
                                    Male
                                  </div>
                                </li>
                                <li>
                                  <div
                                    className="dropdown-item"
                                    onClick={(e) =>
                                      this.setState({ gender: "FEMALE" })
                                    }
                                  >
                                    Female
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <label>D.O.B</label>
                            <div className="input-group input-group-static is-filled">
                              <input
                                type="date"
                                className="form-control form-control-mv"
                                onFocus="focused(this)"
                                defaultValue={this.state.employee.dob}
                                onChange={(e) =>
                                  this.setState({ dob: e.target.value })
                                }
                                onfocusout="defocused(this)"
                              />
                            </div>
                          </div>
                          <div className="col-12 mt-3 d-flex align-items-start flex-column">
                            <label className="form-label">Address:</label>
                            <button
                              className="w-100 btn srkw bg-gradient-dark dropdown-toggle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Current Address
                            </button>
                            {this.state.employee.address.map((adrs, index) => (
                              <div className="collapse" id="collapseExample">
                                <div className="col-12 p-0 card-body">
                                  <div className="row mt-4">
                                    <div className="col-6">
                                      <div className="input-group input-group-static is-filled">
                                        <label>House no:</label>
                                        <input
                                          type="text"
                                          className="form-control form-control-mv"
                                          id="house_num"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.house_num}
                                          onChange={(e) =>
                                            this.setState({
                                              house_num: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="input-group input-group-static is-filled">
                                        <label>Street:</label>
                                        <input
                                          type="tel"
                                          className="form-control form-control-mv"
                                          id="street"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.street}
                                          onChange={(e) =>
                                            this.setState({
                                              street: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <div className="input-group input-group-static is-filled">
                                        <label>Area:</label>
                                        <input
                                          type="tel"
                                          className="form-control form-control-mv"
                                          id="area"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.area}
                                          onChange={(e) =>
                                            this.setState({
                                              area: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6 mt-3">
                                      <div className="input-group input-group-static is-filled">
                                        <label>City:</label>
                                        <input
                                          type="tel"
                                          className="form-control form-control-mv"
                                          id="city"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.distt}
                                          onChange={(e) =>
                                            this.setState({
                                              city: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6 mt-3">
                                      <div className="input-group input-group-static is-filled">
                                        <label>Pin code:</label>
                                        <input
                                          type="tel"
                                          className="form-control form-control-mv"
                                          id="pin_code"
                                          onFocus="focused(this)"
                                          defaultValue={adrs.pin_code}
                                          onChange={(e) =>
                                            this.setState({
                                              pin_code: e.target.value,
                                            })
                                          }
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <div className="input-group input-group-static is-filled w-100">
                                        <label>State:</label>
                                        <Select
                                          placeholder="Select State"
                                          value={
                                            this.state.state
                                              ? {
                                                  value: this.state.state,
                                                  label: this.state.state,
                                                }
                                              : null
                                          }
                                          options={states.map((st, index) => ({
                                            value: st.state,
                                            label: st.state,
                                          }))}
                                          onChange={(e) =>
                                            this.setState({ state: e.value })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="col-12 mt-3">
                            <label>Email</label>
                            <div className="input-group input-group-static">
                              <input
                                type="email"
                                className="form-control form-control-mv lower-input"
                                defaultValue={this.state.employee.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <label>Phone Number</label>
                            <div className="input-group input-group-static">
                              <input
                                type="text"
                                className="form-control form-control-mv"
                                defaultValue={this.state.employee.phone}
                                onChange={(e) =>
                                  this.setState({ phone: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 mt-3 ">
                            <button
                              className="btn btn-mv bg-gradient-dark d-block ms-auto"
                              onClick={this.submit}
                            >
                              Submitsss
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*// <!-- Card Change Password -->*/}
                    <div className="card mt-4 mb-5" id="password">
                      <div className="card-header">
                        <h5>Change Password</h5>
                      </div>
                      <div className="card-body pt-0">
                        <div className="input-group input-group-outline">
                          {/*<label className="form-label">Current Password</label>*/}
                          <input
                            type="password"
                            placeholder="Current Password"
                            className="form-control lower-input"
                            onChange={(e) =>
                              this.setState({ current_pass: e.target.value })
                            }
                          />
                        </div>
                        <div className="input-group input-group-outline my-4">
                          {/*<label className="form-label">New Password</label>*/}
                          <input
                            type="password"
                            placeholder="New Password"
                            className="form-control lower-input"
                            onChange={(e) =>
                              this.setState({ pass1: e.target.value })
                            }
                          />
                        </div>
                        <div className="input-group input-group-outline">
                          {/*<label className="form-label">Confirm New Password</label>*/}
                          <input
                            type="password"
                            placeholder="Confirm New Password"
                            className="form-control lower-input"
                            onChange={(e) =>
                              this.setState({ pass2: e.target.value })
                            }
                          />
                        </div>
                        <h5 className="mt-5">Password requirements</h5>
                        <p className="text-muted mb-2">
                          Please follow this guide for a strong password:
                        </p>
                        <ul className="text-muted ps-4 mb-0 float-start">
                          <li>
                            <span className="text-sm">
                              One special characters
                            </span>
                          </li>
                          <li>
                            <span className="text-sm">Min 6 characters</span>
                          </li>
                          <li>
                            <span className="text-sm">
                              One number (2 are recommended)
                            </span>
                          </li>
                          <li>
                            <span className="text-sm">Change it often</span>
                          </li>
                        </ul>
                        <button
                          className="btn btn-mv bg-gradient-dark btn-sm float-end mt-6 mb-5 p-2"
                          onClick={this.updatePassword}
                        >
                          Update password
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployee);
