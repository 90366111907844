import React from "react";
import { connect } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import '../../../custom/css/srkcutom.css'
import { axios, modalStyle } from "../../../store/actions/actions";
import Select from "react-select";
import { Multiselect } from "multiselect-react-dropdown";
import GoogleMap from "../../GoogleMap/GoogleMap";
import { stations, all_divisions, states } from "../../common/objects";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class EditEmployee extends React.Component {

    state = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        dob: null,
        gender: null,

        profile: null,

        house_num: null,
        area: null,
        pin_code: null,
        street: null,
        city: null,
        state: null,

        image: null,
        photo: null,

        pass1: null,
        pass2: null,

        open: null,

        all_stations: null,

        selected_division: null,
        selected_division_list: null,

        selected_city: null,
        selected_city_list: null,

        selected_state: null,
        selected_state_list: null,

        lat: null,
        long: null,
        map_address: null,
        area_state: null,
        area_city: null,

        exceptionState: null
    }

    componentDidMount() {
        this.loadStations()
    }

    schema = () => {
        var domain = window.location.hostname;
        if (domain.length===9){
            return 'public'
        } else {
            var domain_name = domain.slice(0,-10)
            return domain_name
        }
    }

    loadStations = () => {
        let endpoint = `employee/stations`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                this.setState({
                    all_stations: responseData.stations
                })

                console.log({ 'Stations response data---------': responseData.stations })
            })
    }

    checkStateStation = (state) => {
        const data = {
            state: state
        }
        let endpoint = `employee/check-state-station`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                if (responseData.result.length !== 0) {
                    this.setState({
                        exceptionState: responseData.result
                    })
                } else {
                    this.setState({
                        exceptionState: null
                    })
                }
                console.log({ 'State response result--------': responseData.result })
            })
    }

    onDivisionSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        this.setState({
            selected_division: selectedItem,
            selected_division_list: selectedList
        })
    }

    onDivisionRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        this.setState({
            selected_division_list: selectedList
        })
    }

    onAssignCitySelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        this.setState({
            selected_city: selectedItem,
            selected_city_list: selectedList
        })
    }

    onAssignCityRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        this.setState({
            selected_city_list: selectedList
        })
    }

    onAssignStateSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        console.log('length of selectedItem', selectedList.length)
        this.checkStateStation(selectedList)
        this.setState({
            selected_state: selectedItem,
            selected_state_list: selectedList
        })
    }

    onAssignStateRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        this.checkStateStation(selectedList)
        this.setState({
            selected_state_list: selectedList
        })
    }

    submit = () => {
        const data = {
            fname: this.state.first_name,
            lname: this.state.last_name,
            email: this.state.email,
            phone: this.state.phone,
            division: this.state.selected_division_list,
            gender: this.state.gender,
            schema: this.schema(),

            // station
            assign_state: this.state.selected_state_list,
            assign_city: this.state.selected_city_list,
            assign_area: this.state.map_address ? [
                {
                    map_address: this.state.map_address,
                    lat: this.state.lat,
                    long: this.state.long,
                    state: this.state.area_state,
                    city: this.state.area_city
                }
            ] : null,

            profile: this.state.profile,

            dob: this.state.dob,

            house_num: this.state.house_num,
            street: this.state.street,
            pin_code: this.state.pin_code,
            area: this.state.area,
            city: this.state.city,
            state: this.state.state,

            pass1: this.state.pass1,
            pass2: this.state.pass2,
        }

        console.log(data)

        let endpoint = `employee/`
        let token = this.props.user.token
        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                if(responseData.success===true){
                    this.setState({
                        open: true
                    })
                }
                console.log({ 'response data---------': responseData })
            })
    }

    // updatePassword = () => {
    //     const data = {
    //         pass1: this.state.pass1,
    //         pass2: this.state.pass2,
    //     }
    //
    //     console.log(data)
    //
    //     let endpoint = `employee/update-pass`
    //     axios
    //         .post(endpoint, data)
    //         .then((res)=>{
    //             let responseData = res.data;
    //             if(responseData.success===true){
    //                 this.setState({
    //                     showModal: true
    //                 })
    //             }
    //             console.log({'response data---------':responseData})
    //         })
    // }

    getAddress = (id) => {
        let endpoint = `customer/get-address/${id}`
        let token = this.props.user.token
        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                this.setState({
                    area_city: responseData.address.city,
                    area_state: responseData.address.state
                })
            });
    }

    handleCallback = (childData) => {
        this.getAddress(childData.place_id)
        this.setState({
            lat: childData.latLng.lat,
            long: childData.latLng.lng,
            map_address: childData.address
        })
    }

    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    render() {
        return (
            <>
                {this.props.user.login === true ?
                    <>
                        <div className="container-fluid">
                            <div className="row justify-content-end">
                                <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "d-none d-lg-block col-md-10" : "d-none d-lg-block col-12"}>
                                    {/*{this.state.employee?*/}
                                    <div className="col-md-10 d-block m-auto mt-lg-0 mt-4">
                                        {/*// <!-- Card Basic Info -->*/}
                                        <div className="card mt-4 mb-4" id="basic-info">
                                            <div className="card-header">
                                                <h5>Basic Info</h5>
                                            </div>
                                            <div className="card-body pt-0">

                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="input-group input-group-static">
                                                            <label>First Name</label>
                                                            <input type="text" className="form-control"
                                                                onChange={(e) => this.setState({ first_name: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="input-group input-group-static">
                                                            <label>Last Name</label>
                                                            <input type="text" className="form-control"
                                                                onChange={(e) => this.setState({ last_name: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-12 input-group input-group-static">
                                                                <label className="form-label">Gender</label>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="dropdown mt-5">
                                                                    <div className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                                                        data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                                        {this.state.gender ? this.state.gender : 'Select Gender'}
                                                                    </div>
                                                                    <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                                    >
                                                                        <li>
                                                                            <div className="dropdown-item" onClick={(e) => this.setState({ gender: 'MALE' })}>
                                                                                Male
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="dropdown-item" onClick={(e) => this.setState({ gender: 'FEMALE' })}>
                                                                                Female
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-12 input-group input-group-static">
                                                                <label className="form-label">Profile</label>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="dropdown mt-5">
                                                                    <div className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                                                        data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                                        {this.state.profile ? this.state.profile : 'Select Profile'}
                                                                    </div>
                                                                    <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                                    >
                                                                        <li>
                                                                            <div className="dropdown-item" onClick={(e) => this.setState({ profile: 'admin' })}>
                                                                                Admin
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="dropdown-item" onClick={(e) => this.setState({ profile: 'accountant' })}>
                                                                                Accountant
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="dropdown-item" onClick={(e) => this.setState({ profile: 'manager' })}>
                                                                                Manager
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="dropdown-item" onClick={(e) => this.setState({ profile: 'coordinator' })}>
                                                                                Coordinator
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="dropdown-item" onClick={(e) => this.setState({ profile: 'executive' })}>
                                                                                Executive
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <div className="row">
                                                            <div className="col-12 input-group input-group-static mb-5">
                                                                <label className="form-label">Division</label>
                                                            </div>
                                                            <Multiselect
                                                                placeholder="Select Division"
                                                                options={all_divisions} // Options to display in the dropdown
                                                                onSelect={this.onDivisionSelect} // Function will trigger on select event
                                                                onRemove={this.onDivisionRemove} // Function will trigger on remove event
                                                                displayValue="name" // Property name to display in the dropdown options

                                                                style={{
                                                                    searchBox: { fontSize: "15px" }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6 mt-4">
                                                        <div className="input-group input-group-static is-filled">
                                                            <label>D.O.B</label>
                                                            <input type="date" className="form-control mt-2" onFocus="focused(this)"
                                                                value={this.state.dob}
                                                                onChange={(e) => this.setState({ dob: e.target.value })}
                                                                onfocusout="defocused(this)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 mt-4">
                                                    <div className="col-12 input-group input-group-static">
                                                        <label className="form-label">Address:</label>
                                                    </div>
                                                    <button className="w-50 btn srkw bg-gradient-dark dropdown-toggle mt-5" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseExample" aria-expanded="false"
                                                        aria-controls="collapseExample">
                                                        Current Address
                                                    </button>
                                                </div>
                                                <div className="collapse" id="collapseExample">
                                                    <div className="col-12 card card-body">
                                                        <div className="row mt-4">
                                                            <div className="col-6">
                                                                <div className="input-group input-group-static is-filled">
                                                                    <label>House no:</label>
                                                                    <input type="text" className="form-control" onFocus="focused(this)"
                                                                        onChange={(e) => this.setState({ house_num: e.target.value })}
                                                                        onfocusout="defocused(this)" />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="input-group input-group-static is-filled">
                                                                    <label>Street:</label>
                                                                    <input type="tel" className="form-control" onFocus="focused(this)"
                                                                        onChange={(e) => this.setState({ street: e.target.value })}
                                                                        onfocusout="defocused(this)" />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-3">
                                                                <div className="input-group input-group-static is-filled">
                                                                    <label>Area:</label>
                                                                    <input type="tel" className="form-control" onFocus="focused(this)"
                                                                        onChange={(e) => this.setState({ area: e.target.value })}
                                                                        onfocusout="defocused(this)" />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <div className="input-group input-group-static is-filled">
                                                                    <label>City:</label>
                                                                    <input type="tel" className="form-control" onFocus="focused(this)"
                                                                        onChange={(e) => this.setState({ city: e.target.value })}
                                                                        onfocusout="defocused(this)" />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <div className="input-group input-group-static is-filled">
                                                                    <label>Pin code:</label>
                                                                    <input type="tel" className="form-control" onFocus="focused(this)"
                                                                        onChange={(e) => this.setState({ pin_code: e.target.value })}
                                                                        onfocusout="defocused(this)" />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <div className="input-group input-group-static is-filled">
                                                                    <label>State:</label>
                                                                    <Select
                                                                        placeholder="Select State"

                                                                        options={states.map((st, index) => (
                                                                            { value: st.state, label: st.state }
                                                                        ))}

                                                                        onChange={(e) => this.setState({ state: e.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <div className="input-group input-group-static">
                                                            <label>Email</label>
                                                            <input type="email" className="form-control lower-input"
                                                                onChange={(e) => this.setState({ email: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="input-group input-group-static">
                                                            <label>Phone Number</label>
                                                            <input type="text" className="form-control"
                                                                onChange={(e) => this.setState({ phone: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-header">
                                                <h5>Assign Station</h5>
                                            </div>

                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="row">
                                                            {this.state.exceptionState ? this.state.exceptionState.map((item, index) => (
                                                                <p id="assign-state-exception"
                                                                    style={{
                                                                        color: "red"
                                                                    }}>`* {item} state has not any object of station.`</p>
                                                            )) : null
                                                            }
                                                            <Multiselect
                                                                placeholder="Assign State"
                                                                options={states} // Options to display in the dropdown
                                                                onSelect={this.onAssignStateSelect} // Function will trigger on select event
                                                                onRemove={this.onAssignStateRemove} // Function will trigger on remove event
                                                                displayValue="state" // Property name to display in the dropdown options
                                                                style={{
                                                                    searchBox: { fontSize: "15px" }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <Multiselect
                                                                placeholder="Assign District"
                                                                options={stations} // Options to display in the dropdown
                                                                onSelect={this.onAssignCitySelect} // Function will trigger on select event
                                                                onRemove={this.onAssignCityRemove} // Function will trigger on remove event
                                                                displayValue="station" // Property name to display in the dropdown options
                                                                style={{
                                                                    searchBox: { fontSize: "15px" }
                                                                }}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 ">
                                                        <div className="dropdown mt-5">
                                                            <div className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                                                data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">
                                                                Assign Area
                                                            </div>
                                                            <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                            >
                                                                <p className="mb-0 text-sm">
                                                                    Tell us where are you living
                                                                </p>

                                                                <div className="google_map_class">
                                                                    <GoogleMap parentCallback={this.handleCallback} />
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-header">
                                                <h5>Password</h5>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="input-group input-group-outline ">
                                                    {/*<label className="form-label">Password</label>*/}
                                                    <input placeholder="Password" type="password" className="form-control lower-input" onChange={(e) => this.setState({ pass1: e.target.value })} />
                                                </div>
                                                <div className="input-group input-group-outline mt-4">
                                                    {/*<label className="form-label lower-input">Confirm password</label>*/}
                                                    <input placeholder="Confirm password" type="password" className="form-control" onChange={(e) => this.setState({ pass2: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="row my-4">
                                                <div className="col-12">
                                                    <button className="btn bg-gradient-dark d-block m-auto"
                                                        onClick={this.submit}
                                                    >Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                                    <Modal
                                                        aria-labelledby="transition-modal-title"
                                                        aria-describedby="transition-modal-description"
                                                        open={this.state.open}
                                                        onClose={(e)=>this.setState({open: false})}
                                                        closeAfterTransition
                                                        BackdropComponent={Backdrop}
                                                        BackdropProps={{
                                                          timeout: 500,
                                                        }}
                                                      >
                                                        <Fade in={this.state.open}>
                                                          <Box sx={modalStyle}>
                                                            {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                                            {/*  Text in a modal*/}
                                                            {/*</Typography>*/}
                                                            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                                <div className="modal-body">
                                                                    <div className="circle__box">
                                                                        <div className="circle__wrapper circle__wrapper--right">
                                                                            <div className="circle__whole circle__right"></div>
                                                                        </div>
                                                                        <div className="circle__wrapper circle__wrapper--left">
                                                                            <div className="circle__whole circle__left"></div>
                                                                        </div>
                                                                        <div className="circle__checkmark">
                                                                        </div>
                                                                    </div>
                                                                    <div style={{textAlign: 'center'}}>
                                                                        <h5>Employee Created Successfully.</h5>
                                                                        <h6><Link to='/' style={{color: "grey"}}>Click here </Link> for go to employee dashboard.</h6>
                                                                    </div>
                                                                </div>
                                                            </Typography>
                                                          </Box>
                                                        </Fade>
                                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <div className=" px-2 d-lg-none d-block m-auto mt-lg-0 mt-4">

                            <div className="dashOption-mv mt-4">
                                        <div className="">
                                            <div className="button-bar-mv d-flex">
                                                <Link to='/employee' className="btn btn-mv "><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                                <Link to={'/employee/edit-employee/' + this.props.user.employee_id} className="btn btn-mv"><img src={require("../../../kavi/icons/edit.png")} alt="btn-icon" />Edit</Link>
                                                {this.props.user.employee_profile === 'admin' ?
                                                    <Link to="/employee/create-employee" className="btn btn-mv active"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create</Link>
                                                    : null}
                                                <Link to="/create-station" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create Station</Link>
                                                {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                                    <Link to='/employee/employee-transactions' className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Transactions</Link>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                            {/*// <!-- Card Basic Info -->*/}
                            <div className="card mt-4 mb-4" id="basic-info">
                                <div className="card-header p-4 pb-0">
                                    <h5>Basic Information</h5>
                                </div>
                                <div className="card-body px-4 py-0 pt-2">
                                    <div className="row">
                                        <div className="col-12 mt-4">
                                            <div className="input-group input-group-static">
                                                <label>First Name</label>
                                                <input type="text" className="form-control form-control-mv"
                                                    onChange={(e) => this.setState({ first_name: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="input-group input-group-static">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control form-control-mv"
                                                    onChange={(e) => this.setState({ last_name: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 mt-4">
                                            <label className="form-label">Gender</label>
                                            <div className="dropdown">
                                                <div className="m-0 btn-mv btn w-100 srkw srkw-mv bg-gradient-dark dropdown-toggle d-flex justify-content-between align-items-center"
                                                    data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                    {this.state.gender ? this.state.gender : 'Select Gender'}
                                                </div>
                                                <ul className="dropdown-menu w-100 srkw-mv" aria-labelledby="navbarDropdownMenuLink2"
                                                >
                                                    <li>
                                                        <div className="dropdown-item" onClick={(e) => this.setState({ gender: 'MALE' })}>
                                                            Male
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="dropdown-item" onClick={(e) => this.setState({ gender: 'FEMALE' })}>
                                                            Female
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-6 mt-4">
                                            <label className="form-label">Profile</label>
                                            <div className="dropdown">
                                                <div className="m-0 btn btn-mv w-100 srkw srkw-mv bg-gradient-dark dropdown-toggle d-flex justify-content-between align-items-center"
                                                    data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                    {this.state.profile ? this.state.profile : 'Select Profile'}
                                                </div>
                                                <ul className="dropdown-menu w-100 srkw" aria-labelledby="navbarDropdownMenuLink2">
                                                    <li>
                                                        <div className="dropdown-item" onClick={(e) => this.setState({ profile: 'manager' })}>
                                                            Manager
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="dropdown-item" onClick={(e) => this.setState({ profile: 'executive' })}>
                                                            Executive
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <label className="form-label">Division</label>
                                            <Multiselect
                                                placeholder="Select Division"
                                                options={all_divisions} // Options to display in the dropdown
                                                onSelect={this.onDivisionSelect} // Function will trigger on select event
                                                onRemove={this.onDivisionRemove} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options

                                                style={{
                                                    searchBox: { fontSize: "15px" }
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="input-group input-group-static is-filled">
                                                <label>D.O.B</label>
                                                <input type="date" className="form-control form-control-mv" onFocus="focused(this)"
                                                    value={this.state.dob}
                                                    onChange={(e) => this.setState({ dob: e.target.value })}
                                                    onfocusout="defocused(this)" />
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="add1 d-flex flex-column">
                                                <label className="form-label">Address:</label>
                                                <button className="w-100 btn btn-mv srkw bg-gradient-dark dropdown-toggle d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseExample" aria-expanded="false"
                                                    aria-controls="collapseExample">
                                                    Current Address
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 card">
                                            <div className="collapse" id="collapseExample">
                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <div className="input-group input-group-static is-filled">
                                                            <label>House no:</label>
                                                            <input type="text" className="form-control form-control-mv" onFocus="focused(this)"
                                                                onChange={(e) => this.setState({ house_num: e.target.value })}
                                                                onfocusout="defocused(this)" />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="input-group input-group-static is-filled">
                                                            <label>Street:</label>
                                                            <input type="tel" className="form-control form-control-mv" onFocus="focused(this)"
                                                                onChange={(e) => this.setState({ street: e.target.value })}
                                                                onfocusout="defocused(this)" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div className="input-group input-group-static is-filled">
                                                            <label>Area:</label>
                                                            <input type="tel" className="form-control form-control-mv" onFocus="focused(this)"
                                                                onChange={(e) => this.setState({ area: e.target.value })}
                                                                onfocusout="defocused(this)" />
                                                        </div>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <div className="input-group input-group-static is-filled">
                                                            <label>City:</label>
                                                            <input type="tel" className="form-control form-control-mv" onFocus="focused(this)"
                                                                onChange={(e) => this.setState({ city: e.target.value })}
                                                                onfocusout="defocused(this)" />
                                                        </div>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <div className="input-group input-group-static is-filled">
                                                            <label>Pin code:</label>
                                                            <input type="tel" className="form-control form-control-mv" onFocus="focused(this)"
                                                                onChange={(e) => this.setState({ pin_code: e.target.value })}
                                                                onfocusout="defocused(this)" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div className="input-group input-group-static is-filled d-flex flex-column pb-3">
                                                            <label>State:</label>
                                                            <Select
                                                                placeholder="Select State"

                                                                options={states.map((st, index) => (
                                                                    { value: st.state, label: st.state }
                                                                ))}

                                                                onChange={(e) => this.setState({ state: e.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="input-group input-group-static">
                                                <label>Email</label>
                                                <input type="email" className="form-control form-control-mv lower-input"
                                                    onChange={(e) => this.setState({ email: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="input-group input-group-static">
                                                <label>Phone Number</label>
                                                <input type="text" className="form-control form-control-mv"
                                                    onChange={(e) => this.setState({ phone: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-header">
                                    <h5>Assign Station</h5>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="row">
                                        <div className="col-12">
                                            {this.state.exceptionState ? this.state.exceptionState.map((item, index) => (
                                                <p id="assign-state-exception"
                                                    style={{
                                                        color: "red"
                                                    }}>`* {item} state has not any object of station.`</p>
                                            )) : null
                                            }
                                            <Multiselect
                                                placeholder="Assign State"
                                                options={states} // Options to display in the dropdown
                                                onSelect={this.onAssignStateSelect} // Function will trigger on select event
                                                onRemove={this.onAssignStateRemove} // Function will trigger on remove event
                                                displayValue="state" // Property name to display in the dropdown options
                                                style={{
                                                    searchBox: { fontSize: "15px" }
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 mt-3">
                                            <Multiselect
                                                placeholder="Assign District"
                                                options={stations} // Options to display in the dropdown
                                                onSelect={this.onAssignCitySelect} // Function will trigger on select event
                                                onRemove={this.onAssignCityRemove} // Function will trigger on remove event
                                                displayValue="station" // Property name to display in the dropdown options
                                                style={{
                                                    searchBox: { fontSize: "15px" }
                                                }}

                                            />
                                        </div>
                                        <div className="col-12 mt-3 d-none">
                                            <div className="dropdown">
                                                <div className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                                    data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">
                                                    Assign Area
                                                </div>
                                                <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                >
                                                    <p className="mb-0 text-sm">
                                                        Tell us where are you living
                                                    </p>

                                                    <div className="google_map_class">
                                                        <GoogleMap parentCallback={this.handleCallback} />
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-header">
                                    <h5>Password</h5>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="input-group input-group-outline ">
                                        {/*<label className="form-label">Password</label>*/}
                                        <input placeholder="Password" type="password" className="form-control lower-input" onChange={(e) => this.setState({ pass1: e.target.value })} />
                                    </div>
                                    <div className="input-group input-group-outline mt-4">
                                        {/*<label className="form-label lower-input">Confirm password</label>*/}
                                        <input placeholder="Confirm password" type="password" className="form-control" onChange={(e) => this.setState({ pass2: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="col-12">
                                        <button className="btn bg-gradient-dark d-block m-auto mb-5"
                                            onClick={this.submit}
                                        >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Redirect to={'/sign-in'} />
                }
            </>

        )
    }
}

const mapStateToProps = state => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar
})

export default connect(mapStateToProps)(EditEmployee)

