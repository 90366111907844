import React, { useEffect, useState } from 'react'
import { Redirect, Link } from "react-router-dom";
import { axios, url } from '../../store/actions/actions';
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import AcUnitIcon from '@mui/icons-material/AcUnit';



function SRF(props) {
    const [pages, setPages] = useState(0)
    const [employee, setEmployee] = useState(null)

    const [submitStatus, setsubmitStatus] = useState(false)

    const [laoding, setLoading] = useState(false)

    const [deadline, setDeadline] = useState(null)


    // Form State 
    const [from, setFrom] = useState('');
    const [formData, setFormData] = useState({
        from: '',
        to: '',
        cc: [],
        category: '',
        purpose: '',
        message: '',
        file: null,
    });
    const [to, setTo] = useState('');
    const [cc, setCC] = useState([]);
    const [category, setCategory] = useState('');
    const [purpose, setPurpose] = useState('');
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null);

    const [srflist, setSrfList] = useState(null);


    useEffect(() => {
        getAllEmployees()
    }, [])

    const getAllEmployees = () => {
        setLoading(true)
        let token = props.user.token
        let endpoint = "common/load-employee"
        console.log(token)
        axios.get(endpoint, token).then((res) => {
            setLoading(false)
            console.log(res.data.srf)
            setEmployee(res.data.employee)
            setSrfList(res.data.srf)
        })
    }

    const handelPagination = () => {
        console.log("Handle Paginations")
    }

    // Handle Form Submit 
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(cc, "CCCCCCCCCCCCCCCCCCCCCCCCCCCCccc")

        let token = props.user.token
        let endpoint = "common/load-employee"

        // Create a FormData object
        const formData = new FormData();
        formData.append('from', from);
        formData.append('to', to);
        // formData.append('cc', cc);
        formData.append('cc', JSON.stringify(cc));
        // formData.append('category', category);
        formData.append('purpose', purpose);
        formData.append('deadline', deadline);
        formData.append('message', message);
        formData.append('file', file);

        console.log(formData)
        let data = {
            "from": from,
            "cc": cc,
            "to": to,
            // "category": category,
            "category": category,
            "purpose": purpose,
            "message": message,
            "file": file,
        }
        setsubmitStatus(true)
        console.log(data, "Data hai Bhaiyya")
        // Send the data to the backend using Axios
        axios.post(endpoint, formData, token)
            .then((response) => {
                // Handle the response from the server, e.g., show a success message
                console.log('SRF form submitted successfully', response);
                setsubmitStatus(false)

                setFrom("")
                setSrfList(response.data.srf)
                window.location.reload();


            })
            .catch((error) => {
                // Handle any errors that occur during the request
                console.error('Error submitting SRF form:', error);
            });
        resetForm()
    };

    const deleteSRFHandler = (itemId) => {

        let token = props.user.token
        let endpoint = `common/api/delete-srf/${itemId}`
        let data = {
            "srfid": itemId
        }

        axios.delete(endpoint, token)
            .then((response) => {

                setsubmitStatus(false)
                setFrom("")
                setSrfList(response.data.srf)
                window.location.reload();

            })
            .catch((error) => {
                console.error('Error submitting SRF form:', error);
            });
    }

    const resetForm = () => {
        setFormData({
            from: '',
            to: '',
            cc: [],
            category: '',
            purpose: '',
            message: '',
            file: null,
        });
    };
    return (
        <>
            {props.user.login === true ? (
                <>
                    {props.user.employee_profile === "executive" ? (

                        <div className="container-fluid homepage-view">
                            <div className="row justify-content-end">
                                <div
                                    className={
                                        props.sidebar === undefined ||
                                            props.sidebar === true
                                            ? "d-none d-md-block col-lg-10"
                                            : "d-none d-md-block col-lg-12"
                                    }
                                >
                                    <div className="container-fluid mt-3">
                                        <div className="row d-none">
                                            <div className="col-lg-3 col-md-4">
                                                <div className="card">
                                                    <form onSubmit={handleSubmit}>
                                                        <div class="row p-3">
                                                            <div class="col-12">
                                                                <h6>SRF Form</h6>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="combo-box-demo"
                                                                        options={employee || []}
                                                                        getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                        fullWidth
                                                                        renderInput={(params) => <TextField {...params} label="From" />}
                                                                        onChange={(e, newValue) => {
                                                                            console.log(newValue); // newValue mein selected option ka object hoga
                                                                            setFrom(newValue.id)
                                                                        }}
                                                                    />

                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="combo-box-demo"
                                                                        options={employee || []}
                                                                        getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                        fullWidth
                                                                        renderInput={(params) => <TextField {...params} label="To" />}
                                                                        onChange={(e, newValue) => {
                                                                            console.log(newValue); // newValue mein selected option ka object hoga
                                                                            setTo(newValue.id)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="tags-outlined"
                                                                        options={employee || []}
                                                                        getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                        filterSelectedOptions
                                                                        fullWidth
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                label="CC"
                                                                                placeholder="CC"
                                                                            />
                                                                        )}
                                                                        onChange={(e, newValue) => {
                                                                            console.log(newValue); // newValue mein selected option ka object hoga
                                                                            setCC(newValue)
                                                                        }}
                                                                    />

                                                                </div>
                                                                <div class="input-group input-group-outline d-none my-3">
                                                                    <TextField id="outlined-basic" fullWidth label="Category" variant="outlined"
                                                                        onChange={(e) => {
                                                                            setCategory(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField id="outlined-basic" fullWidth label="Purpose" variant="outlined"
                                                                        onChange={(e) => {
                                                                            setPurpose(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField id="outlined-basic" type='file' fullWidth variant="outlined"
                                                                        onChange={(e) => {
                                                                            setFile(e.target.files[0])
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <TextField
                                                                        id="outlined-multiline-static"
                                                                        label="Message"
                                                                        multiline
                                                                        rows={4}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        onChange={(e) => {
                                                                            setMessage(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="input-group input-group-outline my-3">
                                                                    <Button type='submit' sx={{ color: "#fff", backgroundColor: "#42424a" }} variant="contained">Create SRF {submitStatus && <CircularProgress color='inherit' size={25} sx={{ marginLeft: "10px" }} />}</Button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 col-md-8">
                                                {/* <div className={laoding ? "card" : "card py-3"}> */}
                                                <div>
                                                    {laoding && <LinearProgress />}
                                                    <VerticalTimeline >
                                                        {srflist ? (
                                                            srflist.length > 0 ? (
                                                                srflist.map((item, index) => {

                                                                    const datetimeString = item.created_at;

                                                                    const dateObject = new Date(datetimeString);

                                                                    const formattedDate = dateObject.toLocaleDateString();

                                                                    return (
                                                                        <VerticalTimelineElement
                                                                            key={index}
                                                                            className="vertical-timeline-element--work"
                                                                            contentArrowStyle={{ borderRight: '7px solid  #42424a' }}
                                                                            date={`${formattedDate}`}
                                                                            iconStyle={{ background: '#42424a', color: '#fff' }}
                                                                            icon={<AcUnitIcon />}
                                                                        >

                                                                            {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                                                                            <div className="d-flex justify-content-between">
                                                                                <p className='mt-0'>
                                                                                    <span> <b>From:</b> {item.froms.first_name} {item.froms.last_name ? item.froms.last_name : null} </span>
                                                                                </p>

                                                                                <p className='mt-0'>
                                                                                    <span> <b>To:</b> {item.to.first_name} {item.to.last_name ? item.to.last_name : null} </span>
                                                                                </p>
                                                                            </div>

                                                                            {/* Loop through item.cc */}
                                                                            <p className="vertical-timeline-element-title mt-0">
                                                                                <span className='fw-normal fs-6'><b>CC:</b> </span>
                                                                                {item.cc.map((ccitem, index) => (
                                                                                    <>
                                                                                        <span key={index}>
                                                                                            {ccitem.first_name} {ccitem.last_name ? ccitem.last_name : null}
                                                                                        </span>{item.cc.length > 1 ? "," : null}&nbsp;
                                                                                    </>
                                                                                ))}
                                                                            </p>

                                                                            <div className="d-none justify-content-between">
                                                                                <p className='mt-0'>
                                                                                    <span> <b>Category:</b> {item.category ? item.category : null} </span>
                                                                                </p>

                                                                                <p className='mt-0'>
                                                                                    <span> <b>Purpose:</b> {item.purpose ? item.purpose : null} </span>
                                                                                </p>
                                                                            </div>

                                                                            <p className="vertical-timeline-element-title mt-0">
                                                                                <span className='fw-normal fs-6'><b>Message:</b> </span>{item.message ? item.message : null}
                                                                            </p>

                                                                            <p className="vertical-timeline-element-title mt-0">
                                                                                <span className='fw-normal fs-6'><b>Created By:</b> </span> {item.created_by.first_name} {item.last_name ? item.last_name : null}
                                                                            </p>
                                                                        </VerticalTimelineElement>
                                                                    );
                                                                })
                                                            ) : null
                                                        ) : null}
                                                    </VerticalTimeline>
                                                    Iska Use Nahi Hai
                                                    <div className="table-responsive d-none px-2">
                                                        <div
                                                            className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                                            <div className="dataTable-top">

                                                                <h5 className="mb-0 ps-3" >Service Request Form</h5>
                                                            </div>
                                                            <div className="dataTable-container text-center">
                                                                <table className="table align-items-center mb-3 dataTable-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                data-sortable="" style={{ width: "5%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("name")}
                                                                            ><span
                                                                                className="dataTable-sorter">S.NO
                                                                                </span></th>
                                                                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                                                data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("city")}
                                                                            >
                                                                                <span
                                                                                    className="dataTable-sorter d-block text-center">
                                                                                    From</span></th>
                                                                            <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                data-sortable="" style={{ width: "20%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("status")}
                                                                            >
                                                                                <span
                                                                                    className="dataTable-sorter">
                                                                                    To</span></th>
                                                                            <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("division")}
                                                                            >
                                                                                <span
                                                                                    className="dataTable-sorter">
                                                                                    CC</span></th>
                                                                            <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("division")}
                                                                            >
                                                                                <span
                                                                                    className="dataTable-sorter">
                                                                                    Photo</span></th>

                                                                            <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("division")}
                                                                            >
                                                                                <span
                                                                                    className="dataTable-sorter">
                                                                                    Deadline</span></th>
                                                                            <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                data-sortable="" style={{ width: "20%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("status")}
                                                                            >
                                                                                <span
                                                                                    className="dataTable-sorter">
                                                                                    Created By:</span></th>
                                                                            <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("division")}
                                                                            >
                                                                                <span
                                                                                    className="dataTable-sorter">
                                                                                    Purpose:</span></th>
                                                                            <th className="text-center text-uppercase d-none text-xxs font-weight-bolder opacity-7"
                                                                                data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("division")}
                                                                            >
                                                                                <span
                                                                                    className="dataTable-sorter">
                                                                                    Category:</span></th>

                                                                            <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                            // onClick={(e)=>this.sortEmpTable("division")}
                                                                            >
                                                                                <span className="dataTable-sorter">
                                                                                    Action</span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {srflist ? (
                                                                                srflist.length > 0 ? (
                                                                                    srflist.map((item, index) => {
                                                                                        const deadlineDate = new Date(item.deadline);
                                                                                        const createdAtDate = new Date(item.created_at);

                                                                                        const formattedDeadline = deadlineDate.toLocaleString();
                                                                                        const formattedCreatedAt = createdAtDate.toLocaleString();

                                                                                        const differenceInTime = deadlineDate.getTime() - createdAtDate.getTime();
                                                                                        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Math.floor ka istemal

                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{item.froms.email ? item.froms.email : null}</td>
                                                                                                <td>{item.to.email ? item.to.email : null}</td>
                                                                                                <td>
                                                                                                    {item.cc.map((ccItem) => (
                                                                                                        <span key={ccItem.email}>
                                                                                                            {ccItem.email} <br />
                                                                                                        </span>
                                                                                                    ))}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <img src={url + item.photo} height="50px" alt="" />
                                                                                                </td>
                                                                                                <td>
                                                                                                    {/* {formattedDeadline} {formattedCreatedAt} */}
                                                                                                    <br />
                                                                                                    Remaining Days: {differenceInDays} days
                                                                                                </td>
                                                                                                <td>{item.created_by.first_name}</td>
                                                                                                <td>{item.purpose}</td>
                                                                                                {/* <td>{item.category}</td> */}
                                                                                                <td>
                                                                                                    <Link to={`edit-srf/${item.id}`}>
                                                                                                        <Button variant='contained'>Edit</Button>
                                                                                                    </Link>
                                                                                                    <Button
                                                                                                        variant='contained'
                                                                                                        onClick={() => deleteSRFHandler(item.id)}
                                                                                                        sx={{ margin: "0 15px" }}
                                                                                                        color='warning'
                                                                                                    >
                                                                                                        Delete
                                                                                                    </Button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                ) : null
                                                                            ) : null}
                                                                    </tbody>

                                                                </table>
                                                                {pages === 1 ?
                                                                    <div className="datatable-mui-pagination">
                                                                        <Pagination count={pages} color="secondary" onChange={handelPagination} size="large" />
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="container-fluid d-lg-none p-0 homepage-view">
                                        <div className="row justify-content-end">
                                            <div className="container-fluid mt-5">
                                                <div className="row g-5">
                                                    <div className="col-lg-3 order-2 col-md-4">
                                                        <div className="card">
                                                            <form onSubmit={handleSubmit}>
                                                                <div class="row p-3">
                                                                    <div class="col-12">
                                                                        <h6>SRF Form</h6>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                options={employee || []}
                                                                                getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                                fullWidth
                                                                                renderInput={(params) => <TextField {...params} label="From" />}
                                                                                onChange={(e, newValue) => {
                                                                                    console.log(newValue); // newValue mein selected option ka object hoga
                                                                                    setFrom(newValue.id)
                                                                                }}
                                                                            />

                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                options={employee || []}
                                                                                getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                                fullWidth
                                                                                renderInput={(params) => <TextField {...params} label="To" />}
                                                                                onChange={(e, newValue) => {
                                                                                    console.log(newValue); // newValue mein selected option ka object hoga
                                                                                    setTo(newValue.id)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <Autocomplete
                                                                                multiple
                                                                                id="tags-outlined"
                                                                                options={employee || []}
                                                                                getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                                filterSelectedOptions
                                                                                fullWidth
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        label="CC"
                                                                                        placeholder="CC"
                                                                                    />
                                                                                )}
                                                                                onChange={(e, newValue) => {
                                                                                    console.log(newValue); // newValue mein selected option ka object hoga
                                                                                    setCC(newValue)
                                                                                }}
                                                                            />

                                                                        </div>
                                                                        <div class="input-group input-group-outline d-none my-3">
                                                                            <TextField id="outlined-basic" fullWidth label="Category" variant="outlined"
                                                                                onChange={(e) => {
                                                                                    setCategory(e.target.value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <TextField id="outlined-basic" fullWidth label="Purpose" variant="outlined"
                                                                                onChange={(e) => {
                                                                                    setPurpose(e.target.value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <TextField id="outlined-basic" type='file' fullWidth variant="outlined"
                                                                                onChange={(e) => {
                                                                                    setFile(e.target.files[0])
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <TextField
                                                                                id="outlined-multiline-static"
                                                                                label="Message"
                                                                                multiline
                                                                                rows={4}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                onChange={(e) => {
                                                                                    setMessage(e.target.value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="input-group input-group-outline my-3">
                                                                            <Button type='submit' variant="contained">Create SRF {submitStatus && <CircularProgress color='inherit' size={25} sx={{ marginLeft: "10px" }} />}</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 order-1 col-md-8">
                                                        <div className={laoding ? "card" : "card py-3"}>
                                                            {laoding && <LinearProgress />}

                                                            <VerticalTimeline >
                                                                {srflist ? (
                                                                    srflist.length > 0 ? (
                                                                        srflist.map((item, index) => {

                                                                            const datetimeString = item.created_at;

                                                                            const dateObject = new Date(datetimeString);

                                                                            const formattedDate = dateObject.toLocaleDateString();

                                                                            return (
                                                                                <VerticalTimelineElement
                                                                                    key={index}
                                                                                    className="vertical-timeline-element--work"
                                                                                    contentArrowStyle={{ borderRight: '7px solid  #42424a' }}
                                                                                    date={`${formattedDate}`}
                                                                                    iconStyle={{ background: '#42424a', color: '#fff' }}
                                                                                    icon={<AcUnitIcon />}
                                                                                >

                                                                                    {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <p className='mt-0'>
                                                                                            <span> <b>From:</b> {item.froms.first_name} {item.froms.last_name ? item.froms.last_name : null} </span>
                                                                                        </p>

                                                                                        <p className='mt-0'>
                                                                                            <span> <b>To:</b> {item.to.first_name} {item.to.last_name ? item.to.last_name : null} </span>
                                                                                        </p>
                                                                                    </div>

                                                                                    {/* Loop through item.cc */}
                                                                                    <p className="vertical-timeline-element-title mt-0">
                                                                                        <span className='fw-normal fs-6'><b>CC:</b> </span>
                                                                                        {item.cc.map((ccitem, index) => (
                                                                                            <>
                                                                                                <span key={index}>
                                                                                                    {ccitem.first_name} {ccitem.last_name ? ccitem.last_name : null}
                                                                                                </span>{item.cc.length > 1 ? "," : null}&nbsp;
                                                                                            </>
                                                                                        ))}
                                                                                    </p>

                                                                                    <div className=" d-none justify-content-between">
                                                                                        <p className='mt-0'>
                                                                                            <span> <b>Category:</b> {item.category ? item.category : null} </span>
                                                                                        </p>

                                                                                        <p className='mt-0'>
                                                                                            <span> <b>Purpose:</b> {item.purpose ? item.purpose : null} </span>
                                                                                        </p>
                                                                                    </div>

                                                                                    <p className="vertical-timeline-element-title mt-0">
                                                                                        <span className='fw-normal fs-6'><b>Message:</b> </span>{item.message ? item.message : null}
                                                                                    </p>

                                                                                    <p className="vertical-timeline-element-title mt-0">
                                                                                        <span className='fw-normal fs-6'><b>Created By:</b> </span> {item.created_by.first_name} {item.last_name ? item.last_name : null}
                                                                                    </p>
                                                                                </VerticalTimelineElement>
                                                                            );
                                                                        })
                                                                    ) : null
                                                                ) : null}
                                                            </VerticalTimeline>

                                                            <div className="table-responsive d-none px-2">
                                                                <div
                                                                    className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                                                    <div className="dataTable-top">

                                                                        <h5 className="mb-0 ps-3" >Service Request Form</h5>
                                                                    </div>
                                                                    <div className="dataTable-container text-center">
                                                                        <table className="table align-items-center mb-3 dataTable-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                        data-sortable="" style={{ width: "5%", cursor: "pointer" }}
                                                                                    // onClick={(e)=>this.sortEmpTable("name")}
                                                                                    ><span
                                                                                        className="dataTable-sorter">S.NO
                                                                                        </span></th>
                                                                                    <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                                                        data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                    // onClick={(e)=>this.sortEmpTable("city")}
                                                                                    >
                                                                                        <span
                                                                                            className="dataTable-sorter d-block text-center">
                                                                                            From</span></th>
                                                                                    <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                        data-sortable="" style={{ width: "20%", cursor: "pointer" }}
                                                                                    // onClick={(e)=>this.sortEmpTable("status")}
                                                                                    >
                                                                                        <span
                                                                                            className="dataTable-sorter">
                                                                                            To</span></th>
                                                                                    <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                        data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                    // onClick={(e)=>this.sortEmpTable("division")}
                                                                                    >
                                                                                        <span
                                                                                            className="dataTable-sorter">
                                                                                            CC</span></th>
                                                                                    <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                        data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                    // onClick={(e)=>this.sortEmpTable("division")}
                                                                                    >
                                                                                        <span
                                                                                            className="dataTable-sorter">
                                                                                            Photo</span></th>
                                                                                    <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                        data-sortable="" style={{ width: "20%", cursor: "pointer" }}
                                                                                    // onClick={(e)=>this.sortEmpTable("status")}
                                                                                    >
                                                                                        <span
                                                                                            className="dataTable-sorter">
                                                                                            Created By:</span></th>
                                                                                    <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                        data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                    // onClick={(e)=>this.sortEmpTable("division")}
                                                                                    >
                                                                                        <span
                                                                                            className="dataTable-sorter">
                                                                                            Purpose:</span></th>
                                                                                    <th className="text-center d-none text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                        data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                    // onClick={(e)=>this.sortEmpTable("division")}
                                                                                    >
                                                                                        <span
                                                                                            className="dataTable-sorter">
                                                                                            Category:</span></th>

                                                                                    <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                        data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                    // onClick={(e)=>this.sortEmpTable("division")}
                                                                                    >
                                                                                        <span className="dataTable-sorter">
                                                                                            Action</span>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {srflist ? (
                                                                                srflist.length > 0 ? (
                                                                                    srflist.map((item, index) => {
                                                                                        const deadlineDate = new Date(item.deadline);
                                                                                        const createdAtDate = new Date(item.created_at);

                                                                                        const formattedDeadline = deadlineDate.toLocaleString();
                                                                                        const formattedCreatedAt = createdAtDate.toLocaleString();

                                                                                        const differenceInTime = deadlineDate.getTime() - createdAtDate.getTime();
                                                                                        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Math.floor ka istemal

                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{item.froms.email ? item.froms.email : null}</td>
                                                                                                <td>{item.to.email ? item.to.email : null}</td>
                                                                                                <td>
                                                                                                    {item.cc.map((ccItem) => (
                                                                                                        <span key={ccItem.email}>
                                                                                                            {ccItem.email} <br />
                                                                                                        </span>
                                                                                                    ))}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <img src={url + item.photo} height="50px" alt="" />
                                                                                                </td>
                                                                                                <td>
                                                                                                    {/* {formattedDeadline} {formattedCreatedAt} */}
                                                                                                    <br />
                                                                                                    Remaining Days: {differenceInDays} days
                                                                                                </td>
                                                                                                <td>{item.created_by.first_name}</td>
                                                                                                <td>{item.purpose}</td>
                                                                                                {/* <td>{item.category}</td> */}
                                                                                                <td>
                                                                                                    <Link to={`edit-srf/${item.id}`}>
                                                                                                        <Button variant='contained'>Edit</Button>
                                                                                                    </Link>
                                                                                                    <Button
                                                                                                        variant='contained'
                                                                                                        onClick={() => deleteSRFHandler(item.id)}
                                                                                                        sx={{ margin: "0 15px" }}
                                                                                                        color='warning'
                                                                                                    >
                                                                                                        Delete
                                                                                                    </Button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                ) : null
                                                                            ) : null}
                                                                            </tbody>

                                                                        </table>
                                                                        {pages === 0 ?
                                                                            <div className="datatable-mui-pagination">
                                                                                <Pagination count={pages} color="secondary" onChange={handelPagination} size="large" />
                                                                            </div> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="container-fluid homepage-view">
                                <div className="row justify-content-end">
                                    <div
                                        className={
                                            props.sidebar === undefined ||
                                                props.sidebar === true
                                                ? "d-none d-md-block col-10"
                                                : "d-none d-md-block col-12"
                                        }
                                    >
                                        <div className="container-fluid mt-3">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4">
                                                    <div className="card">
                                                        <form onSubmit={handleSubmit}>
                                                            <div class="row p-3">
                                                                <div class="col-12">
                                                                    <h6>SRF Form</h6>
                                                                    <div class="input-group input-group-outline my-3">
                                                                        <Autocomplete
                                                                            disablePortal
                                                                            id="combo-box-demo"
                                                                            options={employee || []}
                                                                            getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                            fullWidth
                                                                            renderInput={(params) => <TextField {...params} label="From" />}
                                                                            onChange={(e, newValue) => {
                                                                                console.log(newValue); // newValue mein selected option ka object hoga
                                                                                setFrom(newValue.id)
                                                                            }}
                                                                        />

                                                                    </div>
                                                                    <div class="input-group input-group-outline my-3">
                                                                        <Autocomplete
                                                                            disablePortal
                                                                            id="combo-box-demo"
                                                                            options={employee || []}
                                                                            getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                            fullWidth
                                                                            renderInput={(params) => <TextField {...params} label="To" />}
                                                                            onChange={(e, newValue) => {
                                                                                console.log(newValue); // newValue mein selected option ka object hoga
                                                                                setTo(newValue.id)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="input-group input-group-outline my-3">
                                                                        <Autocomplete
                                                                            multiple
                                                                            id="tags-outlined"
                                                                            options={employee || []}
                                                                            getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                            filterSelectedOptions
                                                                            fullWidth
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="CC"
                                                                                    placeholder="CC"
                                                                                />
                                                                            )}
                                                                            onChange={(e, newValue) => {
                                                                                console.log(newValue); // newValue mein selected option ka object hoga
                                                                                setCC(newValue)
                                                                            }}
                                                                        />

                                                                    </div>
                                                                    <div class="input-group input-group-outline d-none my-3">
                                                                        <TextField id="outlined-basic" fullWidth label="Category" variant="outlined"
                                                                            onChange={(e) => {
                                                                                setCategory(e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="input-group input-group-outline my-3">
                                                                        <TextField id="outlined-basic" fullWidth label="Purpose" variant="outlined"
                                                                            onChange={(e) => {
                                                                                setPurpose(e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="input-group input-group-outline my-3">
                                                                        <TextField id="outlined-basic" type='file' fullWidth variant="outlined"
                                                                            onChange={(e) => {
                                                                                setFile(e.target.files[0])
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="input-group input-group-outline my-3">
                                                                        <TextField
                                                                            id="date"
                                                                            label="Deadline"
                                                                            type="date"
                                                                            size="large"
                                                                            fullWidth
                                                                            // sx={{ width: 220 }}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setDeadline(e.target.value)
                                                                                console.log(e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="input-group input-group-outline my-3">
                                                                        <TextField
                                                                            id="outlined-multiline-static"
                                                                            label="Message"
                                                                            multiline
                                                                            rows={4}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            onChange={(e) => {
                                                                                setMessage(e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="input-group input-group-outline my-3">
                                                                        <Button type='submit' variant="contained">Create SRF {submitStatus && <CircularProgress color='inherit' size={25} sx={{ marginLeft: "10px" }} />}</Button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9 col-md-8">
                                                    <div className={laoding ? "card" : "card py-3"}>
                                                        {laoding && <LinearProgress />}

                                                        <div className="table-responsive px-2">
                                                            <div
                                                                className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                                                <div className="dataTable-top">

                                                                    <h5 className="mb-0 ps-3" >Service Request Form</h5>
                                                                </div>
                                                                <div className="dataTable-container text-center">
                                                                    <table className="table align-items-center mb-3 dataTable-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                    data-sortable="" style={{ width: "5%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("name")}
                                                                                ><span
                                                                                    className="dataTable-sorter">S.NO
                                                                                    </span></th>
                                                                                <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                                                    data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("city")}
                                                                                >
                                                                                    <span
                                                                                        className="dataTable-sorter d-block text-center">
                                                                                        From</span></th>
                                                                                <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                    data-sortable="" style={{ width: "20%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("status")}
                                                                                >
                                                                                    <span
                                                                                        className="dataTable-sorter">
                                                                                        To</span></th>
                                                                                <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                    data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("division")}
                                                                                >
                                                                                    <span
                                                                                        className="dataTable-sorter">
                                                                                        CC</span></th>
                                                                                <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                    data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("division")}
                                                                                >
                                                                                    <span
                                                                                        className="dataTable-sorter">
                                                                                        Photo</span></th>
                                                                                <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                    data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("division")}
                                                                                >
                                                                                    <span
                                                                                        className="dataTable-sorter">
                                                                                        Deadline
                                                                                    </span></th>
                                                                                <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                    data-sortable="" style={{ width: "20%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("status")}
                                                                                >
                                                                                    <span
                                                                                        className="dataTable-sorter">
                                                                                        Created By:</span></th>
                                                                                <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                    data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("division")}
                                                                                >
                                                                                    <span
                                                                                        className="dataTable-sorter">
                                                                                        Purpose:</span></th>
                                                                                <th className="text-center d-none text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                    data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("division")}
                                                                                >
                                                                                    <span
                                                                                        className="dataTable-sorter">
                                                                                        Category:</span></th>

                                                                                <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                    data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                // onClick={(e)=>this.sortEmpTable("division")}
                                                                                >
                                                                                    <span className="dataTable-sorter">
                                                                                        Action</span>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {srflist ? (
                                                                                srflist.length > 0 ? (
                                                                                    srflist.map((item, index) => {
                                                                                        const deadlineDate = new Date(item.deadline);
                                                                                        const createdAtDate = new Date(item.created_at);

                                                                                        const formattedDeadline = deadlineDate.toLocaleString();
                                                                                        const formattedCreatedAt = createdAtDate.toLocaleString();

                                                                                        const differenceInTime = deadlineDate.getTime() - createdAtDate.getTime();
                                                                                        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Math.floor ka istemal

                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{item.froms.email ? item.froms.email : null}</td>
                                                                                                <td>{item.to.email ? item.to.email : null}</td>
                                                                                                <td>
                                                                                                    {item.cc.map((ccItem) => (
                                                                                                        <span key={ccItem.email}>
                                                                                                            {ccItem.email} <br />
                                                                                                        </span>
                                                                                                    ))}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <img src={url + item.photo} height="50px" alt="" />
                                                                                                </td>
                                                                                                <td>
                                                                                                    {/* {formattedDeadline} {formattedCreatedAt} */}
                                                                                                    <br />
                                                                                                    Remaining Days: {differenceInDays} days
                                                                                                </td>
                                                                                                <td>{item.created_by.first_name}</td>
                                                                                                <td>{item.purpose}</td>
                                                                                                {/* <td>{item.category}</td> */}
                                                                                                <td>
                                                                                                    <Link to={`edit-srf/${item.id}`}>
                                                                                                        <Button variant='contained'>Edit</Button>
                                                                                                    </Link>
                                                                                                    <Button
                                                                                                        variant='contained'
                                                                                                        onClick={() => deleteSRFHandler(item.id)}
                                                                                                        sx={{ margin: "0 15px" }}
                                                                                                        color='warning'
                                                                                                    >
                                                                                                        Delete
                                                                                                    </Button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                ) : null
                                                                            ) : null}


                                                                        </tbody>
                                                                    </table>
                                                                    {pages === 1 ?
                                                                        <div className="datatable-mui-pagination">
                                                                            <Pagination count={pages} color="secondary" onChange={handelPagination} size="large" />
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-md-none">
                                        <div className="container-fluid d-lg-none p-0 homepage-view">
                                            <div className="row justify-content-end">
                                                <div className="container-fluid mt-5">
                                                    <div className="row g-5">
                                                        <div className="col-lg-3 order-2 col-md-4">
                                                            <div className="card">
                                                                <form onSubmit={handleSubmit}>
                                                                    <div class="row p-3">
                                                                        <div class="col-12">
                                                                            <h6>SRF Form</h6>
                                                                            <div class="input-group input-group-outline my-3">
                                                                                <Autocomplete
                                                                                    disablePortal
                                                                                    id="combo-box-demo"
                                                                                    options={employee || []}
                                                                                    getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                                    fullWidth
                                                                                    renderInput={(params) => <TextField {...params} label="From" />}
                                                                                    onChange={(e, newValue) => {
                                                                                        console.log(newValue); // newValue mein selected option ka object hoga
                                                                                        setFrom(newValue.id)
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            <div class="input-group input-group-outline my-3">
                                                                                <Autocomplete
                                                                                    disablePortal
                                                                                    id="combo-box-demo"
                                                                                    options={employee || []}
                                                                                    getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                                    fullWidth
                                                                                    renderInput={(params) => <TextField {...params} label="To" />}
                                                                                    onChange={(e, newValue) => {
                                                                                        console.log(newValue); // newValue mein selected option ka object hoga
                                                                                        setTo(newValue.id)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div class="input-group input-group-outline my-3">
                                                                                <Autocomplete
                                                                                    multiple
                                                                                    id="tags-outlined"
                                                                                    options={employee || []}
                                                                                    getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                                                                                    filterSelectedOptions
                                                                                    fullWidth
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            {...params}
                                                                                            label="CC"
                                                                                            placeholder="CC"
                                                                                        />
                                                                                    )}
                                                                                    onChange={(e, newValue) => {
                                                                                        console.log(newValue); // newValue mein selected option ka object hoga
                                                                                        setCC(newValue)
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            <div class="input-group input-group-outline d-none my-3">
                                                                                <TextField id="outlined-basic" fullWidth label="Category" variant="outlined"
                                                                                    onChange={(e) => {
                                                                                        setCategory(e.target.value)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div class="input-group input-group-outline my-3">
                                                                                <TextField id="outlined-basic" fullWidth label="Purpose" variant="outlined"
                                                                                    onChange={(e) => {
                                                                                        setPurpose(e.target.value)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div class="input-group input-group-outline my-3">
                                                                                <TextField id="outlined-basic" type='file' fullWidth variant="outlined"
                                                                                    onChange={(e) => {
                                                                                        setFile(e.target.files[0])
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div class="input-group input-group-outline my-3">
                                                                                <TextField
                                                                                    id="date"
                                                                                    label="Deadline"
                                                                                    type="date"
                                                                                    size="large"
                                                                                    fullWidth
                                                                                    // sx={{ width: 220 }}
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setDeadline(e.target.value)
                                                                                        console.log(e.target.value)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div class="input-group input-group-outline my-3">
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Message"
                                                                                    multiline
                                                                                    rows={4}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    onChange={(e) => {
                                                                                        setMessage(e.target.value)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div class="input-group input-group-outline my-3">
                                                                                <Button type='submit' variant="contained">Create SRF {submitStatus && <CircularProgress color='inherit' size={25} sx={{ marginLeft: "10px" }} />}</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 order-1 col-md-8">
                                                            <div className={laoding ? "card" : "card py-3"}>
                                                                {laoding && <LinearProgress />}

                                                                <div className="table-responsive px-2">
                                                                    <div
                                                                        className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                                                        <div className="dataTable-top">

                                                                            <h5 className="mb-0 ps-3" >Service Request Form</h5>
                                                                        </div>
                                                                        <div className="dataTable-container text-center">
                                                                            <table className="table align-items-center mb-3 dataTable-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                            data-sortable="" style={{ width: "5%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("name")}
                                                                                        ><span
                                                                                            className="dataTable-sorter">S.NO
                                                                                            </span></th>
                                                                                        <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                                                            data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("city")}
                                                                                        >
                                                                                            <span
                                                                                                className="dataTable-sorter d-block text-center">
                                                                                                From</span></th>
                                                                                        <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                            data-sortable="" style={{ width: "20%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("status")}
                                                                                        >
                                                                                            <span
                                                                                                className="dataTable-sorter">
                                                                                                To</span></th>
                                                                                        <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                            data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("division")}
                                                                                        >
                                                                                            <span
                                                                                                className="dataTable-sorter">
                                                                                                CC</span></th>
                                                                                        <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                            data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("division")}
                                                                                        >
                                                                                            <span
                                                                                                className="dataTable-sorter">
                                                                                                Photo</span></th>

                                                                                        <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                            data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("division")}
                                                                                        >
                                                                                            <span
                                                                                                className="dataTable-sorter">
                                                                                                Deadline</span></th>


                                                                                        <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                            data-sortable="" style={{ width: "20%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("status")}
                                                                                        >
                                                                                            <span
                                                                                                className="dataTable-sorter">
                                                                                                Created By:</span></th>
                                                                                        <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                            data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("division")}
                                                                                        >
                                                                                            <span
                                                                                                className="dataTable-sorter">
                                                                                                Purpose:</span></th>
                                                                                        <th className="text-center d-none text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                            data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("division")}
                                                                                        >
                                                                                            <span
                                                                                                className="dataTable-sorter">
                                                                                                Category:</span></th>

                                                                                        <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                                                            data-sortable="" style={{ width: "10%", cursor: "pointer" }}
                                                                                        // onClick={(e)=>this.sortEmpTable("division")}
                                                                                        >
                                                                                            <span className="dataTable-sorter">
                                                                                                Action</span>
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {srflist ? (
                                                                                srflist.length > 0 ? (
                                                                                    srflist.map((item, index) => {
                                                                                        const deadlineDate = new Date(item.deadline);
                                                                                        const createdAtDate = new Date(item.created_at);

                                                                                        const formattedDeadline = deadlineDate.toLocaleString();
                                                                                        const formattedCreatedAt = createdAtDate.toLocaleString();

                                                                                        const differenceInTime = deadlineDate.getTime() - createdAtDate.getTime();
                                                                                        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Math.floor ka istemal

                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{item.froms.email ? item.froms.email : null}</td>
                                                                                                <td>{item.to.email ? item.to.email : null}</td>
                                                                                                <td>
                                                                                                    {item.cc.map((ccItem) => (
                                                                                                        <span key={ccItem.email}>
                                                                                                            {ccItem.email} <br />
                                                                                                        </span>
                                                                                                    ))}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <img src={url + item.photo} height="50px" alt="" />
                                                                                                </td>
                                                                                                <td>
                                                                                                    {/* {formattedDeadline} {formattedCreatedAt} */}
                                                                                                    <br />
                                                                                                    Remaining Days: {differenceInDays} days
                                                                                                </td>
                                                                                                <td>{item.created_by.first_name}</td>
                                                                                                <td>{item.purpose}</td>
                                                                                                {/* <td>{item.category}</td> */}
                                                                                                <td>
                                                                                                    <Link to={`edit-srf/${item.id}`}>
                                                                                                        <Button variant='contained'>Edit</Button>
                                                                                                    </Link>
                                                                                                    <Button
                                                                                                        variant='contained'
                                                                                                        onClick={() => deleteSRFHandler(item.id)}
                                                                                                        sx={{ margin: "0 15px" }}
                                                                                                        color='warning'
                                                                                                    >
                                                                                                        Delete
                                                                                                    </Button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                ) : null
                                                                            ) : null}
                                                                                </tbody>

                                                                            </table>
                                                                            {pages === 0 ?
                                                                                <div className="datatable-mui-pagination">
                                                                                    <Pagination count={pages} color="secondary" onChange={handelPagination} size="large" />
                                                                                </div> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <Redirect to={"/sign-in"} />
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(SRF);
