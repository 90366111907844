import React from "react";
import {sideBar, userLogin} from "../../../store/actions/actions";
import { connect } from "react-redux";
import '../../common/common.css'
import {HalfPieChart} from "half-pie-chart";
import Chart from "react-apexcharts";


class HomeDataCards extends React.Component {

    state = {
        options: {
          chart: {
            width: 380,
            type: 'donut',
          },
          // cutoutPercentage: 10,
          // dataLabels: {
          //   enabled: false
          // },

          labels: ['Sampling', 'Survey', 'Payment', 'Closure', 'Followup'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }],
          legend: {
            position: 'right',
            offsetY: 0,
            height: 230,
          }
        },
        all_emp: null,
        manager_emp: null,
        admin_emp: null,
        executive_emp: null,

        new_emp: null,
        new_admin: null,
        new_executive: null,
        new_manager: null,
        right: [
        {
          value: this.props.data.active_employee,
          displayValue: this.props.data.active_employee,
          text: "Present",
          color: "#4cb38e",
        },
      ],
      left: [
        {
          value: this.props.data.all_employee - this.props.data.active_employee,
          displayValue: this.props.data.all_employee - this.props.data.active_employee,
          text: "Absent",
          color: "#eee36b",
        },
      ]
    }

    callbackFilter = (data) => {
        this.props.parentCallback(data)
        // vt.map((i, index) => (purpose?i.purpose!==null?i.purpose.toLowerCase().includes(purpose.toLowerCase()) ? myList.push(i) : null:null:null))
    }

    render() {
        return (
            <>
                <div className='d-none d-lg-block'>
                    <div className='row'>
                    <div className='col-3 my-3'>
                        <div className='half-pid-fix'>
                        {/*<Chart*/}
                        {/*  options={this.state.options}*/}
                        {/*  series={this.state.series}*/}
                        {/*  type="donut"*/}
                        {/*  width="380"*/}
                        {/*/>*/}

                        <HalfPieChart
                            name="attendance"
                            right={this.state.right}
                            left={this.state.left}
                            title="Attendance"
                            dark={true}
                        />
                        </div>
                    </div>
                    <div className={this.props.sidebar === undefined || this.props.sidebar === true ?'col-5 my-3':'col-6 my-3'}>
                        <div className="card mb-2 p-3">
                            <div className="main-content" onClick={(e)=>this.callbackFilter('sampling')}>
                                <h6>Sampling</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.sampling/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.sampling}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content" onClick={(e)=>this.callbackFilter('survey')}>
                                <h6>Survey</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.survey/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.survey}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content" onClick={(e)=>this.callbackFilter('closure')}>
                                <h6>Closure</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.closure/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.closure}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content" onClick={(e)=>this.callbackFilter('followup')}>
                                <h6>Follow up</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.followup/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.followup}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content" onClick={(e)=>this.callbackFilter('payment')}>
                                <h6>Payment</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.payment/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.payment}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.props.sidebar === undefined || this.props.sidebar === true ?'col-4 my-3 pb-2': 'col-3 my-3 pb-2'}>
                        <div className='card h-40 overflow-hidden'>
                            <div className="p-3 chart-wrap">
                                <div id="chart">
                                    <Chart options={this.state.options} series={[this.props.data.sampling, this.props.data.survey, this.props.data.payment, this.props.data.closure, this.props.data.followup]}
                                                    type="donut" width={'100%'} height={'205%'}/>
                                    {/*<Chart options={this.state.options} series={[this.props.data.sampling, this.props.data.survey, this.props.data.payment, this.props.data.closure, this.props.data.followup]}*/}
                                    {/*                type="donut" width={'108%'} height={'150%'}/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 my-4'>
                        <div className='row'>
                            <div className="col-4">
                                <div className="card">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">person_add</i>
                                    </div>
                                    <div className="text-end pt-1 mt-4">
                                        <p className="text-lg mb-0 text-capitalize">Visits</p>
                                        <h4 className="mb-0">{this.props.data.all_visits}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark"/>
                                <div className="card-footer p-3">
                                    {/*<p className="mb-0">Just updated</p>*/}
                                </div>
                                </div>
                            </div>
                            <div className="col-3 d-none">
                                <div className="card">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">person_add</i>
                                    </div>
                                    <div className="text-end pt-1 mt-4">
                                        <p className="text-lg mb-0 text-capitalize">TA/DA</p>
                                        <h4 className="mb-0">{this.props.data.tada}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark"/>
                                <div className="card-footer p-3">
                                    {/*<p className="mb-0">Just updated</p>*/}
                                </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">person_add</i>
                                    </div>
                                    <div className="text-end pt-1 mt-4">
                                        <p className="text-lg mb-0 text-capitalize">Total Distance</p>
                                        <h4 className="mb-0">{this.props.data.total_distance_travelled}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark"/>
                                <div className="card-footer p-3">
                                    {/*<p className="mb-0">Just updated</p>*/}
                                </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">person_add</i>
                                    </div>
                                    <div className="text-end pt-1 mt-4">
                                        <p className="text-lg mb-0 text-capitalize">New Customer's</p>
                                        <h4 className="mb-0">5</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark"/>
                                <div className="card-footer p-3">
                                    {/*<p className="mb-0">Just updated</p>*/}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className='d-lg-none p-2'>
                    <div className='row'>
                    <div className='col-12'>
                        <HalfPieChart
                            name="attendance"
                            right={this.state.right}
                            left={this.state.left}
                            title="Attendance"
                            dark={true}
                        />
                    </div>
                    <div className='col-12 mt-3'>
                        <div className="card mb-2 p-3">
                            <div className="main-content">
                                <h6>Sampling</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.sampling/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.sampling}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content">
                                <h6>Survey</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.survey/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.survey}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content">
                                <h6>Closure</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.closure/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.closure}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content">
                                <h6>Follow up</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.followup/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.followup}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content">
                                <h6>Payment</h6>
                                <div id="example-2" className="examples">
                                    <div className="cssProgress">
                                        <div className="progress2">
                                            <div className="cssProgress-bar " data-percent="75" style={{width: `${(this.props.data.payment/this.props.data.all_visits)*100}%`}}>
                                                <span className="cssProgress-label">{this.props.data.payment}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row'>
                            <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#45454B" }} className="panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Visits</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.props.data.all_visits}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }} className="line-truncate-twoline">
                                    <span>+{this.state.new_emp} </span>employee’s added this month
                                </p>

                            </div>
                        </div>
                            <div className="col-6 d-none">
                            <div className="panel-mv">
                                <img style={{ background: "#45454B" }} className="panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>TA/DA</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.props.data.tada}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }} className="line-truncate-twoline">
                                    <span>+{this.state.new_emp} </span>employee’s added this month
                                </p>

                            </div>
                        </div>
                            <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#45454B" }} className="panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>Total Distance</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>{this.props.data.total_distance_travelled}</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }} className="line-truncate-twoline">
                                    <span>+{this.state.new_emp} </span>employee’s added this month
                                </p>

                            </div>
                        </div>
                            <div className="col-6">
                            <div className="panel-mv">
                                <img style={{ background: "#45454B" }} className="panel-icon-mv" src={require("../../../kavi/icons/all employe.png")}
                                    alt="pannel" />
                                <div className="panel-caption-mv d-flex justify-content-between align-items-center">
                                    <p className="my-2" style={{ fontWeight: "bold", fontSize: "12px" }}>New Customer's</p>
                                    <p className="m-0" style={{ fontWeight: "bold", fontSize: "12px" }}>5</p>
                                </div>
                                <p style={{ fontSize: "10px", marginBottom: "0" }} className="line-truncate-twoline">
                                    <span>+{this.state.new_emp} </span>employee’s added this month
                                </p>

                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar
})

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeDataCards)
