import React from "react";
import {connect} from "react-redux";
import {axios, sideBar, userLogin, modalStyle} from "../../../store/actions/actions";
import {states} from "../../common/objects";
import GoogleMap from "../../GoogleMap/GoogleMap";
import Select from "react-select";
import StarRatings from "react-star-ratings/build/star-ratings";
import SubmitModal from "../../common/Modals/submitModal";
import {Link, Redirect} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class EditCustomer extends React.Component {

    state = {
        address_type: 'address',

        address_id: null,
        street: null,
        street_name: null,
        city: null,
        pin_code: null,
        state: null,
        plot_num: null,
        transport_name: null,
        nearest_station: null,
        special_instruction: null,

        addrs_by_loc_id:null,
        map_address: null,
        lat: null,
        long: null,

        bank_id: null,
        bank_name: null,
        account_holder_name: null,
        account: null,
        ifsc: null,
        bank_phone: null,
        remark: null,
        is_default: 0,

        auth_id: null,
        principle_name: null,
        principle_landline: null,
        school_strength: null,
        date_of_finalization: null,

        cust_id: null,
        customer_type: null,
        company: null,
        cust_phone: null,
        cust_mobile: null,
        email: null,
        gst_num: null,
        pan_num: null,
        rating: null,
        website: null,

        customer: null,
        users: null,

        open: null,
        getbygoogle: false,

    }

    componentDidMount() {
        this.loadCustomerPageWithAxios()
        this.toeknExpiryCheck()
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

    loadCustomerPageWithAxios = () => {
        let pathname = window.location.pathname;

        let pathurl = pathname.split("/");
        let path = pathurl[pathurl.length - 1];
        let endpoint = `customer/${path}`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data.customer[0];

                this.setState({cust_id: responseData.id})
                this.setState({customer_type: responseData.type})
                this.setState({company: responseData.company})
                this.setState({cust_phone: responseData.phone})
                this.setState({cust_mobile: responseData.cust_mobile})
                this.setState({email: responseData.email})
                this.setState({gst_num: responseData.gst_num})
                this.setState({pan_num: responseData.pan_num})
                this.setState({rating: responseData.rating})
                this.setState({website: responseData.website})

                this.setState({address_id: responseData.address[0]?responseData.address[0].id:null})
                this.setState({street_name: responseData.address[0]?responseData.address[0].street:null})
                this.setState({city: responseData.address[0]?responseData.address[0].distt:null})
                this.setState({pin_code: responseData.address[0]?responseData.address[0].pin_code:null})
                this.setState({state: responseData.address[0]?responseData.address[0].state:null})
                this.setState({transport_name: responseData.address[0]?responseData.address[0].transport_name:null})
                this.setState({nearest_station: responseData.address[0]?responseData.address[0].nearest_station:null})
                this.setState({special_instruction: responseData.address[0]?responseData.address[0].special_instruction:null})

                this.setState({addrs_by_loc_id: responseData.address_by_location?responseData.address_by_location.id:null})
                this.setState({map_address: responseData.address_by_location?responseData.address_by_location.address:null})
                this.setState({lat: responseData.address_by_location?responseData.address_by_location.geolocation.split(",")[0]:null})
                this.setState({long: responseData.address_by_location?responseData.address_by_location.geolocation.split(",")[1]:null})

                this.setState({bank_id: responseData.bank[0]?responseData.bank[0].id:null})
                this.setState({bank_name: responseData.bank[0]?responseData.bank[0].bank_name:null})
                this.setState({account_holder_name: responseData.bank[0]?responseData.bank[0].account_holder_name:null})
                this.setState({account: responseData.bank[0]?responseData.bank[0].account:null})
                this.setState({ifsc: responseData.bank[0]?responseData.bank[0].ifsc:null})
                this.setState({bank_phone: responseData.bank[0]?responseData.bank[0].phone:null})
                this.setState({remark: responseData.bank[0]?responseData.bank[0].remark:null})
                this.setState({is_default: responseData.bank[0]?responseData.bank[0].is_default:null})

                this.setState({auth_id: responseData.information?responseData.information.id:null})
                this.setState({principle_name: responseData.information?responseData.information.principle_name:null})
                this.setState({principle_landline: responseData.information?responseData.information.principle_landline:null})
                this.setState({school_strength: responseData.information?responseData.information.school_strength:null})
                this.setState({date_of_finalization: responseData.information?responseData.information.date_of_finalization:null})

                this.setState({customer: responseData})

                console.log({'response data---------':responseData})

            });
    }

  getEstablishmentDetails = (id) => {
    //  -------> call by backend
    let endpoint = `customer/get-establishment-details/${id}`
        let token = this.props.user.token
    axios
        .get(endpoint, token)
        .then((res)=>{
                let responseData = res.data;

                console.log({'**************** response data---------':responseData})

                this.setState({
                    company: responseData.name,
                    cust_phone: responseData.phone,
                    website: responseData.website,
                    rating: responseData.rating,

                    street: responseData.address.street?responseData.address.street+', ':'',
                    city: responseData.address.city,
                    pin_code: responseData.address.pin_code,
                    state: responseData.address.state,
                    area: responseData.address.area?responseData.address.area+', ':'',
                    route: responseData.address.route?responseData.address.route+', ':'',
                    landmark: responseData.address.landmark?responseData.address.landmark+', ':'',
                    sector: responseData.address.sector?responseData.address.sector+', ':'',
                    block: responseData.address.block?responseData.address.block+', ':'',

                    getbygoogle: true,
                })
            });

    //  -------> call by google
    // let endpoint = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${id}&key=${google_map_api_key}`
    // axios
    //     .get(endpoint)
    //     .then((res)=>{
    //             let responseData = res.data;
    //             var address_components = responseData.results[0].address_components;
    //
    //             for (var i = 0; i < address_components.length; i++)
    //             {
    //                if (address_components[i].types[0] === "political") {
    //                     this.setState({area: address_components[i].long_name})
    //                     console.log('area= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "street_number") {
    //                     this.setState({street: address_components[i].long_name})
    //                     console.log('street= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "route") {
    //                     this.setState({route: address_components[i].long_name})
    //                     console.log('route= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "administrative_area_level_1" && address_components[i].types[1] === "political") {
    //                     this.setState({state: address_components[i].short_name})
    //                     console.log('state= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "administrative_area_level_2" && address_components[i].types[1] === "political") {
    //                     this.setState({city: address_components[i].long_name})
    //                     console.log('city= '+ address_components[i].long_name)
    //                }
    //
    //                if (address_components[i].types[0] === "postal_code") {
    //                     this.setState({pin_code: address_components[i].long_name})
    //                     console.log('zipcode= '+ address_components[i].long_name)
    //                }
    //             }
    //         });
  };

  handleCallback = (childData) => {
      this.getEstablishmentDetails(childData.place_id)
      this.getInfo(childData.place_id)
      this.setState({
          lat: childData.latLng.lat,
          long: childData.latLng.lng,
          map_address: childData.address
      })
  }

  getInfo = (id) => {
        let endpoint = `/customer/get-establishment-details/${id}`
        let token = this.props.user.token
        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({company: responseData.name})
                this.setState({cust_phone: responseData.phone})
                this.setState({website: responseData.website})
                this.setState({rating: responseData.rating})
                console.log(JSON.stringify(responseData));
            });
    }

    PrvNxt = (data) => {
        if (data === 'info') {
            document.getElementById("show-info").style.display = "block"
            document.getElementById("show-bank").style.display = "none"
            document.getElementById("show-address").style.display = "none"
            document.getElementById("show-auth").style.display = "none"

            document.getElementById("bank-progress").classList.remove("js-active");
            document.getElementById("auth-progress").classList.remove("js-active");
            document.getElementById("info-progress").classList.add("js-active");
        }

        if (data === 'address') {
            document.getElementById("show-address").style.display = "block"
            document.getElementById("show-info").style.display = "none"
            document.getElementById("show-bank").style.display = "none"
            document.getElementById("show-auth").style.display = "none"

            document.getElementById("bank-progress").classList.remove("js-active");
            document.getElementById("auth-progress").classList.remove("js-active");
            document.getElementById("address-progress").classList.add("js-active");
            document.getElementById("info-progress").classList.remove("js-active");
        }

        if (data === 'bank') {
            document.getElementById("show-info").style.display = "none"
            document.getElementById("show-bank").style.display = "block"
            document.getElementById("show-address").style.display = "none"
            document.getElementById("show-auth").style.display = "none"

            document.getElementById("bank-progress").classList.add("js-active");
            document.getElementById("auth-progress").classList.remove("js-active");
            document.getElementById("address-progress").classList.add("js-active");
            document.getElementById("info-progress").classList.add("js-active");
        }

        if (data === 'auth') {
            document.getElementById("show-info").style.display = "none"
            document.getElementById("show-bank").style.display = "none"
            document.getElementById("show-address").style.display = "none"
            document.getElementById("show-auth").style.display = "block"

            document.getElementById("bank-progress").classList.add("js-active");
            document.getElementById("auth-progress").classList.add("js-active");
            document.getElementById("address-progress").classList.add("js-active");
            document.getElementById("info-progress").classList.add("js-active");
        }
    }

    submit = () => {
      const data = {
        address_id: this.state.address_id,
        street_name: this.state.getbygoogle===true?this.state.street + this.state.block + this.state.sector + this.state.route + this.state.landmark + this.state.area:this.state.street_name,
        dist: this.state.city,
        pin_code: this.state.pin_code,
        state: this.state.state,
        transport_name: this.state.transport_name,
        nearest_station: this.state.nearest_station===null?this.state.city:this.state.nearest_station,
        special_instruction: this.state.special_instruction,

        bank_id: this.state.bank_id,
        bank_name: this.state.bank_name,
        account_holder_name: this.state.account_holder_name,
        account: this.state.account,
        ifsc: this.state.ifsc,
        bank_phone: this.state.bank_phone,
        remark: this.state.remark,
        is_default: this.state.is_default,

        auth_id: this.state.auth_id,
        principle_name: this.state.principle_name,
        principle_landline: this.state.principle_landline,
        school_strength: this.state.school_strength,
        date_of_finalization: this.state.date_of_finalization,

        cust_id: this.state.cust_id,
        customer_type: this.state.customer_type,
        company: this.state.company,
        cust_phone: this.state.cust_phone,
        cust_mobile: this.state.cust_mobile,
        email: this.state.email,
        rating: this.state.rating,
        gst_num: this.state.gst_num,
        pan_num: this.state.pan_num,
        website: this.state.website,

        addrs_by_loc_id: this.state.addrs_by_loc_id,
        lat: this.state.lat,
        long: this.state.long,
        map_address: this.state.map_address,

        address_type: this.state.address_type,
      }
      console.log('========>', data)

      let endpoint = `customer/`
        let token = this.props.user.token
      axios
          .put(endpoint, data, token)
          .then((res)=>{
              let responseData = res.data;
                if(responseData.success===true){
                    this.setState({
                        open: true
                    })
                }
              console.log({'response data---------':responseData})
          })
    }

    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

  render() {
    return (
        <>
            {this.props.user.login===true?
                <>
            <div className="container-fluid">
                <div className="row justify-content-end">
                    <div
                        className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-md-10" : "col-12"}>
                        <div className="row mt-5 mb-5 justify-content-center">
                            <div className="col-12">
                                <div className="multisteps-form mb-2">
                                    <div className="row">
                                        <div className="col-12 col-lg-10 m-auto">
                                            <div className="card">
                                                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                                    <div
                                                        className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                                                        <div className="multisteps-form__progress">
                                                            <button
                                                                className="multisteps-form__progress-btn js-active"
                                                                id="address-progress"
                                                                onClick={(e) => this.PrvNxt('address')}
                                                                type="button"
                                                                title="Address"
                                                            >
                                                                Address
                                                            </button>
                                                            <button
                                                                className="multisteps-form__progress-btn"
                                                                id="info-progress"
                                                                onClick={(e) => this.PrvNxt('info')}
                                                                type="button"
                                                                title="User Info"
                                                            >
                                                                <span>Info</span>
                                                            </button>
                                                            <button
                                                                className="multisteps-form__progress-btn"
                                                                id="bank-progress"
                                                                onClick={(e) => this.PrvNxt('bank')}
                                                                type="button"
                                                                title="Socials"
                                                            >
                                                                Bank Details
                                                            </button>
                                                            <button
                                                                className="multisteps-form__progress-btn"
                                                                id="auth-progress"
                                                                onClick={(e) => this.PrvNxt('auth')}
                                                                type="button"
                                                                title="Authorization"
                                                            >
                                                                Information
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <form className="multisteps-form__form">
                                                        {/*-----------------------Address----------------------*/}
                                                        <div id="show-address">
                                                            <div className="row">
                                                                <div className="col-md-7">
                                                                    <h5 className="font-weight-bold mb-0">Address</h5>
                                                                    <p className="mb-0 text-sm">
                                                                        Tell us where are you living
                                                                    </p>
                                                                    <div className="google_map_class">
                                                                        <GoogleMap
                                                                            parentCallback={this.handleCallback}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5">


                                                                    <div className="multisteps-form__content">
                                                                        <>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="dropdown mt-4">
                                                                                        <div  className="m-0 btn w-70 srkw bg-gradient-dark dropdown-toggle"
                                                                                           data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">
                                                                                            {this.state.customer_type?this.state.customer_type:'Select Customer Type'}
                                                                                        </div>
                                                                                        <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                                                            >
                                                                                            <li>
                                                                                                <div className="dropdown-item"
                                                                                                    onClick={(e) => this.setState({customer_type: 'SCHOOL'})}>
                                                                                                    SCHOOL
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div className="dropdown-item"
                                                                                                    onClick={(e) => this.setState({customer_type: 'SELLER'})}>
                                                                                                    SELLER
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-12 ">
                                                                                    <div
                                                                                        className="input-group input-group-dynamic">
                                                                                        {/*<label className="form-label">Street Name</label>*/}
                                                                                        <input
                                                                                            placeholder='Street Name'
                                                                                            onChange={(e) => this.setState({street: e.target.value})}
                                                                                            defaultValue={this.state.getbygoogle===true?this.state.street + this.state.block + this.state.sector + this.state.route + this.state.landmark + this.state.area:this.state.street_name}

                                                                                            className="multisteps-form__input form-control"
                                                                                            type="text"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-12 ">
                                                                                    <div
                                                                                        className="input-group input-group-dynamic">
                                                                                        {/*<label className="form-label">City</label>*/}
                                                                                        <input
                                                                                            placeholder='City'
                                                                                            onChange={(e) => this.setState({city: e.target.value})}
                                                                                            defaultValue={this.state.city}
                                                                                            className="multisteps-form__input form-control"
                                                                                            type="text"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-12 ">
                                                                                    <div
                                                                                        className="input-group input-group-dynamic">
                                                                                        {/*<label className="form-label">Pin Code</label>*/}
                                                                                        <input
                                                                                            placeholder='Pin Code'
                                                                                            onChange={(e) => this.setState({pin_code: e.target.value})}
                                                                                            defaultValue={this.state.pin_code}
                                                                                            className="multisteps-form__input form-control"
                                                                                            type="text"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-12 ">
                                                                                    <div
                                                                                        className="input-group input-group-dynamic">
                                                                                        {/*<label className="form-label">State</label>*/}

                                                                                      <Select
                                                                                            placeholder="Select State"

                                                                                            value={this.state.state? {value: this.state.state, label: this.state.state}:null}

                                                                                            options={states.map((st, index)=>(
                                                                                                {value: st.state, label: st.state}
                                                                                            ))}
                                                                                            onChange={(e)=>this.setState({state: e.value})}
                                                                                      />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-6">
                                                                                    <div
                                                                                        className="input-group input-group-dynamic">
                                                                                        <input
                                                                                            placeholder='Transport Name'
                                                                                            onChange={(e) => this.setState({transport_name: e.target.value})}
                                                                                            defaultValue={this.state.transport_name}
                                                                                            className="multisteps-form__input form-control"
                                                                                            type="text"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div
                                                                                        className="input-group input-group-dynamic">
                                                                                        <input
                                                                                            placeholder='Nearest Station'
                                                                                            onChange={(e) => this.setState({nearest_station: e.target.value})}
                                                                                            className="multisteps-form__input form-control"
                                                                                            defaultValue={this.state.city}
                                                                                            type="text"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-12">
                                                                                    <div
                                                                                        className="input-group input-group-dynamic">
                                                                                        <textarea
                                                                                            placeholder='Special Instruction'
                                                                                            onChange={(e) => this.setState({special_instruction: e.target.value})}
                                                                                            className="mt-4 multisteps-form__textarea form-control"
                                                                                            defaultValue={this.state.special_instruction}
                                                                                            rows="3"
                                                                                        ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        <div className="button-row d-flex mt-4">
                                                                            <button
                                                                                className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                                                                type="button"
                                                                                title="Next"
                                                                                onClick={(e) => this.PrvNxt('info')}
                                                                            >
                                                                                Next
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*-----------------------Info----------------------*/}
                                                        <div id="show-info" style={{display: 'none'}}
                                                        >
                                                            <h5 className="font-weight-bolder mb-0">
                                                                Create Your New Account
                                                            </h5>
                                                            <p className="mb-0 text-sm">Mandatory informations</p>
                                                            <div className="multisteps-form__content">
                                                                <div className="row mt-3">
                                                                    <div className="col-12 mt-3">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">Company Name</label>*/}
                                                                            <input
                                                                                placeholder='Company Name'
                                                                                defaultValue={this.state.company}
                                                                                onChange={(e) => this.setState({company: e.target.value})}
                                                                                className="multisteps-form__input form-control"
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 mt-4">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">*/}
                                                                            {/*  Phone Number*/}
                                                                            {/*</label>*/}
                                                                            <input
                                                                                placeholder='Phone Number'
                                                                                minLength="11"
                                                                                maxLength="11"
                                                                                defaultValue={this.state.cust_phone}
                                                                                onChange={(e) => this.setState({cust_phone: e.target.value})}
                                                                                className="multisteps-form__input form-control"
                                                                                type="tel"
                                                                                pattern="[0-9]{3}-[0-9]{8}"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 mt-4">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            <label
                                                                                className="form-label">Rating:</label>
                                                                            {this.state.rating ?
                                                                                <div style={{
                                                                                    marginTop: "5px",
                                                                                    marginLeft: "50px"
                                                                                }}>
                                                                                    <StarRatings
                                                                                        rating={this.state.rating}
                                                                                        starDimension="15px"
                                                                                        starSpacing="1px"
                                                                                        starRatedColor="yellow"
                                                                                        name="rating"
                                                                                    />
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-12 col-sm-12">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">Email</label>*/}
                                                                            <input
                                                                                placeholder="Email"
                                                                                onChange={(e) => this.setState({email: e.target.value})}
                                                                                defaultValue={this.state.email}
                                                                                className="multisteps-form__input form-control"
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 mt-4">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">Websites</label>*/}
                                                                            <input
                                                                                placeholder='Websites'
                                                                                defaultValue={this.state.website}
                                                                                onChange={(e) => this.setState({website: e.target.value})}
                                                                                className="multisteps-form__input form-control"
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-12 col-sm-6">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            <input
                                                                                placeholder='GST No.'
                                                                                onChange={(e) => this.setState({gst_num: e.target.value})}
                                                                                defaultValue={this.state.gst_num}
                                                                                className="multisteps-form__input form-control"
                                                                                type="text"
                                                                                minLength="15"
                                                                                maxLength="15"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            <input
                                                                                placeholder='PAN Number'
                                                                                onChange={(e) => this.setState({pan_num: e.target.value})}
                                                                                defaultValue={this.state.pan_num}
                                                                                className="multisteps-form__input form-control"
                                                                                type="text"
                                                                                maxLength="10"
                                                                                minLength="10"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="button-row d-flex mt-4">
                                                                    <button
                                                                        className="btn bg-gradient-light mb-0 js-btn-prev"
                                                                        type="button"
                                                                        title="Prev"
                                                                        onClick={(e) => this.PrvNxt('address')}
                                                                    >
                                                                        Prev
                                                                    </button>
                                                                    <button
                                                                        className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                                                        type="button"
                                                                        title="Next"
                                                                        onClick={(e) => this.PrvNxt('bank')}
                                                                    >
                                                                        Next
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*-----------------------Bank Details----------------------*/}
                                                        <div id="show-bank" style={{display: 'none'}}
                                                        >
                                                            <h5 className="font-weight-bolder mb-0">
                                                                Bank Deatails
                                                            </h5>
                                                            {/* <!-- <p className="mb-0 text-sm">Please provide at least one social link</p> --> */}
                                                            <div className="multisteps-form__content">
                                                                <div className="row mt-3">
                                                                    <div className="col-12">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">*/}
                                                                            {/*  Bank Name*/}
                                                                            {/*</label>*/}
                                                                            <input
                                                                                placeholder='Bank Name'
                                                                                defaultValue={this.state.bank_name}
                                                                                onChange={(e) => this.setState({bank_name: e.target.value})}
                                                                                className="multisteps-form__input form-control"
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-3">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">*/}
                                                                            {/*  Account Number*/}
                                                                            {/*</label>*/}
                                                                            <input
                                                                                placeholder='Account Number'
                                                                                defaultValue={this.state.account}
                                                                                onChange={(e) => this.setState({account: e.target.value})}
                                                                                className="multisteps-form__input form-control"
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-3">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">*/}
                                                                            {/*  Account Holder Name*/}
                                                                            {/*</label>*/}
                                                                            <input
                                                                                placeholder='Account Holder Name'
                                                                                defaultValue={this.state.account_holder_name}
                                                                                onChange={(e) => this.setState({account_holder_name: e.target.value})}
                                                                                className="multisteps-form__input form-control"
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-3">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">remark:</label>*/}
                                                                            <div className="col-12 mt-3">
                                                                                <div
                                                                                    className="input-group input-group-dynamic">
                                                                                      <textarea
                                                                                          placeholder='Remark'
                                                                                          defaultValue={this.state.remark}
                                                                                          onChange={(e) => this.setState({remark: e.target.value})}
                                                                                          className="multisteps-form__textarea form-control"
                                                                                          rows="3"
                                                                                      ></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-3">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">*/}
                                                                            {/*  Phone Number*/}
                                                                            {/*</label>*/}
                                                                            <input
                                                                                placeholder='Phone Number'
                                                                                defaultValue={this.state.bank_phone}
                                                                                onChange={(e) => this.setState({bank_phone: e.target.value})}
                                                                                pattern="[0-9]{3}-[0-9]{8}"
                                                                                className="multisteps-form__input form-control"
                                                                                type="tel"
                                                                                minLength="11"
                                                                                maxLength="11"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-3">
                                                                        <div
                                                                            className="input-group input-group-dynamic">
                                                                            {/*<label className="form-label">*/}
                                                                            {/*  IFSC:*/}
                                                                            {/*</label>*/}
                                                                            <input
                                                                                placeholder='IFSC Code'
                                                                                onChange={(e) => this.setState({ifsc: e.target.value})}
                                                                                defaultValue={this.state.ifsc}
                                                                                pattern="[0-9]{3}-[0-9]{8}"
                                                                                className="multisteps-form__input form-control"
                                                                                type="tel"
                                                                                minLength="11"
                                                                                maxLength="11"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="button-row d-flex mt-4 col-12">
                                                                        <button
                                                                            className="btn bg-gradient-light mb-0 js-btn-prev"
                                                                            type="button"
                                                                            title="Prev"
                                                                            onClick={(e) => this.PrvNxt('address')}
                                                                        >
                                                                            Prev
                                                                        </button>
                                                                        <button
                                                                            className="btn bg-gradient-dark ms-auto mb-0 js-btn-next"
                                                                            type="button"
                                                                            title="Next"
                                                                            onClick={(e) => this.PrvNxt('auth')}
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*-----------------------Information----------------------*/}
                                                        <div id="show-auth" style={{display: 'none'}}
                                                        >
                                                            <h5 className="font-weight-bolder mb-0">
                                                                Authorised By:
                                                            </h5>
                                                            {/* <!-- <p className="mb-0 text-sm">Optional informations</p> --> */}
                                                            <div className="multisteps-form__content mt-3">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="col-12 mt-3">
                                                                            <div
                                                                                className="input-group input-group-dynamic">
                                                                                {/*<label className="form-label">*/}
                                                                                {/*  Name of Principle*/}
                                                                                {/*</label>*/}
                                                                                <input
                                                                                    placeholder='Name of Principle'
                                                                                    defaultValue={this.state.principle_name}
                                                                                    onChange={(e) => this.setState({principle_name: e.target.value})}
                                                                                    className="multisteps-form__input form-control"
                                                                                    type="text"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    {/*<label className="form-label">Date</label>*/}
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div
                                                                                        className="input-group input-group-dynamic">
                                                                                        <input
                                                                                            placeholder='Date'
                                                                                            defaultValue={this.state.date_of_finalization}
                                                                                            onChange={(e) => this.setState({date_of_finalization: e.target.value})}
                                                                                            className="multisteps-form__input form-control"
                                                                                            type="date"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="col-12 mt-3">
                                                                            <div
                                                                                className="input-group input-group-dynamic">
                                                                                {/*<label className="form-label">*/}
                                                                                {/*  Landline*/}
                                                                                {/*</label>*/}
                                                                                <input
                                                                                    placeholder='Landline'
                                                                                    onChange={(e) => this.setState({principle_landline: e.target.value})}
                                                                                    defaultValue={this.state.principle_landline}
                                                                                    className="multisteps-form__input form-control"
                                                                                    type="tel"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    {/*<label className="form-label">Strength</label>*/}
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div
                                                                                        className="input-group input-group-dynamic">
                                                                                        <input
                                                                                            placeholder='Strength'
                                                                                            className="multisteps-form__input form-control"
                                                                                            defaultValue={this.state.school_strength}
                                                                                            onChange={(e) => this.setState({school_strength: e.target.value})}
                                                                                            type="number"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="button-row d-flex mt-4">
                                                                    <button
                                                                        onClick={(e) => this.PrvNxt('bank')}
                                                                        className="btn bg-gradient-light mb-0 js-btn-prev"
                                                                        type="button"
                                                                        title="Prev"
                                                                    >
                                                                        Prev
                                                                    </button>
                                                                    <button
                                                                        className="btn bg-gradient-dark ms-auto mb-0"
                                                                        type="button"
                                                                        title="Send"
                                                                        onClick={(e) => this.submit()}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>




                                                    <Modal
                                                        aria-labelledby="transition-modal-title"
                                                        aria-describedby="transition-modal-description"
                                                        open={this.state.open}
                                                        onClose={(e)=>this.setState({open: false})}
                                                        closeAfterTransition
                                                        BackdropComponent={Backdrop}
                                                        BackdropProps={{
                                                          timeout: 500,
                                                        }}
                                                      >
                                                        <Fade in={this.state.open}>
                                                          <Box sx={modalStyle}>
                                                            {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                                            {/*  Text in a modal*/}
                                                            {/*</Typography>*/}
                                                            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                                <div className="modal-body">
                                                                    <div className="circle__box">
                                                                        <div className="circle__wrapper circle__wrapper--right">
                                                                            <div className="circle__whole circle__right"></div>
                                                                        </div>
                                                                        <div className="circle__wrapper circle__wrapper--left">
                                                                            <div className="circle__whole circle__left"></div>
                                                                        </div>
                                                                        <div className="circle__checkmark">
                                                                        </div>
                                                                    </div>
                                                                    <div style={{textAlign: 'center'}}>
                                                                        <h5>Customer Updated Successfully.</h5>
                                                                        <h6><Link to='/customer' style={{color: "grey"}}>Click here </Link> for go to customer dashboard.</h6>
                                                                    </div>
                                                                </div>
                                                            </Typography>
                                                          </Box>
                                                        </Fade>
                                                    </Modal>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
            :
                <Redirect to={'/sign-in'}/>
            }
        </>

    );
  }
}

const mapStateToProps = (state) => ({
  user:state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer)
