import React from "react";
import { connect } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import {axios, sideBar, url, userLogin, modalStyle} from "../../../store/actions/actions";
import '../../common/imageCase.css'
import {Link, Redirect} from "react-router-dom";
import { states } from "../../common/objects";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

class CreateTeam extends React.Component {

    state = {
        name: null,

        all_cities: null,

        selectedState: null,
        selectedStateList: null,

        selected_city: null,
        selected_city_list: null,

        headOptions: [{ name: 'Option 1️⃣', id: 1 }, { name: 'Option 2️⃣', id: 2 }],
        selectedHead: null,
        selectedHeadList: null,

        memberOptions: [{ name: 'Option 1️⃣', id: 1 }, { name: 'Option 2️⃣', id: 2 }],
        selectedMembers: null,
        selectedMembersList: null,

        coordinatorOptions: [{ name: 'Option 1️⃣', id: 1 }, { name: 'Option 2️⃣', id: 2 }],
        coordinator: null,

        logo: null,
        open: null,
        image: null,

        lat: null,
        long: null,
        map_address: null,

    }

    componentDidMount() {
        this.loadHeadsAndMembers()
        this.loadStations()
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

    loadStations = () => {
        let endpoint = `employee/stations`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                this.setState({
                    all_cities: responseData.stations
                })

                console.log({ 'Stations response data---------': responseData.stations })
            })
    }

    loadHeadsAndMembers = () => {
        let endpoint = `teams/get-members-heads`
        let token = this.props.user.token
        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                this.setState({ coordinatorOptions: responseData.coordinators })
                this.setState({ headOptions: responseData.heads })
                this.setState({ memberOptions: responseData.members })
                this.setState({ stateOptions: responseData.states })

                console.log({ 'Heads and Members data---------': responseData })
            })
    }

    onStateSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        this.setState({
            selectedState: selectedItem,
            selectedStateList: selectedList
        })
    }

    onStateRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        this.setState({
            selectedStateList: selectedList
        })
    }

    onHeadSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        this.setState({
            selectedHead: selectedItem,
            selectedHeadList: selectedList
        })
    }

    onHeadRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        this.setState({
            selectedHeadList: selectedList
        })
    }

    onMemberSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        this.setState({
            selectedMember: selectedItem,
            selectedMembersList: selectedList
        })
    }

    onMemberRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        this.setState({
            selectedMemberList: selectedList
        })
    }

    handleFileSelected = (e) => {
        const files = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                logo: files,
                image: reader.result
            });
        }
        reader.readAsDataURL(files);

    };


    submitLogo = (id) => {
        let data = new FormData();
        data.append("logo", this.state.logo);
        data.append("id", id);
        let endpoint = `teams/`
        let token = this.props.user.token
        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                if (responseData.success === true) {
                    this.setState({
                        showModal: true
                    })
                }

                console.log({ 'Response---------': responseData })
            })
    }

    onAssignCitySelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        this.setState({
            selected_city: selectedItem,
            selected_city_list: selectedList
        })
    }

    onAssignCityRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        this.setState({
            selected_city_list: selectedList
        })
    }

    submit = () => {
        this.setState({open: true})
        const data = {
            "name": this.state.name,
            "heads": this.state.selectedHeadList,
            'members': this.state.selectedMembersList,
            'coordinator': this.state.coordinator,
            "state": this.state.selectedStateList,
            "city": this.state.selected_city_list,
            "area": [
                this.state.map_address,
                this.state.lat,
                this.state.long
            ]
        }

        console.log(data)

        let endpoint = `teams/`
        let token = this.props.user.token
        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                if (responseData.success === true) {
                    this.submitLogo(responseData.id)
                }
                console.log({ 'Response---------': responseData })
            })
    }

    handleCallback = (childData) => {
        this.setState({
            lat: childData.latLng.lat,
            long: childData.latLng.lng,
            map_address: childData.address
        })
    }

    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    render() {
        return (
            <>
                {this.props.user.login === true ?
                    <>
                        <div className="container-fluid d-none d-lg-block">
                            <div className="row justify-content-end">
                                <div
                                    className={
                                        this.props.sidebar === undefined || this.props.sidebar === true
                                            ? "col-10"
                                            : "col-12"
                                    }
                                >
                                    <div className="row mt-5 mb-5 justify-content-center">
                                        <div className="col-md-10 mt-lg-0 mt-4">
                                            <div className="card mt-4" id="basic-info">
                                                <div className="card-header">
                                                    <h5>Create Team</h5>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div className="row">

                                                        <div className="row my-2">
                                                            <div className="col-6">
                                                                <div className="input-group input-group-dynamic">
                                                                    <label className="form-label">
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        placeholder='Team name'
                                                                        onChange={(e) => this.setState({ name: e.target.value })}
                                                                        className="multisteps-form__input form-control mt-4"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </div>
                                                <div className="col-6">
                                                    <label className="form-label">
                                                        State
                                                    </label>
                                                    <Multiselect
                                                        placeholder="Select State"
                                                        options={this.state.stateOptions} // Options to display in the dropdown
                                                        selectedValues={this.state.stateSelectedValues} // Preselected value to persist in dropdown
                                                        onSelect={this.onStateSelect} // Function will trigger on select event
                                                        onRemove={this.onStateRemove} // Function will trigger on remove event
                                                        displayValue="state" // Property name to display in the dropdown options
                                                        style={{
                                                            searchBox: {fontSize: "15px"}
                                                        }}

                                                    />
                                                </div>

                                                        </div>

                                                        <div className="row mt-3">
                                                            {this.state.coordinatorOptions?
                                                                <div className="col-6 p-3 mt-3">
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        size='small'
                                                                        id="combo-box-demo"
                                                                        options={this.state.coordinatorOptions}
                                                                        onChange={(e, value)=>this.setState({coordinator: value.id})}
                                                                        getOptionLabel={(option) => option.last_name?option.first_name+' '+option.last_name:option.first_name}
                                                                        // sx={{ width: 300 }}
                                                                        renderOption={(props, option) => (
                                                                            <Box component="li" sx={{
                                                                                '& > img': {
                                                                                    mr: 2,
                                                                                    flexShrink: 0
                                                                                }
                                                                            }} {...props}>
                                                                                <img
                                                                                    style={{borderRadius: '50%'}}
                                                                                    // loading="lazy"
                                                                                    width="30"
                                                                                    src={option.pic?url + option.pic:url+'/static/agent.png'}
                                                                                    // srcSet={`${url + option.pic} 2x`}
                                                                                    alt=""
                                                                                />
                                                                                {option.last_name?option.first_name+' '+option.last_name:option.first_name}
                                                                            </Box>
                                                                        )}
                                                                        renderInput={(params) =>
                                                                            <TextField {...params}
                                                                                       label="Select Coordinator"/>}
                                                                    />
                                                                </div>
                                                            :null}
                                                            <div className="col-6">
                                                                <label className="form-label">
                                                                    Heads
                                                                </label>
                                                                <Multiselect
                                                                    placeholder="Select Team Heads"
                                                                    options={this.state.headOptions} // Options to display in the dropdown
                                                                    // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                                    onSelect={this.onHeadSelect} // Function will trigger on select event
                                                                    onRemove={this.onHeadRemove} // Function will trigger on remove event
                                                                    displayValue="first_name" // Property name to display in the dropdown options
                                                                    style={{
                                                                        searchBox: { fontSize: "15px" }
                                                                    }}

                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">
                                                                    Members
                                                                </label>
                                                                <Multiselect
                                                                    placeholder="Select Team Members"
                                                                    options={this.state.memberOptions} // Options to display in the dropdown
                                                                    // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                                    onSelect={this.onMemberSelect} // Function will trigger on select event
                                                                    onRemove={this.onMemberRemove} // Function will trigger on remove event
                                                                    displayValue="first_name" // Property name to display in the dropdown options
                                                                    style={{
                                                                        searchBox: { fontSize: "15px" }
                                                                    }}

                                                                />
                                                            </div>
                                                        </div>


                                                            <div className="col-12">
                                                                <div className="dropdown mt-5"
                                                                >
                                                                    <div className="m-0 btn w-100 srkw bg-gradient-dark dropdown-toggle"

                                                                        onClick={(e) =>
                                                                            document.getElementById("assignstation").style.display === 'block' ?
                                                                                document.getElementById("assignstation").style.display = 'none' :
                                                                                document.getElementById("assignstation").style.display = 'block'
                                                                        }
                                                                        data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                                        Assign Station
                                                                    </div>
                                                                    <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                                        style={{ display: 'none' }}
                                                                    >
                                                                    </ul>
                                                                </div>
                                                                <div className="card" id='assignstation' style={{ display: 'none' }}>
                                                                    <div className="card-header">
                                                                        <h5>Assign Station</h5>
                                                                    </div>

                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-6">
                                                                                <div className="row">
                                                                                    <Multiselect
                                                                                        placeholder="Assign State"
                                                                                        options={states} // Options to display in the dropdown
                                                                                        // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                                                        onSelect={this.onStateSelect} // Function will trigger on select event
                                                                                        onRemove={this.onStateRemove} // Function will trigger on remove event
                                                                                        displayValue="state" // Property name to display in the dropdown options
                                                                                        style={{
                                                                                            searchBox: { fontSize: "15px" }
                                                                                        }}

                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="row">
                                                                                    {this.state.all_cities ?
                                                                                        <Multiselect
                                                                                            placeholder="Assign District"
                                                                                            options={this.state.all_cities} // Options to display in the dropdown
                                                                                            onSelect={this.onAssignCitySelect} // Function will trigger on select event
                                                                                            onRemove={this.onAssignCityRemove} // Function will trigger on remove event
                                                                                            displayValue="station" // Property name to display in the dropdown options
                                                                                            style={{
                                                                                                searchBox: { fontSize: "15px" }
                                                                                            }}

                                                                                        />
                                                                                        : null}
                                                                                </div>
                                                                            </div>
                                                                            {/*<div className="col-12 ">*/}
                                                                            {/*     <div className="dropdown mt-5">*/}
                                                                            {/*         <div  className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"*/}
                                                                            {/*            data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">*/}
                                                                            {/*             Assign Area*/}
                                                                            {/*         </div>*/}
                                                                            {/*         <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"*/}
                                                                            {/*             >*/}
                                                                            {/*             <p className="mb-0 text-sm">*/}
                                                                            {/*               Tell us where are you living*/}
                                                                            {/*             </p>*/}

                                                                            {/*             <div className="google_map_class">*/}
                                                                            {/*                 <GoogleMap parentCallback = {this.handleCallback} />*/}
                                                                            {/*             </div>*/}
                                                                            {/*         </ul>*/}
                                                                            {/*     </div>*/}
                                                                            {/*</div>*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        <div className="mt-3">
                                                            <label htmlFor="formFileLg" className="form-label">
                                                                Upload Team Logo
                                                            </label>
                                                            <input className="form-control form-control-lg" id="formFileLg"
                                                                onChange={this.handleFileSelected}
                                                                style={{ color: 'red', backgroundColor: '#e5e5e5' }}
                                                                type="file"
                                                            />
                                                        </div>
                                                    </div>
                                                    <Modal
                                                        aria-labelledby="transition-modal-title"
                                                        aria-describedby="transition-modal-description"
                                                        open={this.state.open}
                                                        onClose={(e)=>this.setState({open: false})}
                                                        closeAfterTransition
                                                        BackdropComponent={Backdrop}
                                                        BackdropProps={{
                                                          timeout: 500,
                                                        }}
                                                      >
                                                        <Fade in={this.state.open}>
                                                          <Box sx={modalStyle}>
                                                            {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                                            {/*  Text in a modal*/}
                                                            {/*</Typography>*/}
                                                            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                                <div className="modal-body">
                                                                    <div className="circle__box">
                                                                        <div className="circle__wrapper circle__wrapper--right">
                                                                            <div className="circle__whole circle__right"></div>
                                                                        </div>
                                                                        <div className="circle__wrapper circle__wrapper--left">
                                                                            <div className="circle__whole circle__left"></div>
                                                                        </div>
                                                                        <div className="circle__checkmark">
                                                                        </div>
                                                                    </div>
                                                                    <div style={{textAlign: 'center'}}>
                                                                        <h5>Team Created Successfully.</h5>
                                                                        <h6><Link to='/team' style={{color: "grey"}}>Click here </Link> for go to teams dashboard.</h6>
                                                                    </div>
                                                                </div>
                                                            </Typography>
                                                          </Box>
                                                        </Fade>
                                                    </Modal>

                                                    {this.state.image ?
                                                        <div className="image-area mt-4">
                                                            <img
                                                                src={this.state.image}
                                                                alt="Preview" />
                                                            <div className="remove-image"
                                                                onClick={() => this.setState({ image: null })}
                                                                style={{ display: "inline" }}
                                                            >&#215;
                                                            </div>
                                                        </div>
                                                        : null}
                                                    <div className="row my-4">
                                                        <div className="col-12 mt-4">
                                                            <button className="btn bg-gradient-dark d-block m-auto"
                                                                onClick={this.submit}
                                                            >Submit</button>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-2 d-lg-none">
                                    <div className="dashOption-mv mt-4">
                                        <div className="">
                                            <div className="button-bar-mv d-flex">
                                                <Link to='/team' className="btn btn-mv "><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                                {this.props.user.employee_profile === 'admin' ?
                                                <Link to="/team/create-team" className="btn btn-mv active"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create</Link>
                                                :null}
                                                {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                                <Link to="/account/team-account" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Create Budget</Link>
                                                :null}
                                                {this.props.user.employee_profile === 'admin' || this.props.user.employee_profile === 'accountant' ?
                                                <Link to="/account/team-transactions" className="btn btn-mv"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Transactions</Link>
                                                :null}
                                            </div>
                                        </div>
                                    </div>
                            <div className="row mb-5 justify-content-center">
                                <div className="col-md-10 mt-lg-0 mt-3">
                                    <div className="card mt-4" id="basic-info">
                                        <div className="card-header pb-0">
                                            <h5>Create Team</h5>
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="form-label">Name</label>
                                                    <div className="input-group input-group-dynamic">
                                                        <input
                                                            placeholder='Team name'
                                                            onChange={(e) => this.setState({ name: e.target.value })}
                                                            className="multisteps-form__input form-control form-control-mv"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="dropdown mt-3"
                                                    >
                                                        <div className="m-0 btn btn-mv w-100 srkw bg-gradient-dark dropdown-toggle dropdown-toggle-mv"

                                                            onClick={(e) =>
                                                                document.getElementById("assignstation").style.display === 'block' ?
                                                                    document.getElementById("assignstation").style.display = 'none' :
                                                                    document.getElementById("assignstation").style.display = 'block'
                                                            }
                                                            data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                            Assign Station
                                                        </div>
                                                        <ul className="dropdown-menu w-100 srkw-mv srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                            style={{ display: 'none' }}
                                                        >
                                                        </ul>
                                                    </div>
                                                    <div className="card" id='assignstation' style={{ display: 'none' }}>
                                                        <div className="card-header">
                                                            <h5>Assign Station</h5>
                                                        </div>

                                                        <div className="card-body pt-0">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <Multiselect
                                                                            placeholder="Assign State"
                                                                            options={states} // Options to display in the dropdown
                                                                            // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                                            onSelect={this.onStateSelect} // Function will trigger on select event
                                                                            onRemove={this.onStateRemove} // Function will trigger on remove event
                                                                            displayValue="state" // Property name to display in the dropdown options
                                                                            style={{
                                                                                searchBox: { fontSize: "15px" }
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    {this.state.all_cities ?
                                                                        <Multiselect
                                                                            placeholder="Assign District"
                                                                            options={this.state.all_cities} // Options to display in the dropdown
                                                                            onSelect={this.onAssignCitySelect} // Function will trigger on select event
                                                                            onRemove={this.onAssignCityRemove} // Function will trigger on remove event
                                                                            displayValue="station" // Property name to display in the dropdown options
                                                                            style={{
                                                                                searchBox: { fontSize: "15px" }
                                                                            }}

                                                                        />
                                                                        : null}
                                                                </div>
                                                                {/*<div className="col-12 ">*/}
                                                                {/*     <div className="dropdown mt-5">*/}
                                                                {/*         <div  className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"*/}
                                                                {/*            data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">*/}
                                                                {/*             Assign Area*/}
                                                                {/*         </div>*/}
                                                                {/*         <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"*/}
                                                                {/*             >*/}
                                                                {/*             <p className="mb-0 text-sm">*/}
                                                                {/*               Tell us where are you living*/}
                                                                {/*             </p>*/}

                                                                {/*             <div className="google_map_class">*/}
                                                                {/*                 <GoogleMap parentCallback = {this.handleCallback} />*/}
                                                                {/*             </div>*/}
                                                                {/*         </ul>*/}
                                                                {/*     </div>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <label className="form-label">
                                                            Heads
                                                        </label>
                                                        <Multiselect

                                                            placeholder="Select Team Heads"
                                                            options={this.state.headOptions} // Options to display in the dropdown
                                                            // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                            onSelect={this.onHeadSelect} // Function will trigger on select event
                                                            onRemove={this.onHeadRemove} // Function will trigger on remove event
                                                            displayValue="first_name" // Property name to display in the dropdown options
                                                            style={{
                                                                searchBox: { fontSize: "15px" }
                                                            }}

                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="form-label">
                                                            Members
                                                        </label>
                                                        <Multiselect
                                                            placeholder="Select Team Members"
                                                            options={this.state.memberOptions} // Options to display in the dropdown
                                                            // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                            onSelect={this.onMemberSelect} // Function will trigger on select event
                                                            onRemove={this.onMemberRemove} // Function will trigger on remove event
                                                            displayValue="first_name" // Property name to display in the dropdown options
                                                            style={{
                                                                searchBox: { fontSize: "15px" }
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">

                                                </div>

                                                <div className="mt-3">
                                                    <label htmlFor="formFileLg" className="form-label">
                                                        Upload Team Logo
                                                    </label>
                                                    <input className="form-control form-control-lg form-control-mv" id="formFileLg"
                                                        onChange={this.handleFileSelected}
                                                        style={{ color: 'red', backgroundColor: '#e5e5e5' }}
                                                        type="file"
                                                    />
                                                </div>
                                            </div>
                                                    <Modal
                                                        aria-labelledby="transition-modal-title"
                                                        aria-describedby="transition-modal-description"
                                                        open={this.state.open}
                                                        onClose={(e)=>this.setState({open: false})}
                                                        closeAfterTransition
                                                        BackdropComponent={Backdrop}
                                                        BackdropProps={{
                                                          timeout: 500,
                                                        }}
                                                      >
                                                        <Fade in={this.state.open}>
                                                          <Box sx={modalStyle}>
                                                            {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                                            {/*  Text in a modal*/}
                                                            {/*</Typography>*/}
                                                            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                                <div className="modal-body">
                                                                    <div className="circle__box">
                                                                        <div className="circle__wrapper circle__wrapper--right">
                                                                            <div className="circle__whole circle__right"></div>
                                                                        </div>
                                                                        <div className="circle__wrapper circle__wrapper--left">
                                                                            <div className="circle__whole circle__left"></div>
                                                                        </div>
                                                                        <div className="circle__checkmark">
                                                                        </div>
                                                                    </div>
                                                                    <div style={{textAlign: 'center'}}>
                                                                        <h5>Team Created Successfully.</h5>
                                                                        <h6><Link to='/team' style={{color: "grey"}}>Click here </Link> for go to teams dashboard.</h6>
                                                                    </div>
                                                                </div>
                                                            </Typography>
                                                          </Box>
                                                        </Fade>
                                                    </Modal>

                                            {this.state.image ?
                                                <div className="image-area mt-4">
                                                    <img
                                                        src={this.state.image}
                                                        alt="Preview" />
                                                    <div className="remove-image"
                                                        onClick={() => this.setState({ image: null })}
                                                        style={{ display: "inline" }}
                                                    >&#215;
                                                    </div>
                                                </div>
                                                : null}
                                            <div className="row my-4">
                                                <div className="col-12 mt-4">
                                                    <button className="btn bg-gradient-dark d-block ms-auto"
                                                        onClick={this.submit}
                                                    >Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Redirect to={'/sign-in'} />
                }
            </>

        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam);
