import React from "react";
import {axios, sideBar, userLogin} from "../../../store/actions/actions";
import {connect} from "react-redux";

class DataCards extends React.Component {

    state = {
        all_emp: null,
        manager_emp: null,
        admin_emp: null,
        executive_emp: null,

        new_emp: null,
        new_admin: null,
        new_executive: null,
        new_manager: null
    }

    componentDidMount() {
        this.loadDataCardsWithAxios()
        this.toeknExpiryCheck()
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

    loadDataCardsWithAxios = () => {
        let endpoint = `employee/data-cards`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res)=>{
                let responseData = res.data;
                // console.log({'response data---------':responseData})
                this.setState({all_emp: responseData.all_emp})
                this.setState({admin_emp: responseData.admin_emp})
                this.setState({manager_emp: responseData.manager_emp})
                this.setState({executive_emp: responseData.executive_emp})
                this.setState({new_executive: responseData.new_executive})
                this.setState({new_manager: responseData.new_manager})
                this.setState({new_admin: responseData.new_admin})
                this.setState({new_emp: responseData.new_emp})
            })
    }

    render() {
        return(
            <>
            {this.props.data?
                <>
                    <div className='d-none d-lg-block '>
                    <div className="row mt-3">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">weekend</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">All Visit</p>
                                        <h4 className="mb-0">{this.props.data.all_visit}</h4>
                                    </div>
                                </div>
                                <hr className="dark horizontal my-0" />
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.props.data.new_visit} </span>
                                        visit's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 mt-sm-0 mt-4">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">leaderboard</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">School Visits</p>
                                        <h4 className="mb-0">{this.props.data.school_visit}</h4>
                                    </div>
                                </div>
                                <hr className="dark horizontal my-0" />
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.props.data.new_school_visit} </span>
                                        new school visit's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4">
                            <div className="card mb-2">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">store</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Seller Visits</p>
                                        <h4 className="mb-0">{this.props.data.seller_visit}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark" />
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.props.data.new_seller_visit} </span>
                                        new seller visit's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>


                    <div className="d-lg-none row mt-3">
                        <div className="col-12">
                            <div className="card mb-5">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">weekend</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">All Visit</p>
                                        <h4 className="mb-0">{this.props.data.all_visit}</h4>
                                    </div>
                                </div>
                                <hr className="dark horizontal my-0" />
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.props.data.new_visit} </span>
                                        visit's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card mb-5">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">leaderboard</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">School Visits</p>
                                        <h4 className="mb-0">{this.props.data.school_visit}</h4>
                                    </div>
                                </div>
                                <hr className="dark horizontal my-0" />
                                <div className="card-footer p-3">
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.props.data.new_school_visit} </span>
                                        new school visit's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card mb-5">
                                <div className="card-header p-3 pt-2 bg-transparent">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">store</i>
                                    </div>
                                    <div className="text-end pt-1">
                                        <p className="text-sm mb-0 text-capitalize">Seller Visits</p>
                                        <h4 className="mb-0">{this.props.data.seller_visit}</h4>
                                    </div>
                                </div>
                                <hr className="horizontal my-0 dark" />
                                <div className="card-footer p-3 kv-wrap" >
                                    <p className="mb-0">
                                        <span className="text-success text-sm font-weight-bolder">+{this.props.data.new_seller_visit} </span>
                                        new seller visit's added this month
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :null}
            </>
        )
    }
}

const mapStateToProps = state =>({
    user:state.userData.userData,
    sidebar:state.sideBarData.sidebarData.sidebar
})

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
    sideBarHandler: data => dispatch(sideBar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DataCards)