import React from "react";
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import { axios, modalStyle, url } from "../../../store/actions/actions";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import LatLng from "../../GoogleMap/getLatLng";
import Loadingfade from "../../common/Loadingfade";

let dates = new Date();
let mnth = ("0" + (dates.getMonth() + 1)).slice(-2);
let day = ("0" + dates.getDate()).slice(-2);
let abc = [dates.getFullYear(), mnth, day].join("-");
class ScheduledVisitDataTable extends React.Component {
  state = {
    visits: null,
    filterVisits: null,
    pages: null,
    pageItems: null,

    open: false,
    msg: null,
    data: 1,

    reschedule_date: abc,
    show_reschedule_date: undefined,
    reschedule_time: null,
    show_reschedule_time: undefined,
    delete_id: null,

    reschedule: false,
    rescheduleObj: null,

    latLng: null,
    type: null,
    todayDate: abc,
  };

  latLng = null;

  getLatLng = (data) => {
    this.setState({
      scheduledVisits: null,
      filterScheduledVisits: null,
    });

    this.latLng = data;
    this.setState({ latLng: data });
    this.loadSVisits(data);
  };

  loadSVisits = (location) => {
    const data = {
      lat: this.state.latLng.lat ? this.state.latLng.lat : location.lat,
      lng: this.state.latLng.lng ? this.state.latLng.lng : location.lng,
      type: this.state.type,
    };

    // let endpoint = `visit/scheduled-visit-datatable`;
    let endpoint = "common/home-scheduled-visit";
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      console.log({
        "Scheduled Visit response data Sharukh----------------->": responseData,
      });
      const objWithIndex = responseData.visits.map((obj, index) =>
        Object.assign(obj, { index: index + 1 })
      );
      this.setState({
        data: responseData.visits.length,
        visits: objWithIndex,
        filterVisits: objWithIndex.slice(0, 10).map((i) => {
          return i;
        }),
        pages: Math.ceil(objWithIndex.length / 10),
      });
    });
  };

  handelPagination = (event, value) => {
    let start = 10 * (value - 1);
    let end = 10 * value;
    let items = this.state.visits.slice(start, end).map((i) => {
      return i;
    });

    this.setState({
      filterVisits: items,
    });
    console.log("items", items);
  };

  delete = (id) => {
    this.setState({
      open: true,
      msg: "Are you sure to delete this scheduled visit.",
      delete_id: id,
    });
  };

  delete2 = (id) => {
    this.setState({
      open: false,
      msg: null,
    });
    let endpoint = `visit/scheduled-visit?id=${this.state.delete_id}`;
    let token = this.props.user.token;

    axios.delete(endpoint, token).then((response) => {
      const responseData = response.data;
      console.log({ "Delete response----------------->": responseData });
      if (responseData.success === true) {
        this.loadSVisits();
        this.setState({ delete_id: null });
      }
    });
  };

  reschedule = (obj) => {
    let dates = new Date();
    let mnth = ("0" + (dates.getMonth() + 1)).slice(-2);
    let day = ("0" + dates.getDate()).slice(-2);
    let abc = [dates.getFullYear(), mnth, day].join("-");
    console.log(abc, "Aaj ka Hai.", mnth, day);

    this.setState({
      msg: null,
      submit: false,
      rescheduleObj: obj,
      reschedule: true,
    });
  };

  dateFormat = (dateType, date) => {
    var date = new Date(date),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    if (dateType === "start_date") {
      this.setState({
        show_reschedule_date: [date.getFullYear(), mnth, day].join("-"),
        reschedule_date: [date.getFullYear(), mnth, day].join("-"),
      });
    }
    console.log([date.getFullYear(), mnth, day].join("-"));
  };

  timeFormat = (timeType, time) => {
    if (timeType === "start_time") {
      this.setState({
        reschedule_time: `${time.$H}:${time.$m}`,
        show_reschedule_time: time,
      });
    }
    console.log(time);
    console.log(`${time.$H}:${time.$m}`);
  };

  checkIn = (obj) => {
    const data = {
      customer: obj.customer_id,
      s_visit: obj.id,
      date: new Date().toISOString().slice(0, 10),
    };

    let endpoint = `trip/`;
    let token = this.props.user.token;

    console.log(data, "-=-=-=-=-=-=")
    
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log(responseData, "Trip Response")
      if (responseData.success === true) {
        alert("Before")
        this.loadSVisits();
        alert("After")
      } else {
        this.setState({
          msg: responseData.msg,
          open: true,
        });
      }
      console.log({ "response data---------": responseData });
    });
  };

  submit = () => {
    const data = {
      id: this.state.rescheduleObj.id,
      date: this.state.reschedule_date,
      time: this.state.reschedule_time,
    };

    console.log(data.date, "Date Print Kiya hu Bhai.");

    let endpoint = `visit/scheduled-visit`;
    let token = this.props.user.token;

    axios.put(endpoint, data, token).then((response) => {
      const responseData = response.data;
      console.log({ "Update Schedule Visit----------------->": responseData });
      if (responseData.success === true) {
        this.loadSVisits();
        this.setState({
          reschedule_date: null,
          show_reschedule_date: undefined,
          reschedule_time: null,
          show_reschedule_time: undefined,
          rescheduleObj: null,
          msg: "Reschedule Successful",
          submit: true,
        });
      } else {
        this.setState({
          rescheduleObj: null,
          msg: responseData.msg,
          submit: false,
          reschedule: false,
          open: true,
          reschedule_date: null,
          show_reschedule_date: undefined,
          reschedule_time: null,
          show_reschedule_time: undefined,
        });
      }
    });
  };

  render() {
    return (
      <>
        {this.latLng === null ? <LatLng getLatLng={this.getLatLng} /> : null}

        <div className="mb-3 row" style={{ overflowX: "hidden" }} id="clients">
          <div className="col-12">
            <div className="card py-3">
              <div className="table-responsive px-2">
                <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                  <div className="dataTable-top">
                    <h5 className="mb-0 ps-3">Scheduled Visits</h5>
                  </div>
                  <div className="dataTable-container" id="employeeTable">
                    {this.state.data !== 0 ? (
                      <>
                        <table className="table align-items-center mb-3 dataTable-table">
                          <thead>
                            <tr>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "4.8032%", cursor: "pointer" }}
                              >
                                <a
                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                  className="dataTable-sorter"
                                  onClick={(e) =>
                                    this.sortEmpTable("serial_num")
                                  }
                                >
                                  S.NO
                                </a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("name")}
                              >
                                <a className="dataTable-sorter">Schedule by</a>
                              </th>

                              {/* Schedule To  */}
                              {this.props.user.employee_profile ===
                              "coordinator" || this.props.user.employee_profile ===
                              "manager" ? (
                                <th
                                  className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                  data-sortable=""
                                  style={{ width: "20%", cursor: "pointer" }}
                                  onClick={(e) => this.sortEmpTable("name")}
                                >
                                  <a className="dataTable-sorter">
                                    Schedule to
                                  </a>
                                </th>
                              ) : null}

                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("name")}
                              >
                                <a className="dataTable-sorter">Customer</a>
                              </th>
                              {/* <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("name")}
                              >
                                <a className="dataTable-sorter">Schedule For</a>
                              </th> */}
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("name")}
                              >
                                <a className="dataTable-sorter">Type</a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("name")}
                              >
                                <a className="dataTable-sorter">Date</a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("status")}
                              >
                                <a className="dataTable-sorter">Time</a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("status")}
                              >
                                <a className="dataTable-sorter">Status</a>
                              </th>
                              <th
                                className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                data-sortable=""
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={(e) => this.sortEmpTable("status")}
                              >
                                <a className="dataTable-sorter">Action</a>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*{this.props.visit.map((item, index)=>(*/}
                            {this.state.filterVisits ? (
                              this.state.filterVisits.map((item, index) => (
                                <tr>
                                  <td>
                                    {/*<Link to={'/visit/visit-view/'+item.id}>*/}
                                    <div className="d-flex px-2 py-1">
                                      <div className="d-flex flex-column justify-content-center">
                                        <h6 className="mb-0 font-weight-normal text-sm">
                                          {item.index}
                                        </h6>
                                      </div>
                                    </div>
                                    {/*</Link>*/}
                                  </td>
                                  <td>
                                    {/*<Link to={'/visit/visit-view/'+item.id}>*/}
                                    <div className="d-flex align-items-center">
                                      <h6 className="mb-0 font-weight-normal text-sm">
                                        {item.scheduled_by__last_name
                                          ? item.scheduled_by__first_name +
                                            " " +
                                            item.scheduled_by__last_name
                                          : item.scheduled_by__first_name}
                                      </h6>
                                    </div>
                                    {/*</Link>*/}
                                  </td>

                                  {/* Schedule To  */}
                                  {this.props.user.employee_profile ===
                                  "coordinator" || this.props.user.employee_profile ===
                                  "manager" ? (
                                    <td
                                      className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                      data-sortable=""
                                      style={{
                                        width: "20%",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => this.sortEmpTable("name")}
                                    >
                                      <a className="dataTable-sorter">
                                        {item.scheduled_for__first_name}
                                      </a>
                                    </td>
                                  ) : null}

                                  <td>
                                    {/*<Link to={'/visit/visit-view/'+item.id}>*/}
                                    <h6 className="mb-0 font-weight-normal text-sm">
                                      {item.customer__company}
                                    </h6>
                                    {/*</Link>*/}
                                  </td>
                                  <td>
                                    <h6 className="mb-0 font-weight-normal text-sm">
                                      {item.customer__type}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 className="mb-0 font-weight-normal text-sm">
                                      {item.schedule_date}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 className="mb-0 font-weight-normal text-sm">
                                      {item.schedule_time}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 className="mb-0 font-weight-normal text-sm">
                                      {item.status}
                                    </h6>
                                  </td>
                                  <td className="align-middle text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                      {this.props.user.employee_full_name ===
                                        item.employee__first_name +
                                          " " +
                                          item.employee__last_name ||
                                      this.props.user.employee_profile ===
                                        "admin" ||
                                      this.props.user.employee_profile ===
                                        "coordinator" ? (
                                        <>
                                          {/*<Link to={"/tour/edit/" + tr.id}>*/}
                                          <ModeEditIcon
                                            onClick={(e) =>
                                              this.reschedule(item)
                                            }
                                            style={{
                                              color: "#528dde",
                                              margin: "0px 20px 0px 0px",
                                            }}
                                          />
                                          {/*</Link>*/}
                                          {/*<div onClick={(e) => this.delete(item.id)} >*/}
                                          <DeleteIcon
                                            onClick={(e) =>
                                              this.delete(item.id)
                                            }
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                          />
                                          {/*</div>*/}
                                        </>
                                      ) : (
                                        <div className="f-btn sv-f-btn">
                                          {item.status === "Completed" ? (
                                            item.status
                                          ) : item.reschedule === true ? (
                                            <button
                                              onClick={(e) =>
                                                this.reschedule(item)
                                              }
                                            >
                                              Reschedule
                                            </button>
                                          ) : item.check_in === true ? (
                                            <button
                                              onClick={(e) =>
                                                this.checkIn(item)
                                              }
                                              style={{background:"rgb(117, 225, 65)"}}
                                            >
                                              Check In
                                            </button>
                                          ) : (
                                            <>
                                              <button
                                                onClick={(e) =>
                                                  this.setState({
                                                    open: true,
                                                    msg: "You are not in the Check-In area.",
                                                  })
                                                }
                                              >
                                                Check In
                                              </button>
                                            </>
                                          )}
                                        </div>

                                        // <div className="f-btn sv-f-btn">
                                        //   {item.status === "Completed" ? (
                                        //     item.status
                                        //   ) : item.check_in === true ? (
                                        //     <button
                                        //       style={{ background: "#75e141" }}
                                        //       onClick={(e) =>
                                        //         this.checkIn(item)
                                        //       }
                                        //     >
                                        //       Check In
                                        //     </button>
                                        //   ) : item.reschedule === false ? (
                                        //     <button
                                        //       onClick={(e) =>
                                        //         this.checkIn(item)
                                        //       }
                                        //     >
                                        //       Check In
                                        //     </button>
                                        //   ) : (
                                        //     <button
                                        //     style={{ background: "#75e141" }}
                                        //     onClick={(e) =>
                                        //       this.checkIn(item)
                                        //     }
                                        //   >
                                        //     Reschedule
                                        //   </button>
                                        //   )}
                                        // </div>

                                        // <div className="f-btn sv-f-btn">
                                        //   {item.status === "Completed" ? (
                                        //     item.status
                                        //   ) : item.check_in === true ? (
                                        //     <button
                                        //       style={{ background: "#75e141" }}
                                        //       onClick={(e) =>
                                        //         this.checkIn(item)
                                        //       }
                                        //     >
                                        //       Check In
                                        //     </button>
                                        //   ) : (
                                        //     {item.reschedule === false ? (

                                        //     ):()}
                                        //   )}
                                        // </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan={9}>
                                    <Loadingfade
                                      spacing="1"
                                      variant="wave"
                                      height="20px"
                                      style={{ borderRadius: "30px" }}
                                      width="100%"
                                    />
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                        {this.state.filterVisits ? (
                          this.state.pages === 1 ? null : (
                            <div className="datatable-mui-pagination">
                              <Pagination
                                count={this.state.pages}
                                color="secondary"
                                onChange={this.handelPagination}
                                size="large"
                              />
                            </div>
                          )
                        ) : null}
                      </>
                    ) : (
                      <h4 style={{ textAlign: "center", color: "#bbb1b1" }}>
                        No Scheduled Visits
                      </h4>
                    )}
                  </div>

                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.reschedule}
                    onClose={(e) => this.setState({ reschedule: false })}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={this.state.reschedule}>
                      <Box sx={modalStyle}>
                        {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                        {/*  Text in a modal*/}
                        {/*</Typography>*/}
                        <Typography
                          id="transition-modal-description"
                          sx={{ mt: 2 }}
                        >
                          <div className="modal-body">
                            {this.state.rescheduleObj ? (
                              <>
                                <div className="row">
                                  <div className="col-8 mt-2">
                                    <h4>
                                      {
                                        this.state.rescheduleObj
                                          .customer__company
                                      }
                                    </h4>
                                  </div>
                                  <div
                                    className="col-6 mt-4 schedule-visit"
                                    style={{ paddingLeft: "25px" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <MobileDatePicker
                                        label="Start Date"
                                        value={this.state.show_reschedule_date}
                                        onChange={(e) =>
                                          this.dateFormat("start_date", e.$d)
                                        }
                                        // onChange={(e) => console.log(e)}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                  <div
                                    className="col-6 mt-4 schedule-visit"
                                    style={{ paddingRight: "25px" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <MobileTimePicker
                                        label="Start Time"
                                        value={this.state.show_reschedule_time}
                                        onChange={(e) =>
                                          this.timeFormat("start_time", e)
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>

                                <div className="row mt-4">
                                  <div className="col-12 mb-4">
                                    <button
                                      className="btn bg-gradient-dark d-block m-auto"
                                      onClick={(e) => this.submit()}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            {this.state.submit ? (
                              <>
                                <div className="circle__box">
                                  <div className="circle__wrapper circle__wrapper--right">
                                    <div className="circle__whole circle__right"></div>
                                  </div>
                                  <div className="circle__wrapper circle__wrapper--left">
                                    <div className="circle__whole circle__left"></div>
                                  </div>
                                  <div className="circle__checkmark"></div>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <h5>{this.state.msg}</h5>
                                  <h6>
                                    <Link to="/" style={{ color: "grey" }}>
                                      Click here
                                    </Link>
                                    &nbsp;for go to HomePage.
                                  </h6>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={(e) => this.setState({ open: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Box sx={modalStyle}>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <div className="modal-body">
                  <div style={{ textAlign: "center" }}>
                    <h5>{this.state.msg}</h5>
                    <button
                      type="button"
                      className="btn text-center btn-danger"
                      style={{ background: "#acadb1" }}
                      onClick={(e) => this.setState({ open: false })}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn d-none btn-danger"
                      style={{ float: "right" }}
                      onClick={this.delete2}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(ScheduledVisitDataTable);
