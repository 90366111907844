import React from "react";
import {connect} from "react-redux";
import {axios, url, userLogin} from "../../../store/actions/actions";
import '../../common/common.css'


class EmpDataTable extends React.Component {

    state = {
        questionnaire: null,
        survey: null,
        sampling: null,
        closure: null,
        followup: null,
        payment: null,

        purposeArray: []
    }

    componentDidMount() {
        this.loadQuestionnaire()
    }

    purArray = []

    loadQuestionnaire = () => {
        let endpoint = `visit/get-questionnaire`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                let survey = []
                let sampling = []
                let closure = []
                let followup = []
                let payment = []

                responseData.questionnaire.map((item)=>(item.status.choice==='SURVEY'?survey.push(item):null))
                responseData.questionnaire.map((item)=>(item.status.choice==='SAMPLING'?sampling.push(item):null))
                responseData.questionnaire.map((item)=>(item.status.choice==='CLOSURE'?closure.push(item):null))
                responseData.questionnaire.map((item)=>(item.status.choice==='FOLLOWUP'?followup.push(item):null))
                responseData.questionnaire.map((item)=>(item.status.choice==='PAYMENT'?payment.push(item):null))

                this.setState({
                    questionnaire: responseData.questionnaire,
                    survey: survey,
                    sampling: sampling,
                    closure: closure,
                    followup: followup,
                    payment: payment
                })

                console.log({'questionnaire response data---------':responseData})
            });
    }

    getPurpose = (data) => {
        if (this.purArray.includes(data)===true){
            this.removePurpose(data)
        } else {
            this.purArray.push(data)
            console.log(this.purArray)
            this.setState({
               purposeArray: this.purArray
            })
            this.props.parentCallback(this.purArray)
        }
    }

    removePurpose = (data) => {
       let arr = this.purArray.filter(function(item) {
            return item !== data
        })
        this.purArray = arr
        console.log(this.purArray)
        this.setState({
           purposeArray: arr
        })
        this.props.parentCallback(this.purArray)
    }

  render() {
    return (
      <>
        <div className="d-none d-lg-block row my-4 mb-5" id="clients">
              <div className="col-12">
                  <div className="card py-3">
                      <div className="table-responsive px-2">
                          <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                              <div className="dataTable-container"
                                   // style={{height: "580.641px"}}
                              >
                                  <table className="table align-items-center mb-0 dataTable-table" id="empe">
                                      <thead>

                                          <tr>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter">Survey
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Sampling
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}

                                                  >
                                                  <a
                                                  className="dataTable-sorter ">
                                                  Closure</a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Follow Up
                                              </a></th>

                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Payment
                                              </a></th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                              <tr>
                                                  <td>
                                                      {this.state.survey?this.state.survey.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.option}
                                                        </h6>
                                                      <br/>
                                                      </div>
                                                      )):null}
                                                  </td>
                                                  <td>
                                                  {this.state.sampling?this.state.sampling.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                                {item.option}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                  )):null}
                                                  </td>
                                                  <td>
                                                  {this.state.closure?this.state.closure.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.option}
                                                        </h6>
                                                      <br/>
                                                      </div>
                                                  )):null}
                                                  </td>
                                                  <td>
                                                      {this.state.followup?this.state.followup.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                                {item.option}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                      )):null}
                                                  </td>
                                                  <td>
                                                  {this.state.payment?this.state.payment.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                                {item.option}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                  )):null}
                                                  </td>
                                              </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>

        <div className="d-lg-none row mb-5" id="clients">
              <div className="col-12">
                  <div className="card py-3">
                      <div className="table-responsive px-2">
                          <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                              <div className="dataTable-container"
                                   // style={{height: "580.641px"}}
                              >
                                  <table className="table align-items-center mb-0 dataTable-table kv-table" id="empe">
                                      <thead>

                                          <tr>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter">Survey
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Sampling
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "15%", cursor: "pointer"}}

                                                  >
                                                  <a
                                                  className="dataTable-sorter ">
                                                  Closure</a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Follow Up
                                              </a></th>

                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}

                                              ><a
                                                  className="dataTable-sorter ">Payment
                                              </a></th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                              <tr>
                                                  <td>
                                                      {this.state.survey?this.state.survey.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.option}
                                                        </h6>
                                                      <br/>
                                                      </div>
                                                      )):null}
                                                  </td>
                                                  <td>
                                                  {this.state.sampling?this.state.sampling.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                                {item.option}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                  )):null}
                                                  </td>
                                                  <td>
                                                  {this.state.closure?this.state.closure.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                        <h6 className="mb-0 font-weight-normal text-sm">
                                                            {item.option}
                                                        </h6>
                                                      <br/>
                                                      </div>
                                                  )):null}
                                                  </td>
                                                  <td>
                                                      {this.state.followup?this.state.followup.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                                {item.option}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                      )):null}
                                                  </td>
                                                  <td>
                                                  {this.state.payment?this.state.payment.map((item)=>(
                                                      <div className={this.state.purposeArray.includes(item.option)?"align-items-center kv-fill kv-full":"align-items-center kv-fill"}
                                                         onClick={(e)=>this.getPurpose(item.option)}
                                                      >
                                                            <h6 className="mb-0 font-weight-normal text-sm">
                                                                {item.option}
                                                            </h6>
                                                      <br/>
                                                      </div>
                                                  )):null}
                                                  </td>
                                              </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
    user:state.userData.userData
});

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmpDataTable);

