import React from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import PageNotFound from "../../common/PageNotFound";
import {Detector, Offline, Online} from "react-detect-offline";
import InternetConnection from "../../common/InternetConnection";
import Select from "react-select";
import {axios} from "../../../store/actions/actions";


class TourFundRequest extends React.Component {

  state = {
    teams: null,
    team_id: null,
    team_balance: null,
    amount: null,
    remark_text: null,
    remark_file: null,

    redirect: false
  }

  componentDidMount() {
    this.loadEmployee()
  }

  loadEmployee = () => {
    let endpoint = `teams/get-employee-team`
    let token = this.props.user.token

    axios
      .get(endpoint, token)
      .then((res)=>{
        let responseData = res.data;
        this.setState({
          teams: responseData.team,
          redirect: false
        })
        console.log({'Team response data---------':responseData.team})
      })
    }

    teamBalance = (team) => {
      this.setState({
          team_id: team.id,
          team_balance: team.balance
      })
    }

    submitRemark = (id) => {
        let data = new FormData();
        data.append("remark_id", id);
        data.append("remark_text", this.state.remark_text);
        data.append("remark_file", this.state.remark_file);

        let endpoint = `teams/team-account`
        let token = this.props.user.token

        axios
          .post(endpoint, data, token)
          .then((res)=>{
            let responseData = res.data;
            if (responseData.success!==false){
                this.submitRemark(responseData.id)
            }
            console.log({'response data---------':responseData})
          })
    }

    submit = () => {
      const data = {
          team_id: this.state.team_id,
          amount: this.state.amount,
          remark_text: this.state.remark_text
      }

      let endpoint = `account/tar`
      let token = this.props.user.token

     axios
      .post(endpoint, data, token)
      .then((res)=>{
        let responseData = res.data;
        if (responseData.success===true){
            this.setState({redirect: true})
        }
        console.log({'response data---------':responseData})
      })
    }


  render() {
    return (
            <>
            {this.props.user.login===true?
                <>
                    <Online>
                    {this.props.user.login?
                      <>
                        {this.state.redirect===true?
                            <Redirect to={'/tour'}/>
                        :null}
                        <div className="container-fluid d-none d-lg-block">
                        <div className="row justify-content-end">
                          <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-10" : "col-12"}>
                            <div className="col-md-10 d-block m-auto mt-lg-0 mt-4">
                            {/*// <!-- Card Basic Info -->*/}
                              <div className="card mt-4 mb-4" id="basic-info">
                                <div className="card-header">
                                  <h5>Tour Advance Request</h5>
                                </div>
                                <div className="card-body pt-0">
                                  <div className="row">
                                    <div className="row col-12">
                                      <div className="col-6">
                                        {this.state.teams?
                                          <Select
                                                placeholder="Select Team"

                                                options={this.state.teams.map((team, index)=>(
                                                    {value: team, label: team.name}
                                                ))}

                                                onChange={(e)=>this.teamBalance(e.value)}
                                          />
                                        :null}
                                      </div>
                                      {this.state.team_balance?
                                      <div className="col-6">
                                        <label>Current Balance</label>
                                        <h6>{this.state.team_balance}</h6>
                                      </div>
                                      :null}
                                    </div>
                                    <div className="col-6 mt-3">
                                      <div className="input-group input-group-static">
                                        <label>Enter Amount</label>
                                        <input type="text" className="form-control"
                                        onChange={(e) => this.setState({amount: e.target.value})}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <div className="input-group input-group-static">
                                        <label>Remarks</label>
                                        <input type="text" className="form-control"
                                        onChange={(e) => this.setState({remark_text: e.target.value})}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                   <button onClick={this.submit} className="btn bg-gradient-dark btn-md mt-6">
                                       Submit
                                   </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-lg-none justify-content-end px-2">
                        <div className="dashOption-mv mt-4">
                                <div className="">
                                    <div className="button-bar-mv d-flex">
                                        <Link to='/tour' className="btn btn-mv "><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                        <Link to="/tour/request" className="btn btn-mv active"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Request</Link>
                                    </div>
                                </div>
                        </div>
                        <div className="col-12 d-block">
                        {/*// <!-- Card Basic Info -->*/}
                          <div className="card mt-4 mb-4" id="basic-info">
                            <div className="card-header">
                              <h5>Tour Advance Request</h5>
                            </div>
                            <div className="card-body pt-0 d-flex justify-content-end flex-column">
                              <div className="row">
                                <div className="row col-12">
                                  <div className="col-12">
                                    {this.state.teams?
                                      <Select
                                            placeholder="Select Team"

                                            options={this.state.teams.map((team, index)=>(
                                                {value: team, label: team.name}
                                            ))}

                                            onChange={(e)=>this.teamBalance(e.value)}
                                      />
                                    :null}
                                  </div>
                                  {this.state.team_balance?
                                  <div className="col-12 mt-3">
                                    <label>Current Balance</label>
                                    <h6 className="ps-3">&#x20b9; {this.state.team_balance}</h6>
                                  </div>
                                  :null}
                                </div>
                                <div className="col-12 mt-3">
                                  <div className="input-group input-group-static">
                                    <label>Enter Amount</label>
                                    <input type="text" className="form-control form-control-mv"
                                    onChange={(e) => this.setState({amount: e.target.value})}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 mt-3">
                                  <div className="input-group input-group-static">
                                    <label>Remarks</label>
                                    <input type="text" className="form-control form-control-mv"
                                    onChange={(e) => this.setState({remark_text: e.target.value})}
                                    />
                                  </div>
                                </div>
                              </div>

                               <button onClick={this.submit} className="btn bg-gradient-dark btn-md mt-6 ms-auto">
                                   Submit
                               </button>
                            </div>
                          </div>
                        </div>
                    </div>
                      </>
                    :
                        <PageNotFound/>
                    }
                    </Online>
                    <Offline>
                        <InternetConnection/>
                    </Offline>
                </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>
    );
  }
}

const mapStateToProps = (state) => ({
  user:state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(TourFundRequest);
