import React from "react";
import {axios} from "../../../store/actions/actions";
import {connect} from "react-redux";

class SalesTable extends React.Component {

    state = {
        data: null,
        data1: null,
    }

    componentDidMount() {
        this.loadSalesTableWithAxios()
    }

    loadSalesTableWithAxios = () => {
        let endpoint = `employee/sales-table`
        let token = this.props.user.token
        axios
            .get(endpoint, token)
            .then((res)=>{
                let responseData = res.data;
                console.log({'Sales table response data---------':responseData.state})
                this.setState({data: responseData.state})
            })
    }

    render() {
        return(
            <>


                <div className="d-lg-none sales-mv mt-3">
                    <div className="">
                        <div className="sales-box-mv">
                            <div className="head d-flex align-items-center">
                                <img style={{background: "#64A459"}} className="panel-icon" src={require("../../../kavi/icons/web.png")} alt="icon"/>
                                    <p className="m-0 ms-4" style={{fontWeight: "bold", fontSize: "16px"}}>Sales by
                                        State</p>
                            </div>
                            <div className="sales-table-mv">
                                <table width="100%">
                                    <thead>
                                    <tr>
                                        <th style={{textAlign: "left", paddingLeft: "2px"}}>State:</th>
                                        <th>Stations:</th>
                                        <th>Customers:</th>
                                        <th>Visits:</th>
                                        <th>Employee:</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        {this.state.data ? this.state.data.map((item, i) => (
                                            <>
                                            <td style={{textAlign: "left", paddingLeft: "2px"}}>{item.state}</td>
                                            <td>{item.station}</td>
                                            <td>{item.customer}</td>
                                            <td>{item.visit}</td>
                                            <td>{item.employee}</td>
                                            </>
                                        )):null}
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-none d-lg-block row mt-4">
                    <div className="col-lg-12 position-relative z-index-2">
                        <div className="card mb-4">
                            <div className="d-flex">
                                <div
                                    className="icon icon-shape icon-lg bg-gradient-success shadow text-center border-radius-xl mt-n3 ms-4">
                                    <i className="material-icons opacity-10" aria-hidden="true">language</i>
                                </div>
                                <h6 className="mt-3 mb-2 ms-3">Sales by State</h6>
                            </div>
                            <div className="card-body p-3">
                                <div className="row">
                                    <div className="col-lg-6 col-md-7">
                                        <div className="table-responsive">
                                            <table className="table align-items-center">
                                                <tbody>
                                                    {this.state.data ? this.state.data.map((item, i) => (
                                                    <tr>
                                                        <td className="w-30">
                                                            <div className="d-flex px-2 py-1 align-items-center">
                                                                {/*<div>*/}
                                                                {/*    <img src="../../../custom/img/icons/flags/US.png"*/}
                                                                {/*         alt="Country flag"/>*/}
                                                                {/*</div>*/}
                                                                <div className="ms-4">
                                                                    <p className="text-xs font-weight-bold mb-0">
                                                                        State:
                                                                    </p>
                                                                    <h6 className="text-sm font-weight-normal mb-0">
                                                                        {item.state}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-center">
                                                                <p className="text-xs font-weight-bold mb-0">
                                                                    Stations:
                                                                </p>
                                                                <h6 className="text-sm font-weight-normal mb-0">
                                                                    {item.station}
                                                                </h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-center">
                                                                <p className="text-xs font-weight-bold mb-0">
                                                                    Customers:
                                                                </p>
                                                                <h6 className="text-sm font-weight-normal mb-0">
                                                                    {item.customer}
                                                                </h6>
                                                            </div>
                                                        </td>
                                                        <td className="align-middle text-sm">
                                                            <div className="col text-center">
                                                                <p className="text-xs font-weight-bold mb-0">
                                                                    Visits:
                                                                </p>
                                                                <h6 className="text-sm font-weight-normal mb-0">
                                                                    {item.visit}
                                                                </h6>
                                                            </div>
                                                        </td>
                                                        <td className="align-middle text-sm">
                                                            <div className="col text-center">
                                                                <p className="text-xs font-weight-bold mb-0">
                                                                    Employee:
                                                                </p>
                                                                <h6 className="text-sm font-weight-normal mb-0">
                                                                    {item.employee}
                                                                </h6>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-5">
                                        <div id="map" className="mt-0 mt-lg-n4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state =>({
    user:state.userData.userData
})

export default connect(mapStateToProps)(SalesTable);