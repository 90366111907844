import React from "react";
import '../Loader/Loader.css'

class Loader extends React.Component {


  render() {
    return (
        <>
            <div className="myloader"></div>
        </>
    );
  }
}


export default Loader;
