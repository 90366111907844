import React from "react";
import {connect} from "react-redux";
import EditTripComp from "./EditTripComp";
import {Link, Redirect} from "react-router-dom";

class EditTrip extends React.Component {

  render() {

    return (
            <>
            {this.props.user.login===true?
                <>

                    <div className="dashOption-mv mt-4 px-2">
                        <div className="">
                            <div className="button-bar-mv d-flex">
                                <Link to='/trip' className="btn btn-mv "><img src={require("../../../kavi/icons/dashbord.png")} alt="btn-icon" />Dashboard</Link>
                                <div className="btn btn-mv active"><img src={require("../../../kavi/icons/add.png")} alt="btn-icon" />Edit Trip</div>
                            </div>
                        </div>
                    </div>
        <div className="row justify-content-end">
            <div className={this.props.sidebar===undefined || this.props.sidebar===true?"col-10":"col-12"}>
                  <EditTripComp token={this.props.user.token}/>
            </div>
        </div>
      </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>
    );
  }
}

const mapStateToProps = (state) => ({
    user:state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(EditTrip);

