import React from 'react'
import Select from 'react-select'

class EmployeeSearch extends React.Component {

    handleChange = (selectedOption) => {
        this.props.parentCallback({'value': [{id: selectedOption.value, f_name: selectedOption.label.split(" ")[0], S_name: selectedOption.label.split(" ")[1]}], "type": this.props.placeholder})
    };

    render(){

    return(
        <>
            {this.props.value === null || this.props.value === undefined ?
                <Select
                    placeholder={this.props.placeholder}

                    options={
                        this.props.employee ? this.props.employee.map((item, index) => (
                            {value: item.id, label: item.first_name + ' ' + item.last_name}
                        )) : null
                    }
                    onChange={this.handleChange}
                />
                 :
                 this.props.value.map((employee, index)=>(
                 <Select
                    placeholder={this.props.placeholder}

                    value={typeof this.props.value==='object'?this.props.value.map((employee, index)=>(
                    {value: employee.id, label: employee.first_name + ' ' + employee.last_name})):null}

                    options={
                        this.props.employee ? this.props.employee.map((item, index) => (
                            {value: item.id, label: item.first_name + ' ' + item.last_name}
                        )) : null
                    }
                    onChange={this.handleChange}
                />
                ))
            }
        </>
    )
  }
}
export default EmployeeSearch;