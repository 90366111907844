import React from "react";
import "../Customer/Style";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import StarRatings from "react-star-ratings/build/star-ratings";
import { axios, sideBar, userLogin } from "../../../store/actions/actions";
import Loader from "../../common/Loader/Loader";
import CustomerVisits from "./CustomerVisits";

class CustomerDetail extends React.Component {
  state = {
    customer: null,
  };

  componentDidMount() {
    this.loadCustomerPageWithAxios();
    this.toeknExpiryCheck();
  }

  toeknExpiryCheck = () => {
    var varDate = new Date(this.props.user.expiry_date);
    var today = new Date();

    if (today >= varDate) {
      window.localStorage.clear();
      this.props.userLoginHandler({ undefined });
    }

    let data = {
      emp_id: this.props.user.employee_id,
    };

    let endpoint = `employee/check-employee-update`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log({ "Update Response data----------": responseData });
      if (responseData.success === true) {
        window.localStorage.clear();
        this.props.userLoginHandler({ undefined });
      }
    });
  };

  loadCustomerPageWithAxios = () => {
    let pathname = window.location.pathname;

    let pathurl = pathname.split("/");
    let path = pathurl[pathurl.length - 1];
    let endpoint = `customer/${path}`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ customer: responseData.customer });
      console.log({ "response data---------": responseData.customer });
    });
  };

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            {this.state.customer ? (
              this.state.customer.map((cust, index) => (
                <div className="container-fluid">
                  <div className="row justify-content-end my-4">
                    <div
                      className={
                        this.props.sidebar === undefined ||
                        this.props.sidebar === true
                          ? "col-md-9 me-md-5"
                          : "col-11 me-5"
                      }
                    >
                      <div className="card">
                        <div className="card-body p-3">
                          <div id="basic-info">
                            <div>
                              <div>
                                <h5 className="font-weight-bolder mb-2">
                                  Customer
                                </h5>
                              </div>
                            </div>

                            {/*-------------------Address------------------*/}
                            <div className="card-body pt-0">
                              <h5>Address</h5>
                              <div className="row mt-3">
                                <div className="col-12 ">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Street Name
                                    </label>
                                    <input
                                      disabled
                                      // placeholder='Street Name'
                                      // onChange={(e) => this.setState({street: e.target.value})}
                                      defaultValue={
                                        cust.address
                                          ? cust.address.map(
                                              (add, index) => add.street
                                            )
                                          : null
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-6 ">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">City</label>
                                    <input
                                      disabled
                                      // placeholder='City'
                                      // onChange={(e) => this.setState({city: e.target.value})}
                                      defaultValue={
                                        cust.address
                                          ? cust.address.map(
                                              (add, index) => add.distt
                                            )
                                          : null
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 ">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Pin Code
                                    </label>
                                    <input
                                      disabled
                                      // placeholder='Pin Code'
                                      // onChange={(e) => this.setState({pin_code: e.target.value})}
                                      defaultValue={
                                        cust.address
                                          ? cust.address.map(
                                              (add, index) => add.pin_code
                                            )
                                          : null
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-12 ">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">State</label>
                                    <input
                                      disabled
                                      // placeholder='State'
                                      // onChange={(e) => this.setState({state: e.target.value})}
                                      defaultValue={
                                        cust.address
                                          ? cust.address.map(
                                              (add, index) => add.state
                                            )
                                          : null
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-6">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Transport Name
                                    </label>
                                    <input
                                      disabled
                                      // placeholder='Transport Name'
                                      // onChange={(e) => this.setState({transport_name: e.target.value})}/
                                      defaultValue={
                                        cust.address
                                          ? cust.address.map(
                                              (add, index) => add.transport_name
                                            )
                                          : null
                                      }
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Nearest Station
                                    </label>
                                    <input
                                      disabled
                                      // placeholder='Nearest Station'
                                      className="multisteps-form__input form-control mt-4"
                                      defaultValue={
                                        cust.address
                                          ? cust.address.map(
                                              (add, index) =>
                                                add.nearest_station
                                            )
                                          : null
                                      }
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-12">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Special Instruction
                                    </label>
                                    <textarea
                                      disabled
                                      // placeholder='Special Instruction'
                                      defaultValue={
                                        cust.address
                                          ? cust.address.map(
                                              (add, index) =>
                                                add.special_instruction
                                            )
                                          : null
                                      }
                                      className="mt-4 multisteps-form__textarea form-control mt-4"
                                      rows="3"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*-------------------Info------------------*/}
                            <div className="card-body pt-0">
                              <h5>Info</h5>
                              <div className="row mt-3">
                                <div className="col-12 mt-3">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Company Name
                                    </label>
                                    <input
                                      disabled
                                      // placeholder='Company Name'
                                      defaultValue={cust.company}
                                      // onChange={(e) => this.setState({company: e.target.value})}
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6 mt-4">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Phone Number
                                    </label>
                                    <input
                                      disabled
                                      // placeholder='Phone Number'
                                      // minLength="11"
                                      // maxLength="11"
                                      defaultValue={cust.phone}
                                      // onChange={(e) => this.setState({cust_phone: e.target.value})}
                                      className="multisteps-form__input form-control mt-4"
                                      type="tel"
                                      pattern="[0-9]{3}-[0-9]{8}"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6 mt-4">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Rating:
                                    </label>
                                    {cust.rating ? (
                                      <div
                                        style={{
                                          marginTop: "5px",
                                          marginLeft: "50px",
                                        }}
                                      >
                                        <StarRatings
                                          rating={cust.rating}
                                          starDimension="15px"
                                          starSpacing="1px"
                                          starRatedColor="yellow"
                                          name="rating"
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-12 col-sm-12">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">Email</label>
                                    <input
                                      disabled
                                      // placeholder="Email"
                                      defaultValue={cust.email}
                                      // onChange={(e) => this.setState({email: e.target.value})}
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>

                                <div className="col-12 mt-4">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      Websites
                                    </label>
                                    <input
                                      disabled
                                      // placeholder='Websites'
                                      defaultValue={cust.website}
                                      // onChange={(e) => this.setState({website: e.target.value})}
                                      className="multisteps-form__input form-control mt-4"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-12 col-sm-6">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      GST NO.
                                    </label>
                                    <input
                                      disabled
                                      // placeholder='GST No.'
                                      // onChange={(e) => this.setState({gst_num: e.target.value})}
                                      className="multisteps-form__input form-control mt-4"
                                      defaultValue={cust.gst_num}
                                      type="text"
                                      minLength="15"
                                      maxLength="15"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                  <div className="input-group input-group-dynamic">
                                    <label className="form-label">
                                      PAN NO.
                                    </label>
                                    <input
                                      disabled
                                      // placeholder='PAN Number'
                                      // onChange={(e) => this.setState({pan_num: e.target.value})}
                                      className="multisteps-form__input form-control mt-4"
                                      defaultValue={cust.pan_num}
                                      type="text"
                                      maxLength="10"
                                      minLength="10"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*-------------------Bank------------------*/}
                            {cust.type === "SELLER"
                              ? cust.bank
                                ? cust.bank.map((bank, index) => (
                                    <div className="card-body pt-0">
                                      <h5>
                                        {cust.bank.length === 1
                                          ? "Bank-info"
                                          : "Bank-info(" +
                                            parseInt(index + 1) +
                                            ")"}
                                      </h5>
                                      <div className="row mt-3">
                                        <div className="col-12">
                                          <div className="input-group input-group-dynamic">
                                            <label className="form-label">
                                              Bank Name
                                            </label>
                                            <input
                                              disabled
                                              // placeholder='Bank Name'
                                              // onChange={(e) => this.setState({bank_name: e.target.value})}
                                              defaultValue={bank.bank_name}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <div className="input-group input-group-dynamic">
                                            <label className="form-label">
                                              Account Number
                                            </label>
                                            <input
                                              disabled
                                              // placeholder='Account Number'
                                              // onChange={(e) => this.setState({account: e.target.value})}
                                              defaultValue={bank.account}
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <div className="input-group input-group-dynamic">
                                            <label className="form-label">
                                              Account Holder Name
                                            </label>
                                            <input
                                              disabled
                                              // placeholder='Account Holder Name'
                                              // onChange={(e) => this.setState({account_holder_name: e.target.value})}
                                              defaultValue={
                                                bank.account_holder_name
                                              }
                                              className="multisteps-form__input form-control mt-4"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <div className="input-group input-group-dynamic">
                                            <label className="form-label">
                                              Remark:
                                            </label>
                                            <div className="col-12 mt-3">
                                              <div className="input-group input-group-dynamic">
                                                <textarea
                                                  disabled
                                                  // placeholder='Remark'
                                                  // onChange={(e) => this.setState({remark: e.target.value})}
                                                  defaultValue={bank.remark}
                                                  className="multisteps-form__input form-control mt-4"
                                                  rows="3"
                                                ></textarea>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <div className="input-group input-group-dynamic">
                                            <label className="form-label">
                                              Phone Number
                                            </label>
                                            <input
                                              disabled
                                              // placeholder='Phone Number'
                                              // onChange={(e) => this.setState({bank_phone: e.target.value})}
                                              pattern="[0-9]{3}-[0-9]{8}"
                                              defaultValue={bank.phone}
                                              className="multisteps-form__input form-control mt-4"
                                              type="tel"
                                              minLength="11"
                                              maxLength="11"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                          <div className="input-group input-group-dynamic">
                                            <label className="form-label">
                                              IFSC:
                                            </label>
                                            <input
                                              disabled
                                              type="text"
                                              defaultValue={bank.ifsc}
                                              className="multisteps-form__input form-control mt-4"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : null
                              : null}

                            {/*-------------------Auth------------------*/}
                            <div className="card-body pt-0">
                              <h5>Authorization</h5>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="col-12 mt-3">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Name of Principle
                                      </label>
                                      <input
                                        disabled
                                        // placeholder='Name of Principle'
                                        // onChange={(e) => this.setState({principle_name: e.target.value})}
                                        defaultValue={
                                          cust.information
                                            ? cust.information.principle_name
                                            : null
                                        }
                                        className="multisteps-form__input form-control mt-4"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 mt-3">
                                    <div className="row">
                                      <div className="col-12">
                                        <label className="form-label">
                                          Date
                                        </label>
                                      </div>
                                      <div className="col-12">
                                        <div className="input-group input-group-dynamic">
                                          <input
                                            disabled
                                            // placeholder='Date'
                                            // onChange={(e) => this.setState({date_of_finalization: e.target.value})}
                                            defaultValue={
                                              cust.information
                                                ? cust.information
                                                    .date_of_finalization
                                                : null
                                            }
                                            className="multisteps-form__input form-control"
                                            type="date"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="col-12 mt-3">
                                    <div className="input-group input-group-dynamic">
                                      <label className="form-label">
                                        Landline
                                      </label>
                                      <input
                                        disabled
                                        // placeholder='Landline'
                                        // onChange={(e) => this.setState({principle_landline: e.target.value})}
                                        defaultValue={
                                          cust.information
                                            ? cust.information
                                                .principle_landline
                                            : null
                                        }
                                        className="multisteps-form__input form-control mt-4"
                                        type="tel"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 mt-3">
                                    <div className="row">
                                      <div className="col-12">
                                        <label className="form-label">
                                          Strength
                                        </label>
                                      </div>
                                      <div className="col-12">
                                        <div className="input-group input-group-dynamic">
                                          <input
                                            disabled
                                            placeholder="Strength"
                                            defaultValue={
                                              cust.information
                                                ? cust.information
                                                    .school_strength
                                                : null
                                            }
                                            className="multisteps-form__input form-control"
                                            type="number"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CustomerVisits />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
