import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  axios,
  sideBar,
  userLogin,
  google_map_api_key,
} from "../../../store/actions/actions";

class Punch extends React.Component {
  state = {
    status: "done",
    address: [],
    attend: null,

    punch_in: null,
    punch_in_address: null,
    punch_out: null,
    punch_out_address: null,

    lat: null,
    lng: null,
  };

  componentDidMount() {
    this.punchInCheck();
    this.sendLocation();
  }

  sendLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getLocation);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  punchInCheck = () => {
    let endpoint = "employee/punch-in-check";
    let data = {
      username: this.props.user.username,
    };
    let token = this.props.user.token;
    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log("Response data--------------------->", responseData);
      this.setState({
        status: responseData.status,

        punch_in: responseData.punch_in,
        punch_in_address: responseData.punch_in_address,
        punch_out: responseData.punch_out,
        punch_out_address: responseData.punch_out_address,
      });
    });
  };

  getLocation = (position) => {
    this.setState({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
    // let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=32.607872,74.7386713&key=${google_map_api_key}`
    let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${google_map_api_key}`;
    axios.get(endpoint).then((res) => {
      let responseData = res.data.results[0].address_components;
      let adrs_len = responseData.length;
      for (let step = 0; step < adrs_len; step++) {
        let index = adrs_len - step;
        if (index !== adrs_len) {
          this.state.address.push(responseData[index].long_name);
        }
      }
      if (this.state.address.length <= 1) {
        this.getLocationAgain(position);
      }

      console.log(
        "1. address length--------------------->",
        this.state.address.length
      );
    });
  };

  getLocationAgain = (position) => {
    // let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=32.607872,74.7386713&key=${google_map_api_key}`
    let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${google_map_api_key}`;
    axios.get(endpoint).then((res) => {
      let responseData = res.data.results[1].address_components;
      let adrs_len = responseData.length;
      for (let step = 0; step < adrs_len; step++) {
        let index = adrs_len - step;
        if (index !== adrs_len) {
          this.state.address.push(responseData[index].long_name);
        }
      }
      if (this.state.address.length <= 1) {
        this.getLocationAgain2(position);
      }

      console.log(
        "2. address length--------------------->",
        this.state.address.length
      );
    });
  };

  getLocationAgain2 = (position) => {
    // let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=32.607872,74.7386713&key=${google_map_api_key}`
    let endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${google_map_api_key}`;
    axios.get(endpoint).then((res) => {
      let responseData = res.data.results[2].address_components;
      let adrs_len = responseData.length;
      for (let step = 0; step < adrs_len; step++) {
        let index = adrs_len - step;
        if (index !== adrs_len) {
          this.state.address.push(responseData[index].long_name);
        }
      }
      console.log(
        "3. address length--------------------->",
        this.state.address.length
      );
    });
  };

  punch = () => {
    // if (this.state.address.length>=1) {
    let data = {
      status: this.state.status,
      address: this.state.address,
      lat: this.state.lat,
      lng: this.state.lng,
    };
    let endpoint = "employee/punch";
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((res) => {
      let responseData = res.data;
      console.log("Response data------------------>", responseData);
      this.setState({
        status: responseData.status,
        punch_in: responseData.punch_in,
        punch_in_address: responseData.punch_in_address,
        punch_out: responseData.punch_out,
        punch_out_address: responseData.punch_out_address,
      });
    });
    // }
  };

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="d-lg-none">
              <div className="dashOption-mv my-3 px-2">
                <div className="">
                  <div className="button-bar-mv d-flex">
                    <Link to="/attendance" className="btn btn-mv ">
                      <img
                        src={require("../../../kavi/icons/dashbord.png")}
                        alt="btn-icon"
                      />
                      Dashboard
                    </Link>
                    <Link to="/attendance/" className="btn btn-mv active">
                      <img
                        src={require("../../../kavi/icons/add.png")}
                        alt="btn-icon"
                      />
                      Punch
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {this.state.status === "done" ? null : (
              <div className="row mt-5">
                <div className="punch-btn col-12 mt-5">
                  <button onClick={this.punch} id="bigbutton">
                    <span>{this.state.status}</span>
                  </button>
                </div>
              </div>
            )}

            <div className="row justify-content-end mt-5 px-3">
            <div className="col-lg-10 p-3 mb-5 mt-4">
              <div className="card py-3">
                <div className="table-responsive px-2">
                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div
                      className="dataTable-container"
                      // style={{height: "580.641px"}}
                    >
                      <table
                        className="table align-items-center mb-3 dataTable-table"
                        id="empe"
                      >
                        <thead>
                          <tr>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "15%", cursor: "pointer" }}
                            >
                              <a className="dataTable-sorter">Type</a>
                            </th>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                            >
                              <a className="dataTable-sorter ">Date & Time</a>
                            </th>
                            {/* <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                            >
                              <a className="dataTable-sorter ">Location</a>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="align-items-center">
                                <h6 className="mb-0 font-weight-normal text-sm">
                                  Punch In
                                </h6>
                              </div>
                            </td>
                            <td>
                              <div className="align-items-center">
                                <h6 className="mb-0 font-weight-normal text-sm">
                                  {/*{this.state.punch_in}*/}
                                  {this.state.punch_in
                                    ? new Date(
                                        this.state.punch_in
                                      ).toLocaleString("en-IN", {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      })
                                    : null}
                                </h6>
                              </div>
                            </td>
                            {/* <td>
                              <div className="align-items-center">
                                <h6 className="mb-0 font-weight-normal text-sm">
                                  {this.state.punch_in_address}
                                </h6>
                              </div>
                            </td> */}
                          </tr>
                          <tr>
                            <td>
                              <div className="align-items-center">
                                <h6 className="mb-0 font-weight-normal text-sm">
                                  Punch Out
                                </h6>
                              </div>
                            </td>
                            <td>
                              <div className="align-items-center">
                                <h6 className="mb-0 font-weight-normal text-sm">
                                  {this.state.punch_out
                                    ? new Date(
                                        this.state.punch_out
                                      ).toLocaleString("en-IN", {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      })
                                    : null}
                                </h6>
                              </div>
                            </td>
                            {/* <td>
                              <div className="align-items-center">
                                <h6 className="mb-0 font-weight-normal text-sm">
                                  {this.state.punch_out_address}
                                </h6>
                              </div>
                            </td> */}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
  sideBarHandler: (data) => dispatch(sideBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Punch);
