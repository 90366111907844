import React from "react";
import { connect } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import '../../custom/css/srkcutom.css'
import { axios , modalStyle} from "../../store/actions/actions";
import { Multiselect } from "multiselect-react-dropdown";
import { stations, states } from "./objects";
import Select from "react-select";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";


class CreateStation extends React.Component {
    state = {

        open: false,

        selected_city: null,
        selected_city_list: null,

        state: null,
        station: null,
        city_quan: null
    }

    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    onAssignCitySelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        this.setState({
            selected_city: selectedItem,
            city_quan: selectedList.length,
            selected_city_list: selectedList
        })
    }

    onAssignCityRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        this.setState({
            city_quan: selectedList.length,
            selected_city_list: selectedList
        })
    }

    filterStations = (state) => {
        this.setState({
            state: state
        })
        this.setState({
            station: stations.filter(obj => obj.state === state)
        })
    }

    submit = () => {
        const data = {
            state: this.state.state,
            station: this.state.selected_city_list
        }

        console.log(data)

        let endpoint = `common/create-station`
        let token = this.props.user.token
        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                if(responseData.success===true){
                    this.setState({
                        open: true
                    })
                }
                console.log({ 'response data---------': responseData })
            })
    }

    render() {
        return (
            <>
                {this.props.user.login === true ?
                    <>
                        <div className="container-fluid">
                            <div className="row justify-content-end">
                                <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "d-none d-lg-block col-md-10" : "d-none d-lg-block col-12"}>

                                    <div className="card" style={{minHeight:"250px"}}>
                                        <div className="card-header">
                                            <h5>Create Station</h5>
                                        </div>

                                        <div className="card-body pt-0">
                                            {/*<div className="row">*/}
                                            <div className="col-6">
                                                <div className="row">
                                                    <Select
                                                        placeholder="Select State"

                                                        options={states.map((st, index) => (
                                                            { value: st.state, label: st.state }
                                                        ))}

                                                        onChange={(e) => this.filterStations(e.value)}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.station ?
                                                <div className="col-6 mt-4">
                                                    <div className="row">
                                                        <Multiselect
                                                            placeholder="Seclect City"
                                                            options={this.state.station} // Options to display in the dropdown
                                                            onSelect={this.onAssignCitySelect} // Function will trigger on select event
                                                            onRemove={this.onAssignCityRemove} // Function will trigger on remove event
                                                            displayValue="station" // Property name to display in the dropdown options
                                                            style={{
                                                                searchBox: { fontSize: "15px" }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                : null}

                                            {this.state.city_quan !== null && this.state.city_quan !== 0 ?
                                                <div className="row my-4">
                                                    <div className="col-12">
                                                        <button className="btn bg-gradient-dark d-block m-auto"
                                                            onClick={this.submit}
                                                        >Submit</button>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-lg-none mt-5 px-2">
                            <div className='card'>
                                <div className="card-header">
                                    <h5>Create Station</h5>
                                </div>

                                <div className="card-body pt-0" style={{minHeight:"150px"}}>
                                    {/*<div className="row">*/}
                                    <div className="col-12">
                                        <div className="row">
                                            <Select
                                                placeholder="Select State"

                                                options={states.map((st, index) => (
                                                    { value: st.state, label: st.state }
                                                ))}

                                                onChange={(e) => this.filterStations(e.value)}
                                            />
                                        </div>
                                    </div>
                                    {this.state.station ?
                                        <div className="col-12 mt-4">
                                            <div className="row">
                                                <Multiselect
                                                    placeholder="Seclect City"
                                                    options={this.state.station} // Options to display in the dropdown
                                                    onSelect={this.onAssignCitySelect} // Function will trigger on select event
                                                    onRemove={this.onAssignCityRemove} // Function will trigger on remove event
                                                    displayValue="station" // Property name to display in the dropdown options
                                                    style={{
                                                        searchBox: { fontSize: "15px" }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        : null}

                                    {this.state.city_quan !== null && this.state.city_quan !== 0 ?
                                        <div className="row my-4">
                                            <div className="col-12">
                                                <button className="btn bg-gradient-dark d-block ms-auto"
                                                    onClick={this.submit}
                                                >Submit</button>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.open}
                            onClose={(e)=>this.setState({open: false})}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                            <Fade in={this.state.open}>
                              <Box sx={modalStyle}>
                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                    <div className="modal-body">
                                        <div className="circle__box">
                                            <div className="circle__wrapper circle__wrapper--right">
                                                <div className="circle__whole circle__right"></div>
                                            </div>
                                            <div className="circle__wrapper circle__wrapper--left">
                                                <div className="circle__whole circle__left"></div>
                                            </div>
                                            <div className="circle__checkmark">
                                            </div>
                                        </div>
                                        <div style={{textAlign: 'center'}}>
                                            <h5>Station Created Successfully.</h5>
                                            <h6><Link to='/' style={{color: "grey"}}>Click here </Link> for go to employee dashboard.</h6>
                                        </div>
                                    </div>
                                </Typography>
                              </Box>
                            </Fade>
                        </Modal>
                    </>
                    :
                    <Redirect to={'/sign-in'} />
                }
            </>

        )
    }
}

const mapStateToProps = state => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar
})

export default connect(mapStateToProps)(CreateStation)

