import React from "react";
import { geolocated } from "react-geolocated";
import {axios} from "../../../store/actions/actions";
import EmployeeSearch from "../../common/EmployeeSearch";
import CustomerSearch from "../../common/CustomerSearch";
import {getPreciseDistance} from 'geolib';

class ViewTripComponent extends React.Component {
    state = {
        customer: null,
        employee: null,
        employee_id: null,
        date: null,
        get_cust: null,
        get_emp: null,
        cust_lat: null,
        cust_long: null,

        near_by_companies: null,

        trip_id: null,
        today_date: new Date().toISOString().slice(0, 10),
    }

    componentDidMount() {
        this.loadPageWithAxios()
        this.loadSelectCompWithAxios()
    }


    checkCustNearEmp = () =>{
        const companies_near_by = []

        this.state.get_cust.map((item, index)=>(
          item.address_by_location===null?null:
              getPreciseDistance(
              { latitude: this.props.coords.latitude, longitude: this.props.coords.longitude},
              { latitude: item.address_by_location.geolocation.split(",")[0], longitude: item.address_by_location.geolocation.split(",")[1]}
              )<=20000?companies_near_by.push(item.company) && this.setState({cust_lat:item.address_by_location.geolocation.split(",")[0], cust_long:item.address_by_location.geolocation.split(",")[1]})
                :null
        ))

        this.setState({near_by_companies: companies_near_by})
    }


    loadSelectCompWithAxios = () =>{
      let endpoint = `trip/trip-emp-cust`
        let token = this.props.token
      axios
          .get(endpoint, token)
          .then((res)=>{
              let responseData = res.data;
              this.setState({get_cust:responseData.customer})
              this.setState({get_emp:responseData.employee})

              // console.log({'response data---------':responseData})
          })
    }


    loadPageWithAxios = () =>{
        let pathname = window.location.pathname;

        let pathurl = pathname.split("/");
        let path = pathurl[pathurl.length - 1];

        let endpoint = `trip/${path}`
        let token = this.props.token
        axios
          .get(endpoint, token)
          .then((res)=>{
              let responseData = res.data;
              this.setState({customer:responseData.trip[0].company})
              this.setState({cust_lat:responseData.trip[0].geolocation?responseData.trip[0].geolocation.split(",")[0]:null})
              this.setState({cust_long:responseData.trip[0].geolocation?responseData.trip[0].geolocation.split(",")[1]:null})
              this.setState({employee:responseData.trip[0].employee_obj})
              this.setState({employee_id:responseData.trip[0].employee})
              this.setState({trip_id:responseData.trip[0].id})
              this.setState({date:responseData.trip[0].date})

              console.log({'Trip response data---------':responseData})
          })
    }


    handleCallback = (childData) =>{
      if (childData.type==='Customer Name') {
          this.setState({customer: childData.value.company})
          this.setState({cust_lat: childData.value.address_by_location?childData.value.address_by_location.geolocation.split(",")[0]:null})
          this.setState({cust_long: childData.value.address_by_location?childData.value.address_by_location.geolocation.split(",")[1]:null})
      }

      if (childData.type==='Employee Name') {
          this.setState({employee: childData.value})
          this.setState({employee_id: childData.value[0].id})
      }
    }


    submit = () =>{

      const data = {
          id: this.state.trip_id,
          emp_lat: this.props.coords.latitude,
          emp_long: this.props.coords.longitude,
          cust_lat: this.state.cust_lat,
          cust_long: this.state.cust_long,
          customer: this.state.customer,
          employee_id: this.state.employee_id,
          date: this.state.date,
      }

      let endpoint = `trip/`
        let token = this.props.token
      axios
          .put(endpoint, data, token)
          .then((res)=>{
              let responseData = res.data;
              console.log({'response data---------':responseData})
          })
    }

    render() {

    return (
        <>
                <div className="col-md-10 mt-lg-0 mt-4">

                    {/* <!-- Card Basic Info -->*/}
                    <div className="card mt-4" id="basic-info">
                        <div className="card-header">
                            <h5>Your Trip</h5>
                        </div>

                        <div className="card-body pt-0">
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group input-group-static is-filled">
                                        <label>Customer</label>

                                    </div>

                                        <h6>{this.state.customer}</h6>
                                </div>
                                <div className="col-6">
                                    <div className="input-group input-group-static is-filled">
                                        <label>Date</label>
                                        {/*<input type="date" className="form-control" onFocus="focused(this)"*/}
                                        {/*       value={this.state.date?this.state.date:this.state.today_date}*/}
                                        {/*       onChange={(e) => this.setState({date: e.target.value})}*/}
                                        {/*       onfocusout="defocused(this)"/>*/}
                                    </div>
                                    <h6>{this.state.date}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
  }
}


export default geolocated()(ViewTripComponent);
