import React from "react";
import { connect } from "react-redux";
import {
  axios,
  url,
  userLogin,
  modalStyle,
} from "../../../store/actions/actions";
import { Link } from "react-router-dom";
import DeleteModal from "../../common/Modals/deleteModal";
import Loader from "../../common/Loader/Loader";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "@mui/material/Pagination";
import "../../common/common.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextField from "@mui/material/TextField";
import { Multiselect } from "multiselect-react-dropdown";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';

class EmpDataTable extends React.Component {
  state = {
    employee: null,
    quan: null,
    total: null,
    city: null,
    filterEmployees: null,
    selected_state: null,

    del_name: null,
    del_endpoint: null,

    // sort
    nameSort: null,
    stateSort: null,
    stationSort: null,
    citySort: null,
    divisionSort: null,

    // pagination
    page: null,

    // filter
    filter: false,
    all_states: null,
    all_divisions: null,
    showfilter: false,
    fromDate: null,
    toDate: null,
    stateFilter: null,
    divisionFilter: null,
    clear: false,

    open: false,
    delete_employee_id: null,
    is_delete: false,

    // Search Text 
    searchText: null,

    index_page: null,
    employee_search_loading: false
  };

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  componentDidMount() {
    this.loadStates();
    this.loadEmployeePageWithAxios();
  }

  loadEmployeePageWithAxios = () => {
    this.setState({ open: false });
    let endpoint = `employee/employee-datatable`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ employee: responseData.employee });
      this.setState({ filterEmployees: responseData.employee });
      this.setState({ quan: responseData.count });
      this.setState({ page: responseData.page });
      this.setState({ total: responseData.count });
      console.log({ "response data---------": responseData.employee });
    });
  };

  loadStates = () => {
    this.loadDivision();
    let endpoint = `employee/states`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ all_states: responseData.state });
      console.log({ "states response data---------": responseData.state });
    });
  };

  loadDivision = () => {
    let endpoint = `employee/division`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ all_divisions: responseData.division });
      console.log({ "division response data---------": responseData.division });
    });
  };

  dateFormat = (inputDate, format) => {
    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    //replace the month
    format = format.replace("MM", month.toString().padStart(2, "0"));

    //replace the year
    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }

    //replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  };

  handleSearch = (e) => {
    let text = e.target.value;
    let endpoint = `employee/search-employee?search=${text}`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ filterEmployees: responseData });
      console.log({ "response data---------": responseData });
    });
  };

  handlePage = (e, p) => {
    console.log("--------------------", p);
    this.setState({ index_page: p === 1 ? 0 : (p - 1) * 10 });
    const data = {
      from: p === 1 ? 0 : (p - 1) * 10,
      to: p === 1 ? 10 : p * 10,
    };
    console.log("--------------------", data);
    let endpoint = `employee/pagination`;
    let token = this.props.user.token;

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      this.setState({ filterEmployees: responseData.employee });
      console.log({ "response data---------": responseData });
    });
  };

  sortEmpTable = (data) => {
    let arr = this.state.filterEmployees;

    if (data === "name") {
      if (this.state.nameSort === null || this.state.nameSort === "lower") {
        this.setState({
          nameSort: "upper",
        });
        arr.sort(function (a, b) {
          if (a.first_name.toLowerCase() < b.first_name.toLowerCase())
            return -1;
          return 0;
        });
      }

      if (this.state.nameSort === "upper") {
        this.setState({
          nameSort: "lower",
        });
        arr.sort(function (a, b) {
          if (a.first_name.toLowerCase() > b.first_name.toLowerCase())
            return -1;
          return 0;
        });
      }
      this.setState({
        filterEmployees: arr,
      });
    }

    if (data === "state") {
      if (this.state.stateSort === null || this.state.stateSort === "lower") {
        this.setState({
          stateSort: "upper",
        });
        arr.sort(function (a, b) {
          let state_a = a.state.map((st, index) => st.state.toLowerCase());
          let state_b = b.state.map((st, index) => st.state.toLowerCase());

          if (state_a < state_b) return -1;
          return 0;
        });
      }

      if (this.state.stateSort === "upper") {
        this.setState({
          stateSort: "lower",
        });
        arr.sort(function (a, b) {
          let state_a = a.state.map((st, index) => st.state.toLowerCase());
          let state_b = b.state.map((st, index) => st.state.toLowerCase());

          if (state_a > state_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterEmployees: arr,
      });
    }

    if (data === "city") {
      if (this.state.citySort === null || this.state.citySort === "lower") {
        this.setState({
          citySort: "upper",
        });
        arr.sort(function (a, b) {
          let station_a = a.station.map((st, index) =>
            st.station.toLowerCase()
          );
          let station_b = b.station.map((st, index) =>
            st.station.toLowerCase()
          );

          if (station_a < station_b) return -1;
          return 0;
        });
      }

      if (this.state.citySort === "upper") {
        this.setState({
          citySort: "lower",
        });
        arr.sort(function (a, b) {
          let station_a = a.station.map((st, index) =>
            st.station.toLowerCase()
          );
          let station_b = b.station.map((st, index) =>
            st.station.toLowerCase()
          );

          if (station_a > station_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterEmployees: arr,
      });
    }

    if (data === "status") {
      if (this.state.statusSort === null || this.state.statusSort === "lower") {
        this.setState({
          statusSort: "upper",
        });
        arr.sort(function (a, b) {
          let status_a =
            a.is_admin === true
              ? "Admin"
              : a.is_manager === true
                ? "Manager"
                : a.is_executive === true
                  ? "Executive"
                  : null;
          let status_b =
            b.is_admin === true
              ? "Admin"
              : b.is_manager === true
                ? "Manager"
                : b.is_executive === true
                  ? "Executive"
                  : null;

          if (status_a < status_b) return -1;
          return 0;
        });
      }
      if (this.state.statusSort === "upper") {
        this.setState({
          statusSort: "lower",
        });
        arr.sort(function (a, b) {
          let status_a =
            a.is_admin === true
              ? "Admin"
              : a.is_manager === true
                ? "Manager"
                : a.is_executive === true
                  ? "Executive"
                  : null;
          let status_b =
            b.is_admin === true
              ? "Admin"
              : b.is_manager === true
                ? "Manager"
                : b.is_executive === true
                  ? "Executive"
                  : null;

          if (status_a > status_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterEmployees: arr,
      });
    }

    if (data === "division") {
      if (
        this.state.divisionSort === null ||
        this.state.divisionSort === "lower"
      ) {
        this.setState({
          divisionSort: "upper",
        });
        arr.sort(function (a, b) {
          let division_a = a.division.map((st, index) => st.name.toLowerCase());
          let division_b = b.division.map((st, index) => st.name.toLowerCase());

          if (division_a < division_b) return -1;
          return 0;
        });
      }

      if (this.state.divisionSort === "upper") {
        this.setState({
          divisionSort: "lower",
        });
        arr.sort(function (a, b) {
          let division_a = a.division.map((st, index) => st.name.toLowerCase());
          let division_b = b.division.map((st, index) => st.name.toLowerCase());

          if (division_a > division_b) return -1;
          return 0;
        });
      }

      this.setState({
        filterEmployees: arr,
      });
    }
  };

  onFilterStateSelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      stateFilter: selectedList,
    });
  };

  onFilterStateRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      stateFilter: selectedList,
    });
  };

  onFilterDivisionSelect = (selectedList, selectedItem) => {
    console.log("ON SELECT----------------------->");
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    this.setState({
      divisionFilter: selectedList,
    });
  };

  onFilterDivisionRemove = (selectedList, removedItem) => {
    console.log("ON REMOVE----------------------->");
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    this.setState({
      divisionFilter: selectedList,
    });
  };

  delete = (data) => {
    this.setState({
      open: true,
      delete_employee_id: data,
    });
    if (data === "delete") {
      let endpoint = `employee/${this.state.delete_employee_id}`;
      let token = this.props.user.token;

      axios.delete(endpoint, token).then((response) => {
        const responseData = response.data;
        this.setState({
          is_delete: true,
        });
        console.log({ "delete response data---------": responseData });
      });
    }
  };

  applyFilter = () => {
    this.setState({
      filter: true,
      clear: true,
    });
    let states = [];
    let division = [];

    if (this.state.stateFilter !== null && this.state.stateFilter.length > 0) {
      // if (this.state.stateFilter !== null && this.state.stateFilter !== []) {
      console.log("state filter");
      this.state.stateFilter.map((state, index) => states.push(state.id));
    }

    if (
      this.state.divisionFilter !== null &&
      //   this.state.divisionFilter !== []
      this.state.divisionFilter.length > 0
    ) {
      console.log("division filter");
      this.state.divisionFilter.map((div, index) => division.push(div.id));
    }

    let endpoint = `employee/filter-employee?division_in=${division}&state_in=${states}&from_date=${this.state.fromDate ? this.state.fromDate : ""
      }&to_date=${this.state.toDate ? this.state.toDate : ""}`;
    let token = this.props.user.token;

    axios.get(endpoint, token).then((response) => {
      const responseData = response.data;
      this.setState({ filterEmployees: responseData });
      console.log({
        ">>>>>>>>>>>>>>>>>>>>>>response data---------": responseData,
      });
    });
  };

  clearFilter = () => {
    console.log(this.state.employee);
    this.setState({
      clear: false,
      stateFilter: null,
      divisionFilter: null,
      filterEmployees: this.state.employee,
      fromDate: null,
      toDate: null,
    });
  };

  printTable = () => {
    console.log("run");
    //Get the HTML of div
    var divElements = document.getElementById("employeeTable").innerHTML;
    //Get the HTML of whole page
    var oldPage = document.body.innerHTML;
    //Reset the page's HTML with div's HTML only
    document.body.innerHTML =
      "<html><head><title></title></head><body>" + divElements + "</body>";
    //Print Page
    window.print();
    //Restore orignal HTML
    document.body.innerHTML = oldPage;
  };

   searchHandler = () => {
    let endpoint = `employee/employee-search-query`;
    let token = this.props.user.token;
    
    let data = {
      "searchText":this.state.searchText
    }

    console.log(token, "-=-=-=-=-=-=", endpoint)
    this.setState({
      employee_search_loading:true
    })

    axios.post(endpoint, data, token).then((response) => {
      const responseData = response.data;
      this.setState({ filterEmployees: responseData.employee });
      console.log(responseData)
    });
  }

  render() {
    return (
      <>
        <div className="d-none d-lg-block row my-3" id="clients">
          <div className="col-12">
            <div className="card py-3">
              {this.state.employee ? (
                <div className="table-responsive px-2">
                  <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                    <div className="dataTable-top">
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0 ps-3">Employee</h5>
                      </div>
                      {/*<div className="dataTable-dropdown">*/}
                      {/*    <label>*/}
                      {/*    <select className="dataTable-selector"*/}
                      {/*            onChange={(e) => this.setState({quan: e.target.value})}*/}
                      {/*    >*/}
                      {/*        <option value="4" selected>5</option>*/}
                      {/*        <option value="9">10</option>*/}
                      {/*        <option value="19">20</option>*/}
                      {/*        /!*<option value={this.state.total}>All</option>*!/*/}

                      {/*    </select> entries per page</label></div>*/}
                      <div
                        className=" d-flex justify-content-end align-items-center"
                        style={{ position: "relative" }}
                      >
                        <LocalPrintshopIcon
                          className="cursor-pointer me-3"
                          onClick={this.printTable}
                        />

                        <div className="d-flex align-items-center justify-content-center" style={{width:"250px"}}>
                          <div class="input-group input-group-outline">
                            <input type="text" class="form-control" onChange={(e)=>{
                              this.setState({
                                searchText : e.target.value
                              })
                            }} placeholder="Search Visit" />
                          </div>
                          
                        </div>
                        <button type="btn" className="btn btn-primary text-wrap ms-3 mb-0" onClick={this.searchHandler}> {this.employee_search_loading ? <CircularProgress /> : null }  Search</button>
                        {/* <TextField size="small" id="outlined-basic" label="Search..." variant="outlined" onChange={this.handleSearch}/> */}
                        <div
                          className="d-none"
                          onClick={(e) =>
                            this.setState({
                              showfilter:
                                this.state.showfilter === true ? false : true,
                            })
                          }
                        >
                          <FilterListIcon
                            style={{ cursor: "pointer", marginLeft: "15px" }}
                          />
                        </div>
                        {this.state.showfilter === true ? (
                          <div
                            className="card p-4"
                            style={{
                              width: "700px",
                              position: "absolute",
                              top: "50px",
                              right: "0",
                              zIndex: "10",
                              boxShadow: "0px 0px 6px #bdbbbb",
                            }}
                          >
                            <h6>FILTERS</h6>
                            {this.state.clear ? (
                              <p
                                onClick={this.clearFilter}
                                style={{ color: "#5f5fcb", cursor: "pointer" }}
                              >
                                Clear
                              </p>
                            ) : null}
                            <div className="row p-1">
                              <div className="col-12 p-1">
                                <p>Date range</p>
                                <div className="d-flex mt-2 p-1 align-items-center">
                                  <TextField
                                    id="date"
                                    label="From"
                                    type="date"
                                    size="small"
                                    onChange={(e) =>
                                      this.setState({
                                        fromDate: e.target.value,
                                      })
                                    }
                                    sx={{ width: 220 }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                  <p
                                    className="mx-4 my-0"
                                    style={{ fontSize: "24px" }}
                                  >
                                    to
                                  </p>

                                  <TextField
                                    id="date"
                                    label="To"
                                    type="date"
                                    size="small"
                                    onChange={(e) =>
                                      this.setState({ toDate: e.target.value })
                                    }
                                    sx={{ width: 220 }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 p-1 mt-2">
                                <p>State</p>
                                <div className="form-check-inline">
                                  <div className="">
                                    <Multiselect
                                      placeholder="Seclect State"
                                      options={this.state.all_states} // Options to display in the dropdown
                                      onSelect={this.onFilterStateSelect} // Function will trigger on select event
                                      onRemove={this.onFilterStateRemove} // Function will trigger on remove event
                                      displayValue="state" // Property name to display in the dropdown options
                                      style={{
                                        searchBox: { fontSize: "15px" },
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-1 mt-2">
                                <p>Division</p>
                                <div className="form-check-inline">
                                  <>
                                    <Multiselect
                                      placeholder="Seclect Division"
                                      options={this.state.all_divisions} // Options to display in the dropdown
                                      onSelect={this.onFilterDivisionSelect} // Function will trigger on select event
                                      onRemove={this.onFilterDivisionRemove} // Function will trigger on remove event
                                      displayValue="name" // Property name to display in the dropdown options
                                      style={{
                                        searchBox: { fontSize: "15px" },
                                      }}
                                    />
                                  </>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                              <button
                                onClick={this.applyFilter}
                                className="btn my-0 bg-gradient-dark d-block"
                              >
                                Apply Filters
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/*<div>*/}
                      {/*</div>*/}
                    </div>
                    <div className="dataTable-container" id="employeeTable">
                      <table className="table align-items-center mb-3 dataTable-table">
                        <thead>
                          <tr>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "4.8032%", cursor: "pointer" }}
                            >
                              <a
                                // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                className="dataTable-sorter"
                                onClick={(e) => this.sortEmpTable("serial_num")}
                              >
                                S.NO
                              </a>
                            </th>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("name")}
                            >
                              <a className="dataTable-sorter">Name</a>
                            </th>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("state")}
                            >
                              <a className="dataTable-sorter text-center">
                                Assign State
                              </a>
                            </th>
                            <th
                              className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("city")}
                            >
                              <a className="dataTable-sorter d-block text-center">
                                Assign City
                              </a>
                            </th>
                            <th
                              className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("status")}
                            >
                              <a className="dataTable-sorter">Status</a>
                            </th>
                            <th
                              className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={(e) => this.sortEmpTable("division")}
                            >
                              <a className="dataTable-sorter">Division</a>
                            </th>
                            <th
                              className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                              data-sortable=""
                              style={{ width: "15%" }}
                            >
                              <a className="dataTable-sorter">Action</a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filterEmployees
                            ? this.state.filterEmployees.map((emp, index) =>
                              index <= this.state.quan ? (
                                <tr>
                                  <td>
                                    <div className="d-flex px-2 py-1">
                                      <div className="d-flex flex-column justify-content-center">
                                        <h6 className="mb-0 font-weight-normal text-sm">
                                          {index + 1 + this.state.index_page}
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex  align-items-center">
                                      <img
                                        src={
                                          emp.pic
                                            ? url + emp.pic
                                            : url +
                                            "/static/dreambox_logo.jpg"
                                        }
                                        className="avatar avatar-sm me-3"
                                        alt="avatar image"
                                      />
                                      <h6 className="mb-0 font-weight-normal text-sm">
                                        {emp.last_name
                                          ? emp.first_name +
                                          " " +
                                          emp.last_name
                                          : emp.first_name}
                                      </h6>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        height:
                                          emp.state.length >= 3
                                            ? "200px"
                                            : "none",
                                        overflow: "overlay",
                                        display: "block",
                                      }}
                                    >
                                      {emp.state.map((st, index) => (
                                        <div>
                                          <p className="text-sm text-center font-weight-normal mb-0">
                                            {st.state}
                                          </p>
                                          {emp.state.length > 1 ? (
                                            <hr />
                                          ) : null}
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        height: "23px",
                                        overflow: "scroll",
                                        display: "block",
                                      }}
                                    >
                                      {emp.station.map((st, index) => (
                                        <div>
                                          <p className="text-sm text-center font-weight-normal mb-0">
                                            {st.station ? st.station : null}
                                          </p>
                                          {emp.station.length > 1 ? (
                                            <hr />
                                          ) : null}
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                  <td className="align-middle text-center">
                                    <p className="text-sm font-weight-normal mb-0">
                                      {emp.is_admin === true ? "Admin" : null}
                                      {emp.is_manager === true
                                        ? "Manager"
                                        : null}
                                      {emp.is_accountant === true
                                        ? "Accountant"
                                        : null}
                                      {emp.is_executive === true
                                        ? "Executive"
                                        : null}
                                      {emp.is_coordinator === true
                                        ? "Coordinator"
                                        : null}
                                    </p>
                                  </td>
                                  <td className="align-middle text-center">
                                    {emp.division.map((div, index) => (
                                      <p className="text-sm font-weight-normal mb-0">
                                        {div.name}
                                      </p>
                                    ))}
                                  </td>
                                  <td className="align-middle text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                      <Link
                                        to={
                                          "/employee/employee-view/" + emp.id
                                        }
                                      >
                                        <RemoveRedEyeIcon
                                          style={{ color: "grey" }}
                                        />
                                      </Link>
                                      <Link
                                        to={
                                          "/employee/edit-employee-admin/" +
                                          emp.id
                                        }
                                      >
                                        <ModeEditIcon
                                          style={{
                                            color: "#12b312",
                                            margin: "0px 15px",
                                          }}
                                        />
                                      </Link>
                                      <div
                                        className="d-none"
                                        onClick={(e) => this.delete(emp.id)}
                                      >
                                        <DeleteIcon
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )
                            : null}
                        </tbody>
                      </table>
                    </div>
                    {this.state.page === 1 ? null : (
                      <div className="datatable-mui-pagination">
                        <Pagination
                          count={this.state.page}
                          color="secondary"
                          onChange={this.handlePage}
                          size="large"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Loader />
              )}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.open}
                onClose={(e) => this.setState({ open: false })}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={this.state.open}>
                  <Box sx={modalStyle}>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      {this.state.is_delete ? (
                        <>
                          <div className="circle__box">
                            <div className="circle__wrapper circle__wrapper--right">
                              <div className="circle__whole circle__right delete_circle__right"></div>
                            </div>
                            <div className="circle__wrapper circle__wrapper--left">
                              <div className="circle__whole circle__left delete_circle__left"></div>
                            </div>
                            <div className="circle__checkmark delete_circle__checkmark"></div>
                          </div>

                          <div style={{ textAlign: "center" }}>
                            <h5>Employee deleted successfully.</h5>
                            <h6>
                              <div
                                onClick={this.loadEmployeePageWithAxios}
                                style={{ color: "grey", cursor: "pointer" }}
                              >
                                Click here{" "}
                              </div>
                              to go Employee Dashboard
                            </h6>
                          </div>
                        </>
                      ) : (
                        <div className="modal-body">
                          <div style={{ textAlign: "center" }}>
                            <h5>
                              Click the Delete button to delete this Employee.
                            </h5>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={(e) => this.delete("delete")}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </Typography>
                  </Box>
                </Fade>
              </Modal>
            </div>
          </div>
        </div>
        <div className="d-lg-none employee-mv mt-3">
          <div className="">
            <div className="head-content-mv">
              <div className="dataTable-top-mv">
                <h5 className="mb-0 ps-3">Employees</h5>
                {/*<div className="dataTable-dropdown">*/}
                {/*    <label>*/}
                {/*    <select className="dataTable-selector"*/}
                {/*            onChange={(e) => this.setState({quan: e.target.value})}*/}
                {/*    >*/}
                {/*        <option value="4" selected>5</option>*/}
                {/*        <option value="9">10</option>*/}
                {/*        <option value="19">20</option>*/}
                {/*        /!*<option value={this.state.total}>All</option>*!/*/}

                {/*    </select> entries per page</label></div>*/}
                <div
                  className=" d-flex justify-content-end align-items-center"
                  style={{ position: "relative", width: "100% !important" }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Search..."
                    variant="outlined"
                    onChange={this.handleSearch}
                  />
                  <div
                    onClick={(e) =>
                      this.setState({
                        showfilter:
                          this.state.showfilter === true ? false : true,
                      })
                    }
                  >
                    <FilterListIcon
                      style={{ cursor: "pointer", marginLeft: "15px" }}
                    />
                  </div>
                  {this.state.showfilter === true ? (
                    <div
                      className="card p-4 f-card-mv"
                      style={{
                        width: "700px",
                        position: "absolute",
                        top: "50px",
                        right: "0",
                        zIndex: "10",
                        boxShadow: "0px 0px 6px #bdbbbb",
                      }}
                    >
                      <h6>FILTERS</h6>
                      {this.state.clear ? (
                        <p
                          onClick={this.clearFilter}
                          style={{ color: "#5f5fcb", cursor: "pointer" }}
                        >
                          Clear
                        </p>
                      ) : null}
                      <div className="row p-1 flex-sm-column">
                        <div className="col-12 p-1">
                          <p>Date range</p>
                          <div className="d-flex mt-2 p-1 ms-md-5 ms-2 align-items-center">
                            <TextField
                              id="date"
                              label="From"
                              type="date"
                              size="small"
                              onChange={(e) =>
                                this.setState({ fromDate: e.target.value })
                              }
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <p
                              className="mx-4 my-0"
                              style={{ fontSize: "24px" }}
                            >
                              to
                            </p>

                            <TextField
                              id="date"
                              label="To"
                              type="date"
                              size="small"
                              onChange={(e) =>
                                this.setState({ toDate: e.target.value })
                              }
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 p-1 mt-2">
                          <p>State</p>
                          <div className="form-check-inline ms-sm-5">
                            <div className="">
                              <Multiselect
                                placeholder="Select State"
                                options={this.state.all_states} // Options to display in the dropdown
                                onSelect={this.onFilterStateSelect} // Function will trigger on select event
                                onRemove={this.onFilterStateRemove} // Function will trigger on remove event
                                displayValue="state" // Property name to display in the dropdown options
                                style={{
                                  searchBox: { fontSize: "15px" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 p-1 mt-2">
                          <p>Division</p>
                          <div className="form-check-inline ">
                            <>
                              <Multiselect
                                placeholder="Seclect Division"
                                options={this.state.all_divisions} // Options to display in the dropdown
                                onSelect={this.onFilterDivisionSelect} // Function will trigger on select event
                                onRemove={this.onFilterDivisionRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                style={{
                                  searchBox: { fontSize: "15px" },
                                }}
                              />
                            </>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-3">
                        <button
                          onClick={this.applyFilter}
                          className="btn my-0 bg-gradient-dark d-block"
                        >
                          Apply Filters
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                {/*<div>*/}
                {/*</div>*/}
              </div>
              <div className="table-responsive w-100">
                {this.state.employee ? (
                  <table
                    className="table"
                    width="100%"
                    style={{ overflowX: "auto" }}
                  >
                    <thead>
                      <tr style={{ fontSize: "8px" }}>
                        <th>NAME</th>
                        <th>ASSIGN STATE</th>
                        <th>ASSIGN CITY</th>
                        <th>STATUS</th>
                        <th>DIVISION</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filterEmployees
                        ? this.state.filterEmployees.map((emp, index) =>
                          index <= this.state.quan ? (
                            <tr>
                              <td>
                                <div className="pro-mv d-flex align-items-center">
                                  <div className="profile-mv d-flex align-items-center">
                                    <img
                                      className="m-0"
                                      src={require("../../../kavi/images/profile.png")}
                                      alt="Profile Image"
                                    />
                                  </div>
                                  <p className="m-0 ps-2">
                                    {emp.last_name ? emp.first_name : null}
                                    <br />
                                    {emp.last_name ? emp.last_name : null}
                                  </p>
                                </div>
                              </td>
                              <td width="25%">
                                <div
                                  style={{
                                    height:
                                      emp.state.length >= 3
                                        ? "200px"
                                        : "none",
                                    overflow: "overlay",
                                    display: "block",
                                  }}
                                >
                                  {emp.state.map((st, index) => (
                                    <div>
                                      {/*<p className="text-sm text-center font-weight-normal mb-0">*/}
                                      {st.state}
                                      {/*</p>*/}
                                      {emp.state.length > 1 ? <hr /> : null}
                                    </div>
                                  ))}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    height: "23px",
                                    overflow: "scroll",
                                    display: "block",
                                  }}
                                >
                                  {emp.station.map((st, index) => (
                                    <div>
                                      {/*<p className="text-sm text-center font-weight-normal mb-0">*/}
                                      {st.station ? st.station : null}
                                      {/*</p>*/}
                                      {emp.station.length > 1 ? <hr /> : null}
                                    </div>
                                  ))}
                                </div>
                              </td>
                              <td>
                                {emp.is_admin === true ? "Admin" : null}
                                {emp.is_manager === true ? "Manager" : null}
                                {emp.is_accountant === true
                                  ? "Accountant"
                                  : null}
                                {emp.is_executive === true
                                  ? "Executive"
                                  : null}
                                {emp.is_coordinator === true
                                  ? "Coordinator"
                                  : null}
                              </td>
                              <td>
                                {emp.division.map((div, index) => div.name)}
                              </td>
                              <td>
                                <div className="action d-flex justify-content-between align-items-center">
                                  <Link
                                    to={"/employee/employee-view/" + emp.id}
                                  >
                                    <img
                                      src={require("../../../kavi/icons/eye.png")}
                                      alt="actionImage"
                                    />
                                  </Link>
                                  <Link
                                    to={
                                      "/employee/edit-employee-admin/" +
                                      emp.id
                                    }
                                  >
                                    <img
                                      src={require("../../../kavi/icons/edit green.png")}
                                      alt="actionImage"
                                    />
                                  </Link>
                                  <img
                                    onClick={(e) =>
                                      this.delete(emp.first_name, emp.id)
                                    }
                                    src={require("../../../kavi/icons/delete.png")}
                                    alt="actionImage"
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : null
                        )
                        : null}
                    </tbody>
                  </table>
                ) : (
                  <Loader />
                )}
              </div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.open}
                onClose={(e) => this.setState({ open: false })}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={this.state.open}>
                  <Box sx={modalStyle}>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      {this.state.is_delete ? (
                        <>
                          <div className="circle__box">
                            <div className="circle__wrapper circle__wrapper--right">
                              <div className="circle__whole circle__right delete_circle__right"></div>
                            </div>
                            <div className="circle__wrapper circle__wrapper--left">
                              <div className="circle__whole circle__left delete_circle__left"></div>
                            </div>
                            <div className="circle__checkmark delete_circle__checkmark"></div>
                          </div>

                          <div style={{ textAlign: "center" }}>
                            <h5>Employee deleted successfully.</h5>
                            <h6>
                              <div
                                onClick={this.loadEmployeePageWithAxios}
                                style={{ color: "grey", cursor: "pointer" }}
                              >
                                Click here{" "}
                              </div>
                              to go Employee Dashboard
                            </h6>
                          </div>
                        </>
                      ) : (
                        <div className="modal-body">
                          <div style={{ textAlign: "center" }}>
                            <h5>
                              Click the Delete button to delete this Employee.
                            </h5>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={(e) => this.delete("delete")}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </Typography>
                  </Box>
                </Fade>
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
  user: state.userData.userData,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: (data) => dispatch(userLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmpDataTable);
