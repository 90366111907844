import React from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import '../../../custom/css/srkcutom.css'
import {axios, userLogin, modalStyle} from "../../../store/actions/actions";
import {states, all_divisions, stations} from "../../common/objects";
import Select from "react-select";
import Loader from "../../common/Loader/Loader";
import {Multiselect} from "multiselect-react-dropdown";
import GoogleMap from "../../GoogleMap/GoogleMap";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class EditEmployeeByAdmin extends React.Component {


    state = {

        employee: null,

        first_name: null,
        last__name: null,
        email: null,
        phone: null,
        dob: null,
        gender: null,

        profile: null,

        division: null,

        house_num: null,
        area: null,
        pin_code: null,
        street: null,
        city: null,
        state: null,

        image: null,
        photo: null,

        open: false,

        station: null,

        assign_states: null,

        selected_division: null,
        selected_division_list: null,

        selected_city: null,
        selected_city_list: null,

        selected_state: null,
        selected_state_list: null
    }

    style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };


    componentDidMount() {
        this.loadEmployee()
        this.toeknExpiryCheck()
    }

    toeknExpiryCheck = () => {
        var varDate = new Date(this.props.user.expiry_date);
        var today = new Date();

        if(today >= varDate) {
            window.localStorage.clear();
            this.props.userLoginHandler({ undefined })
        }

        let data = {
            emp_id: this.props.user.employee_id
        }

        let endpoint = `employee/check-employee-update`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Update Response data----------': responseData})
                if (responseData.success===true){
                    window.localStorage.clear();
                    this.props.userLoginHandler({ undefined })
                }
            })
    }

    loadEmployee = () => {
        let pathname = window.location.pathname;
        let pathurl = pathname.split("/");
        let path = pathurl[pathurl.length - 1];
        let endpoint = `employee/${path}`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res)=>{
                let responseData = res.data;
                this.setState({
                    employee: responseData.employee,
                    gender: responseData.employee.gender,
                    division: responseData.employee.division?responseData.employee.division:null,
                    station: responseData.employee.station?responseData.employee.station:null,
                    profile: responseData.employee.is_admin===true?'Admin':responseData.employee.is_manager===true?'Manger':responseData.employee.is_accountant===true?'Accountant':responseData.employee.is_coordinator===true?'Coordinator':'Executive',
                    area: responseData.employee.address[0]?responseData.employee.address[0].area:null,
                    street: responseData.employee.address[0]?responseData.employee.address[0].street:null,
                    pin_code: responseData.employee.address[0]?responseData.employee.address[0].pin_code:null,
                    house_num: responseData.employee.address[0]?responseData.employee.address[0].house_num:null,
                    city: responseData.employee.address[0]?responseData.employee.address[0].distt:null,
                    state: responseData.employee.address[0]?responseData.employee.address[0].state:null,
                    assign_states: responseData.employee.state?responseData.employee.state:null
                })
                console.log({'Emp response data---------':responseData.employee})
            })
    }

    checkStateStation = (state) => {
        const data = {
            state: state
        }
        let endpoint = `employee/check-state-station`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((res)=>{
                let responseData = res.data;
                if (responseData.result.length!==0){
                    this.setState({
                        exceptionState: responseData.result
                    })
                }else{
                    this.setState({
                        exceptionState: null
                    })
                }
                console.log({'State response result--------':responseData.result})
            })
    }

    onDivisionSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList',selectedList)
        console.log('selectedItem',selectedItem)
        this.setState({
            selected_division: selectedItem,
            selected_division_list: selectedList
        })
    }

    onDivisionRemove = (selectedList, removedItem) => {
        console.log('ON REMOVE----------------------->')
        console.log('selectedList',selectedList)
        console.log('removedItem',removedItem)
        this.setState({
            selected_division_list: selectedList
        })
    }

    removedStateList = []
    removedCityList = []

    onAssignCitySelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList',selectedList)
        console.log('selectedItem',selectedItem)
        this.setState({
            selected_city: selectedItem,
            selected_city_list: selectedList
        })
    }

    onAssignCityRemove = (selectedList, removedItem) => {
        this.removedCityList.push(removedItem)
        console.log('ON REMOVE----------------------->')
        console.log('selectedList',selectedList)
        console.log('removedItem',removedItem)
        console.log('removedList',this.removedCityList)
        this.setState({
            selected_city_list: selectedList,
        })
    }

    onAssignStateSelect = (selectedList, selectedItem) => {
        console.log('ON SELECT----------------------->')
        console.log('selectedList',selectedList)
        console.log('selectedItem',selectedItem)
        console.log('length of selectedItem', selectedList.length)
        this.checkStateStation(selectedList)
        this.setState({
            selected_state: selectedItem,
            selected_state_list: selectedList
        })
    }

    onAssignStateRemove = (selectedList, removedItem) => {
        this.removedStateList.push(removedItem)
        console.log('ON REMOVE----------------------->')
        console.log('selectedList',selectedList)
        console.log('removedItem',removedItem)
        console.log('removedList',this.removedStateList)
        this.checkStateStation(selectedList)
        this.setState({
            selected_state_list: selectedList,
            // remove_state_list: removedStateList
        })
    }

    submit = () => {
        const data = {
            emp_id: this.state.employee.id,
            fname: this.state.first_name?this.state.first_name:this.state.employee.first_name,
            lname: this.state.last_name?this.state.last_name:this.state.employee.last_name,
            email: this.state.email||this.state.employee.email,
            phone: this.state.phone||this.state.employee.phone,
            division: this.state.selected_division_list?this.state.selected_division_list:this.state.division,
            gender: this.state.gender||this.state.employee.gender,

            // station
            assign_state: this.state.selected_state_list===null?null:this.state.selected_state_list.length===0?'empty':this.state.selected_state_list,
            assign_city: this.state.selected_city_list===null?null:this.state.selected_city_list.length===0?'empty':this.state.selected_city_list,
            remove_state: this.removedStateList,
            remove_city: this.removedCityList,
            assign_area: this.state.map_address?[
                {map_address: this.state.map_address,
                lat: this.state.lat,
                long: this.state.long,
                state: this.state.area_state,
                city: this.state.area_city}
            ]:null,

            profile: this.state.profile,

            dob: this.state.dob || this.state.employee.dob,

            house_num: this.state.house_num,
            street: this.state.street,
            pin_code: this.state.pin_code,
            area: this.state.area,
            city: this.state.city,
            state: this.state.state,

            update: true
        }

        console.log(data)
        this.setState({
            open: true
        })

        let endpoint = `employee/`
        let token = this.props.user.token

        axios
            .put(endpoint, data, token)
            .then((res)=>{
                let responseData = res.data;
                // if(responseData.success===true){
                //     this.setState({
                //         open: true
                //     })
                // }
                console.log({'response data---------':responseData})
            })
    }

    // updatePassword = () => {
    //     const data = {
    //         pass1: this.state.pass1,
    //         pass2: this.state.pass2,
    //     }
    //
    //     console.log(data)
    //
    //     let endpoint = `employee/update-pass`
    //     let token = this.props.user.token
    //     axios
    //         .post(endpoint, data, token)
    //         .then((res)=>{
    //             let responseData = res.data;
    //             if(responseData.success===true){
    //                 this.setState({
    //                     open: true
    //                 })
    //             }
    //             console.log({'response data---------':responseData})
    //         })
    // }

    render() {
        return(
            <>
            {this.props.user.login===true?
                <>
                    {this.state.employee?
               <div className="container-fluid">
               <div className="row justify-content-end">
                   <div className={this.props.sidebar===undefined || this.props.sidebar===true?"col-md-10":"col-12"}>
                       {/*{this.state.employee?*/}
                       <div className="col-md-10 d-block m-auto mt-lg-0 mt-4">
                           {/*// <!-- Card Basic Info -->*/}
                           <div className="card mt-4 mb-4" id="basic-info">
                               <div className="card-header">
                                   <h5>Basic Info</h5>
                               </div>
                               <div className="card-body pt-0">

                                   <div className="row">
                                       <div className="col-6">
                                           <div className="input-group input-group-static">
                                               <label>First Name</label>
                                               <input type="text" className="form-control"
                                                      defaultValue={this.state.employee.first_name}
                                                  onChange={(e) => this.setState({first_name: e.target.value})}
                                               />
                                           </div>
                                       </div>
                                       <div className="col-6">
                                           <div className="input-group input-group-static">
                                               <label>Last Name</label>
                                               <input type="text" className="form-control"
                                                      defaultValue={this.state.employee.last_name}
                                                  onChange={(e) => this.setState({last_name: e.target.value})}
                                                  />
                                           </div>
                                       </div>
                                   </div>


                                   <div className="row mt-4">
                                       <div className="col-6">
                                           <div className="row">
                                                <div className="col-12 input-group input-group-static">
                                                    <label className="form-label">Gender</label>
                                                </div>
                                                <div className="col-12">
                                                <div className="dropdown mt-5">
                                                    <div  className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                                       data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                        {this.state.gender?this.state.gender:'Select Gender'}
                                                    </div>
                                                    <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                        >
                                                        <li>
                                                            <div className="dropdown-item" onClick={(e) => this.setState({gender: 'MALE'})}>
                                                                Male
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dropdown-item" onClick={(e) => this.setState({gender: 'FEMALE'})}>
                                                                Female
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                           </div>
                                       </div>
                                       <div className="col-6">
                                           <div className="row">
                                                <div className="col-12 input-group input-group-static">
                                                    <label className="form-label">Profile</label>
                                                </div>
                                                <div className="col-12">
                                                <div className="dropdown mt-5">
                                                    <div  className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                                       data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">

                                                        {this.state.profile?this.state.profile:'Select Profile'}
                                                    </div>
                                                    <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                        >
                                                        <li>
                                                            <div className="dropdown-item" onClick={(e) => this.setState({profile: 'admin'})}>
                                                                Admin
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dropdown-item" onClick={(e) => this.setState({ profile: 'accountant' })}>
                                                                Accountant
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dropdown-item" onClick={(e) => this.setState({profile: 'manager'})}>
                                                                Manager
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dropdown-item" onClick={(e) => this.setState({ profile: 'coordinator' })}>
                                                                Coordinator
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dropdown-item" onClick={(e) => this.setState({profile: 'executive'})}>
                                                                Executive
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                           </div>
                                       </div>
                                       <div className="col-6 mt-3">
                                           <div className="row">
                                                <div className="col-12 input-group input-group-static mb-5">
                                                    <label className="form-label">Division</label>
                                                </div>
                                                <div className="col-12">
                                                    {all_divisions?
                                                    <Multiselect
                                                        placeholder="Select Division"
                                                        options={all_divisions} // Options to display in the dropdown
                                                        selectedValues={this.state.division} // Preselected value to persist in dropdown
                                                        onSelect={this.onDivisionSelect} // Function will trigger on select event
                                                        onRemove={this.onDivisionRemove} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                        style={{
                                                            searchBox: {fontSize: "15px"}
                                                        }}

                                                    />
                                                        :null}
                                            </div>
                                           </div>
                                       </div>
                                        <div className="col-6 mt-4">
                                            <div className="input-group input-group-static is-filled">
                                                <label>D.O.B</label>
                                                <input type="date" className="form-control" onFocus="focused(this)"
                                                       defaultValue={this.state.employee.dob}
                                                       onChange={(e) => this.setState({dob: e.target.value})}
                                                       onfocusout="defocused(this)"/>
                                            </div>
                                        </div>
                                   </div>
                                   <div className="col-6 mt-4">
                                        <div className="col-12 input-group input-group-static">
                                            <label className="form-label">Address:</label>
                                        </div>
                                        <button className="w-50 btn srkw bg-gradient-dark dropdown-toggle mt-5" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            Current Address
                                        </button>
                                   </div>
                                   {this.state.employee.address.map((add, index)=>(
                                   <div className="collapse" id="collapseExample">
                                        <div className="col-12 card card-body">
                                             <div className="row mt-4">
                                                 <div className="col-6">
                                                    <div className="input-group input-group-static is-filled">
                                                        <label>House no:</label>
                                                        <input type="text" className="form-control" onFocus="focused(this)"
                                                                defaultValue={add.house_num}
                                                               onChange={(e) => this.setState({house_num: e.target.value})}
                                                               onfocusout="defocused(this)"/>
                                                    </div>
                                                 </div>
                                                 <div className="col-6">
                                                    <div className="input-group input-group-static is-filled">
                                                        <label>Street:</label>
                                                        <input type="tel" className="form-control" onFocus="focused(this)"
                                                       defaultValue={add.street}
                                                               onChange={(e) => this.setState({street: e.target.value})}
                                                               onfocusout="defocused(this)"/>
                                                    </div>
                                                 </div>
                                                 <div className="col-12 mt-3">
                                                    <div className="input-group input-group-static is-filled">
                                                        <label>Area:</label>
                                                        <input type="tel" className="form-control" onFocus="focused(this)"
                                                       defaultValue={add.area}
                                                               onChange={(e) => this.setState({area: e.target.value})}
                                                               onfocusout="defocused(this)"/>
                                                    </div>
                                                 </div>
                                                 <div className="col-6 mt-3">
                                                    <div className="input-group input-group-static is-filled">
                                                        <label>City:</label>
                                                        <input type="tel" className="form-control" onFocus="focused(this)"
                                                                defaultValue={add.distt}
                                                               onChange={(e) => this.setState({city: e.target.value})}
                                                               onfocusout="defocused(this)"/>
                                                    </div>
                                                 </div>
                                                 <div className="col-6 mt-3">
                                                    <div className="input-group input-group-static is-filled">
                                                        <label>Pin code:</label>
                                                        <input type="tel" className="form-control" onFocus="focused(this)"
                                                       defaultValue={add.pin_code}
                                                               onChange={(e) => this.setState({pin_code: e.target.value})}
                                                               onfocusout="defocused(this)"/>
                                                    </div>
                                                 </div>
                                                 <div className="col-6 mt-3">
                                                    <div className="input-group input-group-static is-filled">
                                                        <label>State:</label>
                                                          <Select
                                                                placeholder="Select State"

                                                                value={{value: this.state.state, label: this.state.state}}

                                                                options={states.map((st, index)=>(
                                                                    {value: st.state, label: st.state}
                                                                ))}

                                                                onChange={(e)=>this.setState({state: e.value})}
                                                          />
                                                    </div>
                                                 </div>
                                             </div>
                                        </div>
                                   </div>
                                   ))}
                                   <div className="row mt-4">
                                       <div className="col-6">
                                           <div className="input-group input-group-static">
                                               <label>Email</label>
                                               <input type="email" className="form-control lower-input"
                                                      defaultValue={this.state.employee.email}
                                                      onChange={(e) => this.setState({email: e.target.value})}
                                                      />
                                           </div>
                                       </div>
                                       <div className="col-6">
                                           <div className="input-group input-group-static">
                                               <label>Phone Number</label>
                                               <input type="text" className="form-control"
                                                      defaultValue={this.state.employee.phone}
                                                      onChange={(e) => this.setState({phone: e.target.value})}
                                                      />
                                           </div>
                                       </div>
                                   </div>
                               </div>


                                   <div className="card-header">
                                       <h5>Assign Station</h5>
                                   </div>

                                   <div className="card-body pt-0">
                                   <div className="row">
                                       <div className="col-6">
                                           <div className="row">
                                               {this.state.exceptionState?this.state.exceptionState.map((item, index) => (
                                               <p id="assign-state-exception"
                                                  style={{
                                                   color: "red"
                                                  }}>`* {item} state has not any object of station.`</p>
                                               )):null
                                               }
                                                <Multiselect
                                                    placeholder="Assign State"
                                                    options={states} // Options to display in the dropdown
                                                    selectedValues={this.state.assign_states} // Preselected value to persist in dropdown
                                                    onSelect={this.onAssignStateSelect} // Function will trigger on select event
                                                    onRemove={this.onAssignStateRemove} // Function will trigger on remove event
                                                    displayValue="state" // Property name to display in the dropdown options
                                                    style={{
                                                        searchBox: {fontSize: "15px"}
                                                    }}
                                                />
                                           </div>
                                       </div>
                                       <div className="col-6">
                                           <div className="row">
                                                <Multiselect
                                                    placeholder="Assign City"
                                                    options={stations} // Options to display in the dropdown
                                                    selectedValues={this.state.station} // Preselected value to persist in dropdown
                                                    onSelect={this.onAssignCitySelect} // Function will trigger on select event
                                                    onRemove={this.onAssignCityRemove} // Function will trigger on remove event
                                                    displayValue="station" // Property name to display in the dropdown options
                                                    style={{
                                                        searchBox: {fontSize: "15px"}
                                                    }}

                                                />
                                           </div>
                                       </div>
                                       <div className="col-12 ">
                                            <div className="dropdown mt-5">
                                                    <div  className="m-0 btn w-50 srkw bg-gradient-dark dropdown-toggle"
                                                       data-bs-toggle="dropdown" id="navbarDropdownMenuLink2" aria-expanded="false">
                                                        Assign Area
                                                    </div>
                                                    <ul className="dropdown-menu w-50 srkw" aria-labelledby="navbarDropdownMenuLink2"
                                                        >
                                                        <p className="mb-0 text-sm">
                                                          Tell us where are you living
                                                        </p>

                                                        <div className="google_map_class">
                                                            <GoogleMap parentCallback = {this.handleCallback} />
                                                        </div>
                                                    </ul>
                                                </div>
                                       </div>
                                    </div>
                                    </div>

                                   <div className="row my-4">
                                       <div className="col-12">
                                           <button className="btn bg-gradient-dark d-block m-auto"
                                                   onClick={this.submit}
                                           >Submit</button>
                                       </div>
                                   </div>
                           </div>
                       </div>


                                                    <Modal
                                                        aria-labelledby="transition-modal-title"
                                                        aria-describedby="transition-modal-description"
                                                        open={this.state.open}
                                                        onClose={(e)=>this.setState({open: false})}
                                                        closeAfterTransition
                                                        BackdropComponent={Backdrop}
                                                        BackdropProps={{
                                                          timeout: 500,
                                                        }}
                                                      >
                                                        <Fade in={this.state.open}>
                                                          <Box sx={modalStyle}>
                                                            {/*<Typography id="transition-modal-title" variant="h6" component="h2">*/}
                                                            {/*  Text in a modal*/}
                                                            {/*</Typography>*/}
                                                            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                                <div className="modal-body">
                                                                    <div className="circle__box">
                                                                        <div className="circle__wrapper circle__wrapper--right">
                                                                            <div className="circle__whole circle__right"></div>
                                                                        </div>
                                                                        <div className="circle__wrapper circle__wrapper--left">
                                                                            <div className="circle__whole circle__left"></div>
                                                                        </div>
                                                                        <div className="circle__checkmark">
                                                                        </div>
                                                                    </div>
                                                                    <div style={{textAlign: 'center'}}>
                                                                        <h5>Employee Updated Successfully.</h5>
                                                                        <h6><Link to='/tada' style={{color: "grey"}}>Click here </Link> for go to employee dashboard.</h6>
                                                                    </div>
                                                                </div>
                                                            </Typography>
                                                          </Box>
                                                        </Fade>
                                                    </Modal>
                   </div>
               </div>
               </div>
                        :
                        <Loader/>}
            </>
            :
                <Redirect to={'/sign-in'}/>
            }
            </>

        )
    }
}


const mapStateToProps = state =>({
    user:state.userData.userData,
    sidebar:state.sideBarData.sidebarData.sidebar
})

const mapDispatchToProps = dispatch => ({
    userLoginHandler: data => dispatch(userLogin(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployeeByAdmin)

