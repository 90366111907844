import React from "react";
import {connect} from "react-redux";
import "../common/common.css"
import {Link} from "react-router-dom";

class PageNotFound extends React.Component{

    componentDidMount() {
        let p = document.querySelector("body")
        p.classList.add("page-not-found")
    }

    render() {
        return(
            <>
                <div className="container-fluid mt-7">
                    <div className="row justify-content-end">
                        <div
                            className={this.props.sidebar === undefined || this.props.sidebar === true ? "col-10 me-5" : "col-12 me-5"}>
                                <section className="page_404">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 ">
                                <div className="col-sm-offset-1  text-center" style={{width: "102%"}}>

                                    <div className="four_zero_four_bg">
                                        <h1 className="text-center "></h1>
                                    </div>

                                    <div className="contant_box_404">
                                        <h3 className="h2">
                                            Whoops!
                                        </h3>

                                        <p>There seems to be a problem with you Network Connection</p>

                                        <Link to="create-customer" className="link_404" style={{borderRadius: '35px', width: "150px"}}>Try Again</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
  user:state.userData.userData,
  sidebar: state.sideBarData.sidebarData.sidebar,
});

export default connect(mapStateToProps)(PageNotFound);