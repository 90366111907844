import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import "../../../custom/css/srkcutom.css";
import DataCards from "./DataCards";
// import GraphCards from "./GraphCards";
import SalesTable from "./SalesTable";
import {
  axios,
  google_map_api_key,
  modalStyle,
} from "../../../store/actions/actions";
import { Stack, Skeleton } from "@mui/material";
import TempEmpDataTable from "./TempEmpDataTable";
// import ScheduledVisitDataTable from "../HomePage/ScheduledVisitDataTable";
// import EmployeeDataTable from "./EmployeeDataTable";
import ScheduledVisitDataTable from "../VisitDash/ScheduledVisitDataTable";

class EmployeeDash extends React.Component {
  state = {
    scheduledVisits: null,
  };

  componentDidMount() {
    let a = "sharukh khan";
    console.log(a);
    // this.loadScheduledVisits();
  }

  render() {
    return (
      <>
        {this.props.user.login === true ? (
          <>
            <div className="container-fluid homepage-view">
              <div className="row justify-content-end">
                <div
                  className={
                    this.props.sidebar === undefined ||
                    this.props.sidebar === true
                      ? "d-none d-lg-block col-10"
                      : "d-none d-lg-block col-12"
                  }
                >
                  {this.props.user.employee_profile !== "admin" ? null : (
                    <>
                       <DataCards />
                       <SalesTable />
                    </>
                  )}

                 
                  {/*<GraphCards />*/}
                
                  {this.props.user.employee_profile === "executive" ? null : (
                    <>
                      <TempEmpDataTable />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="d-lg-none px-2">
              <div className="dashOption-mv mt-4">
                <div className="">
                  <div className="button-bar-mv d-flex">
                    <Link to="/employee" className="btn btn-mv active">
                      <img
                        src={require("../../../kavi/icons/dashbord.png")}
                        alt="btn-icon"
                      />
                      Dashboard
                    </Link>
                    <Link
                      to={
                        "/employee/edit-employee/" + this.props.user.employee_id
                      }
                      className="btn d-none btn-mv"
                    >
                      <img
                        src={require("../../../kavi/icons/edit.png")}
                        alt="btn-icon"
                      />
                      Edit
                    </Link>
                    <Link
                      to="/create-station"
                      className="btn btn-mv"
                    >
                      <img
                        src={require("../../../kavi/icons/edit.png")}
                        alt="btn-icon"
                      />
                      Create Station
                    </Link>
                    {/* {this.props.user.employee_profile === "admin" ? (
                      <Link
                        to="/employee/create-employee"
                        className="btn btn-mv"
                      >
                        <img
                          src={require("../../../kavi/icons/add.png")}
                          alt="btn-icon"
                        />
                        Create
                      </Link>
                    ) : null} */}
                    {/* <Link to="/create-station" className="btn btn-mv">
                      <img
                        src={require("../../../kavi/icons/add.png")}
                        alt="btn-icon"
                      />
                      Create Station
                    </Link> */}
                    {/* {this.props.user.employee_profile === "admin" ||
                    this.props.user.employee_profile === "accountant" ? (
                      <Link
                        to="/employee/employee-transactions"
                        className="btn btn-mv"
                      >
                        <img
                          src={require("../../../kavi/icons/add.png")}
                          alt="btn-icon"
                        />
                        Transactions
                      </Link>
                    ) : null} */}
                  </div>
                </div>
              </div>

              {/* : null} */}

              {/* <DataCards /> */}

              {/*<GraphCards />*/}
              {/* <SalesTable /> */}
              {this.props.user.employee_profile === "executive" ? null : (
                <>
                  <div className="">
                    <TempEmpDataTable />
                  </div>
                </>
              )}

              <div className="col-12 mt-3">
                <ScheduledVisitDataTable />
              </div>
            </div>
          </>
        ) : (
          <Redirect to={"/sign-in"} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sideBarData.sidebarData.sidebar,
  user: state.userData.userData,
});

export default connect(mapStateToProps)(EmployeeDash);
