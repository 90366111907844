import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar'
import React from 'react';
import EmployeeDash from "./components/DashBoard/EmployeeDash/EmployeeDash";
import VisitsDash from "./components/DashBoard/VisitDash/VisitsDash";
import TeamDash from "./components/DashBoard/TeamDash/TeamDash";
import CustomerDash from "./components/DashBoard/Customer/CustomerDash";
import CreateCustomer from "./components/DashBoard/Customer/CreateCustomer";
import EditCustomer from "./components/DashBoard/Customer/EditCustomer";
import EditEmployee from "./components/DashBoard/EmployeeDash/EditEmployee";
import CreateEmployee from "./components/DashBoard/EmployeeDash/CreateEmployee";
import CreateVisit from "./components/DashBoard/VisitDash/CreateVisit";
import EditVisit from "./components/DashBoard/VisitDash/EditVisit";
import TripDash from "./components/DashBoard/TripDash/TripDash";
import EditTrip from "./components/DashBoard/TripDash/EditTrip";
import CreateTrip from "./components/DashBoard/TripDash/CreateTrip";
import ViewTrips from "./components/DashBoard/TripDash/ViewTrips";
import CreateTada from "./components/DashBoard/TadaDash/CreateTada";
import NewCreateVisit from "./components/DashBoard/VisitDash/NewCreateVisit";
import TeamView from "./components/DashBoard/TeamDash/TeamView";
import ProjectView from "./components/DashBoard/TeamDash/ProjectView";
import CustomerDetail from "./components/DashBoard/Customer/CustomerDetail";
import CreateTeam from "./components/DashBoard/TeamDash/CreateTeam";
import EditTeam from "./components/DashBoard/TeamDash/EditTeam";
import SignIn from "./components/SignIn/SignIn";
import VisitView from "./components/DashBoard/VisitDash/VisitView";
import EditEmployeeByAdmin from "./components/DashBoard/EmployeeDash/EditEmployeeByAdmin";
import CreateStation from "./components/common/CreateStation";
import EmployeeView from "./components/DashBoard/EmployeeDash/EmployeeView";
import TourFund from "./components/DashBoard/TourFund/TourFundDash";
import TourFundRequest from "./components/DashBoard/TourFund/TourFundRequest";
import ViewTourFundRequest from "./components/DashBoard/TourFund/ViewTourFundRequest";
import EditTourFundRequest from "./components/DashBoard/TourFund/EditTourFundRequest";
import TadaDash from "./components/DashBoard/TadaDash/TadaDash";
import ViewTeamAccount from "./components/DashBoard/Account/CreateAnnualBudget";
import TeamTransactions from "./components/DashBoard/Account/TeamTransactions";
import EmployeeTransactions from "./components/DashBoard/EmployeeDash/EmployeeTransactions";
import TadaView from "./components/DashBoard/TadaDash/TadaView";
import CreateTadas from './components/DashBoard/TadaDash/CreateTadas';
import Report from "./components/DashBoard/ReportsDash/Report";
import SampleRequest from "./components/DashBoard/SampleRequest/SampleRequest";
import Punch from "./components/DashBoard/Attendence/Punch";
import SampleRequestDash from "./components/DashBoard/SampleRequest/SampleRequestDash";
import AttendanceDash from "./components/DashBoard/Attendence/AttendanceDash";
import NewCreateTada from "./components/DashBoard/TadaDash/NewCreateTada";
import Punch2 from "./components/DashBoard/Attendence/Punch2";
import HomePageDash from "./components/DashBoard/HomePage/HomePageDash";
import VisitView2 from "./components/DashBoard/VisitDash/VisitView2";
import MapActivity from "./components/DashBoard/EmployeeDash/MapActivity";
import SRDataTable from "./components/DashBoard/SampleRequest/SRDataTable";
import HomePageDash2 from "./components/DashBoard/HomePage/HomePageDash2";
import ScheduleVisit from "./components/DashBoard/VisitDash/ScheduleVisit";
import TourPlan from "./components/DashBoard/VisitDash/TourPlan";
import SignUp from "./components/SignIn/SignUp";
import LeafMapActivity from './components/DashBoard/EmployeeDash/LeafMapActivity';
import VModal from './components/common/ValidationModal/VModal';
import Srktesting from './components/common/Srktesting';
import SRF from './components/SRF/SRF';
import Crudsrf from './components/SRF/Crudsrf';
import Srfedit from './components/SRF/Srfedit';
import Kanban from './components/SRF/Kanban';
import FarziCustomer from './components/DashBoard/Customer/FarziCustomer';
import GoogleAPITesting from './components/DashBoard/Customer/GoogleAPITesting';



class App extends React.Component {

    render() {
        return (
            <Router>
                <Navbar />
                    <Switch>
                        <Route exact path='/sign-in' component={SignIn}/>
                        <Route exact path='/sign-up' component={SignUp}/>

                        <Route exact path='/' component={HomePageDash}/>
                        {/* To be Delete  */}
                        <Route exact path='/srk-crud-api' component={Srktesting}/>

                        <Route exact path='/create-station' component={CreateStation}/>

                        <Route exact path='/account/team-account' component={ViewTeamAccount}/>
                        <Route exact path='/account/team-transactions' component={TeamTransactions}/>

                        <Route exact path='/employee' component={EmployeeDash}/>
                        <Route exact path='/employee/edit-employee/:id' component={EditEmployee}/>
                        <Route exact path='/employee/edit-employee-admin/:id' component={EditEmployeeByAdmin}/>
                        <Route exact path='/employee/create-employee' component={CreateEmployee} />
                        <Route exact path='/employee/employee-view/:id' component={EmployeeView}/>
                        <Route exact path='/employee/employee-transactions' component={EmployeeTransactions}/>

                        <Route exact path='/visit' component={VisitsDash}/>
                        <Route exact path='/visit/create-visit' component={CreateVisit}/>
                        <Route exact path='/visit/create-new-visit' component={NewCreateVisit}/>
                        <Route exact path='/visit/create-visit' component={CreateVisit}/>
                        <Route exact path='/visit/schedule-visit' component={ScheduleVisit}/>
                        <Route exact path='/visit/edit-visit/:id' component={EditVisit}/>

                        {/*<Route exact path='/visit/visit-view/:id' component={VisitView}/>*/}
                        <Route exact path='/visit/visit-view/:id' component={VisitView2}/>

                        <Route exact path='/tada' component={TadaDash} />
                        <Route exact path='/tada/create-tada' component={CreateTadas} />
                        <Route exact path='/tada/view-tada/:id' component={TadaView} />

                        <Route exact path='/team' component={TeamDash} />
                        <Route exact path='/team/create-team' component={CreateTeam} />
                        <Route exact path='/team/edit-team/:id' component={EditTeam} />
                        <Route exact path='/team/team-view/:id' component={TeamView} />
                        <Route exact path='/team/team-view/:id/project-view/:id' component={ProjectView} />

                        <Route exact path='/customer' component={CustomerDash} />
                        <Route exact path='/customer/edit-customer/:id' component={EditCustomer} />
                        <Route exact path='/customer/create-customer' component={CreateCustomer} />
                        <Route exact path='/customer/customer-detail/:id' component={CustomerDetail} />

                        <Route exact path='/trip' component={TripDash}/>
                        <Route exact path='/trip/create-trip' component={CreateTrip}/>
                        <Route exact path='/trip/edit-trip/:id' component={EditTrip}/>
                        <Route exact path='/trip/view-trip/:id' component={ViewTrips}/>

                        <Route exact path='/tour' component={TourFund}/>
                        <Route exact path='/tour/request' component={TourFundRequest}/>
                        <Route exact path='/tour/view/:id' component={ViewTourFundRequest}/>
                        <Route exact path='/tour/edit/:id' component={EditTourFundRequest}/>

                        <Route exact path='/report' component={Report}/>

                        <Route exact path='/sample' component={SRDataTable}/>
                        {/* <Route exact path='/sample' component={SampleRequestDash}/> */}
                        <Route exact path='/sample/sample-request' component={SampleRequest}/>
                        <Route exact path='/sample/view-sample-request' component={SRDataTable}/>

                        <Route exact path='/attendance' component={AttendanceDash}/>
                        <Route exact path='/attendance/punch' component={Punch}/>

                        <Route exact path='/find' component={Punch2}/>

                        {/* <Route exact path='/map' component={MapActivity}/> */}
                        <Route exact path='/map' component={LeafMapActivity}/>
                        <Route exact path='/tour-plan' component={TourPlan}/>

                        {/* Testing */}
                        <Route exact path='/srk-testing' component={VModal}/>

                        {/* Service request Form  */}
                        <Route exact path='/SRF' component={SRF}/>
                        <Route exact path='/edit-srf/:id' component={Srfedit}/>
                        <Route exact path='/SRF-SRK' component={Crudsrf}/>

                        <Route exact path='/kanban' component={Kanban}/>


                        {/* Farzi  */}
                        <Route exact path='/farzilat' component={FarziCustomer}/>
                        <Route exact path='/googleapisrk' component={GoogleAPITesting}/>

                    </Switch>
            </Router>
        );
    }
}

export default App;