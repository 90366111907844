import axios from 'axios';
import React, { Component } from 'react'
import { connect } from "react-redux";
// import axios from 'axios';
// import Pagination from '@mui/material/Pagination';
// import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";

class Testingsrk extends Component {

    state = {
        box1:null,
        box2:null,
        box3:null,
        box4:null,
    }
    
    componentDidMount(){
        this.box_1()
    }

    box_1 = () => {
        let endpoint = "common/test"
        let params = {
            name: "data"
        }
        axios
            .get(endpoint)
            .then((res) => {
                console.log("Response AA Gya BHai", res)
            })
    }

    render() {
        return (
            <>
                <div className="container-fluid homepage-view">
                    <div className="row justify-content-end">
                        <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "d-none d-lg-block col-10" : "d-none d-lg-block col-12"}>
                            {/* Section One */}
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card flex-row justify-content-center align-items-center" style={{ height: "120px" }}>
                                        <h5>Box-1</h5>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card flex-row justify-content-center align-items-center" style={{ height: "120px" }}>
                                        <h5>Box-1</h5>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card flex-row justify-content-center align-items-center" style={{ height: "120px" }}>
                                        <h5>Box-1</h5>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card flex-row justify-content-center align-items-center" style={{ height: "120px" }}>
                                        <h5>Box-1</h5>
                                    </div>
                                </div>
                            </div>

                            {/* section Two  */}
                            <div className="mb-5 row my-3" id="clients">
                  <div className="col-12">
                      <div className="card py-3">
                              <div className="table-responsive px-2">
                                  <div
                                      className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                      <div className="dataTable-top">

                                          <h5 className="mb-0 ps-3" >Visits</h5>
                                      </div>
                                      <div className="dataTable-container" id='employeeTable'>
                                          
                                          <>
                                          <table className="table align-items-center mb-3 dataTable-table">
                                              <thead>
                                              <tr>
                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "4.8032%", cursor: 'pointer'}}>
                                                      <a
                                                      // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                      className="dataTable-sorter"
                                                      onClick={(e)=>this.sortEmpTable("serial_num")}>S.NO
                                                  </a></th>
                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("name")}
                                                  ><a
                                                      className="dataTable-sorter">Name
                                                  </a></th><th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("name")}
                                                  ><a
                                                      className="dataTable-sorter">Customer
                                                  </a></th>
                                                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("state")}
                                                      >
                                                      <a
                                                      className="dataTable-sorter text-center">
                                                      Purpose</a></th>
                                                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                      data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                      onClick={(e)=>this.sortEmpTable("status")}
                                                      >
                                                      <a
                                                      className="dataTable-sorter">
                                                      Question</a></th>
                                              </tr>
                                              </thead>
                                             
                                          </table>
                                          </>
                                      </div>
                                  </div>
                              </div>
                      </div>
                  </div>
            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userData.userData,
    sidebar: state.sideBarData.sidebarData.sidebar,
})


export default connect(mapStateToProps)(Testingsrk)

