import React from "react";
import { connect } from "react-redux";
import {Redirect} from "react-router-dom";
import '../../../custom/css/srkcutom.css'
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import {axios, url} from "../../../store/actions/actions";
import SubmitedSamplesDataTable from "./SubmitedSamplesDataTable";


class SampleRequestDash extends React.Component {

    state = {
        all_employee: null,
        samples: null,
        filterSamples: null,
        page: null,
        customers: null,
        books: null,

        filter: false,
        showMainfilter: false,
        f_employee: null,
        f_customer: [],
        f_book: [],

        pageNum: null,
        from: 0,
        to: 10,
    }

    componentDidMount() {
        this.loadSubmitedSamples()
        this.loadSelectEmployees()
        this.loadSelectBooks()
        this.loadSelectCustomers()
    }

    filterButton = () => {
        this.setState({
            showMainfilter: this.state.showMainfilter===true?false:true,
            f_employee: this.state.showMainfilter===false?null:this.state.f_employee,
            f_book: this.state.showMainfilter===false?[]:this.state.f_book,
            filter: false,
            // f_city: this.state.showMainfilter===false?null:this.state.f_city,
            // f_purpose: this.state.showMainfilter===false?null:this.state.f_purpose,
            // f_quest: this.state.showMainfilter===false?null:this.state.f_quest
        })
    }

    loadSubmitedSamples = () => {
        let endpoint = `books/get-submit-sample`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Samples Response data---------------->': responseData})
                const objWithIndex = responseData.samples.map((obj, index)=>(Object.assign(obj,{index: index+1})))
                this.setState({
                    samples: objWithIndex,
                    filterSamples: objWithIndex,
                    page: Math.ceil(responseData.total/10),
                })
            })
    }

    loadSelectCustomers = () => {
        let endpoint = `customer/select-customer`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Customers Response data------------>': responseData})
                this.setState({
                    customers: responseData.customers
                })
            })
    }

    loadSelectBooks = () => {
        let endpoint = `books/get-books`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((res) => {
                let responseData = res.data;
                console.log({ 'Books Response data------------>': responseData})
                this.setState({
                    books: responseData.books
                })
            })
    }

    loadSelectEmployees = () => {
        let endpoint = `/account/get-employees`
        let token = this.props.user.token

        axios
          .get(endpoint, token)
          .then((res)=>{
            let responseData = res.data;
            this.setState({
              all_employee: responseData.employee
            })
            console.log({'Employee data---------':responseData.employee})
          })
    }

    applyFilter = (e, p) => {
        console.log('==========filter==========')
        console.log('<====================>',p)
        this.setState({
            filter: true,
            clear: true,
            showMainfilter: false,
            pageNum: p,
        })

        // let endpoint = `books/filter-submitted-samples?employee_in=${[this.state.f_employee]}&customer_in=${[...new Set(this.state.f_customer)]}&book_in=${[...new Set(this.state.f_book)]}&from_date=${this.state.fromDate?this.state.fromDate:''}&to_date=${this.state.toDate?this.state.toDate:''}&page_from=${p===1||p===undefined?0:(p-1)*10}&page_to=${p===1||p===undefined?10:p*10}`
        let endpoint = `books/filter-submitted-samples?from_date=${this.state.fromDate?this.state.fromDate:''}&to_date=${this.state.toDate?this.state.toDate:''}&page_from=${p===1||p===undefined?0:(p-1)*10}&page_to=${p===1||p===undefined?10:p*10}`
        let token = this.props.user.token

        axios
            .get(endpoint, token)
            .then((response) => {
                const responseData = response.data;
                this.setState({
                    filterSamples: null,
                    page: null,
                })
                console.log({'Filtered response data---------': responseData})
                let fa = setTimeout(e =>this.filterAction(responseData), 100)
            })
            .catch(function (error) {
              console.log(error);
            });
    }

    filterAction = (data) => {
        const objWithIndex = data.samples.map((obj, index)=>(Object.assign(obj,{index: index+1})))
        this.setState({
            filterSamples: objWithIndex,
            page: Math.ceil(data.total/10),
        })
    }

    handlePage = (e, p) => {
        console.log('--------------------',p)
        const data = {
            from: p===1?0:(p-1)*10,
            to: p===1?10:p*10,
        }
        console.log('--------------------',data)
        let endpoint = `books/submitted-samples-pagination`
        let token = this.props.user.token

        axios
            .post(endpoint, data, token)
            .then((response) => {
                const responseData = response.data;
                console.log({'pagination response data---------': responseData})
                this.setState({filterSamples: responseData.samples})
            });
    }

    render() {
        return (
            <>
                {this.props.user.login === true ?
                  <>
                    <div className="container-fluid homepage-view">
                      <div className="row justify-content-end">
                        <div className={this.props.sidebar === undefined || this.props.sidebar === true ? "d-none d-lg-block col-10" : "d-none d-lg-block col-12"}>
                          <div className=''>
                            <div className="f-btn d-flex justify-content-end mb-2">
                                <button className="ms-auto" onClick={this.filterButton}>Filter</button>
                            </div>

                            {this.state.showMainfilter===true?
                                <div className="card f-card p-4" style={{width: "700px", position: "absolute", top: "142px", right: "10px", zIndex: "10", boxShadow: "0px 0px 6px #bdbbbb"}}>
                                  <h6>FILTERS</h6>
                                  {/*{this.state.clear?<p onClick={this.clearFilter} style={{color: "#5f5fcb", cursor: "pointer"}}>Clear</p>:null}*/}
                                  <div className="row p-1">
                                      <div className="col-12 mt-2">
                                            <div className="d-flex align-items-center"  >
                                      <TextField
                                        id="date"
                                        label="From"
                                        type="date"
                                        size="small"
                                        fullWidth
                                        onChange={(e) => this.setState({fromDate: e.target.value})}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                      <p className="mx-4 my-0" style={{fontSize: "24px"}}>to</p>

                                      <TextField
                                        id="date"
                                        label="To"
                                        type="date"
                                        size="small"
                                        onChange={(e) => this.setState({toDate: e.target.value})}
                                        fullWidth
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                  </div>
                                      </div>
                                      <div className="col-12 mt-4">
                                            {this.state.all_employee ?
                                                <Autocomplete
                                                    disablePortal
                                                    size='small'
                                                    id="combo-box-demo"
                                                    options={this.state.all_employee}
                                                    onChange={(e, value)=>this.setState({f_employee: value.first_name})}
                                                    getOptionLabel={(option) => option.last_name?option.first_name+' '+option.last_name:option.first_name}
                                                    // sx={{ width: 300 }}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{
                                                            '& > img': {
                                                                mr: 2,
                                                                flexShrink: 0
                                                            }
                                                        }} {...props}>
                                                            <img
                                                                style={{borderRadius: '50%'}}
                                                                // loading="lazy"
                                                                width="30"
                                                                src={option.pic?url + option.pic:url+'/static/agent.png'}
                                                                // srcSet={`${url + option.pic} 2x`}
                                                                alt=""
                                                            />
                                                            {option.last_name?option.first_name+' '+option.last_name:option.first_name}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                                   label="Select Employee"/>}
                                                />
                                            : null}
                                      </div>
                                      <div className="col-12 mt-3">
                                            {this.state.customers ?
                                                <Autocomplete
                                                    multiple
                                                    size='small'
                                                    id="tags-standard"
                                                    options={this.state.customers}
                                                    onChange={(e, value)=>value.map((item)=>(this.state.f_customer.push(item.id)))}
                                                    getOptionLabel={(option) => option.company}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                                   label="Select Customer"/>}
                                                />
                                            : null}
                                      </div>

                                      <div className="col-12 mt-3">
                                            {this.state.books ?
                                                <Autocomplete
                                                    multiple
                                                    size='small'
                                                    id="tags-standard"
                                                    options={this.state.books}
                                                    onChange={(e, value)=>value.map((item)=>(this.state.f_book.push(item.id)))}
                                                    getOptionLabel={(option) => option.title}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                                   label="Select Book"/>}
                                                />
                                            : null}
                                      </div>


                                      {/*<div className="col-6 mt-4">*/}
                                      {/*    {this.state.states ?*/}
                                      {/*    <Autocomplete*/}
                                      {/*      multiple*/}
                                      {/*      size='small'*/}
                                      {/*      id="tags-standard"*/}
                                      {/*      options={this.state.states}*/}
                                      {/*      getOptionLabel={(option) => option.state}*/}
                                      {/*      onChange={(e, value)=>value.map((item)=>(this.state.f_state.push(item.state)))}*/}
                                      {/*      renderInput={(params) => (*/}
                                      {/*        <TextField*/}
                                      {/*          {...params}*/}
                                      {/*          label="Select State"*/}
                                      {/*          // placeholder="Favorites"*/}
                                      {/*        />*/}
                                      {/*      )}*/}
                                      {/*    />*/}
                                      {/*    :null}*/}
                                      {/*</div>*/}
                                      {/*<div className="col-6 mt-4">*/}
                                      {/*  {this.state.cities ?*/}
                                      {/*    <Autocomplete*/}
                                      {/*      multiple*/}
                                      {/*      size='small'*/}
                                      {/*      id="tags-standard"*/}
                                      {/*      options={this.state.cities}*/}
                                      {/*      getOptionLabel={(option) => option.station}*/}
                                      {/*      onChange={(e, value)=>value.map((item)=>(this.state.f_city.push(item.station)))}*/}
                                      {/*      renderInput={(params) => (*/}
                                      {/*        <TextField*/}
                                      {/*          {...params}*/}
                                      {/*          label="Select City"*/}
                                      {/*          // placeholder="Favorites"*/}
                                      {/*        />*/}
                                      {/*      )}*/}
                                      {/*    />*/}
                                      {/*    :null}*/}
                                      {/*</div>*/}
                                  </div>
                                  <div className="d-flex justify-content-end mt-3">
                                      <button onClick={this.applyFilter} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>
                                      {/*<button onClick={this.test} className="btn my-0 bg-gradient-dark d-block">Apply Filters</button>*/}
                                  </div>
                              </div>
                            :null}

                            {/*{this.state.cards?*/}
                            {/*    <SSDataCards  data={this.state.cards} parentCallback={this.handleCallback}/>*/}
                            {/*:*/}
                            {/*    <div>*/}
                            {/*    <div className='row'>*/}
                            {/*    <div className='col-2'>*/}
                            {/*        <Stack spacing={1}>*/}
                            {/*          <Skeleton variant="rounded" style={{height: '257px', borderRadius: '12px'}} />*/}
                            {/*        </Stack>*/}
                            {/*    </div>*/}
                            {/*    <div className='col-5'>*/}
                            {/*        <Stack spacing={1}>*/}
                            {/*          <Skeleton variant="text"  sx={{ fontSize: '1rem', height: '45px' }}/>*/}
                            {/*          <Skeleton variant="text"  sx={{ fontSize: '1rem', height: '45px'  }}/>*/}
                            {/*          <Skeleton variant="text"  sx={{ fontSize: '1rem' , height: '45px' }}/>*/}
                            {/*          <Skeleton variant="text"  sx={{ fontSize: '1rem', height: '45px'  }}/>*/}
                            {/*          <Skeleton variant="text"  sx={{ fontSize: '1rem' , height: '45px' }}/>*/}
                            {/*        </Stack>*/}
                            {/*    </div>*/}
                            {/*    <div className='col-5'>*/}
                            {/*        <div className='row'>*/}
                            {/*            <div className="col-6">*/}
                            {/*              <Skeleton variant="rounded" style={{height: '100px', borderRadius: '12px'}} />*/}
                            {/*            </div>*/}
                            {/*            <div className="col-6">*/}
                            {/*              <Skeleton variant="rounded" style={{height: '100px', borderRadius: '12px'}} />*/}
                            {/*            </div>*/}
                            {/*            <div className="col-6 mt-5">*/}
                            {/*              <Skeleton variant="rounded" style={{height: '100px', borderRadius: '12px'}} />*/}
                            {/*            </div>*/}
                            {/*            <div className="col-6 mt-5">*/}
                            {/*              <Skeleton variant="rounded" style={{height: '100px', borderRadius: '12px'}} />*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*}*/}
                              {this.state.filterSamples?
                                  <SubmitedSamplesDataTable pageNum={this.state.pageNum} samples={this.state.filterSamples} page={this.state.page} pagination={this.state.filter===false?this.handlePage:this.applyFilter}/>
                              :null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <Redirect to={'/sign-in'} />
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    sidebar: state.sideBarData.sidebarData.sidebar,
    user: state.userData.userData
})

export default connect(mapStateToProps)(SampleRequestDash)