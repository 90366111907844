import React from 'react'
import Contorlled from './Contorlled'
import Uncontorlled from './Uncontorlled'

function Kanban() {
  return (
    <>
    {/* <Contorlled /> */}
    <Uncontorlled />
    </>
  )
}

export default Kanban