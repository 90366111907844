import React from "react";
import { Stack, Skeleton } from "@mui/material";

function Loadingfade(props) {
  return (
    <>
      <Stack spacing={props.spacing}>
        <Skeleton
          variant={props.variant}
          style={{ height: `${props.height}`, width:`${ props.width}` }}
        />
      </Stack>
    </>
  );
}

export default Loadingfade;
