import React from "react";
import {url} from "../../../store/actions/actions";
import {connect} from "react-redux";
import Pagination from "@mui/material/Pagination";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

class SampleDataTable extends React.Component {

    state = {
        samples: null,
        filterSamples: null,
        pages: null
    }

    componentDidMount() {
        this.setState({
            samples:this.props.samples,
            filterSamples: this.props.samples.slice(0, 10).map(i => {return i}),
            pages: Math.ceil(this.props.samples.length/10)
        })
    }

    handelPagination = (event, value) => {
        let start = 10 * (value - 1)
        let end = 10 * value
        let items = this.state.samples.slice(start, end).map(i => {
            return i
        });

        this.setState({
            filterEmployees: items,
        })
        console.log('items', items)
    }

    render() {
    return(
      <>
        <div className="row my-3" id="clients">
              <div className="col-12">
                  <div className="card py-3">
                          <div className="table-responsive px-2">
                              <div
                                  className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                                  <div className="dataTable-top">

                                      <h5 className="mb-0 ps-3" >Samples</h5>
                                  </div>
                                  <div className="dataTable-container" id='employeeTable'>
                                      <table className="table align-items-center mb-3 dataTable-table">
                                          <thead>
                                          <tr>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "4.8032%", cursor: 'pointer'}}>
                                                  <a
                                                  // href="reactify-ui/src/components/DashBoard/Customer/CustomerCard#"
                                                  className="dataTable-sorter">S.NO
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("name")}
                                              ><a
                                                  className="dataTable-sorter">Employee
                                              </a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("state")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter text-center">
                                                  Book</a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("state")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter text-center">
                                                  Class</a></th>
                                              <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("city")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter d-block text-center">
                                                  Quantity</a></th>
                                              <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7"
                                                  data-sortable="" style={{width: "20%", cursor: "pointer"}}
                                                  onClick={(e)=>this.sortEmpTable("status")}
                                                  >
                                                  <a
                                                  className="dataTable-sorter">
                                                  Price</a></th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.filterSamples?this.state.filterSamples.map((item, index)=>(
                                                  <tr>
                                                      <td>
                                                          <div className="d-flex px-2 py-1">
                                                              <div
                                                                  className="d-flex flex-column justify-content-center">
                                                                  <h6 className="mb-0 font-weight-normal text-sm">{item.index}
                                                                  </h6>
                                                              </div>
                                                          </div>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.employee_name}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.book_name}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {/*{item.book[0].classes.class_name+' - '+item.book[item.book.length-1].classes.class_name}*/}
                                                              {item.class_name}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.quantity}
                                                          </p>
                                                      </td>
                                                      <td>
                                                          <p className="text-sm text-center font-weight-normal mb-0">
                                                              {item.price}
                                                          </p>
                                                      </td>
                                                  </tr>
                                          )):null}
                                          </tbody>
                                      </table>
                                      {this.state.pages===1?null:
                                          <div className="datatable-mui-pagination">
                                            <Pagination count={this.state.pages} color="secondary" onChange={this.handelPagination} size="large"/>
                                          </div>
                                      }
                                  </div>
                              </div>
                          </div>
                  </div>
              </div>
        </div>
      </>

        )
    }
}

const mapStateToProps = state =>({
    user:state.userData.userData,
    sidebar:state.sideBarData.sidebarData.sidebar
})

export default connect(mapStateToProps)(SampleDataTable)